<app-loading-screen *ngIf="spinnergetnaicmapping">Loading NAIC Mapping</app-loading-screen>
<form>
    <div class="pagetitle">Company NAIC Mapping: {{ prfYear }}</div>
    <!-- <div *ngIf="compno!==null">
        <span class="pagetitleSecondLayerBold"> {{ prfNumber }} </span>:<span class="pagetitleSecondLayer">
            {{ prfName }}</span>
    </div> -->
    <br />
    <div>
        <table class="table table-hover">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Company</th>
                    <th scope="col">Company Name</th>
                    <th scope="col">NAIC</th>
                    <th scope="col">AMBest</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of naicMappinglist;let row=index">
                    <td>{{row+1}}</td>
                    <td>{{item.companyno}} </td>
                    <td>{{item.companyname}}</td>
                    <td>{{item.naic}}</td>
                    <td>{{item.ambest}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</form>
