<div class="rule-table-main">
  <div class="container">
    <table>
      <thead>
        <tr>
          <th *ngFor="let col of data.cols">{{ col }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of data.data">
          <td *ngFor="let cell of row">{{ cell }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
