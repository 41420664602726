<app-loading-screen *ngIf="show">Loading Annual Statement Comparison Report</app-loading-screen>
<form>
    <div>
        <div class="pagetitle">Annual Statement Comparison: {{ currentYear }}</div>
        <div *ngIf="compno!==null">
            <span class="pagetitleSecondLayerBold"> {{ prfNumber }} </span>:<span class="pagetitleSecondLayer">
                {{ prfName }}</span>
            <a *ngIf="!isCustomer && !isReadOnlyAdmin" class="emailBtn interactive-links" (click)="email()"><span style="padding-right: 0.5ex;"
                    class="table-material-icons">email</span>Email</a>
        </div>
    </div>
    <br/>
    <div>
        <div *ngIf="iconNote" class="note"><i class="table-material-icons">flag</i> =
            Annual Statement and Premium Report Totals
            do not match</div>
        <table class="table table-hover">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Columns</th>
                    <th scope="col">Annual Statement</th>
                    <th scope="col">Premium Values</th>
                    <th>Premium Difference</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of annualstatementlist;let row=index">
                    <td>{{ (item.Text!=='Total') ? row+1 : ""}}</td>
                    <td>{{item.Text}} </td>
                    <td>{{item.col1 | currency :'USD':symbol:'1.0-2'}}</td>
                    <td>{{item.col2 | currency :'USD':symbol:'1.0-2'}}</td>
                    <td>{{item.diff | currency :'USD':symbol:'1.0-2'}} <i *ngIf="item.mark !==''"
                            class="table-material-icons">flag</i> </td>
                </tr>
            </tbody>
        </table>
    </div>
</form>
<!-- <app-confirm-dialog></app-confirm-dialog> <span class="s-text">{{item.mark}}</span>-->
<!-- <app-toasts aria-live="polite" aria-atomic="true"></app-toasts> -->