export class CompanyInfoModel{
    public address:string;
    public ambest:string;
    public billingno:string;
    public city : string
    public groupreport:string
    public naic:string
    public phone:string;
    public preparer:string;
    public reportofficer:string
    public additionalemail:string;
    public state:string
    public title:string
    public zipcode:string
    public compname:string;
    
    constructor(){
        this.address =''
        this.ambest =''
        this.billingno =''
        this.city  =''
        this.groupreport ='on'
        this.naic =''
        this.phone =''
        this.preparer =''
        this.reportofficer =''
        this.additionalemail = ''
        this.state =''
        this.title =''
        this.zipcode =''
        this.compname =''
    }
}

export class UserDefiledColsModel{
    public ColName:string;
    public ColName1:string;
    public ColName2:string;
    public ColName3:string;
    public ColName4 : string
    public ISOCol:string
    public MatchCol:any
    public ColNo:any
}

export class GroupReportingModel{
    public No:string = "New";
    public CompanyName:string;
    public NAIC:number;
    public chkvalue:boolean=true;
    public AMBest : number
}
export class ReminderMailModel{
    public companyno:string;
    public companyname:string;
    public primaryemail:string;
    public secondaryemail:string;
    public checked:boolean;
    public status : string;
    public sendon : string;
}
export class CompanylistcopyprioryearModel {
    public compname: string;
    public compno: string;
    public modifiedby: string;
    public status: string;
    public checked:boolean;
    public comment: string; 
    public groupcode: string;
    public prfyear: string;
    public submissiondate: string;
    public uploaddate: string;
  
  }

