import { Component, Input } from '@angular/core';
import { ValidationSummaryItem } from '../../data-access/validation-summary-model';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {
  @Input() validationSummaryItems: ValidationSummaryItem[] = [];
  @Input() readOnly: boolean;

  getPanelClass(item: ValidationSummaryItem): string {
    if (item.isInFeedbackUpdatedState) {
      return 'updated';
    }
    return item.status ? item.status.toLowerCase() : 'default';
  }

  getPanelContentClass(item: ValidationSummaryItem): string {
    if (item.isInFeedbackUpdatedState) {
      return 'updated';
    }
    return item.status ? item.status.toLowerCase() : 'default';
  }
}
