import { PremiumReportState, initialDirectState, initialSupplemantalState } from '../premium-report.models';

export const onLoadPremiumInfoFailed = (
  state: PremiumReportState,
  actions
): PremiumReportState => {
  return { ...state, isLoading: false };
};

export const onLoadPremiumInfoSuccess = (
  state: PremiumReportState,
  { response }
): PremiumReportState => {
  var [
    directPremium,
    supplementalPremium,
    uPremium,
    uPremiumSupplemental,
    direct_premium_copy,
    supplemental_premium_copy,
    rows,
    columns,
    userdefined_premium_columns,
    validation_job_id,
  ] = response;

  validation_job_id = validation_job_id || "";

  return {
    ...state,
    isLoading: false,
    premiumData: {
      ...state.premiumData,
      D: {
        ...state.premiumData['D'],
        premiumGridData: [...directPremium],
        premiumGridDataOriginalCopy: [...direct_premium_copy],
      },
      S: {
        ...state.premiumData['S'],
        premiumGridData: [...supplementalPremium],
        premiumGridDataOriginalCopy: [...supplemental_premium_copy],
      },
    },
    userDefinedPremiumData: {
      ...state.userDefinedPremiumData,
      D: [...uPremium],
      S: [...uPremiumSupplemental],
    },
    rows: [...rows],
    columns: { D: [...columns], S: [...columns] },
    userDefinedPremiumDataColumns: [...userdefined_premium_columns],
    validationJobId: validation_job_id
  };
};

export const onLoadPremiumInfo = (
  state: PremiumReportState,
  params: any
): PremiumReportState => {
  return {
    ...state,
    isLoading: true,
    premiumData: {
      D: initialDirectState,
      S: initialSupplemantalState,
    },
    validationJobId: '',
  };
};
