<div class="modal" tabindex="-1" role="dialog" style="display:block!important">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="pagetitle">{{title}}</h4>
                <span class="table-material-icons" (click)="activeModal.dismiss('Cross click')"
                    style="cursor: pointer;font-size: 24px;">close</span>
            </div>
            <div class="modal-body commentbox">
                <p [innerHTML]="message"></p>
            </div>
            <div class="modal-footer">
                <app-button size="medium" (click)="activeModal.dismiss('Cross click')" buttonType="primary"> Close</app-button>
            </div>
        </div>
    </div>
</div>