import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/company/company.service';
import { AppService } from '../../app.service'
import { Router } from '@angular/router';
import { ConfirmDialogService } from '../../confirm-dialog/confirm-dialog.service';
import { Role } from 'src/app/shared/models/user-model';

@Component({
  selector: 'app-prioryearcomparison',
  templateUrl: './prioryearcomparison.component.html',
  styleUrls: ['./prioryearcomparison.component.scss']
})
export class PrioryearcomparisonComponent implements OnInit {

  prfYear: number;
  prfNumber: string;
  prfName: string;
  compno: any;
  groupCode: any;
  priorYearComparisonList: any[] = [];
  compareToPrfNumber: string;
  spinnergetprioryearcomparison: boolean = false;
  showPageError: boolean = false;
  pageError: string = "";
  disableComapreTo: boolean = false;
  isReadOnlyAdmin: boolean;
  isLocked: boolean;
  isReadOnly: boolean;
  flagNote: boolean = false;
  isCustomer: boolean = false;
  format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  constructor(private companyService: CompanyService, private appService: AppService, private router: Router, private confirmDialogService: ConfirmDialogService) { }

  ngOnInit(): void {
    if (!this.appService.hasRole(Role.Admin)) {
      this.isCustomer = true;
    }
    this.compno = this.companyService.companyId;
    this.prfYear = this.companyService.currentYear;
    this.prfNumber = this.companyService.prfNumber;
    this.prfName = this.companyService.prfName;
    this.compareToPrfNumber = this.prfNumber;
    this.isLocked = this.companyService.isEditLocked;
    this.isReadOnlyAdmin = this.appService.isReadonlyAdmin;
    this.isReadOnly = this.isLocked || this.isReadOnlyAdmin;
    this.companyService.getgroup().subscribe((value) => {
      this.groupCode = value;
    });
    this.loadPriorYearComparison();
    this.getPrgress();
  }

  getPrgress() {
    this.companyService.getCustomerPrfProgress(this.prfYear, this.compno, this.groupCode).subscribe({
      next: (resp) => {
        if (resp["message"] == "Success") {
          this.companyService.disablePrf.next(false);
        }
      },
      error: (error) => {
        // //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    });
  }

  loadPriorYearComparison() {
    this.spinnergetprioryearcomparison = true;
    this.priorYearComparisonList = [];
    this.companyService.loadPriorYearComparisonDetails(this.prfYear, this.groupCode, this.compno, this.compareToPrfNumber).subscribe({
      next: (response) => {
        this.spinnergetprioryearcomparison = false;
        if (response["message"] == "Success") {
          this.priorYearComparisonList = response['comparisondata']['prior_comparison_data'];
          if (this.priorYearComparisonList.length == 0) {
            this.pageError = response['comparisondata']['msg'];
            this.showPageError = true;
          }
          else {
            for (let i = 0; i < this.priorYearComparisonList.length; i++) {
              if (this.priorYearComparisonList[i]["mark"] != "")
                this.flagNote = true;
            }
            this.showPageError = false;
          }
        }
      },
      error: (error) => {
        // //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    });
  }
  
  compareTo() {
    let value = this.compareToPrfNumber.toUpperCase();   
    if ((this.compareToPrfNumber == "") || (value.length > 6) || ((value.length > 4) && !(value.substring(4).match(/^[a-zA-Z]+$/)))|| (value.length == 4 && !(/^\d+$/.test(value)) || (value.length < 4 && !(/^\d+$/.test(value)) )) || (value.length<4) || (this.format.test(value)) || (isNaN(+value.substring(0,4))) ) {     
      this.pageError = "Invalid search criteria. Search with valid PRF Number"
      this.showPageError = true;
    }       
    else {
      this.showPageError = false;      
      this.loadPriorYearComparison();
    }
  }
  email() {
    let value = this.compareToPrfNumber.toUpperCase();   
    if ((this.compareToPrfNumber == "") || (value.length > 6) || ((value.length > 4) && !(value.substring(4).match(/^[a-zA-Z]+$/)))|| (value.length == 4 && !(/^\d+$/.test(value)) || (value.length < 4 && !(/^\d+$/.test(value)) )) || (value.length<4) || (this.format.test(value)) || (isNaN(+value.substring(0,4))) ) {  
      this.pageError = "Invalid search criteria. Search with valid PRF Number"
      this.showPageError = true;
      
    }       
    else {
      this.showPageError = false;
      localStorage.setItem("routename", "prioryearcomparison");
      localStorage.setItem("comparedprfnumber", this.compareToPrfNumber);
      this.router.navigate(['/emailpreview'])
    }
    
  }
}
