import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-customer-company-list-header',
  templateUrl: './company-list-header.component.html',
  styleUrls: ['./company-list-header.component.scss'],
})
export class CustomerCompanyListHeaderComponent implements OnInit {
  @Input() headerDetailsLoaded: boolean;
  @Input() isReadOnlyAdmin: boolean;
  @Input() isAdmin: boolean;
  @Input() groupcode: string;
  @Input() groupname: string;
  @Input() custid: string;
  @Input() currentYear: number;
  @Output() onClickAddNewForm = new EventEmitter();
  ngOnInit(): void {}
  addNewForm() {
    this.onClickAddNewForm.emit();
  }
}
