import { Injectable } from '@angular/core';
// import { kMaxLength } from 'buffer';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
// import { stringify } from 'querystring';
import _, { map } from 'underscore';
import { UDEF_excl_cells_service } from '../shared/UDEF_excl_cells_service';
// import { columns } from './app.component';

@Injectable({
    providedIn: 'root'
})


export class PDFService {

    params: printParams;
    constructor(
        private udef_excl_cells_service: UDEF_excl_cells_service
    ) {

    }

    prepareTables = function () {

        var pageCount = Math.floor((this.params.columns.length - 2) / 11) + 1;
        var tables = [];
        let count = this.params.repeatingColumns.length + 1;
        let counts = this.params.repeatingColumns;

        for (var k = 0; k < pageCount; k++) {

            for (var i = count; i < count + 11; i++) {
                counts.push(i);
            }
            var filteredColumns = _.filter(this.params.columns, function (p, index) {
                return _.includes(counts, index);
            });

            var head = _.map(filteredColumns, function (obj) { return _.pick(obj, 'columnName'); });
            var body = [];
            _.each(this.params.data, function (itm) { this.push(_.values(_.pick(itm, counts))) }, body);

            // Get the filtered columns
            const calculatedColumns = _.filter(
                filteredColumns,
              { cellTypeId: 'C' }
            );

            // Get the indices of the filtered columns
            const calculatedColumnIndices = _.reduce(
              _.pick(this.params.columns, counts),
              (result, column, index) => {
                if (_.find(calculatedColumns, column)) {
                  result.push(index - 1 - 11 * k);
                }
                return result;
              },
              []
            );

            var calculatedRows = _.map(this.params.rows, function (obj1) { return _.pick(obj1, 'rowNumber'); });
            
            tables[k] = {
                'header': head.map(a => a.columnName),
                'body': body, 'calculatedColumns': calculatedColumnIndices, 'calculatedRows': calculatedRows.map(a => a.rowNumber)
                // 'exclusiveColumns': exclusiveColumns
            };

            count += 11;
            counts = [1, 2]
        }


        //var results = _.map(this.data, function(obj) { return _.pick(obj, fields); });
        
        return tables;
    }

    public generate() {
        let doc = new jsPDF('landscape');

        doc.setTextColor(0);
        let headers = this.prepareTables();

        let currentHead = new Array(13);
        let currentBody = new Array(13);
        let currentCalculatedColumns = new Array(13);
        let currentCalculatedRows = new Array();
        let exclusiveColumns = new Array();
        let columns = this.params.columns;
        let premlobs = this.params.premlobs;
        let cleanedData = this.params.cleanedData;
        let filteredData = this.params.data;
        let isuserdefined = this.params.userDefined;
        let udef_excl_cells_service = this.udef_excl_cells_service;
        let pageno = 0;
        let totpages = 0;
        for (var i = 0; i < headers.length; i++) {
            currentHead = headers[i].header;
            if (currentHead.length > 2) {
                totpages++;
                currentBody = headers[i].body;
                currentCalculatedColumns = headers[i].calculatedColumns;
                currentCalculatedRows = headers[i].calculatedRows;
                exclusiveColumns = headers[i].exclusiveColumns;
                pageno = i;
                doc.setFontSize(9);

                var fontsize = 0;
                var headerFontSize = 0;
                if (this.params.userDefined) {
                    fontsize = 3.7;
                    headerFontSize = 5.2;
                }
                else {
                    fontsize = 4;
                    headerFontSize = 5.4;
                }
                (doc as any).autoTable({
                    head: [currentHead],
                    body: currentBody,
                    styles: {
                        cellPadding: .35, fontSize: fontsize, minCellHeight: 2, lineWidth: 0.01,
                        lineColor: 0, halign: 'right'
                    },
                    //columnStyles: { text: { cellWidth: '10' } },
                    headStyles: { fontSize: headerFontSize, fillColor: [85, 147, 195], textColor: 0, halign: 'center' },

                    bodyStyles: { textColor: 0 },
                    alternateRowStyles: { fillColor: 255 },
                    columnStyles: {
                        0: { halign: 'left', fontStyle: 'bold', cellWidth: 36 },
                        1: { halign: 'left', fontStyle: 'bold', cellWidth: 8 },
                        2: { cellWidth: 22 },
                        3: { cellWidth: 22 },
                        4: { cellWidth: 22 },
                        5: { cellWidth: 22 },
                        6: { cellWidth: 22 },
                        7: { cellWidth: 22 },
                        8: { cellWidth: 22 },
                        9: { cellWidth: 22 },
                        10: { cellWidth: 22 },
                        11: { cellWidth: 22 },
                        12: { cellWidth: 22 },
                        13: { cellWidth: 22 }
                    },
                    willDrawCell: function (data) {
                        
                        if (data.row.section === 'body' && (_.contains(currentCalculatedColumns, String(data.column.dataKey)) ||
                            _.contains(currentCalculatedColumns, data.column.dataKey)))
                            doc.setFillColor(211, 211, 211)

                        var rowIndex = data.row.index + 6;
                        if (!isuserdefined) {
                            var inxOffset = getStateIndexOffset(rowIndex);
                            if (data.row.section === 'body' && (_.contains(currentCalculatedRows, parseInt(inxOffset)) ||
                                _.contains(currentCalculatedRows, inxOffset)))
                                doc.setFillColor(211, 211, 211)
                        } else {
                            if (data.row.section === 'body' && (_.contains(currentCalculatedRows, String(rowIndex)) ||
                                _.contains(currentCalculatedRows, rowIndex)))
                                doc.setFillColor(211, 211, 211)
                        } 

                        if (data.row.section === 'body' && (_.contains([0, 1], String(data.column.dataKey)) ||
                            _.contains([0, 1], data.column.dataKey)))
                            doc.setFillColor(255, 255, 255)

                        //Exclusive columns
                        if (data.row.section === 'body') {
                            var ColIndex = data.column.dataKey + 1;
                            if (ColIndex > 2) {
                                var colIndexToCheck = ColIndex;
                                // colIndexToCheck = ColIndex + (pageno * 13);
                                if (data.table.startPageNumber > 1) {
                                    colIndexToCheck = ColIndex + ((data.table.startPageNumber - 1) * 11);
                                }
                                if (isuserdefined) {
                                    colIndexToCheck = ColIndex + (pageno * 11);
                                    var isexclusive = udef_excl_cells_service.IsExclusiveCell(rowIndex, colIndexToCheck, columns, 3, premlobs);
                                    if (isexclusive) {
                                        doc.setFillColor(102, 178, 255);
                                    }
                                }
                                else {
                                    var columnInfo = columns.filter(function (e, index) {
                                        return index == colIndexToCheck
                                    });
                                    if (columnInfo.length > 0) {
                                        var rows = columnInfo[0]["ExclRows"];
                                        console.log(rows)
                                        if (rows != null) {
                                            var rowSplit = rows.split(",");
                                            var inxOffset = getStateIndexOffset(rowIndex);
                                            if (rowSplit.indexOf(inxOffset) >= 0) {
                                               doc.setFillColor(102, 178, 255);
                                            }
                                        }
                                    }
                                }


                                // var indexes = exclusiveColumns.map(a => a.indexes);
                                // for (var j = 0; j < indexes.length; j++) {
                                //     if (data.row.index === indexes[j] && _.contains(exclusiveColumns.map(a => a.columns)[j], data.column.dataKey)) {
                                //         doc.setFillColor(102, 178, 255);
                                //     }
                                // }
                            }
                        }

                    },
                    theme: 'grid', // 'striped', 'grid' or 'plain'
                    // Properties
                    //startY: 10, // false (indicates margin top value) or a number
                    margin: {
                        //top: 10,
                        right: 5,
                        bottom: 0,
                        left: 5,
                        useFor: 'page' // This property is mandatory to keep the margin to supsequent pages
                    },
                    pageBreak: 'avoid', // 'auto', 'avoid' or 'always'
                    tableWidth: 'auto', // 'auto', 'wrap' or a number, 

                    //showHead: 'everyPage', // 'everyPage', 'firstPage', 'never',
                    tableLineColor: 0, // number, array (see color section below)
                    tableLineWidth: .1,

                }
                )

                if (i != headers.length - 1) {
                    doc.addPage();
                }
            }
            else {
                doc.deletePage(headers.length)
            }
        };
        var userdefinedText = "";
        if (this.params.userDefined) {
            userdefinedText = 'User Defined Columns -';
        }
        var pageheading = this.params.currentYear + ' Premium Report Form (In Thousands) - ' + userdefinedText + this.params.prfNumber + ' - ' + this.params.prfName + this.params.premiumtype;
        var splitTitle = doc.splitTextToSize(pageheading, 220);
        var printingHead = splitTitle.slice(0, 2)
        var Number = 0;
        for (var i = 1; i <= headers.length; i++) {
            currentHead = headers[i - 1].header;
            if (currentHead.length > 2) {
                Number++;
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(10);
                doc.setPage(i).text(printingHead, 145, 7, { align: 'center' })
                doc.setFontSize(8);
                doc.setFont('helvetica', 'normal');
                doc.text('Page ' + Number + ' of ' + totpages, 145, 200, { align: 'center' })

                var today = new Date();
                doc.text('Copyright, ISO Properties, Inc., ' + today.getFullYear(), 292, 200, { align: 'right' })
                // var date = (today.getMonth() + 1) + '-' + today.getDate() + '-' + today.getFullYear();
                // var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                // var dateTime = date + ' ' + time;
                var Dt = new Date().toLocaleString();
                doc.text(Dt, 5, 200);
            }
        }
        doc.save(pageheading.split('.').join(""))

        function getStateIndexOffset(rowIndex: number): string {
            const adjustedIndex = rowIndex - 6;
            const stateKey = filteredData[adjustedIndex][1];
            
            const index = cleanedData.findIndex(item => item[1] === stateKey);
            return (index !== -1) ? String(index + 6) : '-1';
        }


    }
}

export class printParams {
    public columns = [];
    public rows = [];
    public data = []
    public repeatingColumns = [];
    public pageColumns: number;
    public currentYear: number;
    public prfNumber: string;
    public prfName: string;
    public userDefined: boolean;
    public premlobs = [];
    public premiumtype: string;
    public cleanedData = []
    constructor(columns = [], rows = [], data = [], repeatingColumns = [], pageColumns: number,
        currentyear, prfno, prfname, userdefined, premlobs, premiumtype, cleanedData=[]) {
        this.columns = columns;
        this.rows = rows;
        this.data = data;
        this.repeatingColumns = repeatingColumns;
        this.pageColumns = pageColumns;
        this.currentYear = currentyear;
        this.prfNumber = prfno;
        this.prfName = prfname;
        this.userDefined = userdefined;
        this.premlobs = premlobs;
        this.premiumtype = premiumtype;
        this.cleanedData = cleanedData
    }
}

