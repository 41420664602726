<div *ngIf="isLoaded">
    <div class="modal" tabindex="-1" role="dialog" style="display:block!important">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <i class="table-material-icons">warning</i>
                    <div>
                        <p class="confirmpagetitle">You are leaving this page without saving changes.</p>
                        <p class="confirmbody">Are you sure you want to leave without saving your changes? All unsaved
                            changes on this page will be lost.</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <app-button size="medium" (click)="action(false)" buttonType="primary" style="width: 180px;"> No,
                        stay
                        on page </app-button>
                    <span> or </span>
                    <a (click)="action(true);" class="vui-links">Yes, leave without saving
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>