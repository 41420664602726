
import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReportsubmissionComponent } from './reportsubmission/reportsubmission.component';
import { GroupmodalComponent } from './groupmodal/groupmodal.component'
import { AdobelaunchService } from './_services/adobelaunch.service';
import { AppService } from 'src/app/app.service';

@Component({
    selector: 'app-openmodal',
    template: ''
})

export class ModalContainerComponent implements OnDestroy, AfterViewInit {
    destroy = new Subject<any>();
    currentDialog = null;

    constructor(
        private modalService: NgbModal, private location: Location,
        private appService: AppService,
        route: ActivatedRoute,private AdobelaunchService:AdobelaunchService,
        router: Router
    ) {
        route.params.pipe(takeUntil(this.destroy)).subscribe(params => {
            // When router navigates on this component, opens up the submission modal
            if (router.routerState.snapshot.url === '/PRFSubmission') {
                this.currentDialog = this.modalService.open(ReportsubmissionComponent, { windowClass: "finalSubmissionModal" });
                // Go back/home page after the modal is closed/dismissed
                this.currentDialog.result.then(result => {
                    if (result == 'withNavigateToPremiumReport') {
                            router.navigate(['/premiumreport']);
                    }
                    else if (result === 'withValidationSummary') {
                            router.navigate(['/validationsummary']);
                    }
                    else {
                        this.location.back();
                    }
                }, reason => {
                    router.navigateByUrl('/dboard');
                });
            }
            else {
                this.modalService.dismissAll()
                this.currentDialog = this.modalService.open(GroupmodalComponent);
                this.currentDialog.result.then((result) => {
                    if (result == "Close") {
                        router.navigate(['/dboard']);
                    }
                });
            }


        });
    }
    ngAfterViewInit() {
        this.AdobelaunchService.EventEndDispatch();
        }
    ngOnDestroy() {
        this.destroy.next(null);
    }
}
