import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrfSelectComponent } from './prf-select.component';
import { SelectDropdownComponent } from 'src/app/ui-components/select-dropdown/select-dropdown.component';

@NgModule({
  declarations: [PrfSelectComponent],
  imports: [CommonModule, SelectDropdownComponent],
  exports: [PrfSelectComponent],
})
export class PrfSelectModule {}
