import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthConfig } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { Subscription } from 'rxjs';
import { EntitlementService } from './shared/entitlement.service';
import { AppService } from './app.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import {
  Router,
  Event,
  NavigationEnd,
  NavigationStart,
  NavigationCancel,
  NavigationError,
} from '@angular/router';

import { ConfirmDialogService } from './confirm-dialog/confirm-dialog.service';
import { HttpService } from './shared/http.service';
import { Role } from './shared/models/user-model';
import { IProduct } from './shared/models/profilelink.model';
import { CompanyService } from './company/company.service';
import { DOCUMENT } from '@angular/common';
import { TabService } from './shared/tab.service';

declare function loadCustomerSelectionJS(): any;

export interface IProductList {
  id: number;
  categoryTitle: string;
  type: string;
  items: any;
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Premium Report Form';
  currrWidth = window.innerWidth;
  hasAdminRole = false;
  menuLists: any[];
  footerList: any[];
  productLists: IProductList[] = [];
  isproducteligible = true;
  helpurl = 'https://iso.com';
  welomemessage: string;
  authConfig: AuthConfig = { waitForTokenInMsec: 10 };
  customerSuportUrl: string;
  ApplicationUrl: string;
  errorMessage: any;
  isAuthenticated = false;
  subscription: Subscription;
  isonetAuthmeUri: string;
  public isDashboard: string = 'none';
  public LoderVisible: string = 'none';
  public isAdmin: string = 'none';
  VendorName: string = '';
  dialogObj: any;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  sessiontitle = 'angular-idle-timeout';
  showLoadingIndicator = true;
  changePasswordLink: string = '#';
  manageProfileLink: string = '#';
  productNewsLink: string = '#';
  corporateHomeLink: string = '#';
  termsAndConditionsLink: string = '#';
  privacyAndPolicyLink: string = '#';
  contactUsLink: string = '#';
  globalprfcustid: string;
  userISOProducts: IProduct[]; // megamenu data set
  allISOProducts: IProduct[]; // megamenu data set
  categoryISOProducts: Object;

  isJSLoaded: boolean = false;

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private oauthService: OAuthService,
    private router: Router,
    private entitlementService: EntitlementService,
    private AppService: AppService,
    private idle: Idle,
    private keepalive: Keepalive,
    private cookieService: CookieService,
    private entitlementservice: EntitlementService,
    private confirmDialogService: ConfirmDialogService,
    private httpService: HttpService,
    private companyService: CompanyService,
    private tabService: TabService
  ) {
     console.log("hello from angular 18");
    // if(!cookieService.get("prd_ctx")){
    cookieService.set("prd_ctx", environment.ProductIdAlias)
    // }
    localStorage.setItem("userid", this.httpService.getIsonetValue("userid"));

    var Cookiecstid = this.httpService.getIsonetValue("cstid");
    //Cookiecstid = '5847'//'5847'167272//8337
    //console.info("Cookiecstid"  +Cookiecstid);
    // var globalprfcustid=window['prfcustid'];
    //this.globalprfcustid = localStorage.getItem('prfcstid')
    // this.globalprfcustid = '5847'
    //if (typeof this.globalprfcustid != 'undefined' && this.globalprfcustid) {
    if (Cookiecstid) {
      localStorage.setItem('prfcstid', Cookiecstid);
      //if (parseInt(this.globalprfcustid) == environment.MasterId) {
      if (+Cookiecstid == environment.MasterId) {
        this.AppService.isAdmin = true;
        this.AppService.user.role = Role.Admin;
        this.hasAdminRole = true;
        // this.AppService.isCustomer=false;
      }
      //else if (parseInt(this.globalprfcustid) == environment.ViewerId) {
      else if (+Cookiecstid == parseInt(environment.ViewerId)) {
        this.AppService.isReadonlyAdmin = true;
        this.AppService.user.role = Role.Admin;
        this.hasAdminRole = true;
        // this.AppService.isCustomer=false;
      } else {
        // this.AppService.isCustomer=true;
        this.AppService.user.role = Role.Customer;
        this.hasAdminRole = false;

        //if (this.globalprfcustid !== '' && this.globalprfcustid !== null) {
        if (Cookiecstid !== '' && Cookiecstid !== null) {
          //this.companyService.setcustid(this.globalprfcustid)
          this.companyService.setcustid(Cookiecstid);
        }
      }
    } else {
      //if (Cookiecstid) {
      var url =
        environment.IsoAuthService +
        '/GetSessionProductGroupMembershipAndCustomerIds/';
      var custidsrv = this.httpService.getcustid(url);
      if (custidsrv) {
        custidsrv.subscribe({
          next: (resp) => {
            var splitted = resp.toString().split('|');
            var custid = splitted.find((x) => x.includes('PRF')) || '';
            if (custid != '') {
              var cstid = custid.toString().split(',')[0];
              localStorage.setItem('prfcstid', cstid);
              //this.companyService.setcustid(cstid);
              // window['prfcustid']=cstid;
              //this.cookieService.set( 'cstid', cstid.toString() );
              if (+cstid == environment.MasterId) {
                this.AppService.isAdmin = true;
                this.AppService.user.role = Role.Admin;
                this.hasAdminRole = true;
                // this.AppService.isCustomer=false;
              } else if (+cstid == parseInt(environment.ViewerId)) {
                this.AppService.isReadonlyAdmin = true;
                this.AppService.user.role = Role.Admin;
                this.hasAdminRole = true;
                // this.AppService.isCustomer=false;
              } else {
                // this.AppService.isCustomer=true;
                this.AppService.user.role = Role.Customer;
                this.hasAdminRole = false;

                if (cstid !== '' && cstid !== null) {
                  this.companyService.setcustid(cstid);
                }
              }
            }
          },
          error: (error) => {
            console.log('ISOnet product eligibility check error.');
            // //console.log(error);
          }
        });
        //}
      }
    }

    console.log('dddddddd');
    console.log(environment.enviroment_code);
    console.log('dddddddd');

    if (environment.enviroment_code == 'D') {
      this.companyService.setcustid('8337');

      //Admin user
      // localStorage.setItem("userid", 'max');
      // this.AppService.isAdmin = true;
      // this.hasAdminRole = true;
      // this.AppService.user.role = Role.Admin;

      // this.AppService.isCustomer=false;

      //Customer
      localStorage.setItem('userid', 'max');
      localStorage.setItem('groupcode', '0057');
      localStorage.setItem('groupname', 'Test');

      //Readonly Admin
      // this.AppService.isReadonlyAdmin = true;
    }

    this.welomemessage = 'Connecting to ISONet....';
    this.setupredirect();
    this.AppService.getConfigStatus().subscribe({
      next: (configStatus) => {
        if (configStatus) {
          const appConfig = this.AppService.getGlobalConfig();
          this.authConfig.issuer = appConfig.identityUrl;
          this.authConfig.redirectUri = appConfig.redirectUrl;
          this.authConfig.clientId = appConfig.clientId;
          this.authConfig.scope = 'openid profile email';
          this.authConfig.logoutUrl =
            appConfig.identityUrl + '/connect/endsession';
          this.customerSuportUrl = appConfig.veriskCustomerSupport;
          this.configureWithConfigApi();
          this.geToken(appConfig.apiUrl);
          this.ApplicationUrl = appConfig.apiUrl;
        }
      },
      error: (error) => {
        console.log('Error in config read: ' + error);
        this.errorMessage = error as any;
      }
    });

    /*
  this.router.events.subscribe((myrouterEvent: Event) => {
    if(myrouterEvent instanceof NavigationStart){
      this.showLoadingIndicator = true;

    }
    if(myrouterEvent instanceof NavigationEnd){
      this.showLoadingIndicator = false;

    }
  });*/

    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(parseInt(environment.IDLE_TIME_OUT.toString()));
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(parseInt(environment.SESSION_TIME_OUT.toString()));
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    /*
      idle.onIdleEnd.subscribe(() => {
        this.idleState = 'No longer idle.'

        this.reset();
      }); */

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      // console.log(this.idleState);
      this.router.navigate(['/']);
      this.logout();
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'Need More Time?';
      this.confirmDialogService.confirmThis(
        'Session',
        'Your session is about to expire. Select YES to remain in your session',
        () => {
          this.idleState = 'No longer idle, continue session';
          this.reset();
        },
        () => {
          this.idleState = 'Timed out!';
          this.timedOut = true;
          this.router.navigate(['/']);
          this.logout();
        }
      );
      // // this.dialogService.closeconfirmDialog();
      // // this.dialogObj = this.dialogService.openConfirmDialog("Your session is about to expire. Select YES to remain in your session").afterClosed().subscribe(resp => {
      // //   if (resp) {
      // //     this.idleState = 'No longer idle, continue session'
      // //     this.reset();
      // //   } else {
      // //     this.idleState = 'Timed out!';
      // //     this.timedOut = true;
      // //     this.router.navigate(['/']);
      // //     this.logout();
      // //   }
      // // });
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.reset();

    this.allISOProducts = [];
    this.userISOProducts = [];
    this.categoryISOProducts = {};
    //   'Analytical': [
    //     {
    //       id: 402,
    //       title: "Verisk Strategic Alliances Portal",
    //       url: "http://vsapt.iso.com",
    //       isEligible: true,
    //     },
    //     {
    //       id: 190,
    //       title: "wcPrism",
    //       url: "http://wcsuat.iso.com",
    //       isEligible: false,
    //     }

    //   ],
    //   'Bundle of papers': [
    //     {
    //       id: 412,
    //       title: "Verisk Test Portal",
    //       url: "http://vsapt.iso.com",
    //       isEligible: false,
    //     },
    //     {
    //       id: 195,
    //       title: "TesPrism",
    //       url: "http://wcsuat.iso.com",
    //       isEligible: true,
    //     }
    //   ]
    // };

    const userId: string = httpService.getIsonetValue('userid');
    const ticket: string = httpService.getIsonetValue('ticket');

    // this.productLists.push(
    //   {
    //     id: 1,
    //     categoryTitle: "My Products",
    //     type: "head",
    //     items: [{
    //       id: 398,
    //       title: "My App",
    //       url:
    //         "http://www.verisk.com/insurance/products/iso-risk-analyzer-suite/",
    //       isEligible: true,
    //     }]
    //   },
    //   {
    //     id: 2,
    //     categoryTitle: "Full List of Products",
    //     type: "head",
    //     // items: this.allISOProducts
    //     items: [
    //       {
    //         id: 398,
    //         title: "ISO Risk Analyzer Table Express",
    //         url:
    //           "http://www.verisk.com/insurance/products/iso-risk-analyzer-suite/",
    //         isEligible: false,
    //       },
    //       {
    //         id: 125,
    //         title: "ISOPAX",
    //         url: "http://www.isopax.com/",
    //         isEligible: true,
    //       }
    //     ]
    //   }
    // )
    this.AppService.getAllProducts().subscribe((data) => {
      // populate category products
      if (data['categories']) {
        data['categories'].forEach((c) => {
          this.categoryISOProducts[c['title']] = c['products'];
        });
      }

      for (const key in this.categoryISOProducts) {
        const index1 = this.categoryISOProducts[key].findIndex(
          (d) => d.title == 'Electronic Participation Reports'
        );
        if (index1 > -1) {
          this.categoryISOProducts[key].splice(index1, 1);
        }
        const index2 = this.categoryISOProducts[key].findIndex(
          (d) => d.title == 'Stat Agent Visualizer & Reports'
        );
        if (index2 > -1) {
          this.categoryISOProducts[key].splice(index2, 1);
        }
        const index3 = this.categoryISOProducts[key].findIndex(
          (d) => d.title == 'Strategic Alliances Services'
        );
        if (index3 > -1) {
          this.categoryISOProducts[key].splice(index3, 1);
        }
      }

      // populate all products
      if (data['products']) {
        data['products'].forEach((p) => {
          if (
            p['title'] != 'Electronic Participation Reports' &&
            p['title'] != 'Stat Agent Visualizer & Reports' &&
            p['title'] != 'Strategic Alliances Services'
          ) {
            const item: IProduct = {
              id: p['id'],
              title: p['title'],
              url: p['url'],
              isEligible: p['isEligible'],
            };
            this.allISOProducts.push(item);
          }
        });
      }

      this.AppService.getUserProducts(userId, ticket).subscribe({
        next: (_userProducts) => {
          // populate user products
          this.userISOProducts = _userProducts;
          if (_userProducts) {
            _userProducts.forEach((p) => {
              const item: IProduct = {
                id: p['id'],
                title: p['title'],
                url: p['url'],
                isEligible: p['isEligible'],
              };
              this.userISOProducts.push(item);

              // reset eligible products in all products
              const index = this.allISOProducts.findIndex((element) => {
                return element.title === p.title;
              });
              if (index > -1) {
                this.allISOProducts[index].isEligible = true;
              }
              // reset eligible products in category products
              // tslint:disable-next-line: forin
              for (const key in this.categoryISOProducts) {
                const i = this.categoryISOProducts[key].findIndex((element) => {
                  return element.title === p.title;
                });
                if (i > -1) {
                  this.categoryISOProducts[key][i].isEligible = true;
                }
              }
            });
          }
        },
        error: (error) => (this.errorMessage = <any>error)
      });

      this.productLists.push(
        {
          id: 1,
          categoryTitle: 'My Products',
          type: 'head',
          items: this.userISOProducts,
        },
        {
          id: 2,
          categoryTitle: 'Full List of Products',
          type: 'head',
          items: this.allISOProducts,
        }
      );
    });

    this.AppService.getAllUserProfileLinks().subscribe({
      next: (_headerLinks) => {
        if (_headerLinks) {
          _headerLinks.forEach((up) => {
            if (up.linkFor === 'Change Password') {
              this.changePasswordLink = up.url;
            }
            if (up.linkFor === 'Manage Profile') {
              this.manageProfileLink = up.url;
            }
            if (up.linkFor === 'Manage Your ISOnet Product News') {
              this.productNewsLink = up.url;
            }
            if (up.linkFor === 'Verisk Corporate Home') {
              this.corporateHomeLink = up.url;
            }
            if (up.linkFor === 'Terms and Conditions') {
              this.termsAndConditionsLink = up.url;
            }
            if (up.linkFor === 'Privacy and Security Policy') {
              this.privacyAndPolicyLink = up.url;
            }
            if (up.linkFor === 'Contact Us') {
              this.contactUsLink = up.url;
            }
            this.fillMenuList();
            this.fillFooterList();
          });
        }
      },
      error: (error) => (this.errorMessage = <any>error)
    });

    this.AppService.getUserEligibleproductv2(
      environment.ProductIdAlias,
      ticket
    ).subscribe((iseligible) => {
      if (iseligible) {
        this.AppService.isProductEligible.next(true);
      } else {
        this.AppService.isProductEligible.next(false);
      }
    });
  }
  private setupredirect() {
    if (window.location.href.includes('/redirecttovalidationsummary')) {
      sessionStorage.setItem(
        'redirectToValidationSummaryUrl',
        window.location.href
      );
    }
    else if (window.location.href.includes('/redirect')) {
      sessionStorage.setItem(
        'redirect',
        window.location.href
      );
    }
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  ngOnInit(): void {
    // var evt = new CustomEvent('event-viewend');
    // this.doc.body.dispatchEvent(evt);

    // this.loadScript(); //Load Adobe Launch Code
    this.entitlementService.getLoginStatus().pipe(distinctUntilChanged()).subscribe(status => {
      this.isAuthenticated = status;
      if (status) {
        this.loadJSScript().onload = () => {
          this.isJSLoaded = true;
          loadCustomerSelectionJS();
        };
      }
    })
    this.AppService.title.subscribe((updatedTitle) => {
      this.title = updatedTitle;
    });
    this.AppService.display.subscribe((display) => {
      this.isDashboard = display;
    });
    this.router.events.subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent);
    });

    this.AppService.vendorname.subscribe((vendorname) => {
      this.VendorName = 'Vendor: ' + vendorname;
    });
    this.AppService.admin.subscribe((admin) => {
      this.isAdmin = admin;
    });

    this.AppService.isProductEligible.subscribe((isEligible) => {
      this.isproducteligible = isEligible;
      // checking the iso product eligibility - uncomment below line when running locally.
      // this.isproducteligible = true;
    });

    this.tabService.switchChange$().subscribe((isActive) => {
      if (isActive) {
        const ticket = this.httpService.getIsonetValue('ticket');
        if (!ticket && environment.enviroment_code != 'D') {
          this.httpService.logout();
        }
      }
    });
  }
  //Adobe Launch Code
  public loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = environment.adobeLaunchCode;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  homeClick() {
    this.AppService.setTitle('DashBoard');
  }
  private configureWithConfigApi() {
    this.oauthService.setStorage(sessionStorage);
    this.oauthService.configure(this.authConfig);
    this.oauthService.requireHttps = false;
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndLogin();
  }
  geToken(apiUrl: string): void {
    this.entitlementService.getLoginStatus().subscribe((status) => {
      this.isAuthenticated = status;
      if (status) {
        const appConfig = this.AppService.getGlobalConfig();
        //location.href=appConfig.apiUrl;
        //location.href='/dboard';
      }
    });
  }

  checkRouterEvent(routerEvent: Event): void {
    if (routerEvent instanceof NavigationStart) {
      var userId: string = this.httpService.getIsonetValue('userid');
      //var customerid: string = this.globalprfcustid ?? this.httpService.getCookie('cstid');
      var customerid: string = this.httpService.getIsonetValue('cstid');
      var customername: string = this.httpService.getIsonetValue('customername');
      var prfnumber: string = localStorage.getItem('prfNumber') || '';

      var digitalData = {
        page: {
          pageInfo: {
            pageID: window.location.hostname + routerEvent.url,
            URL: window.location.hostname,
            path: window.location.pathname,
            subdomain: '',
            product: 'Premium Report Form',
            prf_numb: prfnumber, // PRF number when on one
            cust_type: this.hasAdminRole ? 'admin' : 'normal user', // admin or normal user
          },
          user: {
            username: userId,
            customerid: customerid,
            customername: customername,
          },
        },
      };
      window['digitalData'] = digitalData;

      var evt = new CustomEvent('event-view-start');
      document.body.dispatchEvent(evt);
      // console.log('event-view-start triggered')
    }

    if (routerEvent instanceof NavigationEnd) {
      if (this.getCookie('id_token')) {
        this.welomemessage = 'Receiving from ISONet....';
        this.entitlementService.setLoginStatus(true);
      }
    }
  }
  // Show loading on route change
  /*loader(routerEvent: Event): void{
    if(routerEvent instanceof NavigationStart){
      this.showLoadingIndicator = true;
    }
    if(routerEvent instanceof NavigationEnd){
      this.showLoadingIndicator = false;
    }
  }*/
  fillMenuList() {
    this.menuLists = [];
    this.menuLists = [
      {
        id: 1,
        title: 'Change Password',
        url: this.changePasswordLink,
      },
      {
        id: 2,
        title: 'Manage Profile',
        url: this.manageProfileLink,
      },
      {
        id: 3,
        title: 'Manage Your ISOnet Product  News',
        url: this.productNewsLink,
      },
    ];
  }

  fillFooterList() {
    this.footerList = [
      {
        id: 1,
        title: 'Verisk Corporate Home',
        url: this.corporateHomeLink,
      },
      {
        id: 1,
        title: 'Terms and Conditions',
        url: this.termsAndConditionsLink,
      },
      {
        id: 1,
        title: 'Privacy and Security Policy',
        url: this.privacyAndPolicyLink,
      },
      {
        id: 1,
        title: 'Contact Us',
        url: this.contactUsLink,
      },
    ];
  }

  getCookie(name: string) {
    return sessionStorage.getItem(name);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  logout() {
    localStorage.removeItem('prfcstid');
    localStorage.setItem('groupcode', '');
    localStorage.setItem('groupname', '');
    localStorage.setItem('companyId', '');
    localStorage.setItem('year', '');
    localStorage.removeItem('isLocked');
    localStorage.removeItem('validationProgress');
    localStorage.setItem('groupreportenabled', '');
    localStorage.setItem('prfName', '');
    localStorage.removeItem('prfNumber');
    localStorage.setItem('currentprfyear', '');
    localStorage.setItem("searchValue", "");
    localStorage.setItem("searchType", "");
    localStorage.setItem('currentyearadmin', '');
    localStorage.setItem('currentyearcust', '');
    localStorage.removeItem('prfStatus');
    console.log("APP COMP LOGOUT")
    // this.entitlementService.setLoginStatus(false);
    // this.oauthService.logOut();
    sessionStorage.clear();
    var id_token = this.getCookie('id_token');
    var logoutUrl =
      environment.GATEWAY_LOGOUT +
      environment.IDENTITY_LOGOUT +
      '?id_token_hint=' +
      id_token +
      '&post_logout_redirect_uri=' +
      window.location.origin;
    window.location.href = logoutUrl;
  }
  //js loader for isonet okta integration
  public loadJSScript(): HTMLScriptElement {
    const script = document.createElement('script');
    script.src = environment.OKTA_INTEGRATION_JS;
    script.async = true;
    document.head.appendChild(script);
    return script;
  }
}
