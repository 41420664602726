import { NgModule } from '@angular/core';
import { ClickOutsideDirective } from './click-outside.directive';
import * as i0 from "@angular/core";
var ClickOutsideModule = function () {
  function ClickOutsideModule() {}
  ClickOutsideModule.ɵmod = i0.ɵɵdefineNgModule({
    type: ClickOutsideModule
  });
  ClickOutsideModule.ɵinj = i0.ɵɵdefineInjector({
    factory: function ClickOutsideModule_Factory(t) {
      return new (t || ClickOutsideModule)();
    }
  });
  return ClickOutsideModule;
}();
export { ClickOutsideModule };
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(ClickOutsideModule, {
    declarations: [ClickOutsideDirective],
    exports: [ClickOutsideDirective]
  });
})();
(function () {
  i0.ɵsetClassMetadata(ClickOutsideModule, [{
    type: NgModule,
    args: [{
      declarations: [ClickOutsideDirective],
      exports: [ClickOutsideDirective]
    }]
  }], null, null);
})();