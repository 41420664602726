import { createReducer, on } from '@ngrx/store';
import * as ValidationSummaryActions from './validation-summary-actions';
import {
  ValidationDetailContent,
  ValidationSummaryBase,
  ValidationSummaryItem,
  ValidationSummaryState,
} from './validation-summary-model';

export const initialState: ValidationSummaryState = {
  jobId: '',
  validationSummaryData: {
    isComplete: false,
    data: [],
    isDummyReport: false,
    year: 0,
    groupCode: "",
    companyNumber: "",
    numberOfCompanyValidationMessage: null,
    emailCount:0,
    lastEmailSendDate:''
  },

};

const onLoadValidationSummaryItemsSuccess = (
  state: ValidationSummaryState,
  {
    validationSummaryBaseItem,
  }: { validationSummaryBaseItem: ValidationSummaryBase }
): ValidationSummaryState => ({
  ...state,
  validationSummaryData: validationSummaryBaseItem,
});


const onLoadValidationSummaryItemsFailure = (
  state: ValidationSummaryState
): ValidationSummaryState => ({
  ...state,
  validationSummaryData: {
    ...state.validationSummaryData,
    isComplete: false,
    data: [],
  },
});

const onInitiateValidationOnAfterSubmit = (
  state: ValidationSummaryState,
  { inputData }: { inputData: any }
): ValidationSummaryState => {
  return {
    ...state,
    validationSummaryData: {
      ...state.validationSummaryData,
      isComplete: false,
      data: [],
      isDummyReport: inputData.isDummyReport,
      numberOfCompanyValidationMessage: null,
    },
  };
};

const onResetValidationSummaryData = (
  state: ValidationSummaryState,
): ValidationSummaryState => {
  return {
    ...state,
    validationSummaryData: {
      ...state.validationSummaryData,
      isComplete: false,
      data: [],
      isDummyReport: state.validationSummaryData.isDummyReport,
      numberOfCompanyValidationMessage: null,
    },
  };
};

const onLoadInitiateValidationOnAfterSubmitSuccess = (
  state: ValidationSummaryState,
  {
    jobId,
  }: {
    jobId: string;
  }
) => ({
  ...state,
  jobId: jobId,
});

const OnLoadNumberOfCompanyValidationMessageSuccess = (
  state: ValidationSummaryState,
  {
    message,
  }: {
    message: string;
  }
) => ({
  ...state,
  validationSummaryData: {
    ...state.validationSummaryData,
    numberOfCompanyValidationMessage: message,
  },
});

const onLoadAllValidationSummaryItems = (
  state: ValidationSummaryState
) => ({
  ...state,
  validationSummaryData: {
    ...state.validationSummaryData,
    numberOfCompanyValidationMessage: null,
  },
});

const onReset = (state: ValidationSummaryState): ValidationSummaryState => ({
  ...state,
});

export const validationSummaryReducer = createReducer(
  initialState,
  on(
    ValidationSummaryActions.loadValidationSummaryItemsSuccess,
    onLoadValidationSummaryItemsSuccess
  ),
  on(
    ValidationSummaryActions.initiateValidationOnAfterSubmitSuccess,
    onLoadInitiateValidationOnAfterSubmitSuccess
  ),
  on(
    ValidationSummaryActions.loadValidationSummaryItemsFailure,
    onLoadValidationSummaryItemsFailure
  ),
  on(ValidationSummaryActions.reSubmit, onReset),
  on(
    ValidationSummaryActions.initiateValidationOnAfterSubmit,
    onInitiateValidationOnAfterSubmit
  ),
  on(
    ValidationSummaryActions.loadNumberOfCompanyValidationMessageSuccess,
    OnLoadNumberOfCompanyValidationMessageSuccess
  ),
  on(
    ValidationSummaryActions.loadAllValidationSummaryItems,
    onLoadAllValidationSummaryItems
  ),
  on(
    ValidationSummaryActions.resetValidationSummaryData,
    onResetValidationSummaryData
  ),
);
