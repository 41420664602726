import { Injectable } from '@angular/core';
import {
  Premiumreportservice,
  premiumreportParams,
} from '../company/premiumreport/data-access/services/premiumreportservice';

@Injectable({
  providedIn: 'root',
})
export class Userdefinedservice {
  params: userdefinedParams;
  premiumreportparams: premiumreportParams;

  constructor(private premiumreportservice: Premiumreportservice) {}

  setParams(
    columns,
    rows,
    userdefined_premium_columns,
    const_statecode_column,
    userdefined_row_code,
    supplemental_row_code,
    const_fixed_columns,
    AssumedFromNonAffiliates_row_code
  ) {
    this.params = new userdefinedParams(
      columns,
      rows,
      userdefined_premium_columns,
      const_statecode_column,
      userdefined_row_code,
      supplemental_row_code,
      const_fixed_columns,
      AssumedFromNonAffiliates_row_code
    );
  }

  public UpdateUDFormulaCells(
    direct_premium,
    supplemental_premium,
    userdefined_premium,
    userdefined_premium_supplemental
  ) {
    this.premiumreportparams = new premiumreportParams(
      this.params.columns,
      this.params.rows,
      this.params.userdefined_premium_columns,
      this.params.const_statecode_column,
      this.params.userdefined_row_code,
      this.params.supplemental_row_code,
      this.params.const_fixed_columns,
      this.params.AssumedFromNonAffiliates_row_code
    );
    this.premiumreportservice.params = this.premiumreportparams;

    //updating formula totals for supplemental userdefined premium
    userdefined_premium_supplemental = this.updateColumnTotal(
      userdefined_premium_supplemental
    );
    userdefined_premium_supplemental = this.calculateRowTotal(
      userdefined_premium_supplemental,
      'S'
    );

    //updating formula totals for direct user defined premium
    userdefined_premium = this.updateSuppPremiumRow(
      userdefined_premium,
      userdefined_premium_supplemental
    );
    userdefined_premium = this.updateColumnTotal(userdefined_premium);
    userdefined_premium = this.calculateRowTotal(userdefined_premium, 'D');

    var updatedpremium = this.premiumreportservice.UpdateToPremiumReport(
      direct_premium,
      supplemental_premium,
      userdefined_premium,
      userdefined_premium_supplemental
    );
    direct_premium = updatedpremium[0];
    supplemental_premium = updatedpremium[1];
    return [
      direct_premium,
      supplemental_premium,
      userdefined_premium,
      userdefined_premium_supplemental,
    ];
  }

  //Updating supplemental premium info in direct premium in user defined data
  private updateSuppPremiumRow(
    userdefined_premium,
    userdefined_premium_supplemental
  ) {
    // if (this.premium_type == "D") {
    //Updating supplemental row in dpremium
    var dSuppRowIndex = userdefined_premium.findIndex(
      (x) =>
        x[this.params.const_statecode_column] ===
        this.params.supplemental_row_code
    );
    var spremium =
      userdefined_premium_supplemental[this.params.rows.length - 1];
    var dpremium = userdefined_premium[this.params.rows.length - 1];
    if (dSuppRowIndex != -1) {
      for (
        let index = this.params.const_fixed_columns;
        index < this.params.userdefined_premium_columns.length - 1;
        index++
      ) {
        //For Adjusting total of user defined direct premium
        var diff = spremium[index] - userdefined_premium[dSuppRowIndex][index];
        userdefined_premium[dSuppRowIndex][index] = spremium[index];
        dpremium[index] += diff;
      }
      //Updating supplemental row last cell to zero
      userdefined_premium[dSuppRowIndex][
        this.params.userdefined_premium_columns.length - 1
      ] = 0;
      //Update total
      var total = 0;
      for (var prop in dpremium) {
        if (
          parseInt(prop) > this.params.const_fixed_columns - 1 &&
          parseInt(prop) != this.params.userdefined_premium_columns.length - 1
        ) {
          total += dpremium[prop];
        }
      }
      dpremium[this.params.userdefined_premium_columns.length - 1] = total;
    }
    return userdefined_premium;
    // }
  }

  private updateColumnTotal(premiumData) {
    if (premiumData == null) {
      return false;
    }
    for (let index = 0; index < premiumData.length; index++) {
      premiumData[index] = this.calculateColumnwiseTotal(premiumData[index]);
    }
    return premiumData;
  }

  private calculateColumnwiseTotal(rowData) {
    for (var prop in rowData) {
      var columnInfo = this.params.userdefined_premium_columns.filter((p) => {
        return p.columnNumber == prop;
      });
      if (columnInfo != null && columnInfo.length > 0) {
        if (
          columnInfo[0].cellTypeId == 'C' &&
          columnInfo[0].cellformulae.length > 0
        ) {
          //Avoid calculating the total for user defined and supplemental premium for the last column
          if (
            columnInfo[0].columnNumber ==
              this.params.userdefined_premium_columns.length - 1 &&
            (rowData[this.params.const_statecode_column] ==
              this.params.userdefined_row_code ||
              rowData[this.params.const_statecode_column] ==
                this.params.supplemental_row_code)
          ) {
            rowData[prop] = null;
            continue;
          }
          if (columnInfo[0].cellformulae.indexOf(',') > 0) {
            var split = columnInfo[0].cellformulae.split(',');
            var total = 0;
            for (let index = 0; index < split.length; index++) {
              const element = split[index];
              total += this.getCellTotalRow(element, rowData);
            }
            rowData[prop] = total;
          } else {
            rowData[prop] = this.getCellTotalRow(
              columnInfo[0].cellformulae,
              rowData
            );
          }
        }
      }
    }
    return rowData;
  }

  private getCellTotalRow(cellformula, rowData) {
    var formula = cellformula.split(':');
    var columnVal = 0;
    for (
      let i = parseInt(formula[0]);
      i <= parseInt(formula[formula.length - 1]);
      i++
    ) {
      //columnVal += parseInt(rowData[i]);
      columnVal += rowData[i] == null ? 0 : parseInt(rowData[i]);
    }
    return columnVal;
  }

  private calculateRowTotal(Data, premium_type) {
    // var Data = this.grid1.data;
    for (let index = 0; index < Data.length; index++) {
      var rowdata = Data[index];
      var flag = this.IsTotalRowData(parseInt(rowdata[0]));
      if (flag) {
        if (
          rowdata[this.params.const_statecode_column] !=
          this.params.userdefined_row_code
        ) {
          if (
            rowdata[this.params.const_statecode_column] ==
              this.params.AssumedFromNonAffiliates_row_code ||
            rowdata[this.params.const_statecode_column] ==
              this.params.supplemental_row_code
          ) {
            for (var prop in rowdata) {
              if (
                parseInt(prop) >= this.params.const_fixed_columns &&
                // (this.columns[prop].cellTypeId == "D" || parseInt(prop) == this.columns.length - 1) &&
                (rowdata[prop] == null || rowdata[prop] == 0)
              ) {
                rowdata[prop] = null;
              }
            }
          } else {
            rowdata = this.resetTotal(rowdata);
            var formula = this.GetTotalRowFormula(parseInt(rowdata[0]));
            if (formula.indexOf(',') > 0) {
              var split = formula.split(',');
              for (let i = 0; i < split.length; i++) {
                const _formula = split[i];
                rowdata = this.getRowCellTotal(_formula, rowdata, Data);
              }
              if (index == Data.length - 1 && premium_type == 'D') {
                //need to add supplemental premium total to last row last cell
                rowdata = this.addSuppPremiumToLastRow(rowdata, Data);
              }
            } else {
              rowdata = this.getRowCellTotal(formula, rowdata, Data);
            }
          }
        }
      }
    }
    return Data;
  }

  private IsTotalRowData(rowNumber) {
    var rowInfo = this.params.rows.filter(function (p) {
      return p.rowNumber == parseInt(rowNumber);
    });
    return (rowInfo.length > 0 && rowInfo[0].cellTypeId == 'C') == true
      ? true
      : false;
  }

  private resetTotal(columnTotal) {
    //assign 0 to column total
    for (var prop in columnTotal) {
      var columnInfo = this.params.userdefined_premium_columns.filter((p) => {
        return p.columnNumber == prop;
      });
      if (columnInfo != null && columnInfo.length > 0) {
        // if (columnInfo[0].dataType == "number") {
        if (columnInfo[0].columnNumber >= this.params.const_fixed_columns) {
          columnTotal[prop] = 0;
        }
      }
    }
    return columnTotal;
  }

  private GetTotalRowFormula(rowNumber) {
    var rowInfo = this.params.rows.filter(function (p) {
      return p.rowNumber == parseInt(rowNumber);
    });
    return (rowInfo.length > 0 && rowInfo[0].cellTypeId == 'C') == true
      ? rowInfo[0].cellformulae
      : '';
  }

  private getRowCellTotal(rowFormula, element, Data) {
    var formulaSplit = rowFormula.split(':');
    var start = parseInt(formulaSplit[0]);
    var end = parseInt(formulaSplit[1]);
    for (var prop in element) {
      if (Object.prototype.hasOwnProperty.call(element, prop)) {
        if (parseInt(prop) >= this.params.const_fixed_columns) {
          //Avoiding fixed columns
          var values = Data.filter(function (a) {
            if (a[0] >= start && a[0] <= end) {
              return a;
            }
          });
          var total = this.getSum(values, prop);
          var value = parseInt(element[prop]);
          if (isNaN(value)) {
            value = 0;
          }
          element[prop] = value + total;
        }
      }
    }
    return element;
  }

  private addSuppPremiumToLastRow(rowdata, userdefined_premium) {
    var dSuppRowIndex = userdefined_premium.findIndex(
      (x) =>
        x[this.params.const_statecode_column] ===
        this.params.supplemental_row_code
    );
    var dSuppData = userdefined_premium[dSuppRowIndex];
    var suppTotal = 0;
    for (var prop in dSuppData) {
      if (parseInt(prop) > this.params.const_fixed_columns - 1) {
        var value = parseInt(dSuppData[prop]);
        if (isNaN(value)) {
          value = 0;
        }
        suppTotal += value;
      }
    }
    var oldValue = parseInt(
      rowdata[this.params.userdefined_premium_columns.length - 1]
    );
    if (isNaN(oldValue)) {
      oldValue = 0;
    }
    rowdata[this.params.userdefined_premium_columns.length - 1] =
      oldValue + suppTotal;
    return rowdata;
  }

  private getSum(array, prop) {
    var total = 0;
    for (var i = 0, _len = array.length; i < _len; i++) {
      var val = array[i][prop];
      total += val == null || val == undefined ? 0 : parseInt(val);
    }
    return total;
  }
}

export class userdefinedParams {
  public columns = [];
  public rows = [];
  public userdefined_premium_columns = [];
  public const_statecode_column: number;
  public userdefined_row_code: number;
  public supplemental_row_code: number;
  public AssumedFromNonAffiliates_row_code: number;
  public const_fixed_columns: number;

  constructor(
    columns = [],
    rows = [],
    userdefined_premium_columns = [],
    const_statecode_column,
    userdefined_row_code,
    supplemental_row_code,
    const_fixed_columns,
    AssumedFromNonAffiliates_row_code
  ) {
    this.columns = columns;
    this.rows = rows;
    this.userdefined_premium_columns = userdefined_premium_columns;
    this.const_statecode_column = const_statecode_column;
    this.userdefined_row_code = userdefined_row_code;
    this.supplemental_row_code = supplemental_row_code;
    this.AssumedFromNonAffiliates_row_code = AssumedFromNonAffiliates_row_code;
    this.const_fixed_columns = const_fixed_columns;
  }
}
