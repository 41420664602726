<div class="igx-grid-container" (clickOutside)="onClickedOutside($event)">
  <igx-grid
    igxPreventDocumentScroll
    #supplementalPremiumsGrid
    [data]="initialData"
    [autoGenerate]="false"
    height="90%"
    [rowEditable]="false"
    paste-handler
    (onDataProcessed)="updateRecords($event)"
    [primaryKey]="'0'"
    [clipboardOptions]="premiumReportService.getClipboardOptions"
    (cellEditEnter)="editStart($event)"
    (cellEditDone)="onEditCompleted($event)"
    (selected)="onCellSelect($event)"
    [emptyGridMessage]="'.'"
    (gridKeydown)="enterGridEditMode($event)"
    (keydown)="enterEditMode($event)"
    [cellSelection]="'multiple'"
  >
    <igx-column
      *ngFor="let c of columns"
      [field]="c.columnNumber.toString()"
      [header]="c.columnName"
      [pinned]="c.IsPinned"
      [cellClasses]="cellClass"
      [editable]="isReadOnly == true ? false : c.editable"
      [width]="getColWidth(c.columnNumber)"
      [dataType]="c.dataType"
      [resizable]="true"
      [hidden] = "c.hidden"
    >
      <ng-template igxHeader let-column>
        <div>
          {{ column.header }}
        </div>
      </ng-template>
      <ng-template igxCellEditor let-cell="cell">
        <igx-input-group>
          <input
            igxInput
            [igxFocus]="true"
            (focus)="onCellFocus($event)"
            [(ngModel)]="cell.editValue"
            style="color: black"
            type="number"
            [ngClass]="{ 'no-spinners': true }"
            id="input"
          />
        </igx-input-group>
      </ng-template>
    </igx-column>
  </igx-grid>
  <div id="direct" style="width: 100%"></div>
  <div id="supplemental"></div>
</div>
