import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CompanyService } from '../company.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CompanyInfoModel } from '../commpany.model';
import { ToastService } from '../../shared/toast.service'
import { UnsavedmsgModalComponent } from '../../unsavedmsg-modal/unsavedmsg-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AppService } from '../../app.service'
import { AlertmodalComponent } from '../../alertmodal/alertmodal.component'
import { ConfirmDialogService } from '../../confirm-dialog/confirm-dialog.service';
import { AdobelaunchService } from '../../_services/adobelaunch.service';
import { combineLatest } from 'rxjs';
import { isLockForValidationSelector, isPrfLocked } from '../premiumreport/data-access/premium-report.selectors';
import { Store } from '@ngrx/store';
import { US_ZIP_PATTERN, USPhoneValidator } from './patterns';
import { PopulateaddressComponent } from './populateaddress/populateaddress.component';
import { RenameconfirmComponent } from './renameconfirm/renameconfirm.component';
@Component({
  selector: 'app-companyinformation',
  templateUrl: './companyinformation.component.html',
  styleUrls: ['./companyinformation.component.scss'],
})
export class CompanyinformationComponent implements OnInit, AfterViewInit {
  companyinformationForm: UntypedFormGroup;
  companyLoaded: Promise<boolean>;
  statesLoaded: Promise<boolean>;
  compno: any;
  groupcode: any
  currentYear: number;
  prfNumber: string;
  prfName: string;
  companyInfo: CompanyInfoModel = new CompanyInfoModel();
  unsavedData: boolean = false;
  display: string = "none";
  isvalidbillno: boolean = true;
  showPageError: boolean = false;
  messagePageError: string = ""
  compnoPageError: string = ""
  states: any[] = [];
  spinnergetcompinfo: boolean = true;
  spinnersavecompinfo: boolean;
  selectedState: any;
  ViewselectedState: any;
  isNewForm: boolean = true;
  formCleard: boolean = false;
  selectedIndex: number;
  validPhone: boolean = false;
  isLocked: boolean;
  isReadOnly: boolean;
  isReadOnlyAdmin: boolean;
  hasStatrtedWithGroup: boolean = true;
  isAdmin: boolean
  billingNumberChanged: boolean
  spinnerrenamecompany: boolean
  validnaic: boolean = true;
  validambest: boolean = true;
  validcode: boolean = true;
  renamecompleted: boolean = true;
  unConfirmedData: boolean = false;
  prfstatus: string;
  renameComment: string = '';


  constructor(private confirmDialogService: ConfirmDialogService, private appService: AppService, private router: Router, private companyService: CompanyService, public toastService: ToastService, private modalService: NgbModal,private AdobelaunchService:AdobelaunchService, private store: Store) {

  }
  ngAfterViewInit() {
    this.AdobelaunchService.EventEndDispatch();
    }


  ngOnInit(): void {
    // console.log('info loading')
    this.removePrevAlerts();
    this.isAdmin = this.appService.isAdmin;

    this.compno = this.companyService.companyId;
    this.currentYear = this.companyService.currentYear;
    this.prfNumber = this.companyService.prfNumber;
    this.prfName = this.companyService.prfName;
    this.isLocked = this.companyService.isEditLocked;
    this.isReadOnlyAdmin = this.appService.isReadonlyAdmin;
    this.prfstatus = localStorage.getItem('prfStatus');

    combineLatest([
      this.store.select(isLockForValidationSelector),
      this.store.select(isPrfLocked),
    ]).subscribe(([isValidationLocked, prfLocked]) => {
      console.log(
        'updated',
        isValidationLocked,
        localStorage.getItem('isLocked') == 'true'
      );

      this.isLocked = prfLocked || isValidationLocked;
      this.isReadOnly = this.isLocked || this.appService.isReadonlyAdmin;
      console.log(this.isLocked);
    });
    this.companyinformationForm = new UntypedFormGroup({
      compname: new UntypedFormControl('', Validators.required),
      preparer: new UntypedFormControl('', Validators.required),
      billingno: new UntypedFormControl('', Validators.required),
      //reportofficer: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]),
      reportofficer: new UntypedFormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$")]),
      additionalemail: new UntypedFormControl('', Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$")),
      title: new UntypedFormControl('', Validators.required),
      address: new UntypedFormControl('', Validators.required),
      city: new UntypedFormControl('', Validators.required),
      //state: new FormControl('', Validators.required),
      zipcode: new UntypedFormControl('', [Validators.required, Validators.pattern(US_ZIP_PATTERN)]),
      phone: new UntypedFormControl('', [Validators.required, Validators.required, Validators.minLength(7), Validators.maxLength(14),USPhoneValidator()]),
      groupreport: new UntypedFormControl(''),
      ambest: new UntypedFormControl(''),
      naic: new UntypedFormControl(''),

    });
    this.companyService.getgroup().subscribe((value) => {
      this.groupcode = value;
      if (this.compno == '')
        this.companyinformationForm.get('billingno').setValue(value);
    });

    this.companyinformationForm.valueChanges.subscribe(
      (value) => {
        this.unsavedData = true;
      }
    );
    if (this.compno == '') {
      this.companyinformationForm.get('billingno').setValue(this.groupcode);
      this.companyService.getprfstates().subscribe({
        next: (resp) => {
          if (resp["message"] == "Success") {
            this.states = resp["comment"]
            this.states.unshift({ code: '00', name: 'Select' });
            this.selectedState = this.states[0].name;
            this.companyLoaded = Promise.resolve(true)
            this.statesLoaded = Promise.resolve(true);

            if (this.isNewForm == true) {
              this.spinnergetcompinfo = false;
            }
          }
        },
        error: (error) => {
          //console.log(error);
          this.router.navigate(['/apifailure']);
        }
      });


      this.statesLoaded = Promise.resolve(true);
      this.companyLoaded = Promise.resolve(true)
      this.display = "block"
    }

    if (this.prfstatus === 'notStarted') {
      this.unConfirmedData = true;
      this.loadCompanyInfo();
    } else if (this.compno) {
      this.getPrgress();
      this.loadCompanyInfo();
    } else {
      this.companyService.setCompanyInformation('inprogress');
    }

  }
  loadCompanyInfo(){
    this.companyService.getprfstates().subscribe({
      next: (resp) => {
        if (resp["message"] == "Success") {
          this.states = resp["comment"];
          if (this.states.length > 0) {
            this.selectedState = this.states[0].name;
            this.companyService.loadCompanyInformation(this.currentYear, this.compno, this.groupcode).subscribe({
              next: (resp) => {
                this.isNewForm = false;
                if (resp["message"] == "Success") {
                  this.companyInfo = resp["companydata"];
                  this.companyService.grouponoff = this.companyInfo.groupreport;
                  localStorage.setItem("groupreportenabled", this.companyInfo.groupreport);
                  this.companyService.getLocalStorage();
                  this.companyinformationForm = new UntypedFormGroup({
                    compname: new UntypedFormControl(this.companyInfo.compname),
                    preparer: new UntypedFormControl(this.companyInfo.preparer, Validators.required),
                    billingno: new UntypedFormControl(this.companyInfo.billingno),
                    reportofficer: new UntypedFormControl(this.companyInfo.reportofficer, [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$")]),
                    additionalemail: new UntypedFormControl(this.companyInfo.additionalemail, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$")),
                    title: new UntypedFormControl(this.companyInfo.title, Validators.required),
                    address: new UntypedFormControl(this.companyInfo.address, Validators.required),
                    city: new UntypedFormControl(this.companyInfo.city, Validators.required),
                    //state: new FormControl(this.companyInfo.state, Validators.required),
                    zipcode: new UntypedFormControl(this.companyInfo.zipcode, [Validators.required, Validators.pattern("^\\d{5}(-\\d{4})?$")]),
                    phone: new UntypedFormControl(this.companyInfo.phone, [Validators.required, Validators.minLength(7), Validators.maxLength(14)]),
                    groupreport: new UntypedFormControl(this.companyInfo.groupreport),
                    ambest: new UntypedFormControl(this.companyInfo.ambest),
                    naic: new UntypedFormControl(this.companyInfo.naic),
                  });
                  //this.markReadOnly();
                  if (this.companyInfo !== undefined && this.companyInfo !== null) {
                    this.selectedState = this.companyInfo.state;
                    this.ViewselectedState = this.companyInfo.state;
                    if (this.states.filter(item => item.name == this.companyInfo.state)[0] !== undefined) {
                      this.selectedIndex = this.states.indexOf(this.states.filter(item => item.name === this.companyInfo.state)[0]);
                    } else {
                      this.selectedIndex = null;
                      this.states.unshift({ code: '00', name: 'Select' });
                      this.selectedState = this.states[0].name;
                    }
                    this.statesLoaded = Promise.resolve(true);
                    this.companyLoaded = Promise.resolve(true);
                    this.spinnergetcompinfo = false;
                  }
                  this.companyLoaded = Promise.resolve(true);
                  this.display = "block";
                  this.companyinformationForm.valueChanges.subscribe((value) => {
                    this.isNewForm = false;
                    this.unsavedData = true;
                  });
                }
              },
              error: (error) => {
                //console.log(error);
                this.router.navigate(['/apifailure']);
              }
            });
          }
          if (this.isNewForm == true) {
            this.spinnergetcompinfo = false;
          }
        }
      },
      error: (error) => {
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    });
  }
  clearbillingerror() {
    this.isvalidbillno = true;
  }
  console(e) {
    // console.log(e);
  }
  setState(state: any) {
    this.selectedState = state.value;
    this.unsavedData = true;

  }
  doSomething(e: any) {
    // this.companyinformationForm.controls["reset"].enable({emitEvent:false});
  }
  validateBillNumber(billno: any): boolean {
    if (!billno.target.value.startsWith(this.groupcode)) {
      this.hasStatrtedWithGroup = false;
    }
    else {
      this.hasStatrtedWithGroup = true;
      this.isvalidbillno = true;
    }

    if (billno.target.value.length > 6) {
      this.isvalidbillno = false;
    } else if (billno.target.value.length > 4) {

      if (billno.target.value.substring(4).match(/^[a-zA-Z]+$/)) {
        this.isvalidbillno = true;
      } else {
        this.isvalidbillno = false;
      }
    }
    if (this.isvalidbillno) {
      this.billingNumberChanged = true;
    }
    return true;
  }

  getPrgress() {

    this.companyService.getCustomerPrfProgress(this.currentYear, this.compno, this.groupcode).subscribe({
      next: (resp) => {
        if (resp["message"] == "Success") {
          // this.companyService.disablePrf.next(false)
        }
      },
      error: (error) => {
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    });


  }
  onsubmit() {
    if (this.unsavedData || this.unConfirmedData) {
      this.toastService.remove(this.toastService.toasts[0]);
      this.companyInfo = Object.assign(this.companyInfo, this.companyinformationForm.value);
      this.companyInfo.billingno = this.companyInfo.billingno.toUpperCase();
      if (this.isAdmin && this.compno !== '' &&
        (this.companyInfo.billingno.toUpperCase() !== this.compno.toUpperCase())) {
          this.openRenameConfirmModal();
      } else {
        this.save(false);
      }
    }
  }

  save(rename: boolean) {

    this.spinnersavecompinfo = true;
    var mode = 'Update'
    if (this.compno == '') {
      mode = 'Add'
    }
    var newcompno = ''
    var newcompname = ''
    if (rename) {
      newcompno = this.companyInfo.billingno;
      newcompname = this.companyInfo.compname;
      this.companyInfo.compname = this.prfName;
      this.companyInfo.billingno = this.compno;
    }
    this.companyInfo.state = this.selectedState;
    this.companyService.clearStatus();
    this.companyService.disableCompanyList.next(true)
    this.companyService.saveCompanyInformation(this.currentYear, mode, this.companyInfo, this.groupcode, this.unConfirmedData).subscribe({
      next: (resp) => {
        if (resp["message"] == "Success") {
          window.scrollTo(0, 0);      //move view window to top
          const messages = ["Company already exist", "Group Company already exist", "Stand-alone company already exist", "The company is merged"];

          if (messages.includes(resp["status_msg"])) {
            //this.toastService.show(resp["status_msg"], { classname: 'bg-danger text-light', delay: 3000 });
            this.companyinformationForm.setErrors({ 'invalid': true })
            this.showPageError = true;  //show page level error msg
            this.messagePageError = resp["status_msg"];
            this.compnoPageError = this.companyInfo.billingno;
            this.spinnersavecompinfo = false;
            this.companyService.disableCompanyList.next(false)


          } else {
            this.companyinformationForm.setErrors(null);
            this.showPageError = false;
            this.spinnersavecompinfo = false;
            if (localStorage.getItem("isCreateComapny") !== 'true') {
              this.compno = this.companyInfo.billingno;
              localStorage.setItem("companyId", this.compno);
              localStorage.setItem("prfNumber", this.compno.toUpperCase());
              localStorage.setItem("groupreportenabled", this.companyInfo.groupreport);
              localStorage.setItem("prfName", this.companyService.toTitleCase(this.companyInfo.compname));
              this.companyService.getLocalStorage();
              this.companyService.disableCompanyList.next(false)
              this.prfNumber = this.compno.toUpperCase();
              this.prfName = this.companyService.toTitleCase(this.companyInfo.compname);

            } 
            if (!rename) {
              this.toastService.show(resp["status_msg"], { classname: 'bg-success text-light', delay: 3000 });
              window.scrollTo(0, 0);
            }
            this.unsavedData = false;
            this.unConfirmedData = false;
            localStorage.setItem("prfStatus",'');

            this.isNewForm = false;
            if (localStorage.getItem("isCreateComapny") !== 'true') {
              this.getPrgress();
              this.companyService.disableCompanyList.next(false)
            }
            if (!rename && this.companyInfo.groupreport && localStorage.getItem("isCreateComapny") == 'false') {
              this.confirmDialogService.confirmThis('Company Information', 'Do you want to navigate to group reporting?', () => {
                  this.router.navigate(['/groupreporting'])
              }, () => {
                this.getPrgress();
              });

            }

            if (rename) {
              this.spinnerrenamecompany = true;
              this.companyService.renamecompany(this.currentYear, this.groupcode, this.compno, newcompno,
                this.prfName, newcompname, this.renameComment).subscribe({
                  next: (resp) => {
                    const messages = ["Company already exist", "Group Company already exist", "Stand-alone company already exist", "The company is merged"];

                    if (resp["message"] == "Success") {
                      if (messages.includes(resp["status_msg"])) {
                        this.companyinformationForm.setErrors({ 'invalid': true })
                        this.showPageError = true;  //show page level error msg
                        this.messagePageError = resp["status_msg"];
                        this.compnoPageError = newcompno;
                        this.spinnersavecompinfo = false;
                        this.spinnerrenamecompany = false;

                      } else {
                        var message = "Company Number " + this.prfNumber + " was successfully renamed in the PRF File to " + newcompno + "."
                        this.toastService.show(message, { classname: 'bg-success text-light', delay: 3000 });
                        this.compno = newcompno;
                        this.companyInfo.billingno = newcompno;
                        localStorage.setItem("companyId", newcompno);
                        localStorage.setItem("prfNumber", newcompno.toUpperCase());
                        localStorage.setItem("groupreportenabled", this.companyInfo.groupreport);
                        localStorage.setItem("prfName", this.companyService.toTitleCase(newcompname));
                        this.companyService.getLocalStorage();

                        this.prfNumber = newcompno.toUpperCase();
                        this.prfName = this.companyService.toTitleCase(newcompname);
                        this.spinnerrenamecompany = false;

                        if (this.companyInfo.groupreport) {
                          this.confirmDialogService.confirmThis('Company Information', 'Do you want to navigate to group reporting?', () => {
                            this.router.navigate(['/groupreporting'])
                          }, () => {
                            this.getPrgress();
                          });


                        }
                      }
                      this.renamecompleted = true;
                    }

                  },
                  error: (error) => {
                    //console.log(error);
                    this.router.navigate(['/apifailure']);
                  }
                })
            }
          }


          this.companyService.loadPremiumData();
        }
      },
      error: (error) => {
        window.scrollTo(0, 0);
        this.unsavedData = false;
        this.unConfirmedData = false;
        localStorage.setItem("prfStatus",'');
        this.router.navigate(['/apifailure']);
      }
    })
  }
  hasUnsavedData() {
    return this.unsavedData;
  }
  isFormValid() {
    let isvalid = false;
    if (this.companyinformationForm.valid && this.hasStatrtedWithGroup && this.isvalidbillno)
      isvalid = true;

    return isvalid;
  }




  clear() {
    this.formCleard = true;
    this.isNewForm = true;
    this.unsavedData = false;
    this.companyinformationForm.reset();  //reset component, clear all
    this.companyinformationForm.patchValue(this.companyInfo);

  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  openPageChangeModal() {
    const modalRef = this.modalService.open(UnsavedmsgModalComponent);
    modalRef.componentInstance.name = 'New';

    modalRef.result.then((result) => {
      if (result) {

        this.onsubmit();

      }
    });

  }

  openAlertModal() {
    const modalRef = this.modalService.open(AlertmodalComponent);
    modalRef.componentInstance.title = 'Validation Errors';
    modalRef.componentInstance.message = 'Please fix the validation errors before leaving the page.';
  }

  focusout(e) {

    if (e.target.value == '') {
      this.validPhone = false;
    } else {
      this.validPhone = /^\(?[2-9]\d{2}[\)\.-]?\s?\d{3}[\s\.-]?\d{4}$/i.test(e.target.value)
      if (!this.validPhone)
        this.companyinformationForm.get('phone').setErrors({ 'incorrect': true })
      else
        this.companyinformationForm.get('phone').setErrors(null);
    }
  }
  handleGroupReportChange(e) {
    this.companyinformationForm.controls['ambest'].setValue('')
    this.companyinformationForm.controls['naic'].setValue('')
    this.validambest = true;
    this.validnaic = true;
  }
  markReadOnly() {
    if (this.isReadOnly) {
      Object.keys(this.companyinformationForm.controls).forEach(key => {
        this.companyinformationForm.get(key).disable({ onlySelf: true });
        //this.companyinformationForm.get(key).setAttribute('readonly');

      });


    }
  }
  validateNaic(e) {
    //this.validambest = true;
    if (e.target.value == '' || /^\d+$/.test(e.target.value)) {
      this.validnaic = true;
      this.validcode = this.validambest && this.validnaic;
    }

    else {
      this.validnaic = false;
      this.validcode = false;
    }
  }
  validateAMBest(e) {
    //this.validnaic = true;
    if (e.target.value == '' || /^\d+$/.test(e.target.value)) {
      this.validambest = true;
      this.validcode = this.validambest && this.validnaic;
    }

    else {
      this.validambest = false;
      this.validcode = false;
    }
  }

  removePrevAlerts(){
    for (var i = this.toastService.toasts.length-1; i >= 0; i--) {
      this.toastService.remove(this.toastService.toasts[i]);
    }
  }

  openHelpDoc() {
    this.companyService.getTemplateFile("ScreenHelp.pdf").subscribe({
      next: (resp) => {
        if (resp["message"] == "Success") {
          let fileurl = resp["templateurl"];
          window.open(fileurl, "_blank");
        }
      },
      error: (error) => {
        this.router.navigate(['/apifailure']);
      }
    });
  }

  setCompanyInformation(addressinfo) {
    this.companyinformationForm.patchValue({
      compname: addressinfo.companyname,
      billingno: addressinfo.companyno,
      address: addressinfo.address,
      city: addressinfo.city,
      zipcode: addressinfo.zipcode
    });

    if (addressinfo.state) {
      this.companyLoaded = Promise.resolve(false);
    
      this.selectedState = addressinfo.state;
      this.ViewselectedState = addressinfo.state;

      const matchingState = this.states.find(item => item.name === addressinfo.state);
      this.selectedIndex = matchingState ? this.states.indexOf(matchingState) : 0;

      this.companyLoaded = Promise.resolve(true);
    }
    this.toastService.reset();
    this.toastService.show('The company address has been populated.', { classname: 'bg-success text-light', delay: 3000 });
    setTimeout(() => {
      this.toastService.reset();
    }, 3000);
  }

  openPopulateModal() {
    const modalRef = this.modalService.open(PopulateaddressComponent);
    modalRef.componentInstance.title = 'Populate Address';
    const companyNo = this.companyinformationForm.get('billingno').value;
    console.log('companyNo', companyNo);
    modalRef.componentInstance.companyNo = companyNo;
    modalRef.componentInstance.addressInfo.subscribe((addressinfo) => {
      this.setCompanyInformation(addressinfo);
    });
  }
  openRenameConfirmModal() {
    const modalRef = this.modalService.open(RenameconfirmComponent);
    modalRef.componentInstance.title = 'Company Information';
    modalRef.componentInstance.message =
      'Do you want to rename the company - ' + this.prfNumber + '?';

    modalRef.result.then(
      (result) => {
        console.log('result', result);
        if (result.commentSubmitted && result.renameConfirmed) {
          this.renameComment = result.commentSubmitted;
          this.renamecompleted = false;
          this.save(true);
        }
      },
      () => {
        this.companyInfo.billingno = this.compno;
        this.companyinformationForm.patchValue(this.companyInfo);
        if (this.unConfirmedData) {
          this.save(false);
        }
      }
    );
  }

}




