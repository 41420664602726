import { createReducer, on } from '@ngrx/store';
import * as companyActions from './admin-company-list-actions';
import {
  AdminCompanyListState,
  Companylisttable,
  ListTypeList,
  listTypeListData,
  statusList,
} from './admin-company-list-model';

export const initialState: AdminCompanyListState = {
  companyList: [],
  originalCompanyList: [],
  isReadOnlyAdmin: false,
  yearsList: [],
  currentYear: null,
  companyId: '',
  groupCode: '',
  prfNumber: '',
  prfName: '',
  showCompanyLoader: false,
  searchType: '',
  searchValue: '',
  currentPrfYear: null,
  currentYearAdmin: '',
  yearsLoaded: false,
  filterStatusList: statusList,
  filterStatusSelected: 'All',
  filterStatusSelectedIndex: '0',
  filterStatusSelectedLabel: 'All',
  showPageError: false,
  pageError: '',
  filterListType: [
    { value: 'mylist', label: 'My List' },
    { value: 'all', label: 'All' },
  ],
  listTypeSelected: listTypeListData[0],
  allSelected: false,
  deleteLoader: false,
  selectedQuarter: 1,
  corpId: 1,
  showUploadLoader: false,
  selectedYear: null,
  showNoDataMessage: false,
};

const onLoadPrfYearsSuccess = (
  state: AdminCompanyListState,
  {
    yearsList,
  }: {
    yearsList: any[];
  }
) => ({
  ...state,
  yearsList: yearsList,
  currentYear: state.selectedYear != null ? state.selectedYear : yearsList[0],
  currentPrfYear: yearsList[0],
  yearsLoaded: true,
  selectedYear: state.selectedYear != null ? state.selectedYear : yearsList[0],
});

const filterCompanyListByStatus = (
  companyList: Companylisttable[],
  filterStatusSelected: string
): Companylisttable[] => {
  return filterStatusSelected !== 'All'
    ? companyList.filter((company) => company.status === filterStatusSelected)
    : companyList;
};

const handleCompanyListSuccess = (
  state: AdminCompanyListState,
  {
    companyList,
    filterStatusSelected,
  }: {
    companyList: Companylisttable[];
    filterStatusSelected: string;
  }
): AdminCompanyListState => {
  const filteredCompanyList = filterCompanyListByStatus(
    companyList,
    filterStatusSelected
  );
  

  // Iterate over filteredCompanyList and update selectDisabled
  const updatedCompanyList = filteredCompanyList.map((newCompany) => {
    const existingCompany = state.companyList.find(
      (company) => company.compno === newCompany.compno
    );

    return {
      ...newCompany,
      prfyear: parseInt(newCompany.prfyear.toString()), // Applying parseInt() to prfyear
      selectDisabled: !(
        newCompany.status === 'Submitted' || newCompany.status === 'Uploaded'
      ),
      isSelected: existingCompany ? existingCompany.isSelected : false,
    };
  });

  const hasResults: boolean = updatedCompanyList.length > 0;

  return {
    ...state,
    companyList: updatedCompanyList,
    originalCompanyList: companyList,
    showCompanyLoader: false,
    showUploadLoader: false,
    showNoDataMessage: !hasResults,
    showPageError: false,
  };

};

const onLoadCompaniesByYearSuccess = (
  state: AdminCompanyListState,
  params: { companyList: Companylisttable[]; filterStatusSelected: string }
): AdminCompanyListState => handleCompanyListSuccess(state, params);

const onLoadSubmittedCompaniesSuccess = (
  state: AdminCompanyListState,
  params: { companyList: Companylisttable[]; filterStatusSelected: string }
): AdminCompanyListState => handleCompanyListSuccess(state, params);

const onLoadAdminHomeCompanyListSuccess = (
  state: AdminCompanyListState,
  params: { companyList: Companylisttable[]; filterStatusSelected: string }
): AdminCompanyListState => handleCompanyListSuccess(state, params);

const onLoadSearchCompanySuccess = (
  state: AdminCompanyListState,
  params: { companyList: Companylisttable[]; filterStatusSelected: string }
): AdminCompanyListState => handleCompanyListSuccess(state, params);

const onSetListType = (
  state: AdminCompanyListState,
  {
    listTypeSelected,
  }: {
    listTypeSelected: ListTypeList;
  }
) => ({
  ...state,
  listTypeSelected: listTypeSelected,
  showCompanyLoader: true,
});

const onSetCurrentYear = (
  state: AdminCompanyListState,
  {
    currentYear,
  }: {
    currentYear: number;
  }
) => ({
  ...state,
  currentYear: currentYear,
  selectedYear: currentYear
});

const onSetFilterStatusSelected = (
  state: AdminCompanyListState,
  {
    filterStatusSelected,
    index,
    filterStatusSelectedLabel,
  }: {
    filterStatusSelected: string;
    index: string;
    filterStatusSelectedLabel: string;
  }
) => ({
  ...state,
  filterStatusSelected: filterStatusSelected,
  filterStatusSelectedIndex: index,
  showCompanyLoader: true,
  filterStatusSelectedLabel: filterStatusSelectedLabel,
});

const onResetFilters = (state: AdminCompanyListState) => ({
  ...state,
  listTypeSelected: initialState.listTypeSelected,
});

const onSetFilterStatusList = (
  state: AdminCompanyListState,
  {
    filterStatusList,
  }: {
    filterStatusList: string[];
  }
) => ({
  ...state,
  filterStatusList: filterStatusList,
});

const onLoadCompaniesByYear = (
  state: AdminCompanyListState,
  {
    currentYear,
  }: {
    currentYear: number;
  }
) => ({
  ...state,
  currentYear: currentYear,
  selectedYear: currentYear,
  searchValue: '',
  searchType: '',
  showCompanyLoader: true,
});

const onSetSearchValue = (
  state: AdminCompanyListState,
  {
    searchValue,
  }: {
    searchValue: string;
  }
) => {
  let searchType: string = '';

  if (searchValue.length === 4 && /^\d+$/.test(searchValue)) {
    searchType = 'group';
  } else if (
    searchValue.length > 4 &&
    /^[a-z0-9]+$/i.test(searchValue) &&
    /^\d+$/.test(searchValue.substring(0, 4))
  ) {
    searchType = 'companyno';
  }

  return {
    ...state,
    searchValue: searchValue,
    searchType: searchType,
    showCompanyLoader: true,
  };
};

const onSetSearchTypeError = (state: AdminCompanyListState) => ({
  ...state,
  showCompanyLoader: false,
  showPageError: true,
  pageError:
    'Invalid search criteria. Search with either Group Code or PRF Number',
});

const onSetFilterListTypes = (
  state: AdminCompanyListState,
  {
    filterListTypes,
  }: {
    filterListTypes: ListTypeList[];
  }
) => ({
  ...state,
  filterListType: filterListTypes,
});

const onSelectAll = (
  state: AdminCompanyListState,
  {
    checked,
  }: {
    checked: boolean;
  }
) => {
  const updatedCompanyList = state.companyList.map((company) => {
    if (['Submitted', 'Uploaded'].includes(company.status)) {
      return { ...company, isSelected: checked };
    }
    return company;
  });

  return {
    ...state,
    allSelected: checked,
    companyList: updatedCompanyList,
  };
};

const onToggleSelect = (
  state: AdminCompanyListState,
  { companyId }: { companyId: string } // Replace with the actual type of companyId
) => {
  const updatedCompanyList = state.companyList.map((company) => {
    if (
      company.compno === companyId &&
      ['Submitted', 'Uploaded'].includes(company.status)
    ) {
      return { ...company, isSelected: !company.isSelected };
    }
    return company;
  });

  return {
    ...state,
    companyList: updatedCompanyList,
  };
};
const OnDeleteCompany = (state: AdminCompanyListState) => ({
  ...state,
  deleteLoader: true,
});

const OnDeleteCompanySuccess = (state: AdminCompanyListState) => ({
  ...state,
  deleteLoader: false,
});

const OnDeleteCompanyFailure = (state: AdminCompanyListState) => ({
  ...state,
  deleteLoader: false,
});

const onLoadPrfYear = (state: AdminCompanyListState) => ({
  ...state,
  showCompanyLoader: true,
});
const onUploadSelectedCompanies = (state: AdminCompanyListState) => ({
  ...state,
  showUploadLoader: true,
});
const onUploadSelectedCompaniesSuccess = (state: AdminCompanyListState) => {
  const selectedCompanies = state.companyList.filter(
    (company) => company.isSelected
  );
  const updatedCompanies = selectedCompanies.map((company) => ({
    ...company,
    status: 'Uploading',
    isSelected: false,
  }));

  // Filter originalCompanyList to include only companies with compno present in selectedCompanies
  const updatedOCompanies = state.originalCompanyList
    .filter((company) =>
      selectedCompanies.some(
        (selectedCompany) => selectedCompany.compno === company.compno
      )
    )
    .map((company) => ({ ...company, status: 'Uploading', isSelected: false }));

  return {
    ...state,
    companyList: updatedCompanies,
    originalCompanyList: updatedOCompanies,
    showUploadLoader: false,
    filterStatusSelected: 'Uploading',
    filterStatusSelectedIndex: '4',
    filterStatusSelectedLabel: 'Uploading',
    listTypeSelected: { value: 'all', label: 'All', option: 'All' },
  };
};

const onSetIsReadonlyAdmin = (
  state: AdminCompanyListState,
  { value }: { value: boolean }
) => ({
  ...state,
  isReadOnlyAdmin: value,
  filterStatusSelected: 'All',
  filterStatusSelectedLabel: 'All',
  listTypeSelected: { value: 'all', label: 'All', option: 'All' },
});

export const adminCompanyListReducer = createReducer(
  initialState,
  on(companyActions.loadPrfYearsSuccess, onLoadPrfYearsSuccess),
  on(companyActions.loadCompaniesByYearSuccess, onLoadCompaniesByYearSuccess),
  on(
    companyActions.loadSubmittedCompaniesSuccess,
    onLoadSubmittedCompaniesSuccess
  ),
  on(
    companyActions.loadAdminHomeCompanyListSuccess,
    onLoadAdminHomeCompanyListSuccess
  ),
  on(companyActions.setListType, onSetListType),
  on(companyActions.setCurrentYear, onSetCurrentYear),
  on(companyActions.setFilterStatusSelected, onSetFilterStatusSelected),
  on(companyActions.setFilterStatusList, onSetFilterStatusList),
  on(companyActions.loadCompaniesByYear, onLoadCompaniesByYear),
  on(companyActions.setSearchValue, onSetSearchValue),
  on(companyActions.setSearchTypeFailure, onSetSearchTypeError),
  on(companyActions.loadSearchCompanySuccess, onLoadSearchCompanySuccess),
  on(companyActions.setFilterListTypes, onSetFilterListTypes),
  on(companyActions.resetFilters, onResetFilters),
  on(companyActions.toggleSelectAll, onSelectAll),
  on(companyActions.toggleSelect, onToggleSelect),
  on(companyActions.deleteCompany, OnDeleteCompany),
  on(companyActions.deleteCompanySuccess, OnDeleteCompanySuccess),
  on(companyActions.deleteCompanyFailure, OnDeleteCompanyFailure),
  on(companyActions.loadPrfYears, onLoadPrfYear),
  on(companyActions.uploadSelectedCompanies, onUploadSelectedCompanies),
  on(
    companyActions.uploadSelectedCompaniesSuccess,
    onUploadSelectedCompaniesSuccess
  ),
  on(companyActions.setIsReadonlyAdmin, onSetIsReadonlyAdmin),
);
