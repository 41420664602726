
export const  getHiddenPremiumCount = (premium, columns, states) => {
    const premiumRows = premium.premiumGridData;
    return {
        'rows': findHiddenRowsCount(premiumRows, states),
        'columns': findHiddenColumnsCount(premiumRows, columns)
    }
}
   
const findHiddenRowsCount = (premium, states) => {
    const hiddenPremiumRows = premium.filter((row: { [x: string]: any }) => {
      const matchingValue = states.find(
        (value: { name: any }) => value.name === row['1']
      );
      return matchingValue && !matchingValue.selected;
    });

    const hasNonZeroOrNullValue = (obj) => Object.values(obj).slice(3).some(value => value !== null && value !== 0);
    const filteredArray = hiddenPremiumRows.filter(obj => hasNonZeroOrNullValue(obj));
    return filteredArray.length
  }

const  findHiddenColumnsCount = (premium, columns) => {
    const indicesWithValuesSet: Set<number> = new Set();
    premium.map((item, index) => {
      const [, , , ...remainingItems] = Object.values(item);

      remainingItems.pop();
      const allValuesAreNullOrZero = remainingItems.every(
        (value) => value === 0 || value === null || value === ''
      );

      if (!allValuesAreNullOrZero) {
        remainingItems.forEach((value, i) => {
          if (value !== 0 && value !== null && value !== '') {
            indicesWithValuesSet.add(i);
          }
        });
      }
    });

    const indicesWithValuesArray = Array.from(indicesWithValuesSet);
    const filteredColumns = columns.filter((column, index) => indicesWithValuesArray.includes(index) && !column.selected)
    return filteredColumns.length;
}

export const cleanPremiumData = (data, column_names) => {
  const columns = Object.keys(data[0]);

  const columnsToKeep = columns.filter((col, index) => {
    return data.some(row => row[col] !== null && row[col] !== 0);
  });

  const indicesToKeep = columnsToKeep.map(col => columns.indexOf(col));

  // Create a new table with only the columns to keep and reorder keys
  const cleanedData = data.map(row => {
    const newRow = {};
    columnsToKeep.forEach((col, newIndex) => {
      newRow[newIndex] = row[col];
    });
    return newRow;
  });

  const filteredData = cleanedData.filter(item => {
    // Check if any value from index 3 onward is not null and not 0
    for (let key in item) {
      if (parseInt(key) >= 3 && item[key] !== null && item[key] !== 0) {
        return true;
      }
    }
    return false;
  });

  // Filter the column_names array based on the indices to keep
  const updatedColumnNames = column_names.filter((col, index) => indicesToKeep.includes(index));

  return { cleanedData, filteredData, updatedColumnNames };
}
