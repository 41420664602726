import { Component, OnInit,Input, AfterViewInit } from '@angular/core';
import { CompanyService } from '../company.service';
import { FormGroup, FormControl,FormBuilder, Validators } from '@angular/forms';
import {ToastService} from '../../shared/toast.service'
import {AppService} from '../../app.service'
import {UnsavedmsgModalComponent} from '../../unsavedmsg-modal/unsavedmsg-modal.component';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AdobelaunchService } from '../../_services/adobelaunch.service';
import { combineLatest } from 'rxjs';
import { isLockForValidationSelector, isPrfLocked } from '../premiumreport/data-access/premium-report.selectors';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, AfterViewInit {

  compno: any;
  groupcode:any
  currentYear: number;
  prfNumber: string;
  prfName: string;
  comment:string;
  oldComment:string;
  isComplete:boolean = false;
  spinnergetcomment: boolean = true;
  spinnersavecomment: boolean;
  isLocked:boolean;
  isReadOnly:boolean;
  isReadOnlyAdmin:boolean;
  commentsloaded : Promise<boolean>;

  constructor(private router:Router, private appService:AppService, private modalService: NgbModal,private companyService: CompanyService,public toastService: ToastService
    ,private AdobelaunchService:AdobelaunchService, private store: Store) { }

  ngAfterViewInit() {
    this.AdobelaunchService.EventEndDispatch();
    }
  ngOnInit(): void {
    this.removePrevAlerts();
    this.companyService.commentsCompleted.subscribe(result => {
      this.isComplete = result;
    });

    this.compno = this.companyService.companyId;
    this.currentYear = this.companyService.currentYear;
    this.prfNumber = this.companyService.prfNumber;
    this.prfName = this.companyService.prfName;
    this.companyService.getgroup().subscribe((value) => {
      this.groupcode = value;
    });

    this.isLocked =this.companyService.isEditLocked;
    this.isReadOnlyAdmin = this.appService.isReadonlyAdmin;
    this.isReadOnly = this.isLocked || this.isReadOnlyAdmin;
    this.getPrgress();
    this.companyService.getComments(this.currentYear,this.compno,this.groupcode).subscribe({
      next: (resp) => {
        if (resp["message"] == "Success") {
          this.comment = resp["comment"];
          this.oldComment=this.comment;
          this.spinnergetcomment = false;
          this.commentsloaded = Promise.resolve(true)
        }
      },
      error: (error) => {
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    })

    combineLatest([
      this.store.select(isLockForValidationSelector),
      this.store.select(isPrfLocked),
    ]).subscribe(([isValidationLocked, prfLocked]) => {
      console.log(
        'updated',
        isValidationLocked,
        localStorage.getItem('isLocked') == 'true'
      );

      this.isLocked = prfLocked || isValidationLocked;
      this.isReadOnly = this.isLocked || this.appService.isReadonlyAdmin;
      console.log(this.isLocked);
    });

  }

  getPrgress(){

    this.companyService.getCustomerPrfProgress(this.currentYear, this.compno, this.groupcode).subscribe({
      next: (resp) => {
        if (resp["message"] == "Success") {
          if (!this.isComplete) {
            this.companyService.groupreportingInProgress.next(true)
          }
          this.companyService.disablePrf.next(false)
        }
      },
      error: (error) => {
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    })


  }


  onsubmit(){
    this.companyService.clearStatus();
    this.companyService.disableCompanyList.next(true)
    this.spinnersavecomment = true;
    this.toastService.remove(this.toastService.toasts[0]);
    this.companyService.addComment(this.currentYear,this.compno,this.comment).subscribe({
      next: (resp) => {
        if (resp["message"] == "Success") {
          this.toastService.show(resp["status_msg"], { classname: 'bg-success text-light', delay: 3000 });
          //this.companyService.commentsCompleted.next(true)
          this.spinnersavecomment = false;
          this.oldComment = this.comment;
          this.getPrgress();
          this.companyService.disableCompanyList.next(false)
        }
      },
      error: (error) => {
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    })
  }

  hasUnsavedData() {

    return this.comment != this.oldComment;
  }
openPageChangeModal() {
  const modalRef = this.modalService.open(UnsavedmsgModalComponent);
  modalRef.componentInstance.name = 'New';

  modalRef.result.then((result) => {
    if (result) {
      this.onsubmit();
    }
    });
}
removePrevAlerts(){
  for (var i = this.toastService.toasts.length-1; i >= 0; i--) {
    this.toastService.remove(this.toastService.toasts[i]);
  }
}


}
