import { Component, OnInit, Injectable } from '@angular/core';
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from '../admin.service';
import { CompanyService } from 'src/app/company/company.service';
import { Workbook, CellFill, OrderedSortCondition, SortDirection, HorizontalCellAlignment, WorkbookColorInfo, VerticalCellAlignment, IWorkbookFont, WorkbookFormat } from "igniteui-angular-excel";
import { Color } from 'igniteui-angular-core';
import { IgxExcelExporterService } from '@infragistics/igniteui-angular';
import { ExcelUtility } from '../../shared/ExcelUtility';
import { UntypedFormControl } from "@angular/forms";
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-metricsreport',
  templateUrl: './metricsreport.component.html',
  styleUrls: ['./metricsreport.component.scss']
})
export class MetricsreportComponent implements OnInit {
  public formControlStartDate: UntypedFormControl = new UntypedFormControl(null);
  public formControlEndDate: UntypedFormControl = new UntypedFormControl(null);
  public columns: any[];
  prfYear: number;
  prfNumber: string;
  prfName: string;
  groupCode: string;
  compno: any;
  startDate: NgbDateStruct;
  endDate: NgbDateStruct;
  companyList: any[] = [];
  haveRecords: boolean;
  showStatusLabel: boolean = false;
  showStartDateValidator: boolean = false;
  showEndDateValidator: boolean = false;
  numberOfRecords: number;
  reportTypes: any[] = [{ value: "A", name: "All" }, { value: "U", name: "Uploaded" }, { value: "S", name: "Submitted" }];
  selectedReportType: any = { value: "A", name: "All" };
  showPageError: boolean = false;
  pageError: string = "";
  excelData: any[] = [];
  spinnergetmetricsreport: boolean = false;


  constructor(private adminService: AdminService,
    private companyService: CompanyService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private excelExportService: IgxExcelExporterService,
    private router: Router,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.compno = this.companyService.companyId;
    this.prfYear = this.companyService.currentYear;
    this.prfNumber = this.companyService.prfNumber;
    this.prfName = this.companyService.prfName;
    if (this.compno != null && this.compno.trim().length > 0) {
      this.companyService.getgroup().subscribe((value) => {
        this.groupCode = value;
        this.getPrgress();
      });
    }

  }

  getPrgress() {
    this.companyService.getCustomerPrfProgress(this.prfYear, this.compno, this.groupCode).subscribe((resp) => {
      if (resp["message"] == "Success") {
        this.companyService.disablePrf.next(false);
      }
    },
      (error) => {
        // //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    )
  }



  compareByValue(arg1, arg2) {
    if (arg1 && arg2) {
      return arg1.value == arg2.value;
    } else {
      return false;
    }
  }
  validateInput(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 47) {
      return true;
    }
    else {
      return false;
    }

  }
  startDateOnBlur() {
    this.showStartDateValidator = true;

  }
  setValue(e: any) {
    this.selectedReportType = e;
  }
  startDateOnFocus() {
    this.showStartDateValidator = false;

  }
  endDateOnBlur() {
    this.showEndDateValidator = true;

  }
  endDateOnFocus() {
    this.showEndDateValidator = false;

  }
  generateList() {
    this.showStatusLabel = true;
    let startDateString = this.ngbDateParserFormatter.format(this.startDate);
    let endDateString = this.ngbDateParserFormatter.format(this.endDate);

    let startDate = moment(startDateString);
    let endDate = moment(endDateString);
    let diffInDays = (endDate.diff(startDate, 'days'));

    if (diffInDays < 0) {
      this.pageError = "End Date must be same or greater than Start Date";
      this.showPageError = true;
      this.haveRecords = false;
      return;
    }

    if (this.startDate == null || this.endDate == null) {
      if (this.selectedReportType.value == "A") {
        this.pageError = "All companies in PRF year is listed irrespective of selected date range!";
        this.showPageError = true;

      }
      else if (this.selectedReportType.value == "U" || this.selectedReportType.value == "S") {
        this.pageError = "Please select a valid date range";
        this.showPageError = true;
        return;
      }
    }
    if ((this.startDate != null || this.endDate != null) && this.selectedReportType.value == "A") {
      this.pageError = "All companies in PRF year is listed irrespective of selected date range!";
      this.showPageError = true;
    }

    if ((this.startDate != null && this.endDate != null) && (this.selectedReportType.value == "U" || this.selectedReportType.value == "S")) {
      this.showPageError = false;
    }
    this.spinnergetmetricsreport = true;
    this.adminService.getMetricsReportData(this.prfYear, startDateString, endDateString, this.selectedReportType.value).subscribe((response) => {
      this.spinnergetmetricsreport = false;
      if (response["message"] == "Success") {
        this.companyList = [];
        this.companyList = response["rptdata"];
        if (this.companyList.length != 0) {
          this.haveRecords = true;
          this.numberOfRecords = this.companyList.length;
        }
        else {
          this.haveRecords = false;
          this.numberOfRecords = this.companyList.length;
        }
      }
    },
      (error) => {
        this.haveRecords = false;
        // //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    )

  }

  public exportExcel() {
    let workbook = new Workbook();
    let sheetname = "Company List";
    let worksheet = workbook.worksheets().add(sheetname);
    worksheet.displayOptions.panesAreFrozen = true;
    worksheet.displayOptions.frozenPaneSettings.frozenRows = 1;
    let data =  (JSON.parse(JSON.stringify(this.companyList))); //[...this.companyList]; deep copy so that source is not modified
    this.formatData(data);
    let table = worksheet.tables().add("A1:H" + this.excelData.length, true);
    table.columns(0).sortCondition = new OrderedSortCondition(SortDirection.Ascending);
    let font: IWorkbookFont;
    font = workbook.styles().normalStyle.styleFormat.font;
    font.name = "Arial Unicode MS";
    this.WriteColumnHeadersToExcel(worksheet);
    let cols = ['companyno', 'companyname', 'Locked', 'Uploaded','dummyrpt', 'Uploaduser', 'UploadDate', 'Submitteddate'];

    for (let row = 0; row < this.excelData.length; row++) {
      for (let col = 0; col < cols.length; col++) {

        let cell = worksheet.rows(row + 1).cells(col);
        cell.value = this.excelData[row][cols[col]];

      }
    }
    workbook.setCurrentFormat(WorkbookFormat.Excel2007);
    ExcelUtility.save(workbook, "SubmittedList");

  }
  private WriteColumnHeadersToExcel(worksheet) {
    this.columns = ["COMPANY", "NAME", "SUBMITTED", "UPLOADED","DUMMY", "UPLOADED USER", "UPLOADED DATE", "SUBMISSION DATE"]
    let white = new Color();
    white.colorString = "#FFFFFF";
    for (let col = 0; col < this.columns.length; col++) {
      let cell = worksheet.rows(0).cells(col);
      worksheet.columns(col).setWidth(150, 3);
      cell.value = this.columns[col];
      cell.cellFormat.fill = CellFill.createSolidFill("#6EA3CC");
      cell.cellFormat.alignment = HorizontalCellAlignment.Center;
      cell.cellFormat.verticalAlignment = VerticalCellAlignment.Center;
      cell.cellFormat.font.colorInfo = new WorkbookColorInfo(white);
      cell.cellFormat.font.bold = true;
    }
  }
  private formatData(data) {
    this.excelData = [];
    data.forEach(element => {
      element.Submitteddate = element.Submitteddate != null ? element.Submitteddate.split(' ')[0] : "";
      element.UploadDate = element.UploadDate != null ? element.UploadDate.split(' ')[0] : "";
      element.Locked = (element.Locked=="FALSE" || element.Locked=="") ? "" : "Y";
      element.Uploaded = (element.Uploaded =="FALSE" || element.Uploaded =="") ? "" : "Y";
      element.dummyrpt = (element.dummyrpt==undefined || element.dummyrpt.toUpperCase() =="FALSE" || element.dummyrpt =="") ? "" : "Y";
      this.excelData.push(element);
    });
  }
}

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {

  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split("/");
      if (dateParts.length === 3 && (dateParts[2].length != 4)) {
        return null;

      }
      if (dateParts.length === 1 && isNumber(dateParts[0])) {
        return { day: toInteger(dateParts[0]), month: null, year: null };
      } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
        return {
          month: toInteger(dateParts[0]),
          day: toInteger(dateParts[1]),
          year: null
        };
      } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
        return {
          month: toInteger(dateParts[0]),
          day: toInteger(dateParts[1]),
          year: toInteger(dateParts[2])
        };
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    return date
      ? `${isNumber(date.month) ? padNumber(date.month) : ""}/${isNumber(date.day) ? padNumber(date.day) : ""}/${date.year
      }`
      : "";
  }
}
export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

export function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}

export function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return "";
  }
}
