<div class="filter-dropdown-wrapper">
  <div class="filter-dropdown">
    <app-select-dropdown (onSelect)="onYearFilterChange($event)">
      <option *ngFor="let year of yearList" value="{{year}}" [attr.selected]="year == currentYear ? true : false">
        {{year}}
      </option>
    </app-select-dropdown>
  </div>
  <div *ngIf="isAdmin">
    <div class="searchcont">
      <div class="lib-text-field">
        <input placeholder="SEARCH PRF NUMBER" id="search" [(ngModel)]="searchValue" (keyup)="onenter($event)"
          style="width: 115%; padding: 16px 0px 0px 0px" autocomplete="off" />
      </div>
      <div class="searchiconbutton">
        <i class="table-material-icons" (click)="searchonclick()"
          style="color: #d3d4d5; cursor: pointer; background: #ffffff">search</i>
      </div>
    </div>
  </div>
  <div class="filter-dropdown">
    <app-select-dropdown (onSelect)="getCompanyForStatus($event)">
      <option *ngFor="let statusList of filterStatusList" value="{{statusList.value}}"
        [attr.selected]="statusList.value == selectedStatus ? true : false">
        {{statusList.name}}
      </option>
    </app-select-dropdown>
  </div>
</div>