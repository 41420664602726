import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReminderMailModel } from 'src/app/company/commpany.model';
import { CompanyService } from 'src/app/company/company.service';
import { HttpService } from 'src/app/shared/http.service';
import { AdminService } from 'src/app/admin/admin.service';
import { Workbook, OrderedSortCondition, SortDirection, IWorkbookFont, WorkbookFormat, CellFill,HorizontalCellAlignment,  WorkbookColorInfo, VerticalCellAlignment} from "igniteui-angular-excel";
import { ExcelUtility } from '../../shared/ExcelUtility';
import { Color} from 'igniteui-angular-core';
import { ConfirmDialogService } from '../../confirm-dialog/confirm-dialog.service';
import { AppService } from '../../app.service'
import { ToastService } from '../../shared/toast.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-unsubmittedcompanylist',
  templateUrl: './unsubmittedcompanylist.component.html',
  styleUrls: ['./unsubmittedcompanylist.component.scss']
})
export class UnsubmittedcompanylistComponent implements OnInit {

  public unsubmittedcompanylistForm!: UntypedFormGroup;
  public columns: any[];
  currentYear: number;
  isCustomer: boolean = true;
  show: boolean = false;
  spinnergetunsubmitedcmpanies: boolean = true;
  spinnersearchcompanies: boolean ;
  spinnersendmail: boolean ;
  spinnerrefreshrem: boolean;
  companylist=[];
  allchecked:boolean;
  selectedCompanyList:ReminderMailModel[]=[]
  exclCompanyList:ReminderMailModel[]=[]
  showPageError: boolean = false;
  pageError: string = "";
  strtoday: string = "";
  mailtype: string = "";
  //emailPtrn = "^[a-z0-9A-Z._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}(; ?[a-z0-9A-Z._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4})*$";
  emailPtrn = "^[a-z0-9A-Z._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}(; ?[a-z0-9A-Z._%+-]+@[a-z0-9.-]+\\.[a-z]{2,})*$";
  excelData: any[] = [];
  //isReadOnlyAdmin: boolean = true;
  isdiableinitial: boolean = true;
  isReadOnlyAdmin: boolean;
  isAdmin: boolean;
  isReadOnly: boolean;
  isLocked: boolean;
  

  constructor(public toastService: ToastService, private companyService: CompanyService,private adminService: AdminService,private httpService: HttpService,private router: Router, private appService: AppService, private confirmDialogService: ConfirmDialogService) { }

  ngOnInit(): void {
    this.removePrevAlerts();
    this.currentYear=parseInt(localStorage.getItem("currentprfyear"));
    this.isReadOnlyAdmin = this.appService.isReadonlyAdmin;
    this.isLocked = this.companyService.isEditLocked;
    this.isReadOnlyAdmin = this.appService.isReadonlyAdmin;
    this.isReadOnly = this.isLocked || this.isReadOnlyAdmin;

    let Cookiecstid = this.httpService.getIsonetValue("cstid");
    let globalprfcustid = localStorage.getItem('prfcstid')
    this.isCustomer = false;
    this.clearpageerror();

    this.companyService.unsubmittedcomplist(this.currentYear, '').subscribe((resp) => {
      this.spinnergetunsubmitedcmpanies = false;
      if (resp["message"] == "Success") {
        this.companylist = resp["companylist"]
        this.pageError = resp['statusmsg'];
        if(this.pageError.trim().length > 0)
        {
          this.showPageError = true;
        }
        else
        {
          this.showPageError = false;
        }
      }
    },
      (error) => {
        // //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    );
    this.unsubmittedcompanylistForm = new UntypedFormGroup({
      selectemail: new UntypedFormControl('')
    });
  }
  email(){
    localStorage.setItem("routename", "unsubmittedlist");
    this.router.navigate(['/emailpreview'])
  }
  checkAllCheckBox(ev: any) {
    this.clearpageerror();
		this.companylist.forEach(x => x.checked = ev.target.checked)
    this.selectedCompanyList=[];
    if(this.isAllCheckBoxChecked(true)){
    this.companylist.forEach(cm => {
    this.selectedCompanyList.push(cm);
    })
  }
    else{

      this.selectedCompanyList=[]
    }
	}
  searchonclick() {
    this.search((<HTMLInputElement>document.getElementById('search')).value)
  }
  onenter(e) {
    this.clearpageerror();
    if (e.target.value.length == 0) {
      this.search('');
    }
    else
    {
      if (e.key === 'Enter' || e.keyCode === 13) {
        this.search(e.target.value);
      }
    }
  }
  search(value) {
    this.clearpageerror();
    if(""==value){
      this.spinnergetunsubmitedcmpanies = true;}
    else{
      this.spinnersearchcompanies = true;
    }
    value = value.toUpperCase();
      this.companyService.unsubmittedcomplist(this.currentYear, value).subscribe((resp) => {
        this.spinnersearchcompanies = false, this.spinnergetunsubmitedcmpanies = false;
        if (resp["message"] == "Success") {
          this.companylist = resp["companylist"]
          this.pageError = resp['statusmsg'];
          if(this.pageError.trim().length > 0)
          {
            this.showPageError = true;
          }
          else
          {
            this.showPageError = false;
          }
        }
      },
        (error) => {
          // //console.log(error);
          this.router.navigate(['/apifailure']);
        }
      );
  }
	isAllCheckBoxChecked(isfromcheckevent: boolean) {
    if(isfromcheckevent){
		this.allchecked=this.companylist.every(p => p.checked);
    }
    else
    {this.allchecked=false;}
    return this.allchecked
	}
  OncheckBoxChecked($event){
    this.clearpageerror();
    const companyno=$event.target.value;
    let selectedcompany = this.companylist.find(x => x.companyno == companyno);
    if($event.target.checked){
      this.selectedCompanyList.push(selectedcompany);
      this.isAllCheckBoxChecked(true);
    }
    else{
      this.isAllCheckBoxChecked(false);
      const index: number = this.selectedCompanyList.indexOf(selectedcompany);
      if (index !== -1) {
          this.selectedCompanyList.splice(index, 1);
      }
    }

  }

  OnExcludeChecked($event){
    const companyno=$event.target.value;
    let exclcompany = this.companylist.find(x => x.companyno == companyno);
    if($event.target.checked){
      this.exclCompanyList.push(exclcompany);

    }
    else{

      const index: number = this.exclCompanyList.indexOf(exclcompany);
      if (index !== -1) {
          this.exclCompanyList.splice(index, 1);
      }
    }

  }
  getitem(item){
      item.primedit=true
  }

  sendmail(remtype:string)
  {
    this.clearpageerror();
    this.mailtype ="";
    this.confirmDialogService.confirmThis('Mailing Process', 'Email(s) will be sent to selected company(s). Do you want to continue ?', () => {

      if(!this.unsubmittedcompanylistForm.value.selectemail && remtype=="submission"){
        this.showPageError = true;
        this.pageError="Please select an email content type (Standard/Custom) to send the reminder.";
        return
      }
      this.showPageError = false;
      let unsubmittedlist = [];
      let dupreminder = false;
      let proceedsend = true;
      this.pageError=""
      let blankmailcnt = 0
      if(this.selectedCompanyList.length >0){
      
        this.showPageError = false;
        this.gettodayformatted()
        dupreminder = false;
        for (let selected in this.selectedCompanyList) {
          let obj = {};
          obj["companyno"] = this.selectedCompanyList[selected].companyno;
          obj["companyname"] = this.selectedCompanyList[selected].companyname; 
          obj["primaryemail"] = this.selectedCompanyList[selected].primaryemail; 
          obj["secondaryemail"] = this.selectedCompanyList[selected].secondaryemail;
          
          let remdate = this.selectedCompanyList[selected].sendon;
          if (obj["primaryemail"].trim()  == '' && obj["secondaryemail"].trim()  == '')
          {
            blankmailcnt = blankmailcnt + 1;
            this.showPageError = true;
            this.pageError="Please enter a valid email address for the company(s) with a blank email field to send the reminder."
            return;
          }
          else{
            //const expression: RegExp = /^[a-z0-9A-Z._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}(; ?[a-z0-9A-Z._%+-]+@[a-z0-9.-]+\\.[a-z]{2,})*$/;
            const expression: RegExp = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
            if ((obj["primaryemail"].trim()!= "" && !expression.test(obj["primaryemail"].trim())) ||
             (obj["secondaryemail"].trim() !="" && !expression.test(obj["secondaryemail"].trim()))){
              this.showPageError = true;
              this.pageError="Please enter a valid email address for the company(s) with an invalid email field to send the reminder."
              return;
            }
          }
        
          if (remdate == this.strtoday){
            dupreminder = true
          }
          unsubmittedlist.push(obj);
        }
        //if (blankmailcnt > 0){
        //    this.showPageError = true;
        //    //this.pageError="Please enter email id to send the reminder." + blankmailcnt + " company(s) has blank email id.";
        //    this.pageError="Please enter a valid email address for the company(s) with a blank email field to send the reminder."
        //    return;
        //  }
        if (dupreminder){
          this.confirmDialogService.confirmThis('Reminder Mailing Status', 'Reminder has been sent to selected company(s) today. Do you want to send the reminder mail again?', () => {
            this.sendandupdatestatus(this.currentYear,unsubmittedlist,remtype);
          } ,() => {;});
        }
        else{
        this.sendandupdatestatus(this.currentYear,unsubmittedlist,remtype);
      }
    }
    else{
      this.showPageError = true;
      this.mailtype = remtype=="submission"?"Submission Reminder":"Initial Notification";
      this.pageError="Please select atleast one company to send the "+ this.mailtype+".";
    }
  }, () => {

  });
}
public exportExcel() {
  let workbook = new Workbook();
  let sheetname = "Unsubmitted Company List";
  let worksheet = workbook.worksheets().add(sheetname);
  worksheet.displayOptions.panesAreFrozen = true;
  worksheet.displayOptions.frozenPaneSettings.frozenRows = 1;
  let data = this.companylist;
  this.formatData(data);
  let table = worksheet.tables().add("A1:J" + this.excelData.length, true);
  table.columns(0).sortCondition = new OrderedSortCondition(SortDirection.Ascending);
  let font: IWorkbookFont;
  font = workbook.styles().normalStyle.styleFormat.font;
  font.name = "Arial Unicode MS";
  this.WriteColumnHeadersToExcel(worksheet);
  let cols = ['companyno', 'companyname', 'primaryemail', 'secondaryemail', 'status',  "mailid", "sendon", "sendby", "initmailsend", "submailsend"];

  for (let row = 0; row < this.excelData.length; row++) {
    for (let col = 0; col < cols.length; col++) {

      let cell = worksheet.rows(row + 1).cells(col);
      cell.value = this.excelData[row][cols[col]];

    }
  }
  workbook.setCurrentFormat(WorkbookFormat.Excel2007);
  ExcelUtility.save(workbook, "UnsubmittedCompanyList");

}
private formatData(data) {
  this.excelData = [];
  data.forEach(element => {
    element.Submitteddate = element.Submitteddate != null ? element.Submitteddate.split(' ')[0] : "";
    element.UploadDate = element.UploadDate != null ? element.UploadDate.split(' ')[0] : "";
    element.Locked = (element.Locked=="FALSE" || element.Locked=="") ? "" : "Y";
    element.Uploaded = (element.Uploaded =="FALSE" || element.Uploaded =="") ? "" : "Y";
    this.excelData.push(element);
  });
}
private WriteColumnHeadersToExcel(worksheet) {
  this.columns = ["Company", "Company Name", "PRF Email", "PBS/PDB Email", "Status", "Recipient ID", "Last Sent On", "Sent By", "Initial Mail Sent", "Submission Mail Sent"]
  let white = new Color();
  white.colorString = "#FFFFFF";
  for (let col = 0; col < this.columns.length; col++) {
    let cell = worksheet.rows(0).cells(col);
    if (col ==4) {worksheet.columns(col).setWidth(350, 3);
    }else{
    worksheet.columns(col).setWidth(150, 3);
    }
    cell.value = this.columns[col];
    cell.cellFormat.fill = CellFill.createSolidFill("#6EA3CC");
    cell.cellFormat.alignment = HorizontalCellAlignment.Center;
    cell.cellFormat.verticalAlignment = VerticalCellAlignment.Center;
    cell.cellFormat.font.colorInfo = new WorkbookColorInfo(white);
    cell.cellFormat.font.bold = true;
  }
}

private gettodayformatted(){
  let today = new Date();
  let strtoday = ""
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear();
  this.strtoday = mm + '/' + dd + '/' + yyyy;
}

private sendandupdatestatus(currentYear,unsubmittedlist,remtype)
{
      this.clearpageerror();
       this.spinnersendmail = true;
       this.companyService.sendremindermail(this.currentYear,unsubmittedlist,this.unsubmittedcompanylistForm.value.selectemail, remtype).subscribe((resp) => {
         this.spinnersendmail = false;
         this.toastService.show(resp["status_msg"], { classname: 'bg-success text-light', delay: 3000 , width : 500});
         this.spinnerrefreshrem = true;
         this.companyService.getcompreminderstatus(this.currentYear,this.companylist ).subscribe((remresp) => {
           this.spinnerrefreshrem = false;
           this.companylist = [];
           this.companylist = remresp["companylist"]
           this.selectedCompanyList = []
               });
       },
       (error) => {
         this.router.navigate(['/apifailure']);
       }
        );

}

removePrevAlerts(){
  for (let i = this.toastService.toasts.length-1; i >= 0; i--) {
    this.toastService.remove(this.toastService.toasts[i]);
  }
}

clearpageerror(){
  this.showPageError = false;
  this.pageError=""
}

}
