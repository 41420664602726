import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AdobelaunchService {
  EventEndDispatch() {
    let evt = new CustomEvent('event-view-end');

    document.getElementById('app').dispatchEvent(evt);
  }
}
