export interface PremiumReportState {
  hasError: boolean;
  hasWarning: boolean;
  errorMessage?: string;
  selectedCellName?: string;
  selectedCellFormula?: string;
  isLoading: boolean;
  slectedPremiumType: 'D' | 'S';
  premiumData: { D: PremiumInfoType; S: PremiumInfoType };
  userDefinedPremiumData: { D: any[]; S: any[] };
  rows: any[];
  columns: { D: any[]; S: any[] };
  userDefinedPremiumDataColumns: any[];
  validationJobId: string;
  isLockForValidation: boolean,
  isLocked: boolean,
}
export interface PremiumInfoType {
  isDirty: boolean;
  isValid: boolean;
  premiumGridData: any[];
  premiumGridDataOriginalCopy: any[];
  hiddenPremiumGridData: any[];
  hiddenPremiumCount: hiddenPremiumCountType;
  clearSelectionToggler: boolean;
  endEditToggler: boolean;
}

export interface hiddenPremiumCountType {
  rows: number;
  columns: number;
}

export const initialDirectState: PremiumInfoType = {
  isDirty: false,
  isValid: true,
  premiumGridData: [],
  premiumGridDataOriginalCopy: [],
  hiddenPremiumGridData: [],
  hiddenPremiumCount: { rows: 0, columns: 0 },
  clearSelectionToggler: false,
  endEditToggler: false,
};
export const initialSupplemantalState: PremiumInfoType = {
  isDirty: false,
  isValid: true,
  premiumGridData: [],
  premiumGridDataOriginalCopy: [],
  hiddenPremiumGridData: [],
  hiddenPremiumCount: { rows: 0, columns: 0 },
  clearSelectionToggler: false,
  endEditToggler: false,
};

export const intialPremiumReportState: PremiumReportState = {
  hasError: false,
  hasWarning: false,
  isLoading: true,
  slectedPremiumType: 'D',
  premiumData: {
    D: initialDirectState,
    S: initialSupplemantalState,
  },
  userDefinedPremiumData: {
    D: [],
    S: [],
  },
  columns: { D: [], S: [] },
  userDefinedPremiumDataColumns: [],
  rows: [],
  validationJobId: '',
  isLockForValidation: false,
  isLocked: false
};
