<div class="lib-footer">
  <div class="footer">
    <div class="footer-logo">
      <img alt="verisk logo white" src="../../assets/img/logo-verisk-white.svg" />
    </div>
    <div class="footer-content">
      <p class="copyright-text">
        Copyright © {{currentYear}} Insurance Services Office, Inc. All rights reserved.
      </p>
      <p>
        <a target="_blank" id="lnkcorporateHome" href={{_corporateHomeLink}}>
          Verisk Corporate Home
        </a>
        |
        <a target="_blank" id="lnktermsAndConditions" href={{_termsAndConditionsLink}}>
          Terms and Conditions
        </a>
        |
        <a target="_blank" id="lnkprivacyAndPolicy" href={{_privacyAndPolicyLink}}>
          Privacy and Security Policy
        </a>
        |
        <a target="_blank" id="lnkcontactUs" href={{_contactUsLink}}>
          Contact Us
        </a>
      </p>
    </div>
    <div class="footer-logo-analytics">
      <img alt="verisk logo analytics" src="../../assets/img/logo-verisk-analytics.png" />
    </div>
  </div>
</div>
