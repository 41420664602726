import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService } from '../../company/company.service'
import { Router } from '@angular/router';
import { ResponsivePhaseDescription } from 'igniteui-angular-core';

@Component({
  selector: 'app-create-group-modal',
  templateUrl: './create-group-modal.component.html',
  styleUrls: ['./create-group-modal.component.scss']
})
export class CreateGroupModalComponent implements OnInit {
  @Input() title;
  @Input() prfyear;

  groupForm: UntypedFormGroup;
  alphaRegex = /^[A-Z]+$/;
  number: string = "";
  pageerror: boolean = false;
  spinloader: boolean = false;
  spinnertxt: string;
  error: string;
  isLoaded:boolean = false;

  constructor(public activeModal: NgbActiveModal, private companyService: CompanyService, private router: Router) { }

  ngOnInit(): void {
    setTimeout(()=>{this.isLoaded=true;},500);
    this.groupForm = new UntypedFormGroup({
      groupcode: new UntypedFormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(5)]),
      groupname: new UntypedFormControl('', [Validators.required]),
    });
  }
  validateInput(event): boolean {
    this.groupForm.controls['groupname'].setValue('');
    this.pageerror = false;
    const charCode = (event.which) ? event.which : event.keyCode;
    if (this.number.length < 4) {
      if ((charCode >= 48 && charCode <= 57)) {
        return true;
      }
      return false;
    }
    else {
      if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)) {
        return true;
      }
      return false;
    }
  }
  getGroupName(evt) {
    let groupno = evt.target.value.toUpperCase();
    if (groupno.length > 3 && groupno.length < 6) {
      this.spinloader = true;
      this.spinnertxt = "Loading Group";
      this.companyService.getGroupname(this.prfyear, groupno).subscribe((resp) => {
        this.spinloader = false;
        if (resp["message"] == "Success") {
          let gno = resp["groupname"][0]['groupno'];
          let gname = resp["groupname"][0]['groupname'];
          this.groupForm.controls['groupname'].setValue(gname);
          this.groupForm.controls['groupcode'].setValue(gno);
        }
        else {
          this.groupForm.controls['groupname'].setValue('');
          this.pageerror = true;
          this.error = resp["status_msg"];
        }
      },
        (error) => {
          this.activeModal.close('Close');
          // //console.log(error);
          this.router.navigate(['/apifailure']);
        }
      )
    }
    else {
      this.groupForm.controls['groupname'].setValue('');
    }
  }

  onsubmit() {
    let obj = [{
      'Name': this.groupForm.get("groupname").value, 'Number': this.groupForm.get("groupcode").value
    }];
    this.addGroupRecord(obj);
  }

  addGroupRecord(dataObj) {
    this.spinloader = true;
    this.spinnertxt = "Saving Group";
    this.companyService.saveGroupRecord(this.prfyear, dataObj).subscribe((resp) => {
      this.spinloader = false;
      if (resp["message"] == "Success") {
        this.activeModal.close({ msg: resp["status_msg"] });
      }
      else {
        this.pageerror = true;
        this.error = resp["status_msg"];
      }
    },
      (error) => {
        this.activeModal.close('Close');
        // //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    )
  }

}
