
<!-- <div *ngIf="hasAdminRole;else customer">
    <div class="lib-content">
      <content class="content">
        <div class="lib-container">
          <div>
            <div class="lib-menu">
              <app-admin-navigation></app-admin-navigation>
            </div>
            <div [ngClass]="
                currrWidth > 1200
                  ? 'lib-body'
                  : 'lib-body-fixed'
              ">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </content>
    </div>

  </div>
  <ng-template #customer> -->
    <div class="lib-content">
      <content class="content">
        <div class="lib-container">
          <div>
            <div class="lib-menu">
              <app-navigation></app-navigation>
            </div>
            <div [ngClass]="
            currrWidth > 1200
              ? 'lib-body'
              : 'lib-body-fixed'
          ">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </content>
    </div>
  <!-- </ng-template> -->
  