<div [ngClass]="
    scrollTop > 130
      ? 'lib-header-container lib-fix-header'
      : 'lib-header-container'
  ">
  <div class="lib-top-header-container">
    <div class="lib-header">
      <div class="lib-head-icon">
        <div (clickOutside)="closeProductDD()" id="productMenu-bar">
          <div class="lib-head-burger-menu" (click)="showingProductDD = !showingProductDD"
            [ngClass]="showingProductDD && 'menu-active'">
            <span><i class="material-icons">menu</i></span>
          </div>
          <div class="lib-product-dd-container" *ngIf="showingProductDD">
            <div class="lib-product-dd">
              <div class="lib-product-left">
                <div *ngFor="let productList of productLists">
                  <p class="head-hover" (mouseover)="hoverIn(productList.categoryTitle)" [ngClass]="
                  productList.categoryTitle == hover_megamenu && 'product-sel'">
                    {{ productList.categoryTitle }}
                  </p>
                </div>
                <div>
                  <p> Product Categories </p>
                </div>
                <ng-template ngFor let-category [ngForOf]="objectKeys(categoryISOProducts)" let-i="index">
                  <div>
                    <p class="p2 head-hover" id="item{{ i + 3 }}" (mouseenter)="hoverIn(category)"
                      [ngClass]="category == hover_megamenu && 'product-sel'"> {{ category }} </p>
                  </div>
                </ng-template>
              </div>


              <div class="lib-product-right">
                <!-- the same view can be shown in more than one case -->
                <div>
                  <div [ngClass]="objectKeys(categoryISOProducts).indexOf(hover_megamenu) > -1 ? '' : 'hidden'"
                    class="product-menu-row">
                    <div>
                      <li *ngFor="let product of categoryISOProducts[hover_megamenu]"
                        [ngClass]="product.isEligible ? 'subs' : 'without-subs'">
                        <a *ngIf="product.isEligible" href="{{ product.url }}" target="_blank" rel="noopener noreferrer">{{ product.title }}</a>
                        <a *ngIf="!product.isEligible">{{ product.title }}</a>
                      </li>
                    </div>
                  </div>
                </div>
                <div *ngFor="let productList of productLists">
                  <div *ngIf="productList.categoryTitle == hover_megamenu" class="product-menu-row">
                    <div>
                      <li *ngFor="let item of productList.items" [ngClass]="item.isEligible ? 'subs' : 'without-subs'">
                        <a *ngIf="item.isEligible" href="{{ item.url }}" target="_blank" rel="noopener noreferrer">{{ item.title }}</a>
                        <a *ngIf="!item.isEligible">{{ item.title }}</a>
                      </li>
                    </div>
                  </div>
                  <!--default case when there are no matches -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <a class="lib-head-logo">
          <div class="lib-head-home-icon"></div>
          <div class="lib-produect-head-title" *ngIf="scrollTop > 150">
            <span>{{ productTitle }}</span>
          </div>
        </a>
      </div>
      <div (clickOutside)="closeProfileMenu()" id="profileMenu-bar">
        <div class="lib-head-profile-menu" (click)="showingProfile = !showingProfile"
          [ngClass]="showingProfile && 'menu-active'">
          <span><i class="material-icons">person</i><i class="material-icons"> arrow_drop_down </i></span>
        </div>
        <div [ngClass]="
            showingProfile ? 'show-profile lib-profile-dd' : 'lib-profile-dd'
          ">
          <div>
            <div>
              <h3 class="customerselection_company_profile" style="margin: 15px 15px 20px;color: #333;">{{customername}}</h3>
              <hr class="customerselection_switch_profile" style="margin-top: 5px; margin-bottom: 5px;"/>
              <li id="switch-profile" onclick="openCustomerSelectionModal()" style="display: none;">
                <a href="javascript:void(0);" style="margin-top: 5px; margin-bottom: -5px;">Switch Profile</a>
              <hr class="customerselection_switch_profile" style="margin-top: 8px; margin-bottom: -5px;"/></li>
              <li *ngFor="let menu of menuLists" (click)="openURl(menu.url)">
                <a>{{ menu.title }}</a>
              </li>
              <li><a class="pointer" (click)="logout()">Sign out</a></li>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="lib-product-header-container">
    <div class="lib-product-header">
      <div class="lib-produect-head-title">
        <span>{{ productTitle }}</span>
      </div>
      <div class="lib-product-head-dropdown"></div>
      <div class="lib-head-help" *ngIf="helpUrl" (click)="openInNew(helpUrl)">
        <div><i class="material-icons">help_outline</i><span>Help</span></div>
      </div>
    </div>
  </div>
</div>
