import { createAction, props } from '@ngrx/store';


export const openCompanyDetailsForCustomer = createAction(
    '[Company List] Open Company Details for Customer',
    props<{
      groupCode: any;
      companyId: any;
      currentYear: number;
      prfNumber: any;
      prfName: any;
      prfStatus: any;
    }>()
  );

  export const navigateToValidationSummary = createAction(
    '[Company List] Navigate To Validation Summary',
    props<{
      groupCode: any;
      companyId: any;
      currentYear: number;
      prfNumber: any;
      prfName: any;
    }>()
  );