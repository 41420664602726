<div class="full-text" *ngIf=!isAuthenticated>
  <div id="loader"></div>
  <div id="message">{{ welomemessage }}</div>
</div>
<div *ngIf="isJSLoaded">
  <app-header [productLists]="productLists" [menuLists]="menuLists" [categoryISOProducts]="categoryISOProducts"
    productTitle="Premium Report Form" helpUrl="{{ helpurl }}"></app-header>
</div>
<div id="app" *ngIf="isAuthenticated && isproducteligible && isJSLoaded">
  <!--<app-header [productLists]="productLists" [menuLists]="menuLists" [categoryISOProducts]="categoryISOProducts"
    productTitle="Premium Report Form" helpUrl="{{ helpurl }}"></app-header>-->
  <router-outlet></router-outlet>
  <app-footer [footerList]="footerList"></app-footer>
  <app-confirm-dialog></app-confirm-dialog>
</div>
<div class="access-deny" *ngIf="isAuthenticated && !isproducteligible">
  <!--<app-header [productLists]="productLists" [menuLists]="menuLists" [categoryISOProducts]="categoryISOProducts"
    productTitle="Premium Report Form" helpUrl="{{ helpurl }}"></app-header>-->
  <div class="" style="width: 100%;margin: auto;padding: 0;overflow: hidden;">
    <div class="form-group row" style="width: 73%; margin: auto;">
      <table class="align-table left-p">
        <tr>
          <td>
            <h3>Premium Report Form</h3>
            <h1>ISOnet Customer Notice</h1>
            <br />
            <i><b>Product Description:&nbsp;</b></i>Premium Report Form<br />
            <br />We're sorry, but your ISOnet user ID is not authorized for access to Premium Report Form.<br />
            <br />
            For information on how you can subscribe to access Premium Report Form, please contact Verisk Customer
            Support
            or ISO Regional Sales Office: <br />
            <br />
            <table class="align-table">
              <tr>
                <td><b>Verisk Customer Support:</b> <br />Phone: 1-800-888-4476&nbsp;&nbsp;<br />
                  E-mail: <a href="mailto:isonet@verisk.com"> isonet&#64;verisk.com</a>
                </td>
              </tr>
            </table>
            <br />
            <br />
          </td>
        </tr>
      </table>
    </div>
  </div>
  <app-footer [footerList]="footerList"></app-footer>
</div>