<div *ngIf="isLoaded" class="modal" tabindex="-1" role="dialog" style="display:block!important">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="pagetitle"></h4>
                <img src="../../../assets/img/upload.png" alt="" /> <span class="table-material-icons"
                    (click)="activeModal.dismiss('Cross click')" style="cursor: pointer;">close</span>
            </div>
            <div class="modal-body">
                <div>
                    <p style="margin-top : -20px">Complete the Excel template and </p>
                    <p style="margin-top : -20px">upload your premiums</p>
                </div>
                <div style="width: 50%; margin:0 auto;">
                    <app-button size="medium" buttonType="primary" (click)="openFile()">UPLOAD Excel FILE
                        <input type="file" style="display:none" (change)="handle($event)" />
                    </app-button>
                    <!-- <div class=" form-group text-center">
                        <button (click)="downloadtemplate()">Download Template</button>
                    </div> -->
                </div>
            </div>
            <div class="modal-footer">


            </div>
        </div>
    </div>
</div>