import { createAction, props } from '@ngrx/store';
import { RowState, PremiumColumn, GridConfig } from './grid-config-model';

export const hideGridConfig = createAction('[GridConfigurations] Hide Config');
export const showGridConfig = createAction('[GridConfigurations] Show Config');
export const toggleGridConfig = createAction(
  '[GridConfigurations] Toggle Config'
);

export const updateDirectRowStateAction = createAction(
  '[RowStates] Update Direct RowState',
  props<{ rowState: RowState<any> }>()
);

export const updateSupplementalRowStateAction = createAction(
  '[RowStates] Update Supplemental RowState',
  props<{ rowState: RowState<any> }>()
);

export const updateDirectPremiumColumnsAction = createAction(
  '[RowStates] Update Direct Premium Columns',
  props<{ directPremiumColumn: PremiumColumn<any> }>()
);

export const updateSupplementalPremiumColumnsAction = createAction(
  '[RowStates] Update Supplemental Premium Columns',
  props<{ supplementalPremiumColumn: PremiumColumn<any> }>()
);

export const saveGridConfigurations = createAction(
  '[GridConfigurations] Save Configurations'
);

export const applyGridConfigurationChanges = createAction(
  '[GridConfigurations] Apply Grid Configurations changes',
  props<{ gridConfigData: GridConfig<any, any> }>()
);

export const saveGridConfigurationsSuccess = createAction(
  '[GridConfigurations] Save Configurations Success'
);

export const saveGridConfigurationsFailure = createAction(
  '[GridConfigurations] Save Configurations Failure',
  props<{ error: any }>()
);

export const updatePremiumColumnsAction = createAction(
  '[PremiumColumns] Update PremiumColumn',
  props<{ premiumColumn: PremiumColumn<any> }>()
);

export const savePremiumColumnsAction = createAction(
  '[PremiumColumns] Save PremiumColumns'
);

export const loadAllConfigurationsAction = createAction(
  '[AllConfigurations] Load All Configurations'
);

export const loadAllConfigurationsSuccess = createAction(
  '[AllConfigurations] Load AllConfigurations Success',
  props<{
    directRowStates: RowState<any>[];
    directPremiumColumns: PremiumColumn<any>[];
    supplementalRowStates: RowState<any>[];
    supplementalPremiumColumns: PremiumColumn<any>[];
  }>()
);

export const loadAllConfigurationsFailure = createAction(
  '[AllConfigurations] Load loadAllConfigurations Failure',
  props<{ error: any }>()
);

export const toggleDirectRowsSelectAll = createAction(
  '[RowStates] Toggle Direct RowStates Select All'
);

export const toggleSupplementalRowsSelectAll = createAction(
  '[RowsStates] Toggle supplemental RowsStates Select All'
);
export const toggleDirectColumnSelectAll = createAction(
  '[PremiumColumns] Toggle Direct PremiumColumns Select All'
);

export const toggleSupplementalColumnSelectAll = createAction(
  '[PremiumColumns] Toggle supplemental PremiumColumns Select All'
);

export const loadGridConfigurationsAction = createAction(
  '[GridConfigurations] Load Grid Configurations'
);

export const loadGridConfigurationsSuccess = createAction(
  '[GridConfigurations] Load grid Configurations Success',
  props<{
    hiddenDirectRowStates: RowState<any>[];
    hiddenDirectPremiumColumns: PremiumColumn<any>[];
    hiddenSupplementalRowStates: RowState<any>[];
    hiddenSupplementalPremiumColumns: PremiumColumn<any>[];
  }>()
);

export const loadDefaultGridConfigurationsSuccess = createAction(
  '[GridConfigurations] Load default grid Configurations Success',
  props<{
    hiddenDirectRowStates: RowState<any>[];
    hiddenDirectPremiumColumns: PremiumColumn<any>[];
    hiddenSupplementalRowStates: RowState<any>[];
    hiddenSupplementalPremiumColumns: PremiumColumn<any>[];
  }>()
);

export const loadExistingDataGridConfigurationsSuccess = createAction(
  '[GridConfigurations] Load with Existing Data grid Configurations Success',
  props<{
    hiddenDirectRowStates: RowState<any>[];
    hiddenDirectPremiumColumns: PremiumColumn<any>[];
    hiddenSupplementalRowStates: RowState<any>[];
    hiddenSupplementalPremiumColumns: PremiumColumn<any>[];
  }>()
);

export const loadGridConfigurationsFailure = createAction(
  '[GridConfigurations] Load loadGridConfigurations Failure',
  props<{ error: any }>()
);

export const toggleRowSelection = createAction(
  '[GridConfigurations] Toggle Row Selection',
  props<{ row: RowState<any> }>()
);

// export const loadLatestValues = createAction(
//   '[GridConfigurations] Load Latest Values'
// );

export const resetGridConfig = createAction(
  '[GridConfigurations] Reset Configuration',
  props<{ premiumType: 'D' | 'S'; resetType: 'default' | 'existingData' }>()
);

export const loadInitialStates = createAction(
  '[GridConfigurations] Load Initial States',
  props<{
    directRowStates: RowState<any>[];
    directPremiumColumns: PremiumColumn<any>[];
    supplementalRowStates: RowState<any>[];
    supplementalPremiumColumns: PremiumColumn<any>[];
  }>()
);

export const applyInitialStates = createAction(
  '[GridConfigurations] Apply Initial States'
);

export const applyGridConfigWithExistingData = createAction(
  '[GridConfigurations] Reset Configuration with Existing Data'
);
