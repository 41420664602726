import { Component, Input, OnInit } from "@angular/core";
import { AppService } from '../app.service';
import { Observable, throwError } from 'rxjs';
import { IProfileLinks } from './profilelink.model';
import { environment } from "../../environments/environment";
import { catchError} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear: number = 2019;
  _corporateHomeLink: string = "#";
  _termsAndConditionsLink: string = "#";
  _privacyAndPolicyLink: string = "#";
  _contactUsLink: string = "#";
  homePageApiUrl = '';

  @Input() footerList: [];
  constructor( private AppService: AppService, private http: HttpClient) { }

  ngOnInit(): void {
    this.checkEligible();
    this.currentYear = (new Date()).getFullYear();
  }
  private checkEligible() {
    this.AppService.getConfigStatus().subscribe(
      _configStatus => {
        if (_configStatus) {
          const _appConfig = this.AppService.getGlobalConfig();
          this.homePageApiUrl = _appConfig.homePageApiUrl;

          this.getAllUserProfileLinks(this.homePageApiUrl).subscribe(
            _headerLinks => {
              if (_headerLinks) {
                _headerLinks.forEach(up => {
                  if (up.linkFor === 'Verisk Corporate Home') {
                    this._corporateHomeLink = up.url;
                  }
                  if (up.linkFor === 'Terms and Conditions') {
                    this._termsAndConditionsLink = up.url;
                  }
                  if (up.linkFor === 'Privacy and Security Policy') {
                    this._privacyAndPolicyLink = up.url;
                  }
                  if (up.linkFor === 'Contact Us') {
                    this._contactUsLink = up.url;
                  }
                });
              }

            },
          );
        }
      });
  }
  getAllUserProfileLinks(homePageApiUrl: string): Observable<IProfileLinks[]> {
    return this.http.get<IProfileLinks[]>(environment.PRODUCTS_URL + `/API/ViewLinks/GetAll`).pipe(
      // tap(data => console.log('Header API Data: ' + JSON.stringify(data))),
      catchError(this.handleError)
    );
}
private handleError(err: HttpErrorResponse) {
  let errorMessage = '';
  if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
  } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
  }
  console.error(errorMessage);
  return throwError(errorMessage);
}

}
