import { AbstractControl, ValidatorFn } from "@angular/forms";

export function noWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const value = control.value || '';
    const cleanedValue = value
      .replace(/<p><br><\/p>/g, '') // Handle TinyMCE's empty state
      .replace(/<[^<>]+>/g, '')     // Remove HTML tags
      .replace(/&nbsp;/g, ' ')     // Replace non-breaking spaces
      .trim();                     // Trim regular spaces

    if (cleanedValue.length === 0) {
      return { whitespace: true };
    }
    return null;
  };
}

