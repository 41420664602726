<mat-accordion>
  <mat-expansion-panel *ngFor="let item of validationSummaryItems">
    <mat-expansion-panel-header [ngClass]="getPanelClass(item)">
      <div class="accordion-header-left">
        <app-validation-icon [typeName]="item.isInFeedbackUpdatedState ? 'UPDATED' : item.status"></app-validation-icon>
        <span class="title-text"
          ><b>{{ item.ruleName }}</b></span>
      </div>
      <div class="accordion-header-right">
        <div *ngIf="item.status !== 'OK'">
          <button mat-button color="primary">View Report</button>
        </div>
      </div>
    </mat-expansion-panel-header>

    <div [ngClass]="getPanelContentClass(item) + '-detail'">
      <mat-expansion-panel-content>
        <app-validation-detail-content
          [validationItem]="item"
          [readOnly]="readOnly"
        ></app-validation-detail-content>
      </mat-expansion-panel-content>
    </div>
  </mat-expansion-panel>
</mat-accordion>
