import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { CompanyService } from 'src/app/company/company.service';
import { Router } from '@angular/router';
import { trim } from 'igniteui-angular-core';

@Component({
  selector: 'app-naicmapping',
  templateUrl: './naicmapping.component.html',
  styleUrls: ['./naicmapping.component.scss']
})
export class NaicmappingComponent implements OnInit {
  prfYear: number;
  prfNumber: string;
  prfName: string;
  groupCode: string;
  compno: any;
  spinnergetnaicmapping: boolean = true;
  naicMappinglist: any[] = [];

  constructor(private adminService: AdminService, private companyService: CompanyService, private router: Router) { }

  ngOnInit(): void {
    this.compno = this.companyService.companyId;
    this.prfYear = this.companyService.currentYear;
    this.prfNumber = this.companyService.prfNumber;
    this.prfName = this.companyService.prfName;
    this.loadNAICMappingReport();
    if (this.compno != null && this.compno.trim().length > 0) {
      this.companyService.getgroup().subscribe((value) => {
        this.groupCode = value;
        this.getPrgress();
      });
    }
  }

  getPrgress() {
    this.companyService.getCustomerPrfProgress(this.prfYear, this.compno, this.groupCode).subscribe((resp) => {
      if (resp["message"] == "Success") {
        this.companyService.disablePrf.next(false);
      }
    },
      (error) => {
        // //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    )
  }

  loadNAICMappingReport() {
    this.naicMappinglist = [];
    this.adminService.loadNAICMapping(this.prfYear).subscribe((response) => {
      this.spinnergetnaicmapping = false;
      if (response["message"] == "Success") {
        this.naicMappinglist = response["mappingdetails"];
      }
    },
      (error) => {
        // //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    )
  }
}
