import { Injectable } from '@angular/core';
import * as companyActions from './company-list-actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { CompanyService } from '../../company.service';
@Injectable()
export class CompanyListEffects {
openCompanyDetailsForCustomer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(companyActions.openCompanyDetailsForCustomer),
        tap((action) => {

          localStorage.setItem("companyId", action.companyId);
          localStorage.setItem("year", action.currentYear+"");
          localStorage.setItem("prfNumber", action.prfNumber);
          localStorage.setItem("prfName", this.companyService.toTitleCase(action.prfName));
          localStorage.setItem("groupcode", action.groupCode);
          localStorage.setItem("prfStatus", action.prfStatus);
          this.companyService.getLocalStorage();
          this.companyService.loadPremiumData();
          this.router.navigate(['/companyinformation']);

        })
      ),
    { dispatch: false }
  );

  navigateToValidationSummary$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(companyActions.navigateToValidationSummary),
        tap((action) => {
          const data = action;
          localStorage.setItem('companyId', data.companyId);
          localStorage.setItem('year', data.currentYear + "");
          localStorage.setItem('prfNumber', data.prfNumber);
          localStorage.setItem(
            'prfName',
            this.companyService.toTitleCase(data.prfName)
          );
          localStorage.setItem('groupcode', data.groupCode);
          this.companyService.getLocalStorage();
          this.companyService.loadPremiumData();
            this.router.navigate(['/validationsummary']);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private companyService: CompanyService,
    private router: Router
  ) {}
  
}