import { createReducer, on } from '@ngrx/store';
import isEqual from 'lodash/isEqual';
import {
  GridConfig,
  GridConfigState,
  PremiumColumn,
  RowState,
} from './grid-config-model';
import * as GridActions from './grid-config.actions';

export const initialState: GridConfigState = {
  gridConfigVisible: false,
  gridConfigData: {
    direct: {
      states: [],
      columns: [],
    },
    supplemental: {
      states: [],
      columns: [],
    },
  },
  initialData: {
    direct: {
      states: [],
      columns: [],
    },
    supplemental: {
      states: [],
      columns: [],
    },
  },
};

const onHideGridConfig = (state: GridConfigState): GridConfigState => ({
  ...state,
  gridConfigVisible: false,
});

const onShowGridConfig = (state: GridConfigState): GridConfigState => ({
  ...state,
  gridConfigVisible: true,
});

const onToggleGridConfig = (state: GridConfigState): GridConfigState => ({
  ...state,
  gridConfigVisible: !state.gridConfigVisible,
});

const onUpdateRowStates = <T>(
  state: GridConfigState,
  rowState: RowState<T>,
  isDirect: boolean
): GridConfigState => {
  const configKey = isDirect ? 'direct' : 'supplemental';
  const updatedRowStates = state.gridConfigData[configKey].states.map(
    (currentState) =>
      currentState.id === rowState.id
        ? { ...currentState, selected: !currentState.selected }
        : currentState
  );

  return {
    ...state,
    gridConfigData: {
      ...state.gridConfigData,
      [configKey]: {
        ...state.gridConfigData[configKey],
        states: updatedRowStates,
      },
    },
  };
};

export const onUpdateDirectRowStates = (
  state: GridConfigState,
  { rowState }: { rowState: RowState<any> }
): GridConfigState => onUpdateRowStates(state, rowState, true);

export const onUpdateSupplementalRowStates = (
  state: GridConfigState,
  { rowState }: { rowState: RowState<any> }
): GridConfigState => onUpdateRowStates(state, rowState, false);

const onUpdatePremiumColumns = <T>(
  state: GridConfigState,
  { premiumColumn }: { premiumColumn: PremiumColumn<T> },
  isDirect: boolean
): GridConfigState => {
  const configKey = isDirect ? 'direct' : 'supplemental';
  const updatedPremiumColumns = state.gridConfigData[configKey].columns.map(
    (currentColumn) =>
      currentColumn === premiumColumn
        ? { ...currentColumn, selected: !currentColumn.selected }
        : currentColumn
  );

  return {
    ...state,
    gridConfigData: {
      ...state.gridConfigData,
      [configKey]: {
        ...state.gridConfigData[configKey],
        columns: updatedPremiumColumns,
      },
    },
  };
};

export const onUpdateDirectPremiumColumns = (
  state: GridConfigState,
  { directPremiumColumn }: { directPremiumColumn: PremiumColumn<any> }
): GridConfigState =>
  onUpdatePremiumColumns(state, { premiumColumn: directPremiumColumn }, true);

export const onUpdateSupplementalPremiumColumns = (
  state: GridConfigState,
  {
    supplementalPremiumColumn,
  }: { supplementalPremiumColumn: PremiumColumn<any> }
): GridConfigState =>
  onUpdatePremiumColumns(
    state,
    { premiumColumn: supplementalPremiumColumn },
    false
  );

const onLoadAllConfigurationsSuccess = (
  state: GridConfigState,
  {
    directRowStates,
    directPremiumColumns,
    supplementalRowStates,
    supplementalPremiumColumns,
  }: {
    directRowStates: RowState<any>[];
    directPremiumColumns: PremiumColumn<any>[];
    supplementalRowStates: RowState<any>[];
    supplementalPremiumColumns: PremiumColumn<any>[];
  }
) => ({
  ...state,
  gridConfigData: {
    direct: {
      states: directRowStates,
      columns: directPremiumColumns,
    },
    supplemental: {
      states: supplementalRowStates,
      columns: supplementalPremiumColumns,
    },
  },
});

const onLoadAllConfigurationsFailure = (state: GridConfigState) => ({
  ...state,
});

const onToggleDirectRowsSelectAll = (state: GridConfigState) => {
  const isAllSelected = state.gridConfigData.direct.states.every(
    (rowState) => rowState.selected
  );
  const updatedDirectRowStates = state.gridConfigData.direct.states.map(
    (rowState) => ({
      ...rowState,
      selected: !isAllSelected,
    })
  );
  return {
    ...state,
    gridConfigData: {
      ...state.gridConfigData,
      direct: {
        ...state.gridConfigData.direct,
        states: updatedDirectRowStates,
      },
    },
  };
};

const onToggleDirectColumnsSelectAll = (state: GridConfigState) => {
  const isAllSelected = state.gridConfigData.direct.columns.every(
    (premiumColumn) => premiumColumn.selected
  );
  const updatedDirectPremiumColumns = state.gridConfigData.direct.columns.map(
    (premiumColumn) => ({
      ...premiumColumn,
      selected: !isAllSelected,
    })
  );
  return {
    ...state,
    gridConfigData: {
      ...state.gridConfigData,
      direct: {
        ...state.gridConfigData.direct,
        columns: updatedDirectPremiumColumns,
      },
    },
  };
};

const onToggleSupplementalRowsSelectAll = (state: GridConfigState) => {
  const isAllSelected = state.gridConfigData.supplemental.states.every(
    (rowState) => rowState.selected
  );
  const updatedSupplementalRowStates =
    state.gridConfigData.supplemental.states.map((rowState) => ({
      ...rowState,
      selected: !isAllSelected,
    }));
  return {
    ...state,
    gridConfigData: {
      ...state.gridConfigData,
      supplemental: {
        ...state.gridConfigData.supplemental,
        states: updatedSupplementalRowStates,
      },
    },
  };
};

const onToggleSupplementalColumnsSelectAll = (state: GridConfigState) => {
  const isAllSelected = state.gridConfigData.supplemental.columns.every(
    (premiumColumn) => premiumColumn.selected
  );
  const updatedSupplementalPremiumColumns =
    state.gridConfigData.supplemental.columns.map((premiumColumn) => ({
      ...premiumColumn,
      selected: !isAllSelected,
    }));
  return {
    ...state,
    gridConfigData: {
      ...state.gridConfigData,
      supplemental: {
        ...state.gridConfigData.supplemental,
        columns: updatedSupplementalPremiumColumns,
      },
    },
  };
};

const onLoadGridConfigurations = (state: GridConfigState): GridConfigState => ({
  ...state,
  gridConfigData: {
    direct: {
      states: [],
      columns: [],
    },
    supplemental: {
      states: [],
      columns: [],
    },
  },
});

const onLoadGridConfigurationsSuccess = (
  state: GridConfigState,
  {
    hiddenDirectRowStates,
    hiddenDirectPremiumColumns,
    hiddenSupplementalRowStates,
    hiddenSupplementalPremiumColumns,
  }: {
    hiddenDirectRowStates: RowState<any>[];
    hiddenDirectPremiumColumns: PremiumColumn<any>[];
    hiddenSupplementalRowStates: RowState<any>[];
    hiddenSupplementalPremiumColumns: PremiumColumn<any>[];
  }
) => {
  const updatedDirectRowStates = state.gridConfigData.direct.states.map(
    (rowState) => {
      const isHidden = hiddenDirectRowStates.some(
        (hiddenState) => hiddenState.name === rowState.name
      );
      return {
        ...rowState,
        selected: hiddenDirectRowStates.length === 0 || !isHidden,
      };
    }
  );

  const updatedDirectPremiumColumns = state.gridConfigData.direct.columns.map(
    (column) => {
      const isHidden = hiddenDirectPremiumColumns.some(
        (hiddenColumn) => hiddenColumn.id === column.id
      );
      return {
        ...column,
        selected: hiddenDirectPremiumColumns.length === 0 || !isHidden,
      };
    }
  );

  const updatedSupplementalRowStates =
    state.gridConfigData.supplemental.states.map((rowState) => {
      const isHidden = hiddenSupplementalRowStates.some(
        (hiddenState) => hiddenState.name === rowState.name
      );
      return {
        ...rowState,
        selected: hiddenSupplementalRowStates.length === 0 || !isHidden,
      };
    });

  const updatedSupplementalPremiumColumns =
    state.gridConfigData.supplemental.columns.map((column) => {
      const isHidden = hiddenSupplementalPremiumColumns.some(
        (hiddenColumn) => hiddenColumn.id === column.id
      );
      return {
        ...column,
        selected: hiddenSupplementalPremiumColumns.length === 0 || !isHidden,
      };
    });

  return {
    ...state,
    gridConfigData: {
      direct: {
        states: updatedDirectRowStates,
        columns: updatedDirectPremiumColumns,
      },
      supplemental: {
        states: updatedSupplementalRowStates,
        columns: updatedSupplementalPremiumColumns,
      },
    },
    initialData: {
      direct: {
        states: updatedDirectRowStates,
        columns: updatedDirectPremiumColumns,
      },
      supplemental: {
        states: updatedSupplementalRowStates,
        columns: updatedSupplementalPremiumColumns,
      },
    },
  };
};

const onLoadDefaultGridConfigurationsSuccess = (
  state: GridConfigState,
  {
    hiddenDirectRowStates,
    hiddenDirectPremiumColumns,
    hiddenSupplementalRowStates,
    hiddenSupplementalPremiumColumns,
  }: {
    hiddenDirectRowStates: RowState<any>[];
    hiddenDirectPremiumColumns: PremiumColumn<any>[];
    hiddenSupplementalRowStates: RowState<any>[];
    hiddenSupplementalPremiumColumns: PremiumColumn<any>[];
  }
) => {
  const updatedDirectRowStates = state.gridConfigData.direct.states.map(
    (rowState) => {
      const isHidden = hiddenDirectRowStates.some(
        (hiddenState) => hiddenState.name === rowState.name
      );
      return {
        ...rowState,
        selected: hiddenDirectRowStates.length === 0 || !isHidden,
      };
    }
  );

  const updatedDirectPremiumColumns = state.gridConfigData.direct.columns.map(
    (column) => {
      const isHidden = hiddenDirectPremiumColumns.some(
        (hiddenColumn) => hiddenColumn.id === column.id
      );
      return {
        ...column,
        selected: hiddenDirectPremiumColumns.length === 0 || !isHidden,
      };
    }
  );

  const updatedSupplementalRowStates =
    state.gridConfigData.supplemental.states.map((rowState) => {
      const isHidden = hiddenSupplementalRowStates.some(
        (hiddenState) => hiddenState.name === rowState.name
      );
      return {
        ...rowState,
        selected: hiddenSupplementalRowStates.length === 0 || !isHidden,
      };
    });

  const updatedSupplementalPremiumColumns =
    state.gridConfigData.supplemental.columns.map((column) => {
      const isHidden = hiddenSupplementalPremiumColumns.some(
        (hiddenColumn) => hiddenColumn.id === column.id
      );
      return {
        ...column,
        selected: hiddenSupplementalPremiumColumns.length === 0 || !isHidden,
      };
    });

  return {
    ...state,
    gridConfigData: {
      direct: {
        states: updatedDirectRowStates,
        columns: updatedDirectPremiumColumns,
      },
      supplemental: {
        states: updatedSupplementalRowStates,
        columns: updatedSupplementalPremiumColumns,
      },
    },
  };
};

const onLoadAllConfigurations = (
  gridConfigState: GridConfigState
): GridConfigState => ({
  ...gridConfigState,
  gridConfigData: {
    ...gridConfigState.gridConfigData,
    direct: {
      states: [],
      columns: [],
    },
    supplemental: {
      states: [],
      columns: [],
    },
  },
});

const onSaveGridConfigurationsSuccess = (
  state: GridConfigState
): GridConfigState => ({
  ...state,
});

const onSaveGridConfigurationsFailure = (
  state: GridConfigState
): GridConfigState => ({
  ...state,
});

const onSaveGridConfigurations = (state: GridConfigState): GridConfigState => ({
  ...state,
  gridConfigVisible: false,
  initialData: { ...state.gridConfigData },
});

const onApplyGridConfigurationChanges = (
  state: GridConfigState,
  { gridConfigData }: { gridConfigData: GridConfig<any, any> }
): GridConfigState => {
  const updatedState = {
    ...state,
    gridConfigData: {
      ...gridConfigData,
    },
  };

  return updatedState;
};

const onApplyInitialStates = (state: GridConfigState): GridConfigState => {
  if (!isEqual(state.initialData, state.gridConfigData)) {
    return {
      ...state,
      gridConfigVisible: false,
      gridConfigData: {
        ...state.gridConfigData,
        direct: {
          ...state.gridConfigData.direct,
          states: [...state.initialData.direct.states],
          columns: [...state.initialData.direct.columns],
        },
        supplemental: {
          ...state.gridConfigData.supplemental,
          states: [...state.initialData.supplemental.states],
          columns: [...state.initialData.supplemental.columns],
        },
      },
    };
  } else {
    return {
      ...state,
      gridConfigVisible: false,
      gridConfigData: {
        ...state.gridConfigData,
      },
    };
  }
};

export const gridConfigReducer = createReducer(
  initialState,
  on(GridActions.showGridConfig, onShowGridConfig),
  on(GridActions.hideGridConfig, onHideGridConfig),
  on(GridActions.toggleGridConfig, onToggleGridConfig),
  on(GridActions.updateDirectRowStateAction, onUpdateDirectRowStates),
  on(
    GridActions.updateSupplementalRowStateAction,
    onUpdateSupplementalRowStates
  ),
  on(
    GridActions.updateDirectPremiumColumnsAction,
    onUpdateDirectPremiumColumns
  ),
  on(
    GridActions.updateSupplementalPremiumColumnsAction,
    onUpdateSupplementalPremiumColumns
  ),
  on(GridActions.saveGridConfigurations, onSaveGridConfigurations),
  on(
    GridActions.applyGridConfigurationChanges,
    onApplyGridConfigurationChanges
  ),
  on(
    GridActions.saveGridConfigurationsSuccess,
    onSaveGridConfigurationsSuccess
  ),
  on(
    GridActions.saveGridConfigurationsFailure,
    onSaveGridConfigurationsFailure
  ),
  on(GridActions.loadAllConfigurationsAction, onLoadAllConfigurations),
  on(GridActions.loadAllConfigurationsSuccess, onLoadAllConfigurationsSuccess),
  on(GridActions.loadAllConfigurationsFailure, onLoadAllConfigurationsFailure),
  on(GridActions.toggleDirectRowsSelectAll, onToggleDirectRowsSelectAll),
  on(GridActions.toggleDirectColumnSelectAll, onToggleDirectColumnsSelectAll),
  on(
    GridActions.toggleSupplementalRowsSelectAll,
    onToggleSupplementalRowsSelectAll
  ),
  on(
    GridActions.toggleSupplementalColumnSelectAll,
    onToggleSupplementalColumnsSelectAll
  ),
  on(GridActions.loadGridConfigurationsAction, onLoadGridConfigurations),
  on(
    GridActions.loadGridConfigurationsSuccess,
    onLoadGridConfigurationsSuccess
  ),
  on(
    GridActions.loadDefaultGridConfigurationsSuccess,
    onLoadDefaultGridConfigurationsSuccess
  ),
  on(
    GridActions.loadExistingDataGridConfigurationsSuccess,
    onLoadDefaultGridConfigurationsSuccess
  ),
  on(GridActions.applyInitialStates, onApplyInitialStates)
);
