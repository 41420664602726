<div class="lib-content">
  <content class="content">
    <div class="lib-container">
      <div>
        <div class="lib-menu" [ngClass]="{'d-none': isListPage()}" >
          <app-navigation></app-navigation>
        </div>
        <div [ngClass]="{
          'lib-body': currrWidth > 1200,
          'lib-body-fixed': currrWidth <= 1200,
          'lib-body-full-width': isListPage()
        }">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </content>
</div>
