import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { CompanyInfoModel } from 'src/app/company/commpany.model';
import { CompanyService } from 'src/app/company/company.service';

@Component({
  selector: 'app-redirecttovalidationsummary',
  templateUrl: './redirecttovalidationsummary.component.html',
  styleUrls: ['./redirecttovalidationsummary.component.scss'],
})
export class RedirecttovalidationsummaryComponent {
  showSpinner: boolean = true;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private companyService: CompanyService,
    private appService: AppService,
  ) {}
  companyInfo: CompanyInfoModel = new CompanyInfoModel();
  ngOnInit(): void {
    this.checkProfileSelectedPrf();
  }

  checkProfileSelectedPrf() { 
    if(!this.appService.isReadonlyAdmin){
    const checkInterval = setInterval(() => {
      const profileSelectedPrf = localStorage.getItem('profile_selected_prf');
      
      if (profileSelectedPrf === 'yes') {
        clearInterval(checkInterval);  // Stop the interval once the value is "yes"
        this.setLocalStorageAndNavigteToValidationSummary();
      }
    }, 1000);  // Check every 1 second
  }
  else{
    this.showSpinner = false;
    this.router.navigate(['/']);
  }
  }
  setLocalStorageAndNavigteToValidationSummary() {
    sessionStorage.removeItem('redirectToValidationSummaryUrl');
    this.route.queryParams.subscribe((params) => {
      const groupCode = params['groupcode'];
      const year = params['year'];
      const companyId = params['companyid'];
      if (params && groupCode && year && companyId) {
        this.companyService
          .loadCompanyInformation(Number(year), companyId, groupCode)
          .subscribe({
            next: (resp) => {
              if (resp['message'] == 'Success') {
                this.companyInfo = resp['companydata'];
                const prfName = this.companyInfo.compname;
                this.companyService.navigateToValidationSummary(
                  groupCode,
                  companyId,
                  year,
                  companyId,
                  prfName
                );
              }
            },
            error: (error) => {
              this.showSpinner = false;
              this.router.navigate(['/apifailure']);
            }
          });
      } else {
        this.showSpinner = false;
        this.router.navigate(['/']);
      }
    });
  }
}
