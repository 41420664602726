<div class="validation-summary-main">
  <app-validation-header
    [compNo]="compno"
    [prfNumber]="prfNumber"
    [prfName]="prfName"
    [validationSummaryItem]="validationSummaryItem$ | async"
    [selectAllFeedbackValid]="selectAllFeedbackValid$ | async"
    [selectValidationSummaryIsComplete]="selectValidationSummaryIsComplete$ | async"
    [isLockForValidation]="selectIsLockForValidation$ | async"
    [isPrfLocked]="selectIsPrfLocked$ | async"
    [readOnly]="isReadOnly$|async"
    [selectAllStatusSuccess]="selectAllStatusSuccess$ | async"
    [showNumberOfCompanyValidationMessage]="selectShowNumberOfCompanyValidationMessage$ | async"
    [numberOfCompanyValidationMessage]="selectNumberOfCompanyValidationMessage$ | async"
    [isAdmin]="isAdmin"
    [validationJobId]="selectValidationJobId$ | async"
  ></app-validation-header>
  <app-validation-detail
    [validationSummaryItem]="validationSummaryItem$ | async"
    [readOnly]="isReadOnly$|async"
  ></app-validation-detail>
</div>
