import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TabService {
  private switchChange: Subject<boolean> = new Subject<boolean>();

  constructor() {
    document.addEventListener('visibilitychange', () => {
      this.switchChange.next(!document.hidden);
    });
  }

  switchChange$(): Observable<boolean> {
    return this.switchChange.asObservable();
  }
}
