import { Component, Input } from '@angular/core';
import { CompanyService } from 'src/app/company/company.service';

import * as ValidationSummaryActions from '../../data-access/validation-summary-actions';
import { Store } from '@ngrx/store';
import {
  STATUSMAPPER,
  ValidationSummaryBase,
} from '../../data-access/validation-summary-model';
import { Router } from '@angular/router';
import { ConfirmDialogService } from 'src/app/confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'app-validation-header',
  templateUrl: './validation-header.component.html',
  styleUrls: ['./validation-header.component.scss'],
})
export class ValidationHeaderComponent {
  constructor(private store: Store, private companyService: CompanyService, private router: Router,private confirmDialogService: ConfirmDialogService) {}

  @Input() compNo: string;
  @Input() prfNumber: string;
  @Input() prfName: string;
  @Input() validationSummaryItem: ValidationSummaryBase;
  @Input() selectAllFeedbackValid: boolean;
  @Input() selectValidationSummaryIsComplete: boolean;
  @Input() isLockForValidation: boolean;
  @Input() isPrfLocked: boolean;
  @Input() readOnly: boolean;
  @Input() selectAllStatusSuccess: boolean;
  @Input() showNumberOfCompanyValidationMessage: boolean;
  @Input() numberOfCompanyValidationMessage: string;
  @Input() isAdmin: boolean;
  @Input() validationJobId: boolean;

  clickToUnLock() {
    const inputData = {
      prfuser: this.companyService.userid,
    };
    this.store.dispatch(ValidationSummaryActions.unlockToEdit({ inputData }));
  }

  areAllItemsStatusZero(): boolean {
    return this.validationSummaryItem?.data.every(
      (item) => item.status === STATUSMAPPER['0']
    );
  }

  isValidationRunningCompleted(): boolean {
    return this.selectValidationSummaryIsComplete;
  }

  isAllItemStatusUpdated(): boolean {
    return this.selectAllFeedbackValid;
  }

  reSubmit() {
    const inputData = {
      prfuser: this.companyService.userid,
    };

    this.store.dispatch(ValidationSummaryActions.reSubmit({ inputData }));
  }
  goToEmailPreview() {
    const diffInDays = this.getDaysDiff(this.validationSummaryItem.lastEmailSendDate);
    let message = ''
    let emailCount = this.validationSummaryItem.emailCount
    let lastEmailSendDate = this.validationSummaryItem.lastEmailSendDate  
    if(emailCount !== 0 && lastEmailSendDate !== '' && emailCount !== null && lastEmailSendDate !== null && emailCount !== undefined && lastEmailSendDate !== undefined) {
    message =`We have already sent ${this.validationSummaryItem.emailCount} email(s), and it's only been ${diffInDays} day(s) since the last one. Should we continue sending the email?`
    this.confirmDialogService.confirmThis(
      'Validation Summary Email Preview',
      message,
      () => {
        this.route_to_email_preview()
      },
      () => {}
    );
  } 
  else {
    this.route_to_email_preview()
  }
  }

  route_to_email_preview() {
    localStorage.setItem('routename', 'validationsummary');
    this.router.navigate(['/emailpreview'], {
      queryParams: { id: this.validationJobId },
    });
  }
  getDaysDiff(Lastemailsenddate: string) {
    let diffInDays = 0
    if(Lastemailsenddate !== '') {
    let dbDate = new Date(Lastemailsenddate); // Convert the string to a Date object
    let today = new Date(); // Get today's date

    // Set both times to midnight (00:00:00)
    dbDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    // Calculate the difference in milliseconds and convert to days
    let diffInMs = today.getTime() - dbDate.getTime(); // Difference in milliseconds
    diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24)); // Convert ms to days
    }

    return diffInDays;
}
}
