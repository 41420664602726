<div class="sidenav">
        <div class="head">Report Actions</div>
        <a routerLink="/dboard" class="companylist" [ngClass]="{'disabled': (disablecommonmenu==true)}">Administration
                Home</a>
        <ul>
                <li routerLinkActive="active"><a [ngClass]="{'disabled': (disable==true)}"
                                routerLink="/admin/companyinformation">Company Information</a></li>

                <li routerLinkActive="active"><a [ngClass]="{'disabled': (disable==true)}"
                                routerLink="/admin/groupreporting">Group Reporting</a></li>

                <li routerLinkActive="active"><a [ngClass]="{'disabled': (disable==true)}"
                                routerLink="/admin/annualstatement">Annual Statement</a></li>

                <li routerLinkActive="active"><a [ngClass]="{'disabled': (disable==true)}"
                                routerLink="/admin/premiumreport">Premium Report</a></li>

                <li routerLinkActive="active"><a [ngClass]="{'disabled': (disable==true)}"
                                routerLink="/admin/userdefineddata">User Defined Data</a></li>

                <li routerLinkActive="active"><a [ngClass]="{'disabled': (disable==true)}"
                                routerLink="/admin/comments">Comments</a></li>

                <li routerLinkActive="active"><a [ngClass]="{'disabled': (disable==true)}"
                                routerLink="/admin/annualstatementcomparison">Annual
                                Statement Comparison</a></li>
                <li routerLinkActive="active"><a [ngClass]="{'disabled': (disable==true)}"
                                routerLink="/admin/prioryearcomparison">Prior Year
                                Comparison</a></li>
                <li routerLinkActive="active"><a
                                [ngClass]="{'disabled': (disable==true 
                                || !(this.isValidationJobIdNotEmptySelector$|async))}"
                                routerLink="/admin/validationsummary">Validation Summary</a></li>

                <li routerLinkActive="active"><a [ngClass]="{'disabled': (disablecommonmenu==true)}"
                                routerLink="/admin/metricsreport">Metrics Report</a></li>
                <li routerLinkActive="active"><a [ngClass]="{'disabled': (disablecommonmenu==true)}"
                                routerLink="/admin/naicmapping">NAIC Mapping</a></li>
                <li routerLinkActive="active"><a [ngClass]="{'disabled': (disablecommonmenu==true)}"
                                routerLink="/admin/unsubmittedcompanylist">Unsubmitted Company List</a></li>

                <li *ngIf="!isReadOnlyAdmin" routerLinkActive="active"><a style="cursor: pointer;"
                                routerLink="/admin/addcompany" [ngClass]="{'disabled': (disablecommonmenu==true)}"
                                (click)="openGroup()">Add Company</a></li>
                <li routerLinkActive="active"><a [ngClass]="{'disabled': (disablecommonmenu==true)}"
                                routerLink="/admin/copyfromprioryear">Prior Year Copy</a></li>
                <li routerLinkActive="active"><a [ngClass]="{'disabled': (disablecommonmenu==true)}"
                                routerLink="/admin/emailconfiguration">Email Configuration</a></li>
                <li routerLinkActive="active"><a [ngClass]="{'disabled': (disablecommonmenu==true)}"
                                routerLink="/admin/companytypeconfig">Company Type Configuration</a></li>
        </ul>


        <div *ngIf="!isReadOnlyAdmin"
                style="display: flex; justify-content: center;padding-top: 14px;padding-right: 10px;">
                <button class="submitreport"
                        [ngClass]="{active: ((!disable && !disablecommonmenu && companyInformationCompleted && premiumreportCompleted)==true), disabled: !(!disable && companyInformationCompleted && premiumreportCompleted)}"
                        [disabled]="!(!disable && !disablecommonmenu && companyInformationCompleted && premiumreportCompleted)"
                        (click)="openFinalSubmission()" *ngIf="!(isLockedForValisation$ | async)">Submit
                        Report</button>
                <app-button buttonType="secondary" *ngIf="(isLockedForValisation$ | async) && (!disable)"
                        routerLink="/admin/validationsummary">Validation Summary</app-button>

        </div>

</div>
