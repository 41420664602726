import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { IgxGridComponent } from '@infragistics/igniteui-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Color } from 'igniteui-angular-core';
import {
  CellBorderLineStyle,
  CellFill,
  HorizontalCellAlignment,
  IWorkbookFont,
  VerticalCellAlignment,
  Workbook,
  WorkbookColorInfo,
  WorkbookFormat,
} from 'igniteui-angular-excel';
import { Subject, combineLatest } from 'rxjs';
import {
  first,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { AdobelaunchService } from '../../_services/adobelaunch.service';
import { AdminService } from '../../admin/admin.service';
import { AppService } from '../../app.service';
import { ConfirmDialogService } from '../../confirm-dialog/confirm-dialog.service';
import { ExcelUtility } from '../../shared/ExcelUtility';
import { PDFService, printParams } from '../../shared/pdf.service';
import { ToastService } from '../../shared/toast.service';
import { userdefinedParams } from '../../shared/userdefinedservice';
import { CompanyService } from '../company.service';
import * as GridActions from '../grid-configuration/data-access/grid-config.actions';
import { UploadPremiumComponent } from '../upload-premium/upload-premium.component';
import * as premiumReportActions from './data-access/premium-report.action';
import { PremiumReportState } from './data-access/premium-report.models';
import {
  columnsSelector,
  currentCellFormulaSelector,
  currentCellNameSelector,
  currentGridDataSelector,
  currentGridTypeSelector,
  currentGridisValid,
  errorMessageSelector,
  errorStateSelector,
  hiddenPremiumCountSelector,
  isGridDirty,
  isLoadingStateSelector,
  isLockForValidationSelector,
  isOtherGridDirty,
  isPrfLocked,
  rowsSelector,
  selectPremiumReportState,
  userDefinedPremiumColumnSelector,
  userDefinedPremiumDataSelector,
} from './data-access/premium-report.selectors';
import {
  Premiumreportservice,
  premiumreportParams,
} from './data-access/services/premiumreportservice';
import { cleanPremiumData } from './data-access/utilities/premiumcount.utility';

@Component({
  selector: 'app-premium-report',
  templateUrl: './premium-report.component.html',
  styleUrls: ['./premium-report.component.scss'],
})
export class PremiumReportComponent
  implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subject<void> = new Subject<void>();
  @ViewChild('grid1', { read: IgxGridComponent, static: true })
  public grid1: IgxGridComponent;
  listenerFn: () => void;
  public direct_premium: any[];
  public supplemental_premium: any[];
  public userdefined_premium: any[];
  public userdefined_premium_columns: any[];
  public userdefined_premium_supplemental: any[];
  public direct_premium_copy: any[];
  public supplemental_premium_copy: any[];
  public columns: any[];
  public rows: any[];
  public supplemental_premium$ = this.store.select(
    currentGridDataSelector('S')
  );
  public direct_premium$ = this.store.select(currentGridDataSelector('D'));
  data: any;
  public userdefined_premium_direct$ = this.store.select(
    userDefinedPremiumDataSelector('D')
  );
  public userdefined_premium_columns$ = this.store.select(
    userDefinedPremiumColumnSelector
  );
  public userdefined_premium_supplemental$ = this.store.select(
    userDefinedPremiumDataSelector('S')
  );

  public cellName$ = this.store.select(currentCellNameSelector).pipe(
    tap((x) => {
      console.log(x);
    })
  );
  public cellFormula$ = this.store.select(currentCellFormulaSelector);
  public premium_type: 'D' | 'S' = 'D';
  public premium_type$ = this.store.select(currentGridTypeSelector);

  private isDirty = false;
  public showPageError$ = this.store.select(errorStateSelector);
  public showPageWarning = false;
  private isvalidgrid = true;
  public errorMsg$ = this.store.select(errorMessageSelector);
  public warningMsg = '';
  premiumreportparams: premiumreportParams;
  userdefinedparams: userdefinedParams;
  compno: any;
  groupcode: any;
  currentYear: number;
  prfNumber: string;
  prfName: string;
  isLocked: boolean;
  isReadOnly: boolean;
  isReadOnlyAdmin: boolean;
  user: string;
  isLoading: boolean = false;
  isLoading$ = this.store.select(isLoadingStateSelector);
  isDownloading: boolean = false;
  isComplete: boolean = true;
  isUpload: boolean = false;
  UnEditableCellInfo: object = {};
  IsUnEditableWithTab: boolean = false;
  isTypingOverGridCell = false;
  prevGridCellValue = '';
  selectText: boolean = true;
  hiddenPremiumCount: { rows: number, columns: number } = { 'rows': 0, 'columns': 0 };
  public columns$ = this.premium_type$.pipe(
    switchMap((premium_type) => {
      return this.store.select(columnsSelector(premium_type));
    })
  );
  public rows$ = this.store.select(rowsSelector);
  supp_editable_cols = [25, 31]; //TO DO : Handle this through db
  public options = {
    enabled: true,
    copyHeaders: false,
    copyFormatters: true,
    separator: '\t',
  };

  setDataForGrid(data: any[], grid: 'D' | 'S' = this.premium_type) {
    this.store.dispatch(
      premiumReportActions.setGridPremiumData({
        grid: grid,
        premiumData: data,
      })
    );
  }
  endEdit() {

  }
  clearCellSelection() {

  }

  hasUnsavedData() {
    this.endEdit();
    return this.isDirty;
  }

  constructor(
    private adminService: AdminService,
    private companyService: CompanyService,
    private modalService: NgbModal,
    public toastService: ToastService,
    private pdfService: PDFService,
    private confirmDialogService: ConfirmDialogService,
    private appService: AppService,
    private router: Router,
    private premiumReportService: Premiumreportservice,
    private adobeLaunchService: AdobelaunchService,
    private store: Store
  ) { }

  ngAfterViewInit() {
    this.adobeLaunchService.EventEndDispatch();
  }

  ngOnInit() {
    this.removePrevAlerts();

    this.compno = this.companyService.companyId;
    this.currentYear = this.companyService.currentYear;
    this.prfNumber = this.companyService.prfNumber;
    this.prfName = this.companyService.prfName;
    this.isLocked = this.companyService.isEditLocked;
    this.isReadOnlyAdmin = this.appService.isReadonlyAdmin;

    this.isReadOnly = this.isLocked || this.isReadOnlyAdmin;
    this.companyService
      .getgroup()
      .pipe(takeUntil(this.subscriptions))
      .subscribe((value) => {
        this.groupcode = value;
      });
    this.companyService.getPrfStatus();
    // this.LoadData();
    this.premium_type$
      .pipe(
        switchMap((premium_type) => {
          return this.store.select(isGridDirty(premium_type));
        })
      )
      .subscribe((x) => {
        this.isDirty = x;
      });
    this.premium_type$
      .pipe(
        switchMap((premium_type) => {
          return this.store.select(currentGridisValid(premium_type));
        })
      )
      .subscribe((x) => {
        this.isvalidgrid = x;
      });
    this.columns$.subscribe((col) => {
      console.log(col);
      this.columns = col;
    });
    this.rows$.subscribe((rows) => {
      this.rows = rows;
    });
    this.premium_type$.subscribe((gridType) => {
      this.premium_type = gridType;
    });
    this.supplemental_premium$.subscribe((x) => {
      this.supplemental_premium = x;
    });
    this.store.select(isOtherGridDirty).subscribe((isDirty) => {
      if (isDirty) {
        this.showSaveChangesDialog(
          this.premium_type == 'D' ? 'direct' : 'supplemental'
        );
      }
    });
    this.premium_type$
      .pipe(
        switchMap((premium_type) => {
          return this.store.select(hiddenPremiumCountSelector(premium_type));
        })
      )
      .subscribe((obj) => {
        this.hiddenPremiumCount = obj;
      });

      combineLatest([
        this.store.select(isLockForValidationSelector),
        this.store.select(isPrfLocked),
      ]).subscribe(([isValidationLocked, prfLocked]) => {
        console.log(
          'updated',
          isValidationLocked,
          localStorage.getItem('isLocked') == 'true'
        );

        this.isLocked = prfLocked || isValidationLocked;
        this.isReadOnly = this.isLocked || this.appService.isReadonlyAdmin;
        console.log(this.isLocked);
      });
  }

  private LoadData() {
    this.store.dispatch(
      premiumReportActions.loadPremiumInfo({
        compno: this.compno,
        currentYear: this.currentYear,
        groupcode: this.groupcode,
      })
    );
  }

  private showError(msg) {
    this.store.dispatch(premiumReportActions.setError({ message: msg }));
    console.error('error set from base');
  }

  private showWarning(msg) {
    this.showPageWarning = true;
    this.warningMsg = msg;
    setTimeout(() => {
      this.showPageWarning = false;
      this.warningMsg = '';
    }, 5000);
  }

  private clearError() {
    this.store.dispatch(premiumReportActions.clearError());
  }

  public downloadtemplate() {
    this.companyService.getTemplateFile('Template.xlsx').subscribe(
      (resp) => {
        if (resp['message'] == 'Success') {
          var fileurl = resp['templateurl'];
          let pwa = window.open(fileurl, '_blank', 'noopener noreferrer');
          if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
            // console.log('Please disable your Pop-up blocker and try again.');
          }
        }
      },
      () => {
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    );
  }

  public updatePremiumDetails(premium_type = this.premium_type) {
    setTimeout(() => {
      // Adding delay to wait for grid update to complete
      this.toastService.reset();
      this.endEdit();
      this.store.dispatch(
        premiumReportActions.setGridClean({ grid: premium_type })
      );
      this.store
        .select(selectPremiumReportState)
        .pipe(take(1))
        .subscribe((data: PremiumReportState) => {
          const griddata = {
            premreportlobs: data.columns[premium_type],
            premreportstates: data.rows,
            dpremiuminfo: data.premiumData.D.premiumGridData,
            spremiuminfo: data.premiumData.S.premiumGridData,
            udpremiuminfo: data.userDefinedPremiumData.D,
            uspremiuminfo: data.userDefinedPremiumData.S,
            udlobs: data.userDefinedPremiumDataColumns,
          };
          this.adminService.disablesidemenu.next(true);
          this.adminService.disablecommonmenu.next(true);
          this.companyService.disableCompanyList.next(true);
          this.companyService.clearStatus();

          this.companyService
            .updatepremiumdetails(
              this.currentYear,
              this.compno,
              griddata,
              this.groupcode,
              false
            )
            .pipe(takeUntil(this.subscriptions))
            .subscribe(
              (resp) => {
                if (resp['message'] === 'Success') {
                  this.toastService.reset();
                  this.store.dispatch(
                    premiumReportActions.setGridClean({ grid: premium_type })
                  );
                  this.store.dispatch(
                    premiumReportActions.refreshCopyWithCurrent()
                  );

                  if (this.isUpload) {
                    this.toastService.show('File Uploaded Successfully.', {
                      classname: 'bg-success text-light',
                      delay: 5000,
                    });
                  } else {
                    this.toastService.show('Premium saved successfully.', {
                      classname: 'bg-success text-light',
                      delay: 3000,
                    });
                  }

                  this.isUpload = false;
                  this.adminService.disablesidemenu.next(false);
                  this.adminService.disablecommonmenu.next(false);
                  this.companyService.disableCompanyList.next(false);
                  this.companyService.getPrfStatus();
                }
              },
              () => {
                // this.isDirty = true;
                this.store.dispatch(
                  premiumReportActions.setGridDirty({ grid: this.premium_type })
                );
                this.router.navigate(['/apifailure']);
              }
            );
        });
    }, 500);
    // this.isDirty = false;
  }

  public uploadExcelDataToGrid(uploadedFile) {
    // this.grid1.endEdit();
    this.endEdit();
    if (!uploadedFile) {
      this.showError('Please select a file to Upload!');
      return false;
    }
    const fileExt = uploadedFile.name.split('.').pop();
    if (fileExt !== 'xlsx') {
      this.showError('Invalid file selected, valid files are of .xlsx types.');
      return false;
    }

    this.showWarning(
      'Please Wait! File upload will take some time.\nYou will get an upload status at end.\nAlso make sure that you are using the correct file for uploading premiums for the company ' +
      this.compno +
      '.'
    );

    ExcelUtility.load(uploadedFile).then((w) => {
      this.uploadPremium(w);
    });
  }

  private validateLOBs(worksheet) {
    for (let col = 0; col < this.columns.length; col++) {
      if (col === 0) {
        continue;
      }
      const column = this.columns[col];
      const excelLob = worksheet.rows(0).cells(col).value;
      if (
        excelLob !== null &&
        column.columnName.replace(/\r?\n|\r|\s/g, '') !==
        excelLob.replace(/\r?\n|\r|\s/g, '')
      ) {
        this.showError(
          'LOB Header in col [' +
          (col - 2) +
          '] is invalid. Please use the template file for upload.'
        );
        return false;
      }
    }
    return true;
  }

  public Clear(flag = false) {
    if (this.isDirty || flag) {
      this.endEdit();

      this.store.dispatch(premiumReportActions.refreshDirectWithCopy());
      this.store.dispatch(premiumReportActions.refreshSupplementalWithCopy());
    }
  }

  private uploadPremium(workbook) {
    // to check original template.xlsx file is uploaded.
    if (!this.validateA1Cell(workbook)) {
      return;
    }
    if (workbook.worksheets().count !== 2) {
      this.showError(
        'Invalid File.File contains [' +
        workbook.worksheets().count +
        '] Sheet(s)! Download the Template.'
      );
      return;
    }

    if (
      workbook.worksheets(0).name !== 'Direct Premiums' ||
      workbook.worksheets(1).name !== 'Supplemental Premiums'
    ) {
      this.showError(
        'Invalid Sheet Names! First sheet name should be [Direct Premiums] and second sheet name should be [Supplemental Premiums].Download the Template.'
      );
      return;
    }

    const sheetCount = workbook.worksheets().count;

    combineLatest([this.direct_premium$, this.supplemental_premium$])
      .pipe(take(1))
      .subscribe(([direct_premium, supplemental_premium]) => {
        for (let sheet = 0; sheet < sheetCount; sheet++) {
          const worksheet = workbook.worksheets(sheet);
          const expectedColumns = this.columns.length;
          const expectedRows = this.rows.length;
          // Validate the structure of the uploaded template
          if (!this.validateTemplateStructure(worksheet, expectedRows, expectedColumns)) {
            this.Clear(true);
            return false;
          }
          if (!this.validateFormulaCells(worksheet, this.premiumReportService.FORMULATED_COLUMNS,this.premiumReportService.FORMULATED_ROWS)) {
            this.Clear(true);
            return false;
          }
          if (!this.validatelockedCells(worksheet, this.premiumReportService.LOCKED_COLUMNS,this.premiumReportService.LOCKED_ROWS)) {
            this.Clear(true);
            return false;
          }
          var slno = 6
          if (this.validateLOBs(worksheet)) {
            var i = 1;
            do {
              var stateCode = '';
              var stateDesc = '';
              for (let col = 0; col < this.columns.length; col++) {
                var value = null;
                if (col < this.premiumReportService.FIXED_COLUMNS) {
                  value = worksheet.rows(i).cells(col).value;
                  if(col == this.premiumReportService.CONST_SLNO_COLUMN){
                    if(parseInt(value) == slno){
                      slno = slno+1
                    }else{
                      this.showError(
                        `SL No mismatch. Invalid template in sheet ${worksheet.name}. use original template file'.`
                      );
                      this.Clear(true);
                      return false;
                    }
                  }
                } else {
                  value =
                    worksheet.rows(i).cells(col).value != null &&
                      worksheet.rows(i).cells(col).value != ''
                      ? worksheet.rows(i).cells(col).value
                      : null;
                    // Check if the value is non-numeric in non-fixed columns
                    if (isNaN(value)) {
                      this.showError('Invalid data! Non-numeric value found in column ' + (col + 1) + ' at row ' + (i+1));
                      this.Clear(true);
                      return false;
                    }
                    // Round numeric values if valid
                    value = value != null ? Math.round(parseFloat(value)) : null;
                }

                if (parseInt(value) > 99999999 || parseInt(value) < -99999999) {
                  this.showError(
                    'Upload cell value cannot be greater than 99,999,999 or less than -99,999,999.'
                  );
                  this.store.dispatch(
                    premiumReportActions.setGridDirty({
                      grid: this.premium_type,
                    })
                  );
                  // this.isDirty = true;
                  this.Clear(true);
                  return false;
                }

                if (col == this.premiumReportService.CONST_STATE_COLUMN) {
                  stateDesc = value;
                } else if (
                  col == this.premiumReportService.CONST_STATECODE_COLUMN
                ) {
                  stateCode = value;
                }

                //state validation
                if (
                  col == this.premiumReportService.CONST_STATECODE_COLUMN &&
                  this.isValidState(stateCode, stateDesc, sheet) == false
                ) {
                  this.Clear(true);
                  return false;
                }

                //Avoid updation of premium for User Defined Row
                if (
                  stateCode == this.premiumReportService.userdefined_row_code
                ) {
                  break;
                }


                if (sheet == 0 && direct_premium[i - 1] != null) {
                  direct_premium[i - 1][col] = value;
                } else if (sheet == 1 && supplemental_premium[i - 1] != null) {
                  supplemental_premium[i - 1][col] = value;
                }
              }
              i++;
            } while (worksheet.rows(i).cells(0).value != null);
          } else {
            this.Clear(true);
            return false;
          }
        }

        // this.grid1.data = this.direct_premium;
        this.setDataForGrid(direct_premium, 'D');
        // this.grid1.data = this.supplemental_premium;
        this.setDataForGrid(supplemental_premium, 'S');

        this.isUpload = true;
        this.updatePremiumDetails();
        this.clearError();
      });
  }

  private isValidState(stateCode, stateDesc, sheet) {

    if (sheet == 0) {
      if (stateCode == this.premiumReportService.userdefined_row_code && stateDesc === 'Total User Defined Columns')
        stateDesc = 'Total User Defined Columns-Suppl';
    }
    if (sheet == 1) {
      if (stateCode == this.premiumReportService.userdefined_row_code && stateDesc === 'Total User Defined Columns-Suppl.')
        stateDesc = 'Total User Defined Columns-Suppl';
      if (stateCode == this.premiumReportService.supplemental_row_code && (stateDesc === '' || stateDesc == null))
        stateDesc = 'Supplemental Premium Volume';
    }
    var rowInfo = this.rows.filter((p) => {
      return p.Code == stateCode;
    });
    if (!rowInfo || rowInfo.length === 0) {
      this.showError(
        'Not able to fetch State information. State Code - ' + stateCode
      );
      return false;
    }

    if (rowInfo[0].Name == stateDesc) {
      return true;
    } else {
      this.showError(
        'State details in position [' +
        stateCode +
        '] is invalid. Please use the template file for upload. '
      );
      return false;
    }
  }

  public downloadData(isPDF) {
    // this.grid1.endEdit();
    this.endEdit();
    if (this.isDirty) {
      this.confirmDialogService.confirmThis_withClose(
        'Premium Report',
        'Do you want to save changes?',
        () => {
          // this.updateFormulaCells();
          this.updatePremiumDetails();
          this.download(isPDF);
        },
        () => {
          this.store.dispatch(
            premiumReportActions.setGridClean({ grid: this.premium_type })
          );
          // this.isDirty = false;
          this.Clear(true);
          // this.updateFormulaCells();
          this.download(isPDF);
        },
        () => {
          return;
        }
      );
    } else {
      this.download(isPDF);
    }
  }

  public download(isPDF) {
    if (isPDF) {
      this.generatePDF();
    } else {
      this.isDownloading = true;
      setTimeout(() => {
        this.exportExcel();
      }, 100);
    }
  }

  public exportExcel() {
    // this.grid1.endEdit();
    this.endEdit();
    var workbook = new Workbook();
    var sheetname =
      this.premium_type == 'D' ? 'Direct Premiums' : 'Supplemental Premiums';
    var worksheet = workbook.worksheets().add(sheetname);

    worksheet.displayOptions.panesAreFrozen = true;
    worksheet.displayOptions.frozenPaneSettings.frozenRows = 1;
    worksheet.displayOptions.frozenPaneSettings.frozenColumns =
      this.premiumReportService.FIXED_COLUMNS;

    var font: IWorkbookFont;
    font = workbook.styles().normalStyle.styleFormat.font;
    font.name = 'Arial Unicode MS';
    font.height = 10 * 20;

    this.WriteColumnHeadersToExcel(worksheet);
    // var data = this.grid1.data;
    this.store
      .select(currentGridDataSelector(this.premium_type))
      .pipe(
        take(1),
        map((x) => JSON.parse(JSON.stringify(x))),
        withLatestFrom(
          this.direct_premium$,
          this.supplemental_premium$,
          this.userdefined_premium_direct$,
          this.userdefined_premium_columns$,
          this.userdefined_premium_supplemental$
        )
      )
      .subscribe(
        ([
          data,
          direct_premium,
          supplemental_premium,
          userdefined_premium_direct,
          userdefined_premium_columns,
          userdefined_premium_supplemental,
        ]) => {
          // var data = this.getGridData();
          for (let row = 0; row < data.length; row++) {
            for (let col = 0; col < this.columns.length; col++) {
              var columnInfo = this.columns[col];
              var cell = worksheet.rows(row + 1).cells(col);
              if (col == 0) {
                cell.cellFormat.fill = CellFill.createSolidFill('#6EA3CC');
              }
              if (
                columnInfo.columnNumber == 0 ||
                columnInfo.columnNumber == 2
              ) {
                cell.value = String(data[row][col]);
              } else {
                cell.value = data[row][col];
                cell.cellFormat.formatString = '#,##0';
              }

              //Avoid adding user defined and supplemental premiums in last cell of last row
              var isLastCell = false;
              if (
                columnInfo.columnNumber == this.columns.length - 1 &&
                data[row][this.premiumReportService.CONST_STATECODE_COLUMN] ==
                this.premiumReportService.total_row_code
              ) {
                isLastCell = true;
              }

              if (
                this.premiumReportService.IsTotalRowData(
                  parseInt(
                    data[row][this.premiumReportService.CONST_ROW_NUMBER_COLUMN]
                  )
                ) &&
                col >= this.premiumReportService.FIXED_COLUMNS
              ) {
                cell.cellFormat.fill = CellFill.createSolidFill('#CECED2');
                cell = this.setCellBorder(cell, '#000000');
                var rowformula = this.premiumReportService.GetTotalRowFormula(
                  parseInt(
                    data[row][this.premiumReportService.CONST_ROW_NUMBER_COLUMN]
                  )
                );
                if (rowformula.trim().length > 0) {
                  var formulastring = this.getRowFormula(rowformula, row, col);
                  cell.applyFormula(formulastring);
                }
              }

              if (columnInfo && columnInfo.cellTypeId == 'C') {
                if (columnInfo.cellformulae && columnInfo.cellformulae.length > 0) {
                  var formula = this.getFormula(
                    columnInfo,
                    row + 2,
                    true,
                    isLastCell,
                    userdefined_premium_direct,
                    userdefined_premium_columns,
                    userdefined_premium_supplemental
                  );
                  cell.applyFormula(formula);
                }
                cell.cellFormat.fill = CellFill.createSolidFill('#CECED2');
                cell = this.setCellBorder(cell, '#000000');
              }

              //Avoid calculating the total for user defined and supplemental premium for the last column
              if (
                columnInfo.columnNumber == this.columns.length - 1 &&
                (data[row][this.premiumReportService.CONST_STATECODE_COLUMN] ==
                  this.premiumReportService.userdefined_row_code ||
                  data[row][this.premiumReportService.CONST_STATECODE_COLUMN] ==
                  this.premiumReportService.supplemental_row_code)
              ) {
                cell.value = '';
              }

              if (
                data[row][this.premiumReportService.CONST_STATECODE_COLUMN] ==
                this.premiumReportService.AssumedFromNonAffiliates_row_code ||
                data[row][this.premiumReportService.CONST_STATECODE_COLUMN] ==
                this.premiumReportService.supplemental_row_code
              ) {
                if (
                  columnInfo.columnNumber >=
                  this.premiumReportService.FIXED_COLUMNS &&
                  (columnInfo.cellTypeId == 'D' ||
                    columnInfo.columnNumber == this.columns.length - 1) &&
                  (cell.value == null || cell.value == 0)
                ) {
                  cell.value = '';
                }
              }

              if (this.IsExclusive(data[row], columnInfo.columnNumber)) {
                cell.cellFormat.fill = CellFill.createSolidFill('#BADDFF');
                cell = this.setCellBorder(cell, '#000000');
              }
            }
          }
          workbook.setCurrentFormat(WorkbookFormat.Excel2007);
          ExcelUtility.save(workbook, 'Premium Report');
          this.isDownloading = false;
        }
      );
  }

  private getRowFormula(rowInfo, rowIndex, colIndex) {
    var formula = '';
    if (rowInfo.indexOf(',') > 0) {
      var split = rowInfo.split(',');
      var formulastring = [];
      for (let index = 0; index < split.length; index++) {
        const element = split[index];
        formulastring.push(
          this.getRowFormulaString(element, rowIndex, colIndex)
        );
      }
      formula = '=' + formulastring.join('+');
    } else {
      formula = '=' + this.getRowFormulaString(rowInfo, rowIndex, colIndex);
    }
    return formula;
  }

  private setCellBorder(cell, colString) {
    let color = new Color();
    color.colorString = colString;
    cell.cellFormat.leftBorderColorInfo = new WorkbookColorInfo(color);
    cell.cellFormat.leftBorderStyle = CellBorderLineStyle.Default;
    cell.cellFormat.topBorderColorInfo = new WorkbookColorInfo(color);
    cell.cellFormat.topBorderStyle = CellBorderLineStyle.Default;
    cell.cellFormat.bottomBorderColorInfo = new WorkbookColorInfo(color);
    cell.cellFormat.bottomBorderStyle = CellBorderLineStyle.Default;
    cell.cellFormat.rightBorderColorInfo = new WorkbookColorInfo(color);
    cell.cellFormat.rightBorderStyle = CellBorderLineStyle.Default;
    return cell;
  }

  private WriteColumnHeadersToExcel(worksheet) {
    const white = new Color();
    white.colorString = '#FFFFFF';

    for (let col = 0; col < this.columns.length; col++) {
      let cell = worksheet.rows(0).cells(col);
      const isFirstColumn = col === 0;

      worksheet.columns(col).setWidth(isFirstColumn ? 50 : 150, 3);
      cell.value = this.columns[col].columnName;

      cell.cellFormat.fill = CellFill.createSolidFill('#6EA3CC');
      cell.cellFormat.alignment = HorizontalCellAlignment.Center;
      cell.cellFormat.verticalAlignment = VerticalCellAlignment.Center;

      const fontInfo = cell.cellFormat.font;
      fontInfo.colorInfo = new WorkbookColorInfo(white);
      fontInfo.bold = true;

      cell = this.setCellBorder(cell, '#C0C0C0');
    }
  }

  private getFormula(
    columnInfo,
    rowIndex,
    IsExcelExport,
    IsLastCell,
    userdefined_premium,
    userdefined_premium_columns,
    userdefined_premium_supplemental
  ) {
    let formula = '';
    if (
      columnInfo &&
      columnInfo.cellformulae &&
      columnInfo.cellformulae.length > 0
    ) {
      if (columnInfo.cellformulae.indexOf(',') > 0) {
        var split = columnInfo.cellformulae.split(',');
        var formulastring = [];
        for (let index = 0; index < split.length; index++) {
          const element = split[index];
          formulastring.push(
            this.premiumReportService.getFormulaString(
              element,
              rowIndex,
              IsExcelExport
            )
          );
        }
        formula = '=' + formulastring.join('+');
        if (IsExcelExport && this.premium_type == 'D' && IsLastCell == false) {
          if (columnInfo.columnNumber == this.columns.length - 1) {
            var supplementalpremium =
              this.supplemental_premium[rowIndex - 2][columnInfo.columnNumber];
            if (
              supplementalpremium != null &&
              supplementalpremium != undefined
            ) {
              formula += '+' + supplementalpremium;
            }

            var userdefinedpremiumValue =
              userdefined_premium[rowIndex - 2][
              userdefined_premium_columns.length - 1
              ];
            if (
              userdefinedpremiumValue != null &&
              userdefinedpremiumValue != undefined
            ) {
              formula += '+' + userdefinedpremiumValue;
            }
          }
        } else if (
          IsExcelExport &&
          this.premium_type == 'S' &&
          IsLastCell == false
        ) {
          var udsupplemental =
            userdefined_premium_supplemental[rowIndex - 2][
            userdefined_premium_columns.length - 1
            ];
          if (udsupplemental != null && udsupplemental != undefined) {
            formula += '+' + udsupplemental;
          }
        }
      } else {
        formula =
          '=' +
          this.premiumReportService.getFormulaString(
            columnInfo.cellformulae,
            rowIndex,
            IsExcelExport
          );
      }
    }
    return formula;
  }

  private getRowFormulaString(formulaVal, rowIndex, colIndex) {
    const [startCol, endCol] = formulaVal.split(':');
    const adjustedStartCol = this.getColName(colIndex, startCol - 4);
    const adjustedEndCol = this.getColName(colIndex, endCol - 4);
    return `Sum(${adjustedStartCol}:${adjustedEndCol})`;
  }

  generatePDF() {
    console.log("Inside generate method.");
    this.endEdit();
    (this.premium_type == 'D'
      ? this.direct_premium$
      : this.supplemental_premium$
    ).pipe(first()).subscribe((premium) => {
      this.isLoading = true;
      const temp_rows = this.rows.filter((row) => row.cellTypeId === 'C');
      const result = cleanPremiumData(premium, this.columns);

      if (result.updatedColumnNames.length < 4) {
        this.isLoading = false;
        this.toastService.reset();
        this.toastService.show('No premiums have been entered yet.', { classname: 'bg-danger text-light', delay: 3000 });
        window.scrollTo(0, 0);
        return true;
      }

      const premiumtype = this.premium_type == 'D' ? '' : ' (Supplemental)';
      const params: printParams = new printParams(
        result.updatedColumnNames,
        temp_rows,
        result.filteredData,
        [1, 2],
        13,
        this.currentYear,
        this.prfNumber,
        this.prfName,
        false,
        null,
        premiumtype,
        result.cleanedData
      );
      this.pdfService.params = params;
      this.pdfService.generate();
      this.isLoading = false;
    });
  }

  openUploadPremium() {
    const modalRef = this.modalService.open(UploadPremiumComponent);
    modalRef.result.then((result) => {
      if (result) {
        this.uploadExcelDataToGrid(result);
      }
    });
  }

  handleClickOutside() {
    this.store.dispatch(GridActions.applyInitialStates());
  }

  onGridConfigButtonClick() {
    this.store.dispatch(GridActions.toggleGridConfig());
  }

  public toggleConsolidatedOrDetailView(event: MatSlideToggleChange) {
    if (event.checked) {
      this.store.dispatch(premiumReportActions.enableConsolidatedView());
    } else {
      this.store.dispatch(premiumReportActions.enableDetailedView());
    }
  }

  private getColName(colIndex, rowIndex) {
    let colName = '';

    while (colIndex >= 0) {
      colName = String.fromCharCode((colIndex % 26) + 65) + colName;
      colIndex = Math.floor(colIndex / 26) - 1;
    }

    if (colName.length > 0) {
      colName += rowIndex;
    }

    return colName;
  }

  private IsExclusive = (rowData: any, columnKey: any): boolean => {
    const columnInfo = this.columns.find(
      (column) => column.Number == columnKey - 2
    );

    if (columnInfo) {
      const rows = columnInfo.ExclRows;
      if (rows != null) {
        const rowSplit = rows.split(',');
        return rowSplit.includes(String(rowData[0]));
      }
    }
    return false;
  };

  private switchTab(tabName) {
    this.store.dispatch(
      premiumReportActions.setPremiumType({
        premiumType: tabName === 'direct' ? 'D' : 'S',
      })
    );
  }

  public showtab(tabName) {
    // Clear grid selection
    this.gridClearSelection();

    // Check if the user clicks the same tab
    if (this.shouldSkipTabSwitch(tabName)) {
      return;
    }

    // Check if the grid is in valid state
    if (!this.isvalidgrid) {
      this.store.dispatch(
        premiumReportActions.setGridValidity({
          grid: this.premium_type,
          validity: true,
        })
      );
      return;
    }
    this.switchTabAndRefresh(tabName);
  }

  private shouldSkipTabSwitch(tabName: string): boolean {
    return (
      (this.premium_type === 'D' && tabName === 'direct') ||
      (this.premium_type === 'S' && tabName === 'supplemental')
    );
  }

  private showSaveChangesDialog(tabName: string): void {
    this.confirmDialogService.confirmThis_withClose(
      'Premium Report',
      'Do you want to save changes?',
      () => {
        this.handleSaveChangesConfirmation(tabName);
      },
      () => {
        this.handleCancelChangesConfirmation(tabName);
      },
      () => {
        // Close button
        return;
      }
    );
  }

  private handleSaveChangesConfirmation(tabName: string): void {
    this.updatePremiumDetails(tabName == 'direct' ? 'S' : 'D');
    this.switchTabAndRefresh(tabName);
  }

  private handleCancelChangesConfirmation(tabName: string): void {
    this.store.dispatch(premiumReportActions.refreshDirectWithCopy());
    this.store.dispatch(premiumReportActions.refreshSupplementalWithCopy());
    this.switchTabAndRefresh(tabName);
  }

  private switchTabAndRefresh(tabName: string): void {
    this.switchTab(tabName);
    // this.updateFormulaCells();
  }

  gridClearSelection() {
    this.endEdit();
    this.clearCellSelection();
  }

  removePrevAlerts() {
    const toasts = this.toastService.toasts.slice().reverse();
    for (const toast of toasts) {
      this.toastService.remove(toast);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.next();
    this.subscriptions.complete();
    this.Clear()
  }

  private validateTemplateStructure(worksheet, expectedRows: number, expectedColumns: number): boolean {
    let actualRowCount = 0
    let actualColumnCount =0
    let rowIndex = 1;
    let colIndex =0;

    while (worksheet.rows(rowIndex).cells(0).value != null) {
          actualRowCount++;
          rowIndex++;
        }

    while (worksheet.rows(0).cells(colIndex).value != null) {
      actualColumnCount++
      colIndex++
    }

    if (actualRowCount !== expectedRows) {
      this.showError(
        `Invalid number of rows. Expected ${expectedRows+1}, but found ${actualRowCount+1}. Please use the correct template.`
      );
      return false;
    }

    if (actualColumnCount !== expectedColumns) {
      this.showError(
        `Invalid number of columns. Expected ${expectedColumns}, but found ${actualColumnCount}. Please use the correct template.`
      );
      return false;
    }
    return true;
  }

  private validateA1Cell(workbook): boolean {
    const expectedValue = "PRF Original";
    const sheetCount = workbook.worksheets().count;

    for (let sheet = 0; sheet < sheetCount; sheet++) {
      const worksheet = workbook.worksheets(sheet);
      const a1Value = worksheet.rows(0).cells(0).value;

      if (a1Value !== expectedValue) {
        this.showPageWarning = false;
        const errorMessage = "The template you used for upload is invalid. Please use the `Download Template` button to acquire this year's version";
        this.showError(errorMessage);
        return false;
      }
    }
    return true;
  }

  private validateFormulaCells(worksheet, lockedColumns, lockedRows): boolean {

    if (worksheet.name === 'Supplemental Premiums') {
      lockedRows = lockedRows.filter(row => row !== 65);
    }
    // Check each row in the worksheet
    for (let row = 1; row <= this.rows.length; row++) {
      for (let col of lockedColumns) {
        if (this.premiumReportService.EXCEPTED_COLUMNS.includes(col) && this.premiumReportService.EXCEPTED_ROWS.includes(row)) {
          const cell = worksheet.rows(row).cells(col);
            if(cell.value !== null && cell.value !== undefined && cell.value !=0){
              console.log(cell.value)
              this.showError(
                'Invalid data! Manually entered value found in ' + worksheet.name +
                  ' locked column ' + (col + 1) + ' at row ' + (row + 1) +
                  '. It is a locked cell. '
              );
              return false;
            }
          continue;
        }

        const cell = worksheet.rows(row).cells(col);
        if (!cell.formula) {
          this.showError(
            'Invalid data! Manually entered value found in ' + worksheet.name +
            ' locked column ' + (col + 1) + ' at row ' + (row + 1) +
            '. The value should be formula-calculated.'
          );
          return false;
        }
      }

      // Check specific rows for formula-calculated values
      if (lockedRows.includes(row)) {
        for (let col = 3; col < this.columns.length; col++) {
          if (this.premiumReportService.EXCEPTED_COLUMNS.includes(col) && this.premiumReportService.EXCEPTED_ROWS.includes(row)) {
            continue;
          }
          const cell = worksheet.rows(row).cells(col);

          if (!cell.formula) {
            this.showError(
              'Invalid data! Manually entered value found in ' + worksheet.name +
              ' locked row ' + (row + 1) + ' at column ' + (col + 1) +
              '. The value should be formula-calculated.'
            );
            return false;
          }
        }
      }
    }
    return true;
  }

  private validatelockedCells(worksheet,lockedColumns, lockedRows): boolean {

    if (worksheet.name === 'Direct Premiums') {
      lockedRows = lockedRows.filter(row => row !== 65);
    }

    const isFormulaOrZeroRow = (row: number) =>
      this.premiumReportService.FORMULATED_ROWS.includes(row) ||
      this.premiumReportService.VALUE_ZERO_ROWS.includes(row);

    const validateLockedColumns = (row: number): boolean => {
      for (let col of lockedColumns) {
        if (isFormulaOrZeroRow(row)) continue;
        const cell = worksheet.rows(row).cells(col);
        if(cell.value !== null && cell.value !== undefined){
          console.log(cell.value)
          this.showError(
            'Invalid data! Manually entered value found in ' + worksheet.name +
              ' locked column ' + (col + 1) + ' at row ' + (row + 1) +
              '. It is a locked cell. '
          );
          return false;
        }
      }
      return true;
    };

    const validateLockedRow = (row: number): boolean => {
      for (let col=3; col<=this.columns.length; col++){
        if (this.premiumReportService.FORMULATED_COLUMNS.includes(col)) {
          continue;
        }
        const cell = worksheet.rows(row).cells(col);
        if(cell.value !== null && cell.value !== undefined){
          console.log(cell.value)
          this.showError(
            'Invalid data! Manually entered value found in ' + worksheet.name +
              ' locked row ' + (row + 1) + ' at column ' + (col + 1) +
              '. It is a locked cell. '
          );
          return false;
        }
      }
      return true;
    };

    const validateZeroValueRow = (row: number): boolean => {
      for (let col = 3; col < this.columns.length; col++) {
          if (this.premiumReportService.FORMULATED_COLUMNS.includes(col)) continue;
          if (worksheet.rows(row).cells(col).value !== 0) {
            this.showError(
              'Invalid data! Manually entered value found in ' + worksheet.name +
                ' locked row ' + (row + 1) + ' at column ' + (col + 1) +
                '. It is a locked cell. '
            );
              return false;
          }
      }
      return true;
    };

    for (let row=1; row<=this.rows.length; row++){
      if(worksheet.name === 'Direct Premiums'&& !validateLockedColumns(row)){
        return false;
      }
      // Check specific rows
      if (lockedRows.includes(row) && !validateLockedRow(row)){
        return false;
      }
      if(this.premiumReportService.VALUE_ZERO_ROWS.includes(row)&& !validateZeroValueRow(row)){
        return false;
      }
    }
    return true
  }

}
