import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-company-list-pagination',
  templateUrl: './company-list-pagination.component.html',
  styleUrls: ['./company-list-pagination.component.scss'],
})
export class CompanyListPaginationComponent implements OnChanges {
  @Input() showCompanyLoader: boolean = false;
  @Input() showspingetprfyears: boolean = false;
  @Input() totalItems: number;
  @Input() itemsPerPage: number = 10;
  @Input() currentPage: number = 1;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  totalPages: number;
  pages: number[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.totalItems || changes.itemsPerPage) {
      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    }
    this.generatePages();
  }

  generatePages() {
    const pages = [];
    const totalPages = this.totalPages;
    const currentPage = this.currentPage;
  
    if (totalPages <= 10) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      const startPage = Math.max(currentPage - 4, 1);
      const endPage = Math.min(currentPage + 4, totalPages);
  
      if (startPage > 1) {
        pages.push(1);
        if (startPage > 2) {
          pages.push(-1);
        }
      }
  
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
  
      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          pages.push(-1);
        }
        pages.push(totalPages);
      }
    }
    this.pages = pages;
  }

  setPage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.pageChange.emit(this.currentPage);
    }
  }
}
