<button
  type="{{type}}"
  class="{{ class }} shared-button btn {{ btnType }} {{ btnSize }}"
  [attr.disabled]="disabled ? '' : null"
>
  <i *ngIf="icon != null" class="material-icons">{{ icon }}</i>
  <span [ngClass]="bold && 'btn-bold'" style="margin: 0 auto; width: 100%"
    ><ng-content></ng-content
  ></span>
</button>
