import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AdobelaunchService } from '../_services/adobelaunch.service';

@Component({
  selector: 'app-apifailuremessage',
  templateUrl: './apifailuremessage.component.html',
  styleUrls: ['./apifailuremessage.component.scss']
})
export class ApifailuremessageComponent implements OnInit,AfterViewInit {

  constructor(private AdobelaunchService:AdobelaunchService) { }

  ngOnInit(): void {
  }
  ngAfterViewInit() {     
    this.AdobelaunchService.EventEndDispatch();
    }
}
