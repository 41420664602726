import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as ValidationSummaryActions from '../../data-access/validation-summary-actions';
import { ValidationSummaryItem } from '../../data-access/validation-summary-model';
import { CompanyService } from 'src/app/company/company.service';

@Component({
  selector: 'app-validation-detail-content',
  templateUrl: './validation-detail-content.component.html',
  styleUrls: ['./validation-detail-content.component.scss'],
})
export class ValidationDetailContentComponent {
  constructor(private store: Store, private companyService: CompanyService) {}
  @Input() validationItem: ValidationSummaryItem;
  @Input() readOnly: boolean;

  get dataType(): string {
    return this.validationItem.content.type;
  }

  get dataValue(): any {
    return this.validationItem.content.value;
  }

  isValidationSummaryFeedbackSaveReadonly(): boolean {
    return !this.validationItem.feedback;
  }

  cancel() {
    this.validationItem.feedback = '';
  }

  updateFeedback() {
    const feedbackData = {
      feedback: this.validationItem.feedback,
      pk: this.validationItem.pk,
      sk: this.validationItem.sk,
      prfuser: this.companyService.userid,
    };
    this.store.dispatch(
      ValidationSummaryActions.updateFeedback({ feedbackData })
    );
  }
}
