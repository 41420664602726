<div class="loading-screen">
  <div class="loading">
    <div class="spinBackground">
      <div id="loader"></div>
      <div class="loading-content">
        <p>
          <ng-content></ng-content>
        </p>
      </div>
    </div>
  </div>
</div>