import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  IGridEditEventArgs,
  IGridKeydownEventArgs,
  IgxGridCell,
  IgxGridComponent,
} from '@infragistics/igniteui-angular';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import {
  selectInitialDirectPremiumColumns,
  selectInitialDirectRowStates,
} from 'src/app/company/grid-configuration/data-access/grid-config.selectors';
import { Premiumreportservice } from 'src/app/company/premiumreport/data-access/services/premiumreportservice';
import { ToastService } from 'src/app/shared/toast.service';
import { CompanyService } from '../../../company.service';
import * as premiumReportActions from '../../data-access/premium-report.action';
import {
  columnsSelector,
  currentGridDataSelector,
  rowsSelector,
  userDefinedPremiumColumnSelector,
  userDefinedPremiumDataSelector,
} from '../../data-access/premium-report.selectors';
import { IgxadaptorComponent } from '../../core/igxadaptor/igxadaptor.component';

@Component({
  selector: 'app-direct-premiums',
  templateUrl: './direct-premiums.component.html',
  styleUrls: ['./direct-premiums.component.scss'],
})
export class DirectPremiumsComponent
  extends IgxadaptorComponent
  implements OnInit
{
  @ViewChild('directPremiumsGrid', { read: IgxGridComponent, static: true })
  currentPremiumsGrid: IgxGridComponent;
  // @Input() public premiumReportState: PremiumReportState;
  @Input() isFullscreen;
  public directPremium$ = this.store.select(currentGridDataSelector('D'));
  public gridConfiguration$ = this.store.select(selectInitialDirectRowStates);

  initialData: any;
  public supplementalPremium$ = this.store.select(currentGridDataSelector('S'));
  public userdefined_premium$ = this.store.select(
    userDefinedPremiumDataSelector('D')
  );
  public userdefined_premium_columns$ = this.store.select(
    userDefinedPremiumColumnSelector
  );
  public userdefined_premium_supplemental = this.store.select(
    userDefinedPremiumDataSelector('S')
  );
  public columns$ = combineLatest([
    this.store.select(selectInitialDirectPremiumColumns),
    this.store.select(columnsSelector('D')),
  ]).pipe(
    map(([premiumColumns, columns]) => {
      const newModifiedColumns =
        this.premiumReportService.updateColumnVisibility(
          columns,
          premiumColumns
        );
      return newModifiedColumns;
    })
  );
  public data$ = this.premiumReportService.getVisibleData$(
    this.directPremium$,
    this.gridConfiguration$
  );
  public rows$ = this.store.select(rowsSelector);
  public rows: any[];
  public userdefined_row_code = 'UD';
  public supplemental_row_code = 'SP';
  public premium_type: 'S' | 'D' = 'D';

  isLocked: boolean;
  isReadOnly: boolean;
  isReadOnlyAdmin: boolean;

  constructor(
    private companyService: CompanyService,
    public toastService: ToastService,
    private appService: AppService,
    public premiumReportService: Premiumreportservice,
    public store: Store
  ) {
    super(premiumReportService, store);
  }

  ngOnInit(): void {
    this.isLocked = this.companyService.isEditLocked;
    this.isReadOnlyAdmin = this.appService.isReadonlyAdmin;
    this.isReadOnly = this.isLocked || this.isReadOnlyAdmin;

    this.gridClearSelection();
    this.columns$.subscribe((col) => {
      this.columns = col;
    });
    this.rows$.subscribe((rows) => {
      this.rows = rows;
    });
    this.data$.pipe(take(1)).subscribe((intial) => {
      this.initialData = intial;
    });
    this.data$.subscribe((newModifiedRows) => {
      this.currentPremiumsGrid.data = newModifiedRows;
    });
  }

  

  public commit() {
    this.currentPremiumsGrid.transactions.commit(this.currentPremiumsGrid.data);
    this.store.dispatch(
      premiumReportActions.setGridPremiumData({
        grid: 'D',
        premiumData: this.currentPremiumsGrid.data,
      })
    );
  }









  public onCellSelect(data: any) {
    this.columns$
      .pipe(
        take(1),
        withLatestFrom(
          this.supplementalPremium$,
          this.userdefined_premium$,
          this.userdefined_premium_columns$
        )
      )
      .subscribe(
        ([
          columns,
          supplemental_premium,
          userdefined_premium,
          userdefined_premium_columns,
        ]) => {
          this.onInputFocus(data);
          const rowIndex = data.cell.row.index + 1;
          const columnIndex = data.cell.column.index;
          //Fetching cell name
          this.store.dispatch(
            premiumReportActions.setSelectedCellName({
              name: this.getColName(columnIndex - 1, rowIndex),
            })
          );
          //Fetching cell formula
          const columnInfo = columns.filter(
            (p) => p.columnNumber == columnIndex
          );

          if (columnInfo?.length) {
            var formula = this.premiumReportService.getFormula(
              columnInfo[0],
              rowIndex,
              false,
              false,
              columns,
              supplemental_premium,
              userdefined_premium,
              userdefined_premium_columns,
              'D'
            );

            this.store.dispatch(
              premiumReportActions.setSelectedCellFormula({
                formula: formula,
              })
            );
          }
          //Avoid showing formula for user defined and supplemental premium for the last column
          this.avoidFormulaLastColumn(data, columns, columnInfo);

          this.prevGridCellValue = '';
        }
      );
  }




 
}
