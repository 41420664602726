<div>
  <div class="content-header">
    <div class="content-header-text">
      Visible Premium Columns
    </div>
    <div class="content-header-sub-text">
      Select the Premium Columns that best match your annual statement. You can always edit this in the future,
      your preference will be saved for future sessions.
    </div>
    <div class="slider-section grid-2col-select-all">
      <span class="config-select-all-span">
        Select All
      </span>
      <mat-slide-toggle class="custom-slider-select-all" [checked]="isAllColumnSelected" [hideIcon]="true"
        (change)="toggleSelectAll()"></mat-slide-toggle>
    </div>
  </div>
  <div class="scrollable-content">
    <div class="item-list grid-single-col">
      <div *ngFor="let premiumColumn of premiumColumns">
        <div class="slider-section grid-2col-list-items">
          <span class="config-item-span" [style.color]="premiumColumn.selected ? '#004EAA' : 'black'">{{ premiumColumn.name }}</span>
          <mat-slide-toggle class="custom-slider" [color]="color" [checked]="premiumColumn.selected" [hideIcon]="true" (change)="toggleSelectColumn(premiumColumn)"></mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>
</div>
