import { createAction, props } from '@ngrx/store';
import {
  ValidationSummaryBase,
  ValidationSummaryItem,
} from './validation-summary-model';

export const loadAllValidationSummaryItems = createAction(
  '[ValidationSummary] Load All Validation Summary Items'
);

export const loadValidationSummaryItemsSuccess = createAction(
  '[ValidationSummary] Load All Validation Summary Items Success',
  props<{ validationSummaryBaseItem: ValidationSummaryBase }>()
);

export const loadValidationSummaryItemsFailure = createAction(
  '[ValidationSummary] Load All Validation Summary Items Failure',
  props<{ error: any }>()
);

export const initiateValidationOnAfterSubmit = createAction(
  '[ValidationSummary] Initiate Validation On After Submit Response',
  props<{ inputData: any }>()
);

export const initiateValidationOnAfterSubmitSuccess = createAction(
  '[ValidationSummary] Initiate Validation On After Submit Response Success',
  props<{ jobId: string }>()
);

export const initiateValidationOnAfterSubmitFailure = createAction(
  '[ValidationSummary] Initiate Validation On After Submit Response Failure',
  props<{ error: any }>()
);

export const unlockToEdit = createAction(
  '[ValidationSummary] Unlock The Company For Edit',
  props<{ inputData: any }>()
);

export const unlockToEditSuccess = createAction(
  '[ValidationSummary] Unlock The Company For Edit Success'
);

export const unlockToEditFailure = createAction(
  '[ValidationSummary] Unlock The Company For Edit Failure',
  props<{ error: any }>()
);

export const updateFeedback = createAction(
  '[ValidationSummary] Update the Feedback Comment',
  props<{ feedbackData: any }>()
);

export const updateFeedbackSuccess = createAction(
  '[ValidationSummary] Update the Feedback Comment Success'
);

export const updateFeedbackFailure = createAction(
  '[ValidationSummary] Update the Feedback Comment Failure',
  props<{ error: any }>()
);

export const reSubmit = createAction(
  '[ValidationSummary] Re Submit the PRF',
  props<{ inputData: any }>()
);

export const reSubmitSuccess = createAction(
  '[ValidationSummary] Re Submit the PRF Success'
);

export const reSubmitFailure = createAction(
  '[ValidationSummary] Re Submit the PRF Failure',
  props<{ error: any }>()
);

export const triggerInitialSubmit = createAction(
  '[ValidationSummary] Trigger the Initial Submit of the PRF',
  props<{ inputData: any }>()
);

export const updateJobIdSuccess = createAction(
  '[ValidationSummary] update Job Id Success',
  props<{ jobId: string }>()
);

export const updateJobIdFailure = createAction(
  '[ValidationSummary] update Job Id Failure',
  props<{ error: any }>()
);

export const loadNumberOfCompanyValidationMessage = createAction(
  '[ValidationSummary] load Number Of Company Validation Message'
);

export const loadNumberOfCompanyValidationMessageSuccess = createAction(
  '[ValidationSummary] load Number Of Company Validation Message Success', props<{ message: string }>()
);

export const loadNumberOfCompanyValidationMessageFailure = createAction(
  '[ValidationSummary] load Number Of Company Validation Message Failure',
  props<{ error: any }>()
);

export const resetValidationSummaryData = createAction(
  '[ValidationSummary] Reset Validation Summary Data'
);

export const resetValidationSummaryDataFilure = createAction(
  '[ValidationSummary] Reset Validation Summary Data Failure',
  props<{ error: any }>()
);
