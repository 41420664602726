import { Component, Input } from '@angular/core';
import { ValidationSummaryBase } from '../../data-access/validation-summary-model';

@Component({
  selector: 'app-validation-detail',
  templateUrl: './validation-detail.component.html',
  styleUrls: ['./validation-detail.component.scss'],
})
export class ValidationDetailComponent {
  panelOpenState = false;
  @Input() validationSummaryItem: ValidationSummaryBase;
  @Input() readOnly: boolean;
}
