import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
// import { NgbTabTitle } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable() export class ConfirmDialogService {
    private subject = new Subject<any>();
    private subject_withclose = new Subject<any>();

    confirmThis(title: string, message: string, yesFn: () => void, noFn: () => void): any {
        this.setConfirmation(title,message, yesFn, noFn);
    }

    setConfirmation(title:string,message: string, yesFn: () => void, noFn: () => void): any {
        const that = this;
        this.subject.next({
            type: 'confirm',
            text: message,
            title:title,
            yesFn(): any {
                that.subject.next(null); // This will close the modal
                yesFn();
            },
            noFn(): any {
                that.subject.next(null);
                noFn();
            }
        });

    }

    confirmThis_withClose(title: string, message: string, yesFn: () => void, noFn: () => void, closeFn: () => void): any {
        this.setConfirmation_withclose(title,message, yesFn, noFn,closeFn);
    }

    setConfirmation_withclose(title:string,message: string, yesFn: () => void, noFn: () => void, closeFn: () => void): any {
        const that = this;
        this.subject_withclose.next({
            type: 'confirm',
            text: message,
            title:title,
            yesFn(): any {
                that.subject_withclose.next(null); // This will close the modal
                yesFn();
            },
            noFn(): any {
                that.subject_withclose.next(null);
                noFn();
            },closeFn(): any {
                that.subject_withclose.next(null);
                closeFn();
            }
        });

    }
    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
    getMessage_withclose(): Observable<any> {
        return this.subject_withclose.asObservable();
    }
    
}