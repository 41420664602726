import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { CompanyService } from '../company.service';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddGroupReportingComponent } from '../add-group-reporting/add-group-reporting.component'
import { ToastService } from '../../shared/toast.service';
import { UnsavedmsgModalComponent } from '../../unsavedmsg-modal/unsavedmsg-modal.component';
import { ConfirmDialogService } from '../../confirm-dialog/confirm-dialog.service';
import { AppService } from '../../app.service'
import { Router } from '@angular/router';
import { AdobelaunchService } from '../../_services/adobelaunch.service';
@Component({
  selector: 'app-groupreporting',
  templateUrl: './groupreporting.component.html',
  styleUrls: ['./groupreporting.component.scss']
})
export class GroupreportingComponent implements OnInit, AfterViewInit {

  @Input() title;
  compno: any;
  currentYear: number;
  prfNumber: string;
  prfName: string;
  groupreporting: any[] = [];
  groupreportingform: UntypedFormGroup;
  passed: boolean;
  showPageError: boolean;
  unsavedData: boolean = false;
  aval: string;
  nval: string;
  show: boolean = true;
  spinnersave: boolean;
  isComplete: boolean = false;
  isLocked: boolean;
  isReadOnly: boolean;
  isReadOnlyAdmin: boolean;
  groupcode: any;
  invalidgroupcompany: any
  groupreportloaded: Promise<boolean>;
  isDelete: boolean;
  nvalError: boolean;
  avalError: boolean;
  constructor(private router: Router, private appService: AppService, private confirmDialogService: ConfirmDialogService, private companyService: CompanyService, public builder: UntypedFormBuilder, private modalService: NgbModal, public toastService: ToastService,
    private AdobelaunchService:AdobelaunchService) {
    this.groupreportingform = this.builder.group({});
  }

  ngAfterViewInit() {     
    this.AdobelaunchService.EventEndDispatch();
    }
  ngOnInit(): void {
    this.removePrevAlerts();
    this.companyService.groupreportingCompleted.subscribe(result => {
      this.isComplete = result;
    });
    this.compno = this.companyService.companyId;
    this.currentYear = this.companyService.currentYear;
    this.prfNumber = this.companyService.prfNumber;
    this.prfName = this.companyService.prfName;
    this.isLocked = this.companyService.isEditLocked;
    this.isReadOnlyAdmin = this.appService.isReadonlyAdmin;
    this.companyService.getgroup().subscribe((value) => {
      this.groupcode = value;
    });

    this.isReadOnly = this.isLocked || this.isReadOnlyAdmin || !this.companyService.groupreportenabled;
    this.getPrgress();
    this.loadGroupReporting()


  }

  getPrgress() {
    //this.show=true;
    this.companyService.getCustomerPrfProgress(this.currentYear, this.compno, this.groupcode).subscribe({
      next: (resp) => {
        if (resp["message"] == "Success") {
          if (!this.isComplete)
            this.companyService.groupreportingInProgress.next(true)
          this.companyService.disablePrf.next(false)
        }
      },
      error: (error) => {
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    });


  }

  loadGroupReporting() {
    this.show = true;
    this.companyService.loadGroupReport(this.currentYear, this.compno, this.groupcode).subscribe({
      next: (resp) => {
        if (resp["message"] == "Success") {
          this.groupreporting = resp["groupcompanydetails"]
          this.show = false;
          for (var i = 0; i < this.groupreporting.length; i++) {
            if (this.groupreporting[i].chkvalue == 'true')
              this.groupreporting[i].chkvalue = true;
            if (this.groupreporting[i].chkvalue == 'false')
              this.groupreporting[i].chkvalue = false;
            this.tableRow(i);
          }
          this.groupreportloaded = Promise.resolve(true)
        }
      },
      error: (error) => {
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    });
  }
  onFormValueChanges(data) {
    for (const key in this.groupreportingform.controls) {
      const control = this.groupreportingform.get(key);
      this.groupreporting[key] = control.value
    }
  }
  tableRow(i: number, scenario?: string) {

    this.groupreportingform.addControl('No_' + i, new UntypedFormControl(''));
    this.groupreportingform.addControl('Name_' + i, new UntypedFormControl(''));
    this.groupreportingform.addControl('chkvalue_' + i, new UntypedFormControl(''));
    this.groupreportingform.addControl('NAIC_' + i, new UntypedFormControl(''));
    this.groupreportingform.addControl('AMBest_' + i, new UntypedFormControl(''));
    this.groupreportingform.addControl('VisibleTo_' + i, new UntypedFormControl(''));
    this.groupreportingform.addControl('chktext_' + i, new UntypedFormControl(''));
    this.groupreportingform.addControl('TakenBy_' + i, new UntypedFormControl(''));
  }

  add() {
    const modalRef = this.modalService.open(AddGroupReportingComponent);

    modalRef.componentInstance.title = "Add Group Reporting";
    modalRef.componentInstance.prfYear = this.currentYear;
    modalRef.componentInstance.companyno = this.compno;

    modalRef.result.then((result) => {
      if (result) {

        result.chkvalue = true;

        result.TakenBy = this.compno;

        result.VisibleTo = '';
        result.chktext = 'Include';
        var newRows = this.groupreporting.filter(function (x) { return x.No.toUpperCase().includes('NEW') })
          .map(function (item) {
            return parseInt(item.No.replace('NEW', ''));
          });
        newRows.sort(function (a, b) { return a - b });
        if (newRows.length > 0) {
          result.No = 'NEW' + (newRows[newRows.length - 1] + 1)
        } else {
          result.No = 'NEW1'
        }
        this.tableRow(this.groupreporting.length)
        this.groupreporting.push(result)
        this.unsavedData = true;
      }
    });

  }

  deletegroupreport(gr) {
    
    this.confirmDialogService.confirmThis('Delete', 'Do you want to delete the company : ' + gr.No.toUpperCase() + '?', () => {
      if (gr == this.invalidgroupcompany) {
        this.groupreportingform.setErrors(null);
        this.showPageError = false;
      }
      this.groupreporting = this.groupreporting.filter(item => item !== gr);
      this.isDelete = true;
    }, () => {

    });
    this.unsavedData = true;

  }

  onsubmit() {
    this.toastService.remove(this.toastService.toasts[0]);
    this.spinnersave = true;
    //this.confirmval();
    //if(this.passed && this.groupreportingform.valid){
   
    for (var i = 0; i < this.groupreporting.length; i++) {
      var valid = true;
      if (this.groupreporting[i].chkvalue == true) {

        valid = this.confirmval(this.groupreporting[i]);
      }
      if (!valid) {
        this.invalidgroupcompany = this.groupreporting[i];
        break;
      }
    }

    if (this.groupreportingform.valid) {
      this.companyService.disableCompanyList.next(true)
      this.companyService.clearStatus();
      this.unsavedData = false;
      this.showPageError = false;
      var mode = '';
      if (this.isDelete)
        mode = "delete"
      this.companyService.SaveGroupReport(this.currentYear, this.compno, this.groupreporting, this.groupcode, mode).subscribe({
        next: (resp) => {
          if (resp["message"] == "Success") {
            this.getPrgress();
            this.toastService.show(resp["status_msg"], { classname: 'bg-success text-light', delay: 3000 });
            this.spinnersave = false;
            this.isDelete = false;
            this.companyService.disableCompanyList.next(false);
          }
        },
        error: (error) => {
          this.router.navigate(['/apifailure']);
        }
      });
    }
  }
  hasUnsavedData() {
    return this.unsavedData;
  }
  onchange() {
    if (!this.isReadOnly) {
      this.unsavedData = true;
    }

  }
  validateInput(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      return true;
    }
    return false;
  }
  onNativeChange(e, row) {


    if (this.groupreporting[row].chktext == "Include" && e.target.checked == true) {
      this.groupreporting[row].TakenBy = this.compno;

    } else if (this.groupreporting[row].chktext == "Include" && e.target.checked == false) {
      this.groupreporting[row].NAIC = '';
      this.groupreporting[row].AMBest = '';
      this.groupreporting[row].TakenBy = ''

    }

    this.unsavedData = true;
  }

  confirmval(truegroup): boolean {

    this.nval = truegroup['NAIC'];
    this.aval = truegroup['AMBest'];

    if (this.nval != '' || this.aval != '') {
      // console.log('i passed');
      this.groupreportingform.setErrors(null);
      this.showPageError = false;
      if (this.nval != '') {
        if (this.nval == '' || /^\d+$/.test(this.nval)) {
          this.nvalError = false;
          this.groupreportingform.setErrors(null);
        }
        else {
          this.groupreportingform.setErrors({ 'invalid': true });
          this.nvalError = true;
          this.spinnersave = false;
          return false;
        }
      } else {
        this.nvalError = false;
        this.groupreportingform.setErrors(null);
      }
      if (this.aval != '') {
        if (this.aval == '' || /^\d+$/.test(this.aval)) {
          this.avalError = false;
          this.groupreportingform.setErrors(null);
        }
        else {
          this.groupreportingform.setErrors({ 'invalid': true });
          this.avalError = true;
          this.spinnersave = false;
          return false;
        }
      } else {
        this.avalError = false;
        this.groupreportingform.setErrors(null);
      }
      return true
    } else {
      this.groupreportingform.setErrors({ 'invalid': true });
      this.showPageError = true;
      this.nvalError = false;
      this.avalError = false;
      // console.log('failed');
      this.spinnersave = false;
      return false;
    }
  }


  openPageChangeModal() {
    const modalRef = this.modalService.open(UnsavedmsgModalComponent);
    modalRef.componentInstance.name = 'New';

    modalRef.result.then((result) => {
      if (result) {
        this.onsubmit();
      }
    });
  }

  removePrevAlerts(){
    for (var i = this.toastService.toasts.length-1; i >= 0; i--) {
      this.toastService.remove(this.toastService.toasts[i]);
    }
  }
}
