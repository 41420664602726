import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastsContainer } from './toaster-container.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    ToastsContainer
  ],
  imports: [
    CommonModule,
    NgbModule,
  ],
  exports:[
    ToastsContainer
  ]
})
export class ToastContainerModule { }
