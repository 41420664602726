import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-comments-modal',
  templateUrl: './comments-modal.component.html',
  styleUrls: ['./comments-modal.component.scss']
})
export class CommentsModalComponent implements OnInit {
  @Input() title;
  @Input() isSubmissionComment: boolean;
  @Input() submissionComment;

  subcommentForm: UntypedFormGroup;
  isLoaded: boolean = false;
  // commentText:string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    setTimeout(()=>{this.isLoaded=true;},500);
    this.subcommentForm = new UntypedFormGroup({
      comment: new UntypedFormControl('', [Validators.required]),
    });
    if (this.isSubmissionComment) {
      this.subcommentForm.patchValue({
        comment: this.submissionComment,
      })
    }
  }

  onsubmit() {
    this.activeModal.close({ data: this.subcommentForm.get("comment").value });
  }

}
