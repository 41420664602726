<app-modal-loading-screen *ngIf="show">Loading User Defined columns</app-modal-loading-screen>
<app-modal-loading-screen *ngIf="spinnersave">Saving User Defined columns</app-modal-loading-screen>

<div class="modal" tabindex="-1" role="dialog" style="display:block!important">
  <app-userdefinedspinner *ngIf="spinnerdelete">Deleting User Defined columns</app-userdefinedspinner>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="pagetitle">User Defined Data: {{ currentYear }}</h4>
        <span class="table-material-icons" (click)="activeModal.dismiss('Cross click')"
          style="cursor: pointer;font-size: 24px;">close</span>

      </div>

      <div class="modal-body" *ngIf="columnsloaded | async">

        <form [formGroup]="userdefiendcolsform">


          <div>
            <div *ngIf="compno!==null">
              <span class="pagetitleSecondLayerBold"> {{ prfNumber.toUpperCase() }} </span>:<span
                class="pagetitleSecondLayer">
                {{ prfName }}</span>
            </div>
            <p>Add custom columns to the premium report.</p>
            <div>

              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Column Name</th>
                    <th scope="col">ISO Program</th>
                    <th scope="col">ISO Matching Column</th>
                    <th scope="col" *ngIf="!isReadOnly"></th>
                    <th scope="col" *ngIf="!isReadOnly"></th>
                  </tr>
                </thead>
                <tbody>

                  <tr *ngIf="!isLocked">
                    <td colspan="6">
                      <div class="information">
                        <div class="messagetitle">
                          <i class="table-material-icons">error</i>
                          This column is reserved for Aggregate Write-Ins. Aggregate Write-In reported : {{premium}}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr *ngFor="let item of userdefiledcols;let row=index">
                    <td>
                      <input type="hidden" formControlName="ColName_{{row}}" />
                      <input type="hidden" formControlName="ISOCol_{{row}}" />
                      <input type="hidden" formControlName="MatchCol_{{row}}" />
                      <input type="hidden" formControlName="ColName1_{{row}}" />
                      <input type="hidden" formControlName="ColName2_{{row}}" />
                      <input type="hidden" formControlName="ColName3_{{row}}" />
                      <input type="hidden" formControlName="ColName4_{{row}}" />
                      <input type="hidden" formControlName="ColNo_{{row}}" />
                      {{row+1}}
                    </td>

                    <td>
                      <ng-container *ngIf="item.ColName == 'Aggregate Write-Ins'; else customCol ">
                        {{ item.ColName }}
                      </ng-container>
                      <ng-template #customCol>
                        <div *ngIf="item.ColName1 !='' && item.ColName1.trim().length != 0 " style="display: inline;">
                          {{ item.ColName1 }}
                        </div>
                        <br
                          *ngIf="(item.ColName2 !='' && item.ColName2.trim().length != 0 && item.ColName1.trim().length != 0)" />
                        {{ item.ColName2 }}
                        <br
                          *ngIf="(item.ColName3 !='' && item.ColName3.trim().length != 0 && (item.ColName2.trim().length != 0 || item.ColName1.trim().length != 0 ))" />
                        {{ item.ColName3 }}
                        <br
                          *ngIf="(item.ColName4 !='' && item.ColName4.trim().length != 0 && (item.ColName3.trim().length != 0 || item.ColName2.trim().length != 0 || item.ColName1.trim().length != 0))" />
                        {{ item.ColName4 }}
                      </ng-template>

                    </td>
                    <td>
                      <div *ngIf="item.ISOCol=='on';else default">
                        Yes
                      </div>

                      <ng-template #default>
                        <div>No</div>
                      </ng-template>

                    </td>
                    <td>{{ item.MatchCol}}</td>
                    <td *ngIf="!isReadOnly">
                      <div class='edit'> <span class="table-material-icons" (click)="edit(item)">edit</span></div>
                    </td>
                    <td *ngIf="!isReadOnly">
                      <div class='delete' *ngIf="item.ColName.trim()!=='Aggregate Write-Ins'"> <span
                          class="table-material-icons" (click)="delete(item)">delete</span></div>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <div *ngIf="!isReadOnly" class='edit' style="float: right;" (click)="add()"> <span
                class="table-material-icons">add</span> Add Column
            </div>
          </div>
        </form>
        <app-confirm-dialog></app-confirm-dialog>
        <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
      </div>

    </div>
  </div>
</div>