import { Component } from '@angular/core';
import {
  IGridEditEventArgs,
  IGridKeydownEventArgs,
  IgxGridCell,
  IgxGridComponent,
} from '@infragistics/igniteui-angular';
import { Premiumreportservice } from '../../data-access/services/premiumreportservice';
import { Store } from '@ngrx/store';
import * as premiumReportActions from '../../data-access/premium-report.action';

@Component({
  selector: 'app-igxadaptor',
  templateUrl: './igxadaptor.component.html',
  styleUrls: ['./igxadaptor.component.scss'],
})
export abstract class IgxadaptorComponent {
  currentPremiumsGrid: IgxGridComponent;
  selectText: boolean = true;
  editedCellID: { columnID: any; rowID: any; rowIndex: number };
  columns: any[];
  isUneditableWithTab: boolean = false;
  uneditableCellInfo: object = {};
  public premium_type: 'S' | 'D';
  protected editedCell: any;
  public const_state_column = 1;
  public const_statecode_column = 2;
  prevGridCellValue = '';
  isTypingOverGridCell = false;
  isReadOnly: any;
  rows: any;
  userdefined_row_code: any;
  supplemental_row_code: any;

  constructor(
    public premiumReportService: Premiumreportservice,
    public store: Store
  ) {}

  endEdit() {
    this.currentPremiumsGrid.endEdit();
  }

  clearCellSelection() {
    this.currentPremiumsGrid.clearCellSelection();
  }

  onClickedOutside(e: Event) {
    this.gridClearSelection();
  }

  
  protected avoidFormulaLastColumn(data: any, columns: any, columnInfo: any) {
    if (columnInfo && columnInfo.length > 0) {
      if (
        columnInfo[0].columnNumber == columns.length - 1 &&
        (this.currentPremiumsGrid.data[data.cell.row.index][
          this.const_statecode_column
        ] == this.userdefined_row_code ||
          this.currentPremiumsGrid.data[data.cell.row.index][
            this.const_statecode_column
          ] == this.supplemental_row_code)
      ) {
        this.store.dispatch(
          premiumReportActions.setSelectedCellFormula({
            formula: '',
          })
        );
      }
    }
  }

  protected setGridCellData(rowindex: any, colIindex: any, value: any) {
    console.log('setGridCellData');
    this.currentPremiumsGrid.data[rowindex][colIindex] = value;
  }

  protected isEditable() {
    if (!this.currentPremiumsGrid.selectedCells.length) {
      return false;
    }
    if (this.isReadOnly) {
      return false;
    }
    //validating cells
    for (
      let index = 0;
      index < this.currentPremiumsGrid.selectedCells.length;
      index++
    ) {
      const element = this.currentPremiumsGrid.selectedCells[index];
      if (element.editable == false) {
        // console.log("cell is not editable");
        return false;
      }
      //Fetching cell formula
      const columnIndex = element.column.index;
      const columnInfo = this.columns[columnIndex];
      if (columnInfo.cellTypeId == 'C') {
        // console.log("Calculated column will not be editable");
        return false;
      }
      const rowIndex = element.row.index;
      if (this.rows[rowIndex].cellTypeId == 'C') {
        // console.log("Calculated row will not be editable");
        return false;
      }
    }
    return true;
  }
  protected showError(msg) {
    this.store.dispatch(premiumReportActions.setError({ message: msg }));
  }

  gridClearSelection() {
    this.currentPremiumsGrid.endEdit();
    this.currentPremiumsGrid.clearCellSelection();
  }

  public onCellFocus(event) {
    if (this.selectText) {
      event.target.select();
    }
    this.selectText = true;
  }

  protected isTotalRow = (rowData: any, columnKey: any): boolean => {
    if (columnKey < this.premiumReportService.FIXED_COLUMNS) {
      return false;
    }
    return this.premiumReportService.IsTotalRowData(parseInt(rowData[0]));
  };

  protected onKeydown = (event: KeyboardEvent) => {
    const eventKey = event.key.toLowerCase();
    const eventKeys = [
      'arrowup',
      'arrowdown',
      'arrowright',
      'arrowleft',
      'enter',
      'tab',
    ];
    const isArrowKey = eventKeys.includes(eventKey);
    const excludeKeys = ['enter', 'tab'];

    if (isArrowKey) {
      this.currentPremiumsGrid.cellSelection = excludeKeys.includes(eventKey)
        ? 'single'
        : event.shiftKey
        ? 'multiple'
        : 'single';

      event.stopPropagation();
      event.preventDefault();

      let newRow = this.editedCellID.rowIndex;
      let newCol = this.editedCellID.columnID;

      switch (eventKey) {
        case 'enter':
          newRow += 1;
          const hiddenECount = this.premiumReportService.findHiddenColumnCount(
            newCol,
            this.columns
          );
          if (hiddenECount > 0) {
            newCol -= hiddenECount;
          }
          break;
        case 'arrowright':
          const hiddenRCount = this.premiumReportService.findHiddenColumnCount(
            newCol,
            this.columns
          );
          newCol += 1;
          if (hiddenRCount > 0) {
            newCol -= hiddenRCount;
          }
          break;
        case 'arrowleft':
          const hiddenLCount = this.premiumReportService.findHiddenColumnCount(
            newCol,
            this.columns
          );
          newCol -= 1;
          if (hiddenLCount > 0) {
            newCol -= hiddenLCount;
          }
          break;
        case 'arrowup':
          newRow -= 1;
          const hiddenUCount = this.premiumReportService.findHiddenColumnCount(
            newCol,
            this.columns
          );
          if (hiddenUCount > 0) {
            newCol -= hiddenUCount;
          }
          break;
        case 'arrowdown':
          newRow += 1;
          const hiddenDCount = this.premiumReportService.findHiddenColumnCount(
            newCol,
            this.columns
          );
          if (hiddenDCount > 0) {
            newCol -= hiddenDCount;
          }
          break;
      }

      if (eventKey != 'tab') {
        this.arrowkeynavigate(newRow, newCol);
      }

      if (eventKey === 'tab') {
        const rowIndex = this.isUneditableWithTab
          ? this.uneditableCellInfo['rowIndex']
          : this.editedCellID.rowIndex;
        var columnID = this.isUneditableWithTab
          ? this.uneditableCellInfo['columnID']
          : this.editedCellID.columnID;
        const hiddenTCount = this.premiumReportService.findHiddenColumnCount(
          columnID,
          this.columns
        );
        if (hiddenTCount > 0) {
          columnID -= hiddenTCount;
        }
        if (event.shiftKey) {
          if (columnID == 0) {
            this.arrowkeynavigate(
              rowIndex - 1,
              this.currentPremiumsGrid.visibleColumns.length - 2
            );
          } else {
            this.arrowkeynavigate(rowIndex, columnID - 1);
          }
        } else {
          if (columnID == this.currentPremiumsGrid.visibleColumns.length - 2) {
            this.arrowkeynavigate(rowIndex + 1, 0);
          } else {
            this.arrowkeynavigate(rowIndex, columnID + 1);
          }
        }
      }

      if (event.shiftKey && excludeKeys.indexOf(eventKey) === -1) {
        const cellRange = this.premiumReportService.selectMultipleCells(
          this.currentPremiumsGrid
        );

        setTimeout(() => {
          const range = {
            rowStart: cellRange.rowStart,
            rowEnd: cellRange.rowEnd,
            columnStart: cellRange.columnStart,
            columnEnd: cellRange.columnEnd,
          };
          this.currentPremiumsGrid.selectRange(range);
        }, 0);
      }
    } else {
      if (
        event.target['value'] !=
        this.currentPremiumsGrid.data[this.editedCellID.rowIndex][
          this.editedCellID.columnID
        ]
      ) {
        this.store.dispatch(
          premiumReportActions.setGridDirty({ grid: this.premium_type })
        );
      }
    }
  };

  public arrowkeynavigate(rowindex, columnid) {
    this.currentPremiumsGrid.endEdit();
    this.currentPremiumsGrid.cellSelection = 'multiple';
    this.currentPremiumsGrid.navigateTo(rowindex, columnid, (obj) => {
      obj.target.activate();
      obj.target.setEditMode(true);
      this.currentPremiumsGrid.tbody.nativeElement.focus();
      requestAnimationFrame(() => {
        this.currentPremiumsGrid.notifyChanges();
      });
    });
  }
  public getColWidth(colNumber: number) {
    return this.premiumReportService.getColWidth(
      colNumber,
      this.columns,
      this.premiumReportService.CONST_ROW_NUMBER_COLUMN,
      this.const_state_column,
      this.const_statecode_column
    );
  }

  public editStart(data: IGridEditEventArgs) {
    this.isUneditableWithTab = false;
    this.uneditableCellInfo = {};
    const flag = this.premiumReportService.IsTotalRowData(
      parseInt(data.rowData[0])
    );
    data.cancel = flag;
    const currRow = this.currentPremiumsGrid.rowList.find(
      (row) => row.key === data.rowID
    );
    const currCell = currRow.cells.find(
      (cell) => cell.cellID.columnID === data.cellID.columnID
    );

    this.editedCellID = data.cellID;
    this.editedCell = currCell;
    this.editedCell.nativeElement.addEventListener(
      'keydown',
      this.onKeydown,
      true
    );
  }

  protected isTotalColumn = (rowData: any, columnKey: any): boolean => {
    const columnInfo = this.columns.filter(function (e) {
      return e.columnNumber == columnKey;
    });
    return columnInfo != null && columnInfo[0].cellTypeId == 'C';
  };

  protected isFirstColumn = (rowData: any, columnKey: any): boolean => {
    return columnKey == this.premiumReportService.CONST_ROW_NUMBER_COLUMN;
  };

  protected isExclusive = (rowData: any, columnKey: any): boolean => {
    const columnInfo = this.columns.filter(function (e) {
      return e.Number == columnKey - 2;
    });
    if (columnInfo.length > 0) {
      const rows = columnInfo[0]['ExclRows'];
      if (rows != null) {
        const rowSplit = rows.split(',');
        if (rowSplit.indexOf(String(rowData[0])) >= 0) {
          return true;
        }
      }
    }
    return false;
  };

  public enterEditMode(evt) {
    if (evt.key == 'Tab' && this.isEditable() == false) {
      this.isUneditableWithTab = true;
      this.uneditableCellInfo = {
        columnID: this.currentPremiumsGrid.selectedCells[0].id.columnID,
        rowIndex: this.currentPremiumsGrid.selectedCells[0].id.rowIndex,
      };
      this.onKeydown(evt);
      setTimeout(() => {
        this.isUneditableWithTab = false;
        this.uneditableCellInfo = {};
      }, 0);
    }
    if (evt.ctrlKey == true && evt.keyCode == 67) {
      if (this.currentPremiumsGrid.selectedCells.length > 0) {
        const rowsToCopy = [];
        let minRowIndex = Infinity;
        let minColumnID = Infinity;

        // Find the minimum rowIndex among the selected cells
        for (const cell of this.currentPremiumsGrid.selectedCells) {
          const rowIndex = cell.id.rowIndex;
          const columnID = cell.id.columnID;

          if (rowIndex < minRowIndex) {
            minRowIndex = rowIndex;
          }

          if (columnID < minColumnID) {
            minColumnID = columnID;
          }
        }

        for (const cell of this.currentPremiumsGrid.selectedCells) {
          const rowIndex = cell.id.rowIndex; // Adjust rowIndex to start from 1st row (0th index)
          var columnID = cell.id.columnID;
          const cellValue = this.currentPremiumsGrid.data[rowIndex][columnID];
          const value = cellValue !== null ? cellValue.toString() : '';

          const hiddenCount =
            this.premiumReportService.findHiddenColumnCountFromRange(
              minColumnID,
              columnID,
              this.columns
            );
          if (hiddenCount > 0) {
            columnID -= hiddenCount;
          }

          if (!rowsToCopy[rowIndex - minRowIndex]) {
            rowsToCopy[rowIndex - minRowIndex] = [];
          }
          rowsToCopy[rowIndex - minRowIndex][columnID - minColumnID] = value;
        }
        const clipboardData = rowsToCopy
          .map((row) => row.join('\t'))
          .join('\n');

        // Copy the data to the clipboard
        navigator.clipboard
          .writeText(clipboardData)
          .then(() => {
            //you can give copied to clipboard messge here if needed
          })
          .catch((error) => {
            this.showError('Failed to copy data to clipboard: ' + error);
          });
      }
    }

    if (this.isEditable() == false) {
      return;
    }
    //Cut operation
    if (evt.ctrlKey == true && evt.keyCode == 88) {
      // console.log("Ctrl+x");

      var invalidCells = this.currentPremiumsGrid.selectedCells.filter((p) => {
        return p.column.index < this.premiumReportService.FIXED_COLUMNS;
      });
      if (invalidCells.length == 0) {
        document.execCommand('copy');
        for (
          let index = 0;
          index < this.currentPremiumsGrid.selectedCells.length;
          index++
        ) {
          const cell = this.currentPremiumsGrid.selectedCells[index];
          this.setGridCellData(cell.row.index, cell.column.index, null);
        }
        // this.updateFormulaCells();
        this.store.dispatch(
          premiumReportActions.setGridDirty({ grid: this.premium_type })
        );
      }
    }

    //Allowing to type values by selecting cell
    if (this.currentPremiumsGrid.selectedCells.length == 1) {
      if (
        this.currentPremiumsGrid.selectedCells[0] &&
        this.currentPremiumsGrid.selectedCells[0].active &&
        isNaN(evt.key) == false &&
        this.isEditable() &&
        evt.key != 'Enter' &&
        evt.key != 'ArrowRight' &&
        evt.key != 'ArrowLeft'
      ) {
        this.isTypingOverGridCell = true;
        const _cell = this.currentPremiumsGrid.selectedCells[0];
        this.prevGridCellValue =
          this.currentPremiumsGrid.data[_cell.row.index][_cell.column.index];
        setTimeout(() => {
          this.currentPremiumsGrid.selectedCells[0].editMode = true;
          this.selectText = false;
          this.isTypingOverGridCell = false;
        }, 0);
      } else if (
        this.currentPremiumsGrid.selectedCells[0] &&
        this.currentPremiumsGrid.selectedCells[0].active &&
        evt.key == 'Escape'
      ) {
        this.currentPremiumsGrid.selectedCells[0].update(
          this.prevGridCellValue
        );
        this.currentPremiumsGrid.endEdit();
      }
    }
  }

  public onEditCompleted(data: any) {
    var rowindex = data.cellID.rowIndex;
    var colIindex = data.cellID.columnID;
    if (data.newValue != null && (data.newValue > 0 || data.newValue < 0)) {
      var value = Math.round(parseFloat(data.newValue));
      if (value > 99999999 || value < -99999999) {
        this.store.dispatch(
          premiumReportActions.setGridValidity({
            grid: this.premium_type,
            validity: false,
          })
        );
        this.showError(
          'Value cannot be greater than 99,999,999 or less than -99,999,999.'
        );
        // data.newValue = null;
        this.setGridCellData(rowindex, colIindex, null);
        // this.currentPremiumsGrid.data[rowindex][colIindex] = null;
        // this.updateFormulaCells();
        this.commit();
        return;
      } else {
        this.setGridCellData(rowindex, colIindex, value);
        // this.currentPremiumsGrid.data[rowindex][colIindex] = value;
        // data.newValue = value;
      }
    } else if (data.newValue == null || data.newValue == 0) {
      // data.newValue = null;
      this.setGridCellData(rowindex, colIindex, null);
      // this.currentPremiumsGrid.data[rowindex][colIindex] = null;
    }
    if (data.newValue != data.oldValue) {
      this.store.dispatch(
        premiumReportActions.setGridDirty({ grid: this.premium_type })
      );
    }
    this.editedCell.nativeElement.removeEventListener(
      'keydown',
      this.onKeydown,
      true
    );
    this.commit();
  }

  public enterGridEditMode(args: IGridKeydownEventArgs) {
    const evt: KeyboardEvent = args.event as KeyboardEvent;

    if (!this.isEditable()) {
      if (evt.key !== 'Enter') {
        return;
      }
      this.navigateCell(args, evt.key);
    }

    //Preventing up/down arrow keys
    if (
      (evt.key == 'ArrowUp' || evt.key == 'ArrowDown') &&
      this.currentPremiumsGrid.selectedCells[0] &&
      this.currentPremiumsGrid.selectedCells[0].editMode
    ) {
      evt.preventDefault();
    }

    //Cell Navigations
    if (this.currentPremiumsGrid.selectedCells.length == 1) {
      if (
        evt.key == 'Enter' &&
        this.currentPremiumsGrid.selectedCells[0] &&
        this.currentPremiumsGrid.selectedCells[0].editMode == false
      ) {
        this.currentPremiumsGrid.endEdit();
        this.navigateCell(args, evt.key);
      }
      if (
        evt.key == 'ArrowRight' &&
        this.currentPremiumsGrid.selectedCells[0] &&
        this.currentPremiumsGrid.selectedCells[0].editMode
      ) {
        this.currentPremiumsGrid.endEdit();
        this.navigateCell(args, evt.key);
      }
      if (
        evt.key == 'ArrowLeft' &&
        this.currentPremiumsGrid.selectedCells[0] &&
        this.currentPremiumsGrid.selectedCells[0].editMode
      ) {
        this.currentPremiumsGrid.endEdit();
        this.navigateCell(args, evt.key);
      }
    }
  }

  
  public cellClass = {
    firstRowColor: this.isFirstColumn,
    totalColumn: this.isTotalColumn,
    IsTotalRow: this.isTotalRow,
    IsExclusive: this.isExclusive,
  };


  private navigateCell(args, key) {
    const target: IgxGridCell = args.target as IgxGridCell;
    let rowIndex = target.row.index;
    let colIndex = target.column.visibleIndex;

    switch (key) {
      case 'Enter':
        rowIndex += 1;
        break;
      case 'ArrowRight':
        colIndex += 1;
        break;
      case 'ArrowLeft':
        colIndex -= 1;
        break;
    }

    args.cancel = true;
    this.currentPremiumsGrid.navigateTo(rowIndex, colIndex, (obj) => {
      obj.target.activate();
      obj.target.setEditMode(false);
      this.currentPremiumsGrid.tbody.nativeElement.focus();
      requestAnimationFrame(() => {
        this.currentPremiumsGrid.notifyChanges();
      });
    });
  }
  protected onInputFocus(event) {
    event.cell.editMode = true;
    setTimeout(() => {
      let input = document.getElementById('input') as HTMLInputElement;
      if (input !== null && input !== undefined) {
        input.focus();
        input.select();
      }
    }, 0);
  }

  protected getColName(colIndex, rowIndex) {
    let s = '';
    while (colIndex >= 0) {
      s = String.fromCharCode((colIndex % 26) + 65) + s;
      colIndex = Math.floor(colIndex / 26) - 1;
    }
    if (s.length > 0) {
      s += rowIndex;
    }
    return s;
  }

  public updateRecords(processedData: any[]) {
    if (!this.currentPremiumsGrid.selectedCells.length) {
      return;
    }

    this.currentPremiumsGrid.endEdit();

    if (this.isReadOnly) {
      return;
    }

    this.store.dispatch(
      premiumReportActions.setGridDirty({ grid: this.premium_type })
    );
    let columnIsSelected = false;
    let cell = this.currentPremiumsGrid.selectedCells[0];
    let columnField;
    if (this.currentPremiumsGrid.selectedColumns().length > 0) {
      this.currentPremiumsGrid.verticalScrollContainer.scrollPosition = 0;
      this.currentPremiumsGrid.cdr.detectChanges();
      columnIsSelected = true;
      columnField = this.currentPremiumsGrid.selectedColumns()[0].field;
    }
    //Copied only one cell and pasting to multiple cells
    if (
      processedData.length == 1 &&
      processedData[0].length == 1 &&
      this.currentPremiumsGrid.selectedCells.length > 1
    ) {
      setTimeout(() => {
        const columns = this.currentPremiumsGrid.visibleColumns;
        let currentCell = processedData[0].shift();

        var selectedRanges = this.currentPremiumsGrid.getSelectedRanges();
        for (let range = 0; range < selectedRanges.length; range++) {
          const selectedrange = selectedRanges[range];
          for (
            let rowindex = selectedrange.rowStart;
            rowindex <= selectedrange.rowEnd;
            rowindex++
          ) {
            for (
              let colindex = Number(selectedrange.columnStart);
              colindex <= selectedrange.columnEnd;
              colindex++
            ) {
              if (
                columns[colindex].editable == true &&
                this.rows[rowindex].cellTypeId != 'C' &&
                currentCell != undefined
              ) {
                var value =
                  Math.round(parseFloat(currentCell)) == 0
                    ? null
                    : Math.round(parseFloat(currentCell));
                if ((value > 99999999 || value < -99999999) == false) {
                  value = isNaN(value) ? null : value;
                  this.setGridCellData(rowindex, colindex, value);
                } else {
                  this.showError(
                    'Value cannot be greater than 99,999,999 or less than -99,999,999.'
                  );
                }
              }
            }
          }
        }
        this.currentPremiumsGrid.cdr.detectChanges();
        this.currentPremiumsGrid.tbody.nativeElement.focus();
        this.commit();
        // this.updateFormulaCells();
      }, 100);
    } else {
      setTimeout(() => {
        if (columnIsSelected) {
          cell = this.currentPremiumsGrid.getCellByColumn(0, columnField);
        }
        const pk = this.currentPremiumsGrid.primaryKey;
        if (!cell) {
          return;
        }
        var rowIndex = cell.row.index;
        // const rowPkValue = cell.row.rowData[pk];
        const cellIndex = cell.column.visibleIndex;
        const columns = this.currentPremiumsGrid.visibleColumns;
        let newRowsIndex = 1;
        const updatedRecsPK = [];
        for (const curentDataRow of processedData) {
          const rowData = {};
          const data = this.currentPremiumsGrid.filteredSortedData.length
            ? this.currentPremiumsGrid.filteredSortedData
            : this.currentPremiumsGrid.data;
          const dataRec = data[rowIndex];
          const rowPkValue = dataRec
            ? dataRec[pk]
            : this.currentPremiumsGrid.data.length + newRowsIndex;
          rowData[pk] = rowPkValue;
          for (let j = 0; j < columns.length; j++) {
            let currentCell;
            if (j >= cellIndex) {
              currentCell = curentDataRow.shift();
              if (currentCell != null) {
                currentCell = currentCell.replace(/,/g, '');
              }
            }
            const colKey = columns[j].field;
            if (
              columns[j].editable == true &&
              this.rows[rowIndex].cellTypeId != 'C' &&
              currentCell != undefined
            ) {
              if (parseInt(colKey) >= this.premiumReportService.FIXED_COLUMNS) {
                var value =
                  Math.round(parseFloat(currentCell)) == 0
                    ? null
                    : Math.round(parseFloat(currentCell));
                var valRounded = isNaN(value) ? null : Math.round(value);
                if (valRounded != null && (valRounded > 0 || valRounded < 0)) {
                  if (
                    (valRounded > 99999999 || valRounded < -99999999) == false
                  ) {
                    rowData[colKey] = valRounded;
                    //Selecting the cells
                    const range = {
                      rowStart: rowIndex,
                      rowEnd: rowIndex,
                      columnStart: j,
                      columnEnd: j,
                    };
                    this.currentPremiumsGrid.selectRange(range);
                  } else {
                    this.showError(
                      'Value cannot be greater than 99,999,999 or less than -99,999,999.'
                    );
                  }
                }
              } else {
                rowData[colKey] =
                  currentCell || (!!dataRec ? dataRec[colKey] : null);
              }
            } else {
              rowData[colKey] = dataRec[colKey];
            }
          }
          if (!dataRec) {
            // no rec to update, add instead
            rowData[pk] = rowPkValue;
            this.currentPremiumsGrid.addRow(rowData);
            newRowsIndex += 1;
            // continue;
          }
          this.currentPremiumsGrid.updateRow(rowData, rowPkValue);
          this.currentPremiumsGrid.cdr.detectChanges();
          updatedRecsPK.push(rowPkValue);
          rowIndex++;
        }
        this.currentPremiumsGrid.tbody.nativeElement.focus();
        this.commit();
      }, 100);
    }
    this.currentPremiumsGrid.selectionService.clearAllSelectedColumns();
  }

  abstract commit();
}
