<div class="container">
    <div class="messagetitle">
        <i class="table-material-icons">error</i>
        Page Not Found
    </div>
    <div class="messagebody">
        <p>
            Could not find the page you are looking for
        </p>
    </div>
</div>