<app-loading-screen *ngIf="showsubmittedcompanies"
  >Loading Companies</app-loading-screen
>
<app-loading-screen *ngIf="showcompaniesaftercopy"
  >Copying Data</app-loading-screen
>
<div>
  <div class="pagetitle">Prior Year Copy: {{ currentYear }}</div>
  <div class="information" *ngIf="showPageError">
    <div class="messagetitle">
      <i class="table-material-icons">error</i> {{ pageError }}
    </div>
  </div>
</div>

<div class="row">
  <div class="form-group col-md-6">
    <div class="searchinputleft">
      <div class="searchcont">
        <label class="lib-text-field">
          <input
            placeholder="SEARCH PRF NUMBER"
            id="search"
            style="width: 115%; padding: 16px 5px 4px 5px"
            autocomplete="off"
            (keyup)="onenter($event)"
          />
        </label>
        <div class="searchiconbutton">
          <i
            class="table-material-icons"
            (click)="searchonclick()"
            style="color: #d3d4d5; cursor: pointer; background: #ffffff"
            >search</i
          >
        </div>
      </div>
    </div>
  </div>
  <div class="form-group col-md-6">
    <label for="" style="display: none">&nbsp;test</label>
    <br />
    <div class="inputleft">
      <app-button
        [disabled]="isReadOnlyAdmin"
        [ngClass]="{
          active: !isReadOnlyAdmin,
          disabled: isReadOnlyAdmin
        }"
        (click)="oncopyfromprioryear()"
        buttonType="primary"
        style="width: auto; float: right"
        >Copy From Previous Year</app-button
      >
    </div>
  </div>
</div>
<div *ngIf="show" style="text-align: left; margin-top: 20px">
  <div style="font-weight: bold">Company List : {{ currentYear - 1 }}</div>
  <br />
  <div>
    <table class="table table-hover">
      <thead>
        <tr>
          <th style="width: 30px" class="text-left" scope="col">
            <input
              type="checkbox"
              [checked]="allchecked"
              (change)="checkAllCheckBox($event)"
            />
          </th>
          <th
            style="width: 100px"
            class="text-left"
            scope="col"
            [appSort]="companylist"
            data-name="status"
            class="sorted"
          >
            Status
            <i class="table-material-icons">arrow_drop_down</i>
          </th>
          <th
            style="width: 100px"
            class="text-left"
            scope="col"
            [appSort]="companylist"
            data-order="desc"
            data-name="compno"
            class="sorted"
          >
            PRF Number
            <i class="table-material-icons">arrow_drop_down</i>
          </th>
          <th
            style="width: 200px"
            class="text-left"
            scope="col"
            [appSort]="companylist"
            data-order="desc"
            data-name="compname"
            class="sorted"
          >
            Company Name
            <i class="table-material-icons">arrow_drop_down</i>
          </th>
          <th
            style="width: 100px"
            class="text-left"
            scope="col"
            [appSort]="companylist"
            data-order="desc"
            data-name="modifiedby"
            class="sorted"
          >
            Modified By
            <i class="table-material-icons">arrow_drop_down</i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of companylist; let i = index">
          <td>
            <input
              type="checkbox"
              value="{{ item.compno }}"
              [(ngModel)]="companylist[i].checked"
              (change)="OncheckBoxChecked($event)"
            />
          </td>
          <td>
            <container-element [ngSwitch]="item.status">
              <div
                class="locked"
                *ngSwitchCase="'Submitted'"
                placement="bottom"
              >
                <span class="table-material-icons">lock</span> Submitted
              </div>
              <div
                class="verified"
                *ngSwitchCase="'Uploaded'"
                placement="bottom"
              >
                <span class="table-material-icons">verified_user</span> Uploaded
              </div>
              <div *ngSwitchDefault placement="bottom">-</div>
            </container-element>
          </td>
          <td>{{ item.compno }}</td>
          <td>{{ item.compname }}</td>
          <td>{{ item.modifiedby }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
