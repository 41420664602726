<div class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="loading-screen" *ngIf="isLoading">
        <div class="loading">
            <div id="loader"></div>
            <div class="loading-content">
              <p>
                Loading Address...
              </p>
            </div>
        </div>
      </div>
      <div class="modal-header">
        <h4 class="pagetitle">{{ title }}</h4>
        <span
          class="table-material-icons close-button"
          (click)="activeModal.dismiss('Cross click')"
          >close</span
        >
      </div>
      <div class="confirm-information" *ngIf="showPageError">
        <div class="messagetitle">No address data available for company number: {{ companyNo }}</div>
      </div>
      <div class="modal-body">
        <div class="inputcontent">
          <div class="form-check form-check-inline">
            <input
              id="pbsadd"
              class="form-check-input"
              type="radio"
              name="selectaddresstype"
              value="pbs"
              checked
              (change)="onAddressTypeChange($event)"
            />
            <label for="pbsadd" class="form-check-label interactive-links">
              Billing Address</label
            >
          </div>
          <div class="form-check form-check-inline radio-button-pdb">
            <input
              id="pdbadd"
              class="form-check-input"
              type="radio"
              name="selectaddresstype"
              value="pdb"
              (change)="onAddressTypeChange($event)"
            />
            <label
              for="pdbadd"
              class="form-check-label interactive-links"
            >
            Core Line Services Address</label
            >
          </div>
`
            <div class="dropdown-label">Address</div>
            <mat-form-field appearance="fill">
              <mat-select [(value)]="selectedAddressvalue"
               (selectionChange)="onAddressSelect($event)">
                <mat-option *ngFor="let item of filteredAddressList;" [value]="item">
                  {{ item }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          <div class="textarea-div">
            <textarea
              matInput
              readonly
              [(ngModel)]="selectedAddressForTextArea"
              value="selectedAddressForTextArea"
              rows="8"
              cols="124"
              class="textarea-content"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <app-button
          size="medium"
          (click)="populateAddress()"
          buttonType="primary"
          class="select-button"
          [disabled]="!selectedAddress || Object.keys(selectedAddress).length === 0"
        >
          Select</app-button
        >
        <app-button
          size="medium"
          (click)="activeModal.dismiss('Cross click')"
          buttonType="secondary"
        >
          Close</app-button
        >
      </div>
    </div>
  </div>
</div>