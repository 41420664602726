<app-loading-screen *ngIf="showCompanyLoader"
  >Loading Companies</app-loading-screen
>
<app-loading-screen *ngIf="showDeleteLoader"
  >Deleting Company</app-loading-screen
>
<app-loading-screen *ngIf="showUploadLoader"
  >Uploading Companies</app-loading-screen
>

<div style="float: right; display: flex; align-items: center">
  <div *ngIf="!isReadOnlyAdmin" style="margin-right: 10px">
    <app-button
      size="medium"
      buttonType="primary"
      (click)="uploadSelectedCompanies()"
      [disabled]="!isAnyCompaniesSelected"
      [ngClass]="{
        active: isAnyCompaniesSelected,
        disabled: !isAnyCompaniesSelected
      }"
    >
      Upload Selected
    </app-button>
  </div>
  <div *ngIf="!isReadOnlyAdmin">
    <app-button size="medium" buttonType="primary" (click)="openGroup()"
      >Create New Group</app-button
    >
  </div>
</div>
<br />
<div class="pagetitle">Premium Report Administration : {{ currentYear }}</div>
<div style="float: right; width: 40%">
  <div class="export" style="display: inline-block; float: right">
    <button (click)="exportExcel()" class="interactive-links">
      <i class="table-material-icons">get_app</i>Export to Excel
    </button>
  </div>
</div>
<div class="pagetitlesubtext">
  Select a PRF number from the table to review a premium report.
</div>
<div class="search-container">
  <div class="inputleft">
    <label class="lib-text-field">
      <span class="lib-placeholder focus"></span>
      <div style="margin-top: 33px">
        <mat-form-field *ngIf="yearsLoaded">
          <!-- <mat-label>Select status</mat-label> -->
          <mat-select
            [(value)]="selectedYear"
            (selectionChange)="getCompanyForYear($event)"
          >
            <mat-option *ngFor="let year of yearList" [value]="year">{{
              year
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </label>
  </div>
  <div class="inputright">
    <div class="searchcont">
      <label class="lib-text-field">
        <input
          placeholder="SEARCH PRF NUMBER"
          id="search"
          [(ngModel)]="searchValue"
          (keyup)="onenter($event)"
          style="width: 115%; padding: 16px 5px 15px 5px"
          autocomplete="off"
        />
      </label>
      <div class="searchiconbutton">
        <i
          class="table-material-icons"
          (click)="searchonclick()"
          style="color: #d3d4d5; cursor: pointer; background: #ffffff"
          >search</i
        >
      </div>
    </div>
  </div>

  <div class="company-list" *ngIf="!isReadOnlyAdmin">
    <mat-form-field>
      <mat-select
        [(value)]="selectedListType"
        (selectionChange)="getcompanies($event)"
        class="topunit"
      >
        <mat-option
          *ngFor="let option of filterListTypes"
          [value]="option.value"
          >{{ option.label }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>

  <div class="status-list" *ngIf="!isReadOnlyAdmin">
    <mat-form-field>
      <mat-select
        [(value)]="filterStatusSelectedLabel"
        (selectionChange)="getcompaniesByStatus($event)"
      >
        <mat-option *ngFor="let status of filterStatusList" [value]="status">{{
          status
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
