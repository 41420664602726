import { Injectable } from '@angular/core';
import { Subject } from "rxjs";
import { environment } from 'src/environments/environment';
import { HttpService } from '../shared/http.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  disablesidemenu = new Subject<boolean>();
  disablecommonmenu = new Subject<boolean>();
  public userid: string
  constructor(private httpService: HttpService) { 
    if (environment.enviroment_code == "D") {
      this.userid = localStorage.getItem("userid");
    }
    else {
      this.userid = this.httpService.getIsonetValue("userid");
    }
  }

  disablefunc() {
    this.disablesidemenu.next(false);
  }

  loadAnnualStatementComparison(year: number, groupcode: string, compno: string) {
    let ticket = this.httpService.getIsonetValue('ticket')
    const data = { 'prfyear': year, 'groupcode': groupcode, 'companyno': compno, 'prfuser': this.userid, 'ticket': ticket };
    return this.httpService.functionPost(`getascomparisonreport`, data);
  }

  loadNAICMapping(year: number) {
    const data = { 'prfyear': year };
    return this.httpService.functionPost(`getnaicmapping`, data);
  }

  loadPriorYearComparisonDetails(year: number, groupcode: string, compno: string,compareto:string) {
    let ticket = this.httpService.getIsonetValue('ticket')
    const data = { 'prfyear': year, 'groupcode': groupcode, 'companyno': compno,'compareto':compareto, 'prfuser': this.userid, 'ticket': ticket };
    return this.httpService.functionPost(`getprioryearpremcomparisondetails`, data);
  }

  sendMail(receivers, cc, bcc, subject, message) {
    const data = {
      'receivers': receivers, 'bcc': bcc,
      'cc': cc, 'subject': subject, 'message': message
    };
    return this.httpService.functionPost(`sendemail`, data);
  }
  getMetricsReportData(year: number, startDate, endDate, reportType) {
    const data = {
      'prfyear': year,
      'startdate': startDate,
      'enddate': endDate,
      'rpttype': reportType
    };
    return this.httpService.functionPost(`getmetricsrptdata`, data);
  }
  getEmailPreviewDetailsForValidationSummary(
    year: number,
    groupcode: string,
    compno: string,
    jobId: any
  ) {
    let ticket = this.httpService.getIsonetValue('ticket');
    const data = {
      prfyear: year,
      groupcode: groupcode,
      companyno: compno,
      prfuser: this.userid,
      ticket: ticket,
      jobId: jobId
    };
    return this.httpService.functionPost(`getvalidationsummaryemailinfo`, data);
  }
}
