import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ClickOutsideModule } from 'ng-click-outside';

@Component({
  selector: 'app-select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.scss'],
  standalone: true,
  imports: [CommonModule, ClickOutsideModule],
})
export class SelectDropdownComponent implements OnInit {
  showOptionList: boolean = false;
  selectedOption: string = '';
  selectedValue: string = '';
  scrollTop;
  showonTop: boolean = false;

  optionList: Array<{ value: string; option: string }> = [];
  @ViewChild('content', { read: ElementRef, static: true }) content: ElementRef;
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();

  constructor(private renderer: Renderer2) {
    /*
  if(!this.showOptionList){
    this.renderer.listen('window', 'click',(e:Event)=>{
      /**
       * Only run when Button is not clicked
       * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu

     if(e.target !== this.content.nativeElement){

         this.hideOptionList();
     }
 });}
*/
  }

  ngOnInit(): void {
    this.selectedValue = '';
    this.selectedOption = '';
    this.optionList = [];
    // this.renderer.listen('window', 'click', (e: Event) => {
    //   console.log(this.content.nativeElement)
    //   console.log(e.target)
    //   console.log(this.content.nativeElement.contains(e.target))
    //   console.log("....................")
    //   if (!this.content.nativeElement.contains(e.target)) {
    //     if (this.showOptionList) {
    //       this.hideOptionList();
    //     }
    //   }
    // });
  }

  ngOnChanges() {
    this.selectedValue = '';
    this.selectedOption = '';
    this.optionList = [];
    this.refresh();
  }

  ngAfterContentInit() {
    this.refresh();
  }
  refresh() {
    if (this.content.nativeElement)
      for (var i = 0; i < this.content.nativeElement.length; i++) {
        this.optionList.push({
          value: this.content.nativeElement[i].getAttribute('value'),
          option: this.content.nativeElement[i].innerHTML,
        });
        if (
          this.content.nativeElement[i].getAttribute('selected') != null &&
          this.content.nativeElement[i].getAttribute('selected') != 'false'
        ) {
          this.selectedValue = this.optionList[i].value;
          this.selectedOption = this.optionList[i].option;
        }
      }
    if (this.selectedValue == '' && this.selectedOption == '') {
      this.selectedValue =
        this.optionList[0] !== undefined ? this.optionList[0].value : '';
      this.selectedOption =
        this.optionList[0] !== undefined ? this.optionList[0].option : '';
    }
  }

  showOptions(e) {
    if (window.innerHeight > 240) {
      if (window.innerHeight < e.target.getBoundingClientRect().y + 200) {
        window.scrollBy(0, 220);
      }
    }
    this.showOptionList = !this.showOptionList;
  }

  hideOptionList() {
    console.log('outside click');
    this.showOptionList = false;
  }

  onContentChange(changes: MutationRecord[]) {
    // logs everything that changed
    // changes.forEach(change => console.log(change.target.data));
    this.refresh();
 }

  select(option, value) {
    this.selectedOption = option;
    this.selectedValue = value;
    this.showOptionList = false;
    let arr = { option: this.selectedOption, value: this.selectedValue };
    this.onSelect.emit(arr);
  }
}
