// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  enviroment_code: "#{EnvCode}",
  apiUrl: "#{ApiUrl}",
  AUTH_ISSUER_URL: "#{IdentityUrl}",
  CLIENT_ID: "#{ClientId}",
  PRODUCTS_URL: "#{ProductsUrl}",
  SESSION_TIME_OUT: 3000,
  IDLE_TIME_OUT: 6000,
  MasterId: 5847,
  ViewerId: "#{ViewerId}",
  ProductId: "#{ProductId}",
  ProductIdAlias: "#{ProductIdAlias}",
  Unexpected_Msg: "#{UnexpectedMsg}",
  prfmailid: "#{MailId}",
  IsoAuthService: "#{IsoAuthService}",
  adobeLaunchCode: "#{AdobeLaunchCode}",
  OKTA_INTEGRATION_JS: "#{OktaIntegrationJs}",
  GATEWAY_LOGOUT: "#{GatewayLogout}",
  IDENTITY_LOGOUT: "#{IdentityLogout}",
  EligibilityUrl:"#{EligibilityUrl}",
};

// import * as json from "../assets/jsons/env.json";
// export const environment = json;
