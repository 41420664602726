import { Component, Input, OnInit } from '@angular/core';
import { PremiumColumn } from '../../data-access/grid-config-model';
import { Store } from '@ngrx/store';
import {
  toggleDirectColumnSelectAll,
  toggleSupplementalColumnSelectAll,
  updateDirectPremiumColumnsAction,
  updateSupplementalPremiumColumnsAction,
} from '../../data-access/grid-config.actions';

@Component({
  selector: 'premium-columns-configuration',
  templateUrl: './premium-columns.component.html',
  styleUrls: ['./premium-columns.component.scss'],
})
export class PremiumColumnComponent implements OnInit{
  @Input() premiumType: string;
  @Input() premiumColumns: PremiumColumn<any>[];
  @Input() isAllColumnSelected: boolean;

  constructor(private store: Store) {}
  ngOnInit(): void {
    console.log(this.premiumType);
  }

  toggleSelectAll(): void {
    if (this.premiumType === 'D') {
      this.store.dispatch(toggleDirectColumnSelectAll());
    } else {
      this.store.dispatch(toggleSupplementalColumnSelectAll());
    }
  }

  toggleSelectColumn(column: PremiumColumn<any>): void {
    if (this.premiumType === 'D') {
      this.store.dispatch(
        updateDirectPremiumColumnsAction({ directPremiumColumn: column })
      );
    } else {
      this.store.dispatch(
        updateSupplementalPremiumColumnsAction({
          supplementalPremiumColumn: column,
        })
      );
    }
  }
}
