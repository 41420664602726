import { environment } from 'src/environments/environment';
// IgxExcelExporterService no longer need to be manually provided and can be safely removed.
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';

import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  IgxExcelExporterService,
  IgxGridModule,
  IgxLayoutModule,
  IgxNavbarModule,
  IgxNavigationDrawerModule,
  IgxRippleModule,
} from '@infragistics/igniteui-angular';
import { AppRoutingModule } from './app-routing.module';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { OAuthModule } from 'angular-oauth2-oidc';
import { IgxExcelModule } from 'igniteui-angular-excel';
import { AuthGuard } from './AuthGuard';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HttpService } from './shared/http.service';
import { PasteHandler } from './shared/paste-handler.directive';
import { CookieService } from 'ngx-cookie-service';
import { AnnualstatementComponent } from './company/annualstatement/annualstatement.component';
import { CommentsComponent } from './company/comments/comments.component';
import { CompanyinformationComponent } from './company/companyinformation/companyinformation.component';
import { CompanylistComponent } from './company/companylist/companylist.component';
import { GroupreportingComponent } from './company/groupreporting/groupreporting.component';
import { UserdefineddataComponent } from './company/userdefineddata/userdefineddata.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HelppageComponent } from './helppage/helppage.component';
import { NavigationComponent } from './navigation/navigation.component';

import { CompanyService } from './company/company.service';
import { ButtonComponent } from './ui-components/button/button.component';
import { LoadingScreenComponent } from './ui-components/loading-screen/loading-screen.component';
import { ModalComponent } from './ui-components/modal/modal.component';
import { SelectDropdownComponent } from './ui-components/select-dropdown/select-dropdown.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddEditUserDefinedColsComponent } from './company/add-edit-user-defined-cols/add-edit-user-defined-cols.component';
import { AddGroupReportingComponent } from './company/add-group-reporting/add-group-reporting.component';
import { CommentsModalComponent } from './company/comments-modal/comments-modal.component';
import { GridConfigurationComponent } from './company/grid-configuration/grid-configuration.component';
import { PremiumColumnComponent } from './company/grid-configuration/ui/config-premium-columns/premium-columns.component';
import { StatesComponent } from './company/grid-configuration/ui/config-states/states.component';
import { UploadPremiumComponent } from './company/upload-premium/upload-premium.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from './confirm-dialog/confirm-dialog.service';
import {
  CanDeactivateGuardAnualStatement,
  CanDeactivateGuardComment,
  CanDeactivateGuardCompanyInfo,
  CanDeactivateGuardGroupReporting,
  CanDeactivateGuardPremiumReport,
  CanDeactivateGuardUserdefineddata,
} from './shared/can-deactivate-guard.service';
import { UnsavedmsgModalComponent } from './unsavedmsg-modal/unsavedmsg-modal.component';

import { ClickOutsideModule } from 'ng-click-outside';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EmailPreviewComponent } from './email-preview/email-preview.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SortDirective } from './shared/sort.directive';
import { SortEmitterDirective } from './shared/sortEmitter.directive';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AdobelaunchService } from './_services/adobelaunch.service';
import { AlertmodalComponent } from './alertmodal/alertmodal.component';
import { ApifailuremessageComponent } from './apifailuremessage/apifailuremessage.component';
import { AuthInterceptor } from './auth.interceptor';
import { GridConfigEffects } from './company/grid-configuration/data-access/grid-config.effects';
import { gridConfigReducer } from './company/grid-configuration/data-access/grid-config.reducer';
import { PremiumReportEffects } from './company/premiumreport/data-access/premium-report.effects';
import { premiumReportReducer } from './company/premiumreport/data-access/reducers/premium-report.reducer';
import { DirectPremiumsComponent } from './company/premiumreport/features/direct-premiums/direct-premiums.component';
import { SupplementalPremiumsComponent } from './company/premiumreport/features/supplemental-premiums/supplemental-premiums.component';
import { PremiumReportComponent } from './company/premiumreport/premium-report.component';
import { UserdefinedcolsmodalComponent } from './company/userdefinedcolsmodal/userdefinedcolsmodal.component';
import { GroupmodalComponent } from './groupmodal/groupmodal.component';
import { ModalContainerComponent } from './opensubmissionmodal';
import { ReportsubmissionComponent } from './reportsubmission/reportsubmission.component';
import { PrfSelectModule } from './shared/ui/prf-select/prf-select.module';
import { appReducer } from './store/reducers/app.reducer';
import { ToastContainerModule } from './toaster-container/toast-container.module';
import { ModalLoadingScreenComponent } from './ui-components/modal-loading-screen/modal-loading-screen.component';
import { UserdefinedspinnerComponent } from './ui-components/userdefinedspinner/userdefinedspinner.component';
import { AccordionComponent } from './company/validation-summary/ui/accordion/accordion.component';
import { ValidationDetailComponent } from './company/validation-summary/ui/validation-detail/validation-detail.component';
import { ValidationHeaderComponent } from './company/validation-summary/ui/validation-header/validation-header.component';
import { ValidationSummaryComponent } from './company/validation-summary/validation-summary.component';
import { HiddenConfigWarningPopupComponent } from './reportsubmission/UI/hidden-config-warning-popup/hidden-config-warning-popup.component';
import { IconComponent } from './shared/ui/icon/icon.component';
import { UnlockIconComponent } from './shared/ui/unlock-icon/unlock-icon.component';
import { ValidationIconComponent } from './shared/ui/validation-icon/validation-icon.component';
import { ValidationSummaryEffects } from './company/validation-summary/data-access/validation-summary-effects';
import { validationSummaryReducer } from './company/validation-summary/data-access/validation-summary-reducer';
import { ValidationDetailContentComponent } from './company/validation-summary/ui/validation-detail-content/validation-detail-content.component';
import { ValidationRuleComponent } from './company/validation-summary/ui/validation-rule/validation-rule.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { WrapperComponent } from './navigation/wrapper/wrapper.component';
import { ObserversModule } from '@angular/cdk/observers';
import { ModifyHeader } from './company/premiumreport/data-access/utilities/modifyHeaders';
import { ValidationEmailPreviewComponent } from './company/validation-summary/email-preview/validation-email-preview/validation-email-preview.component';
import { RedirecttovalidationsummaryComponent } from './company/validation-summary/redirect-to-validation-summary/redirecttovalidationsummary/redirecttovalidationsummary.component';
import { CompanyListEffects } from './company/companylist/data-access/company-list-effects';
import { CustomerCompanyListHeaderComponent } from './company/companylist/ui/company-list-header/company-list-header.component';
import { CompanyListFilterComponent } from './company/companylist/ui/company-list-filter/company-list-filter.component';
import { CustomerCompanyListComponent } from './company/companylist/ui/company-list/company-list.component';
import { PopulateaddressComponent } from './company/companyinformation/populateaddress/populateaddress.component';
import { RenameconfirmComponent } from './company/companyinformation/renameconfirm/renameconfirm.component';
import { DeletecompanyComponent } from './company/companylist/ui/deletecompany/deletecompany.component';
import { CompanyListPaginationComponent } from './company/company-list-pagination.component/ui/company-list-pagination/company-list-pagination.component';
import { PrioryearcomparisonComponent } from './company/prioryearcomparison/prioryearcomparison.component';
import { AnnualstatementcomparisonComponent } from './company/annualstatementcomparison/annualstatementcomparison.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PasteHandler,
    ModifyHeader,
    // MatConfirmDialogComponent,
    HeaderComponent,
    FooterComponent,
    NavigationComponent,
    CompanylistComponent,
    CompanyinformationComponent,
    GroupreportingComponent,
    AnnualstatementComponent,
    UserdefineddataComponent,
    CommentsComponent,
    HelppageComponent,
    ModalComponent,
    ButtonComponent,
    // SelectDropdownComponent,
    LoadingScreenComponent,
    UploadPremiumComponent,
    GridConfigurationComponent,
    StatesComponent,
    PremiumColumnComponent,
    AddEditUserDefinedColsComponent,
    CommentsModalComponent,
    UnsavedmsgModalComponent,
    AddGroupReportingComponent,
    ConfirmDialogComponent,
    NotFoundComponent,
    DashboardComponent,
    SortDirective,
    SortEmitterDirective,
    EmailPreviewComponent,
    AlertmodalComponent,
    ReportsubmissionComponent,
    UserdefinedcolsmodalComponent,
    ApifailuremessageComponent,
    GroupmodalComponent,
    ModalLoadingScreenComponent,
    ModalContainerComponent,
    UserdefinedspinnerComponent,
    DirectPremiumsComponent,
    SupplementalPremiumsComponent,
    PremiumReportComponent,
    IconComponent,
    HiddenConfigWarningPopupComponent,
    ValidationSummaryComponent,
    ValidationHeaderComponent,
    ValidationDetailComponent,
    AccordionComponent,
    UnlockIconComponent,
    ValidationIconComponent,
    ValidationDetailContentComponent,
    ValidationRuleComponent,
    LandingpageComponent,
    WrapperComponent,
    ValidationEmailPreviewComponent,
    RedirecttovalidationsummaryComponent,
    CustomerCompanyListHeaderComponent,
    CompanyListFilterComponent,
    CustomerCompanyListComponent,
    PopulateaddressComponent,
    RenameconfirmComponent,
    DeletecompanyComponent,
    CompanyListPaginationComponent,
    PrioryearcomparisonComponent,
    AnnualstatementcomparisonComponent
  ],
  bootstrap: [AppComponent], imports: [ToastContainerModule,
    FormsModule,
    MatCardModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatFormFieldModule,
    MatDividerModule,
    BrowserModule,
    HammerModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    IgxNavigationDrawerModule,
    IgxNavbarModule,
    IgxLayoutModule,
    IgxRippleModule,
    IgxGridModule,
    IgxExcelModule,
    OAuthModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    OverlayModule,
    NgbModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    MatButtonToggleModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatExpansionModule,
    SelectDropdownComponent,
    PrfSelectModule,
    ObserversModule,
    MatSelectModule,
    EditorModule,
    StoreModule.forRoot({
      app: appReducer,
      config: gridConfigReducer,
      premiumReport: premiumReportReducer,
      validationSummary: validationSummaryReducer,
  }, {
      runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
      },
  }),
  StoreDevtoolsModule.instrument({
      name: 'App DevTools',
      logOnly: environment.production,
  }),
  EffectsModule.forRoot([
      GridConfigEffects,
      PremiumReportEffects,
      ValidationSummaryEffects,
      CompanyListEffects
  ])], providers: [
  IgxExcelExporterService,
  HttpService,
  AuthGuard,
  CookieService,
  CompanyService,
  CanDeactivateGuardAnualStatement,
  CanDeactivateGuardCompanyInfo,
  CanDeactivateGuardGroupReporting,
  CanDeactivateGuardPremiumReport,
  CanDeactivateGuardUserdefineddata,
  CanDeactivateGuardComment,
  ConfirmDialogService,
  AdobelaunchService,
  {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
  },
  { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
  provideHttpClient(withInterceptorsFromDi()),
] })
export class AppModule {}