import { deepCopy } from 'src/app/shared/utils/data.util';
import { PremiumReportState } from '../premium-report.models';


export const onRefreshCopyWithCurrent = (
  state: PremiumReportState
): PremiumReportState => {
  return {
    ...state,
    premiumData: {
      ...state.premiumData,
      D: {
        ...state.premiumData.D,
        premiumGridDataOriginalCopy: deepCopy([ ...state.premiumData.D.premiumGridData ]),
      },
      S: {
        ...state.premiumData.S,
        premiumGridDataOriginalCopy: deepCopy([ ...state.premiumData.S.premiumGridData ]),
      },
    },
  };
};

export const onResetDirectWithCopy = (
  state: PremiumReportState
): PremiumReportState => {
  return {
    ...state,
    premiumData: {
      ...state.premiumData,
      D: {
        ...state.premiumData.D,
        isDirty:false,
        premiumGridData: deepCopy([ ...state.premiumData.D.premiumGridDataOriginalCopy ]),
      },
    },
  };
};
export const onResetSupplementalWithCopy = (
  state: PremiumReportState
): PremiumReportState => {
  return {
    ...state,

    premiumData: {
      ...state.premiumData,
      S: {
        ...state.premiumData.S,
        isDirty:false,
        premiumGridData: deepCopy([...state.premiumData.S.premiumGridDataOriginalCopy ]),
      },
    },
  };
};
