<app-loading-screen *ngIf="show">Loading Email</app-loading-screen>
<form [formGroup]="emailForm" (ngSubmit)="onsubmit()">

    <div [ngClass]="{
        'has-error':
        emailForm.get('toaddr').errors &&
          (emailForm.get('toaddr').touched ||
          emailForm.get('toaddr').dirty)
      }">
        <label class="lib-text-field" required>
            <input class="fullWidth" class="fullWidth" placeholder=" " formControlName="toaddr" />
            <span class="lib-placeholder">To</span>
            <span class="help-block" *ngIf="
            emailForm.get('toaddr').errors &&
          (emailForm.get('toaddr').touched ||
          emailForm.get('toaddr').dirty)
        ">
                <span *ngIf="emailForm.get('toaddr').errors.required" class="inputMsg"><i
                        class="table-material-icons">error</i> Email is
                    required</span>
                <span *ngIf="emailForm.get('toaddr').errors.pattern" class="inputMsg"><i
                        class="table-material-icons">error</i> Please enter a
                    valid email</span>
            </span>
        </label>
    </div>
    <div [ngClass]="{
        'has-error':
        emailForm.get('ccaddr').errors &&
          (emailForm.get('ccaddr').touched ||
          emailForm.get('ccaddr').dirty)
      }">
        <label class="lib-text-field">
            <input class="fullWidth" class="fullWidth" placeholder=" " formControlName="ccaddr" />
            <span class="lib-placeholder">Cc</span>
            <span class="help-block" *ngIf="
            emailForm.get('ccaddr').errors &&
          (emailForm.get('ccaddr').touched ||
          emailForm.get('ccaddr').dirty)
        ">
                <span *ngIf="emailForm.get('ccaddr').errors.pattern" class="inputMsg"><i
                        class="table-material-icons">error</i> Please enter a
                    valid email</span>
            </span>
        </label>
    </div>
    <div [ngClass]="{
        'has-error':
        emailForm.get('bccaddr').errors &&
          (emailForm.get('bccaddr').touched ||
          emailForm.get('bccaddr').dirty)
      }">
        <label class="lib-text-field">
            <input class="fullWidth" class="fullWidth" placeholder=" " formControlName="bccaddr" />
            <span class="lib-placeholder">Bcc</span>
            <span class="help-block" *ngIf="
            emailForm.get('bccaddr').errors &&
          (emailForm.get('bccaddr').touched ||
          emailForm.get('bccaddr').dirty)
        ">
                <span *ngIf="emailForm.get('bccaddr').errors.pattern" class="inputMsg"><i
                        class="table-material-icons">error</i> Please enter a
                    valid email</span>
            </span>
        </label>
    </div>
    <div [ngClass]="{
        'has-error':
        emailForm.get('subject').errors &&
          (emailForm.get('subject').touched ||
          emailForm.get('subject').dirty)
      }">
        <label class="lib-text-field" required>
            <input class="fullWidth" class="fullWidth" placeholder=" " formControlName="subject" />
            <span class="lib-placeholder">Subject</span>
            <span class="help-block" *ngIf="
            emailForm.get('subject').errors &&
          (emailForm.get('subject').touched ||
          emailForm.get('subject').dirty)
        ">
                <span *ngIf="emailForm.get('subject').errors.required" class="inputMsg"><i
                        class="table-material-icons">error</i> Subject is
                    required</span>
            </span>
        </label>
    </div>
    <div class="div-content" >
        <img src="../../assets/img/maillogo.png" alt="" height="100" style="margin-top: 3%;" />

        <div [innerHTML]="contentBody" style="margin-top: 3%;">

        </div>
    </div>
    <div class="inputright" [ngClass]="{
        'has-error':
        emailForm.get('phone').errors &&
          (emailForm.get('phone').touched ||
          emailForm.get('phone').dirty)
      }">
        <label class="lib-text-field">
            <input class="fullWidth" placeholder=" " formControlName="phone" />
            <span style="color: #333333; margin-top: 5px;">ex. (999) 999-9999</span>
            <span class="help-block" *ngIf="
            emailForm.get('phone').errors &&
            (emailForm.get('phone').touched ||
            emailForm.get('phone').dirty)
          ">
                <span *ngIf="emailForm.get('phone').errors.maxlength" class="inputMsg"><i
                        class="table-material-icons">error</i> Please enter a valid
                    phone
                    number</span>
                <span *ngIf="emailForm.get('phone').errors.minlength" class="inputMsg"><i
                        class="table-material-icons">error</i> Please enter a valid
                    phone
                    number</span>
            </span>
        </label>
    </div>
    <div *ngIf="annualstatementRoute" style="padding-top: 2%;">
        <table id="tblAnnualSt" class="table table-hover"
            style="padding-right: 30px; padding-bottom: 5px; padding-top: 5px; height: auto; width: 100%;">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col" style="text-align: left;">Columns</th>
                    <th scope="col" style="text-align: left;">Annual Statement</th>
                    <th scope="col" style="text-align: left;">Premium Values</th>
                    <th style="text-align: left;">Premium Difference</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of annualstatementlist;let row=index">
                    <td style="width: 2%;">{{ (item.Text!=='Total') ? row+1 : ""}}</td>
                    <td style="max-width: 17%;">{{item.Text}} </td>
                    <td style="text-align: left;max-width: 12%;">{{item.col1 | currency :'USD':symbol:'1.0-2'}}</td>
                    <td style="text-align: left;max-width: 12%;">{{item.col2 | currency :'USD':symbol:'1.0-2'}}</td>
                    <td style="text-align: left;max-width: 12%;">{{item.diff | currency :'USD':symbol:'1.0-2'}}
                        <i *ngIf="item.mark !==''" class="table-material-icons">flag</i>
                    </td>

                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="prioryearRoute" style="padding-top: 2%;">
        <table id="tblPriorYear" class="table table-hover"
            style="padding-right: 30px; padding-bottom: 5px; padding-top: 5px; height: auto; width: 100%;">
            <thead>
                <tr class="grid-header">
                    <td></td>
                    <td style="color:#333333!important;">[All Premiums In Thousands]</td>
                    <td colspan="2" style="text-align: center;"><strong>Gross Premiums in (000s)</strong></td>
                    <td style="text-align: left;padding-left: 2%;"><strong>%</strong></td>

                </tr>
                <tr>
                    <th scope="col"></th>
                    <th scope="col" style="text-align: left;">Line Of Insurance</th>
                    <th scope="col" style="text-align: left;">{{currentYear-1}} Premium Report Form</th>
                    <th scope="col" style="text-align: left;">{{currentYear}} Premium Report Form</th>
                    <th scope="col" style="text-align: left;">Premium Difference</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of priorYearComparisonList;let row=index">
                    <td style="width: 2%;">{{ (item.lineofinsuraance!=='Total') ? row+1 : ""}}</td>
                    <td>{{item.lineofinsuraance}} </td>
                    <td>{{item.priorpremium | currency :'USD':symbol:'1.0-2'}}</td>
                    <td>{{item.currpremium | currency :'USD':symbol:'1.0-2'}}</td>
                    <td>
                        <div *ngIf="item.premiumdiff==0;else texttemp">{{item.premiumdiff}}</div>
                        <ng-template #texttemp>
                            <div *ngIf="item.premiumdiff=='Increase > 100' || item.premiumdiff=='Decrease > 100';else valuetemp"
                                style="font-weight: bold;">{{item.premiumdiff}} <i *ngIf="item.mark !==''"
                                    class="table-material-icons">flag</i></div>
                        </ng-template>
                        <ng-template #valuetemp>
                            <div>{{item.premiumdiff}} <i *ngIf="item.mark !==''"
                                    class="table-material-icons">flag</i>

                            </div>
                        </ng-template>
                    </td>

                </tr>
                <tr *ngIf="!priorYearComparisonList.length && !show">
                    <td style=" text-align: center;color: #333333;font-family: 'Roboto-Regular', sans-serif;padding-top: 1.5ex;font-size: 13px;"
                        [attr.colspan]="5">
                        No prior year data exist for company : {{compno}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
<div id="tblValidationSummary" *ngIf="validationsummaryRoute"> 
    <app-validation-email-preview [validationSummaryItem]="warningFilteredValidationSummaryItems"></app-validation-email-preview>
   
</div>
    <div class="div-footer">
        <app-button size="medium" type="submit" buttonType="primary" [disabled]="!emailForm.valid" 
            style="margin-right: 15px;">Send Email</app-button>
        <span> or </span>
        <a style="float:right" class="interactive-links" (click)="back()">Cancel</a>
    </div>
</form>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>