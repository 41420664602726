import { createReducer, on } from '@ngrx/store';
import {
  PremiumReportState,
  initialDirectState,
  initialSupplemantalState,
  intialPremiumReportState,
} from '../premium-report.models';
import * as premiumReportActions from '../premium-report.action';
import {
  onLoadPremiumInfoFailed,
  onLoadPremiumInfoSuccess,
} from './loadPremiumInfo.reducer';
import { onLoadPremiumInfo } from './loadPremiumInfo.reducer';
import {
  onRefreshCopyWithCurrent,
  onResetDirectWithCopy,
  onResetSupplementalWithCopy,
} from './onRefreshCopyWithCurrent';
import { getHiddenPremiumCount } from '../utilities/premiumcount.utility';

export const onSetError = (
  state: PremiumReportState,
  { message }
): PremiumReportState => {
  return { ...state, hasError: true, hasWarning: false, errorMessage: message };
};

export const onSetPremiumType = (
  state: PremiumReportState,
  { premiumType }
): PremiumReportState => {
  return { ...state, slectedPremiumType: premiumType };
};
export const onClearError = (state: PremiumReportState): PremiumReportState => {
  return { ...state, hasError: false, errorMessage: '' };
};
export const onsetSelectedCellName = (
  state: PremiumReportState,
  { name }
): PremiumReportState => {
  return { ...state, selectedCellName: name };
};

export const onSetSelectedCellFormula = (
  state: PremiumReportState,
  { formula }
): PremiumReportState => {
  return { ...state, selectedCellFormula: formula };
};

export const onSetGridDirty = (
  state: PremiumReportState,
  { grid }
): PremiumReportState => {
  var premiumData = { ...state.premiumData };
  premiumData[grid] = { ...premiumData[grid], isDirty: true };
  return { ...state, premiumData: { ...premiumData } };
};

export const onSetGridClean = (
  state: PremiumReportState,
  { grid }
): PremiumReportState => {
  var premiumData = { ...state.premiumData };
  premiumData[grid] = { ...premiumData[grid], isDirty: false };
  return { ...state, premiumData: { ...premiumData } };
};

export const onSetGridValidity = (
  state: PremiumReportState,
  { grid, validity }
): PremiumReportState => {
  var premiumData = { ...state.premiumData };
  premiumData[grid] = { ...premiumData[grid], validity: validity };
  return { ...state, premiumData: { ...premiumData } };
};

export const onClearSelectionToggler = (
  state: PremiumReportState,
  { grid }
): PremiumReportState => {
  var premiumData = { ...state.premiumData };
  premiumData[grid] = {
    ...premiumData[grid],
    clearSelectionToggler: !premiumData[grid].clearSelectionToggler,
  };
  return { ...state, premiumData: { ...premiumData } };
};

export const onEndEditToggler = (
  state: PremiumReportState,
  { grid }
): PremiumReportState => {
  var premiumData = { ...state.premiumData };
  premiumData[grid] = {
    ...premiumData[grid],
    endEditToggler: !premiumData[grid].endEditToggler,
  };
  return { ...state, premiumData: { ...premiumData } };
};

export const onSetGridPremiumDataSuccess = (
  state: PremiumReportState,
  { D: dpremiumData, S: spremiumData }
): PremiumReportState => {
  var allPremiumData = state.premiumData;
  allPremiumData = {
    ...allPremiumData,
    D: {
      ...allPremiumData.D,
      premiumGridData: [...dpremiumData],
    },
    S: {
      ...allPremiumData.S,
      premiumGridData: [...spremiumData],
    },
  };
  return { ...state, premiumData: { ...allPremiumData } };
};

const getHiddenPremiumRows = (
  grid: 'D' | 'S',
  state: PremiumReportState,
  gridConfigData: { directRowStates: any; supplementalRowStates: any }
) => {
  const rows = state.premiumData[grid].premiumGridData;
  const stateRows =
    grid === 'D'
      ? gridConfigData.directRowStates
      : gridConfigData.supplementalRowStates;
  const hiddenPremiumRows = rows.filter((row: { [x: string]: any }) => {
    const matchingValue = stateRows.find(
      (value: { name: any }) => value.name === row['1']
    );
    return matchingValue && !matchingValue.selected;
  });
  return hiddenPremiumRows;
};

export const onBackupHiddenRows = (
  state: PremiumReportState,
  { gridConfigData }
): PremiumReportState => {
  const dhiddenPremiumRows = getHiddenPremiumRows('D', state, gridConfigData);
  const shiddenPremiumRows = getHiddenPremiumRows('S', state, gridConfigData);

  var allPremiumData = state.premiumData;
  allPremiumData = {
    ...allPremiumData,
    ['D']: {
      ...allPremiumData['D'],
      hiddenPremiumGridData: [...dhiddenPremiumRows],
    },
    ['S']: {
      ...allPremiumData['S'],
      hiddenPremiumGridData: [...shiddenPremiumRows],
    },
  };
  return { ...state, premiumData: { ...allPremiumData } };
};

export const onSetHiddenPremiumCount = (
  state: PremiumReportState,
  { gridConfigData }
): PremiumReportState => {
  var allPremiumData = { ...state.premiumData };
  const directCount = getHiddenPremiumCount(
    allPremiumData.D,
    gridConfigData.directPremiumColumns,
    gridConfigData.directRowStates
  );
  const supplementalCount = getHiddenPremiumCount(
    allPremiumData.S,
    gridConfigData.supplementalPremiumColumns,
    gridConfigData.supplementalRowStates
  );
  allPremiumData = {
    ...allPremiumData,
    D: {
      ...allPremiumData.D,
      hiddenPremiumCount: directCount,
    },
    S: {
      ...allPremiumData.S,
      hiddenPremiumCount: supplementalCount,
    },
  };
  return { ...state, premiumData: { ...allPremiumData } };
};

export const onSetColums = (
  state: PremiumReportState,
  { grid, columns }
): PremiumReportState => {
  return { ...state, columns: { ...state.columns, [grid]: [...columns] } };
};

export const onSetGridPremiumCellData = (
  state: PremiumReportState,
  { grid, rowIndex, colIndex, value }
): PremiumReportState => {
  var pd = [...state.premiumData[grid].premiumGridData];
  pd[rowIndex] = { ...pd[rowIndex], [colIndex]: value };
  return {
    ...state,
    premiumData: {
      ...state.premiumData,
      [grid]: {
        ...state.premiumData[grid],
        premiumGridData: pd,
      },
    },
  };
};

export const onUpdateJobId = (
  state: PremiumReportState,
  { jobId }
): PremiumReportState => {
  jobId = jobId || '';

  return {
    ...state,
    validationJobId: jobId,
  };
};

export const onResetValidationJobId = (
  state: PremiumReportState
): PremiumReportState => {
  return {
    ...state,
    validationJobId: '',
  };
};

export const onSetValidationLock = (state, { value }): PremiumReportState => {
  return {
    ...state,
    isLockForValidation:value
  }
}
export const onSetPrfLock = (state, { value }): PremiumReportState => {
  return {
    ...state,
    isLocked:value
  }
}

export const onUpdateLockStatus = (state): PremiumReportState => {
  return {
    ...state,
  };
};

export const onUpdateLockStatusSuccess = (
  state,
  { lockedForValidation, prfLocked }
): PremiumReportState => {
  return {
    ...state,
    isLocked: prfLocked,
    isLockForValidation: lockedForValidation,
  };
};

export const onUpdateLockStatusFailure = (
  state,
  { error }
): PremiumReportState => {
  return {
    ...state,
  };
};

export const onCreateNewPRF = (
  state
): PremiumReportState => {
  return {
    ...state,
    hasError: false,
    hasWarning: false,
    isLoading: true,
    slectedPremiumType: 'D',
    premiumData: {
      D: initialDirectState,
      S: initialSupplemantalState,
    },
    userDefinedPremiumData: {
      D: [],
      S: [],
    },
    columns: { D: [], S: [] },
    userDefinedPremiumDataColumns: [],
    rows: [],
    validationJobId: '',
    isLockForValidation: false,
    isLocked: false
  };
};

export const premiumReportReducer = createReducer(
  intialPremiumReportState,
  on(premiumReportActions.setError, onSetError),
  on(premiumReportActions.clearError, onClearError),
  on(premiumReportActions.setSelectedCellName, onsetSelectedCellName),
  on(premiumReportActions.setSelectedCellFormula, onSetSelectedCellFormula),
  on(premiumReportActions.setGridDirty, onSetGridDirty),
  on(premiumReportActions.setGridClean, onSetGridClean),
  on(premiumReportActions.setGridValidity, onSetGridValidity),
  on(
    premiumReportActions.setGridPremiumDataSuccess,
    onSetGridPremiumDataSuccess
  ),
  on(premiumReportActions.clearSelectionToggler, onClearSelectionToggler),
  on(premiumReportActions.endEditToggler, onEndEditToggler),
  on(premiumReportActions.loadPremiumInfo, onLoadPremiumInfo),
  on(premiumReportActions.loadPremiumInfoSuccess, onLoadPremiumInfoSuccess),
  on(premiumReportActions.loadPremiumInfoFailed, onLoadPremiumInfoFailed),
  on(premiumReportActions.setPremiumType, onSetPremiumType),
  on(premiumReportActions.refreshCopyWithCurrent, onRefreshCopyWithCurrent),
  on(premiumReportActions.setColums, onSetColums),
  on(premiumReportActions.setGridPremiumCellData, onSetGridPremiumCellData),
  on(premiumReportActions.refreshDirectWithCopy, onResetDirectWithCopy),
  on(
    premiumReportActions.refreshSupplementalWithCopy,
    onResetSupplementalWithCopy
  ),
  on(premiumReportActions.backupHiddenRows, onBackupHiddenRows),
  on(premiumReportActions.setHiddenPremiumCount, onSetHiddenPremiumCount),
  on(premiumReportActions.updateJobId, onUpdateJobId),
  on(premiumReportActions.setValidationLock, onSetValidationLock),
  on(premiumReportActions.setPrfLock, onSetPrfLock),
  on(premiumReportActions.updateLockStatus, onUpdateLockStatus),
  on(premiumReportActions.updateLockStatusSuccess, onUpdateLockStatusSuccess),
  on(premiumReportActions.updateLockStatusFailure, onUpdateLockStatusFailure),
  on(premiumReportActions.resetValidationJobId, onResetValidationJobId),
  on(premiumReportActions.createNewPRF, onCreateNewPRF),
);
