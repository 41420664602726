import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-modal-loading-screen',
  templateUrl: './modal-loading-screen.component.html',
  styleUrls: ['./modal-loading-screen.component.scss']
})
export class ModalLoadingScreenComponent implements OnInit {
  @Input() size;

  constructor() { }
  iconSize;
  tranfromSize;
  ngOnInit() {
    if (this.size && this.size < 200) {
      this.iconSize = this.size;
      this.tranfromSize = this.size / 200;
    } else {
      this.iconSize = 100;
      this.tranfromSize = 0.5;
    }
  }
}
