import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserDefiledColsModel } from "../commpany.model";
import { CompanyService } from '../company.service';
import { Router } from '@angular/router';
import { ToastService } from '../../shared/toast.service';
@Component({
  selector: 'app-add-edit-user-defined-cols',
  templateUrl: './add-edit-user-defined-cols.component.html',
  styleUrls: ['./add-edit-user-defined-cols.component.scss']
})
export class AddEditUserDefinedColsComponent implements OnInit {
  @Input() title;
  @Input() prfYear;
  @Input() companyno;
  @Input() group;
  @Input() model: UserDefiledColsModel;
  @Input() selectedIndex: number;
  @Input() isoCol: string;
  @Input() ColNo: any;
  @Input() mode: any;
  disable: boolean;
  userdefinedCols: UserDefiledColsModel;
  addEditUsedColsForm: UntypedFormGroup;
  isocolumnsloadded: Promise<boolean>;
  matchingisocolumns: any[] = [];
  showRequiredMsg = false;
  selIndex: number;
  selectionChanged: boolean = false;
  spinnermodal: boolean;
  formValid: boolean = true;
  saveButtonText = "";
  error: any;
  spinnersave: boolean;
  spinneradd: boolean;
  isLoaded: boolean = false;
  constructor(private toastService: ToastService, private router: Router, private companyService: CompanyService, public builder: UntypedFormBuilder, public activeModal: NgbActiveModal) {
    this.addEditUsedColsForm = this.builder.group({});
  }

  ngOnInit(): void {
    this.spinnermodal = true;

    if (this.model != undefined && this.model.ColName.trim() == "Aggregate Write-Ins") {
      this.disable = true;
    }
    this.selIndex = this.selectedIndex;
    this.saveButtonText = this.mode == "add" ? "ADD COLUMN" : "UPDATE COLUMN";
    this.addEditUsedColsForm.addControl('ColName1', new UntypedFormControl(this.model !== undefined ? this.model.ColName1 : '', Validators.required));
    this.addEditUsedColsForm.addControl('ColName2', new UntypedFormControl(this.model !== undefined ? this.model.ColName2 : ''));
    this.addEditUsedColsForm.addControl('ColName3', new UntypedFormControl(this.model !== undefined ? this.model.ColName3 : ''));
    this.addEditUsedColsForm.addControl('ColName4', new UntypedFormControl(this.model !== undefined ? this.model.ColName4 : ''));
    this.addEditUsedColsForm.addControl('ISOCol', new UntypedFormControl(this.model !== undefined ? this.model.ISOCol : 'on'));
    this.addEditUsedColsForm.addControl('ColNo', new UntypedFormControl(this.model !== undefined ? this.model.ColNo : this.ColNo));

    this.companyService.getMatchiIsoColumns(this.prfYear, this.companyno).subscribe({
      next: (resp) => {
        if (resp["message"] == "Success") {
          this.matchingisocolumns = resp["columnslist"]["matchingisolines"];
          if (this.selectedIndex == undefined) {
            this.selectedIndex = resp["columnslist"]["selindex"];
            if (this.mode == "add") {
              this.selectedIndex = 0;
            }
            this.selIndex = 1;
            //this.selIndex =this.matchingisocolumns.indexOf(this.selectedIndex);
          }
          else {
            this.selectedIndex = this.matchingisocolumns.indexOf(this.matchingisocolumns.find(item => item.Number == this.selectedIndex));
          }
          this.isocolumnsloadded = Promise.resolve(true);
          this.spinnermodal = false;
        }
      },
      error: (error) => {
        this.activeModal.close('error');
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    })
    setTimeout(()=>{this.isLoaded=true;},500);


  }
  addformcontrolsValues() {
    this.addEditUsedColsForm.get("ColName1").setValue(this.userdefinedCols.ColName1);
    this.addEditUsedColsForm.get("ColName2").setValue(this.userdefinedCols.ColName2);
    this.addEditUsedColsForm.get("ColName3").setValue(this.userdefinedCols.ColName3);
    this.addEditUsedColsForm.get("ColName4").setValue(this.userdefinedCols.ColName4);
    // this.addEditUsedColsForm.get("ISOCol").setValue(this.model !== undefined ? this.model.ISOCol : 'on');
    // this.addEditUsedColsForm.get("ColNo").setValue(this.model !== undefined ? this.model.ColNo : this.ColNo);


  }

  getIsoColumn(item: any) {
    this.selectionChanged = true;
    this.selectedIndex = parseInt(item.value);
    this.selIndex = parseInt(item.value);
  }
  onsubmit() {
    this.formValid = true;

    this.userdefinedCols = this.addEditUsedColsForm.value;

    if (this.selectionChanged == false) {
      this.userdefinedCols.MatchCol = this.selIndex;
    }
    else {
      this.userdefinedCols.MatchCol = this.selectedIndex;
    }
    var strheadinglength = this.userdefinedCols.ColName1.trim().length + this.userdefinedCols.ColName2.trim().length + this.userdefinedCols.ColName3.trim().length + this.userdefinedCols.ColName4.trim().length

    this.userdefinedCols.ColName = this.userdefinedCols.ColName1 + " " + this.userdefinedCols.ColName2 + " " + this.userdefinedCols.ColName3 + " " + this.userdefinedCols.ColName4;
    this.userdefinedCols.ColName = this.userdefinedCols.ColName.trim();

    if (this.userdefinedCols.ColName.trim() == '') {
      this.formValid = false;
      this.error = "At least one heading is required to add and save new columns."
    } else {
      if (strheadinglength > 70) {
        this.formValid = false;
        this.error = "Total text length of Heading Line(s) must not be exceed 70 characters."
        if (this.mode !== "add")
          this.addformcontrolsValues();
        return;
      }
      if (this.mode == "add") {
        this.toastService.remove(this.toastService.toasts[0]);
        this.spinneradd = true;
        this.companyService.saveUserDefinedCol(this.prfYear, this.group, this.companyno, this.userdefinedCols).subscribe({
          next: (resp) => {
            if (resp["message"] == "Success") {
              this.formValid = false;
              if (resp["status_msg"] == "Column already exists. Please give different column headings.") {
                this.error = resp["status_msg"];
                this.spinneradd = false;
              } else {
                this.toastService.show(resp["status_msg"], { classname: 'bg-success text-light', delay: 3000 });
                this.spinneradd = false;
                this.activeModal.close(this.userdefinedCols);
              }
            }
          },
          error: (error) => {
            this.activeModal.close();
            //console.log(error);
            this.router.navigate(['/apifailure']);
          }
        })
      } else {
        this.toastService.remove(this.toastService.toasts[0]);
        this.spinnersave = true;
        this.companyService.updateUserDefinedCol(this.prfYear, this.group, this.companyno, this.userdefinedCols).subscribe({
          next: (resp) => {
            if (resp["message"] == "Success") {
              this.formValid = false;
              if (resp["status_msg"] == "Column already exists. Please give different column headings.") {
                this.error = resp["status_msg"];
                this.spinnersave = false;
              } else {
                this.toastService.show(resp["status_msg"], { classname: 'bg-success text-light', delay: 3000 });
                this.spinnersave = false;
                this.activeModal.close(this.userdefinedCols);
              }
            }
          },
          error: (error) => {
            this.activeModal.close();
            //console.log(error);
            this.router.navigate(['/apifailure']);
          }
        })
      }
    }

  }


}
