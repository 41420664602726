<app-loading-screen *ngIf="spinnergetcomment">Loading Comment</app-loading-screen>
<app-loading-screen *ngIf="spinnersavecomment">Saving Comment</app-loading-screen>
<div *ngIf="commentsloaded | async">
<div class="pagetitle">Comments: {{ currentYear }}</div>

<div *ngIf="compno!==null">
  <span class="pagetitleSecondLayerBold"> {{ prfNumber.toUpperCase() }} </span>:<span class="pagetitleSecondLayer">
    {{ prfName }}</span>
</div>
<!-- <div style="width: 400px;" *ngIf="isLocked" class="information">
  <div class="messagetitle">
    <i class="table-material-icons">error</i>
    This form is aleardy submitted. You cannot make changes.
  </div>
</div>
<div style="width: 400px;" *ngIf="isReadOnlyAdmin && !isLocked" class="information">
  <div class="messagetitle"><i class="table-material-icons">error</i> You are not authorized to make changes.</div>
</div> -->

<div style="width: 400px;">
  <div *ngIf="!isReadOnly" class="titleBold" style="padding-top:40px;">
    Please add, edit or review your comment for this year's Premium Report.
  </div>
  <div *ngIf="!isReadOnly" class="titleLight" style="padding-top:10px;padding-bottom: 10px;">
    Any information explaining substantial differences in your report over the year will help expedite the approval
    process.
  </div>

  <form>
    <br *ngIf="isReadOnly" />
    <textarea [readonly]="isReadOnly" [(ngModel)]="comment" name="comment" rows="8" cols="52" style="resize: none;"></textarea>
    <div *ngIf="!isReadOnly" style="padding-top:30px;">
      <app-button size="medium" style="width: 100%;" (click)="onsubmit()" buttonType="primary">Confirm Information
      </app-button>
    </div>
  </form>
</div>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>