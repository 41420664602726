<app-modal-loading-screen *ngIf="show">Loading Final Submission</app-modal-loading-screen>
<app-modal-loading-screen *ngIf="spinnersave">Submitting details</app-modal-loading-screen>
<app-modal-loading-screen *ngIf="spinnerredirect">Redirecting to Premium Report</app-modal-loading-screen>

<div *ngIf="isLoaded" class="modal" tabindex="-1" role="dialog" style="display: block !important">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 *ngIf="!isSubmissionCompleted; else submissionCompleteTitle" class="pagetitle">
          Final Submission: {{ prfYear }}
        </h4>
        <ng-template #submissionCompleteTitle>
          <h4 class="pagetitle">Submission Complete</h4>
        </ng-template>
        <span class="table-material-icons" (click)="activeModal.close('Close')"
          style="cursor: pointer; font-size: 24px">close</span>
      </div>
      <div style="padding-left: 1rem">
        <div *ngIf="compno !== null">
          <span class="titleBold" style="font-size: 18px">
            {{ prfNumber.toUpperCase() }} </span>:<span class="titleLight" style="font-size: 18px">
            {{ prfName }}</span>
        </div>
      </div>

      <div class="modal-body">
        <form class="form-class">
          <hidden-config-warning *ngIf="(!isLocked) && (hasHiddenPremium$ | async)"> </hidden-config-warning>



          <div *ngIf="!isReadOnly && !isSubmissionCompleted && !(hasHiddenPremium$ | async)" class="comment-msg-p"
            style="opacity: 79%">

            <i class="table-material-icons">warning</i>
            <div>
              <p *ngIf="!isReadOnly" class="title-m">
                You cannot make changes once you submit the Premium Report Form.
              </p>
              <div [innerHTML]="msg" (click)="openComments($event)" style="text-decoration: none; margin-top: 10px">
                <i class="interactive-links">comments</i>
              </div>
            </div>
          </div>
          <div *ngIf="isPrfLocked$|async">
            <p><b>The premium report form is already submitted.</b></p>
          </div>
          <div *ngIf="(!(isPrfLocked$|async)) && (isLockForValidation$|async)">
            <p><b>The premium report form is in Validation Stage.</b></p>
          </div>
          <br />
          <div *ngIf="!isSubmissionCompleted" style="padding-top: 1ex">

            <p class="contact-title-l">
              For information on how you can change the Premium Report after
              submitting, contact the ISO Insurer Billing Department:
            </p>
            <div class="lower-sec">
              <div class="address-div">
                <p class="contact-title-m">ISO Insurer Billing Department</p>
                <p class="contact-title-l">Phone: 1-201-469-3045</p>
                <p class="contact-title-l">
                  E-mail:
                  <a class="link interactive-links" href="{{ mailid }}subject=Unlock Company {{
                      compno
                    }} for PRF Year {{ prfYear }}">ISO Premium Report Form</a>
                </p>
              </div>
              <!--<div class="dummy-checkbox" *ngIf="!isLocked && isAdmin">
                <input
                  type="checkbox"
                  [(ngModel)]="isDummy"
                  [ngModelOptions]="{ standalone: true }"
                />
                Submit as dummy report
              </div> -->
              <div class="dummy-checkbox" *ngIf="isLocked && isDummy && (isAdmin || isReadOnlyAdmin)">
                <input type="checkbox" disabled checked />
                Submitted as dummy report
              </div>
            </div>
          </div>
          <div *ngIf="isSubmissionCompleted || (isLockForValidation$|async)">
            <p class="titleBold">Thank you for your submission.</p>
            <p class="titleLight">
              Your premium report is under review and a representative will be
              in contact if any issues are detected and need to be addressed.
            </p>
            <br />
            <p>
              Click here to return to the
              <a routerLink="/dboard" class="interactive-links" (click)="activeModal.dismiss()">PRF List page.</a>
            </p>

            <!-- <p *ngIf="validationSummaryJobId$ | async">
              Click here to go to the
              <a routerLink="/admin/validationsummary" (click)="activeModal.close('withValidationSummary')">Validation Summary page.</a>
            </p> -->

            <p>
              Click here to go to the
              <a routerLink="/validationsummary" class="interactive-links" (click)="activeModal.close('withValidationSummary')">Validation Summary
                page.</a>
            </p>
          </div>
        </form>
      </div>
      <div *ngIf="!isLocked && isAdmin && !isSubmissionCompleted &&  !(hasHiddenPremium$ | async)" class="comment-msg-p"
        style="opacity: 79%; width: 540px; margin-left: 15px">
        <div>
          <p *ngIf="!isLocked && isAdmin" class="title-m">
            Please select the check box to submit the Premium Report Form as
            dummy.
          </p>
          <div class="dummy-checkbox" *ngIf="!isLocked && isAdmin">
            <input type="checkbox" [(ngModel)]="isDummy" [ngModelOptions]="{ standalone: true }" />
            Submit as dummy report
          </div>
        </div>
      </div>
      <div [hidden]="isSubmissionCompleted || isLocked  " class="modal-footer">
        <app-button *ngIf="!(hasHiddenPremium$ | async)" size="medium"
          [ngClass]="{ active: !isLocked, disabled: isLocked }" type="submit" (click)="submit()"
          [disabled]="isLocked  || (hasHiddenPremium$ | async)" buttonType="primary">
          Submit Form
        </app-button>
        <app-button *ngIf="hasHiddenPremium$ | async" size="medium" (click)="handleShowHiddenCells()"
          buttonType="primary">
          Show Hidden Cells
        </app-button>

        <!-- <rs-split-button></rs-split-button> -->
        <span> or </span>
        <a (click)="activeModal.close('Close')" buttonType="primary" class="interactive-links">Cancel</a>
      </div>
    </div>
  </div>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
