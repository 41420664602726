import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject, Subscription, throwError } from "rxjs";
import { IConfig } from './shared/config';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from "../environments/environment";
import { AlertService } from "./shared/alert.service";
import { HttpService } from './shared/http.service';
import { User, Role } from './shared/models/user-model';
import { IProfileLinks,IProduct } from './shared/models/profilelink.model';
import { catchError, map, tap } from 'rxjs/operators';
import { NumberConstant } from 'igniteui-angular-excel';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  user: User;

  private globalConfig = 'assets/jsons/config.json';
  private appConfigLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private appConfig: IConfig;

  title = new Subject<string>();
  display = new Subject<string>();
  minyear: number = (new Date()).getFullYear();
  vendorID: number = 0;
  vendorname = new Subject<string>();
  isAdmin: boolean = false;
  isReadonlyAdmin: boolean = false;
  // isCustomer:boolean;
  admin = new Subject<string>();
  disableMenuChanged = new Subject<boolean>();
  private subscription: Subscription;
  disableMenu: boolean = false;
  listselectedyear: number = 0;
  listselectedquarter: number = 0;
  isProductEligible = new Subject<boolean>();
  // searchvalue = new Subject<string>();
  // private searchvalue: BehaviorSubject<string> = new BehaviorSubject("");
  private searchvalue: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private searchyear : BehaviorSubject<number> = new BehaviorSubject<number>(0);
  hover_megamenu;

  setTitle(title: string) {
    if (title == 'DashBoard') {
      this.display.next('none');
      this.admin.next('none');
    } else { this.display.next('block') }
    this.title.next(title);
  }
  constructor(private http: HttpClient, private alertService: AlertService, private httpService: HttpService) {
    this.user = new User();
    // console.log("Config reading started");
    this.http.get<IConfig>(this.globalConfig).subscribe(
      config => {
        // console.log("Config reading completed");
        this.appConfig = config;
        this.appConfigLoaded.next(true);
      }
    );
    this.subscription = this.disableMenuChanged
      .subscribe(
        (disableMenu) => {
          this.disableMenu = disableMenu;
        }
      );


  }

  getConfigStatus(): Observable<boolean> {
    return this.appConfigLoaded.asObservable();
  }
  getGlobalConfig(): IConfig {
    return this.appConfig;
  }
  validateVendor() {
    if (this.disableMenu) {
      this.alertService.clear();
      if (this.isAdmin == true) {
        this.alertService.error("Please choose a Vendor from the list");
      }
      else {
        this.alertService.error("Invalid Vendor");
      }
      return false;
    }
    return true;

  }

  hasRole(role: Role) {
    return this.user.role === role;
  }

  getAllUserProfileLinks(): Observable<IProfileLinks[]> {
    // let url
    return this.http.get<IProfileLinks[]>(environment.PRODUCTS_URL + `/API/ViewLinks/GetAll`).pipe(
      // tap(data => console.log('Header API Data: ' + JSON.stringify(data))),
      catchError(this.handleError)
    );
  }
  getUserProducts(userId: string, token: string): Observable<IProduct[]> {
    return this.http.get<IProduct[]>(environment.PRODUCTS_URL + `/api/Panels/GetUserProductsWithTicket?userId=${userId}&isoToken=${token}`).pipe(
      // tap(data => console.log('UserProducts API Data: ' + JSON.stringify(data))),
      catchError(this.handleError)
    );
  }

  getAllProducts() {
    return this.http.get(environment.PRODUCTS_URL + `/api/Panels/GetPublishedProducts`).pipe(
      // tap(data => console.log('AllProducts API Data: ' + JSON.stringify(data))),
      catchError(this.handleError)
    );
  }
  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }

  getUserEligibleproduct(apiUrl: string, productid: string, token: string): Observable<string> {
    return this.http.get(`${apiUrl}/isUserEligibleForProduct/${token}/${productid}`, { responseType: 'text' }).pipe(
     
      catchError(this.handleError)
    );
  }
  getUserEligibleproductv2(productIdAlias: string, token: string): Observable<boolean> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    })
    return this.http.get(`${environment.EligibilityUrl}/user/profile/customer/detail/user/product/${productIdAlias}/v1`, { responseType: 'text' ,headers:headers }).pipe(
      map(x=>{ 
        return x.length>0
      }),
      catchError(this.handleError)
    );
  }

}


