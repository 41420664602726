<app-modal-loading-screen *ngIf="spinnerupload"></app-modal-loading-screen>
<app-modal-loading-screen *ngIf="!isLoaded"
  >Loading...</app-modal-loading-screen
>
<div
  *ngIf="isLoaded"
  class="modal"
  tabindex="-1"
  role="dialog"
  style="display: block !important"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="pagetitle">{{ title }}: {{ prfYear }}</h4>
        <span
          class="table-material-icons"
          (click)="closerefresh(); activeModal.dismiss('Cross click')"
          style="cursor: pointer; font-size: 24px"
          >close</span
        >
      </div>
      <div style="padding-left: 1rem">
        <div *ngIf="companyno !== null">
          <span class="titleBold" style="font-size: 18px">
            {{ companyno }} </span
          >:<span class="titleLight" style="font-size: 18px">
            {{ companyname }}</span
          >
        </div>
      </div>

      <div class="modal-body">
        <form class="form-class">
          <div class="form-check form-check-inline">
            <input
              id="uploadcompany"
              class="form-check-input"
              type="radio"
              name="action"
              value="upload"
              [(ngModel)]="action"
              checked
            />
            <label for="uploadcompany" class="form-check-label">
              Upload Company
            </label>
          </div>
          <div class="form-check form-check-inline" *ngIf="submissiondt !== ''">
            <input
              id="unlockcompany"
              class="form-check-input"
              type="radio"
              name="action"
              value="unloack"
              [(ngModel)]="action"
            />
            <label for="unlockcompany" class="form-check-label">
              Unlock Company</label
            >
          </div>

          <div *ngIf="action == 'upload'" class="form-group">
            <div class="form-inline" style="margin-top: 10px">
              <label for="calenderYear" style="margin-right: 5px"
                >Calender Year:</label
              >
              <input
                type="text"
                class="form-control"
                id="calenderYear"
                disabled="true"
                value="{{ calenderYear }}"
                style="width: 65px"
              />
            </div>
            <div class="form-inline" style="margin-top: 10px">
              <div style="margin-top: 10px">
                <label style="float: left">Last Uploaded Status:</label>
                <textarea
                  [readonly]="true"
                  name="statusText"
                  rows="5"
                  cols="52"
                  [ngStyle]="{
                    color:
                      uploadstatuscolor == 'statustextcolorsuccess'
                        ? 'green'
                        : uploadstatuscolor == 'statustextcolorerror'
                        ? 'red'
                        : 'black'
                  }"
                  style="width: 100%"
                  >{{ UploadMessage }}</textarea
                >
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <app-button
          buttonType="primary"
          class="nocursor"
          type="submit"
          (click)="onsubmit()"
          [disabled]="disablesubmit"
          style="width: 100%"
          >Submit
        </app-button>
      </div>
    </div>
  </div>
</div>
