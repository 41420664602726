import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService } from '../company/company.service'
import { environment } from "../../environments/environment";
import { interval, Subscription, timer } from 'rxjs';
@Component({
  selector: 'app-unloackuploadcompany',
  templateUrl: './unloackuploadcompany.component.html',
  styleUrls: ['./unloackuploadcompany.component.scss']
})
export class UnloackuploadcompanyComponent implements OnInit {
  action: any;
  regionProduction = environment.production;
  @Input() title;
  @Input() prfYear;
  @Input() groupcode;
  @Input() companyno;
  @Input() prfuser;
  @Input() status;
  @Input() companyname;
  @Input() submissiondt;
  @Input() previousUploadMessage;
  calenderYear: number;
  quarter: any;
  corpId: any;
  customTitle: any;

  UploadMessage: string;
  uploadstatuscolor = "statustextcolorerror";
  showPageError: boolean;
  spinnerupload: boolean;
  disablesubmit: boolean = false;
  subscription: Subscription;
  uploadStatus: boolean = false;
  checkCounter: number = 0;
  isLoaded:boolean = false;

  constructor(public activeModal: NgbActiveModal, private companyService: CompanyService) { }

  ngOnInit(): void {
    setTimeout(()=>{this.isLoaded=true;},500);
    this.action = "upload"
    this.calenderYear = +this.prfYear + 2;
    this.quarter = 1;
    this.corpId = 1;
    this.setTitle();
    this.UploadMessage = this.previousUploadMessage;


  }
  setTitle() {
    if (this.status == "Submitted") {
      this.title = "Upload/Unlock Company";
    }
    else {
      this.title = "Upload Company";
    }
  }
  isEmptyOrSpaces(str) {
    if (str == undefined)
      return true;
    str = str.toString()
    return str === null || str.match(/^ *$/) !== null;

  }

  onsubmit() {
    if (this.disablesubmit == true) {
      return;
    }
    if (this.action == "unloack") {
      this.companyService.unlockcompany(parseInt(this.prfYear), this.groupcode, this.companyno).subscribe((resp) => {

        if (resp["message"] == "Success") {

          this.activeModal.close(resp["status_msg"]);

        }
      },
        (error) => {
        }
      )
    }
    else {
      if (this.submissiondt !== "") {
        this.spinnerupload = true;
        this.UploadMessage = "";
        this.showPageError = false;
        this.disablesubmit = true;
        if (this.isEmptyOrSpaces(this.prfYear)) {
          this.UploadMessage = "Invalid Year. ";
          this.showPageError = true;
        }
        if (this.isEmptyOrSpaces(this.quarter)) {
          this.UploadMessage += 'Invalid Quarter. ';
          this.showPageError = true;
        }
        if (this.isEmptyOrSpaces(this.corpId)) {
          this.UploadMessage += 'Invalid Corp Id.';
          this.showPageError = true;
        }
        if (this.isEmptyOrSpaces(this.groupcode)) {
          this.UploadMessage += 'Invalid Group Code.';
          this.showPageError = true;
        }
        if (this.isEmptyOrSpaces(this.companyno)) {
          this.UploadMessage += 'Invalid Company Number.';
          this.showPageError = true;
        }
        if (this.showPageError == true) {
          this.spinnerupload = false;
          this.disablesubmit = false;
          return;
        }
        // this.activeModal.close(this.companyno);
        this.uploadstatuscolor = "";
        this.UploadMessage = ""
        this.companyService.uploadpremium(parseInt(this.prfYear), parseInt(this.quarter), this.corpId, this.groupcode, this.companyno).subscribe((resp) => {

          if (resp) {
            console.log("Success returned from upload premiium");
            this.spinnerupload = false;
            this.UploadMessage = "Uploading premium for " + this.companyno+" is in progress. Please check home page for upload status."
            this.uploadstatuscolor = "statustextcolorsuccess";
            this.showPageError = true;
          }

        },
          (error) => {
            console.log(error)
            if (error.status == 417) {
            this.spinnerupload = false;
            this.UploadMessage = error.error.message;
            this.uploadstatuscolor = "statustextcolorerror";
            this.showPageError = true;
            this.disablesubmit = false;
            }
          }
        )
      }
      else {
        this.UploadMessage = "Please Submit the premium before Upload.";
        this.showPageError = true;
        this.spinnerupload = false;
        this.disablesubmit = false;
        return;
      }
    }
  }
  checkuploadstatus() {
    this.companyService.updateuploadstatus(parseInt(this.prfYear), this.companyno, this.groupcode).subscribe((sresp) => {
      //this.spinnerupload = false;
      if (sresp["status"] == "Success" && sresp["result"] != "File Not Found") {

        this.spinnerupload = false;
        //this.UploadMessage = sresp["status_msg"];
        this.UploadMessage = sresp["result"];
        this.uploadstatuscolor = "statustextcolorsuccess"
        this.disablesubmit = false;
      }
      else {
        console.log("File Not found in S3. Waiting for 1 more min to check again")
        const source = interval(1 * 60 * 1000);
        this.subscription = source.subscribe(val => this.subuploadstatuscheck());

      }

    },
      (serror) => {
        this.spinnerupload = false;
        console.log(serror)
        if (serror.status == 417) {
          this.UploadMessage = serror.error.message;
          this.uploadstatuscolor = "statustextcolorerror";
          this.showPageError = true;
          this.disablesubmit = false;

        }
      }
    )
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  timercheckuploadstatus() {

    this.companyService.updateuploadstatus(parseInt(this.prfYear), this.companyno, this.groupcode).subscribe((subresp) => {
      console.log('calling status upload recursive')
      this.checkCounter = this.checkCounter + 1
      if (subresp) {
        this.spinnerupload = false;
        this.UploadMessage = subresp["result"];
        this.uploadstatuscolor = "statustextcolorerror"
        this.disablesubmit = false;
        this.uploadStatus = true;
      }
    },
      (serror) => {
        this.spinnerupload = false;
        console.log(serror)
        if (serror.status == 417) {
          this.UploadMessage = serror.error.message;
          this.uploadstatuscolor = "statustextcolorerror";
          this.showPageError = true;
          this.disablesubmit = false;
          this.uploadStatus = false;
        }
      }
    )
    if (this.subscription)
      this.subscription.unsubscribe();
  }


  subuploadstatuscheck() {
    this.companyService.updateuploadstatus(parseInt(this.prfYear), this.companyno, this.groupcode).subscribe((subresp) => {
      console.log('calling status upload recursive')
      if (subresp) {
        this.spinnerupload = false;
        this.UploadMessage = subresp["result"];
        this.uploadstatuscolor = "statustextcolorerror"
        this.disablesubmit = false;
      }
    },
      (serror) => {
        this.spinnerupload = false;
        console.log(serror)
        if (serror.status == 417) {
          this.UploadMessage = serror.error.message;
          this.uploadstatuscolor = "statustextcolorerror";
          this.showPageError = true;
          this.disablesubmit = false;

        }
      }
    )
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  closerefresh() {
    if (this.uploadstatuscolor == "statustextcolorsuccess") {
      this.activeModal.close("Upload success");
    }
    else {
      this.activeModal.close("Error")
    }

  }
}
