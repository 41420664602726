export interface ValidationDetailContent {
  schema: any;
  type: string;
  value: any;
}

export interface ValidationSummaryItem {
  pk: string;
  sk: string;
  content: ValidationDetailContent;
  ruleDescription: string;
  ruleName: string;
  ruleId: string;
  status: string;
  isBlocker: boolean;
  feedback: string;
  isInFeedbackUpdatedState: boolean;
}

export interface ValidationSummaryBase {
  isComplete: boolean;
  data: ValidationSummaryItem[];
  isDummyReport: boolean;
  year: number;
  groupCode: string;
  companyNumber: string;
  numberOfCompanyValidationMessage: string;
  emailCount: number;
  lastEmailSendDate: string;
}

export interface ValidationSummaryState {
  jobId: string;
  validationSummaryData: ValidationSummaryBase;
}

export interface ValidationInput {
  prfyear: number;
  groupcode: string;
  companyno: string;
  dummyrpt: boolean;
  prfuser: string;
}

export interface ValidationResponse {
  data: {
    jobid: string;
  };
}

export interface ValidationSummaryResponse {
  data: any[];
}

export interface ValidationUnlockInput {
  year: number;
  groupCode: string;
  companyNumber: string;
  prfuser: string;
  dummyrpt: boolean;
}

export interface ValidationSubmitInput {
  year: number;
  groupCode: string;
  companyNumber: string;
  dummyrpt: boolean;
  prfuser: string;
}

export const STATUSMAPPER = {
  0: 'OK',
  1: 'WARNING',
  2: 'ERROR',
};
