import { createReducer, on } from '@ngrx/store';
import { AppState } from '../app.state';

export const initialState: AppState = {
  // Initial state properties
  data: {}
};

export const appReducer = createReducer(
  initialState,
);
