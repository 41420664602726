<div class="validation-header">
  <div class="validation-header-top-content">
    <div>
      <div class="pagetitle"><b>Validation Summary</b></div>
      <div *ngIf="compNo !== null">
        <span class="pagetitleSecondLayerBold"> {{ prfNumber }} </span>:<span
          class="pagetitleSecondLayerBold"
          >{{ prfName }}</span
        >
      </div>
    </div>

    <div
      *ngIf="!readOnly && selectValidationSummaryIsComplete && !selectAllStatusSuccess"
      class="validation-header-top-content-right-section"
    >
    <div *ngIf="isAdmin" class="validation-header-top-content-email" (click)="goToEmailPreview()">
      <span class="table-material-icons icon"> email </span>
      <a class="validation-header-top-content-email-text"> Email </a>
    </div>
    <div
    class="validation-header-top-content-unlock"
      *ngIf="isLockForValidation"
      (click)="clickToUnLock()"
    >
      <app-unlock-icon></app-unlock-icon>
      <div class="validation-header-top-content-unlock-text">
        Unlock To Edit
      </div>      
    </div>
      <div *ngIf="!isLockForValidation"></div>

      <div class="call-to-action">
        <button
          class="primary validation-summary-submit-button"
          [disabled]="
            !(
              isLockForValidation &&
              selectAllFeedbackValid &&
              selectValidationSummaryIsComplete
            )
          "
          (click)="reSubmit()"
          [ngClass]="{
            active:
              isLockForValidation &&
              selectAllFeedbackValid &&
              selectValidationSummaryIsComplete,
            disabled: !(
              isLockForValidation &&
              selectAllFeedbackValid &&
              selectValidationSummaryIsComplete
            )
          }"
        >
          Complete Submission
        </button>
      </div>
    </div>

    <ng-container *ngIf="!selectValidationSummaryIsComplete">
      <div class="validation-header-top-content-right-section-inprogress">
        <div>
          <mat-spinner class="custom-spinner"></mat-spinner>
        </div>
        <div>
          <span>Loading Validation Summary...</span>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="validation-header-middle-content" *ngIf="showNumberOfCompanyValidationMessage">
    <div>
      <span class="material-icons check_circle_info"> info </span>
    <b>{{numberOfCompanyValidationMessage}}</b>
    </div>
  </div>
  <div class="validation-header-bottom-content">
    <div class="validation-header-bottom-content-section-1"><b>Key:</b></div>
    <div class="validation-header-bottom-content-section">
      <div>
        <span class="material-icons check_circle_ico"> check_circle </span>
      </div>
      <div class="validation-header-bottom-content-section-text">
        Rule Validation Successful.
      </div>
    </div>

    <div class="validation-header-bottom-content-section">
      <div>
        <span class="material-icons warning_ico"> warning </span>
      </div>
      <div class="validation-header-bottom-content-section-text">
        Rule Validation Completed with Warnings.
      </div>
    </div>
    <div class="validation-header-bottom-content-section">
      <div>
        <span class="material-icons"> update </span>
      </div>
      <div class="validation-header-bottom-content-section-text">
        Feedback Updated.
      </div>
    </div>
  </div>
</div>
