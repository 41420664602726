<app-loading-screen *ngIf="spinnergetcompinfo"
  >Loading Company Information</app-loading-screen
>
<app-loading-screen *ngIf="spinnersavecompinfo"
  >Saving Company Information</app-loading-screen
>
<app-loading-screen *ngIf="spinnerrenamecompany"
  >Saving Company Information</app-loading-screen
>

<div *ngIf="statesLoaded && companyLoaded | async">
  <div class="confirm-information" *ngIf = "unConfirmedData && !isReadOnly">
    <div class="messagetitle">
      <i class="table-material-icons">error</i> Please confirm the company
      details by clicking the '<strong>Confirm Information</strong>' button to
      proceed.
    </div>
  </div>

  <div class="header-container">
    <div class="pagetitle">Company Information: {{ currentYear }}</div>
    <app-button 
      *ngIf="isNewForm && hasStatrtedWithGroup && isvalidbillno" 
      size="medium" buttonType="primary" (click)="openPopulateModal()"
    >Search Company</app-button>
  </div>
  
  <div class="edit-page">
  <div>
    <div class="PageLevelError" *ngIf="showPageError">
      <div [ngSwitch]="messagePageError">
        <div *ngSwitchCase="'Company already exist'">
          Company with the same ISO Billing Number {{ compnoPageError }} already
          exists in the system.
        </div>
        <div *ngSwitchCase="'Group Company already exist'">
          You cannot add {{ compnoPageError }} as standalone as this is a group
          company. Please check with customer service if you have any
          questions.<br />
          For additional reference, please see
          <a href="javascript:void(0);" (click)="openHelpDoc()">here</a>.
        </div>
        <div *ngSwitchCase="'Stand-alone company already exist'">
          You cannot add {{ compnoPageError }} as alpha company as this is a
          standalone company. Please check with customer service if you have any
          questions.<br />
          For additional reference, please see
          <a href="javascript:void(0);" (click)="openHelpDoc()">here</a>.
        </div>
        <div *ngSwitchCase="'The company is merged'">
          The company with the ISO Billing Number {{ compnoPageError }} is
          merged/transferred to another company. Please check with customer service if you
          have any questions.
        </div>
      </div>
    </div>

    <div
      *ngIf="compno !== '' && (!isAdmin || isReadOnly)"
      style="
        display: flex;
        width: max-content;
        justify-content: flex-start;
        align-items: center;
      "
    >
      <span class="pagetitleSecondLayerBold">
        {{ prfNumber.toUpperCase() }}
      </span>
      <span style="padding-left: 5px; padding-right: 5px">:</span>
      <span class="pagetitleSecondLayer"> {{ prfName }}</span>
    </div>
    <div>
      <form [formGroup]="companyinformationForm" (ngSubmit)="onsubmit()">
        <input type="hidden" class="fullWidth" formControlName="billingno" />
        <input type="hidden" class="fullWidth" formControlName="compname" />
        <div *ngIf="compno == '' || (isAdmin && !isReadOnly)">
          <div
            [ngClass]="{
              'has-error':
                companyinformationForm.get('billingno').errors &&
                (companyinformationForm.get('billingno').touched ||
                  companyinformationForm.get('billingno').dirty)
            }"
          >
            <label class="lib-text-field space" required>
              <input
                id="billingno"
                class="fullWidth"
                placeholder=" "
                formControlName="billingno"
                (keyup)="validateBillNumber($event)"
                required
              />
              <span class="lib-placeholder">ISO Billing Number</span>
              <span
                class="help-block"
                *ngIf="
                  companyinformationForm.get('billingno').errors &&
                  (companyinformationForm.get('billingno').touched ||
                    companyinformationForm.get('billingno').dirty)
                "
              >
                <span
                  *ngIf="
                    companyinformationForm.get('billingno').errors.required
                  "
                  class="inputMsg"
                  ><i class="table-material-icons">error</i> ISO Billing Number
                  is required</span
                >
              </span>
              <span
                class="error inputMsg"
                *ngIf="
                  !hasStatrtedWithGroup &&
                  !companyinformationForm.get('billingno').errors
                "
                ><i class="table-material-icons">error</i> ISO Billing Number
                should start with {{ groupcode }}</span
              >
              <span
                class="error inputMsg"
                *ngIf="!isvalidbillno && hasStatrtedWithGroup"
                ><i class="table-material-icons">error</i> Invalid Billing
                Number</span
              >
            </label>
          </div>
          <div
            [ngClass]="{
              'has-error':
                companyinformationForm.get('compname').errors &&
                (companyinformationForm.get('compname').touched ||
                  companyinformationForm.get('compname').dirty)
            }"
          >
            <label class="lib-text-field" required>
              <input
                class="fullWidth"
                placeholder=" "
                formControlName="compname"
                required
              />
              <span class="lib-placeholder">Company Name</span>
              <span
                class="help-block"
                *ngIf="
                  companyinformationForm.get('compname').errors &&
                  (companyinformationForm.get('compname').touched ||
                    companyinformationForm.get('compname').dirty)
                "
              >
                <span
                  *ngIf="companyinformationForm.get('compname').errors.required"
                  class="inputMsg"
                  ><i class="table-material-icons">error</i> Company Name is
                  required</span
                >
              </span>
            </label>
          </div>
        </div>

        <div
          [ngClass]="{
            'has-error':
              companyinformationForm.get('preparer').errors &&
              (companyinformationForm.get('preparer').touched ||
                companyinformationForm.get('preparer').dirty)
          }"
        >
          <label
            [ngClass]="
              isReadOnly ? 'lib-text-field disableClass' : 'lib-text-field'
            "
            required
          >
            <input
              [readonly]="isReadOnly"
              class="fullWidth"
              placeholder=" "
              formControlName="preparer"
            />
            <span class="lib-placeholder">Preparer's Name</span>
            <span
              class="help-block"
              *ngIf="
                companyinformationForm.get('preparer').errors &&
                (companyinformationForm.get('preparer').touched ||
                  companyinformationForm.get('preparer').dirty)
              "
            >
              <span
                *ngIf="companyinformationForm.get('preparer').errors"
                class="inputMsg"
                ><i class="table-material-icons">error</i> Preparer's Name is
                required</span
              >
            </span>
          </label>
        </div>
        <div class="email-container">
          <div
            [ngClass]="{
              'has-error':
                companyinformationForm.get('reportofficer').errors &&
                (companyinformationForm.get('reportofficer').touched ||
                  companyinformationForm.get('reportofficer').dirty)
            }"
          >
            <label
              [ngClass]="
                isReadOnly ? 'lib-text-field disableClass' : 'lib-text-field'
              "
              required
            >
              <input
                [readonly]="isReadOnly"
                class="fullWidth"
                class="fullWidth"
                placeholder=" "
                formControlName="reportofficer"
              />
              <span class="lib-placeholder">Email Address</span>
              <span
                class="help-block"
                *ngIf="
                  companyinformationForm.get('reportofficer').errors &&
                  (companyinformationForm.get('reportofficer').touched ||
                    companyinformationForm.get('reportofficer').dirty)
                "
              >
                <span
                  *ngIf="
                    companyinformationForm.get('reportofficer').errors.required
                  "
                  class="inputMsg"
                  ><i class="table-material-icons">error</i> Email is
                  required</span
                >
                <span
                  *ngIf="
                    companyinformationForm.get('reportofficer').errors.pattern
                  "
                  class="inputMsg"
                  ><i class="table-material-icons">error</i> Please enter a valid
                  email</span
                >
              </span>
            </label>
          </div>
          <div [ngClass]="{
                  'has-error':
                    companyinformationForm.get('additionalemail').errors &&
                    (companyinformationForm.get('additionalemail').touched ||
                      companyinformationForm.get('additionalemail').dirty)
                }">
            <label [ngClass]="isReadOnly ? 'lib-text-field disableClass' : 'lib-text-field'">
              <input [readonly]="isReadOnly" class="fullWidth" placeholder=" "
                formControlName="additionalemail" />
              <span class="lib-placeholder">Additional Email Address</span>
              <span class="help-block" *ngIf="
                  companyinformationForm.get('additionalemail').errors &&
                  (companyinformationForm.get('additionalemail').touched ||
                    companyinformationForm.get('additionalemail').dirty)
                ">
                <span *ngIf="companyinformationForm.get('additionalemail').errors.pattern" class="inputMsg">
                  <i class="table-material-icons">error</i> Please enter a valid email
                </span>
              </span>
            </label>
          </div>
        </div>
        <div
          [ngClass]="{
            'has-error':
              companyinformationForm.get('title').errors &&
              (companyinformationForm.get('title').touched ||
                companyinformationForm.get('title').dirty)
          }"
        >
          <label
            [ngClass]="
              isReadOnly ? 'lib-text-field disableClass' : 'lib-text-field'
            "
            required
          >
            <input
              [readonly]="isReadOnly"
              class="fullWidth"
              placeholder=" "
              formControlName="title"
            />
            <span class="lib-placeholder">Title</span>
            <span
              class="help-block"
              *ngIf="
                companyinformationForm.get('title').errors &&
                (companyinformationForm.get('title').touched ||
                  companyinformationForm.get('title').dirty)
              "
            >
              <span
                *ngIf="companyinformationForm.get('title').errors.required"
                class="inputMsg"
                ><i class="table-material-icons">error</i> Title is
                required</span
              >
            </span>
          </label>
        </div>
        <div
          [ngClass]="{
            'has-error':
              companyinformationForm.get('address').errors &&
              (companyinformationForm.get('address').touched ||
                companyinformationForm.get('address').dirty)
          }"
        >
          <label
            [ngClass]="
              isReadOnly ? 'lib-text-field disableClass' : 'lib-text-field'
            "
            required
          >
            <input
              [readonly]="isReadOnly"
              class="fullWidth"
              placeholder=" "
              formControlName="address"
            />
            <span class="lib-placeholder">Address</span>
            <span
              class="help-block"
              *ngIf="
                companyinformationForm.get('address').errors &&
                (companyinformationForm.get('address').touched ||
                  companyinformationForm.get('address').dirty)
              "
            >
              <span
                *ngIf="companyinformationForm.get('address').errors"
                class="inputMsg"
                ><i class="table-material-icons">error</i> Address is
                required</span
              >
            </span>
          </label>
        </div>

        <div style="width: 100%">
          <div
            class="inputleft"
            [ngClass]="{
              'has-error':
                companyinformationForm.get('city').errors &&
                (companyinformationForm.get('city').touched ||
                  companyinformationForm.get('city').dirty)
            }"
          >
            <label
              [ngClass]="
                isReadOnly ? 'lib-text-field disableClass' : 'lib-text-field'
              "
              required
            >
              <input
                [readonly]="isReadOnly"
                class="fullWidth"
                placeholder=" "
                formControlName="city"
              />
              <span class="lib-placeholder">City</span>
              <span
                class="help-block"
                *ngIf="
                  companyinformationForm.get('city').errors &&
                  (companyinformationForm.get('city').touched ||
                    companyinformationForm.get('city').dirty)
                "
              >
                <span
                  *ngIf="companyinformationForm.get('city').errors"
                  class="inputMsg"
                  ><i class="table-material-icons">error</i> City is
                  required</span
                >
              </span>
            </label>
          </div>
          <div class="inputright">
            <div *ngIf="!isReadOnly; else readonlyState">
              <div class="dropdown-label">* State</div>

              <app-select-dropdown (onSelect)="setState($event)">
                <option
                  *ngFor="let item of states; let i = index"
                  value="{{ item.name }}"
                  [attr.selected]="i == selectedIndex ? true : false"
                >
                  {{ item.name }}
                </option>
              </app-select-dropdown>
            </div>
            <ng-template #readonlyState>
              <label
                [ngClass]="
                  isReadOnly ? 'lib-text-field disableClass' : 'lib-text-field'
                "
              >
                <input
                  [readonly]="isReadOnly"
                  placeholder=" "
                  value="{{ ViewselectedState }}"
                  name="state"
                />
                <span class="lib-placeholder">State *</span>
              </label>
            </ng-template>
          </div>
        </div>
        <div style="width: 100%">
          <div
            class="inputleft"
            [ngClass]="{
              'has-error':
                companyinformationForm.get('zipcode').errors &&
                (companyinformationForm.get('zipcode').touched ||
                  companyinformationForm.get('zipcode').dirty)
            }"
          >
            <label
              [ngClass]="
                isReadOnly ? 'lib-text-field disableClass' : 'lib-text-field'
              "
              required
            >
              <input
                [readonly]="isReadOnly"
                class="fullWidth"
                placeholder=" "
                formControlName="zipcode"
              />
              <span class="lib-placeholder">Zip Code</span>
              <span style="color: #76797b; margin-top: 5px"
                >ex. 55555 or 55555-5555</span
              >
              <span
                class="help-block"
                *ngIf="
                  companyinformationForm.get('zipcode').errors &&
                  (companyinformationForm.get('zipcode').touched ||
                    companyinformationForm.get('zipcode').dirty)
                "
              >
                <span
                  *ngIf="companyinformationForm.get('zipcode').errors.required"
                  class="inputMsg"
                  ><i class="table-material-icons">error</i> Zip Code is
                  required</span
                >
                <span
                  *ngIf="companyinformationForm.get('zipcode').errors.pattern"
                  class="inputMsg"
                  ><i class="table-material-icons">error</i> Please enter a
                  valid zipcode</span
                >
              </span>
            </label>
          </div>
          <div
            class="inputright"
            [ngClass]="{
              'has-error':
                companyinformationForm.get('phone').errors &&
                (companyinformationForm.get('phone').touched ||
                  companyinformationForm.get('phone').dirty)
            }"
          >
            <label
              [ngClass]="
                isReadOnly ? 'lib-text-field disableClass' : 'lib-text-field'
              "
              required
            >
              <input
                [readonly]="isReadOnly"
                class="fullWidth"
                placeholder=" "
                formControlName="phone"
              />
              <span class="lib-placeholder">Phone Number</span>
              <span style="color: #76797b; margin-top: 5px"
                >ex. (999) 999-9999</span
              >
              <span
                class="help-block"
                *ngIf="
                  (companyinformationForm.get('phone').errors &&
                    (companyinformationForm.get('phone').touched ||
                      companyinformationForm.get('phone').dirty)) ||
                  (companyinformationForm.get('phone').errors &&
                    !companyinformationForm.get('phone').errors.required)
                "
              >
                <span
                  *ngIf="companyinformationForm.get('phone').errors.required"
                  class="inputMsg"
                  ><i class="table-material-icons">error</i> Phone number is
                  required</span
                >
                <span
                  *ngIf="
                    companyinformationForm.get('phone').errors &&
                    !companyinformationForm.get('phone').errors.required
                  "
                  class="inputMsg"
                  ><i class="table-material-icons">error</i> Please enter a
                  valid US phone number</span
                >
              </span>
            </label>
          </div>
        </div>
        <div style="padding-top: 20px">
          <div style="clear: both" class="note">Allow Group Reporting?</div>
          <div class="form-check form-check-inline">
            <input
              id="gron"
              [attr.disabled]="isReadOnly ? 'true' : null"
              class="form-check-input"
              type="radio"
              (change)="handleGroupReportChange($event)"
              name="groupreport"
              value="on"
              formControlName="groupreport"
              checked
            />
            <label for="gron" class="form-check-label"> Yes </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              id="groff"
              [attr.disabled]="isReadOnly ? 'true' : null"
              class="form-check-input"
              type="radio"
              name="groupreport"
              value=""
              formControlName="groupreport"
            />
            <label for="groff" class="form-check-label"> No</label>
          </div>
          <div style="width: 100%">
            <span
              class="note"
              style="margin-top: 8px"
              *ngIf="companyinformationForm.value.groupreport == 'on'"
            >
              You must fill NAIC details through Group Reporting page
            </span>
          </div>
          <div style="width: 100%">
            <div *ngIf="companyinformationForm.value.groupreport !== 'on'">
              <div class="inputleft">
                <div
                  [ngClass]="{
                    'has-error':
                      companyinformationForm.get('naic').errors &&
                      (companyinformationForm.get('naic').touched ||
                        companyinformationForm.get('naic').dirty)
                  }"
                >
                  <label
                    [ngClass]="
                      isReadOnly
                        ? 'lib-text-field disableClass'
                        : 'lib-text-field'
                    "
                  >
                    <input
                      [attr.disabled]="isReadOnly ? 'true' : null"
                      class="fullWidth"
                      placeholder=" "
                      formControlName="naic"
                      (keyup)="validateNaic($event)"
                    />
                    <span class="lib-placeholder">NAIC Code</span>
                    <span
                      class="help-block"
                      *ngIf="
                        companyinformationForm.get('naic').errors &&
                        (companyinformationForm.get('naic').touched ||
                          companyinformationForm.get('naic').dirty)
                      "
                    >
                    </span>
                    <span class="error inputMsg" *ngIf="!validnaic"
                      ><i class="table-material-icons">error</i> Invalid NAIC
                      Code</span
                    >
                  </label>
                </div>
              </div>
              <div class="inputright">
                <div
                  [ngClass]="{
                    'has-error':
                      companyinformationForm.get('ambest').errors &&
                      (companyinformationForm.get('ambest').touched ||
                        companyinformationForm.get('ambest').dirty)
                  }"
                >
                  <label
                    [ngClass]="
                      isReadOnly
                        ? 'lib-text-field disableClass'
                        : 'lib-text-field'
                    "
                  >
                    <input
                      [attr.disabled]="isReadOnly ? 'true' : null"
                      class="fullWidth"
                      placeholder=" "
                      formControlName="ambest"
                      (keyup)="validateAMBest($event)"
                    />
                    <span class="lib-placeholder">AM Best</span>
                    <span
                      class="help-block"
                      *ngIf="
                        companyinformationForm.get('ambest').errors &&
                        (companyinformationForm.get('ambest').touched ||
                          companyinformationForm.get('ambest').dirty)
                      "
                    >
                    </span>
                    <span class="error inputMsg" *ngIf="!validambest"
                      ><i class="table-material-icons">error</i> Invalid AM
                      Best</span
                    >
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span
          class="error"
          *ngIf="
            companyinformationForm.controls['groupreport'].value !== 'on' &&
            companyinformationForm.controls['ambest'].value == '' &&
              companyinformationForm.controls['naic'].value == ''
          "
          >Either NAIC Code or AM BEST is required</span
        >
        <div
          *ngIf="!isReadOnly"
          style="padding: 40px 0 40px 0; display: flex; align-items: center"
        >
          <app-button
            size="medium"
            type="submit"
            style="width: 100%"
            class="nocursor"
            [disabled]="
              selectedState == 'Select' ||
              !renamecompleted ||
              !validcode ||
              !isvalidbillno ||
              !hasStatrtedWithGroup ||
              !companyinformationForm.valid ||
              (companyinformationForm.controls['groupreport'].value !== 'on' &&
                companyinformationForm.controls['ambest'].value == '' &&
                  companyinformationForm.controls['naic'].value == '')
            "
            buttonType="primary"
          >
            Confirm Information</app-button
          >
        </div>
      </form>
    </div>
    </div>
  </div>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
