import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements OnInit {
  @Input() modalTitle;
  @Input() titleNote;
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  nowShowing: boolean = true;
  constructor() {}

  ngOnInit(): void {
    this.nowShowing = true;
    document.body.classList.add("noscroll");
  }

  closeModal() {
    this.nowShowing = false;
    setTimeout(() => {
      document.body.classList.remove("noscroll");
      if (this.onClose) {
        this.onClose.emit();
      }
    }, 150);
  }
}
