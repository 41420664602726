<mat-card class="top-card" *ngIf="gridConfigVisible$ | async" [@slideInOut]>
  <div class="custom-card">
    <div class="card-header">
      <div class="header-content">
        <div class="header-settings-text">Settings</div>
        <div class="reset-data">
          <div class="resetToReccomended" (click)="handleResetToDefaultGridConfig()">
            <app-icon icon="reset-to-default"></app-icon>
            <span class="reset-data-text">Reset Based on Prior Year</span>
          </div>
          <div class="resetToExistingData" (click)="handleResetToExistingDataGridConfig()">
            <app-icon icon="reset-to-existing-data"></app-icon>
            <span class="reset-data-text">Enable Rows and Columns with Values</span>
          </div>
        </div>
      </div>
    </div>
    <div class="custom-card-content">
      <mat-tab-group>
        <mat-tab label="States">
          <states-configuration [rowStates]="rowStates$ | async" [isAllRowsSelected]="isAllRowsSelected$ | async"
            [premiumType]="premiumType"></states-configuration>
        </mat-tab>
        <mat-tab label="Premium Columns">
          <premium-columns-configuration [premiumColumns]="premiumColumns$ | async"
            [isAllColumnSelected]="isAllColumnsSelected$ | async"
            [premiumType]="premiumType"></premium-columns-configuration>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div class="card-footer">
      <div class="button-container">
        <a class="clearButton" (click)="handleGridConfigClose()">Cancel</a>
        <div class="call-to-action">
          <a class="primary grid-config-apply-button" role="button" (click)="handleGridConfigSave()">Apply</a>
        </div>
      </div>
    </div>
  </div>
</mat-card>
