import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../company/company.service'
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isLockForValidationSelector, isValidationJobIdNotEmptySelector } from '../company/premiumreport/data-access/premium-report.selectors';
import { Store } from '@ngrx/store';
import { AppService } from '../app.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  subscription: Subscription;
  companyInformationCompleted: boolean = false;
  companyInformationInProgress: boolean = false;
  disableCompanylist: boolean = false;
  premiumreportCompleted: boolean = false;
  premiumreportInProgress: boolean = false;

  groupreportingCompleted: boolean = false;
  groupreportingInProgress: boolean = false;

  annualstatementCompleted: boolean = false;
  annualstatementInProgress: boolean = false;

  userdefinedcolsCompleted: boolean = false;
  userdefinedcolsInProgress: boolean = false;

  userdefineddataCompleted: boolean = false;
  userdefineddataInProgress: boolean = false;

  commentsCompleted: boolean = false;
  commentsInProgress: boolean = false;
  disableCinformation: boolean = true;
  disable: boolean = true;
  disablePReport: boolean = true;
  disableGReport: boolean = true;
  disableAStatement: boolean = true;
  disableUCols: boolean = true;
  disableUData: boolean = true;
  disableComments: boolean = true;
  helpUrl = "https://iso.com";
  isLockedForValisation$ = this.store.select(isLockForValidationSelector);//.pipe(map(x => { debugger; return Boolean(x); }))

  isValidationJobIdNotEmptySelector$ = this.store.select(
    isValidationJobIdNotEmptySelector
  );
  disableValidationSummary: boolean = true;
  premiumYearComparisonCompleted: boolean = false;
  disablePriorYearComparison: boolean = true;
  isReadOnlyAdmin: boolean;

  constructor(private companyService: CompanyService, private router: Router, private activeRoute: ActivatedRoute, private modalService: NgbModal, private store: Store, private appService: AppService) { }

  ngOnInit(): void {
    this.isReadOnlyAdmin = this.appService.isReadonlyAdmin;
    if (localStorage.getItem('companyId') != '' && localStorage.getItem('year') != '') {
      this.companyService.loadPremiumData();
    }

    this.companyService.disableCompanyList.subscribe(result => {
      this.disableCompanylist = result;
    });

    this.companyService.companyInformationInProgress.subscribe(result => {
      this.companyInformationInProgress = result;
      this.disableCinformation = false;
      this.disablePriorYearComparison = true;
    });

    this.companyService.companyInformationCompleted.subscribe(result => {
      this.companyInformationCompleted = result;
      this.disableCinformation = false;
      this.disablePriorYearComparison = true;
    });


    this.companyService.premiumreportCompleted.subscribe(result => {
      this.premiumreportCompleted = result;
      this.disablePReport = false;
      this.disableValidationSummary = false;
    });


    this.companyService.premiumreportInProgress.subscribe(result => {
      this.premiumreportInProgress = result;
      this.disablePReport = false;
    });



    this.companyService.groupreportingCompleted.subscribe(result => {
      this.groupreportingCompleted = result;
      this.disableGReport = false;
    });



    this.companyService.groupreportingInProgress.subscribe(result => {
      this.groupreportingInProgress = result;
      this.disableGReport = false;
    });



    this.companyService.annualstatementCompleted.subscribe(result => {
      this.annualstatementCompleted = result;
      this.disableAStatement = false;
    });

    this.companyService.annualstatementInProgress.subscribe(result => {
      this.annualstatementInProgress = result;
      this.disableAStatement = false;
    });



    this.companyService.userdefinedcolsCompleted.subscribe(result => {
      this.userdefinedcolsCompleted = result;
      this.disableUCols = false;
    });

    this.companyService.userdefinedcolsInProgress.subscribe(result => {
      this.userdefinedcolsInProgress = result;
      this.disableUCols = false;
    });



    this.companyService.userdefineddataCompleted.subscribe(result => {
      this.userdefineddataCompleted = result;
      this.disableUData = false;
    });

    this.companyService.userdefineddataInProgress.subscribe(result => {
      this.userdefineddataInProgress = result;
      this.disableUData = false;
    });


    this.companyService.commentsCompleted.subscribe(result => {
      this.commentsCompleted = result;
      this.disableComments = false;

    });

    this.companyService.commentsInProgress.subscribe(result => {
      this.commentsInProgress = result;
      this.disableComments = false;
    });

    this.companyService.PriorYearComparisonCompleted.subscribe(result => {
      this.premiumYearComparisonCompleted = result;
      this.disablePriorYearComparison = false;
    });


    this.companyService.disablePrf.subscribe(disable => {
      this.disable = disable;
      this.disableAStatement = disable;
      this.disableCinformation = disable;
      this.disableGReport = disable;
      this.disablePReport = disable;
      this.disableUCols = disable;
      this.disableUData = disable;
      this.disableComments = disable;
      this.disableValidationSummary = disable;
      this.disablePriorYearComparison = disable;
    });

    this.handleProfileChange();
  }

  handleProfileChange() {
    // debugger
    if (this.activeRoute.snapshot.queryParams.hasOwnProperty("switchcompany")) {
      setTimeout(() => {
        // ignore api failure redirect
        this.router.navigate([''])
      }, 300);
    }
  }

  nogroupbullet() {

    var cmpid = localStorage.getItem("companyId") || "";
    if (this.companyService.grouponoff == "" && this.router.url != "/dboard" && cmpid != "") {
      return true;
    }
    else {
      return false;
    }
  }

  openInNew(url) {

    this.companyService.getTemplateFile("ScreenHelp.pdf").subscribe((resp) => {
      if (resp["message"] == "Success") {
        var fileurl = resp["templateurl"];
        window.open(fileurl, "_blank");

      }
    },
      (error) => {
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    )
  }

  openFinalSubmission() {
    this.router.navigate(['/PRFSubmission']);
    //const modalRef = this.modalService.open(ReportsubmissionComponent, { windowClass: "finalSubmissionModal" });
  }

}
