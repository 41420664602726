<app-loading-screen *ngIf="isLoading">Loading Premiums</app-loading-screen>
<app-loading-screen *ngIf="isDownloading">Downloading File</app-loading-screen>
<div *ngIf="!isReadOnly" style="float: right; display: flex;">
    <!-- <app-button size="medium" buttonType="primary" (click)="openuserdefinedcols()" style="margin-right: 15px;">USER
        DEFINED COLS
    </app-button> -->
    <app-button size="medium" buttonType="primary" (click)="UpdatePremiumdetails()" style="margin-right: 15px;">Confirm
        Data</app-button>
    <a class="clearButton interactive-links" (click)="Clear()">Clear</a>
</div>
<div class="pagetitle">Premium Report: User Defined Data: {{ currentYear }}</div>
<div *ngIf="compno!==null">
    <span class="pagetitleSecondLayerBold"> {{ prfNumber }} </span>:<span class="pagetitleSecondLayer">
        {{ prfName }}</span>
</div>
<hr class="horizontalRule" />
<!-- <div *ngIf="isLocked" class="information">
    <div class="messagetitle">
        <i class="table-material-icons">error</i>
        This form is aleardy submitted. You cannot make changes.
    </div>
</div>
<div *ngIf="isReadOnlyAdmin && !isLocked" class="information">
    <div class="messagetitle">
        <i class="table-material-icons">error</i>
        You are not authorized to make changes.
    </div>
</div> -->
<br />

<div class="noteB" style="display: inline-block; ">Note: Dollar amounts represented as thousands of dollars.</div>
<div *ngIf="!isReadOnly && hasUserDefinedCols" class="downloadButton">
    <button (click)="downloadtemplate()" class="interactive-links"><i class="table-material-icons">get_app</i> Download Template</button>
</div>
<div class="PageLevelError" *ngIf="showPageError">{{ errorMsg }}</div>
<div class="PageLevelWarning" *ngIf="showPageWarning">{{ warningMsg }}</div>
<div class="grid__menu">
    <span class="lblCellName">{{cellName}}</span>
    <span class="lblCellName">{{cellFormula}}</span>
</div>

<div class="gridheader">
    <div class="tab">
        <button class="tablinks active interactive-links" (click)="showtab('direct')">Direct Premiums</button>
        <button class="tablinks interactive-links" (click)="showtab('supplemental')">Supplemental Premiums</button>
    </div>
    <div class="flex-item flex-grow"></div>
    <div class="action horizontal-flex-container">
        <button *ngIf="!isReadOnly" (click)="openuserdefinedcols()" style="cursor: pointer;" class="interactive-links"><i
                class="table-material-icons">view_column</i> Manage Columns</button>
        <button *ngIf="!isReadOnly" (click)="openUploadPremium()" style="cursor: pointer;" class="interactive-links"><i
                class="table-material-icons">publish</i> Upload
            Data</button>
        <button (click)="downloadData(false)" class="interactive-links">
            <i class="table-material-icons">get_app</i>
            Download Data</button>
        <button (click)="downloadData(true)" class="interactive-links"><img src="../../../assets/img/pdf.png" alt="" /> Generate PDF</button>
    </div>
</div>
<div class="igx-grid-container" style="width: 100%" (clickOutside)="onClickedOutside($event)">
    <igx-grid igxPreventDocumentScroll #grid1 [data]="premium_info" [autoGenerate]="false" height="450px"
        [rowEditable]="false" paste-handler
        (onDataProcessed)="updateRecords($event)" [primaryKey]="'0'" [clipboardOptions]="options"
        (cellEditEnter)="editStart($event)" (cellEditDone)="onEditCompleted($event)"
        (selected)="onCellSelect($event)" [emptyGridMessage]="'.'" (gridKeydown)="enterGridEditMode($event)"
        (keydown)="enterEditMode($event)">

        <igx-column *ngFor="let c of udcolumns" [field]="c.columnNumber.toString()" [header]="c.columnName" [pinned]="c.IsPinned"
            [cellClasses]="cellClass" [editable]="isReadOnly == true ? false : c.editable"
            [width]="getColWidth(c.columnNumber)" [dataType]="c.dataType" [resizable]="true">
            <ng-template igxHeader let-column>
                <div>
                    {{ column.header | modifyHeader }}
                </div>
            </ng-template>
            <ng-template igxCellEditor let-cell="cell">
                <igx-input-group>
                    <input igxInput [igxFocus]="true" (focus)="onCellFocus($event)" [(ngModel)]="cell.editValue"
                        style="color: black;min-width:100%" type="number" id="input" [ngClass]="{'no-spinners': true}" />
                </igx-input-group>
            </ng-template>
        </igx-column>
    </igx-grid>
    <div id="direct" style="width: 100%"> </div>
    <div id="supplemental"> </div>
</div>
<div class="note" style="display: inline-block">
    <span class="noteTitle">Note:</span> Use Ctrl+X/Ctrl+C/Ctrl+V for Cut/Copy/Paste operations
  </div>
<app-confirm-dialog></app-confirm-dialog>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
