<app-loading-screen *ngIf="isLoading || (isLoading$ | async)"
  >Loading Premiums</app-loading-screen
>
<app-loading-screen *ngIf="isDownloading">Downloading File</app-loading-screen>
<div *ngIf="!isReadOnly" style="float: right; display: flex">
  <app-button
    size="medium"
    buttonType="primary"
    (click)="updatePremiumDetails()"
    style="margin-right: 15px"
    >Confirm Data</app-button
  >
  <a class="clearButton interactive-links" (click)="Clear()">Clear</a>
</div>
<div class="pagetitle">Premium Report: {{ currentYear }}</div>
<div *ngIf="compno !== null">
  <span class="pagetitleSecondLayerBold"> {{ prfNumber }} </span>:<span
    class="pagetitleSecondLayerBold"
  >
    {{ prfName }}</span
  >
</div>
<hr class="horizontalRule" />

<div class="noteB" style="display: inline-block">
  Note: Dollar amounts represented as thousands of dollars.
</div>
<div *ngIf="!isReadOnly" class="downloadButton">
  <button (click)="downloadtemplate()" class="interactive-links">
    <i class="table-material-icons">get_app</i> Download Template
  </button>
</div>
<div class="PageLevelError" *ngIf="showPageError$ | async">
  {{ errorMsg$ | async }}
</div>
<div class="PageLevelWarning" *ngIf="showPageWarning">{{ warningMsg }}</div>
<div class="grid__menu">
  <span class="lblCellName">{{ cellName$ | async }}</span>
  <span class="lblCellName">{{ cellFormula$ | async }}</span>
</div>

<div id="grid-container">
  <div class="gridheader">
    <div class="tab">
      <button
        class="tablinks interactive-links"
        [class.active]="(premium_type$ | async) == 'D'"
        (click)="showtab('direct')"
      >
        Direct Premiums
      </button>
      <button
        class="tablinks interactive-links"
        [class.active]="(premium_type$ | async) == 'S'"
        (click)="showtab('supplemental')"
      >
        Supplemental Premiums
      </button>
    </div>
    <div class="flex-item flex-grow"></div>
    <div class="horizontal-flex-container">
      <div class="consolidated-or-detail-view" *ngIf="!!isReadOnlyAdmin">
        <span>Detail View </span>
        <mat-slide-toggle
          class="custom-slider-select-all"
          [hideIcon]="true"
          (change)="toggleConsolidatedOrDetailView($event)"
        >
        </mat-slide-toggle>
        <span>Consolidated View </span>
        <span class="separator" *ngIf="!!isReadOnlyAdmin"></span>
      </div>
      <button
        *ngIf="!isReadOnly"
        (click)="openUploadPremium()"
        style="cursor: pointer"
        class="interactive-links exitFS"
      >
        <i class="table-material-icons">publish</i> Upload Data
      </button>
      <span class="separator" *ngIf="!isReadOnly"></span>
      <button (click)="downloadData(false)" class="interactive-links exitFS">
        <i class="table-material-icons">get_app</i> Download Data
      </button>
      <span class="separator"></span>
      <button (click)="downloadData(true)" class="interactive-links exitFS">
        <img src="../../../assets/img/pdf.png" alt="" /> Generate PDF
      </button>
      <span class="separator"></span>
      <div class="position-relative">
        <button
          *ngIf="!isReadOnlyAdmin"
          class="button"
          ngbTooltip="Count of hidden States with values | Count of hidden Premium Columns with values"
          placement="bottom"
          tooltipClass="tooltip-class custom-tooltip"
        >
          <i class="table-material-icons">border_all</i>
          <span
            [ngClass]="{
              'active-danger':
                hiddenPremiumCount.rows || hiddenPremiumCount.columns
            }"
            class="custom-badge"
          >
            {{ hiddenPremiumCount.rows }}|{{ hiddenPremiumCount.columns }}
          </span>
        </button>
      </div>
      <span class="separator" *ngIf="!isReadOnlyAdmin"></span>
      <div class="position-relative" (clickOutside)="handleClickOutside()">
        <button
          *ngIf="!isReadOnlyAdmin"
          class="button"
          (click)="onGridConfigButtonClick()"
        >
          <i class="table-material-icons">tune</i>
        </button>
        <grid-configuration
          [premiumType]="premium_type"
          #gridConfig
        ></grid-configuration>
      </div>
      <span class="separator"></span>
      <div class="position-relative">
        <button
          *ngIf="!isFullscreen"
          (click)="openFullscreen()"
          class="bottom"
          ngbTooltip="View&nbsp;full&nbsp;screen"
          placement="bottom"
        >
          <i class="table-material-icons fs-20">fullscreen</i>
        </button>
        <button
        *ngIf="isFullscreen"
         (click)="exitFullscreen()"
         class="left"
         ngbTooltip="Exit&nbsp;full&nbsp;screen"
         placement="left"
       >
         <i class="table-material-icons fs-20 sp-position" >exit_fullscreen</i>
       </button>
  
      </div>
    </div>
  </div>

  <app-direct-premiums [isFullscreen]="isFullscreen"
    *ngIf="premium_type == 'D' && !(isLoading$ | async)"
  ></app-direct-premiums>

  <app-supplemental-premiums [isFullscreen]="isFullscreen"
    *ngIf="premium_type == 'S' && !(isLoading$ | async)"
  ></app-supplemental-premiums>
  <app-confirm-dialog></app-confirm-dialog>
  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
</div>
<div class="note" style="display: inline-block">
  <span class="noteTitle">Note:</span> Use Ctrl+X/Ctrl+C/Ctrl+V for Cut/Copy/Paste operations
</div>
