<div class="ui-select-single"  (clickOutside)="hideOptionList()">
  <div
    class="selected-value"
    (click)="showOptions($event)"
    [ngClass]="showOptionList && 'selected-value-highlight'"
  >
  <div class="mc">
    {{ selectedOption != "" ? selectedOption : ""}}
  </div>
    <span class="material-icons"> expand_more </span>
  </div>
  <select style="display: none" (cdkObserveContent)="onContentChange($event)" #content>
    <ng-content></ng-content>
  </select>
  <div>
    <div
      class="list-options"
      [ngClass]="showOptionList ? 'show-OptList' : 'hide-OptList'"
    >
      <div *ngIf="showOptionList">
        <li
          *ngFor="let opt of optionList"
          (click)="select(opt.option, opt.value)"
          [class.selected]="opt.value === selectedValue"
        >
          {{ opt.option }}
        </li>
      </div>
    </div>
  </div>
</div>
