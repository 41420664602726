import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserDefiledColsModel } from "../company/commpany.model";
import { CompanyService } from '../company/company.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-copycompany',
  templateUrl: './copycompany.component.html',
  styleUrls: ['./copycompany.component.scss']
})
export class CopycompanyComponent implements OnInit {
  @Input() title;
  @Input() prfYear;
  @Input() oldGroup;
  @Input() oldCompanyno;
  @Input() oldName;
  @Input() naic;
  @Input() amBest;
  @Input() groupReporting;

  copyCompanyForm: UntypedFormGroup;
  showPageError: boolean;
  show: boolean
  error: any;
  showvalidation: boolean;
  validationerror: boolean
  hasStatrtedWithGroup: boolean = true;
  isvalidbillno: boolean = true;
  groupcode: any
  disable: boolean = false;
  isLoaded: boolean = false;

  validnaic: boolean = true;
  validambest: boolean = true;
  validcode: boolean = true;

  constructor(private router: Router, private companyService: CompanyService, public builder: UntypedFormBuilder, public activeModal: NgbActiveModal) {
    this.copyCompanyForm = this.builder.group({});
  }

  ngOnInit(): void {
    setTimeout(()=>{this.isLoaded=true;},500);
    this.copyCompanyForm.addControl('newGroup', new UntypedFormControl('', Validators.required));
    this.copyCompanyForm.addControl('newNumber', new UntypedFormControl('', Validators.required));
    this.copyCompanyForm.addControl('newName', new UntypedFormControl('', Validators.required));
    this.copyCompanyForm.addControl('groupname', new UntypedFormControl(''));
    this.copyCompanyForm.addControl('naic', new UntypedFormControl(''));
    this.copyCompanyForm.addControl('amBest', new UntypedFormControl(''));


  }

  clearError() {
    this.error = '';
    this.showPageError = false;
    this.disable = false;
  }

  validateGroup(e) {
    this.showPageError = false;
    if (e.target.value.length == 4 && /^\d+$/.test(e.target.value)) {
      this.showvalidation = true;
      this.companyService.validategroup(this.companyService.currentYear, this.copyCompanyForm.controls['newGroup'].value).subscribe((resp) => {

        if (resp["message"] == "Success") {

          if (resp["status"] == '') {
            this.showPageError = true;
            this.validationerror = true;
            this.error = "Group does not exist"
            this.copyCompanyForm.controls['groupname'].setValue('')
            this.groupcode = ''
          } else {
            this.groupcode = e.target.value
            this.showPageError = false;
            this.validationerror = false;
            this.copyCompanyForm.controls['groupname'].setValue(resp["status"])
            if (this.copyCompanyForm.get('newNumber').value) {
              var newnumber = this.copyCompanyForm.get('newNumber').value;
              if (newnumber.startsWith(this.groupcode))
                this.hasStatrtedWithGroup = true;
              else
                this.hasStatrtedWithGroup = false;
            }
          }
          this.showvalidation = false;
        }
      }
        ,
        (error) => {
          //console.log(error);
          this.router.navigate(['/apifailure']);
        }
      )
    } else {
      this.copyCompanyForm.controls['groupname'].setValue('')
      this.error = "Invalid Group";
      this.groupcode = '';
      this.showPageError = true;
      this.validationerror = true;
    }
  }

  validateBillNumber(billno: any): boolean {
    if (this.groupcode !== '') {
      this.error = '';
      this.showPageError = false;
    }
    this.disable = false;
    if (!billno.target.value.startsWith(this.groupcode)) {
      this.hasStatrtedWithGroup = false;

    }
    else {
      this.hasStatrtedWithGroup = true;
      this.isvalidbillno = true;

    }

    if (billno.target.value.length > 6) {
      this.isvalidbillno = false;
    } else if (billno.target.value.length > 4) {

      if (billno.target.value.substring(4).match(/^[a-zA-Z]+$/)) {
        this.isvalidbillno = true;
      } else {
        this.isvalidbillno = false;
      }
    }

    return true;
  }

  validateNaic(e) {
    //this.validambest = true;
    if (e.target.value == '' || /^\d+$/.test(e.target.value)) {
      console.log('success naic');
      this.validnaic = true;
      this.showPageError = false;
      this.error = '';
      this.validcode = this.validambest && this.validnaic;
    }

    else {
      this.validnaic = false;
      this.validcode = false;
    }
  }

  validateAMBest(e) {
    //this.validnaic = true;
    if (e.target.value == '' || /^\d+$/.test(e.target.value)) {
      console.log('success ambest');
      this.validambest = true;
      this.showPageError = false;
      this.error = '';
      this.validcode = this.validambest && this.validnaic;
    }

    else {
      this.validambest = false;
      this.validcode = false;
    }
  }


  onsubmit() {
    this.error = ''
    var newgroup = this.copyCompanyForm.get("newGroup").value;
    var newNumber = this.copyCompanyForm.get("newNumber").value;
    var newName = this.copyCompanyForm.get("newName").value;
    if (newgroup.trim() == '') {
      this.error = "Invalid Group Number. ";
      this.showPageError = true;
    }
    if (newNumber.trim() == '') {
      this.error += 'Invalid PRF Number. ';
      this.showPageError = true;
    }
    if (newName.trim() == '') {
      this.error += 'Invalid Company Name.';
      this.showPageError = true;
    }
    if (newgroup.trim() == '' || newNumber.trim() == '' || newName.trim() == '') {
      return;
    }
    if (this.oldCompanyno.trim().toUpperCase() == newNumber.trim().toUpperCase() && this.oldGroup.trim().toUpperCase() == newgroup.trim().toUpperCase() && this.oldName.trim().toUpperCase() == newName.trim().toUpperCase()) {
      this.error = "Source and target files are identical. Please give different Group number or PRF number or PRF Name."
      this.showPageError = true;
      return;
    }

    if (newgroup.trim().length !== 4 || /^\d+$/.test(newgroup.trim()) == false) {
      this.error = 'Invalid Group.';
      this.showPageError = true;
      return;
    }
    if (newgroup.trim().substring(0, 4) !== newNumber.trim().substring(0, 4)) {
      this.error = "Invalid billing number. Please start PRF # with " + newgroup.trim().substring(0, 4)
      this.showPageError = true;
      return;
    }

    const naic = this.copyCompanyForm.get("naic").value;
    const amBest = this.copyCompanyForm.get("amBest").value;

    if (!this.validnaic) {
        this.error = "Invalid NAIC Code: Must be a numeric string or empty.";
        this.showPageError = true;
        return;
    }

    if (!this.validambest) {
      this.error = "Invalid AMBEST: Must be a numeric string or empty";
      this.showPageError = true;
      return;
  }

  const groupReporting = this.groupReporting;
  if (!groupReporting) {
    const isNaicEmpty = this.naic === "";
    const isAmBestEmpty = this.amBest === "";
    const isAmBestAndNaicInvalid = isAmBestEmpty && isNaicEmpty;

    if (isAmBestAndNaicInvalid) {
      this.error = "At least one of AMBEST or NAIC must have a value since the group reporting is disabled.";
      this.showPageError = true;
      return;
    }
  }

    if (this.error == '') {
      this.show = true;
      this.disable = true;

      this.companyService.copycompany(
        {
          prfYear: this.prfYear,
          oldGroup: this.oldGroup,
          oldCompanyno: this.oldCompanyno,
          newGroup: newgroup,
          newNumber: newNumber.toUpperCase(),
          newName: newName,
          naic: naic,
          amBest: amBest
        }).subscribe((resp) => {

        if (resp["message"] == "Success") {
          if (typeof (resp["companydetails"]) == 'string') {
            this.error = resp["companydetails"];
            this.showPageError = true;
          } else {
            this.activeModal.close(resp["companydetails"]);
          }
          this.show = false;
        }
      },
        (error) => {
          //console.log(error);
          this.router.navigate(['/apifailure']);
        }
      )
    }
  }
}
