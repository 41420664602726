<app-modal-loading-screen *ngIf="spinloader">{{spinnertxt}}</app-modal-loading-screen>
<div *ngIf="isLoaded" class="modal" tabindex="-1" role="dialog" style="display:block!important">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="pagetitle">{{title}}</h4>
                <span class="table-material-icons" (mousedown)="activeModal.close('Close')"
                    style="cursor: pointer;font-size: 24px;">close</span>
            </div>
            <form [formGroup]="groupForm" (ngSubmit)="onsubmit()">
                <div class="modal-body">
                    <div class="PageLevelError" *ngIf="pageerror">{{error}}</div>
                    <div class="inputright" [ngClass]="{
                        'has-error':
                        groupForm.get('groupcode').errors &&
                          (groupForm.get('groupcode').touched ||
                          groupForm.get('groupcode').dirty)
                      }">
                        <label class="lib-text-field" required>
                            <input placeholder=" " class="fullWidth" [(ngModel)]="number" formControlName="groupcode"
                                (keypress)="validateInput($event)" (blur)="getGroupName($event)" />
                            <span class="lib-placeholder">Number</span>
                            <span class="help-block" *ngIf="
                                groupForm.get('groupcode').errors &&
                        (groupForm.get('groupcode').touched ||
                        groupForm.get('groupcode').dirty)
                      ">
                                <span *ngIf="groupForm.get('groupcode').errors.required" class="inputMsg"><i
                                        class="table-material-icons">error</i> Number is
                                    required</span>
                                <span
                                    *ngIf="groupForm.get('groupcode').errors.maxlength || groupForm.get('groupcode').errors.minlength"
                                    class="inputMsg"><i class="table-material-icons">error</i> Invalid group number.
                                    Length should be equal to 4 or 5</span>
                                <!-- <span
                                    *ngIf="groupForm.get('groupcode').errors.max && groupForm.get('groupcode').errors.pattern"
                                    class="inputMsg"><i class="table-material-icons">error</i> Invalid group number.
                                    Group number fifth characters should be an alphabet</span> -->
                            </span>
                        </label>
                    </div>
                    <!-- <div class="inputright" [ngClass]="{
                        'has-error':
                        groupForm.get('groupname').errors &&
                          (groupForm.get('groupname').touched ||
                          groupForm.get('groupname').dirty)
                      }"> -->
                    <label class="lib-text-field" required>
                        <input readonly placeholder=" " class="fullWidth" formControlName="groupname" />
                        <span class="lib-placeholder">Name</span>
                        <!-- <span class="help-block" *ngIf="
                                groupForm.get('groupname').errors &&
                        (groupForm.get('groupname').touched ||
                        groupForm.get('groupname').dirty)
                      ">
                                <span *ngIf="groupForm.get('groupname').errors.required" class="inputMsg"><i
                                        class="table-material-icons">error</i> Name is
                                    required</span> -->
                        <!-- <span
                                    *ngIf="groupForm.get('groupname').errors.pattern"
                                    class="inputMsg"><i class="table-material-icons">error</i> Invalid group number.
                                    Group number fifth characters should be an alphabet</span> -->
                        <!-- </span> -->
                    </label>
                    <!-- </div> -->



                </div>
                <div class="modal-footer">
                    <app-button buttonType="primary" type="submit" class="nocursor" [disabled]="!groupForm.valid"
                        style="width: 100%;">
                        Create
                    </app-button>
                </div>
            </form>
        </div>
    </div>
</div>