import {
  Directive,
  Input,
  ElementRef,
  Renderer2,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';

@Directive({
  selector: '[appSortEmitter]',
})
export class SortEmitterDirective {
  @Input() appSortEmitter: Array<any>;
  @Output() sortChanged = new EventEmitter<{
    property: string;
    order: string;
  }>();

  constructor(private renderer: Renderer2, private targetElem: ElementRef) {}

  @HostListener('click')
  sortData() {
    const elem = this.targetElem.nativeElement;
    const order = elem.getAttribute('data-order');
    const property = elem.getAttribute('data-name');

    const newOrder = order === 'desc' ? 'asc' : 'desc';
    elem.setAttribute('data-order', newOrder);
    this.sortChanged.emit({ property, order: newOrder });
  }
}
