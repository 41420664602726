import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  STATUSMAPPER,
  ValidationSummaryState,
} from './validation-summary-model';

export const selectValidationSummaryState =
  createFeatureSelector<ValidationSummaryState>('validationSummary');

export const selectValidationSummaryIsComplete = createSelector(
  selectValidationSummaryState,
  (validationSummary: ValidationSummaryState) =>
    validationSummary.validationSummaryData?.isComplete || false
);

export const selectValidationSummaryData = createSelector(
  selectValidationSummaryState,
  (validationSummary: ValidationSummaryState) => {
    return validationSummary.validationSummaryData;
  }
);

export const selectAllFeedbackValid = createSelector(
  selectValidationSummaryState,
  (validationSummary: ValidationSummaryState) => {
    const errorWarningItems =
      validationSummary.validationSummaryData.data.filter(
        (item) =>
          item.status === STATUSMAPPER[1] || item.status === STATUSMAPPER[2]
      );

    const allFeedbackValid = errorWarningItems.every(
      (item) => item.feedback != null && item.feedback !== ''
    );

    return allFeedbackValid;
  }
);

export const selectCommonInputData = createSelector(
  selectValidationSummaryState,
  (validationSummary: ValidationSummaryState) => {
    return {
      companyNumber: validationSummary.validationSummaryData.companyNumber,
      year: validationSummary.validationSummaryData.year,
      groupCode: validationSummary.validationSummaryData.groupCode,
    };
  }
);

export const selectIsDummyReport = createSelector(
  selectValidationSummaryState,
  (validationSummary: ValidationSummaryState) => {
    return validationSummary.validationSummaryData.isDummyReport;
  }
);

export const selectAllStatusSuccess = createSelector(
  selectValidationSummaryState,
  (validationSummary: ValidationSummaryState) => {
    const allStatusZero = validationSummary.validationSummaryData.data.every(
      (item) => item.status === STATUSMAPPER[0]
    );

    return allStatusZero;
  }
);

export const selectShowNumberOfCompanyValidationMessage = createSelector(
  selectValidationSummaryState,
  (validationSummary: ValidationSummaryState) => {
    return validationSummary.validationSummaryData.numberOfCompanyValidationMessage !== null;
  }
);

export const selectNumberOfCompanyValidationMessage = createSelector(
  selectValidationSummaryState,
  (validationSummary: ValidationSummaryState) => {
    return validationSummary.validationSummaryData.numberOfCompanyValidationMessage;
  }
);
