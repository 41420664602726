import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { IgxExcelExporterService } from '@infragistics/igniteui-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Color } from 'igniteui-angular-core';
import {
  CellFill,
  HorizontalCellAlignment,
  IWorkbookFont,
  OrderedSortCondition,
  SortDirection,
  VerticalCellAlignment,
  Workbook,
  WorkbookColorInfo,
  WorkbookFormat,
} from 'igniteui-angular-excel';
import { AppService } from 'src/app/app.service';
import { CompanyService } from 'src/app/company/company.service';
import { ConfirmDialogService } from 'src/app/confirm-dialog/confirm-dialog.service';
import { ExcelUtility } from 'src/app/shared/ExcelUtility';
import { ToastService } from 'src/app/shared/toast.service';
import { AdminService } from '../../../admin.service';
import * as companyActions from '../../data-access/admin-company-list-actions';
import {
  ListTypeList,
  listTypeListData,
} from '../../data-access/admin-company-list-model';
import { Subject} from 'rxjs';
import { CreateGroupModalComponent } from 'src/app/admin/create-group-modal/create-group-modal.component';

@Component({
  selector: 'app-company-list-header',
  templateUrl: './company-list-header.component.html',
  styleUrls: ['./company-list-header.component.scss'],
})
export class CompanyListHeaderComponent implements OnInit {
  @Input() showCompanyLoader: boolean = false;
  @Input() showDeleteLoader: boolean = false;
  @Input() showUploadLoader: boolean = false;

  @Input() isReadOnlyAdmin: boolean = false;
  @Input() isAnyCompaniesSelected: boolean = false;
  @Input() isAllCompaniesSelected: boolean = false;
  @Input() yearsLoaded: boolean = false;
  @Input() showPageError: boolean = false;
  @Input() pageError: string = '';

  @Input() selectedFilterStatusIndex: string;
  @Input() selectedFilterStatus: string = null;

  @Input() currentYear: number = null;
  @Input() yearList: [] = [];
  @Input() filterListTypes: [] = [];
  @Input() selectedListType: string = listTypeListData[0].value;
  @Input() filterStatusList: any[] = [];
  @Input() companyList: [] = [];
  @Input() searchValue: string = '';
  @Input() selectedYear: number = null;
  @Input() uploadingCompaniesCount: number = 0;
  @Input() filterStatusSelectedLabel: string = null;
  @Input() currentPage: number;
  @Output() pageChange = new EventEmitter<number>();

  private destroy$ = new Subject<void>();

  searchstring: string = '';

  public columns: any[];

  constructor(
    private store: Store,
    private appService: AppService,
    private excelExportService: IgxExcelExporterService,
    public toastService: ToastService,
    private companyService: CompanyService,
    private adminservice: AdminService,
    private confirmDialogService: ConfirmDialogService,
    private router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    if (this.isReadOnlyAdmin) {
      // this.store.dispatch(
      //   companyActions.setListType({ listTypeSelected: { value: 'all', label: 'All', option: 'all' } })
      // );
    }
  }

  setPage(page: number) {
    this.pageChange.emit(page);
  }

  getCompanyForYear(year: any) {
    if (typeof year === 'object') {
      year = year.value;
    }
    this.currentYear = parseInt(year);
    localStorage.setItem('currentyearadmin', this.currentYear.toString());
    localStorage.setItem('year', this.currentYear.toString());
    this.companyService.setYear();

    this.store.dispatch(
      companyActions.setCurrentYear({ currentYear: year })
    );
    const searchValue = (<HTMLInputElement>document.getElementById('search')).value;
    this.store.dispatch(
      companyActions.setSearchValue({ searchValue: searchValue })
    );
    this.setPage(1);
  }

  getItemByValue(value: string): ListTypeList | undefined {
    return listTypeListData.find((item) => item.value === value);
  }

  getcompanies(item: any) {
    const listTypeItem = this.getItemByValue(item.value);
    this.store.dispatch(
      companyActions.setListType({ listTypeSelected: listTypeItem })
    );
    this.setPage(1);
  }

  getcompaniesByStatus(item: any) {
    const statusType = item.value === 'Open' ? '' : item.value;
    this.store.dispatch(
      companyActions.setFilterStatusSelected({
        filterStatusSelected: statusType,
        filterStatusSelectedLabel: item.value,
        index: item.value
      })
    );
    this.setPage(1);
  }

  searchonclick() {
    const searchValue = (<HTMLInputElement>document.getElementById('search'))
      .value;
    this.store.dispatch(
      companyActions.setSearchValue({ searchValue: searchValue })
    );
    this.setPage(1);
  }

  onenter(e) {
    if (e.target.value.length == 0) {
      localStorage.setItem('searchvalue', '');
      this.store.dispatch(companyActions.setSearchValue({ searchValue: '' }));
    }
    if (e.key === 'Enter' || e.keyCode === 13) {
      const searchValue = e.target.value;
      this.store.dispatch(
        companyActions.setSearchValue({ searchValue: searchValue })
      );
      this.setPage(1);
    }
  }

  public exportExcel() {
    let workbook = new Workbook();
    let sheetname = 'Company List';
    let worksheet = workbook.worksheets().add(sheetname);
    worksheet.displayOptions.panesAreFrozen = true;
    worksheet.displayOptions.frozenPaneSettings.frozenRows = 1;
    let data = this.companyList;
    let table = worksheet.tables().add('A1:F' + data.length, true);
    table.columns(0).sortCondition = new OrderedSortCondition(
      SortDirection.Ascending
    );
    let font: IWorkbookFont;
    font = workbook.styles().normalStyle.styleFormat.font;
    font.name = 'Arial Unicode MS';
    this.WriteColumnHeadersToExcel(worksheet);
    let cols = [
      'status',
      'compno',
      'compname',
      'modifiedby',
      'submissiondate',
      'uploaddate',
    ];

    for (let row = 0; row < data.length; row++) {
      for (let col = 0; col < cols.length; col++) {
        let cell = worksheet.rows(row + 1).cells(col);
        cell.value = data[row][cols[col]];
      }
    }
    workbook.setCurrentFormat(WorkbookFormat.Excel2007);
    ExcelUtility.save(workbook, 'Company_List _' + this.currentYear);
  }

  private WriteColumnHeadersToExcel(worksheet) {
    this.columns = [
      'Status',
      'PRF Number',
      'Company Name',
      'Modified By',
      'Submission Date',
      'Upload Date',
    ];
    let white = new Color();
    white.colorString = '#FFFFFF';
    for (let col = 0; col < this.columns.length; col++) {
      let cell = worksheet.rows(0).cells(col);
      worksheet.columns(col).setWidth(150, 3);
      cell.value = this.columns[col];
      cell.cellFormat.fill = CellFill.createSolidFill('#6EA3CC');
      cell.cellFormat.alignment = HorizontalCellAlignment.Center;
      cell.cellFormat.verticalAlignment = VerticalCellAlignment.Center;
      cell.cellFormat.font.colorInfo = new WorkbookColorInfo(white);
      cell.cellFormat.font.bold = true;
    }
  }

  openGroup() {
    const modalRef = this.modalService.open(CreateGroupModalComponent);
    modalRef.componentInstance.title = 'Add Group';
    modalRef.componentInstance.prfyear = this.currentYear;

    modalRef.result.then((result) => {
      if (result != 'Close') {
        this.toastService.reset();
        this.toastService.show(result.msg, {
          classname: 'bg-success text-light',
          delay: 3000,
        });
      }
    });
  }

  uploadSelectedCompanies() {
    this.store.dispatch(companyActions.uploadSelectedCompanies());
    this.setPage(1);
  }
}
