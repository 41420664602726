import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpService } from 'src/app/shared/http.service';
import { environment } from 'src/environments/environment';
import { Companylisttable } from './admin-company-list-model';

@Injectable({
  providedIn: 'root',
})
export class AdminCompanyListService {
  constructor(private http: HttpClient, private httpService: HttpService) {}

  baseUrl = environment.apiUrl;

  public loadPrfYears(groupCode: string): Observable<any[]> {
    const data: any = {
      groupcode: groupCode,
    };

    return this.http
      .post<any[]>(`${this.baseUrl}getprfyears`, data)
      .pipe(catchError((error) => throwError(error)));
  }

  public loadCompaniesByYear(): Observable<Companylisttable[]> {
    const data: any = {
      jobId: 'jobId',
    };

    return this.http
      .post<Companylisttable[]>(`${this.baseUrl}getvalidationsummarylist`, data)
      .pipe(catchError((error) => throwError(error)));
  }

  public loadSubmittedCompanies(
    currentYear: number
  ): Observable<Companylisttable[]> {
    const data = { prfyear: currentYear };

    return this.http
      .post<Companylisttable[]>(`${this.baseUrl}getsubmittedcompanylist`, data)
      .pipe(catchError((error) => throwError(error)));
  }
  public loadAdminHomeCompanyList(
    currentYear: number,
    userId: any
  ): Observable<Companylisttable[]> {
    const data = { prfyear: currentYear, username: userId };

    return this.http
      .post<Companylisttable[]>(`${this.baseUrl}getadminhomecompanylist`, data)
      .pipe(catchError((error) => throwError(error)));
  }
  public loadSearchCompany(
    currentYear: number,
    searchType: string,
    searchValue: string
  ): Observable<Companylisttable[]> {
    const data = {
      prfyear: currentYear,
      searchtype: searchType,
      searchtext: searchValue,
    };

    return this.http
      .post<Companylisttable[]>(`${this.baseUrl}searchcompany`, data)
      .pipe(catchError((error) => throwError(error)));
  }

  public getLockStatus(
    currentYear: number,
    companyId: any,
    groupCode: any
  ): Observable<any[]> {
    const data = {
      prfyear: currentYear,
      groupcode: groupCode,
      companyno: companyId,
    };

    return this.http
      .post<any[]>(`${this.baseUrl}getlockstatus`, data)
      .pipe(catchError((error) => throwError(error)));
  }

  public deleteCompany(
    currentYear: number,
    groupCode: any,
    companyId: any,
    userId: string
  ): Observable<any[]> {
    const data = {
      groupcode: groupCode,
      prfyear: currentYear,
      recordlist: [{ companyno: companyId }],
      user: userId,
    };

    return this.http
      .post<any[]>(`${this.baseUrl}deleteform`, data)
      .pipe(catchError((error) => throwError(error)));
  }

  public uploadBulkCompanies(
    currentYear: number,
    userId: any,
    quarter: any,
    selectedCompanyList: any[]
  ): Observable<any[]> {
    const data = {
      prfyear: currentYear,
      quarter: quarter,
      prfuser: userId,
      ticket: this.httpService.getIsonetValue('ticket'),
      prfdata: selectedCompanyList,
    };

    return this.http
      .post<any[]>(`${this.baseUrl}batchuploadprf`, data)
      .pipe(catchError((error) => throwError(error)));
  }
}
