<div>
  <div class="PageLevelError" *ngIf="showPageError">{{ pageError }}</div>
  <table class="table table-hover">
    <thead>
      <tr>
        <th style="width: 30px" class="text-left" scope="col" *ngIf="!isReadOnlyAdmin">
          <input
            type="checkbox"
            [checked]="isAllCompaniesSelected"
            (change)="toggleAllCheckBox($event)"
          />
        </th>
        <th
          style="width: 100px"
          scope="col"
          [appSortEmitter]=""
          data-order="desc"
          data-name="status"
          (click)="onHeaderClick('status', $event.target.getAttribute('data-order'))"
          class="sorted"
        >
          Status<i class="table-material-icons">arrow_drop_down</i>
        </th>

        <th
          style="width: 120px"
          scope="col"
          [appSortEmitter]=""
          data-order="desc"
          data-name="compno"
          (click)="onHeaderClick('compno', $event.target.getAttribute('data-order'))"
          class="sorted"
        >
          PRF Number<i class="table-material-icons">arrow_drop_down</i>
        </th>
        <th
          style="width: 150px"
          scope="col"
          [appSortEmitter]=""
          data-order="desc"
          (click)="onHeaderClick('compname', $event.target.getAttribute('data-order'))"
          data-name="compname"
          class="sorted"
        >
          Company Name<i class="table-material-icons">arrow_drop_down</i>
        </th>
        <th scope="col" style="width: 100px">Modified By</th>
        <th scope="col">Submission Date</th>
        <th scope="col">Uploaded Date</th>
        <th scope="col" style="width: 100px">Comments</th>

        <th *ngIf="!isReadOnlyAdmin" scope="col"></th>
        <th *ngIf="!isReadOnlyAdmin" scope="col"></th>
        <th *ngIf="!isReadOnlyAdmin" scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let company of companyList; let row = index">
        <td *ngIf="!isReadOnlyAdmin">
          <input
            type="checkbox"
            [value]="company.compno"
            [(ngModel)]="company.isSelected"
            (change)="toggleSelect(company.compno)"
            [disabled]="company.selectDisabled"
          />
        </td>

        <td
          *ngIf="!isReadOnlyAdmin"
          style="cursor: pointer"
          (click)="
            uploadOrunlock(
              company.prfyear,
              company.groupcode,
              company.compno,
              company.status,
              company.compname,
              company.submissiondate,
              company.uploadmessage
            )
          "
        >
          <container-element [ngSwitch]="company.status">
            <div
              class="locked"
              *ngSwitchCase="'Submitted'"
              ngbTooltip="Upload/Unlock Company"
              placement="bottom"
              tooltipClass="tooltip-class"
            >
              <span class="table-material-icons">lock</span> Submitted
            </div>
            <div
              class="verified"
              *ngSwitchCase="'Uploaded'"
              ngbTooltip="Upload/Unlock Company"
              placement="bottom"
              tooltipClass="tooltip-class"
            >
              <span class="table-material-icons">verified_user</span> Uploaded
            </div>
            <div
            class="verifying"
            *ngSwitchCase="'Uploading'"
            ngbTooltip="Uploading Company"
            placement="bottom"
            tooltipClass="tooltip-class"
          >
          <span class="table-material-icons">
            update
            </span> Uploading
          </div>
          <div
          class="validation"
          *ngSwitchCase="'Validating'"
          ngbTooltip="In Validation"
          placement="bottom"
          tooltipClass="tooltip-class"
        >
        <span class="table-material-icons">
          sync_problem
          </span>
          Validating
        </div>

            <div
              *ngSwitchDefault
              ngbTooltip="Upload/Unlock Company"
              placement="bottom"
              tooltipClass="tooltip-class"
            >
              -
            </div>
          </container-element>
        </td>
        <td *ngIf="isReadOnlyAdmin">
          <container-element [ngSwitch]="company.status">
            <div
            class="locked"
            *ngSwitchCase="'Submitted'"
            ngbTooltip="Upload/Unlock Company"
            placement="bottom"
            tooltipClass="tooltip-class"
          >
            <span class="table-material-icons">lock</span> Submitted
          </div>
          <div
            class="verified"
            *ngSwitchCase="'Uploaded'"
            ngbTooltip="Upload/Unlock Company"
            placement="bottom"
            tooltipClass="tooltip-class"
          >
            <span class="table-material-icons">verified_user</span> Uploaded
          </div>
          <div
          class="verifying"
          *ngSwitchCase="'Uploading'"
          ngbTooltip="Uploading Company"
          placement="bottom"
          tooltipClass="tooltip-class"
        >
        <span class="table-material-icons">
          update
          </span> Uploading
        </div>
        <div
        class="validation"
        *ngSwitchCase="'Validating'"
        ngbTooltip="In Validation"
        placement="bottom"
        tooltipClass="tooltip-class"
      >
      <span class="table-material-icons">
        sync_problem
        </span>
        Validating
      </div>
            <div *ngSwitchDefault>-</div>
          </container-element>
        </td>
        <td>
          <div
            (click)="
              openCompanyInformation(
                company.groupcode,
                company.compno,
                company.prfyear,
                company.compno,
                company.compname
              )
            "
            style="cursor: pointer"
            class="interactive-links"
          >
            {{ company.compno.toUpperCase() }}
          </div>
        </td>
        <td>{{ company.compname }}</td>
        <td>{{ company.modifiedby }}</td>
        <td>
          <div
            *ngIf="
              company.submissiondate == '' || company.submissiondate == null;
              else submitteddatedefault
            "
          >
            -
          </div>
          <ng-template #submitteddatedefault>
            {{ company.submissiondate.split(" ")[0] | date : "MM.dd.yyyy" }}
          </ng-template>
        </td>
        <td>
          <div
            *ngIf="
              company.uploaddate == '' || company.uploaddate == null;
              else uploaddatedefault
            "
          >
            -
          </div>
          <ng-template #uploaddatedefault>
            {{ company.uploaddate.split(" ")[0] | date : "MM.dd.yyyy" }}
          </ng-template>
        </td>

        <td>
          <div *ngIf="company.comment == ''; else commentdefault">-</div>
          <ng-template #commentdefault>
            <div (click)="openComment(company.comment)" style="cursor: pointer" class="interactive-links">
              View Comment
            </div>
          </ng-template>
        </td>

        <td *ngIf="!isReadOnlyAdmin">
          <div *ngIf="company.validationjobid === ''; else validationdefault">-
        </div>
          <ng-template #validationdefault>
            <div class="edit"
            (click)="navigateToValidationSummary(
            company.groupcode,
            company.compno,
            company.prfyear,
            company.compno,
            company.compname)"
            ngbTooltip="Validation Summary"
            placement="bottom"
            tooltipClass="tooltip-class">
            <a class="vui-links"
            ><span class="table-material-icons">
              sync_problem
              </span>
          </a>
        </div>
          </ng-template>
        </td>
        <!-- <td *ngIf="!isReadOnlyAdmin">
          <div
            class="edit"
            *ngIf="
              company.status === 'Submitted' || company.submissiondate !== '';
              else editdefault
            "
            (click)="
              openCompanyInformation(
                company.groupcode,
                company.compno,
                company.prfyear,
                company.compno,
                company.compname
              )
            "
            ngbTooltip="Open Company"
            placement="bottom"
            tooltipClass="tooltip-class"
            style="cursor: pointer"
          >
            <span class="table-material-icons">edit</span>
          </div>
          <ng-template #editdefault>
            <div
              (click)="
                openCompanyInformation(
                  company.groupcode,
                  company.compno,
                  company.prfyear,
                  company.compno,
                  company.compname
                )
              "
              ngbTooltip="Edit Company"
              placement="bottom"
              tooltipClass="tooltip-class"
              style="cursor: pointer"
            >
              <span class="table-material-icons">edit</span>
            </div>
          </ng-template>
        </td> -->
        <td *ngIf="!isReadOnlyAdmin">
          <div class="edit" style="cursor: pointer">
            <span
              (click)="
                copyCompany(company.groupcode, company.compno, company.compname, company.naic, company.amBest, company.groupreporting)
              "
              ngbTooltip="Copy Company"
              placement="bottom"
              tooltipClass="tooltip-class"
              class="table-material-icons"
              >content_copy</span
            >
          </div>
        </td>
        <td style="text-align: center" *ngIf="!isReadOnlyAdmin">
          <div
            *ngIf="
              company.status === 'Submitted' || company.status === 'Uploaded';
              else deletedefault
            "
            class="delete_disabled"
          >
            <span class="table-material-icons">delete</span>
          </div>

          <ng-template #deletedefault>
            <div class="delete">
              <span
                class="table-material-icons"
                ngbTooltip="Delete Company"
                placement="bottom"
                tooltipClass="tooltip-class"
                (click)="deleteCompany(company.compno, company.groupcode)"
                >delete</span
              >
            </div>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
</div>
