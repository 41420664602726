import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-customer-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss'],
})
export class CustomerCompanyListComponent {
  @Input() companyList: [] = [];
  @Input() isReadOnlyAdmin: boolean;
  @Input() searchValue: string;
  @Input() isAdmin: boolean;
  @Input() currentPage: number;
  @Input() itemsPerPage: number;
  @Input() showError: boolean;
  @Input() pageErrorMessage: string;
  @Output() onOpenCompanyInformation = new EventEmitter();
  @Output() onCommentClick = new EventEmitter();
  @Output() onValidationSummaryClick = new EventEmitter();
  @Output() onDeleteButtonClick = new EventEmitter();
  displayField: string = '';

  openCompanyInformation(compno, compname, groupcode, prfStatus) {
    const data = {
      compNo: compno,
      compName: compname,
      groupCode: groupcode,
      prfStatus: prfStatus
    };
    this.onOpenCompanyInformation.emit(data);
  }
  openComment(comment: string) {
    this.onCommentClick.emit(comment);
  }
  navigateToValidationSummary(compno, compname, groupcode) {
    const data = {
      compNo: compno,
      compName: compname,
      groupCode: groupcode,
    };
    this.onValidationSummaryClick.emit(data);
  }
  deleteCompany(compno, groupcode) {
    const data = {
      compNo: compno,
      groupCode: groupcode,
    };
    this.onDeleteButtonClick.emit(data);
  }
}
