export interface BaseItem<T> {
  id: string;
  name: string;
  selected?: boolean;
}

export interface RowState<T> extends BaseItem<T> {}

export interface PremiumColumn<T> extends BaseItem<T> {}

export interface ConfigResponse<T> {
  data: {
    states: {
      State: T[];
    };
    cols: {
      Col: T[];
    };
  };
}

export const nonConfigurableStateCodes = ['T', 'ST', 'SP', 'UD'];
export const nonConfigurablePremiumColumnNames = [
  'State/Territory',
  'Code State',
  'State Code',
  'Total By State'
];

export interface GridConfiguration<T> {
  states: Record<string, T>;
  columns: Record<string, T>;
}

export interface ConfigurationNode<T> {
  direct: GridConfiguration<T>;
  supplemental: GridConfiguration<T>;
}

export interface GridConfigResponse<T> {
  data: {
    companyno: string;
    prfyear: string;
    adminconfiguration: ConfigurationNode<T>;
    currentconfiguration: ConfigurationNode<T>;
    defaultconfiguration: ConfigurationNode<T>;
  };
}

export interface GridConfig<T, U> {
  direct?: {
    states: RowState<T>[];
    columns: PremiumColumn<U>[];
  };
  supplemental?: {
    states: RowState<U>[];
    columns: PremiumColumn<T>[];
  };
}

export interface GridConfigState<T = any, U = any> {
  gridConfigVisible: boolean;
  gridConfigData: GridConfig<T, U>;
  initialData: GridConfig<T, U>;
}
