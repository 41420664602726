import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import 'tinymce/icons/default';
import { CompanyService } from 'src/app/company/company.service';
import { AppService } from '../../app.service'
import { Router } from '@angular/router';
import { AdminService } from 'src/app/admin/admin.service';
import { ConfirmDialogService } from 'src/app/confirm-dialog/confirm-dialog.service';


@Component({
  selector: 'app-emailconfiguration',
  templateUrl: './emailconfiguration.component.html',
  styleUrls: ['./emailconfiguration.component.scss']
})
export class EmailconfigurationComponent implements OnInit {
  public emailconfigForm!: UntypedFormGroup;
  initialnotificationhtml = '';
  regularreminderhtml ='';
  isdiableinitial: boolean = true;
  isReadOnlyAdmin: boolean;
  currentYear: number;
  contentavailable: boolean = true
  spinnerloadcontent: boolean = true;
  spinnersavecontent: boolean ;
  showPageError:boolean = false;
  pageError = ""

  standardremindercontent="";
  customremindercontent="";
  initialremindercontent="";
  constructor(private appService: AppService, private companyService: CompanyService, private router: Router,private adminService: AdminService,private confirmDialogService: ConfirmDialogService,) { }

  ngOnInit(): void {
    // this.currentYear = this.companyService.currenyprfyear;
    this.currentYear=parseInt(localStorage.getItem("currentprfyear"));
    this.isReadOnlyAdmin = this.appService.isReadonlyAdmin;
    this.spinnerloadcontent = true
    this.companyService.getmailcontent(this.currentYear, 'reminderstandard').subscribe((resp) => {
      this.spinnerloadcontent = false;
      if (resp["message"] == "Success") {
        this.standardremindercontent = resp['emailcontent']['stdsubcontents']
        this.regularreminderhtml = this.standardremindercontent
        this.customremindercontent = resp['emailcontent']['custsubcontent']
        this.initialremindercontent = resp['emailcontent']['initialmailcontent']
        this.initialnotificationhtml = this.initialremindercontent
        this.emailconfigForm = new UntypedFormGroup({
          initialnotificationcontents: new UntypedFormControl(this.initialnotificationhtml, [Validators.maxLength(20000)]),
          regularremindercontents: new UntypedFormControl(this.regularreminderhtml, [Validators.maxLength(20000)]),
          selectemail: new UntypedFormControl('reminderstandard')
        });
      }
    },
      (error) => {
        // //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    );

    this.emailconfigForm = new UntypedFormGroup({
      initialnotificationcontents: new UntypedFormControl(this.initialnotificationhtml, [Validators.maxLength(20000)]),
      regularremindercontents: new UntypedFormControl(this.regularreminderhtml, [Validators.maxLength(20000)]),
      selectemail: new UntypedFormControl('')
    });

  }

  trackeditorunsaveddata() {

    // console.log(this.emailconfigForm.controls.initialnotificationcontents.value);
    // console.log(this.emailconfigForm.controls.regularremindercontents.value);
  }
  hasUnsavedData(){

    
    if(this.isReadOnlyAdmin == true){
      return false
    }

    if(this.emailconfigForm.value.selectemail=="reminderstandard" && this.standardremindercontent!=this.emailconfigForm.value.regularremindercontents){

      return true
    }
    else if(this.emailconfigForm.value.selectemail=="remindercustom" && this.customremindercontent!=this.emailconfigForm.value.regularremindercontents){
      return true
    }
    if(this.initialremindercontent !=this.emailconfigForm.value.initialnotificationcontents){
      return true
    }
  }
  loghtmltoconsole() {
    //alert(this.emailconfigForm.value.initialnotificationcontents);
    // console.log(this.emailconfigForm.value.initialnotificationcontents);
    // console.log(this.emailconfigForm.value.regularremindercontents);
  }
  handleEmailTypeChangestd(e){
    if(this.emailconfigForm.value.regularremindercontents){
    if(this.customremindercontent !=this.emailconfigForm.value.regularremindercontents &&
      this.isReadOnlyAdmin != true) {
      this.confirmDialogService.confirmThis_withClose('Email Configuration', 'Do you want to save changes?', () => {
      //this.savemailcontent("remindercustom",this.emailconfigForm.value.regularremindercontents,
      //this.emailconfigForm.value.initialnotificationcontents)
      this.customremindercontent = this.emailconfigForm.value.regularremindercontents
        this.companyService.savemailcontent(this.currentYear, "remindercustom",this.emailconfigForm.value.regularremindercontents,
        this.emailconfigForm.value.initialnotificationcontents).subscribe((resp) => {
          if (resp["message"] == "Success") {
            this.initialremindercontent = this.emailconfigForm.value.initialnotificationcontents
            this.switchcheckbox()
          }});

      }, () => {

       this.switchcheckbox()
      }, () => {
        //Close button
        return;
      });
    }
    else {
      this.switchcheckbox()
    }
  }
  else{
    this.switchcheckbox()
  }

  }
  handleEmailTypeChangecust(e){
    if(this.emailconfigForm.value.regularremindercontents){
    if (this.standardremindercontent !=this.emailconfigForm.value.regularremindercontents &&
      this.isReadOnlyAdmin != true){
      this.confirmDialogService.confirmThis_withClose('Email Configuration', 'Do you want to save changes?', () => {
        this.companyService.savemailcontent(this.currentYear, "reminderstandard",this.emailconfigForm.value.regularremindercontents,
        this.emailconfigForm.value.initialnotificationcontents).subscribe((resp) => {
          this.spinnersavecontent = false
          if (resp["message"] == "Success") {
          //this.savemailcontent("reminderstandard",this.emailconfigForm.value.regularremindercontents,
          //this.emailconfigForm.value.initialnotificationcontents)
          this.standardremindercontent = this.emailconfigForm.value.regularremindercontents
          this.initialremindercontent = this.emailconfigForm.value.initialnotificationcontents

          this.switchcheckbox()
          this.showPageError = false;
          this.pageError =""
      }});
      }, () => {

        this.switchcheckbox()
      }, () => {
        //Close button
        return;
      });
    }
    else {
      this.switchcheckbox()

    }
  }
  else{
    this.switchcheckbox()
  }
  }
  switchcheckbox(){
    this.spinnerloadcontent = true;

    this.companyService.getmailcontent(this.currentYear, this.emailconfigForm.value.selectemail).subscribe((resp) => {
      this.spinnerloadcontent = false;
      if (resp["message"] == "Success") {
        //this.substandardhtml = resp["emailcontent"]['stdsubcontents']
        //this.subcustomhtml = resp["emailcontent"]['customsubconten']
        this.showPageError = false;
      this.pageError ="";
        if(this.emailconfigForm.value.selectemail=="reminderstandard"){
          //this.standardremindercontent = this.substandardhtml
          //this.regularreminderhtml = this.substandardhtml
          this.regularreminderhtml = resp["emailcontent"]['stdsubcontents']
        }
        else{
          //this.customremindercontent = this.subcustomhtml
          //this.regularreminderhtml = this.subcustomhtml
          this.regularreminderhtml = resp["emailcontent"]['custsubcontent']
        }
        //this.initialnotificationhtml = resp["emailcontent"]['initialmailcontent']
        this.initialnotificationhtml = this.emailconfigForm.value.initialnotificationcontents
        this.emailconfigForm = new UntypedFormGroup({
          initialnotificationcontents: new UntypedFormControl(this.initialnotificationhtml, [Validators.maxLength(20000)]),
          regularremindercontents: new UntypedFormControl(this.regularreminderhtml, [Validators.maxLength(20000)]),
          selectemail: new UntypedFormControl(this.emailconfigForm.value.selectemail)
        });
      }
    },
      (error) => {
        // //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    );
  }
  savemailcontent(emailtype,subcontent,initcontent){

    //alert(this.emailconfigForm.value.initialnotificationcontents);
    //alert(this.emailconfigForm.value.regularremindercontents);
    if(emailtype=="reminderstandard"){
      this.standardremindercontent = subcontent
    }
    else{
      this.customremindercontent = subcontent
    }
    this.initialremindercontent = initcontent
    //if(!this.emailconfigForm.value.regularremindercontents || !this.emailconfigForm.value.initialnotificationcontents){
    //  //this.contentavailable = false
    //  this.showPageError = true
    //  this.pageError = "Please add email content to save"
    //  return
    //}
    this.spinnersavecontent = true
    this.companyService.savemailcontent(this.currentYear, emailtype, subcontent, initcontent).subscribe((resp) => {
      this.spinnersavecontent = false
      if (resp["message"] == "Success") {
        this.showPageError = true
        this.pageError = resp["emailcontent"]
        //this.emailconfigForm = new FormGroup({
        //  initialnotificationcontents: new FormControl(this.initialnotificationhtml, [Validators.maxLength(20000)]),
        //  regularremindercontents: new FormControl(this.regularreminderhtml, [Validators.maxLength(20000)]),
        //});
      }
    },
      (error) => {
        // //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    );
  }

}
