import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CompanyService } from '../company.service';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { ToastService } from '../../shared/toast.service'
import { ResponsivePhaseDescriptionMetadata } from 'igniteui-angular-core';
import { UnsavedmsgModalComponent } from '../../unsavedmsg-modal/unsavedmsg-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../../app.service'
import { Router } from '@angular/router';
import { ConfirmDialogService } from '../../confirm-dialog/confirm-dialog.service';
import { AdminService } from '../../admin/admin.service';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import { Userdefinedservice, userdefinedParams } from '../../shared/userdefinedservice';
import { AdobelaunchService } from '../../_services/adobelaunch.service';
import { combineLatest } from 'rxjs';
import { isLockForValidationSelector, isPrfLocked } from '../premiumreport/data-access/premium-report.selectors';
import { Store } from '@ngrx/store';
@Component({
  selector: 'app-annualstatement',
  templateUrl: './annualstatement.component.html',
  styleUrls: ['./annualstatement.component.scss']
})
export class AnnualstatementComponent implements OnInit, AfterViewInit {

  compno: any;
  currentYear: number;
  prfNumber: string;
  prfName: string;
  annualStatement: any[] = [];
  isolines: any[] = [];



  public direct_premium: any[];
  public supplemental_premium: any[];
  public userdefined_premium: any[];
  public udcolumns: any[];
  public userdefined_premium_supplemental: any[];
  public columns: any[];
  public rows: any[];
  public const_statecode_column = 2;
  public const_fixed_columns = 3;
  public userdefined_row_code = "UD";
  public supplemental_row_code = "SP";
  public AssumedFromNonAffiliates_row_code = "AN";
  userdefinedparams: userdefinedParams;


  selectedisoline: number;
  oldselectedisoline: number;
  anualstatementsForm: UntypedFormGroup;
  isolinesoaded: Promise<boolean>;
  anualstatementoaded: Promise<boolean>;
  unsavedData: boolean = false;
  show: boolean = true;
  spinnersave: boolean;
  hideornot: boolean = false;
  counter: number = 0;
  isComplete: boolean = false;
  isLocked: boolean;
  isReadOnly: boolean;
  isReadOnlyAdmin: boolean;
  isoline: any
  selectedLine: string
  pageError: boolean
  groupcode: any
  isolinerequired: boolean = false;
  saveResponseMsg: string = "";
  isolinerequiredfordirect: boolean = false;
  constructor(
    private adminService: AdminService,
    private router: Router,
    private appService: AppService,
    public toastService: ToastService,
    private companyService: CompanyService,
    public builder: UntypedFormBuilder,
    private modalService: NgbModal,
    private confirmDialogService: ConfirmDialogService,
    private userdefinedservice: Userdefinedservice,
    private AdobelaunchService:AdobelaunchService,
    private store: Store,
  ) {
    this.anualstatementsForm = this.builder.group({});

    // this.rowValidateForm(1);
  }
  ngAfterViewInit() {
    this.AdobelaunchService.EventEndDispatch();
    }
  rowValidateForm(i: number, scenario?: string) {

    this.anualstatementsForm.addControl('Text1_' + i, new UntypedFormControl(this.annualStatement[i].Text1));
    this.anualstatementsForm.addControl('Text_' + i, new UntypedFormControl(this.annualStatement[i].Text));
    this.anualstatementsForm.addControl('Col1_' + i, new UntypedFormControl(this.annualStatement[i].Col1, Validators.pattern("/^[0-9]*\.?[0-9]*$/g")));
    this.anualstatementsForm.addControl('Col2_' + i, new UntypedFormControl(this.annualStatement[i].Col2, Validators.pattern("/^[0-9]*\.?[0-9]*$/g")));
  }

  ngOnInit(): void {

    this.removePrevAlerts();
    this.companyService.annualstatementCompleted.subscribe(result => {
      this.isComplete = result;
    });


    this.companyService.getgroup().subscribe((value) => {
      this.groupcode = value;
    });
    this.compno = this.companyService.companyId;
    this.currentYear = this.companyService.currentYear;
    this.prfNumber = this.companyService.prfNumber;
    this.prfName = this.companyService.prfName;
    this.isLocked = this.companyService.isEditLocked;
    this.isReadOnlyAdmin = this.appService.isReadonlyAdmin;
    this.isReadOnly = this.isLocked || this.isReadOnlyAdmin;
    this.getAnualStatement();
    this.getPrgress();
    this.getIsoLines();

    combineLatest([
      this.store.select(isLockForValidationSelector),
      this.store.select(isPrfLocked),
    ]).subscribe(([isValidationLocked, prfLocked]) => {
      console.log(
        'updated',
        isValidationLocked,
        localStorage.getItem('isLocked') == 'true'
      );

      this.isLocked = prfLocked || isValidationLocked;
      this.isReadOnly = this.isLocked || this.appService.isReadonlyAdmin;
      console.log(this.isLocked);
    });
  }

  getPrgress() {

    this.companyService.getCustomerPrfProgress(this.currentYear, this.compno, this.groupcode).subscribe((resp) => {
      if (resp["message"] == "Success") {
        if (!this.isComplete)
          this.companyService.groupreportingInProgress.next(true)
        this.companyService.disablePrf.next(false)
      }
    }
      ,
      (error) => {
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    )


  }
  getAnualStatement() {
    this.companyService.getAnualStatement(this.currentYear, this.compno).subscribe((resp) => {
      if (resp["message"] == "Success") {
        this.annualStatement = resp["mappingdetails"]
        for (var i = 0; i < this.annualStatement.length; i++) {
          if (this.annualStatement[i].Col1 == '0') {
            this.annualStatement[i].Col1 = '';
          }
          if (this.annualStatement[i].Col2 == '0') {
            this.annualStatement[i].Col2 = '';
          }
          this.rowValidateForm(i);
        }

        if (resp["mappingdetails"][this.annualStatement.length - 1]['Col1'] !== '') {
          var tot1 = parseFloat(resp["mappingdetails"][this.annualStatement.length - 1]['Col1']);
          this.annualStatement[this.annualStatement.length - 1].Col1 = tot1
        }
        if (resp["mappingdetails"][this.annualStatement.length - 1]['Col2'] !== '') {
          var tot2 = parseFloat(resp["mappingdetails"][this.annualStatement.length - 1]['Col2']);
          this.annualStatement[this.annualStatement.length - 1].Col2 = tot2
        }

        this.show = false;
        this.anualstatementoaded = Promise.resolve(true)
      }
    },
      (error) => {
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    )
  }
  getIsoLines() {
    this.companyService.getIsoLines(this.currentYear, this.compno).subscribe((resp) => {

      if (resp["message"] == "Success") {

        this.isolines = resp["isolineslist"]["isolines"];
        this.selectedisoline = resp["isolineslist"]["selindex"];

        this.oldselectedisoline = this.selectedisoline;

        if (this.selectedisoline == 0) {
          this.isolines.unshift({ Number: 0, Value: 'Select' });
        }
        this.isoline = this.isolines.filter(i => i.Number == this.selectedisoline)[0].Value

        this.selectedLine = this.isolines[0].Value;

      }
      this.isolinesoaded = Promise.resolve(true);
    },
      (error) => {
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }

    )
  }
  calculateDirect(e) {
    var input = e.target.value;
    if (input) {
      if (parseFloat(input) == 0 || /[^-?.0-9]+/g.test(input)) {
        e.target.value = '';
      }
      // else {
      //   e.target.value = parseFloat(input).toFixed();
      // }

    }

    var total = 0;
    for (var i = 0; i < this.annualStatement.length - 1; i++) {
      var value = this.annualStatement[i].Col1;
      if (value == '-' || value == '.' || value == '' || value == 0 || /[^-?.0-9]+/g.test(value)) {
        this.annualStatement[i].Col1 = '';
        if (this.annualStatement[i].Text1 === '34.') {
          this.isolinerequiredfordirect = false;
          this.pageError = false;
        }
      }
      else if (this.annualStatement[i].Col1 !== '' && this.annualStatement[i].Col1 !== null) {
        var prem = 0
        prem = parseInt(parseFloat(this.annualStatement[i].Col1).toFixed());
        this.annualStatement[i].Col1 = prem
        total = total + prem
        // var prem = 0
        // prem = parseFloat(this.annualStatement[i].Col1);
        // this.annualStatement[i].Col1 = parseInt(prem.toFixed());
        // total = total + parseInt(prem.toFixed()) //parseFloat(this.annualStatement[i].Col1)
        if (this.annualStatement[i].Text1 === '34.') {
          this.isolinerequiredfordirect = true;
          this.pageError = false;
        }
      }
    }
    var roundTotal = total
    if (roundTotal == -0) {
      roundTotal = 0;
    }
    if ((this.annualStatement[this.annualStatement.length - 1].Col1 !== roundTotal) && !this.isReadOnly)
      this.unsavedData = true;
    this.annualStatement[this.annualStatement.length - 1].Col1 = roundTotal;
  }
  calculateNonDirect(e) {
    var input = e.target.value;
    if (input) {
      if (parseFloat(input) == 0 || /[^-?.0-9]+/g.test(input)) {
        e.target.value = '';
      }
      // else {
      //   e.target.value = parseFloat(input).toFixed();
      // }

    }
    var total = 0;
    for (var i = 0; i < this.annualStatement.length - 1; i++) {
      var value = this.annualStatement[i].Col2;
      if (value == '-' || value == '.' || value == '' || value == 0 || /[^-?.0-9]+/g.test(value)) {
        this.annualStatement[i].Col2 = '';
        if (this.annualStatement[i].Text1 === '34.') {
          this.isolinerequired = false;
          this.pageError = false;
        }
      }
      else if (this.annualStatement[i].Col2 !== '' && this.annualStatement[i].Col2 !== null) {
        var prem = 0
        prem = parseInt(parseFloat(this.annualStatement[i].Col2).toFixed());
        this.annualStatement[i].Col2 = prem
        total = total + prem //parseFloat(this.annualStatement[i].Col2)
        if (this.annualStatement[i].Text1 === '34.') {
          this.isolinerequired = true;
          this.pageError = false;
        }
      }
    }
    var roundTotal = total
    if (roundTotal == -0) {
      roundTotal = 0;
    }
    if ((this.annualStatement[this.annualStatement.length - 1].Col2 !== roundTotal) && !this.isReadOnly)
      this.unsavedData = true;
    this.annualStatement[this.annualStatement.length - 1].Col2 = roundTotal;

  }

  numberOnly(e) {

    if (!this.isReadOnly)
      this.unsavedData = true;
  }
  hasUnsavedData() {
    return this.unsavedData;
  }
  validateDecimalInput(event): boolean {
    if (!this.isReadOnly)
      this.unsavedData = true;
    const charCode = (event.which) ? event.which : event.keyCode;
    var value = event.target.value;

    var cursorPosition = event.target.selectionStart;
    if (charCode == 46 && value.includes('.') || (charCode == 45 && cursorPosition != 0) || (charCode == 45 && value.includes('-') && cursorPosition == 0)) {
      return false;
    }
    // if (event.target.value.length == 1&& value.includes('0') && charCode == 48 ||  (value.includes('-') && charCode == 48 && (cursorPosition == 0 || cursorPosition == 1 ))) {
    //   return false;
    // }
    if ((charCode >= 48 && charCode <= 57) || (charCode > 44 && charCode < 47)) {
      if (value.includes('-') && cursorPosition === 0) {
        return false;
      }
      return true;
    }
    return false;
  }
  validateNumericInput(event): boolean {
    if (!this.isReadOnly)
      this.unsavedData = true;
    const charCode = (event.which) ? event.which : event.keyCode;
    var value = event.target.value;
    var cursorPosition = event.target.selectionStart;
    if ((charCode == 45 && cursorPosition != 0) || (charCode == 45 && value.includes('-') && cursorPosition == 0)) {
      return false;
    }
    if ((charCode >= 48 && charCode <= 57) || (charCode == 45)) {
      if (value.includes('-') && cursorPosition === 0) {
        return false;
      }
      return true;
    }
    return false;
  }
  onsubmit() {
    if (this.selectedLine == "Select" && (this.isolinerequired || this.isolinerequiredfordirect)) {
      this.pageError = true;
    } else {
      let isolinenumber = this.selectedisoline == 0 ? '' : this.selectedisoline
      this.adminService.disablesidemenu.next(true);
      this.adminService.disablecommonmenu.next(true);
      this.companyService.disableCompanyList.next(true)
      this.companyService.clearStatus();
      this.toastService.remove(this.toastService.toasts[0]);
      this.spinnersave = true;
      this.annualStatement = Object.assign(this.annualStatement, this.anualstatementsForm.value);
      this.companyService.saveAnualStatement(this.currentYear, this.compno, this.annualStatement, isolinenumber, this.groupcode).subscribe((resp) => {
        if (resp["message"] == "Success") {
          this.saveResponseMsg = resp["status_msg"];
          this.companyService.getPremiumReportData(this.compno, this.currentYear, this.groupcode).subscribe((resp) => {
            if (resp["message"] == "Success") {
              var result = resp['data'];
              this.direct_premium = resp['data']['data']['direct_premium'];
              this.supplemental_premium = resp['data']['data']['supplemental_premium'];
              this.userdefined_premium = resp['data']['userdefined_premium']['dpremium'];
              this.userdefined_premium_supplemental = resp['data']['userdefined_premium']['spremium'];
              this.columns = result["columns"];
              this.udcolumns = resp['data']['userdefined_columns'];
              this.rows = result["rows"];
              this.userdefinedparams = new userdefinedParams(this.columns, this.rows, this.udcolumns,
                this.const_statecode_column, this.userdefined_row_code, this.supplemental_row_code, this.const_fixed_columns, this.AssumedFromNonAffiliates_row_code);
              this.userdefinedservice.params = this.userdefinedparams;

              var updatedpremium = this.userdefinedservice.UpdateUDFormulaCells(this.direct_premium, this.supplemental_premium,
                this.userdefined_premium, this.userdefined_premium_supplemental);
              this.direct_premium = updatedpremium[0];
              this.supplemental_premium = updatedpremium[1];
              this.userdefined_premium = updatedpremium[2];
              this.userdefined_premium_supplemental = updatedpremium[3];

              this.UpdatePremiumdetails();
            }
          },
            (error) => {
              //console.log(error);
              this.router.navigate(['/apifailure']);
            }
          );
        }
      },
        (error) => {
          //console.log(error);
          this.router.navigate(['/apifailure']);
        }
      )
    }
  }







  public UpdatePremiumdetails(showmanagecols = false) {
    var griddata = {
      "premreportlobs": this.columns,
      "premreportstates": this.rows,
      "dpremiuminfo": this.direct_premium,
      "spremiuminfo": this.supplemental_premium,
      "udpremiuminfo": this.userdefined_premium,
      "uspremiuminfo": this.userdefined_premium_supplemental,
      "udlobs": this.udcolumns
    };
    this.adminService.disablesidemenu.next(true);
    this.adminService.disablecommonmenu.next(true);
    this.companyService.disableCompanyList.next(true)
    this.companyService.clearStatus();
    this.companyService.updatepremiumdetails(this.currentYear, this.compno, griddata, this.groupcode, true).subscribe((resp) => {
      if (resp["message"] == "Success") {
        this.hideornot = false;
        this.toastService.show(this.saveResponseMsg, { classname: 'bg-success text-light', delay: 3000 });
        this.unsavedData = false;
        this.getPrgress();
        this.adminService.disablesidemenu.next(false);
        this.adminService.disablecommonmenu.next(false);
        this.companyService.disableCompanyList.next(false);
        this.spinnersave = false;
      }
    },
      (error) => {
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    )
  }

  getIsoLine(isoline: any) {
    if (typeof isoline === 'object' && isoline.value != 0) {
      isoline = isoline.value;
      this.selectedisoline = isoline;
      this.unsavedData = true;
      this.pageError = false;
      this.selectedLine = isoline;
    }
    else {
      this.selectedLine = "Select";
    }
  }

  clear() {
    this.companyService.getAnualStatement(this.currentYear, this.compno).subscribe((resp) => {

      if (resp["message"] == "Success") {
        this.annualStatement = resp["mappingdetails"]
        for (var i = 0; i < this.annualStatement.length; i++) {
          this.rowValidateForm(i);
        }
        this.unsavedData = false;
      }

    },
      (error) => {
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    )

    this.selectedisoline = this.oldselectedisoline;
  }

  openPageChangeModal() {
    const modalRef = this.modalService.open(UnsavedmsgModalComponent);
    modalRef.componentInstance.name = 'New';

    modalRef.result.then((result) => {
      if (result) {
        this.onsubmit();
      }
    });
  }

  removePrevAlerts(){
    for (var i = this.toastService.toasts.length-1; i >= 0; i--) {
      this.toastService.remove(this.toastService.toasts[i]);
    }
  }
}
