import { Component, Input,  Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { IgxExcelExporterService } from '@infragistics/igniteui-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppService } from 'src/app/app.service';
import { CommentsModalComponent } from 'src/app/company/comments-modal/comments-modal.component';
import { CompanyService } from 'src/app/company/company.service';
import { ConfirmDialogService } from 'src/app/confirm-dialog/confirm-dialog.service';
import { CopycompanyComponent } from 'src/app/copycompany/copycompany.component';
import { ToastService } from 'src/app/shared/toast.service';
import { UnloackuploadcompanyComponent } from 'src/app/unloackuploadcompany/unloackuploadcompany.component';
import { AdminService } from '../../../admin.service';
import * as companyActions from '../../data-access/admin-company-list-actions';
import { ListTypeList } from '../../data-access/admin-company-list-model';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss'],
})
export class CompanyListComponent {
  @Input() showCompanyLoader: boolean = false;
  @Input() showDeleteLoader: boolean = false;
  @Input() isReadOnlyAdmin: boolean = false;
  @Input() isAnyCompaniesSelected: boolean = false;
  @Input() isAllCompaniesSelected: boolean = false;
  @Input() yearsLoaded: boolean = false;
  @Input() showPageError: boolean = false;
  @Input() pageError: string = '';

  @Input() currentYear: number = null;
  @Input() yearList: [] = [];
  @Input() filterListTypes: ListTypeList[] = [];
  @Input() selectedListType: string = '';
  @Input() filterStatusList: [] = [];
  @Input() companyList: [] = [];
  @Input() showNoDataMessage: boolean = false;
  @Output() sortChanged = new EventEmitter<{ property: string, order: string }>();

  searchstring: string = '';

  public columns: any[];

  constructor(
    private store: Store,
    private appService: AppService,
    private excelExportService: IgxExcelExporterService,
    public toastService: ToastService,
    private companyService: CompanyService,
    private adminservice: AdminService,
    private confirmDialogService: ConfirmDialogService,
    private router: Router,
    private modalService: NgbModal
  ) {}
  openCompanyInformation(
    groupcode: any,
    companyId: any,
    year: number,
    prfNumber: string,
    prfName: string
  ) {
    localStorage.setItem('isCreateComapny', 'false');
    if (companyId !== '') {
      this.store.dispatch(
        companyActions.openCompanyDetails({
          groupCode: groupcode,
          companyId: companyId,
          currentYear: year,
          prfNumber: prfNumber,
          prfName: prfName,
        })
      );
    }
  }

  deleteCompany(compno: any, groupcode: any): any {
    this.confirmDialogService.confirmThis(
      'Delete',
      'Do you want to delete the company : ' + compno.toUpperCase() + '?',
      () => {
        this.store.dispatch(
          companyActions.deleteCompany({
            groupCode: groupcode,
            companyId: compno,
          })
        );
      },
      () => {}
    );
  }

  onHeaderClick(property: string, order: string) {
    const newOrder = order === 'desc' ? 'asc' : 'desc';
    this.sortChanged.emit({ property, order: newOrder });
  }

  openComment(comment: string) {
    const modalRef = this.modalService.open(CommentsModalComponent);
    modalRef.componentInstance.comment = comment;
    modalRef.componentInstance.isSubmissionComment = false;
  }

  copyCompany(group: any, companyno: any, companyname: any, naic: any, amBest: any, groupReporting: boolean) {
    const modalRef = this.modalService.open(CopycompanyComponent);
    modalRef.componentInstance.title = 'Copy Company';
    modalRef.componentInstance.oldGroup = group;
    modalRef.componentInstance.oldCompanyno = companyno;
    modalRef.componentInstance.prfYear = this.currentYear;
    modalRef.componentInstance.oldName = companyname;
    modalRef.componentInstance.naic = naic;
    modalRef.componentInstance.amBest = amBest;
    modalRef.componentInstance.groupReporting = groupReporting;

    modalRef.result.then((result) => {
      if (result) {
        this.store.dispatch(companyActions.copyCompanySuccess());
        this.toastService.show(
          'Company ' +
            result.compno +
            ' is copied under group ' +
            result.groupcode,
          { classname: 'bg-success text-light', delay: 3000 }
        );
      }
    });
  }

  uploadOrunlock(
    prfYear: any,
    groupcode: any,
    companyno: any,
    status: any,
    companyname: any,
    submissiondt: any,
    uploadMessage: string
  ) {
    const modalRef = this.modalService.open(UnloackuploadcompanyComponent);
    modalRef.componentInstance.title = 'Upload/Unlock';
    modalRef.componentInstance.prfYear = prfYear;
    modalRef.componentInstance.groupcode = groupcode;
    modalRef.componentInstance.companyno = companyno;
    modalRef.componentInstance.prfuser = this.companyService.userid;
    modalRef.componentInstance.status = status;
    modalRef.componentInstance.companyname = companyname;
    modalRef.componentInstance.submissiondt = submissiondt;
    modalRef.componentInstance.previousUploadMessage = uploadMessage;
    modalRef.result.then((result) => {
      if (result) {
        if (result == 'Upload success') {
          this.store.dispatch(companyActions.unlockUploadCompanySuccess());
        } else if (result == 'Error') {
        } else {
          this.toastService.reset();
          this.store.dispatch(companyActions.unlockUploadCompanySuccess());
          this.toastService.show('Company Unlocked Successfully.', {
            classname: 'bg-success text-light',
            delay: 3000,
          });
        }
      }
    });
  }
  navigateToValidationSummary(
    groupcode: any,
    companyId: any,
    year: any,
    prfNumber: string,
    prfName: string
  ) {
    this.companyService.navigateToValidationSummary(
      groupcode,
      companyId,
      year,
      prfNumber,
      prfName
    );
  }
  toggleAllCheckBox(event: any) {
    this.store.dispatch(
      companyActions.toggleSelectAll({ checked: event.target.checked })
    );
  }
  toggleSelect(companyno: string) {
    this.store.dispatch(companyActions.toggleSelect({ companyId: companyno }));
  }
}
