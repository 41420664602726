import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'prf-select2',
  templateUrl: './prf-select.component.html',
  styleUrls: ['./prf-select.component.scss'],
})
export class PrfSelectComponent implements OnChanges {
  @Output() onSelect = new EventEmitter<any>();
  @Input() options;
  @Input() selectedIndex: number;
  optionsList = [];
  optionMap = {};

  ngOnChanges(changes: SimpleChanges): void {
    this.optionsList = this.options;
    for (let i in this.options) {
      this.optionMap[this.options[i].value] = this.options[i];
    }
    console.table(this.optionsList);
  }
  onSelectItem(event) {
    this.onSelect.emit(this.optionMap[event.value]);
  }
}
