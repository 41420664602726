import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Subject, combineLatest } from 'rxjs';
import { CompanyInfoModel } from './commpany.model';
import { HttpService } from '../shared/http.service';
import { tryParseBool } from 'igniteui-angular-core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, first, map, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import {
  loadPremiumInfo,
  setPrfLock,
  setValidationLock,
  updateLockStatus,
} from './premiumreport/data-access/premium-report.action';
import { Store } from '@ngrx/store';
import { loadAllConfigurationsAction } from './grid-configuration/data-access/grid-config.actions';
import { isLockForValidationSelector, isPrfLocked } from './premiumreport/data-access/premium-report.selectors';
import { CopyCompanyParams } from '../shared/models/copy.company.model';
import { navigateToValidationSummary } from './companylist/data-access/company-list-actions';
@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private group: BehaviorSubject<string>;
  private groupname: BehaviorSubject<string>;
  private custid: BehaviorSubject<string>;
  public groupcode: string;
  public userid: string;
  public companyId: any;
  public currentYear: number;
  public prfNumber: string;
  public prfName: string;
  public isEditLocked: boolean = false;
  public isLocked: boolean = false;
  public lockForValidation: boolean = false;
  public groupreportenabled: boolean;
  public grouponoff: string;
  public remindermailbody: string;
  public currenyprfyear: number;
  groupCode: string;

  companyInformationCompleted = new Subject<boolean>();
  companyInformationInProgress = new Subject<boolean>();

  premiumreportCompleted = new Subject<boolean>();
  premiumreportInProgress = new Subject<boolean>();

  groupreportingCompleted = new Subject<boolean>();
  groupreportingInProgress = new Subject<boolean>();

  annualstatementCompleted = new Subject<boolean>();
  annualstatementInProgress = new Subject<boolean>();

  userdefinedcolsCompleted = new Subject<boolean>();
  userdefinedcolsInProgress = new Subject<boolean>();

  userdefineddataCompleted = new Subject<boolean>();
  userdefineddataInProgress = new Subject<boolean>();

  commentsCompleted = new Subject<boolean>();
  commentsInProgress = new Subject<boolean>();

  PriorYearComparisonCompleted = new Subject<boolean>();

  disablePrf = new Subject<boolean>();
  disableCompanyList = new Subject<boolean>();
  constructor(
    private httpClient: HttpClient,
    private httpService: HttpService,
    private router: Router,
    private store: Store
  ) {
    this.group = new BehaviorSubject<string>('');
    this.groupname = new BehaviorSubject<string>('');
    this.custid = new BehaviorSubject<string>('');
    this.getLocalStorage();
    if (environment.enviroment_code == 'D') {
      this.userid = localStorage.getItem('userid');
    } else {
      this.userid = this.httpService.getIsonetValue('userid');
    }
    combineLatest([
      this.store.select(isLockForValidationSelector),
      this.store.select(isPrfLocked),
    ]).subscribe(([isValidationLocked, prfLocked]) => {
      console.log(
        'updated',
        isValidationLocked,
        localStorage.getItem('isLocked') == 'true'
      );

      this.isEditLocked = prfLocked || isValidationLocked;
      console.log(this.isEditLocked);
    });
    this.getgroup().subscribe((value) => (this.groupCode = value));
  }

  getgroup(): Observable<string> {
    return this.group.asObservable();
  }
  setgroup(newValue): void {
    this.group.next(newValue);
  }

  setCurrentYear(year:number){
    
    this.currentYear = year
    localStorage.setItem('year',this.currentYear+"");
  }
  getgroupname(): Observable<string> {
    return this.groupname.asObservable();
  }
  setgroupname(newValue): void {
    this.groupname.next(newValue);
  }

  loadPremiumData() {
    if (this.companyId == '') return;
    this.store.dispatch(
      loadPremiumInfo({
        compno: this.companyId,
        currentYear: this.currentYear,
        groupcode: this.groupCode,
      })
    );
    this.store.dispatch(loadAllConfigurationsAction());
    this.store.dispatch(updateLockStatus());
  }

  getcustid(): Observable<string> {
    return this.custid.asObservable();
  }
  setcustid(newValue): void {
    this.custid.next(newValue);
  }

  toTitleCase(str) {
    str = str.replace(',', ' ');
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  getLocalStorage() {
    this.companyId = localStorage.getItem('companyId');
    this.currentYear = parseInt(localStorage.getItem('year'));
    this.isLocked = localStorage.getItem('isLocked') == 'true';
    this.lockForValidation =
      localStorage.getItem('lockForValidation') == 'true';
    this.groupreportenabled =
      localStorage.getItem('groupreportenabled') == 'on';
    this.prfName = localStorage.getItem('prfName');
    this.prfNumber = localStorage.getItem('prfNumber');
    if (localStorage.getItem('groupcode') !== '')
      this.setgroup(localStorage.getItem('groupcode'));
    if (localStorage.getItem('groupname') !== '')
      this.setgroupname(localStorage.getItem('groupname'));
  }

  setYear() {
    this.currentYear = parseInt(localStorage.getItem('year'));
  }

  private years: number[] = [2020, 2019, 2018, 2017, 2016, 2015];
  private status: string;

  setCompanyInformation(status: string) {
    if (status == 'completed') {
      this.companyInformationCompleted.next(true);
      this.companyInformationInProgress.next(false);
    } else if (status == 'inprogress') {
      this.companyInformationInProgress.next(true);
      this.companyInformationCompleted.next(false);
    } else {
      this.companyInformationInProgress.next(false);
      this.companyInformationCompleted.next(false);
    }
  }

  setPremiumreportStatus(status: string) {
    if (status == 'completed') {
      this.premiumreportCompleted.next(true);
      this.premiumreportInProgress.next(false);
    } else if (status == 'inprogress') {
      this.premiumreportInProgress.next(true);
      this.premiumreportCompleted.next(false);
    } else {
      this.premiumreportInProgress.next(false);
      this.premiumreportCompleted.next(false);
    }
  }

  setGroupreportingStatus(status: string) {
    if (status == 'completed') {
      this.groupreportingCompleted.next(true);
      this.groupreportingInProgress.next(false);
    } else if (status == 'inprogress') {
      this.groupreportingInProgress.next(true);
      this.groupreportingCompleted.next(false);
    } else {
      this.groupreportingInProgress.next(false);
      this.groupreportingCompleted.next(false);
    }
  }

  setAnnualstatementStatus(status: string) {
    if (status == 'completed') {
      this.annualstatementCompleted.next(true);
      this.annualstatementInProgress.next(false);
    } else if (status == 'inprogress') {
      this.annualstatementInProgress.next(true);
      this.annualstatementCompleted.next(false);
    } else {
      this.annualstatementInProgress.next(false);
      this.annualstatementCompleted.next(false);
    }
  }

  setUserdefinedcolsStatus(status: string) {
    if (status == 'completed') {
      this.userdefinedcolsCompleted.next(true);
      this.userdefinedcolsInProgress.next(false);
    } else if (status == 'inprogress') {
      this.userdefinedcolsInProgress.next(true);
      this.userdefinedcolsCompleted.next(false);
    } else {
      this.userdefinedcolsInProgress.next(false);
      this.userdefinedcolsCompleted.next(false);
    }
  }

  setUserdefinedDataStatus(status: string) {
    if (status == 'completed') {
      this.userdefineddataCompleted.next(true);
      this.userdefineddataInProgress.next(false);
    } else if (status == 'inprogress') {
      this.userdefineddataInProgress.next(true);
      this.userdefineddataCompleted.next(false);
    } else {
      this.userdefineddataInProgress.next(false);
      this.userdefineddataCompleted.next(false);
    }
  }

  setCommentsStatus(status: string) {
    if (status == 'completed') {
      this.commentsCompleted.next(true);
      this.commentsInProgress.next(false);
    } else if (status == 'inprogress') {
      this.commentsInProgress.next(true);
      this.commentsCompleted.next(false);
    } else {
      this.commentsInProgress.next(false);
      this.commentsCompleted.next(false);
    }
  }

  setPriorYearComparisonStatus(status: string) {
    if (status == 'completed') {
      this.PriorYearComparisonCompleted.next(true);
    } else {
      this.PriorYearComparisonCompleted.next(false);
    }
  }
  clearStatus() {
    this.companyInformationInProgress.next(false);
    this.companyInformationCompleted.next(false);
    this.commentsInProgress.next(false);
    this.commentsCompleted.next(false);
    this.groupreportingInProgress.next(false);
    this.groupreportingCompleted.next(false);
    this.annualstatementCompleted.next(false);
    this.annualstatementInProgress.next(false);
    this.userdefineddataCompleted.next(false);
    this.userdefineddataInProgress.next(false);
    this.premiumreportCompleted.next(false);
    this.premiumreportInProgress.next(false);
    this.disablePrf.next(true);
    this.PriorYearComparisonCompleted.next(false);
  }
  getCompanyList(year: number, groupcode: any) {
    const data = { group: groupcode, prfyear: year };
    return this.httpService.functionPost(`getcompanylist`, data);
  }

  deleteCompany(year: number, compno: string, groupcode: any, comment: any) {
    const data = {
      groupcode: groupcode,
      prfyear: year,
      comment: comment,
      recordlist: [{ companyno: compno }],
      user: this.userid,
    };
    return this.httpService.functionPost(`deleteform`, data);
  }

  getPrfYears(groupcode: any) {
    const data: any = groupcode ? { groupcode: groupcode } : {};
    return this.httpService.functionPost(`getprfyears`, data);
  }

  loadCompanyInformation(year: number, compno: string, groupcode) {
    const data = { groupcode: groupcode, prfyear: year, companyno: compno };
    return this.httpService.functionPost(`getcompanyinfo`, data);
  }

  saveCompanyInformation(
    year: number,
    mode: string,
    companyInfo: CompanyInfoModel,
    groupcode: any,
    unConfirmedData: any
  ) {
    const data = {
      groupcode: groupcode,
      prfyear: year,
      compinfo: companyInfo,
      mode: mode,
      prfuser: this.userid,
      unConfirmedData: unConfirmedData,
      ticket: this.httpService.getIsonetValue('ticket'),
    };
    return this.httpService.functionPost(`managecompanyinfo`, data);
  }

  getIsoLines(year: Number, companyno: any) {
    const data = { prfyear: year, companyno: companyno };
    return this.httpService.functionPost(`getisolines`, data);
  }

  getPremiumReportData(companyno, year, groupcode) {
    const data = { companyno: companyno, year: year, groupcode: groupcode };
    return this.httpService.functionPost(`getconvertedjson`, data);
  }

  getTemplateFile(filename) {
    const data = { filename: filename };
    return this.httpService.functionPost(`gettemplatefile`, data);
  }

  getUDEFTemplateFile(filename, mode, year, groupcode, compno) {
    const data = {
      filename: filename,
      mode: mode,
      year: year,
      groupcode: groupcode,
      compno: compno,
    };
    return this.httpService.functionPost(`getudeftemplatefile`, data);
  }

  getAnualStatement(year: number, compno: string) {
    const data = { prfyear: year, companyno: compno };
    return this.httpService.functionPost(`getasmapping`, data);
  }

  saveAnualStatement(
    year: number,
    compno: string,
    asdata: any,
    isolineselection: any,
    groupcode: any
  ) {
    const data = {
      prfyear: year,
      companyno: compno,
      groupno: groupcode,
      asdata: asdata,
      isolineselection: isolineselection,
      prfuser: this.userid,
      ticket: this.httpService.getIsonetValue('ticket'),
    };
    return this.httpService.functionPost(`saveannualstatement`, data);
  }

  getUserdefinedColumns(year: number, compno: string) {
    const data = { prfyear: year, companyid: compno };
    return this.httpService.functionPost(`getuserdefinedlobdetails`, data);
  }

  getMatchiIsoColumns(year: number, compno: string) {
    const data = { prfyear: year, companyno: compno };
    return this.httpService.functionPost(`getmatchingisocolumns`, data);
  }

  addComment(year: number, compno: string, comment: string) {
    const data = {
      prfyear: year,
      companyno: compno,
      comment: comment,
      user: this.userid,
      ticket: this.httpService.getIsonetValue('ticket'),
    };
    return this.httpService.functionPost(`addprfcomment`, data);
  }

  getComments(year: number, compno: string, groupcode: string) {
    const data = { prfyear: year, compno: compno, groupcode: groupcode };
    return this.httpService.functionPost(`getprfcomment`, data);
  }

  saveUserDefinedCols(year: number, compno: string, coldetailslist: any) {
    const data = {
      prfyear: year,
      companyno: compno,
      coldetailslist: coldetailslist,
      user: this.userid,
    };
    return this.httpService.functionPost(`saveuserdefinedcoldetails`, data);
  }

  updatepremiumdetails(
    year: number,
    companyno: string,
    griddata: any,
    groupcode: string,
    isuserdefined: boolean,
    isUpload:boolean=false
  ) {
    var user = this.userid;
    const data = {
      prfyear: year,
      companyno: companyno,
      griddata: griddata,
      groupcode: groupcode,
      user: user,
      isuserdefined: isuserdefined,
      isUpload:isUpload,
      ticket: this.httpService.getIsonetValue('ticket'),
    };
    return this.httpService.functionPost(`updatepremiumdetails`, data);
  }

  loadGroupReport(year: number, compno: string, group: any) {
    const data = { prfyear: year, companyno: compno, group: group };
    return this.httpService.functionPost(`getgroupcompanydetails`, data);
  }

  SaveGroupReport(
    year: number,
    compno: string,
    groupcompanylist: any,
    groupcode: any,
    mode: any
  ) {
    const data = {
      prfyear: year,
      groupcode: groupcode,
      companyno: compno,
      groupcompanylist: groupcompanylist,
      user: this.userid,
      mode: mode,
      ticket: this.httpService.getIsonetValue('ticket'),
    };
    return this.httpService.functionPost(`savegroupcompanydetails`, data);
  }

  getprfstates() {
    var year = this.currentYear;
    const data = { prfyear: year };
    return this.httpService.functionPost(`getprfstates`, data);
  }

  getCustomerPrfProgress(year: number, companyno: any, groupcode: any) {
    const data = { prfyear: year, groupcode: groupcode, companyno: companyno };
    return this.httpService.functionPost(`getprfprogressstatus`, data).pipe(
      map((resp) => {
        if (resp['message'] == 'Success' && this.isPRFSelected()) {
          this.setCompanyInformation(resp['statusdetails'].companyinfo);
          this.setAnnualstatementStatus(resp['statusdetails'].annualstatement);
          this.setPremiumreportStatus(resp['statusdetails'].premiumreport);
          this.setGroupreportingStatus(resp['statusdetails'].groupreporting);
          this.setUserdefinedcolsStatus(resp['statusdetails'].userdefinedcols);
          this.setUserdefinedDataStatus(resp['statusdetails'].userdefineddata);
          this.setCommentsStatus(resp['statusdetails'].reviewcomments);
          this.setPriorYearComparisonStatus(resp['statusdetails'].companyinfo);
          this.disablePrf.next(false)
        } else {
          this.disablePrf.next(true);
          this.clearStatus();
        }
        return resp;
      })
    );
  }
  getPrfStatus() {
    this.getCustomerPrfProgress(
      this.currentYear,
      this.companyId,
      this.group.getValue()
    ).subscribe({
      next: (resp) => {
        if (resp['message'] == 'Success') {
          this.setCompanyInformation(resp['statusdetails'].companyinfo);
          this.setAnnualstatementStatus(resp['statusdetails'].annualstatement);
          this.setPremiumreportStatus(resp['statusdetails'].premiumreport);
          this.setGroupreportingStatus(resp['statusdetails'].groupreporting);
          this.setUserdefinedcolsStatus(resp['statusdetails'].userdefinedcols);
          this.setUserdefinedDataStatus(resp['statusdetails'].userdefineddata);
          this.setCommentsStatus(resp['statusdetails'].reviewcomments);
          this.setPriorYearComparisonStatus(resp['statusdetails'].companyinfo);
          this.premiumreportCompleted
            .asObservable()
            .pipe(
              take(1),
              map((x) => !x)
            )
            .subscribe((x) => {
              this.premiumreportInProgress.next(true);
              this.groupreportingInProgress.next(true);
              this.disablePrf.next(false);
            });
        }
      },
      error: (error) => {
        this.router.navigate(['/apifailure']);
      }
    });
  }
  getadminhomecompanylist(year: number) {
    const data = { prfyear: year, username: this.userid };
    return this.httpService.functionPost(`getadminhomecompanylist`, data);
  }

  isPRFSelected() {
    return !this.router.url.endsWith('/dboard');
  }

  searchCompany(year: number, searchtype: any, searchtext: any) {
    const data = {
      prfyear: year,
      searchtype: searchtype,
      searchtext: searchtext,
    };
    return this.httpService.functionPost(`searchcompany`, data);
  }

  getLockStatus(year: number, companyno: any, groupcode: any) {
    const data = { prfyear: year, groupcode: groupcode, companyno: companyno };
    return this.httpService.functionPost(`getlockstatus`, data).pipe(
      tap((x) => {
        this.store.dispatch(
          setValidationLock({ value: x['result']['lock_for_validation'] })
        );
        this.store.dispatch(
          setPrfLock({ value: x['result']['status'] == 'Locked' })
        );
      })
    );
  }

  getsubmittedcompanylist(year: number) {
    const data = { prfyear: year };
    return this.httpService.functionPost(`getsubmittedcompanylist`, data);
  }

  copycompany(params: CopyCompanyParams) {
    const data = {
      prfyear: params.prfYear,
      oldgroupcode: params.oldGroup,
      oldcompno: params.oldCompanyno,
      newgroupcode: params.newGroup,
      newcompno: params.newNumber,
      newcompname: params.newName,
      naic: params.naic,
      amBest: params.amBest,
      user: this.userid,
      ticket: this.httpService.getIsonetValue('ticket'),
    };
    return this.httpService.functionPost(`copycompany`, data);
  }

  unlockcompany(year: number, groupcode: any, companyno: any) {
    const data = {
      prfyear: year,
      groupcode: groupcode,
      companyno: companyno,
      prfuser: this.userid,
      ticket: this.httpService.getIsonetValue('ticket'),
    };
    return this.httpService.functionPost(`unlockcompany`, data);
  }

  getSubmissionView(year: number, groupcode: any, companyno: any) {
    const data = { prfyear: year, groupcode: groupcode, companyno: companyno };
    return this.httpService.functionPost(`finalsubmissionview`, data);
  }

  saveFinalSubmission(
    year: number,
    groupcode: any,
    companyno: any,
    isdummy?: boolean
  ) {
    var data: any = {
      prfyear: year,
      groupcode: groupcode,
      companyno: companyno,
      prfuser: this.userid,
    };
    if (isdummy !== undefined) {
      data = { ...data, dummyrpt: isdummy };
    }
    return this.httpService.functionPost(`submitform`, data);
  }
  getGridVerificationStatus(year: number, groupcode: string, compno: string) {
    const data = { groupcode: groupcode, prfyear: year, companyno: compno };
    return this.httpService.functionPost(`getgridverificationstatus`, data);
  }

  validategroup(prfyear: number, groupcode: string) {
    const data = { groupcode: groupcode, prfyear: prfyear };
    return this.httpService.functionPost(`validategroup`, data);
  }

  saveGroupRecord(prfyear, dataobj) {
    const data = {
      prfyear: prfyear,
      groupcode: dataobj[0]['Number'],
      groupname: dataobj[0]['Name'],
      user: this.userid,
    };
    return this.httpService.functionPost(`createnewgroup`, data);
  }

  getGroupname(prfyear, groupcode) {
    const data = { prfyear: prfyear, groupno: groupcode };
    return this.httpService.functionPost(`getgroupname`, data);
  }

  renamecompany(
    year: number,
    groupcode: string,
    oldcompno: any,
    newcompno: any,
    oldcompname: any,
    newcompname: any,
    renamecomment: any
  ) {
    const data = {
      prfyear: year,
      groupcode: groupcode,
      oldcompno: oldcompno,
      newcompno: newcompno,
      oldcompname: oldcompname,
      newcompname: newcompname,
      renamecomment: renamecomment,
      prfuser: this.userid,
      ticket: this.httpService.getIsonetValue('ticket'),
    };
    return this.httpService.functionPost(`renamecompany`, data);
  }

  saveUserDefinedCol(
    year: number,
    group: any,
    compno: string,
    coldetails: any
  ) {
    const data = {
      prfyear: year,
      group: group,
      companyno: compno,
      coldetails: coldetails,
      user: this.userid,
      ticket: this.httpService.getIsonetValue('ticket'),
    };
    return this.httpService.functionPost(`addudefcolumn`, data);
  }

  updateUserDefinedCol(
    year: number,
    group: any,
    compno: string,
    coldetails: any
  ) {
    const data = {
      prfyear: year,
      group: group,
      companyno: compno,
      coldetails: coldetails,
      user: this.userid,
      ticket: this.httpService.getIsonetValue('ticket'),
    };
    return this.httpService.functionPost(`updateudefcolumndetails`, data);
  }

  deleteUserDefinedCol(
    year: number,
    group: any,
    compno: string,
    coldetails: any
  ) {
    const data = {
      prfyear: year,
      group: group,
      companyno: compno,
      coldetails: coldetails,
      user: this.userid,
      ticket: this.httpService.getIsonetValue('ticket'),
    };
    return this.httpService.functionPost(`removeudefcolumn`, data);
  }

  uploadpremium(
    year: number,
    quarter: number,
    corpId: any,
    groupcode: any,
    companyno: any
  ) {
    var ticket = this.httpService.getIsonetValue('ticket');
    const data = {
      prfyear: year,
      quarter: quarter,
      corpId: corpId,
      groupcode: groupcode,
      companyno: companyno,
      prfuser: this.userid,
      ticket: ticket,
    };
    return this.httpService.functionPost(`uploadpremium`, data);
  }

  getusergroup(custid: any) {
    const data = { custid: custid };
    return this.httpService.functionPost(`getusergroup`, data);
  }
  updateuploadstatus(year: number, companyno: any, groupcode: any) {
    const data = {
      prfyear: year,
      companyno: companyno,
      groupcode: groupcode,
    };
    return this.httpService.functionPost(`updateuploadstatus`, data);
  }

  unsubmittedcomplist(year: number, searchcriteria: string) {
    const data = {
      prfyear: year,
      searchcriteria: searchcriteria,
    };
    return this.httpService.functionPost(`unsubmittedcompanylist`, data);
  }
  sendremindermail(
    year: number,
    companylist: any,
    emailcontenttype: any,
    remtype: any
  ) {
    const data = {
      prfyear: year,
      companylist: companylist,
      emailcontenttype: emailcontenttype,
      prfuser: this.userid,
      ticket: this.httpService.getIsonetValue('ticket'),
      remtype: remtype,
    };
    return this.httpService.functionPost(`sendemailreminder`, data);
  }
  getcompreminderstatus(year: number, companylist: any) {
    const data = {
      prfyear: year,
      companylist: companylist,
    };
    return this.httpService.functionPost(`getcompanyemailremstatus`, data);
  }

  getmailcontent(year: number, emailtype: any) {
    const data = {
      prfyear: year,
      emailtype: emailtype,
    };
    return this.httpService.functionPost(`getemailcontent`, data);
  }

  savemailcontent(
    year: number,
    emailtype: any,
    submailcontent: any,
    initmailcontent: any
  ) {
    const data = {
      prfyear: year,
      emailtype: emailtype,
      subemailcontent: submailcontent,
      initemailcontent: initmailcontent,
    };
    return this.httpService.functionPost(`saveemailcontent`, data);
  }

  prioryearcopy(
    year: number,
    companylist: any,
    groupcode: any,
    duplicatecheck: boolean,
    override: boolean,
    duplicatecompanylist: any
  ) {
    const data = {
      prfyear: year,
      companylist: companylist,
      prfuser: this.userid,
      groupcode: groupcode,
      duplicatecheck: duplicatecheck,
      override: override,
      duplicatecompanylist: duplicatecompanylist,
      ticket: this.httpService.getIsonetValue('ticket'),
    };
    return this.httpService.functionPost(`prioryearcopy`, data);
  }
  
  navigateToValidationSummary(
    groupcode: any,
    companyId: any,
    year: any,
    prfNumber: string,
    prfName: string
  ) {
    this.store.dispatch(
      navigateToValidationSummary({
        groupCode: groupcode,
        companyId: companyId,
        currentYear: year,
        prfNumber: prfNumber,
        prfName: prfName,
      })
    );
  }
  updateValidationEmailCounter(jobid,compno){
    const data = {
      jobid: jobid,
      compno: compno
    };
    return this.httpService.functionPost(`updatevalidationsummaryemailstatus`, data);
  }
  loadAnnualStatementComparison(year: number, groupcode: string, compno: string) {
    let ticket = this.httpService.getIsonetValue('ticket')
    const data = { 'prfyear': year, 'groupcode': groupcode, 'companyno': compno, 'prfuser': this.userid, 'ticket': ticket };
    return this.httpService.functionPost(`getascomparisonreport`, data);
  }

  loadPriorYearComparisonDetails(year: number, groupcode: string, compno: string,compareto:string) {
    let ticket = this.httpService.getIsonetValue('ticket')
    const data = { 'prfyear': year, 'groupcode': groupcode, 'companyno': compno,'compareto':compareto, 'prfuser': this.userid, 'ticket': ticket };
    return this.httpService.functionPost(`getprioryearpremcomparisondetails`, data);
  }

  sendMail(receivers, cc, bcc, subject, message) {
    const data = {
      'receivers': receivers, 'bcc': bcc,
      'cc': cc, 'subject': subject, 'message': message
    };
    return this.httpService.functionPost(`sendemail`, data);
  }
  getEmailPreviewDetailsForValidationSummary(
    year: number,
    groupcode: string,
    compno: string,
    jobId: any
  ) {
    let ticket = this.httpService.getIsonetValue('ticket');
    const data = {
      prfyear: year,
      groupcode: groupcode,
      companyno: compno,
      prfuser: this.userid,
      ticket: ticket,
      jobId: jobId
    };
    return this.httpService.functionPost(`getvalidationsummaryemailinfo`, data);
  }
  }
