import { AppService } from 'src/app/app.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { CompanyService } from 'src/app/company/company.service';
import { HttpService } from 'src/app/shared/http.service';
import * as companyActions from './admin-company-list-actions';
import {
  selectCurrentYear,
  selectFilterStatusSelected,
  selectListTypeSelectedValue,
  selectQuarter,
  selectSearchType,
  selectSearchValue,
  selectSelectedCompanies,
} from './admin-company-list-selectors';
import { AdminCompanyListService } from './admin-company-list-service';

@Injectable()
export class AdminCompanyListEffects {
  loadPrfYears$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyActions.loadPrfYears),
      switchMap((action) =>
        this.adminCompanyListService.loadPrfYears(action.groupCode).pipe(
          switchMap((data) => [
            companyActions.loadPrfYearsSuccess({
              yearsList: data['prfyearlist'],
            }),
          ]),
          catchError((error) =>
            of(companyActions.loadPrfYearsFailure({ error }))
          )
        )
      )
    )
  );

  loadPrfYearsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        companyActions.loadPrfYearsSuccess,
        companyActions.setFilterStatusSelected,
        companyActions.setListType,
        companyActions.loadCompaniesByYear,
        companyActions.setSearchValue,
        companyActions.deleteCompanySuccess,
        companyActions.unlockUploadCompanySuccess,
        companyActions.copyCompanySuccess,
        companyActions.loadLatestCompanyList,
        companyActions.loadCompanySilently,
      ),
      withLatestFrom(
        this.store.select(selectCurrentYear),
        this.store.select(selectSearchType),
        this.store.select(selectSearchValue),
        this.store.select(selectListTypeSelectedValue),
        this.store.select(selectFilterStatusSelected)
      ),
      switchMap(
        ([
          action,
          currentYear,
          searchType,
          searchValue,
          listTypeSelected,
          filterStatusSelected,
        ]) => {
          localStorage.setItem('currentprfyear', currentYear.toString());
          localStorage.setItem('year', currentYear.toString());
          this.companyService.setYear();
          this.companyService.getLocalStorage();
          return this.handleSearchValue(
            currentYear,
            searchType,
            searchValue,
            listTypeSelected,
            filterStatusSelected
          );
        }
      )
    )
  );

  private handleSearchValue(
    currentYear: number,
    searchType: string,
    searchValue: string,
    listTypeSelected: string,
    filterStatusSelected: string
  ): Observable<Action> {
    if (searchValue && searchValue.trim() !== '') {
      return this.handleSearchType(
        currentYear,
        searchType,
        searchValue,
        filterStatusSelected
      );
    }

    return this.handleEmptySearchValue(listTypeSelected, currentYear);
  }

  private handleSearchType(
    currentYear: number,
    searchType: string,
    searchValue: string,
    filterStatusSelected: string
  ): Observable<Action> {
    if (searchType && searchType.trim() !== '') {
      return this.adminCompanyListService
        .loadSearchCompany(currentYear, searchType, searchValue)
        .pipe(
          map((data) =>
            companyActions.loadSearchCompanySuccess({
              companyList: data['companylist'],
              filterStatusSelected,
            })
          ),
          catchError((error) =>
            of(companyActions.loadSearchCompanyFailure({ error }))
          )
        );
    }

    return of(companyActions.setSearchTypeFailure());
  }

  private handleEmptySearchValue(
    listTypeSelected: string,
    currentYear: number
  ): Observable<Action> {
    if (listTypeSelected === 'all') {
      return of(companyActions.loadSubmittedCompanies({ currentYear }));
    }
    return of(companyActions.loadAdminHomeCompanyList());
  }

  loadSubmittedCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyActions.loadSubmittedCompanies),
      withLatestFrom(this.store.select(selectFilterStatusSelected)),
      switchMap(([action, filterStatusSelected]) =>
        this.adminCompanyListService
          .loadSubmittedCompanies(action.currentYear)
          .pipe(
            switchMap((data) => [
              companyActions.loadSubmittedCompaniesSuccess({
                companyList: data['companylist'],
                filterStatusSelected: filterStatusSelected,
              }),
            ]),
            catchError((error) =>
              of(companyActions.loadSubmittedCompaniesFailure({ error }))
            )
          )
      )
    )
  );

  loadAdminHomeCompanyList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyActions.loadAdminHomeCompanyList),
      withLatestFrom(
        this.store.select(selectCurrentYear),
        this.store.select(selectFilterStatusSelected)
      ),
      switchMap(([action, currentYear, filterStatusSelected]) =>
        this.adminCompanyListService
          .loadAdminHomeCompanyList(currentYear, this.companyService.userid)
          .pipe(
            switchMap((data) => [
              companyActions.loadAdminHomeCompanyListSuccess({
                companyList: data['companylist'],
                filterStatusSelected: filterStatusSelected,
              }),
            ]),
            catchError((error) =>
              of(companyActions.loadAdminHomeCompanyListFailure({ error }))
            )
          )
      )
    )
  );

  openCompanyDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(companyActions.openCompanyDetails),
        tap((action) => {
          const data = action; // Accessing the 'data' property from the action
          localStorage.setItem('companyId', data.companyId);
          localStorage.setItem('year', data.currentYear + "");
          localStorage.setItem('prfNumber', data.prfNumber);
          localStorage.setItem(
            'prfName',
            this.companyService.toTitleCase(data.prfName)
          );
          localStorage.setItem('groupcode', data.groupCode);
          this.companyService.getLocalStorage();
          this.companyService.loadPremiumData();
          if (!this.appService.isAdmin) {
            this.router.navigate(['/companyinformation']);
          }
          else {
            this.router.navigate(['/admin/companyinformation']);
          }
        })
      ),
    { dispatch: false }
  );

  openCompanyDetailsForCustomer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(companyActions.openCompanyDetailsForCustomer),
        tap((action) => {

          localStorage.setItem("companyId", action.companyId);
          localStorage.setItem("year", action.currentYear+"");
          localStorage.setItem("prfNumber", action.prfNumber);
          localStorage.setItem("prfName", this.companyService.toTitleCase(action.prfName));
          localStorage.setItem("groupcode", action.groupCode);
          this.companyService.getLocalStorage();
          this.companyService.loadPremiumData();
          this.router.navigate(['/companyinformation']);

        })
      ),
    { dispatch: false }
  );


  loadSearchCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyActions.loadSearchCompany),
      withLatestFrom(this.store.select(selectFilterStatusSelected)),
      switchMap(([action, filterStatusSelected]) =>
        this.adminCompanyListService
          .loadSearchCompany(
            action.currentYear,
            action.searchType,
            action.searchValue
          )
          .pipe(
            switchMap((data) => [
              companyActions.loadSearchCompanySuccess({
                companyList: data,
                filterStatusSelected: filterStatusSelected,
              }),
            ]),
            catchError((error) =>
              of(companyActions.loadSearchCompanyFailure({ error }))
            )
          )
      )
    )
  );

  deleteCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyActions.deleteCompany),
      withLatestFrom(this.store.select(selectCurrentYear)),
      switchMap(([action, currentYear]) =>
        this.adminCompanyListService
          .deleteCompany(
            currentYear,
            action.groupCode,
            action.companyId,
            this.companyService.userid
          )
          .pipe(
            switchMap((data) => [companyActions.deleteCompanySuccess()]),
            catchError((error) =>
              of(companyActions.deleteCompanyFailure({ error }))
            )
          )
      )
    )
  );

  uploadSelectedCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyActions.uploadSelectedCompanies),
      withLatestFrom(
        this.store.select(selectCurrentYear),
        this.store.select(selectQuarter),
        this.store.select(selectSelectedCompanies)
      ),
      switchMap(([action, currentYear, quarter, selectedCompanyList]) =>
        this.adminCompanyListService
          .uploadBulkCompanies(
            currentYear,
            this.companyService.userid,
            quarter,
            selectedCompanyList
          )
          .pipe(
            switchMap((data) => [
              companyActions.uploadSelectedCompaniesSuccess(),
            ]),
            catchError((error) =>
              of(companyActions.uploadSelectedCompaniesFailure({ error }))
            )
          )
      )
    )
  );

  failureRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          companyActions.loadPrfYearsFailure,
          companyActions.loadSubmittedCompaniesFailure,
          companyActions.loadAdminHomeCompanyListFailure,
          companyActions.getLockStatusFailure,
          companyActions.loadSearchCompanyFailure,
          companyActions.deleteCompanyFailure,
          companyActions.uploadSelectedCompaniesFailure
        ),
        tap(() => {
          this.router.navigate(['/apifailure']);
        })
      ),
    { dispatch: false }
  );

  navigateToValidationSummary$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(companyActions.navigateToValidationSummary),
        tap((action) => {
          const data = action;
          localStorage.setItem('companyId', data.companyId);
          localStorage.setItem('year', data.currentYear + "");
          localStorage.setItem('prfNumber', data.prfNumber);
          localStorage.setItem(
            'prfName',
            this.companyService.toTitleCase(data.prfName)
          );
          localStorage.setItem('groupcode', data.groupCode);
          this.companyService.getLocalStorage();
          this.companyService.loadPremiumData();
          if (!this.appService.isAdmin) {
            this.router.navigate(['/validationsummary']);
          }
          else {
            this.router.navigate(['/admin/validationsummary']);
          }
        })
      ),
    { dispatch: false }
  );
  constructor(
    private actions$: Actions,
    private store: Store,
    private adminCompanyListService: AdminCompanyListService,
    private companyService: CompanyService,
    private router: Router,
    private httpService: HttpService,
    private appService: AppService,
  ) {}
}
