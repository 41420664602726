import {  AfterViewInit,Component,OnInit} from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppService } from '../app.service'
import { HttpService } from '../shared/http.service';
import { AdobelaunchService } from '../_services/adobelaunch.service';
import { PlatformLocation } from '@angular/common';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit,AfterViewInit {

  // @Output() dashboardloaded = new EventEmitter<string>();
  hasAdminRole = false;


  constructor(private appService: AppService,private AdobelaunchService:AdobelaunchService,private httpService: HttpService) {
    // ,location: PlatformLocation
    // location.onPopState(() => {
    //   console.log('This Operation is not allowed');
    //   //this.router.navigateByUrl(‘/multicomponent’);
    //   history.forward();
    //   });
   }
  ngAfterViewInit() {
  this.AdobelaunchService.EventEndDispatch();
  }

  ngOnInit(): void {

    var Cookiecstid = this.httpService.getIsonetValue("cstid");
    var globalprfcustid=localStorage.getItem('prfcstid')
    if (typeof globalprfcustid!='undefined' && globalprfcustid) {
      if (+globalprfcustid == environment.MasterId || +globalprfcustid == parseInt(environment.ViewerId)) {
        this.hasAdminRole = true;
      }
      else {
        this.hasAdminRole = false;
      }
    }
    else{
      if(Cookiecstid){
        var url=environment.IsoAuthService + "/GetSessionProductGroupMembershipAndCustomerIds/";
        this.httpService.getcustid(url).subscribe((resp) => {
          var splitted = resp.toString().split("|");
          var custid=splitted.find(x => x.includes("PRF") )||"";
          if(custid!=""){
            var cstid =custid.toString().split(",")[0];
            localStorage.setItem("prfcstid", cstid);
            if (+cstid == environment.MasterId || +cstid == parseInt(environment.ViewerId)) {
              this.hasAdminRole = true;
            }
            else {
              this.hasAdminRole = false;
            }
          }
        },
          (error) => {
            console.log("ISOnet product eligibility check error-dashboard.")
            //console.log(error);
          }
        );

      }
    }

    // Uncomment 73 for local testing
    //   if(!this.appService.isCustomer){
    //     this.hasAdminRole = true;
    //   }


    // For Testing Admin by Joji

    //   this.hasAdminRole = true;

    // Till Here
  }

}
