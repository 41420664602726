import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-loading-screen",
  templateUrl: "./loading-screen.component.html",
  styleUrls: ["./loading-screen.component.scss"]
})
export class LoadingScreenComponent implements OnInit {
  @Input() size;

  constructor() {}
  iconSize;
  tranfromSize;
  ngOnInit() {
    if (this.size && this.size < 200) {
      this.iconSize = this.size;
      this.tranfromSize = this.size / 200;
    } else {
      this.iconSize = 100;
      this.tranfromSize = 0.5;
    }
  }
}
