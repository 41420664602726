import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService } from '../company.service';
@Component({
  selector: 'app-upload-premium',
  templateUrl: './upload-premium.component.html',
  styleUrls: ['./upload-premium.component.scss']
})
export class UploadPremiumComponent implements OnInit {
  isLoaded:boolean=false;
  constructor(
    public activeModal: NgbActiveModal,
    private companyService: CompanyService
  ) {

  }

  ngOnInit(): void {
    setTimeout(()=>{this.isLoaded=true;},500);
  }

  openFile() {
    document.querySelector('input').click();
  }
  handle(event) {
    if (event.target.files.length == 0) {
      return;
    }
    var file = event.target.files[0];
    this.activeModal.close(file);
  }

  // public downloadtemplate() {
  //   this.companyService.getTemplateFile("Template.xlsx").subscribe((resp) => {
  //     if (resp["message"] == "Success") {
  //       var fileurl = resp["templateurl"];
  //       let pwa = window.open(fileurl, "_blank");
  //       if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
  //         alert('Please disable your Pop-up blocker and try again.');
  //       }
  //     }
  //   },
  //     (error) => {
  //       //console.log(error);
  //     }
  //   )
  // }

}
