import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as GridActions from './data-access/grid-config.actions';
import {
  isAllDirectColumnsSelected,
  isAllDirectRowsSelected,
  isAllSupplementalColumnsSelected,
  isAllSupplementalRowsSelected,
  selectDirectPremiumColumns,
  selectDirectRowStates,
  selectGridConfigVisibility,
  selectSupplementalPremiumColumns,
  selectSupplementalRowStates,
} from './data-access/grid-config.selectors';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

@Component({
  selector: 'grid-configuration',
  templateUrl: './grid-configuration.component.html',
  styleUrls: ['./grid-configuration.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in-out'),
      ]),
      transition(':leave', [
        animate('000ms ease-in-out', style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
})
export class GridConfigurationComponent implements OnInit {
  @Input() premiumType: 'S' | 'D';
  gridConfigVisible$ = this.store.select(selectGridConfigVisibility);

  rowStates$;
  premiumColumns$;
  isAllRowsSelected$;
  isAllColumnsSelected$;

  selectorMapping = {
    D: {
      rowStates: selectDirectRowStates,
      isAllRowsSelected: isAllDirectRowsSelected,
      premiumColumns: selectDirectPremiumColumns,
      isAllColumnsSelected: isAllDirectColumnsSelected,
    },
    S: {
      rowStates: selectSupplementalRowStates,
      isAllRowsSelected: isAllSupplementalRowsSelected,
      premiumColumns: selectSupplementalPremiumColumns,
      isAllColumnsSelected: isAllSupplementalColumnsSelected,
    },
  };
  private destroy$ = new Subject<void>();

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store
      .pipe(
        switchMap(() => {
          const selectors = this.selectorMapping[this.premiumType];
          if (selectors) {
            this.rowStates$ = this.store.select(selectors.rowStates);
            this.isAllRowsSelected$ = this.store.select(
              selectors.isAllRowsSelected
            );
            this.premiumColumns$ = this.store.select(selectors.premiumColumns);
            this.isAllColumnsSelected$ = this.store.select(
              selectors.isAllColumnsSelected
            );
            return of(null);
          } else {
            throw new Error(`Unsupported premium type: ${this.premiumType}`);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.store.dispatch(GridActions.loadAllConfigurationsAction());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.store.dispatch(GridActions.hideGridConfig());
  }

  handleGridConfigClose() {
    this.store.dispatch(GridActions.applyInitialStates());
  }

  handleGridConfigSave() {
    this.store.dispatch(GridActions.saveGridConfigurations());
  }

  handleResetToDefaultGridConfig() {
    this.store.dispatch(
      GridActions.resetGridConfig({
        premiumType: this.premiumType,
        resetType: 'default',
      })
    );
  }

  handleResetToExistingDataGridConfig() {
    this.store.dispatch(
      GridActions.resetGridConfig({
        premiumType: this.premiumType,
        resetType: 'existingData',
      })
    );
  }
}
