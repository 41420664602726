import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { RowState } from '../../data-access/grid-config-model';
import {
  toggleDirectRowsSelectAll,
  toggleSupplementalRowsSelectAll,
  updateDirectRowStateAction,
  updateSupplementalRowStateAction,
} from '../../data-access/grid-config.actions';

@Component({
  selector: 'states-configuration',
  templateUrl: './states.component.html',
  styleUrls: ['./states.component.scss'],
})
export class StatesComponent{
  @Input() premiumType: string;
  @Input() rowStates: RowState<any>[];
  @Input() isAllRowsSelected: boolean;

  constructor(private store: Store) {}

  toggleSelectAll(): void {
    if (this.premiumType === 'D') {
      this.store.dispatch(toggleDirectRowsSelectAll());
    } else {
      this.store.dispatch(toggleSupplementalRowsSelectAll());
    }
  }

  toggleSelectRow(row: RowState<any>): void {
    if (this.premiumType === 'D') {
      this.store.dispatch(updateDirectRowStateAction({ rowState: row }));
    } else {
      this.store.dispatch(updateSupplementalRowStateAction({ rowState: row }));
    }
  }
}
