<div *ngIf="message" class="modal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="page-title">{{ title }}</h4>
      </div>
      <div class="modal-body">
        <div class="commentbox">
          <p>{{ message }}</p>
        </div>
        <div class="textarea-div">
          <textarea
            placeholder="Enter your comment"
            [(ngModel)]="comment"
            name="comment"
            #commentInput="ngModel"
            rows="4"
            required
            (blur)="validateComment(commentInput)"
            (input)="validateComment(commentInput)"
            class="textarea-content"
          ></textarea>
          <mat-error
            *ngIf="
              commentInput.invalid &&
              commentInput.touched &&
              showCommentRequiredError
            "
          >
            Comment is required
          </mat-error>
          <mat-error *ngIf="commentInput.touched && showWhitespaceError">
            Comment cannot be just blank spaces
          </mat-error>
        </div>
      </div>
      <div class="modal-footer">
        <app-button
          size="medium"
          class="button-yes"
          (click)="submitComment(commentInput)"
          buttonType="primary"
        >
          Yes
        </app-button>
        <app-button
          size="medium"
          class="button-no"
          (click)="closeModal()"
          buttonType="secondary"
        >
          No
        </app-button>
      </div>
    </div>
  </div>
</div>
