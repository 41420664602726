import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-validation-rule',
  templateUrl: './validation-rule.component.html',
  styleUrls: ['./validation-rule.component.scss'],
})
export class ValidationRuleComponent implements OnInit {
  ngOnInit(): void {
    console.log(this.data);
  }
  @Input() data: any;
}
