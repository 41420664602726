import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root', // root level, angular creates a single shared instance of this service
})

export class EntitlementService {

  private isAuthenticated: BehaviorSubject<boolean>;

  constructor(private http: HttpClient) {
    this.isAuthenticated = new BehaviorSubject(false);
  }

  getLoginStatus(): Observable<boolean> {
    return this.isAuthenticated.asObservable();
  }

  setLoginStatus(status: boolean): void {
    this.isAuthenticated.next(status);
  }

  getCompanyToken(apiUrl: string, custid: string): Observable<string> {
    return this.http.get(`${apiUrl}/GetUserProductGroupMembershipAndCustomerIds/${custid}`, { responseType: 'text' }).pipe(
      // tap(data => console.log('UserProducts API Data: ' + JSON.stringify(data))),
      catchError(this.handleError)
    );
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }
}
