<div *ngIf="isLoaded" class="modal" tabindex="-1" role="dialog" style="display:block!important">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="pagetitle">Comments</h4>
        <span class="table-material-icons" (click)="activeModal.close('Close')"
          style="cursor: pointer;font-size: 24px;">close</span>
      </div>
      <ng-container *ngIf="!isSubmissionComment; else elseSubComment;">
        <div class="modal-body">
          <div class="commentbox">
            <p>{{comment}}</p>
          </div>
        </div>
        <div class="modal-footer">
          <app-button size="medium" buttonType="primary" (click)="activeModal.close('Close')">Close</app-button>
        </div>
      </ng-container>
      <ng-template #elseSubComment>
        <form [formGroup]="subcommentForm" (ngSubmit)="onsubmit()">
          <div class="modal-body">
            <div class="inputright" [ngClass]="{
              'has-error':
              subcommentForm.get('comment').errors &&
                (subcommentForm.get('comment').touched ||
                subcommentForm.get('comment').dirty)
            }">
              <label class="lib-text-field" required>
                <p>Please enter your comments</p>
                <textarea formControlName="comment" rows="8" cols="56" style="width: 100%;"></textarea>
                <span class="help-block" *ngIf="
              subcommentForm.get('comment').errors &&
              (subcommentForm.get('comment').touched ||
              subcommentForm.get('comment').dirty)
            ">
                  <span *ngIf="subcommentForm.get('comment').errors" class="inputMsg"><i
                      class="table-material-icons">error</i> Comment is
                    required</span>
                </span>
              </label>
            </div>
          </div>
          <div class="modal-footer">
            <app-button buttonType="primary" type="submit" [disabled]="!subcommentForm.valid" style="width: 100%;">Save
              Data
            </app-button>
          </div>
        </form>
      </ng-template>
    </div>
  </div>
</div>

  <!-- <div *ngIf="isSubmissionComment" class="modal-content">
      <div class="modal-header">
        <h4 class="pagetitle">{{title}}</h4>
        <span class="table-material-icons" (click)="activeModal.dismiss('Cross click')"
          style="cursor: pointer;font-size: 24px;">close</span>

      </div>

    </div> -->


  <!-- <div *ngIf="isSubmissionComment" class="modal" tabindex="-1" role="dialog" style="display:block!important">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="pagetitle">{{title}}</h4>
        <span class="table-material-icons" (click)="activeModal.dismiss('Cross click')"
          style="cursor: pointer;font-size: 24px;">close</span>

      </div>
      <div class="modal-body"> -->
  <!-- <div class="information"> Group company information will not be copied. </div><br />
        <div class="PageLevelError" *ngIf="showPageError">{{error}}</div> -->
  <!-- <form [formGroup]="subcommentForm" (ngSubmit)="onsubmit()">
          <div class="inputright" [ngClass]="{
            'has-error':
            subcommentForm.get('comment').errors &&
              (subcommentForm.get('comment').touched ||
              subcommentForm.get('comment').dirty)
          }">
            <label class="lib-text-field" required>
              <textarea formControlName="comment" rows="8" cols="52"></textarea>
              <span class="help-block" *ngIf="
            subcommentForm.get('comment').errors &&
            (subcommentForm.get('comment').touched ||
            subcommentForm.get('comment').dirty)
          ">
                <span *ngIf="subcommentForm.get('comment').errors" class="inputMsg"><i
                    class="table-material-icons">error</i> Comment is
                  required</span>
              </span>
            </label>
          </div>

        </form>
      </div>
      <div class="modal-footer">
        <app-button buttonType="primary" type="submit" (click)="onsubmit()" [disabled]="!subcommentForm.valid"
          style="width: 100%;">SAVE DATA
        </app-button>

      </div>
    </div>
  </div>
</div> -->
