import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store} from '@ngrx/store';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import * as PremiumReportActions from '../../premiumreport/data-access/premium-report.action';
import { validationSummaryJobIdSelector } from '../../premiumreport/data-access/premium-report.selectors';
import * as ValidationSummaryActions from './validation-summary-actions';
import { selectCommonInputData, selectIsDummyReport } from './validation-summary-selectors';
import { ValidationSummaryService } from './validation-summary-service'; // Import the service
import { AdminService } from 'src/app/admin/admin.service';
import { of } from 'rxjs';

@Injectable()
export class ValidationSummaryEffects {
  loadValidationSummaryItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ValidationSummaryActions.initiateValidationOnAfterSubmitSuccess,
        ValidationSummaryActions.loadAllValidationSummaryItems,
        ValidationSummaryActions.updateFeedbackSuccess,
        PremiumReportActions.updateJobIdSuccess
      ),
      withLatestFrom(this.store.select(validationSummaryJobIdSelector)),
      switchMap(([action, jobId]) =>
        this.validationSummaryService.loadAllValidationSummaryItems(jobId).pipe(
          switchMap((validationSummaryBaseItem) => {
            return [
              ValidationSummaryActions.loadValidationSummaryItemsSuccess({
                validationSummaryBaseItem,
              }),
              PremiumReportActions.updateLockStatus(),
            ];
          }),
          catchError((error) =>
            of(
              ValidationSummaryActions.loadValidationSummaryItemsFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  loadNumberOfCompanyValidationMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ValidationSummaryActions.loadValidationSummaryItemsSuccess),
      withLatestFrom(
        this.store.select(validationSummaryJobIdSelector),
        this.store.select(selectCommonInputData)
      ),
      switchMap(([action, jobId, commonInput]) => {
        const isAdmin =
          this.appService.isReadonlyAdmin || this.appService.isAdmin;

        if (!isAdmin) {
          // Dispatch action for non-admin users
          return of(
            ValidationSummaryActions.loadNumberOfCompanyValidationMessageSuccess(
              {
                message: null,
              }
            )
          );
        } else {
          // For admin users, load number of company validation messages
          return this.validationSummaryService
            .loadNumberOfCompanyValidationMessage(jobId, commonInput)
            .pipe(
              switchMap((message) => {
                // Dispatch action for success
                return [
                  ValidationSummaryActions.loadNumberOfCompanyValidationMessageSuccess(
                    {
                      message: message,
                    }
                  ),
                ];
              }),
              catchError((error) => {
                // Dispatch action for failure
                return of(
                  ValidationSummaryActions.loadNumberOfCompanyValidationMessageFailure(
                    {
                      error,
                    }
                  )
                );
              })
            );
        }
      })
    )
  );

  initiateValidationOnAfterSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ValidationSummaryActions.initiateValidationOnAfterSubmit),
      switchMap((action) => {
        return this.validationSummaryService
          .initiateValidationOnSubmit(action.inputData)
          .pipe(
            map((jobId: string) =>
              ValidationSummaryActions.initiateValidationOnAfterSubmitSuccess({
                jobId,
              })
            ),
            catchError((error) =>
              of(
                ValidationSummaryActions.initiateValidationOnAfterSubmitFailure(
                  {
                    error,
                  }
                )
              )
            )
          );
      })
    )
  );

  unlockToEdit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ValidationSummaryActions.unlockToEdit),
      withLatestFrom(this.store.select(selectCommonInputData)),
      switchMap(([action, commonInput]) => {
        const { inputData } = action;
        const updatedInputData = { ...inputData, ...commonInput };

        return this.validationSummaryService
          .unlockToEdit(updatedInputData)
          .pipe(
            switchMap(() => of(ValidationSummaryActions.unlockToEditSuccess())),
            catchError((error) =>
              of(ValidationSummaryActions.unlockToEditFailure({ error }))
            )
          );
      })
    )
  );

  updateJobId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PremiumReportActions.updateJobId),
      map(() => ValidationSummaryActions.resetValidationSummaryData()),
      catchError((error) =>
        of(
          ValidationSummaryActions.resetValidationSummaryDataFilure({
            error,
          })
        )
      )
    )
  );

  unlockToEditSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ValidationSummaryActions.unlockToEditSuccess),
        tap(() => {
          if (this.appService.isAdmin) {
            this.router.navigate(['admin', 'premiumreport']);
          } else {
            this.router.navigate(['/premiumreport']);
          }
        })
      ),
    { dispatch: false }
  );

  reSubmitSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ValidationSummaryActions.reSubmitSuccess),
        tap(() => {
          this.router.navigate(['/dboard']);
        })
      ),
    { dispatch: false }
  );

  reSubmitEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ValidationSummaryActions.reSubmit),
      withLatestFrom(this.store.select(selectIsDummyReport)),
      withLatestFrom(this.store.select(selectCommonInputData)),
      switchMap(([[action, isDummyReport], commonInput]) => {
        const { inputData } = action;
        const updatedInputData = {
          ...inputData,
          ...commonInput,
          dummyrpt: isDummyReport,
        };

        return this.validationSummaryService.reSubmit(updatedInputData).pipe(
          map(() => ValidationSummaryActions.reSubmitSuccess()),
          catchError((error) =>
            of(ValidationSummaryActions.reSubmitFailure({ error }))
          )
        );
      })
    )
  );

  updateFeedback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ValidationSummaryActions.updateFeedback),
      withLatestFrom(this.store.select(selectCommonInputData)),
      switchMap(([action, commonInput]) => {
        const { feedbackData } = action;
        const updatedInputData = { ...feedbackData, ...commonInput };
        return this.validationSummaryService
          .updateFeedback(updatedInputData)
          .pipe(
            map(() => ValidationSummaryActions.updateFeedbackSuccess()),
            catchError((error) =>
              of(
                ValidationSummaryActions.updateFeedbackFailure({
                  error,
                })
              )
            )
          );
      })
    )
  );

  failureRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ValidationSummaryActions.loadValidationSummaryItemsFailure,
          ValidationSummaryActions.loadNumberOfCompanyValidationMessageFailure,
          ValidationSummaryActions.initiateValidationOnAfterSubmitFailure,
          ValidationSummaryActions.unlockToEditFailure,
          ValidationSummaryActions.resetValidationSummaryDataFilure,
          ValidationSummaryActions.reSubmitFailure,
          ValidationSummaryActions.updateFeedbackFailure
        ),
        tap(() => {
          this.router.navigate(['/apifailure']);
        })
      ),
    { dispatch: false }
  );
  
  constructor(
    private actions$: Actions,
    private store: Store,
    private validationSummaryService: ValidationSummaryService,
    private router: Router,
    private appService: AppService
  ) {}
}
