import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { HttpService } from 'src/app/shared/http.service';
import { environment } from 'src/environments/environment';
import { CompanyService } from '../../company.service';
import { selectPremiumReportState } from '../../premiumreport/data-access/premium-report.selectors';
import {
  BaseItem,
  ConfigResponse,
  GridConfig,
  GridConfigResponse,
  GridConfiguration,
  PremiumColumn,
  RowState,
  nonConfigurablePremiumColumnNames,
  nonConfigurableStateCodes,
} from './grid-config-model';
import * as GridActions from './grid-config.actions';
import { selectGridConfig } from './grid-config.selectors';

@Injectable({
  providedIn: 'root',
})
export class GridConfigService {
  baseUrl = environment.apiUrl;

  constructor(
    private companyService: CompanyService,
    private appService: AppService,
    private http: HttpClient,
    private httpService: HttpService,
    private store: Store
  ) {}

  getAllConfigurations(): Observable<
    GridConfig<RowState<BaseItem<any>>, PremiumColumn<BaseItem<any>>>
  > {
    const data = {
      prfyear: this.companyService.currentYear,
    };
    return this.http
      .post<ConfigResponse<BaseItem<any>>>(
        `${this.baseUrl}getallconfigurations`,
        data
      )
      .pipe(
        map((response) => this.mapResponseToGridConfigType(response)),
        catchError((error) => throwError(error))
      );
  }

  private readonly defaultConfig = {
    direct: { states: [], columns: [] },
    supplemental: { states: [], columns: [] },
  };

  getGridConfigurations(): Observable<
    GridConfig<RowState<BaseItem<any>>, PremiumColumn<BaseItem<any>>>
  > {
    const isReadOnlyAdmin = this.appService.isReadonlyAdmin;
    const currentYear = this.companyService.currentYear;
    const companyId = this.companyService.companyId;
    const isAdmin = this.appService.isAdmin;

    if (!isNaN(currentYear) && companyId) {
      const data = {
        prfyear: currentYear,
        companyno: companyId,
        isadmin: !!isAdmin,
      };

      // Return an observable based on whether the user is readOnlyAdmin or not
      return !isReadOnlyAdmin
        ? this.http
            .post<GridConfigResponse<BaseItem<any>>>(
              `${this.baseUrl}gridconfiguration`,
              data
            )
            .pipe(
              map((response) => this.mapResponseToGridConfig(response)),
              catchError((error) => throwError(error))
            )
        : of(this.defaultConfig);
    } else {
      // Return default configuration if currentYear is not a number or companyId is empty
      return of(this.defaultConfig);
    }
  }

  saveGridConfigurations(
    gridConfigData: GridConfig<
      RowState<BaseItem<any>>,
      PremiumColumn<BaseItem<any>>
    >
  ): Observable<GridConfigResponse<BaseItem<any>>> {
    // Filter out items with selected=false
    const filteredGridConfigData: GridConfig<
      RowState<BaseItem<any>>,
      PremiumColumn<BaseItem<any>>
    > = {
      direct: {
        states: gridConfigData.direct.states
          .filter((state) => !state.selected)
          .map(({ selected, ...rest }) => rest),
        columns: gridConfigData.direct.columns
          .filter((column) => !column.selected)
          .map(({ selected, ...rest }) => rest),
      },
      supplemental: {
        states: gridConfigData.supplemental.states
          .filter((state) => !state.selected)
          .map(({ selected, ...rest }) => rest),
        columns: gridConfigData.supplemental.columns
          .filter((column) => !column.selected)
          .map(({ selected, ...rest }) => rest),
      },
    };

    const data = {
      prfyear: this.companyService.currentYear,
      companyno: this.companyService.companyId,
      isadmin: !!this.appService.isAdmin,
      config: filteredGridConfigData,
      config_type: 'current',
    };
    return this.http
      .post<GridConfigResponse<BaseItem<any>>>(
        `${this.baseUrl}updategridconfig`,
        data
      )
      .pipe(
        tap(() =>
          this.store.dispatch(GridActions.loadAllConfigurationsAction())
        ),
        catchError((error) => throwError(error))
      );
  }

  getDefaultGridConfigurations(
    premiumType,
    resetType
  ): Observable<
    GridConfig<RowState<BaseItem<any>>, PremiumColumn<BaseItem<any>>>
  > {
    if (resetType !== 'existingData') {
      const data = {
        prfyear: this.companyService.currentYear,
        companyno: this.companyService.companyId,
      };
      return this.http
        .post<GridConfigResponse<BaseItem<any>>>(
          `${this.baseUrl}getdefaultgridconfiguration`,
          data
        )
        .pipe(
          switchMap((response) =>
            this.mapDefaultResponseToGridConfig(response, premiumType)
          ),
          catchError((error) => throwError(error))
        );
    } else {
      return this.resetToExistingValuesDataGrid(premiumType);
    }
  }

  getConsolidatedViewGridConfigurations(): Observable<
    GridConfig<RowState<BaseItem<any>>, PremiumColumn<BaseItem<any>>>
  > {
    const consolidatedDirectConfig$: Observable<GridConfig<any, any>> =
      this.resetToExistingValuesDataGrid('D');
    const consolidatedSupplementalConfig$: Observable<GridConfig<any, any>> =
      this.resetToExistingValuesDataGrid('S');

    const mergedGridConfig$: Observable<GridConfig<any, any>> = forkJoin({
      directConfig: consolidatedDirectConfig$,
      supplementalConfig: consolidatedSupplementalConfig$,
    }).pipe(
      map(({ directConfig, supplementalConfig }) => ({
        direct: {
          states: directConfig.direct.states,
          columns: directConfig.direct.columns,
        },
        supplemental: {
          states: supplementalConfig.supplemental.states,
          columns: supplementalConfig.supplemental.columns,
        },
      }))
    );

    return mergedGridConfig$;
  }

  private transformPremiumGridData = (
    gridData: any[],
    columns: any[],
    gridType: string
  ): {
    direct?: GridConfiguration<BaseItem<any>>;
    supplemental?: GridConfiguration<BaseItem<any>>;
  } => {
    const indicesWithValuesSet: Set<number> = new Set();

    const transformedStatesData = gridData.map((item, index) => {
      const [firstItem, secondItem, thirdItem, ...remainingItems] =
        Object.values(item);

      const allValuesAreNullOrZero = remainingItems.every(
        (value) => value === 0 || value === null || value === ''
      );

      if (!allValuesAreNullOrZero) {
        remainingItems.forEach((value, i) => {
          if (value !== 0 && value !== null && value !== '') {
            indicesWithValuesSet.add(i);
          }
        });
      }

      if (allValuesAreNullOrZero) {
        return {
          id: firstItem as string,
          name: secondItem as string,
          selected: false,
        };
      }

      return null;
    });

    const indicesWithValuesArray = Array.from(indicesWithValuesSet);
    const updatedIndicesWithValuesArray = indicesWithValuesArray.map(
      (value) => value + 3
    );

    const filteredColumns = columns
      .filter((column, index) => !updatedIndicesWithValuesArray.includes(index))
      .sort((a, b) => a.columnNumber - b.columnNumber);

    const transformedPremiumColumnsData =
      this.constructPremiumColumns(filteredColumns);

    if (gridType === 'D') {
      return {
        direct: {
          states: transformedStatesData
            .filter((item) => item !== null)
            .reduce((acc, item) => {
              acc[item.id] = item;
              return acc;
            }, {} as Record<string, RowState<BaseItem<any>>>),
          columns: transformedPremiumColumnsData.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
          }, {} as Record<string, PremiumColumn<BaseItem<any>>>),
        },
      };
    } else {
      return {
        supplemental: {
          states: transformedStatesData
            .filter((item) => item !== null)
            .reduce((acc, item) => {
              acc[item.id] = item;
              return acc;
            }, {} as Record<string, RowState<BaseItem<any>>>),
          columns: transformedPremiumColumnsData.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
          }, {} as Record<string, PremiumColumn<BaseItem<any>>>),
        },
      };
    }
  };

  constructPremiumColumns(filteredColumns: any[]) {
    const filteredPremiumColumns =
      this.removeNonConfigurablePremiumColumns(filteredColumns);

    return filteredPremiumColumns.map((premiumColumn) => ({
      id: premiumColumn.Number,
      name: this.formatPremiumColumnName([
        premiumColumn.Name1,
        premiumColumn.Name2,
        premiumColumn.Name3,
        premiumColumn.Name4,
      ]),
      selected: false,
    }));
  }

  private mapDefaultResponseToGridConfig(
    response: GridConfigResponse<BaseItem<any>>,
    premiumType: 'D' | 'S'
  ) {
    const configKey = 'defaultconfiguration';
    const gridConfig: GridConfig<any, any> = {
      direct: { states: [], columns: [] },
      supplemental: { states: [], columns: [] },
    };

    const premiumTypeName = premiumType === 'D' ? 'direct' : 'supplemental';

    const rowStates =
      response?.data?.[0]?.[configKey]?.[premiumTypeName]?.states || [];
    const premiumColumns =
      response?.data?.[0]?.[configKey]?.[premiumTypeName]?.columns || [];

    gridConfig[premiumTypeName].states = Object.values(rowStates);
    gridConfig[premiumTypeName].columns = Object.values(premiumColumns);

    return of(gridConfig).pipe(
      switchMap(() =>
        this.store.pipe(
          select(selectGridConfig),
          take(1),
          map((latestValues) => {
            const selectedFalseFilter = (item) => !item.selected;

            if (premiumType === 'D') {
              gridConfig.supplemental.states.push(
                ...Object.values(latestValues.supplementalRowStates).filter(
                  selectedFalseFilter
                )
              );
              gridConfig.supplemental.columns.push(
                ...Object.values(
                  latestValues.supplementalPremiumColumns
                ).filter(selectedFalseFilter)
              );
            } else {
              gridConfig.direct.states.push(
                ...Object.values(latestValues.directRowStates).filter(
                  selectedFalseFilter
                )
              );
              gridConfig.direct.columns.push(
                ...Object.values(latestValues.directPremiumColumns).filter(
                  selectedFalseFilter
                )
              );
            }

            return gridConfig;
          })
        )
      )
    );
  }

  private resetToExistingValuesDataGrid(premiumType: 'D' | 'S') {
    return this.store.pipe(
      select(selectPremiumReportState),
      take(1),
      switchMap((premiumReportSelector) => {
        const gridConfig: GridConfig<any, any> = {
          direct: { states: [], columns: [] },
          supplemental: { states: [], columns: [] },
        };

        const hiddenItems = this.transformPremiumGridData(
          premiumReportSelector.premiumData[premiumType].premiumGridData,
          premiumReportSelector.columns[premiumType],
          premiumType
        );

        const premiumTypeName = premiumType === 'D' ? 'direct' : 'supplemental';

        gridConfig[premiumTypeName].states.push(
          ...Object.values(hiddenItems[premiumTypeName].states)
        );
        gridConfig[premiumTypeName].columns.push(
          ...Object.values(hiddenItems[premiumTypeName].columns)
        );

        return of(gridConfig).pipe(
          switchMap(() =>
            this.store.pipe(
              select(selectGridConfig),
              take(1),
              map((latestValues) => {
                const selectedFalseFilter = (item) => !item.selected;

                if (premiumType === 'D') {
                  gridConfig.supplemental.states.push(
                    ...Object.values(latestValues.supplementalRowStates).filter(
                      selectedFalseFilter
                    )
                  );
                  gridConfig.supplemental.columns.push(
                    ...Object.values(
                      latestValues.supplementalPremiumColumns
                    ).filter(selectedFalseFilter)
                  );
                } else {
                  gridConfig.direct.states.push(
                    ...Object.values(latestValues.directRowStates).filter(
                      selectedFalseFilter
                    )
                  );
                  gridConfig.direct.columns.push(
                    ...Object.values(latestValues.directPremiumColumns).filter(
                      selectedFalseFilter
                    )
                  );
                }

                return gridConfig;
              })
            )
          )
        );
      })
    );
  }

  private mapResponseToGridConfig(response: GridConfigResponse<BaseItem<any>>) {
    const isAdmin = !!this.appService.isAdmin;
    const configKey = isAdmin ? 'adminconfiguration' : 'currentconfiguration';

    const directRowStates = response?.data?.[0]?.[configKey]?.direct?.states;
    const supplementalRowStates =
      response?.data?.[0]?.[configKey]?.supplemental?.states;
    const directPremiumColumns =
      response?.data?.[0]?.[configKey]?.direct?.columns;
    const supplementalPremiumColumns =
      response?.data?.[0]?.[configKey]?.supplemental?.columns;

    return {
      direct: {
        states: directRowStates || [],
        columns: directPremiumColumns || [],
      },
      supplemental: {
        states: supplementalRowStates || [],
        columns: supplementalPremiumColumns || [],
      },
    };
  }

  private removeNonConfigurableStates(statesFromResponse: any[]) {
    return statesFromResponse.filter(
      (state) => !nonConfigurableStateCodes.includes(state.Code)
    );
  }

  private mapStatesFromResponse(response: ConfigResponse<BaseItem<any>>) {
    if (response && response.data) {
      const statesFromResponse = response.data[0]?.states?.State || [];

      // Remove NonConfigurable state rows
      const configurableStates =
        this.removeNonConfigurableStates(statesFromResponse);

      return configurableStates.map((state) => ({
        id: state.Code,
        name: state.Name,
        selected: false,
      }));
    } else {
      throw new Error('Invalid response format');
    }
  }

  private formatPremiumColumnName(nameParts: string[]) {
    return nameParts
      .map((part) => (part ? part.trim() : ''))
      .filter((part) => part !== '')
      .join(' ');
  }

  private removeNonConfigurablePremiumColumns(
    premiumColumnFromResponse: any[]
  ) {
    return premiumColumnFromResponse.filter((premiumColumn) => {
      const premiumColumnName = this.formatPremiumColumnName([
        premiumColumn.Name1,
        premiumColumn.Name2,
        premiumColumn.Name3,
        premiumColumn.Name4,
      ]);
      const shouldRemoved =
        nonConfigurablePremiumColumnNames.includes(premiumColumnName);
      const isNotEmpty =
        premiumColumn.Name1.trim() ||
        premiumColumn.Name2.trim() ||
        premiumColumn.Name3.trim() ||
        premiumColumn.Name4.trim();

      return !shouldRemoved && isNotEmpty;
    });
  }

  private mapPremiumColumnsFromResponse(
    response: ConfigResponse<BaseItem<any>>
  ) {
    const premiumColumnFromResponse = response.data[0]?.cols?.Col || [];

    // Remove NonConfigurable premium columns
    const filteredPremiumColumns = this.removeNonConfigurablePremiumColumns(
      premiumColumnFromResponse
    );

    return filteredPremiumColumns.map((premiumColumn) => ({
      id: premiumColumn.Number,
      name: this.formatPremiumColumnName([
        premiumColumn.Name1,
        premiumColumn.Name2,
        premiumColumn.Name3,
        premiumColumn.Name4,
      ]),
      selected: false,
    }));
  }

  private mapResponseToGridConfigType(response: ConfigResponse<BaseItem<any>>) {
    const rowStates = this.mapStatesFromResponse(response);
    const premiumColumns = this.mapPremiumColumnsFromResponse(response);

    return {
      direct: {
        states: rowStates,
        columns: premiumColumns,
      },
      supplemental: {
        states: rowStates,
        columns: premiumColumns,
      },
    };
  }
}
