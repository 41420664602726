import { Component, Input } from '@angular/core';
import {
  ValidationSummaryItem,
} from '../../data-access/validation-summary-model';

@Component({
  selector: 'app-validation-email-preview',
  templateUrl: './validation-email-preview.component.html',
  styleUrls: ['./validation-email-preview.component.scss'],
})
export class ValidationEmailPreviewComponent {
  @Input() validationSummaryItem: ValidationSummaryItem[] = [];
}
