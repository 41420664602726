<div *ngFor="let item of validationSummaryItem;let row=index" style="margin-top: 10px;padding-top: 10px">
  <div *ngIf="item.status !== 'OK'">
    <div>
      <span class="title-text"><b>{{row+1 }}. {{ item.ruleName }}</b></span>
    </div>
    <div>
      <div style=" display: grid;
        grid-template-columns: 10% 90%;
        margin-top: 10px;
        padding: 10px;">
        <div>
          <b>Rule:</b>
        </div>
        <div>
          {{ item.ruleDescription }}
          <br/>
          <div>
            <div style="margin-top: 10px;padding-top: 20px;">
              <table style="width: 100%;">
                <thead style="background-color: #f8f8f8;">
                  <tr>
                    <th *ngFor="let col of item.content.value.cols"
                      style="text-align: left;padding-right: 10px;border-bottom: 1px solid #ccc;border-top: 1px solid #ccc;">
                      {{ col }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of item.content.value.data">
                    <td *ngFor="let cell of row" style="text-align: left;border-bottom: 1px solid #ccc">{{ cell }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>