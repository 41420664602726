import { createSelector, createFeatureSelector } from '@ngrx/store';
import { GridConfigState, RowState, PremiumColumn } from './grid-config-model';

export const selectGridConfigState =
  createFeatureSelector<GridConfigState<any, any>>('config');

export const selectGridConfigVisibility = createSelector(
  selectGridConfigState,
  (config: GridConfigState<any, any>) => config.gridConfigVisible
);

export const selectDirectRowStates = createSelector(
  selectGridConfigState,
  (config: GridConfigState<any, any>) => config.gridConfigData.direct.states
);

export const selectDirectPremiumColumns = createSelector(
  selectGridConfigState,
  (config: GridConfigState<any, any>) => config.gridConfigData.direct.columns
);

export const isAllDirectRowsSelected = createSelector(
  selectDirectRowStates,
  (directRowStates: RowState<any>[]) =>
    directRowStates.every((directRowState) => directRowState.selected)
);

export const isAllDirectColumnsSelected = createSelector(
  selectDirectPremiumColumns,
  (directPremiumColumns: PremiumColumn<any>[]) =>
    directPremiumColumns.every(
      (directPremiumColumn) => directPremiumColumn.selected
    )
);

export const selectSupplementalRowStates = createSelector(
  selectGridConfigState,
  (config: GridConfigState<any, any>) =>
    config.gridConfigData.supplemental.states
);

export const selectSupplementalPremiumColumns = createSelector(
  selectGridConfigState,
  (config: GridConfigState<any, any>) =>
    config.gridConfigData.supplemental.columns
);

export const isAllSupplementalRowsSelected = createSelector(
  selectSupplementalRowStates,
  (supplementalRowStates: RowState<any>[]) =>
    supplementalRowStates.every(
      (supplementalRowState) => supplementalRowState.selected
    )
);

export const isAllSupplementalColumnsSelected = createSelector(
  selectSupplementalPremiumColumns,
  (supplementalPremiumColumns: PremiumColumn<any>[]) =>
    supplementalPremiumColumns.every(
      (supplementalPremiumColumn) => supplementalPremiumColumn.selected
    )
);

export const selectGridConfig = createSelector(
  selectGridConfigState,
  (config: GridConfigState<any, any>) => ({
    directRowStates: config.gridConfigData.direct.states,
    directPremiumColumns: config.gridConfigData.direct.columns,
    supplementalRowStates: config.gridConfigData.supplemental.states,
    supplementalPremiumColumns: config.gridConfigData.supplemental.columns,
  })
);

export const selectInitialDirectRowStates = createSelector(
  selectGridConfigState,
  (config: GridConfigState<any, any>) => config.initialData.direct.states
);

export const selectInitialDirectPremiumColumns = createSelector(
  selectGridConfigState,
  (config: GridConfigState<any, any>) => config.initialData.direct.columns
);

export const selectInitialSupplementalRowStates = createSelector(
  selectGridConfigState,
  (config: GridConfigState<any, any>) =>
    config.initialData.supplemental.states
);

export const selectInitialSupplementalPremiumColumns = createSelector(
  selectGridConfigState,
  (config: GridConfigState<any, any>) =>
    config.initialData.supplemental.columns
);
