import { createAction, props } from '@ngrx/store';
import { Companylisttable, ListTypeList } from './admin-company-list-model';

export const loadPrfYears = createAction(
  '[Admin Company List] Load PRF Years',
  props<{ groupCode: string }>()
);
export const loadPrfYearsSuccess = createAction(
  '[Admin Company List] Load PRF Years Success',
  props<{ yearsList: any[] }>()
);
export const loadPrfYearsFailure = createAction(
  '[Admin Company List] Load PRF Years Failure',
  props<{ error: any }>()
);

export const loadCompaniesByYear = createAction(
  '[Admin Company List] Load Companies By Year',
  props<{ currentYear: number }>()
);
export const loadCompaniesByYearSuccess = createAction(
  '[Admin Company List] Load Companies By Year Success',
  props<{ companyList: Companylisttable[]; filterStatusSelected: string }>()
);
export const loadCompaniesByYearFailure = createAction(
  '[Admin Company List] Load Companies By Year Failure',
  props<{ error: any }>()
);

export const loadLatestCompanyList = createAction(
  '[Admin Company List] Load Latest Company List'
);

export const loadSubmittedCompanies = createAction(
  '[Admin Company List] Load Submitted Companies',
  props<{ currentYear: number }>()
);
export const loadSubmittedCompaniesSuccess = createAction(
  '[Admin Company List] Load Submitted Companies Success',
  props<{ companyList: Companylisttable[]; filterStatusSelected: string }>()
);
export const loadSubmittedCompaniesFailure = createAction(
  '[Admin Company List] Load Submitted Companies Failure',
  props<{ error: any }>()
);

export const loadAdminHomeCompanyList = createAction(
  '[Admin Company List] Load Admin Home Company List'
);
export const loadAdminHomeCompanyListSuccess = createAction(
  '[Admin Company List] Load  Admin Home Company List Success',
  props<{ companyList: Companylisttable[]; filterStatusSelected: string }>()
);
export const loadAdminHomeCompanyListFailure = createAction(
  '[Admin Company List] Load  Admin Home Company List Failure',
  props<{ error: any }>()
);

export const loadSearchCompany = createAction(
  '[Admin Company List] Load  Search Company',
  props<{ currentYear: number; searchType: string; searchValue: string }>()
);
export const loadSearchCompanySuccess = createAction(
  '[Admin Company List] Load  Search Company Success',
  props<{ companyList: Companylisttable[]; filterStatusSelected: string }>()
);
export const loadSearchCompanyFailure = createAction(
  '[Admin Company List] Load Search Company Failure',
  props<{ error: any }>()
);

export const setListType = createAction(
  '[Admin Company List] Set List Type',
  props<{ listTypeSelected: ListTypeList }>()
);
export const setListTypeSuccess = createAction(
  '[Admin Company List] Set List Type Success',
  props<{ listTypeSelected: ListTypeList }>()
);
export const setListTypeFailure = createAction(
  '[Admin Company List] Set List Type Failure',
  props<{ error: any }>()
);

export const setCurrentYear = createAction(
  '[Admin Company List] Set Current PRF Year',
  props<{ currentYear: number }>()
);

export const setFilterStatusSelected = createAction(
  '[Admin Company List] Set Filter Status Selected',
  props<{ filterStatusSelected: string, index: string, filterStatusSelectedLabel: string }>()
);
export const setFilterStatusSelectedSuccess = createAction(
  '[Admin Company List] Set Filter Status Selected Success'
);
export const setFilterStatusSelectedFailure = createAction(
  '[Admin Company List] Set Filter Status Selected Failure',
  props<{ error: any }>()
);

export const setFilterStatusList = createAction(
  '[Admin Company List] Set Filter Status List',
  props<{ filterStatusList: string[] }>()
);
export const setFilterStatusListSuccess = createAction(
  '[Admin Company List] Set Filter Status List Success'
);
export const setFilterStatusListFailure = createAction(
  '[Admin Company List] Set Filter Status List Failure',
  props<{ error: any }>()
);

export const setSearchValue = createAction(
  '[Admin Company List] Set Search Value',
  props<{ searchValue: string }>()
);
export const setSearchValueSuccess = createAction(
  '[Admin Company List] Set Search Value Success'
);
export const setSearchValueFailure = createAction(
  '[Admin Company List] Set Search Value Failure',
  props<{ error: any }>()
);

export const setSearchTypeFailure = createAction(
  '[Admin Company List] Set Search Type Value Failure'
);

export const setFilterListTypes = createAction(
  '[Admin Company List] Set Filter Type List',
  props<{ filterListTypes: ListTypeList[] }>()
);
export const setFilterListTypesSuccess = createAction(
  '[Admin Company List] Set Filter Type List Success'
);
export const setFilterListTypesFailure = createAction(
  '[Admin Company List] Set Filter Type List Failure',
  props<{ error: any }>()
);

export const resetFilters = createAction(
  '[Admin Company List] Reset List Type Selected'
);
export const resetFiltersSuccess = createAction(
  '[Admin Company List] Reset List Type Selected Success'
);
export const resetFiltersFailure = createAction(
  '[Admin Company List] Reset List Type Selected Failure',
  props<{ error: any }>()
);

export const openCompanyDetails = createAction(
  '[Admin Company List] Open Company Details',
  props<{
    groupCode: any;
    companyId: any;
    currentYear: number;
    prfNumber: any;
    prfName: any;
  }>()
);
export const openCompanyDetailsForCustomer = createAction(
  '[Admin Company List] Open Company Details for Customer',
  props<{
    groupCode: any;
    companyId: any;
    currentYear: number;
    prfNumber: any;
    prfName: any;
  }>()
);

export const getLockStatusSuccess = createAction(
  '[Admin Company List] Get Lock Status Success',
  props<{ data: any }>()
);
export const getLockStatusFailure = createAction(
  '[Admin Company List] Get Lock Status Failure',
  props<{ error: any }>()
);


export const toggleSelectAll = createAction(
  '[Admin Company List] Toggle Select All', props<{ checked: boolean }>()
);
export const toggleSelectAllSuccess = createAction(
  '[Admin Company List] Toggle Select All Success'
);
export const toggleSelectAllFailure = createAction(
  '[Admin Company List] Toggle Select All Failure',
  props<{ error: any }>()
);

export const toggleSelect = createAction(
  '[Admin Company List] Toggle Select',
  props<{ companyId: string }>()
);
export const toggleSelectSuccess = createAction(
  '[Admin Company List] Toggle Select Success'
);
export const toggleSelectFailure = createAction(
  '[Admin Company List] Toggle Select Failure',
  props<{ error: any }>()
);

export const deleteCompany = createAction(
  '[Admin Company List] Delete Company',
  props<{ groupCode: any; companyId: any }>()
);
export const deleteCompanySuccess = createAction(
  '[Admin Company List] Delete Company Success'
);
export const deleteCompanyFailure = createAction(
  '[Admin Company List] Delete Company Failure',
  props<{ error: any }>()
);

export const unlockUploadCompanySuccess = createAction(
  '[Admin Company List] Unlock Upload Company Success'
);
export const unlockUploadCompanyFailure = createAction(
  '[Admin Company List] Unlock Upload Company Failure',
  props<{ error: any }>()
);

export const loadCompanySilently = createAction(
  '[Admin Company List] Load Company List with out loader'
);

export const copyCompanySuccess = createAction(
  '[Admin Company List] Copy Company Success'
);
export const copyCompanyFailure = createAction(
  '[Admin Company List] Copy Company Failure',
  props<{ error: any }>()
);

export const uploadSelectedCompanies = createAction(
  '[Admin Company List] Upload Selected Companies'
);
export const uploadSelectedCompaniesSuccess = createAction(
  '[Admin Company List] Upload Selected Companies Success'
);
export const uploadSelectedCompaniesFailure = createAction(
  '[Admin Company List] Upload Selected Companies Failure',
  props<{ error: any }>()
);

export const setIsReadonlyAdmin = createAction(
  '[Admin Company List] Set Is Readonly Admin',  props<{ value: boolean }>()
);

export const navigateToValidationSummary = createAction(
  '[Admin Company List] Navigate To Validation Summary',
  props<{
    groupCode: any;
    companyId: any;
    currentYear: number;
    prfNumber: any;
    prfName: any;
  }>()
);
