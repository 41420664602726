<app-loading-screen *ngIf="spinnergetmetricsreport">Loading Metrics Report</app-loading-screen>
<div class="pagetitle">Submitted-Uploaded Companies: {{ prfYear }}</div>
<div class="information" *ngIf="showPageError">
  <div class="messagetitle"><i class="table-material-icons">error</i> {{pageError}}</div>
</div>
<br />
<div class="form-group">
  <div class="form-inline">
    <div class="form-inline" style="width: 650px;">
      <form class="form-inline" style="width: 43%;">
        <label for="startDate" style="margin-right: 10px;">Start Date:</label>
        <div class="form-group" style="width: 80%;">
          <div class="input-group">
            <input [formControl]="formControlStartDate" (keypress)="validateInput($event)"
            [class.is-invalid]="formControlStartDate.invalid && showStartDateValidator" class="form-control" id="startDate" placeholder="mm/dd/yyyy" name="dp" [(ngModel)]="startDate"
            ngbDatepicker #sd="ngbDatepicker" (focusout) = "startDateOnBlur()" (focus) = "startDateOnFocus()" />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="sd.toggle()"  type="button">
                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 20 20" height="23px" viewBox="0 0 20 20" width="23px" fill="#004EAA">
                  <g>
                    <rect fill="none" height="23" width="23" x="0"/>
                  </g>
                  <g>
                    <path d="M15.5,4H14V2h-1.5v2h-5V2H6v2H4.5C3.67,4,3,4.68,3,5.5v11C3,17.32,3.67,18,4.5,18h11c0.83,0,1.5-0.68,1.5-1.5v-11 C17,4.68,16.33,4,15.5,4z M15.5,16.5h-11V9h11V16.5z M15.5,7.5h-11v-2h11V7.5z M7.5,12H6v-1.5h1.5V12z M10.75,12h-1.5v-1.5h1.5V12z M14,12h-1.5v-1.5H14V12z M7.5,15H6v-1.5h1.5V15z M10.75,15h-1.5v-1.5h1.5V15z M14,15h-1.5v-1.5H14V15z"/>
                  </g>
                </svg>
              </button>
            </div>
            <div *ngIf="showStartDateValidator" class="invalid-tooltip">{{ formControlStartDate.invalid ? "Date is invalid" : undefined }}</div>
          </div>
        </div>
      </form>
      <form class="form-inline" style="width: 43%;">
        <label for="endDate" style="margin-right: 10px;">End Date:</label>
        <div class="form-group" style="width: 80%;">
          <div class="input-group">
            <input [formControl]="formControlEndDate" (keypress)="validateInput($event)"
            [class.is-invalid]="formControlEndDate.invalid && showEndDateValidator" class="form-control" id="endDate" placeholder="mm/dd/yyyy" name="dp" [(ngModel)]="endDate"
              ngbDatepicker #ed="ngbDatepicker" (focusout) = "endDateOnBlur()" (focus) = "endDateOnFocus()" />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="ed.toggle()" type="button">
                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 20 20" height="23px" viewBox="0 0 20 20" width="23px" fill="#004EAA">
                  <g>
                    <rect fill="none" height="23" width="23" x="0"/>
                  </g>
                  <g>
                    <path d="M15.5,4H14V2h-1.5v2h-5V2H6v2H4.5C3.67,4,3,4.68,3,5.5v11C3,17.32,3.67,18,4.5,18h11c0.83,0,1.5-0.68,1.5-1.5v-11 C17,4.68,16.33,4,15.5,4z M15.5,16.5h-11V9h11V16.5z M15.5,7.5h-11v-2h11V7.5z M7.5,12H6v-1.5h1.5V12z M10.75,12h-1.5v-1.5h1.5V12z M14,12h-1.5v-1.5H14V12z M7.5,15H6v-1.5h1.5V15z M10.75,15h-1.5v-1.5h1.5V15z M14,15h-1.5v-1.5H14V15z"/>
                  </g>
                </svg>
              </button>
            </div>
            <div *ngIf ="showEndDateValidator" class="invalid-tooltip">{{ formControlEndDate.invalid ? "Date is invalid" : undefined }}</div>
          </div>
        </div>
      </form>
    </div>
    <div class="form-inline">
      <app-button buttonType="primary" (click)="generateList()" [disabled] = "formControlStartDate.invalid || formControlEndDate.invalid">Generate
        List</app-button>
    </div>
    <div class="export" style="display: inline-block;margin-left: 10px;">
      <button (click)="exportExcel()" class="interactive-links"><i class="table-material-icons">get_app</i>Export to Excel</button>
    </div>
  </div>
  <div class="form-inline" style="margin-top: 32px;">
    <div style="width: 15%;">


      <app-select-dropdown (onSelect)="setValue($event)">
        <option *ngFor="let item of reportTypes" value="{{item.value}}">
          {{item.name}}
        </option>
      </app-select-dropdown>

    </div>
  </div>

  <div *ngIf="showStatusLabel" style="text-align: left;margin-top: 35px;">
    <span *ngIf="haveRecords" id="lblStatus" style="font-weight: bold;">{{numberOfRecords}} Company(s) listed</span>
  </div>
  <div *ngIf="!haveRecords || !showStatusLabel" class="seperator">
    <div></div>
  </div>

</div>
<div *ngIf="haveRecords">
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col">Company</th>
        <th scope="col">Name</th>
        <th scope="col">Submitted</th>
        <th scope="col">Uploaded</th>
        <th scope="col">Dummy</th>
        <th scope="col">Uploaded User</th>
        <th scope="col">Uploaded Date</th>
        <th scope="col">Submission Date</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of companyList;let row=index">
        <td>{{row+1}}</td>
        <td>{{item.companyno}} </td>
        <td>{{item.companyname}}</td>
        <td>
          <div *ngIf="item.Locked=='FALSE' || item.Locked=='';else submitteddefault">-</div>
          <ng-template #submitteddefault>Y</ng-template>
        </td>

        <td>
          <div *ngIf="item.Uploaded=='FALSE' || item.Uploaded=='';else uploadeddefault">-</div>
          <ng-template #uploadeddefault>Y</ng-template>
        </td>


        <td >
          <div *ngIf="item?.dummyrpt && item.dummyrpt=='True' else notDummy">Y</div>
          <ng-template #notDummy> - </ng-template>
        </td>


        <td>
          <div *ngIf="item.Uploaduser=='';else uploaduserdefault">-</div>
          <ng-template #uploaduserdefault>{{item.Uploaduser}}</ng-template>
        </td>

        <td>
          <div *ngIf="item.UploadDate==null || item.UploadDate=='';else uploaddatedefault">-</div>
          <ng-template #uploaddatedefault>{{item.UploadDate.split(' ')[0]| date: 'MM/dd/yyyy' }}</ng-template>
        </td>
        <td>
          <div *ngIf="item.Submitteddate==null || item.Submitteddate=='';else submitteddatedefault">-</div>
          <ng-template #submitteddatedefault>{{item.Submitteddate.split(' ')[0]| date: 'MM/dd/yyyy'}}</ng-template>
        </td>

      </tr>
    </tbody>
  </table>
</div>
