import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { CompanyService } from '../company.service';
import { ConfirmDialogService } from '../../confirm-dialog/confirm-dialog.service';
import { CommentsModalComponent } from '../comments-modal/comments-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../shared/toast.service';
import { AppService } from 'src/app/app.service';
import {
  createNewPRF,
  resetValidationJobId,
  setPrfLock,
  setValidationLock,
} from '../premiumreport/data-access/premium-report.action';
import { statusList } from './data-access/companylist-model';
import { Store } from '@ngrx/store';
import { resetValidationSummaryData } from '../validation-summary/data-access/validation-summary-actions';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { openCompanyDetailsForCustomer } from './data-access/company-list-actions';
import { DeletecompanyComponent } from './ui/deletecompany/deletecompany.component';

@Component({
  selector: 'app-companylist',
  templateUrl: './companylist.component.html',
  styleUrls: ['./companylist.component.scss'],
})
export class CompanylistComponent implements OnInit, AfterViewInit {
  showModal;
  headerDetailsLoaded: Promise<boolean>;
  yearList: number[] = [];
  currentYear: number;
  companyList: any[] = [];
  companyListForYear: any[] = [];
  showspingetprfyears: boolean = true;
  groupcode: string;
  groupname: string;
  companunoOrg: any;
  custid: any;
  searchyear: number = 0;
  filterStatusList: { name: string; value: string }[];
  selectedStatus: string;
  showSpinnerGetCompanyInfo: boolean = false;
  statusType: string;
  paginatedCompanyList$: Observable<any[]>;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalItems: number;
  isAdmin: boolean;
  isReadOnlyAdmin: boolean;
  searchValue: string = '';
  showPageError: boolean = false;
  pageError: string = '';
  searchType: string = '';
  constructor(
    private store: Store,
    public toastService: ToastService,
    private router: Router,
    private modalService: NgbModal,
    private companyService: CompanyService,
    private confirmDialogService: ConfirmDialogService,
    private appService: AppService
  ) {}

  ngAfterViewInit() {
    // this.AdobelaunchService.EventEndDispatch();
  }
  ngOnInit(): void {
    this.removePrevAlerts();
    this.clearLocalStorage();
    this.isAdmin = this.appService.isAdmin || this.appService.isReadonlyAdmin;
    this.isReadOnlyAdmin = this.appService.isReadonlyAdmin;
    this.searchValue = localStorage.getItem('searchValue') || '';
    this.searchType = localStorage.getItem('searchType') || '';
    this.store.dispatch(setPrfLock({ value: false }));
    this.store.dispatch(setValidationLock({ value: false }));
    this.store.dispatch(resetValidationJobId());
    this.store.dispatch(resetValidationSummaryData());
    this.companyService.getgroupname().subscribe((value) => {
      this.groupname = value;
    });

    this.NavigationStatusReset();
    this.filterStatusList = statusList;
    /* to list the PRF with validating status when navigate from notification button in landing page*/
    if (localStorage.getItem('notificationStatus') !== null) {
      this.selectedStatus = localStorage.getItem('notificationStatus');
      localStorage.removeItem('notificationStatus');
    } else {
      this.selectedStatus = this.filterStatusList[0].value;
    }

    this.getYearsAndProceed();
    this.router.events.subscribe((event: NavigationStart) => {
      if (
        event.navigationTrigger === 'popstate' &&
        this.router.url == '/dboard'
      ) {
        this.router.navigate(['/']);
      }
    });

    this.paginatedCompanyList$ = this.paginate(
      this.companyList,
      this.itemsPerPage,
      this.currentPage
    );
  }

  paginate(
    array: any[],
    itemsPerPage: number,
    currentPage: number
  ): Observable<any[]> {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedArray = array.slice(startIndex, startIndex + itemsPerPage);
    return of(paginatedArray);
  }

  onPageChange(page: number) {
    this.currentPage = page;
    this.paginatedCompanyList$ = this.paginate(
      this.companyList,
      this.itemsPerPage,
      this.currentPage
    );
  }

  initView() {}

  redirectTo(uri: string) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

  deleteCompany(data): any {
    const compno = data.compNo;
    const groupCode = data.groupCode;
    const originalsubmissiondate = data.originalsubmissiondate;
    const modalRef = this.modalService.open(DeletecompanyComponent);
    modalRef.componentInstance.title = 'Delete';
    modalRef.componentInstance.isCommentRequired = !!originalsubmissiondate;
    modalRef.componentInstance.label = 'Do you want to delete the company : ' + compno.toUpperCase() + '?';
    modalRef.componentInstance.deleteConfirmed.subscribe((result: { comment: string; }) => {
      this.companyService
        .deleteCompany(this.currentYear, compno, groupCode, result.comment)
        .subscribe({
          next: (resp) => {
            if (resp['message'] == 'Success') {
              this.companyListForYear = this.companyListForYear.filter(
                (item) => item.compno !== compno
              );
              this.getCompanyForStatus(this.selectedStatus);
              this.toastService.show(resp['status_msg'], {
                classname: 'bg-success text-light',
                delay: 5000,
              });
            }
          },
          error: (error) => {
            this.router.navigate(['/apifailure']);
          }
        });
    });
  }

  onYearChange(year: any) {
    this.currentYear = parseInt(year);
    this.showspingetprfyears = true;
    localStorage.setItem('currentyearcust', this.currentYear.toString());
    localStorage.setItem('year', this.currentYear.toString());
    this.companyService.setYear();
    if (!this.isAdmin) {
      this.getCompanyForYear();
    } else if (this.isAdmin && this.searchValue) {
      this.SearchCompany();
    } else {
      this.getCompanyListForAdmin();
    }
  }
  getCompanyForYear() {
    this.companyService
      .getCompanyList(this.currentYear, this.groupcode)
      .subscribe({
        next: (resp) => this.handleCompanyListResponse(resp),
        error: () => this.router.navigate(['/apifailure'])
      });
  }

  private handleCompanyListResponse(resp: any) {
    if (resp['message'] === 'Success') {
      this.showspingetprfyears = false;
      this.companyList = resp['companylist'];
      this.totalItems = this.companyList.length;
      this.setPagination();

      this.companyList.forEach((element) => {
        element.compname = this.companyService.toTitleCase(element.compname);
        element.owner = this.companyService.toTitleCase(element.owner);
      });

      this.companyListForYear = this.companyList;
      this.getCompanyForStatus(this.selectedStatus);
    }
  }
  getListForAdminOrSearch() {
    this.headerDetailsLoaded = Promise.resolve(true);
    if (this.searchValue !== '' && this.searchType !== '') {
      this.SearchCompany();
    } else {
      this.getCompanyListForAdmin();
    }
  }
  getCompanyListForAdmin() {
    this.companyService.getsubmittedcompanylist(this.currentYear).subscribe({
      next: (resp) => {
        if (resp['message'] == 'Success') {
          this.showspingetprfyears = false;
          this.companyList = resp['companylist'];
          this.totalItems = this.companyList.length;
          this.setPagination();
          this.companyList.forEach((element) => {
            element.compname = this.companyService.toTitleCase(
              element.compname
            );
            element.owner = this.companyService.toTitleCase(element.modifiedby);
            element.status = this.setAdminCompanyStatus(element);
          });
          this.companyListForYear = this.companyList;
          this.getCompanyForStatus(this.selectedStatus);
        }
      },
      error: (error) => {
        this.router.navigate(['/apifailure']);
      }
    });
  }
  setAdminCompanyStatus(element) {
    const statusMap = {
      Submitted: 'Locked',
      Uploaded: 'Verified',
      Validating: 'Validating',
      Uploading:'Uploading',
      notStarted:'notStarted'
    };

    return statusMap[element.status] || '';
  }
  SearchCompany() {
    this.companyService
      .searchCompany(this.currentYear, this.searchType, this.searchValue)
      .subscribe({
        next: (resp) => {
          if (resp['message'] == 'Success') {
            this.showspingetprfyears = false;
            this.companyList = resp['companylist'];
            this.totalItems = this.companyList.length;
            this.setPagination();
            this.companyList.forEach((element) => {
              element.compname = this.companyService.toTitleCase(
                element.compname
              );
              element.owner = this.companyService.toTitleCase(
                element.modifiedby
              );
              element.status = this.setAdminCompanyStatus(element);
            });
            this.companyListForYear = this.companyList;
            this.getCompanyForStatus(this.selectedStatus);
          }
        },
        error: (error) => {
          this.router.navigate(['/apifailure']);
        }
      });
  }
  getCompanyForStatus(status: any): void {
    if (
      this.groupcode !== '' &&
      this.groupcode !== null &&
      !this.showPageError
    ) {
      if (typeof status === 'object') {
        status = status.value;
      }
      this.selectedStatus = status;
      if (status === 'All') {
        this.companyList = [...this.companyListForYear];
      } else {
        this.companyList = this.companyListForYear.filter(
          (element) => element.status === status
        );
      }
      this.totalItems = this.companyList.length;
      this.setPagination();
    } else {
      console.log('Invalid group code: ' + this.groupcode);
    }
  }

  openCompanyInformation(data) {
    const companyId = data.compNo;
    const year = this.currentYear;
    const prfNumber = data.compNo;
    const prfName = data.compName;
    const groupCode = data.groupCode;
    const prfStatus = data.prfStatus; 
    this.showSpinnerGetCompanyInfo = true;
    localStorage.setItem('isCreateComapny', 'false');
    if (companyId !== '') {
      this.companyService.setCompanyInformation('inprogress');
      this.store.dispatch(
        openCompanyDetailsForCustomer({
          groupCode: groupCode,
          companyId: companyId,
          currentYear: year,
          prfNumber: prfNumber,
          prfName: prfName,
          prfStatus: prfStatus,
        })
      );
    } else {
      this.showSpinnerGetCompanyInfo = false;
      this.store.dispatch(
        createNewPRF({
          groupCode: this.groupcode,
          companyId: companyId,
          currentYear: year,
          prfNumber: prfNumber,
          prfName: prfName,
        })
      );
    }
  }

  openComment(comment: string) {
    const modalRef = this.modalService.open(CommentsModalComponent);
    modalRef.componentInstance.comment = comment;
    modalRef.componentInstance.isSubmissionComment = false;
  }

  delete(id: number) {
    alert('');
  }

  navigateToValidationSummary(data) {
    this.companyService.navigateToValidationSummary(
      data.groupCode,
      data.compNo,
      this.currentYear,
      data.compNo,
      data.compName
    );
  }
  searchonclick(searchValue) {
    if (searchValue.length !== 0) {
      this.validateSearchValue(searchValue);
      this.onPageChange(1);
    } else {
      this.getCompanyListForAdmin();
    }
  }
  getYearsAndProceed() {
    this.companyService
      .getPrfYears('')
      .pipe(
        tap((resp) => {
          if (resp['message'] === 'Success') {
            this.yearList = resp['prfyearlist'];
            this.currentYear = this.yearList[0];
            this.searchyear = parseInt(localStorage.getItem('currentyearcust'));
            if (this.searchyear) {
              this.currentYear = this.searchyear;
            }
            localStorage.setItem(
              'currentyearcust',
              this.currentYear.toString()
            );
            localStorage.setItem('year', this.currentYear.toString());
            this.companyService.setYear();
          }
        }),
        switchMap(() => {
          if (!this.isAdmin) {
            return this.companyService.getcustid().pipe(
              switchMap((value) => {
                if (value !== '' && value !== null) {
                  this.custid = value;
                  return this.companyService.getusergroup(this.custid).pipe(
                    tap((resp) => {
                      if (resp['message'] === 'Success' && !resp['usergroupinfo'][0].overflow_status) {
                        localStorage.setItem(
                          'groupcode',
                          resp['usergroupinfo'][0].groupid
                        );
                        localStorage.setItem(
                          'groupname',
                          resp['usergroupinfo'][0].groupname
                        );
                        this.companyService.getLocalStorage();
                        this.groupcode = resp['usergroupinfo'][0].groupid;
                        this.groupname = resp['usergroupinfo'][0].groupname;
                        this.headerDetailsLoaded = Promise.resolve(true);
                        this.getCompanyForYear();
                      } else if (resp['message'] === 'Success' && resp['usergroupinfo'][0].overflow_status) {
                        this.router.navigate(['/overflowcompany']);
                      }
                    }),
                    catchError((error) => {
                      this.router.navigate(['/apifailure']);
                      return EMPTY;
                    })
                  );
                }
              }),
              catchError((error) => {
                this.router.navigate(['/apifailure']);
                return EMPTY;
              })
            );
          } else {
            return of(this.getListForAdminOrSearch());
          }
        }),
        catchError((error) => {
          this.router.navigate(['/apifailure']);
          return EMPTY;
        })
      )
      .subscribe({
        next: () => {},
        error: (error) => {
          this.router.navigate(['/apifailure']);
        }
      });
  }

  onenter(e) {
    const value = e.inputValue;
    if (value.length == 0) {
      this.pageError = '';
      this.showPageError = false;
      this.clearSearchValue();
      this.showspingetprfyears = true;
      this.getCompanyListForAdmin();
    }
    if ((e.key === 'Enter' || e.keyCode === 13) && value.length !== 0) {
      this.validateSearchValue(value);
    }
  }

  validateSearchValue(value: string) {
    this.pageError ='';
    this.showPageError = false;
    this.searchValue = value;
    this.showspingetprfyears = true;
    if (value.length === 4 && /^\d{4}$/.test(value)) {
      this.searchType = 'group';
      this.setSearchValue();
      this.SearchCompany();
    } else if (
      value.length > 4 &&
      /^[a-z0-9]+$/i.test(value) &&
      /^\d+$/.test(value.substring(0, 4))
    ) {
      this.searchType = 'companyno';
      this.setSearchValue();
      this.SearchCompany();
    } else {
      this.clearSearchValue();
      this.pageError =
        'Invalid search criteria. Search with either Group Code or PRF Number';
      this.showPageError = true;
    }
  }
  clearSearchValue() {
    this.searchValue = ''
    this.searchType = ''
    localStorage.setItem('searchValue', '');
    localStorage.setItem('searchType', '');
  }
  setSearchValue() {
    localStorage.setItem('searchValue', this.searchValue);
    localStorage.setItem('searchType', this.searchType);
  }

  setPagination() {
    this.currentPage = 1;
    this.paginatedCompanyList$ = this.paginate(
      this.companyList,
      this.itemsPerPage,
      this.currentPage
    );
  }
  openGroup() {
    this.router.navigate(['/addcompanymodal']);
  }
  addNewForm() {
    if (this.isAdmin) {
      this.openGroup();
    } else {
      const data = {
        compNo: '',
        compName: '',
        groupCode: '',
        prfStatus:''
      };
      this.openCompanyInformation(data);
    }
  }
  removePrevAlerts() {
    for (var i = this.toastService.toasts.length - 1; i >= 0; i--) {
      this.toastService.remove(this.toastService.toasts[i]);
    }
  }
  clearLocalStorage() {
    localStorage.setItem('companyId', '');
    localStorage.setItem('year', '');
    localStorage.removeItem('isLocked');
    localStorage.removeItem('lockForValidation');
    localStorage.setItem('groupreportenabled', '');
    localStorage.setItem('prfName', '');
    localStorage.removeItem('prfNumber');
    localStorage.removeItem('prfStatus');
  }
  NavigationStatusReset() {
    this.companyService.companyInformationCompleted.next(false);
    this.companyService.companyInformationInProgress.next(false);

    this.companyService.premiumreportCompleted.next(false);
    this.companyService.premiumreportInProgress.next(false);

    this.companyService.groupreportingCompleted.next(false);
    this.companyService.groupreportingInProgress.next(false);

    this.companyService.annualstatementCompleted.next(false);
    this.companyService.annualstatementInProgress.next(false);

    this.companyService.userdefinedcolsCompleted.next(false);
    this.companyService.userdefinedcolsInProgress.next(false);

    this.companyService.userdefineddataCompleted.next(false);
    this.companyService.userdefineddataInProgress.next(false);

    this.companyService.commentsCompleted.next(false);
    this.companyService.commentsCompleted.next(false);

    this.companyService.PriorYearComparisonCompleted.next(false);
    this.companyService.disablePrf.next(true);
  }
}
