import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../app.service'
import { Role } from '../shared/models/user-model'
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  title = 'Welcome to Ignite UI for Angular!';
  constructor(private appService: AppService, private router: Router) { }

  ngOnInit() {
    if (this.appService.hasRole(Role.Admin)) {
      this.router.navigate(['../admin/dashboard'])
    }
  }

}
