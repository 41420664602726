import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, Route, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import {AppService} from './app.service'
import {Role} from './shared/models/user-model'
import {NotFoundComponent} from './not-found/not-found.component'
@Injectable()
export class AuthGuard  {

    constructor(private oauthService: OAuthService, private router: Router, private appService:AppService) {}
  
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      // console.log("In can activate")
      if (this.oauthService.hasValidAccessToken()) {
        // console.log("Not has valide access token")
        const roles = route.data.roles as Role[];
        // console.log("got roles")
        // console.log(roles)
        if (roles && !roles.some(r => this.appService.hasRole(r))) {
            // console.log("not found")
            this.router.navigate(['/notfound']);
            return false;
        } else {
          return true; 
        }       
      }

      // console.log("calling loadDiscoveryDocumentAndLogin")
      // this.oauthService.loadDiscoveryDocumentAndLogin();
      this.oauthService.loadDiscoveryDocumentAndLogin({
        disableOAuth2StateCheck: true
      })
      return true;
    }

    canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
      // console.log("In canLoad")
      if (!this.oauthService.hasValidAccessToken()) {
        // console.log("Not hasValidAccessToken")
          return false;
      }
      const roles = route.data && route.data.roles as Role[];
      // console.log("Got roles")
      // console.log(roles)
      if (roles && !roles.some(r => this.appService.hasRole(r))) {
        // console.log("redirecting to notfound")
        this.router.navigate(['/notfound']);
        //this.router.navigateByUrl('/notfound', { skipLocationChange: true });
        
          return false;
      }
      // console.log("Return true")
      return true;
  }
  }
