import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
})
export class IconComponent {
  @Input() icon: 'reset-to-default' | 'reset-to-existing-data' =
    'reset-to-default';
  @Input() xmlns: string = 'http://www.w3.org/2000/svg';
  @Input() height: string = '18px';
  @Input() width: string = '18px';
  @Input() fill: string = '#004EAA';
  @Input() resetToExistingValueViewBox: string = '0 -960 960 960';
  @Input() resetToDefaultViewBox: string = '0 0 24 24';

  getViewBox(): string {
    return this.icon === 'reset-to-default'
      ? this.resetToDefaultViewBox
      : this.resetToExistingValueViewBox;
  }
}
