import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CompanyService } from 'src/app/company/company.service';
import {
  STATUSMAPPER,
  ValidationDetailContent,
  ValidationInput,
  ValidationResponse,
  ValidationSubmitInput,
  ValidationSummaryBase,
  ValidationSummaryItem,
  ValidationSummaryResponse,
  ValidationUnlockInput,
} from './validation-summary-model';

@Injectable({
  providedIn: 'root',
})
export class ValidationSummaryService {
  constructor(
    private http: HttpClient,
    private companyService: CompanyService
  ) {}

  baseUrl = environment.apiUrl;

  loadAllValidationSummaryItems(jobId: any): Observable<ValidationSummaryBase> {
    if (jobId === '') {
      return this.getDefaultValidationSummary();
    }

    const data: any = {
      jobId: jobId,
      companyno: this.companyService.companyId,
    };

    return this.http
      .post<ValidationSummaryResponse>(
        `${this.baseUrl}getvalidationsummarylist`,
        data
      )
      .pipe(
        map((response) => this.mapResponseToValidationSummaryBase(response)),
        catchError((error) => throwError(() => new Error(error.message)))
      );
  }

  private getDefaultValidationSummary(): Observable<ValidationSummaryBase> {
    const validationSummaryBaseData: ValidationSummaryBase = {
      isComplete: false,
      data: [],
      groupCode: '',
      isDummyReport: false,
      companyNumber: '',
      year: null,
      numberOfCompanyValidationMessage: null,
      emailCount:0,
      lastEmailSendDate:''
    };

    return of(validationSummaryBaseData);
  }

  private mapResponseToValidationSummaryBase(
    response: ValidationSummaryResponse
  ): ValidationSummaryBase {
    const responseData = response.data;

    var header = responseData.find((x) => x['SK'] == 'PRF#' + x['companyno']);

    const validationSummaryItemDataList: ValidationSummaryItem[] = responseData
      .slice(1)
      .map((item) => this.mapResponseItemToValidationSummaryItem(item));

    const validationSummaryBaseData: ValidationSummaryBase = {
      isComplete: header['Completed'],
      data: validationSummaryItemDataList,
      groupCode: header['groupcode'],
      isDummyReport: header['dummyreport'],
      companyNumber: header['companyno'],
      year: header['Year'],
      numberOfCompanyValidationMessage: null,
      emailCount:header['Emailcounter'] || 0,
      lastEmailSendDate:header['Lastemailsenddate'] || ''
    };

    return validationSummaryBaseData;
  }

  private mapResponseItemToValidationSummaryItem(
    item: any
  ): ValidationSummaryItem {
    const validationDetailContentData: ValidationDetailContent = {
      schema:
        Array.isArray(item.Message) && item.Message.length > 0
          ? item.Message[0].schema
          : null,
      type:
        Array.isArray(item.Message) && item.Message.length > 0
          ? item.Message[0].type
          : null,
      value:
        Array.isArray(item.Message) && item.Message.length > 0
          ? item.Message[0].value
          : null,
    };

    const updatedRuleDescription = item.Rule_des.replace(/\\n/g, '');

    const validationSummaryItemData: ValidationSummaryItem = {
      pk: item.PK,
      sk: item.SK,
      content: validationDetailContentData,
      ruleDescription: updatedRuleDescription,
      ruleName: item.Rule_name,
      ruleId: item.Rule_id,
      status: STATUSMAPPER[item.Status],
      isBlocker: item.Blocker,
      feedback: item.feedback,
      isInFeedbackUpdatedState:
        STATUSMAPPER[item.Status] !== STATUSMAPPER[0] &&
        item.feedback !== null &&
        item.feedback !== '' &&
        item.feedback !== undefined,
    };

    return validationSummaryItemData;
  }

  initiateValidationOnSubmit(inputData: ValidationInput): Observable<string> {
    const { prfyear, groupcode, companyno, prfuser, dummyrpt } = inputData;
    const data: any = {
      prfyear,
      groupcode,
      companyno,
      prfuser,
      dummyrpt,
    };

    return this.http.post<any>(`${this.baseUrl}submitforvalidation`, data).pipe(
      map((response: ValidationResponse) => response.data.jobid),
      catchError((error) => throwError(() => new Error(error)))
    );
  }

  unlockToEdit(inputData: ValidationUnlockInput): Observable<string> {
    const { year, groupCode, companyNumber, prfuser } = inputData;
    const data = {
      prfyear: year,
      groupcode: groupCode,
      companyno: companyNumber,
      prfuser: prfuser,
    };

    return this.http
      .post<any>(`${this.baseUrl}unlockvalidationstatus`, data)
      .pipe(
        map((response: any) => response),
        catchError((error) => throwError(() => new Error(error)))
      );
  }

  reSubmit(inputData: ValidationSubmitInput): Observable<string> {
    const { year, groupCode, companyNumber, dummyrpt, prfuser } = inputData;
    const data = {
      prfyear: year,
      groupcode: groupCode,
      companyno: companyNumber,
      dummyrpt: dummyrpt,
      prfuser: prfuser,
    };

    return this.http.post<any>(`${this.baseUrl}submitform`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => throwError(() => new Error(error)))
    );
  }

  updateFeedback(feedbackData: any) {
    const { feedback, pk, sk, prfuser, year, groupCode, companyNumber } =
      feedbackData;

    const data: any = {
      feedback,
      pk,
      sk,
      prfuser: prfuser,
      prfyear: year,
      groupcode: groupCode,
      companyno: companyNumber,
    };
    return this.http
      .post<ValidationSummaryResponse>(
        `${this.baseUrl}updatevalidationfeedback`,
        data
      )
      .pipe(
        map((response) => response),
        catchError((error) => throwError(() => new Error(error.message)))
      );
  }

  loadNumberOfCompanyValidationMessage(
    jobId: any,
    commonInput: { year: any; companyNumber: string }
  ): Observable<any> {
    const { year, companyNumber } = commonInput;

    // Check if either year or companyNumber is not provided
    if (year === null || companyNumber === '') {
      return of(null);
    }

    const data: any = {
      jobid: jobId,
      prfyear: year,
      companyno: companyNumber,
    };

    return this.http
      .post<any>(`${this.baseUrl}validatornumberofcompanies`, data)
      .pipe(
        map((response) =>
          this.constructNumberOfCompaniesValidationMessage(response)
        ),
        catchError((error) => throwError(() => new Error(error)))
      );
  }

  constructNumberOfCompaniesValidationMessage(response) {
    const messageData = response?.data?.Message?.[0]?.value?.data;

    const previousYearCompanyCount = messageData[0]?.[1];
    const currentYearCompanyCount = messageData[1]?.[1];
    const previousYear = messageData[0]?.[0];
    const currentYear = messageData[1]?.[0];

    if (
      typeof previousYearCompanyCount !== 'number' ||
      typeof currentYearCompanyCount !== 'number'
    ) {
      return null;
    }
    const previousYearCompanyText = previousYearCompanyCount === 1 ? 'Company' : 'Companies';
    const currentYearCompanyText = currentYearCompanyCount === 1 ? 'Company' : 'Companies';

    return `${currentYearCompanyCount} ${currentYearCompanyText} submitted in ${currentYear} PRF, ${previousYearCompanyCount} ${previousYearCompanyText} in ${previousYear} PRF`;
  }

  mapResponseItemToWarningValidationSummaryItem(response: ValidationSummaryItem[]){
    const responseData = response;
  
    const validationSummaryItemDataList: ValidationSummaryItem[] = responseData
      .map((item) => this.mapResponseItemToValidationSummaryItem(item));
  
      return validationSummaryItemDataList
  }
}
