<!-- The pagination controls -->
<nav aria-label="Page navigation" *ngIf="!showCompanyLoader && !showspingetprfyears">
    <ul class="pagination">
      <li class="page-item" [class.disabled]="currentPage === 1">
        <a class="page-link" (click)="setPage(currentPage - 1)" aria-disabled="currentPage === 1">Previous</a>
      </li>
      <li *ngFor="let page of pages" class="page-item" [class.active]="page === currentPage">
        <a *ngIf="page > 0" class="page-link" (click)="setPage(page)">{{ page }}</a>
        <span *ngIf="page === -1" class="page-link disabled">...</span>
      </li>
      <li class="page-item" [class.disabled]="currentPage === totalPages || totalPages == 0">
        <a class="page-link" (click)="setPage(currentPage + 1)" aria-disabled="currentPage === totalPages">Next</a>
      </li>
    </ul>
</nav>
  