<app-modal-loading-screen *ngIf="spinnermodal">Loading...</app-modal-loading-screen>
<app-modal-loading-screen *ngIf="spinnersave">Saving User Defined columns</app-modal-loading-screen>
<app-modal-loading-screen *ngIf="spinneradd">Adding User Defined columns</app-modal-loading-screen>
<div *ngIf="isLoaded" class="modal" tabindex="-1" role="dialog" style="display:block!important">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="pagetitle">{{title}}</h4>
        <span class="table-material-icons" (click)="activeModal.dismiss('Cross click')"
          style="cursor: pointer;font-size: 24px;">close</span>

      </div>
      <div *ngIf="isocolumnsloadded | async">
        <div class="modal-body">
          <form [formGroup]="addEditUsedColsForm" (ngSubmit)="onsubmit()">

            <input type="hidden" formControlName="ColNo" />
            <div class="PageLevelError" *ngIf="!formValid">{{error}}</div>

            <label class="lib-text-field">
              <input [readonly]='disable' placeholder=" " maxlength="70" formControlName="ColName1" />
              <span class="lib-placeholder">Heading Line 1</span>

            </label>
            <label class="lib-text-field">
              <input [readonly]='disable' placeholder=" " maxlength="70" formControlName="ColName2" />
              <span class="lib-placeholder">Heading Line 2</span>

            </label>
            <label class="lib-text-field">
              <input [readonly]='disable' placeholder=" " maxlength="70" formControlName="ColName3" />
              <span class="lib-placeholder">Heading Line 3</span>

            </label>
            <label class="lib-text-field">
              <input [readonly]='disable' placeholder=" " maxlength="70" formControlName="ColName4" />
              <span class="lib-placeholder">Heading Line 4</span>

            </label>

            <div style="clear: both" class="optionHeader">
              Is this line an ISO Program?
            </div>
            <div class="form-check form-check-inline">
              <input id="ISOProgram" class="form-check-input" type="radio" value="on" formControlName="ISOCol"
                checked />
              <label for="ISOProgram" class="form-check-label"> Yes </label>
            </div>
            <div class="form-check form-check-inline">
              <input id="noISOProgram" class="form-check-input" type="radio" value="off" formControlName="ISOCol" />
              <label for="noISOProgram" class="form-check-label"> No</label>
            </div>
            <div style="clear: both;padding-top: 10px;" class="optionHeader">
              Matching ISO Column
            </div>
            <app-select-dropdown (onSelect)="getIsoColumn($event)">
              <option *ngFor="let col of matchingisocolumns; let i = index" value="{{col.Number}}"
                [attr.selected]="i == selectedIndex ? true : false">
                {{col.Value}}
              </option>
            </app-select-dropdown>

          </form>
        </div>
        <div class="modal-footer">
          <app-button buttonType="primary" type="submit" (click)="onsubmit()" style="width: 100%;">{{saveButtonText}}
          </app-button>

        </div>
      </div>
    </div>
  </div>
</div>