<div *ngIf="!isReadOnlyAdmin" style="float: right">
  <app-button size="medium" buttonType="primary" (click)="addNewForm()"
    >Add New Form</app-button
  >
</div>
<div class="pagetitle">
  <ng-container *ngIf="isAdmin; else customerTitle">
    Premium Report Administration : {{ currentYear }}
  </ng-container>
  <ng-template #customerTitle>
    <ng-container *ngIf="custid !== '' && custid !== null">
      {{ groupcode }} - {{ groupname }} : PRF List
    </ng-container>
  </ng-template>
</div>
<div class="pagetitlesubtext">
  Select a PRF number from the table to retrieve a premium report.
</div>
