export interface Companylisttable {
  comment: string;
  compname: string;
  compno: string;
  modifiedby: string;
  status: string;
  submissiondate: string;
  uploaddate: string;
  groupcode: any;
  prfyear: number;
  isSelected: boolean;
  selectDisabled: boolean;
  uploadmessage: string;
  validationJobId : string;
  naic: string;
  amBest: string;
  groupreporting: boolean;
}

export const statusList: string[] = [
  'All',
  'Open',
  'Validating',
  'Submitted',
  'Uploading',
  'Uploaded',
];

export interface ListTypeList {
  value: string;
  label: string;
  option?: string;
}

export const listTypeListData: ListTypeList[] = [
  { value: 'mylist', label: 'My List', option: 'mylist' },
  { value: 'all', label: 'All', option: 'all' },
];

export interface AdminCompanyListState {
  companyList: Companylisttable[];
  originalCompanyList: Companylisttable[];
  isReadOnlyAdmin: boolean;
  yearsList: any[];
  listTypeSelected: ListTypeList;
  showCompanyLoader: boolean;
  showUploadLoader: boolean;
  searchType: string;
  searchValue: string;
  currentPrfYear: number;
  currentYearAdmin: string;
  yearsLoaded: boolean;
  filterStatusSelected: string;
  filterStatusSelectedIndex: string;
  filterStatusSelectedLabel: string;
  filterStatusList: string[];
  filterListType: ListTypeList[];
  showPageError: boolean;
  pageError: string;
  currentYear: number;
  companyId: string;
  groupCode: string;
  prfNumber: string;
  prfName: string;
  allSelected: boolean;
  deleteLoader: boolean;
  selectedQuarter: number;
  corpId: number;
  selectedYear: number;
  showNoDataMessage: boolean;
}
