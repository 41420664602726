import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as ValidationSummaryActions from '../../data-access/validation-summary-actions';
import { ValidationSummaryItem } from '../../data-access/validation-summary-model';
import { CompanyService } from 'src/app/company/company.service';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { AlertmodalComponent } from 'src/app/alertmodal/alertmodal.component';
import { selectAllFeedbackValid, selectValidationSummaryIsComplete } from '../../data-access/validation-summary-selectors';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-validation-detail-content',
  templateUrl: './validation-detail-content.component.html',
  styleUrls: ['./validation-detail-content.component.scss'],
})
export class ValidationDetailContentComponent {
  constructor(private store: Store, private companyService: CompanyService,private modalService: NgbModal) {}
  @Input() validationItem: ValidationSummaryItem;
  @Input() readOnly: boolean;
  private destroy$ = new Subject<void>();
  isPopupOpen = false; // Flag to track popup state
  ngOnDestroy(): void {
    // Unsubscribe when the component is destroyed
    this.destroy$.next();
    this.destroy$.complete();
  }
  get dataType(): string {
    return this.validationItem.content.type;
  }

  get dataValue(): any {
    return this.validationItem.content.value;
  }

  isValidationSummaryFeedbackSaveReadonly(): boolean {
    return !this.validationItem.feedback;
  }

  cancel() {
    this.validationItem.feedback = '';
  }

  updateFeedback() {
    const feedbackData = {
      feedback: this.validationItem.feedback,
      pk: this.validationItem.pk,
      sk: this.validationItem.sk,
      prfuser: this.companyService.userid,
    };
    this.store.dispatch(
      ValidationSummaryActions.updateFeedback({ feedbackData })
    );
    if (!this.isPopupOpen) {
      this.showPopupIfAllFeedbackValid();
    }
   }
   
   showPopupIfAllFeedbackValid() {
    combineLatest([
      this.store.select(selectAllFeedbackValid),
      this.store.select(selectValidationSummaryIsComplete),
    ])
      .pipe(
        takeUntil(this.destroy$),
        takeWhile(
          ([isAllFeedbackValid, isValid]) => !isAllFeedbackValid || !isValid,
          true
        )
      )
      .subscribe(([isAllFeedbackValid, isValid]) => {
        if (isAllFeedbackValid && isValid && !this.isPopupOpen) {
          this.isPopupOpen = true; // Set flag to true to prevent further popups
  
          const modalRef = this.modalService.open(AlertmodalComponent);
          modalRef.componentInstance.title = 'Complete Submission';
          modalRef.componentInstance.message =
            'Once you have provided a comment for all of the validation warnings that were triggered, please click on "<b>Complete Submission</b>" to finalize your premium report form.';
  
          // Reset the flag when the modal is closed
          modalRef.result.finally(() => {
            this.isPopupOpen = false;
          });
        }
      });
    }
}
