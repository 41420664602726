import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { CommentsModalComponent } from 'src/app/company/comments-modal/comments-modal.component';
import { CompanyService } from 'src/app/company/company.service';
import { environment } from 'src/environments/environment';
import { AppService } from '../app.service';
import * as GridActions from '../company/grid-configuration/data-access/grid-config.actions';
import { hasHiddenPremiumData, isLockForValidationSelector, isPrfLocked, validationSummaryJobIdSelector } from '../company/premiumreport/data-access/premium-report.selectors';
import * as ValidationSummaryActions from '../company/validation-summary/data-access/validation-summary-actions';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog.service';
import { ToastService } from '../shared/toast.service';

import { Observable } from 'rxjs';
import { selectIsDummyReport } from '../company/validation-summary/data-access/validation-summary-selectors';

@Component({
  selector: 'app-reportsubmission',
  templateUrl: './reportsubmission.component.html',
  styleUrls: ['./reportsubmission.component.scss']
})
export class ReportsubmissionComponent implements OnInit {

  prfYear: number;
  prfNumber: string;
  prfName: string;
  groupCode: string;
  compno: any;
  show: boolean = true;
  spinnersave = false;
  spinnerredirect = false;
  msg: string = "";
  msg_p1: string = "";
  msg_p2: string = "";
  href: any;
  comments: string;
  isLocked: boolean;
  lockForValidation: boolean;
  isReadOnly: boolean;
  isReadOnlyAdmin: boolean;
  isAdmin: boolean;
  mailid: string;
  isSubmissionCompleted: boolean;
  isLoaded:boolean = false;
  isDummy:boolean = false;
  hasHiddenPremium$:Observable<boolean> = this.store.select(hasHiddenPremiumData);
  validationSummaryJobId$: Observable<string> = this.store.select(validationSummaryJobIdSelector);
  isLockForValidation$ = this.store.select(isLockForValidationSelector);
  isPrfLocked$ = this.store.select(isPrfLocked);
  selectIsDummyReport$ = this.store.select(selectIsDummyReport);

  constructor(public store: Store,public activeModal: NgbActiveModal, private companyService: CompanyService, private appService: AppService,
    private modalService: NgbModal, private router: Router, public toastService: ToastService, private confirmDialogService: ConfirmDialogService) { }

  ngOnInit(): void {
    this.removePrevAlerts();
    this.compno = this.companyService.companyId;
    this.prfYear = this.companyService.currentYear;
    this.prfNumber = this.companyService.prfNumber;
    this.prfName = this.companyService.prfName;
    this.companyService.getgroup().subscribe((value) => {
      this.groupCode = value;
    });
    this.isLocked = this.companyService.isEditLocked;
    this.lockForValidation = this.companyService.lockForValidation;
    this.isReadOnlyAdmin = this.appService.isReadonlyAdmin;
    this.isReadOnly = this.isLocked || this.isReadOnlyAdmin;
    this.isAdmin = this.appService.isAdmin;
    this.mailid = `${environment.prfmailid}`;
    this.loadFinalSubmissionview();
    setTimeout(()=>{this.isLoaded=true;},500);
  }

  loadFinalSubmissionview() {
    this.companyService.getSubmissionView(this.prfYear, this.groupCode, this.compno).subscribe({
      next: (resp) => {
        if (resp && resp["message"] == "Success") {
          this.isDummy = resp?.['stat']?.DUMMYREPORTING=='True';
          this.show = false;
          if (resp["sub_view"]) {
            var splitted = resp["sub_view"].split('comments');
            this.msg_p1 = splitted[0];
            this.msg_p2 = splitted[1];
            const link = '<a id="comment" class="link interactive-links">comments</a>'
            // var msg = '<p *ngIf="!isReadOnly" class="title-m">You cannot make changes once you submit the Premium Report Form!</p>'
            this.msg = this.msg_p1 + link + this.msg_p2;
            this.loadComments();
          }
        }
      },
      error: (error) => {
        this.show = false;
        this.activeModal.close(error);
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    });
  }

  openComments(evt) {
    if (evt.target.innerText == "comments") {
      const modalRef = this.modalService.open(CommentsModalComponent);
      modalRef.componentInstance.title = "Comment";
      modalRef.componentInstance.isSubmissionComment = true;
      modalRef.componentInstance.submissionComment = this.comments;

      modalRef.result.then((result) => {
        if (result != "Close") {
          this.saveRecords(result.data);
        }
      });
    }
  }

  saveRecords(subcomments) {
    this.show = true;
    this.toastService.remove(this.toastService.toasts[0]);
    this.companyService.addComment(this.prfYear, this.compno, subcomments).subscribe({
      next: (resp) => {
        if (resp["message"] == "Success") {
          this.show = false;
          this.toastService.show(resp["status_msg"], { classname: 'bg-success text-light', delay: 3000 });
          this.loadComments();
        }
      },
      error: (error) => {
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    });
  }

  loadComments() {
    this.companyService.getComments(this.prfYear, this.compno, this.groupCode).subscribe({
      next: (resp) => {
        if (resp["message"] == "Success") {
          this.comments = resp["comment"];
        }
      },
      error: (error) => {
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    });
  }

  handleShowHiddenCells() {
    this.spinnerredirect = true;
    this.store.dispatch(GridActions.applyGridConfigWithExistingData());
    setTimeout( ()=> {
      this.activeModal.close('withNavigateToPremiumReport');
    }, 2000);
  }

  submitForValidation() {
    this.spinnersave = true;

    const inputData = {
      prfyear: this.prfYear,
      groupcode: this.groupCode,
      companyno: this.compno,
      prfuser: this.companyService.userid,
      dummyrpt: this.isDummy,
    }
    this.store.dispatch(ValidationSummaryActions.initiateValidationOnAfterSubmit({
      inputData
    }));
    this.isSubmissionCompleted = true;
    this.spinnersave = false;

  }

  submit() {
    this.submitForValidation();
  }

  removePrevAlerts(){
    for (var i = this.toastService.toasts.length-1; i >= 0; i--) {
      this.toastService.remove(this.toastService.toasts[i]);
    }
  }
}
