import { Component } from '@angular/core';

@Component({
  selector: 'hidden-config-warning',
  templateUrl: './hidden-config-warning-popup.component.html',
  styleUrls: ['./hidden-config-warning-popup.component.scss']
})
export class HiddenConfigWarningPopupComponent {

}
