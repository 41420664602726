<!-- <div class="lib-footer">
    <footer class="footer">
      <div class="lib-foot-container">
        <div>
          <div class="lib-foot-logo">
            <div>
              <img
                src="../../assets/img/logo-one-verisk.png"
                alt=""
                height="24"
              />
            </div>
          </div>
          <div class="lib-foot-content">
            <p class="copyright">
              Copyright &copy; {{currentYear}} Insurance Services Office, Inc. All rights
              reserved.
            </p>
            <p>
              <span *ngFor="let list of footerList; let i = index">
                <span *ngIf="i > 0">&nbsp;|&nbsp;</span>
                <a href="{{ list.url }}" target="_blank" rel="noopener noreferrer">{{ list.title }} </a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  </div>

   -->
<div class="lib-footer">
  <footer class="footer">
    <div class="lib-foot-container">
      <div class="mstrFooterWrapper " id="mstrWeb_footer" name="mstrWeb_footer" iframe="true">
        <div class="footerMargin">
          <div id="footer">
            <!-- Text paragraph-->
            <!--Copyright in footer of page-->
            <li class="bucket">
              <div class="cpwrite">
                Copyright © {{currentYear}} Insurance Services Office, Inc. All rights reserved.
              </div>
              <!--Footer links for corporate Home, terms And Conditions, privacy And Policy and contact Us-->
              <div class="ftlnks interactive-links">
                <a id="lnkcorporateHome" href={{_corporateHomeLink}}>Verisk Corporate Home</a> |
                <a id="lnktermsAndConditions" href={{_termsAndConditionsLink}}>Terms and Conditions</a> |
                <a id="lnkprivacyAndPolicy" href={{_privacyAndPolicyLink}}>Privacy and Security Policy</a> |
                <a id="lnkcontactUs" href={{_contactUsLink}}>Contact Us</a>
              </div>
            </li>
            <li class="bucket">
              <div class="VeriskLogo">
                <img src="../../assets/img/Verisk_logo_BLK.svg" alt="official Verisk logo" width="15%" />
              </div>
            </li>

          </div>
        </div>
      </div>
    </div>
  </footer>
</div>

    <style>
      #footer {
        background-color: #ffffff;
        height: 50px;
        font-size: 14px;
        color: #8a8a8a;
        font-family: Roboto, sans-serif;
      }

      .cpwrite {
        color: #333;
      }

      .footerMargin #footer a:link {
        color: #006BA6;
        text-decoration: none;
      }

      .footerMargin {
        background-position: 99% 18px;
      }

      .bucket {
        list-style: none;
        width: 48%;
        display: inline-block;
        vertical-align: middle;
        margin-top: 10px;
      }

      .ftlnks a:visited {
        text-decoration: none;
      }

      .VeriskLogo {
        text-align: right;
      }
    </style>

