import { Directive, Input, ElementRef, Renderer2, HostListener } from '@angular/core';
import { Sort } from '../admin/table-sort';

@Directive({
    selector: '[appSort]'
})
export class SortDirective {

    @Input() appSort: Array<any>;

    constructor(private renderer: Renderer2, private targetElem: ElementRef) { }

    @HostListener("click")

    sortData() {
        const sort = new Sort();

        const elem = this.targetElem.nativeElement;

        const order = elem.getAttribute("data-order");

        const property = elem.getAttribute("data-name");

        if (order === "desc") {
            this.appSort.sort(sort.StartSort(property, order));
            elem.setAttribute("data-order", "asc");
        }
        else {
            this.appSort.sort(sort.StartSort(property, order));
            elem.setAttribute("data-order", "desc");
        }
    }

}