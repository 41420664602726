import { Injectable } from '@angular/core';

import { CompanyinformationComponent } from "../company/companyinformation/companyinformation.component"
import { AnnualstatementComponent } from "../company/annualstatement/annualstatement.component"
import { GroupreportingComponent } from '../company/groupreporting/groupreporting.component';
import { UserdefineddataComponent } from '../company/userdefineddata/userdefineddata.component';
import { CommentsComponent } from '../company/comments/comments.component'
import { UnsavedmsgModalComponent } from '../unsavedmsg-modal/unsavedmsg-modal.component'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { PremiumReportComponent } from '../company/premiumreport/premium-report.component';

@Injectable()
export class CanDeactivateGuardCompanyInfo  {
  constructor(private modalService: NgbModal) { }
  ngOnInit(){
    
  }


  canDeactivate(component: CompanyinformationComponent) {
    if (component.isNewForm == true) {
      return true
    }

    else if (component.hasUnsavedData()) {
        const subject = new Subject<boolean>();
        const modal = this.modalService.open(UnsavedmsgModalComponent);
        modal.componentInstance.subject = subject;
        return subject.asObservable()
    }
    return true;
  }
}
@Injectable()
export class CanDeactivateGuardAnualStatement  {
  constructor(private modalService: NgbModal) { }
  canDeactivate(component: AnnualstatementComponent) {

    if (component.hasUnsavedData()) {
      const subject = new Subject<boolean>();
      const modal = this.modalService.open(UnsavedmsgModalComponent);
      modal.componentInstance.subject = subject;
      return subject.asObservable()

    }
    return true;
  }
}


@Injectable()
export class CanDeactivateGuardGroupReporting  {
  constructor(private modalService: NgbModal) { }
  canDeactivate(component: GroupreportingComponent) {


    if (component.hasUnsavedData()) {
      const subject = new Subject<boolean>();
      const modal = this.modalService.open(UnsavedmsgModalComponent);
      modal.componentInstance.subject = subject;
      return subject.asObservable()
    }

    return true;
  }
}

@Injectable()
export class CanDeactivateGuardPremiumReport  {
  constructor(private modalService: NgbModal) { }
  canDeactivate(component: PremiumReportComponent) {

    if (component.hasUnsavedData()) {
      const subject = new Subject<boolean>();
      const modal = this.modalService.open(UnsavedmsgModalComponent);
      modal.componentInstance.subject = subject;
      return subject.asObservable()
    }
    return true;
  }
}

@Injectable()
export class CanDeactivateGuardUserdefineddata  {
  constructor(private modalService: NgbModal) { }
  canDeactivate(component: UserdefineddataComponent) {

    if (component.hasUnsavedData()) {
      const subject = new Subject<boolean>();
      const modal = this.modalService.open(UnsavedmsgModalComponent);
      modal.componentInstance.subject = subject;
      return subject.asObservable()
    }
    return true;
  }
}

@Injectable()
export class CanDeactivateGuardComment  {
  constructor(private modalService: NgbModal) { }
  canDeactivate(component: CommentsComponent) {


    if (component.hasUnsavedData()) {
      const subject = new Subject<boolean>();
      const modal = this.modalService.open(UnsavedmsgModalComponent);
      modal.componentInstance.subject = subject;
      return subject.asObservable()
    }

    return true;
  }
}