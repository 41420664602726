import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, forkJoin } from 'rxjs';
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import * as GridActions from './grid-config.actions';

import { Store, select } from '@ngrx/store';
import { selectGridConfig } from './grid-config.selectors';
import { GridConfigService } from './grid-configuration.service';
import * as PremiumReportActions from '../../premiumreport/data-access/premium-report.action';
import { currentGridTypeSelector } from '../../premiumreport/data-access/premium-report.selectors';
import { Router } from '@angular/router';

@Injectable()
export class GridConfigEffects {
  loadAllConfigurations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridActions.loadAllConfigurationsAction),
      switchMap(() =>
        this.gridConfigService.getAllConfigurations().pipe(
          map((data) =>
            GridActions.loadAllConfigurationsSuccess({
              directRowStates: data.direct.states,
              directPremiumColumns: data.direct.columns,
              supplementalRowStates: data.supplemental.states,
              supplementalPremiumColumns: data.supplemental.columns,
            })
          ),
          catchError((error) =>
            of(GridActions.loadAllConfigurationsFailure({ error }))
          )
        )
      )
    )
  );

  loadGridConfigurationsSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridActions.loadGridConfigurationsSuccess),
      withLatestFrom(this.store.select(selectGridConfig)),
      switchMap(([action, gridConfigData]) => {
        return [PremiumReportActions.backupHiddenRows({ gridConfigData })];
      })
    )
  );

  loadGridConfigurations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridActions.loadAllConfigurationsSuccess),
      switchMap(() =>
        this.gridConfigService.getGridConfigurations().pipe(
          map((data) =>
            GridActions.loadGridConfigurationsSuccess({
              hiddenDirectRowStates: data.direct.states,
              hiddenDirectPremiumColumns: data.direct.columns,
              hiddenSupplementalRowStates: data.supplemental.states,
              hiddenSupplementalPremiumColumns: data.supplemental.columns,
            })
          ),
          catchError((error) =>
            of(GridActions.loadGridConfigurationsFailure({ error }))
          )
        )
      )
    )
  );

  fetchLatestValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridActions.saveGridConfigurations),
      switchMap(() =>
        this.store.pipe(
          select(selectGridConfig),
          take(1),
          map((latestValues) =>
            GridActions.applyGridConfigurationChanges({
              gridConfigData: {
                direct: {
                  states: latestValues.directRowStates,
                  columns: latestValues.directPremiumColumns,
                },
                supplemental: {
                  states: latestValues.supplementalRowStates,
                  columns: latestValues.supplementalPremiumColumns,
                },
              },
            })
          ),
          catchError((error) =>
            of(GridActions.saveGridConfigurationsFailure({ error }))
          )
        )
      )
    )
  );

  applyGridConfigurationChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridActions.applyGridConfigurationChanges),
      concatMap((action) =>
        this.gridConfigService
          .saveGridConfigurations(action.gridConfigData)
          .pipe(
            map(() => GridActions.saveGridConfigurationsSuccess()),
            catchError((error) =>
              of(GridActions.saveGridConfigurationsFailure({ error }))
            )
          )
      )
    )
  );

  resetGridConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridActions.resetGridConfig),
      switchMap((action) =>
        this.gridConfigService
          .getDefaultGridConfigurations(action.premiumType, action.resetType)
          .pipe(
            map((data) =>
              GridActions.loadDefaultGridConfigurationsSuccess({
                hiddenDirectRowStates: data.direct.states,
                hiddenDirectPremiumColumns: data.direct.columns,
                hiddenSupplementalRowStates: data.supplemental.states,
                hiddenSupplementalPremiumColumns: data.supplemental.columns,
              })
            ),
            catchError((error) =>
              of(GridActions.loadGridConfigurationsFailure({ error }))
            )
          )
      )
    )
  );

  applyGridConfigWithExistingData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridActions.applyGridConfigWithExistingData),
      switchMap((action) =>
        forkJoin({
          directConfig: this.gridConfigService.getDefaultGridConfigurations(
            'D',
            'existingData'
          ),
          supplementalConfig:
            this.gridConfigService.getDefaultGridConfigurations(
              'S',
              'existingData'
            ),
        }).pipe(
          map(({ directConfig, supplementalConfig }) =>
            GridActions.loadExistingDataGridConfigurationsSuccess({
              hiddenDirectRowStates: directConfig.direct.states,
              hiddenDirectPremiumColumns: directConfig.direct.columns,
              hiddenSupplementalRowStates:
                supplementalConfig.supplemental.states,
              hiddenSupplementalPremiumColumns:
                supplementalConfig.supplemental.columns,
            })
          ),
          catchError((error) =>
            of(GridActions.loadGridConfigurationsFailure({ error }))
          )
        )
      )
    )
  );

  loadExistingDataGridConfigurationsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridActions.loadExistingDataGridConfigurationsSuccess),
      switchMap(() => of(GridActions.saveGridConfigurations()))
    )
  );

  failureRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          GridActions.loadAllConfigurationsFailure,
          GridActions.saveGridConfigurationsFailure,
          GridActions.loadGridConfigurationsFailure
        ),
        tap(() => {
          this.router.navigate(['/apifailure']);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private gridConfigService: GridConfigService,
    private store: Store,
    private router: Router
  ) {}
}
