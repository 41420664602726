<div *ngIf="isLoaded" class="modal" tabindex="-1" role="dialog" style="display:block!important">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="pagetitle">{{title}}</h4>
        <span class="table-material-icons" (click)="closeModal()" style="cursor: pointer;font-size: 24px;">close</span>

      </div>
      <div style="padding: 0 16px 0 16px;" *ngIf="showPageError">
        <div class="PageLevelError">Either NAIC or AMBEST is required</div>
      </div>
      <div class="modal-body">
        <form [formGroup]="addGroupReportingForm" (ngSubmit)="onsubmit()">


          <label class="lib-text-field">
            <input placeholder=" " formControlName="Name" />
            <span class="lib-placeholder">Company Name</span>

          </label>
          <label class="lib-text-field">
            <input placeholder=" " (keypress)="validateInput($event)" formControlName="NAIC" />
            <span class="lib-placeholder">NAIC</span>

          </label>
          <label class="lib-text-field">
            <input placeholder=" " (keypress)="validateInput($event)" formControlName="AMBest" />
            <span class="lib-placeholder">AMBest</span>

          </label>

          <div>
            <app-button buttonType="primary" type="submit" class="nocursor" style="width: 100%;padding-right: 10px;"
              [disabled]="!addGroupReportingForm.valid">Save Data
            </app-button>

          </div>
        </form>
      </div>

    </div>
  </div>
</div>