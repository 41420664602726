import { createAction, props } from '@ngrx/store';
import {
  RowState,
  PremiumColumn,
} from '../../grid-configuration/data-access/grid-config-model';

export const setError = createAction(
  '[Premium Report] set Error',
  props<{ message: string }>()
);

export const clearError = createAction('[Premium Report] clear Error');

export const setSelectedCellName = createAction(
  '[Premium Report] Set Selected Cell Name',
  props<{ name: string }>()
);

export const setSelectedCellFormula = createAction(
  '[Premium Report] Set Selected Cell Formula',
  props<{ formula: string }>()
);

export const setGridDirty = createAction(
  '[Premium Report] Set Grid  Dirty',
  props<{ grid: 'S' | 'D' }>()
);

export const setGridClean = createAction(
  '[Premium Report] Set Grid  Clean',
  props<{ grid: 'S' | 'D' }>()
);

export const setPremiumType = createAction(
  '[Premium Report] Set Current Premium Type',
  props<{ premiumType: 'S' | 'D' }>()
);

export const setGridValidity = createAction(
  '[Premium Report] Set Grid  Validity',
  props<{ grid: 'S' | 'D'; validity: boolean }>()
);

export const setGridPremiumData = createAction(
  '[Premium Report] Set Grid  Premium Data',
  props<{ grid: 'S' | 'D'; premiumData: any[] }>()
);

export const setGridPremiumCellData = createAction(
  '[Premium Report] Set Grid  Premium Cell Data',
  props<{ grid: 'S' | 'D'; rowIndex: any; colIndex: any; value: any }>()
);
export const setGridPremiumDataSuccess = createAction(
  '[Premium Report] Set Grid  Premium Data Success',
  props<{ S: any[]; D: any[] }>()
);
export const setGridPremiumDataFailed = createAction(
  '[Premium Report] Set Grid  Premium Data Failed',
  props<{ error: any }>()
);

export const setUserDefinnedPremiumData = createAction(
  '[Premium Report] Set Grid  Premium Data',
  props<{ grid: 'S' | 'D'; premiumData: any[] }>()
);

export const setRows = createAction(
  '[Premium Report] Set Rows',
  props<{ rows: any[] }>()
);
export const setColums = createAction(
  '[Premium Report] Set Grid  Columns',
  props<{ grid: 'S' | 'D'; columns: any[] }>()
);

export const endEditToggler = createAction(
  '[Premium Report] Trigger End edit',
  props<{ grid: 'S' | 'D' }>()
);

export const clearSelectionToggler = createAction(
  '[Premium Report] trigger clear selection',
  props<{ grid: 'S' | 'D' }>()
);

export const backupHiddenRows = createAction(
  '[Premium Report] load hidden premium rows',
  props<{
    gridConfigData: {
      directRowStates: RowState<any>[];
      directPremiumColumns: PremiumColumn<any>[];
      supplementalRowStates: RowState<any>[];
      supplementalPremiumColumns: PremiumColumn<any>[];
    };
  }>()
);

export const setHiddenPremiumCount = createAction(
  '[Premium Report] set count of hidden rows and columns with premium',
  props<{
    gridConfigData: {
      directRowStates: RowState<any>[];
      directPremiumColumns: PremiumColumn<any>[];
      supplementalRowStates: RowState<any>[];
      supplementalPremiumColumns: PremiumColumn<any>[];
    };
  }>()
);

export const loadPremiumInfo = createAction(
  '[Premium Report] load premium info',
  props<{ compno: string; currentYear: number; groupcode: string }>()
);

export const loadPremiumInfoSuccess = createAction(
  '[Premium Report] load premium info Success',
  props<{ response: any }>() //change to proper type later
);

export const loadPremiumInfoFailed = createAction(
  '[Premium Report] load premium info Failed',
  props<{ response: any }>() //change to proper type later
);

export const refreshCopyWithCurrent = createAction(
  '[Premium Report]  refresh Copy With Current'
);
export const refreshDirectWithCopy = createAction(
  '[Premium Report]  refresh Copy of Direct With Current'
);
export const refreshSupplementalWithCopy = createAction(
  '[Premium Report]  refresh Copy of Supplemental With Current'
);

export const enableConsolidatedView = createAction(
  '[Premium Report]  toggle Consolidated View'
);

export const enableDetailedView = createAction(
  '[Premium Report]  toggle Detailed View'
);

export const updateJobId = createAction(
  '[Premium Report] update Job Id',
  props<{ jobId: string }>()
);

export const updateJobIdSuccess = createAction(
  '[Premium Report] update Job Id Success'
);

export const updateJobIdFailure = createAction(
  '[Premium Report] update Job Id Failure',
  props<{ error: any }>()
);

export const setValidationLock = createAction(
  '[Validation] Set Validation Lock',
  props<{ value: boolean }>()
);
export const setPrfLock = createAction(
  '[Validation] Set Prf Lock',
  props<{ value: boolean }>()
);

export const updateLockStatus = createAction('[Validation] Update Lock Status');

export const updateLockStatusSuccess = createAction(
  '[Validation] Update Lock Status Sucess',
  props<{ lockedForValidation: boolean; prfLocked: boolean }>()
);

export const updateLockStatusFailure = createAction(
  '[Validation] Update Lock Status Failure',
  props<{ error: any }>()
);

export const resetValidationJobId = createAction(
  '[Premium Report] Reset Validation Job Id'
);

export const createNewPRF = createAction(
  '[Premium Report] Create New PRF',
  props<{
    groupCode: any;
    companyId: any;
    currentYear: number;
    prfNumber: any;
    prfName: any;
  }>()
);
export const createNewPRFSuccess = createAction(
  '[Premium Report] Create New PRF Success'
);
export const createNewPRFFailure = createAction(
  '[Premium Report] Create New PRF Failure',
  props<{ error: any }>()
);
