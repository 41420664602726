<div class="content-wrapper">
  <div *ngIf="showError">
    <div class="PageLevelError">{{ pageErrorMessage }}</div>
  </div>
  <table  class="table table-hover">
    <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col">PRF Number</th>
        <th scope="col">PRF Name</th>
        <th scope="col">Owner</th>
        <th scope="col">Submission Date</th>
        <th scope="col">Status</th>
        <th scope="col">Comments</th>
        <th *ngIf="!isReadOnlyAdmin" scope="col"></th>
        <th *ngIf="!isReadOnlyAdmin" scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let company of companyList; let row = index">
        <td>{{ (currentPage - 1) * itemsPerPage + (row + 1) }}</td>
        <td>
          <div
            (click)="
              openCompanyInformation(
                company.compno,
                company.compname,
                company.groupcode,
                company.status
              )
            "
            style="cursor: pointer"
          >
            {{ company.compno.toUpperCase() }}
          </div>
        </td>
        <td>{{ company.compname }}</td>
        <td>{{ company.owner }}</td>
        <td>
          <div
            *ngIf="
              company.submissiondate == '' || company.submissiondate == null;
              else submitteddatedefault
            "
          >
            -
          </div>
          <ng-template #submitteddatedefault>
            {{ company.submissiondate.split(" ")[0] | date : "MM.dd.yyyy" }}
          </ng-template>
        </td>
        <td>
          <container-element>
            <div
              *ngIf="company.status === 'Locked'; else notLockedOrSubmitted"
              class="lockedCust"
            >
              <span class="table-material-icons">lock</span> Submitted
            </div>

            <ng-template #notLockedOrSubmitted>
              <div
                *ngIf="
                  company.status === 'Verified';
                  else notVerifiedOrUploading
                "
                class="verifiedCust"
              >
                <span class="table-material-icons">verified_user</span> Verified
              </div>
            </ng-template>

            <ng-template #notVerifiedOrUploading>
              <div
                *ngIf="company.status === 'Validating'; else notVerifiedOrUploaded"
                class="validation"
              >
                <span class="table-material-icons">sync_problem</span>Validating
              </div>
            </ng-template>
            <ng-template #notVerifiedOrUploaded>
              <div
                *ngIf="company.status === 'Uploading'; else notstarted"
                class="uploading"
              >
              <span class="table-material-icons">
                update
                </span> Uploading
              </div>
            </ng-template>
            <ng-template #notstarted>
              <div
                *ngIf="company.status === 'notStarted'; else defaultTemplate"
                class="NotStarted"
              >
                <span class="table-material-icons">pending</span>Not Started
              </div>
            </ng-template>
            <ng-template #defaultTemplate>
              <div>-</div>
            </ng-template>
          </container-element>
        </td>

        <td>
          <div *ngIf="company.comment == ''; else commentdefault">-</div>
          <ng-template #commentdefault>
            <div (click)="openComment(company.comment)" style="cursor: pointer">
              View Comment
            </div>
          </ng-template>
        </td>
        <td *ngIf="!isReadOnlyAdmin">
          <div *ngIf="company.validationjobid === ''; else validationdefault">
            -
          </div>

          <ng-template #validationdefault>
            <div
              class="edit"
              (click)="
                navigateToValidationSummary(
                  company.compno,
                  company.compname,
                  company.groupcode
                )
              "
              ngbTooltip="Validation Summary"
              placement="bottom"
              tooltipClass="tooltip-class"
            >
              <a
                ><span class="table-material-icons"> sync_problem </span>
              </a>
            </div>
          </ng-template>
        </td>
        <td *ngIf="!isReadOnlyAdmin">
          <div
            *ngIf="
              company.status === 'Locked' ||
                company.status === 'Verified' ||
                company.status === 'Submitted' ||
                company.status === 'Uploading';
              else default
            "
            class="delete_disabled"
          >
            <span class="table-material-icons">delete</span>
          </div>

          <ng-template #default>
            <div class="delete">
              <span
                class="table-material-icons"
                ngbTooltip="Delete Company"
                placement="bottom"
                tooltipClass="tooltip-class"
                (click)="deleteCompany(company.compno, company.groupcode, company.originalsubmissiondate)"
                >delete</span
              >
            </div>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
</div>
