import {isValidPhoneNumber} from 'libphonenumber-js'
import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export const US_ZIP_PATTERN = "^\\d{5}(-\\d{4})?$";
export const US_PHONE_PATTERN = "^(?:(?:[+]?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$";

export function USPhoneValidator(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
        const value = control.value;

        if (!value) {
            return null;
        }

        const phoneValid = isValidPhoneNumber(value, 'US') === true;

        return !phoneValid ? {invalidPhone:true}: null;
    }
}