import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PremiumInfoType, PremiumReportState, hiddenPremiumCountType } from './premium-report.models';

export const selectPremiumReportState =
  createFeatureSelector<PremiumReportState>('premiumReport');

export const errorStateSelector = createSelector(
  selectPremiumReportState,
  (state: PremiumReportState): boolean => state.hasError
);

export const isLoadingStateSelector = createSelector(
  selectPremiumReportState,
  (state: PremiumReportState): boolean => state.isLoading
);
export const warningStateSelector = createSelector(
  selectPremiumReportState,
  (state: PremiumReportState): boolean => state.hasWarning
);

export const currentGridTypeSelector = createSelector(
  selectPremiumReportState,
  (state: PremiumReportState): 'D' | 'S' => state.slectedPremiumType
);
export const errorMessageSelector = createSelector(
  selectPremiumReportState,
  (state: PremiumReportState): string => state.errorMessage
);
export const currentCellNameSelector = createSelector(
  selectPremiumReportState,
  (state: PremiumReportState): string => state.selectedCellName
);
export const currentCellFormulaSelector = createSelector(
  selectPremiumReportState,
  (state: PremiumReportState): string => state.selectedCellFormula
);

export const currentGridSelector = (grid: 'D' | 'S') =>
  createSelector(
    selectPremiumReportState,
    (state: PremiumReportState): PremiumInfoType => state.premiumData[grid]
  );

export const userDefinedPremiumDataSelector = (grid: 'D' | 'S') =>
  createSelector(
    selectPremiumReportState,
    (state: PremiumReportState): any[] => state.userDefinedPremiumData[grid]
  );

export const userDefinedPremiumColumnSelector = createSelector(
  selectPremiumReportState,
  (state: PremiumReportState): any[] => state.userDefinedPremiumDataColumns
);

export const columnsSelector = (grid: 'D' | 'S') =>
  createSelector(
    selectPremiumReportState,
    (state: PremiumReportState): any[] => state.columns[grid]
  );

export const rowsSelector = createSelector(
  selectPremiumReportState,
  (state: PremiumReportState): any[] => state.rows
);

export const currentGridDataSelector = (grid: 'D' | 'S') =>
  createSelector(
    currentGridSelector(grid),
    (state: PremiumInfoType): any[] => state.premiumGridData
  );

export const originalGridDataSelector = (grid: 'D' | 'S') =>
  createSelector(
    currentGridSelector(grid),
    (state: PremiumInfoType): any[] => state.premiumGridDataOriginalCopy
  );
export const currentGridisValid = (grid: 'D' | 'S') =>
  createSelector(
    currentGridSelector(grid),
    (state: PremiumInfoType): boolean => state.isValid
  );
export const isCurrentGridDirty = createSelector(
  selectPremiumReportState,
  (state: PremiumReportState): boolean =>
    state.premiumData[state.slectedPremiumType].isDirty
);
export const isOtherGridDirty = createSelector(
  selectPremiumReportState,
  (state: PremiumReportState): boolean =>
    state.premiumData[state.slectedPremiumType == 'D' ? 'S' : 'D'].isDirty
);
export const isGridDirty = (grid: 'D' | 'S') =>
  createSelector(
    currentGridSelector(grid),
    (state: PremiumInfoType): boolean => state.isDirty
  );
export const currentGridClearSelectionToggled = (grid: 'D' | 'S') =>
  createSelector(
    currentGridSelector(grid),
    (state: PremiumInfoType): boolean => state.clearSelectionToggler
  );
export const currentGridEndEditToggled = (grid: 'D' | 'S') =>
  createSelector(
    currentGridSelector(grid),
    (state: PremiumInfoType): boolean => state.endEditToggler
  );
export const hiddenPremiumCountSelector = (grid: 'D' | 'S') =>
  createSelector(
    currentGridSelector(grid),
    (state: PremiumInfoType): any => state.hiddenPremiumCount
  );

export const hasHiddenPremiumData =
  createSelector(
    hiddenPremiumCountSelector('D'),
    hiddenPremiumCountSelector('S'),
    (direct: hiddenPremiumCountType,supply:hiddenPremiumCountType): any => {
      return (direct.rows+direct.columns+supply.rows+supply.columns)!=0
    }
  )

export const validationSummaryJobIdSelector = createSelector(
  selectPremiumReportState,
  (state: PremiumReportState): string => {
    return state.validationJobId
  }
);

export const isValidationJobIdNotEmptySelector = createSelector(
  validationSummaryJobIdSelector,
  (validationJobId: string): boolean => {
    return validationJobId.trim() !== "";
  }
);

export const isLockForValidationSelector = createSelector(
  selectPremiumReportState,
  (state: PremiumReportState): boolean => {
    return state.isLockForValidation
  }
);
export const isPrfLocked = createSelector(
  selectPremiumReportState,
  (state: PremiumReportState): boolean => {
    return state.isLocked
  }
);
