import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, combineLatest, interval } from 'rxjs';
import { map, switchMap, takeUntil, takeWhile } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { CompanyService } from '../company.service';
import {
  isLockForValidationSelector,
  isPrfLocked,
  validationSummaryJobIdSelector,
} from '../premiumreport/data-access/premium-report.selectors';
import * as ValidationSummaryActions from './data-access/validation-summary-actions';
import {
  selectAllFeedbackValid,
  selectAllStatusSuccess,
  selectNumberOfCompanyValidationMessage,
  selectShowNumberOfCompanyValidationMessage,
  selectValidationSummaryData,
  selectValidationSummaryIsComplete,
} from './data-access/validation-summary-selectors';

@Component({
  selector: 'app-validation-summary',
  templateUrl: './validation-summary.component.html',
  styleUrls: ['./validation-summary.component.scss'],
})
export class ValidationSummaryComponent implements OnInit, OnDestroy {
  constructor(
    private companyService: CompanyService,
    private store: Store,
    private appService: AppService
  ) {}

  compno: any;
  prfNumber: any;
  prfName: any;
  isAdmin: boolean =false;
  validationSummaryItem$;
  selectAllFeedbackValid$;
  selectValidationSummaryIsComplete$;
  selectValidationSummaryIsReadOnly$;
  private destroy$ = new Subject<void>();
  selectIsLockForValidation$ = this.store.select(isLockForValidationSelector);
  selectIsPrfLocked$ = this.store.select(isPrfLocked);
  selectAllStatusSuccess$ = this.store.select(selectAllStatusSuccess);
  selectShowNumberOfCompanyValidationMessage$ = this.store.select(selectShowNumberOfCompanyValidationMessage);
  selectNumberOfCompanyValidationMessage$ = this.store.select(selectNumberOfCompanyValidationMessage);
  selectValidationJobId$ = this.store.select(validationSummaryJobIdSelector);

  isReadOnly$ = combineLatest([
    this.selectIsLockForValidation$,
    this.selectIsPrfLocked$,
  ]).pipe(
    map(([isLockForValidation, isPrfLocked]) => {
      return (
        !isLockForValidation || isPrfLocked || this.appService.isReadonlyAdmin
      );
    })
  );

  ngOnInit(): void {
    this.compno = this.companyService.companyId;
    this.prfNumber = this.companyService.prfNumber;
    this.prfName = this.companyService.prfName;
    if (this.appService.isAdmin) {
      this.isAdmin = true;
    }
    this.companyService.getPrfStatus();
    this.loadValidationSummaryData();
    this.validationSummaryItem$ = this.store.select(
      selectValidationSummaryData
    );
    this.selectAllFeedbackValid$ = this.store.select(selectAllFeedbackValid);
    this.selectValidationSummaryIsComplete$ = this.store.select(
      selectValidationSummaryIsComplete
    );

    interval(1000)
      .pipe(
        // Use switchMap to switch to the latest value
        switchMap(() => this.selectValidationSummaryIsComplete$),
        // Continue until isComplete is true
        takeWhile((isComplete) => !isComplete),
        // Unsubscribe when the component is destroyed
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.loadValidationSummaryData());
      this.selectValidationJobId$.subscribe(id=>{
        console.log(`id:${id}`);
        this.loadValidationSummaryData();
      })
  }

  ngOnDestroy(): void {
    // Unsubscribe when the component is destroyed
    this.destroy$.next();
    this.destroy$.complete();
  }

  private loadValidationSummaryData() {
    this.store.dispatch(
      ValidationSummaryActions.loadAllValidationSummaryItems()
    );
  }
}
