import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService } from '../company.service';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { ConfirmDialogService } from '../../confirm-dialog/confirm-dialog.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddEditUserDefinedColsComponent } from '../add-edit-user-defined-cols/add-edit-user-defined-cols.component';
import { ToastService } from '../../shared/toast.service';
import { UnsavedmsgModalComponent } from '../../unsavedmsg-modal/unsavedmsg-modal.component';
import { AppService } from '../../app.service'
import { AlertmodalComponent } from '../../alertmodal/alertmodal.component';
import { Router } from '@angular/router';
import { Premiumreportservice, premiumreportParams } from '../premiumreport/data-access/services/premiumreportservice';

@Component({
  selector: 'app-userdefinedcolsmodal',
  templateUrl: './userdefinedcolsmodal.component.html',
  styleUrls: ['./userdefinedcolsmodal.component.scss']
})
export class UserdefinedcolsmodalComponent implements OnInit {
  @Input() title;
  compno: any;
  groupcode: any
  currentYear: number;
  prfNumber: string;
  prfName: string;
  userdefiledcols: any[] = [];
  userdefiledcolsOrg: any[] = [];
  userdefiendcolsform: UntypedFormGroup;

  direct_premium: any[];
  supplemental_premium: any[];
  userdefined_premium: any[];
  udcolumns: any[];
  userdefined_premium_supplemental: any[];
  columns: any[];
  rows: any[];
  premiumreportparams: premiumreportParams;
  const_statecode_column = 2;
  userdefined_row_code = "UD";
  supplemental_row_code = "SP";
  AssumedFromNonAffiliates_row_code = "AN";
  const_fixed_columns = 3;
  delete_response_msg: string;

  show: boolean = true;
  spinnersave: boolean;
  spinnerdelete: boolean;
  isComplete: boolean = false;
  isLocked: boolean;
  isReadOnly: boolean;
  isReadOnlyAdmin: boolean;
  premium: number;
  columnsloaded: Promise<boolean>;
  constructor(
    private router: Router,
    public activeModal: NgbActiveModal,
    private appService: AppService,
    public toastService: ToastService,
    private modalService: NgbModal,
    private companyService: CompanyService,
    public builder: UntypedFormBuilder,
    private confirmDialogService: ConfirmDialogService,
    private premiumreportservice: Premiumreportservice
  ) {
    this.userdefiendcolsform = this.builder.group({});
  }

  ngOnInit(): void {
    this.removePrevAlerts();
    this.companyService.userdefinedcolsCompleted.subscribe(result => {
      this.isComplete = result;
    });

    this.compno = this.companyService.companyId;
    this.currentYear = this.companyService.currentYear;
    this.prfNumber = this.companyService.prfNumber;
    this.prfName = this.companyService.prfName;
    this.companyService.getgroup().subscribe((value) => {
      this.groupcode = value;
    });
    this.isLocked = this.companyService.isEditLocked;
    this.isReadOnlyAdmin = this.appService.isReadonlyAdmin;
    this.isReadOnly = this.isLocked || this.isReadOnlyAdmin;
    this.companyService.getUserdefinedColumns(this.currentYear, this.compno).subscribe((resp) => {
      if (resp["message"] == "Success")
        this.userdefiledcols = resp["udeflobdetails"]["lobdetails"];

      this.show = false;
      this.userdefiledcolsOrg = resp["udeflobdetails"]["lobdetails"];
      this.premium = parseInt(resp["udeflobdetails"]["aggregate_total"]);
      for (var i = 0; i < this.userdefiledcols.length; i++) {
        this.tableRow(i);
      }
      this.columnsloaded = Promise.resolve(true)
    },
      (error) => {
        this.activeModal.close();
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    )
    this.companyService.getCustomerPrfProgress(this.currentYear, this.compno, this.groupcode).subscribe((resp) => {

      if (resp["message"] == "Success") {
        if (!this.isComplete)
          this.companyService.userdefinedcolsInProgress.next(true)
      }
    }
      ,
      (error) => {
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    )
  }

  LoadDataAndUpdatePremiums() {
    this.companyService.getPremiumReportData(this.compno, this.currentYear, this.groupcode).subscribe((resp) => {
      if (resp["message"] == "Success") {
        var result = resp['data'];
        this.direct_premium = resp['data']['data']['direct_premium'];
        this.supplemental_premium = resp['data']['data']['supplemental_premium'];
        this.userdefined_premium = resp['data']['userdefined_premium']['dpremium'];
        this.userdefined_premium_supplemental = resp['data']['userdefined_premium']['spremium'];
        this.columns = result["columns"];
        this.udcolumns = resp['data']['userdefined_columns'];
        this.rows = result["rows"];
        this.premiumreportparams = new premiumreportParams(this.columns, this.rows, this.udcolumns,
          this.const_statecode_column, this.userdefined_row_code, this.supplemental_row_code, this.const_fixed_columns,
          this.AssumedFromNonAffiliates_row_code);
        this.premiumreportservice.params = this.premiumreportparams;

        var updatedpremium = this.premiumreportservice.UpdateToPremiumReport(this.direct_premium, this.supplemental_premium,
          this.userdefined_premium, this.userdefined_premium_supplemental);
        this.direct_premium = updatedpremium[0];
        this.supplemental_premium = updatedpremium[1];
        this.UpdateUserDefinedPremiumdetails();
      }
    },
      (error) => {
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    )
  }

  public UpdateUserDefinedPremiumdetails() {
    var griddata = {
      "premreportlobs": this.columns,
      "premreportstates": this.rows,
      "dpremiuminfo": this.direct_premium,
      "spremiuminfo": this.supplemental_premium,
      "udpremiuminfo": this.userdefined_premium,
      "uspremiuminfo": this.userdefined_premium_supplemental,
      "udlobs": this.udcolumns
    };
    this.companyService.updatepremiumdetails(this.currentYear, this.compno, griddata, this.groupcode, true).subscribe((resp) => {
      if (resp["message"] == "Success") {
        this.toastService.show(this.delete_response_msg, { classname: 'bg-success text-light', delay: 3000 });
        this.spinnerdelete = false;
      }
    },
      (error) => {
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    )
  }

  tableRow(i: number, scenario?: string) {

    this.userdefiendcolsform.addControl('ColName_' + i, new UntypedFormControl(''));
    this.userdefiendcolsform.addControl('ISOCol_' + i, new UntypedFormControl(''));
    this.userdefiendcolsform.addControl('MatchCol_' + i, new UntypedFormControl(''));
    this.userdefiendcolsform.addControl('ColName1_' + i, new UntypedFormControl(''));
    this.userdefiendcolsform.addControl('ColName2_' + i, new UntypedFormControl(''));
    this.userdefiendcolsform.addControl('ColName3_' + i, new UntypedFormControl(''));
    this.userdefiendcolsform.addControl('ColName4_' + i, new UntypedFormControl(''));
    this.userdefiendcolsform.addControl('ColNo_' + i, new UntypedFormControl(''));
  }

  delete(col: any) {

    this.confirmDialogService.confirmThis('Delete', 'Are you sure you want to delete this column?', () => {
      this.userdefiledcols = this.userdefiledcols.filter(item => item !== col);

      this.toastService.remove(this.toastService.toasts[0]);
      this.spinnerdelete = true;
      this.companyService.deleteUserDefinedCol(this.currentYear, this.groupcode, this.compno, col).subscribe((resp) => {
        if (resp["message"] == "Success") {
          this.delete_response_msg = resp["status_msg"];
          this.LoadDataAndUpdatePremiums();
          // this.toastService.show(resp["status_msg"], { classname: 'bg-success text-light', delay: 3000 });
          // this.spinnerdelete = false;
        }
      },
        (error) => {
          this.activeModal.close();
          //console.log(error);
          this.router.navigate(['/apifailure']);
        }
      )

    }, () => {

    });

  }
  edit(item: any) {
    if (this.premium == 0 && item.ColName == 'Aggregate Write-Ins') {
      const modalRef = this.modalService.open(AlertmodalComponent);
      modalRef.componentInstance.title = 'Manage Columns';
      modalRef.componentInstance.message = 'This Column is reserved for  \'Aggregate Write-ins \'. No Aggregate Write-in value is entered in Annual Statement. Please add a new column to proceed.';

    } else {
      const modalRef = this.modalService.open(AddEditUserDefinedColsComponent);
      modalRef.componentInstance.title = "Edit User Defined Column";
      modalRef.componentInstance.model = item;
      modalRef.componentInstance.prfYear = this.currentYear;
      modalRef.componentInstance.companyno = this.compno;
      modalRef.componentInstance.selectedIndex = item.MatchCol;
      modalRef.componentInstance.group = this.groupcode;
      if (item.ColName == 'Aggregate Write-Ins') {
        modalRef.componentInstance.disable = true;
      }
      var index = this.userdefiledcols.indexOf(item);
      modalRef.componentInstance.ColNo = (index + 3).toString();
      modalRef.componentInstance.mode = 'edit';
      modalRef.result.then((result) => {

        if (result) {
          if (result == 'error') {
            this.activeModal.close();
          }
          var index = this.userdefiledcols.indexOf(item);

          if (~index) {
            this.userdefiledcols[index] = result;

          }

        }
      });

    }
  }
  add() {

    const modalRef = this.modalService.open(AddEditUserDefinedColsComponent);
    modalRef.componentInstance.title = "Add User Defined Column";
    modalRef.componentInstance.prfYear = this.currentYear;
    modalRef.componentInstance.group = this.groupcode;
    modalRef.componentInstance.companyno = this.compno;
    modalRef.componentInstance.mode = 'add';
    modalRef.componentInstance.ColNo = (this.userdefiledcols.length + 3).toString();
    modalRef.result.then((result) => {
      if (result) {
        if (result == 'error') {
          this.activeModal.close();
        }
        this.tableRow(this.userdefiledcols.length)
        this.userdefiledcols.push(result)

      }
    });




  }

  onsubmit() {

    this.toastService.remove(this.toastService.toasts[0]);
    this.spinnersave = true;
    this.companyService.saveUserDefinedCols(this.currentYear, this.compno, this.userdefiledcols).subscribe((resp) => {
      if (resp["message"] == "Success") {
        this.toastService.show(resp["status_msg"], { classname: 'bg-success text-light', delay: 3000 });

        this.spinnersave = false;
        this.companyService.userdefinedcolsCompleted.next(true)
        this.activeModal.close(true);
      }
    },
      (error) => {
        this.activeModal.close();
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    )
  }
  removePrevAlerts(){
    for (var i = this.toastService.toasts.length-1; i >= 0; i--) {
      this.toastService.remove(this.toastService.toasts[i]);
    }
  }

}
