<app-loading-screen *ngIf="spinnergetunsubmitedcmpanies">Loading Unsubmitted Company List</app-loading-screen>
<app-loading-screen *ngIf="spinnersearchcompanies">Searching company in unsubmitted list</app-loading-screen>
<app-loading-screen *ngIf="spinnersendmail">Sending Reminders</app-loading-screen>
<app-loading-screen *ngIf="spinnerrefreshrem">Refreshing mailing status</app-loading-screen>


<!-- <form> -->
   
<div>
    <div class="pagetitle">Unsubmitted Company List: {{ currentYear }}
        <div class="export" style="display: inline-block;float: right;">
            <button (click)="exportExcel()" style="padding-bottom:30px;"><i class="table-material-icons" >get_app</i>Export to Excel</button> 
    </div>
</div>
    
    <div class="information" *ngIf="showPageError">
        <div class="messagetitle"><i class="table-material-icons">error</i> {{pageError}}</div>
    </div>
</div>
<!-- / -->
    <!-- <div class="form-group col-md-16"> -->
        <div class="searchinputleft" style="padding-bottom:30px;">
            <div class="searchcont">
              <label class="lib-text-field">
                <input placeholder="SEARCH COMPANY" id="search" (keyup)="onenter($event)" style="width: 115%;
              padding: 16px 5px 4px 5px;" autocomplete="off" />
              </label>
              <div class="searchiconbutton">
                <i class="table-material-icons" (click)="searchonclick()"
                  style="color: #d3d4d5;cursor: pointer;background: #ffffff">search</i>
              </div>
            </div>
        <!-- </div> -->
    </div>
    
    <!-- <div> -->
         <!-- <div class="form-group col-md-8"> -->
    <form [formGroup]="unsubmittedcompanylistForm" >
        <label for="" style="display:none">&nbsp;test</label>
        <br />
        <div class="export" style="display: inline-block;padding-left: 270px;">
            <!-- <button (click)="exportExcel()" style="padding-bottom:30px;"><i class="table-material-icons" >get_app</i>Export to Excel</button> --> 
            
            <div class="form-check form-check-inline" style="float: left;padding-left: 2px;padding-top: 20px;">
            <input id="stdon" class="form-check-input"type="radio" 
            name="selectemail" value="reminderstandard" formControlName="selectemail"/>
         <label for="stdon" class="form-check-label"> Standard Email Content</label>
        </div>
        <div class="form-check form-check-inline" style="float:left;padding-left: 2px;padding-top: 20px;">
            <input id="custon" class="form-check-input" type="radio"
              name="selectemail" value="remindercustom" formControlName="selectemail" />
            <label for="custon" class="form-check-label" > Custom Email Content</label>
          </div>
         </div>
       
         
        <div  class="inputleft" style="padding-top: 10px;padding-left: 10px;">
            <button class="btn btn-primary" class="btn-bg" style="width: 150px;" (click)="sendmail('submission')" [disabled]="isReadOnlyAdmin">Submission Reminder</button>
            <!--<button class="btn btn-primary" style="width: 150px;" class="btn-bg" (click)="sendmail()" >Submission Reminder</button>-->
        </div>
                       
    <!-- </div>       -->
  <!-- </div> -->
  <div  class="inputleft" style="padding-top: 10px;padding-left: 650px;">
    <!--<button title="Exclude/Include selected Company(s) From List" class="btn btn-primary" class="btn-bg" style="width: 150px;" [disabled]="isReadOnlyAdmin">Save Exceptions</button>-->
</div>
<br />
<div *ngIf="!isReadOnly" class="inputleft" style="padding-top: 20px;padding-left: 35px;" >
    <!--<button class="btn btn-primary" class="btn-bg" style="width: 150px" (click)="sendmail('initial')" [disabled]="isReadOnlyAdmin">Initial Notification</button>-->
    <a class="clearButton" style="cursor:pointer"  (click)="sendmail('initial')" >Initial Notification</a>
</div>
<div *ngIf="isReadOnly" class="inputleft" style="padding-top: 20px;padding-left: 35px;" >
    <a class="disclearButton"  >Initial Notification</a>
</div>
</form>
    <!-- <br />
    <br /> -->
    <div>
        <table class="table table-hover" >
            <thead>
                <tr >
                    <th style="width: 30px;" class="text-left" scope="col"><input type="checkbox" [checked]="allchecked" (change)="checkAllCheckBox($event)" />
                    </th>
                    <th style="width: 150px;" class="text-left" scope="col" [appSort]="companylist" data-order="desc" data-name="companyno" class="sorted">Company
                    <i class="table-material-icons">arrow_drop_down</i>
                    </th>
                    <th style="width: 200px;" class="text-left" scope="col" [appSort]="companylist" data-order="desc" data-name="companyname" class="sorted">Company Name
                    <i class="table-material-icons">arrow_drop_down</i>
                    </th>
                    <th style="width: 250px;cursor: not-allowed;" class="text-left" scope="col" data-name="email">PRF Email
                    <!--<i class="table-material-icons">arrow_drop_down</i>-->
                    </th>
                    <th style="width: 250px;cursor: not-allowed;" class="text-left" scope="col" data-name="email">PBS/PDB Email
                    <!--<i class="table-material-icons">arrow_drop_down</i>-->
                    </th>
                   
                    <th style="width: 200px;cursor: not-allowed;" class="text-left" scope="col" data-name="status">Status
                    <!--<i class="table-material-icons">arrow_drop_down</i>-->
                    </th>
                    <th style="width: 5px;cursor: not-allowed;" class="text-left" scope="col" data-name="initmailsend">Initial
                    </th>
                    <th style="width: 5px;cursor: not-allowed;" class="text-left" scope="col" data-name="submailsend">Submission
                    </th>
                    <!--<th style="width: 10px;" class="text-left" scope="col" data-name="exclude">Exclude
                        </th>-->
                    <th style="display:none;" scope="col" data-name="mailid">Mail ID</th>
                    <th style="display:none;" scope="col" data-name="sendon">Send On</th>
                    <th style="display:none;" scope="col" data-name="sendby">Send By</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of companylist;let i=index;">
                    <td><input type="checkbox" value="{{item.companyno}}"  [(ngModel)]="companylist[i].checked" (change)="OncheckBoxChecked($event)" /></td>
                    <td>{{ item.companyno }}</td>
                    <td>{{ item.companyname }}</td>
                    <td>
                    
                        <input type="text" name="primaryemail{{rowIdx}}" #primaryemail="ngModel" required [(ngModel)]="item.primaryemail" [pattern]="emailPtrn" style="text-align:left;height: 25px;" class="form-control input-sm" size="5"/>
                        <mat-error *ngIf="primaryemail.errors?.pattern" style="color: red; margin: 15px;">
                            Please enter a valid email address
                        </mat-error>
                    <!-- <span (click) ="getitem(item)"class="table-material-icons" style="cursor: pointer;">edit</span> -->
                    <!-- </div> -->
                    <!-- <div *ngIf="!item.primedit"> -->
                        <!-- {{item.primaryemail}}
                        <span (click) ="getitem(item)"class="table-material-icons" style="cursor: pointer;">edit</span> -->
                        <!-- </div> -->
                    </td>
                    <td>
                    <!--<div *ngIf="item.mailsource=='PDB'">-->
                    <input type="text" name="secondaryemail{{rowIdx}}" #secondaryemail="ngModel" required [(ngModel)]="item.secondaryemail" [pattern]="emailPtrn" style="text-align:left;height: 25px;" class="form-control input-sm" size="5"/>
                    <mat-error *ngIf="secondaryemail.errors?.pattern" style="color: red; margin: 15px;">
                        Please enter a valid email address
                    </mat-error>
                    <!--</div>-->
                    <!--<div *ngIf="item.mailsource=='PBS'">
                        <input [(ngModel)]="item.secondaryemail" style="text-align:left;height: 25px;background-color:beige" class="form-control input-sm" size="5"/>
                    </div>-->
                        <!-- <span class="table-material-icons" style="cursor: pointer;">edit</span> -->
                </td>
                <!--<td><div *ngIf="item.mailsource=='PDB'"><img src="../../assets/img/PDB-Logo.png" alt="" /></div>
                    <div *ngIf="item.mailsource=='PBS'"><img src="../../assets/img/PBS-Logo.png" alt="" /></div>
                </td>-->
                    <td>{{ item.status}}</td>
                    
                    <td style="display:none;">{{ item.mailid}}</td>
                    <td style="display:none;">{{ item.sendon}}</td>
                    <td style="display:none;">{{ item.sendby}}</td>
                    <td><i *ngIf="item.initmailsend !==''" class="emailBtn" title = "Initial Notification sent" style="cursor: default;"><span style="padding-right: 0.5ex;"
                        class="table-material-icons">email</span></i></td>
                    <td style="width:10px"><i *ngIf="item.submailsend !==''"  title = "Submission Reminder sent" style="cursor: default;"><span style="padding-right: 0.5ex;padding-left: 3.0ex;"
                            class="table-material-icons">email</span></i></td>
                    <!--<td style="text-align:right"><input type="checkbox" value="{{item.companyno}}"   (change)="OncheckBoxChecked($event)" /></td>-->
                </tr>
            </tbody>
        </table>
    </div>
    <app-toasts aria-live="polite" aria-atomic="true" ></app-toasts>
<!-- </form> -->

