import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/http.service';

@Injectable({
    providedIn: 'root'
})
export class CompanyInformationService {

    constructor(private httpService: HttpService,) {}

    getPdbAddressDetails(companyno:string) {
        const data = {'group':companyno}
        return this.httpService.functionPost(`getpdbaddressinfo`,data);
    }
    getPbsAddressDetails(companyno:string) {
        const data = {'group':companyno}
        return this.httpService.functionPost(`getpbsaddressinfo`,data);
    }
}