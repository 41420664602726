<div class="validation-detail-content">
  <div>
    <div class="validation-detail-content-rule-section-top">
      <div class="validation-detail-content-rule-section-label">
        <b>Rule:</b>
      </div>
      <div class="validation-detail-content-rule-section-text">
        {{ validationItem.ruleDescription }}
        <div
          *ngIf="validationItem.status !== 'OK'"
          class="validation-detail-content-rule-section-data"
        >
          <div *ngIf="dataType === 'TABLE'; else textContent">
            <app-validation-rule [data]="dataValue"></app-validation-rule>
          </div>
          <ng-template #textContent>
            {{ data }}
          </ng-template>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>

    <div *ngIf="validationItem.status !== 'OK'">
      <div class="validation-detail-content-comment-section-top">
        <div class="validation-detail-content-comment-section-label">
          <b
            >Comments:<span class="comment-required-sign" *ngIf="!readOnly"
              >*</span
            ></b
          >
        </div>
        <div class="validation-detail-content-comment-section">
          <div>
            <div
              *ngIf="!readOnly"
              class="validation-detail-content-comment-section-required-label"
            >
              <span class="comment-required-sign">*</span>Required Field
            </div>
          </div>
          <pre *ngIf="readOnly; else editable">{{
            validationItem.feedback
          }}</pre>
          <ng-template #editable>
            <mat-form-field class="comment-section">
              <textarea
                #editable
                matInput
                [(ngModel)]="validationItem.feedback"
                placeholder="Please type your Description here.."
                [readonly]="readOnly"
              ></textarea>
            </mat-form-field>
          </ng-template>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div>
        <div class="button-container" *ngIf="!readOnly">
          <a
            class="clearButton"
            (click)="cancel()"
            [ngClass]="{
              disabled: isValidationSummaryFeedbackSaveReadonly() || readOnly
            }"
          >
            Cancel
          </a>
          <div class="call-to-action">
            <button
              class="primary update-feedback-button"
              (click)="updateFeedback()"
              [disabled]="isValidationSummaryFeedbackSaveReadonly() || readOnly"
              [ngClass]="{
                active: !isValidationSummaryFeedbackSaveReadonly(),
                disabled: isValidationSummaryFeedbackSaveReadonly() || readOnly
              }"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
