import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-unsavedmsg-modal',
  templateUrl: './unsavedmsg-modal.component.html',
  styleUrls: ['./unsavedmsg-modal.component.scss']
})
export class UnsavedmsgModalComponent implements OnInit {
  subject: Subject<boolean>;
  isLoaded:boolean = false;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    setTimeout(()=>{this.isLoaded=true;},500);
  }

  action(value: boolean) {
    this.activeModal.close();
    this.subject.next(value);
    this.subject.complete();
  }
  

}
