import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AdminService } from '../admin/admin.service';
import { AppService } from '../app.service';
import { CompanyService } from '../company/company.service';
import { isLockForValidationSelector, isValidationJobIdNotEmptySelector } from '../company/premiumreport/data-access/premium-report.selectors';

@Component({
  selector: 'app-admin-navigation',
  templateUrl: './admin-navigation.component.html',
  styleUrls: ['./admin-navigation.component.scss'],
})
export class AdminNavigationComponent implements OnInit {
  disable: boolean = true;
  isReadOnlyAdmin: boolean;
  companyInformationCompleted: boolean = false;
  premiumreportCompleted: boolean = false;
  commentsCompleted: boolean = false;
  disablecommonmenu: boolean = false;
  isLockedForValisation$ = this.store.select(isLockForValidationSelector);
  isValidationJobIdNotEmptySelector$ = this.store.select(
    isValidationJobIdNotEmptySelector
  );
  constructor(
    private store: Store,
    private appService: AppService,
    private adminlist: AdminService,
    private companyService: CompanyService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem('companyId') != '' && localStorage.getItem('year') != '') {
      this.companyService.loadPremiumData();
    }
    this.isReadOnlyAdmin = this.appService.isReadonlyAdmin;
    this.adminlist.disablesidemenu.subscribe((result) => {
      this.disable = result;
    });

    this.adminlist.disablecommonmenu.subscribe((result) => {
      this.disablecommonmenu = result;
    });

    if (
      this.companyService.companyId !== '' &&
      this.companyService.companyId !== null
    )
      this.adminlist.disablesidemenu.next(false);
    else this.adminlist.disablesidemenu.next(true);

    this.companyService.companyInformationCompleted.subscribe((result) => {
      this.companyInformationCompleted = result;
    });

    this.companyService.premiumreportCompleted.subscribe((result) => {
      this.premiumreportCompleted = result;
    });

    this.companyService.commentsCompleted.subscribe((result) => {
      this.commentsCompleted = result;
    });


  }


  resetdisable() {
    this.disable = true;
  }

  openGroup() {
    this.router.navigate(['/addcompanymodal']);
    // const modalRef = this.modalService.open(GroupmodalComponent);
    // modalRef.result.then((result) => {
    //   if (result == "Close") {
    //     this.router.navigate(['/dboard']);
    //   }
    // });
  }

  openFinalSubmission() {
    this.router.navigate(['/PRFSubmission']);
    //const modalRef = this.modalService.open(ReportsubmissionComponent, { windowClass: "finalSubmissionModal" });
  }
}
