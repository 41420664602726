export const statusList= [
    {
        name: 'All',
        value: "All",
    },
    {
        name: 'Not Started',
        value: 'notStarted',
    },
    {
        name: 'Open',
        value: '',
    },
    {
        name: 'Validating',
        value: 'Validating',
    },
    {
        name: 'Submitted',
        value: 'Locked',
    },
    {
        name: 'Verified',
        value: 'Verified',
    }
];