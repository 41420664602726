import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AdobelaunchService } from '../_services/adobelaunch.service';

@Component({
  selector: 'app-helppage',
  templateUrl: './helppage.component.html',
  styleUrls: ['./helppage.component.scss']
})
export class HelppageComponent implements OnInit,AfterViewInit {

  constructor(private AdobelaunchService:AdobelaunchService) { }
  ngAfterViewInit() {     
    this.AdobelaunchService.EventEndDispatch();
    }
  ngOnInit(): void {
  }

}
