import { Component, OnInit } from '@angular/core';
import {GroupReportingModel} from "../commpany.model";
import { UntypedFormGroup, UntypedFormControl,FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
//import { IGX_SWITCH_REQUIRED_VALIDATOR } from '@infragistics/igniteui-angular';
@Component({
  selector: 'app-add-group-reporting',
  templateUrl: './add-group-reporting.component.html',
  styleUrls: ['./add-group-reporting.component.scss']
})
export class AddGroupReportingComponent implements OnInit {

  title: string;

  groupReporting:GroupReportingModel;
  addGroupReportingForm : UntypedFormGroup;
  showPageError: boolean = false;
  isLoaded:boolean=false;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.addGroupReportingForm = new UntypedFormGroup({
      Name: new UntypedFormControl('', Validators.required),
      NAIC: new UntypedFormControl(''),
      AMBest: new UntypedFormControl('')
    });
    setTimeout(()=>{this.isLoaded=true;},500);
  }
  validateInput(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      return true;
    }
    return false;
  }
  onsubmit(){
    if(this.confirmval()){
      if(this.addGroupReportingForm.valid){
        this.groupReporting =this.addGroupReportingForm.value;
        this.activeModal.close(this.groupReporting);
      }
    }
  }
confirmval(){

  let nval = this.addGroupReportingForm.get('NAIC').value;
  let aval = this.addGroupReportingForm.get('AMBest').value;

  if(nval != '' || aval != ''){
    this.showPageError = false;
    this.addGroupReportingForm.setErrors(null);
    return true;
 } else {
    this.showPageError = true;
    this.addGroupReportingForm.setErrors({ 'invalid': true});
    return false;
  }
}
closeModal(){
  this.activeModal.close(this.groupReporting);
}


}
