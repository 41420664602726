import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-company-list-filter',
  templateUrl: './company-list-filter.component.html',
  styleUrls: ['./company-list-filter.component.scss'],
})
export class CompanyListFilterComponent {
  @Input() yearList: [] = [];
  @Input() filterStatusList: [] = [];
  @Input() currentYear: number;
  @Input() isAdmin: boolean;
  @Input() searchValue: boolean;
  @Input() selectedStatus: string = null;  
  @Output() onYearChange = new EventEmitter();
  @Output() onEntersearchClick = new EventEmitter();
  @Output() onSearchButtonClick = new EventEmitter();
  @Output() onStatusFilterChange = new EventEmitter();
  onYearFilterChange(year) {
    if (typeof year === 'object') {
      year = year.value;
    }
    this.currentYear = parseInt(year);
    this.onYearChange.emit(this.currentYear);
  }
  onenter(e) {
    const inputElement = e.target as HTMLInputElement;
    const value = inputElement.value;
    const event = {
      inputValue: value,
      key: e.key,
      keyCode: e.keyCode,
    };
    this.onEntersearchClick.emit(event);
  }
  searchonclick() {
    const searchValue = (<HTMLInputElement>document.getElementById('search'))
      .value;
    this.onSearchButtonClick.emit(searchValue);
  }
  getCompanyForStatus(status: any): void {
    this.onStatusFilterChange.emit(status);
  }
}
