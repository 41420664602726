import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router} from '@angular/router';
import { CompanyService } from 'src/app/company/company.service';
import { AdminService } from '../admin/admin.service';
import { ToastService } from '../shared/toast.service'
import { AdobelaunchService } from '../_services/adobelaunch.service';
import { environment } from 'src/environments/environment';
import { HttpService } from '../shared/http.service';
import { ValidationSummaryBase, ValidationSummaryItem } from '../company/validation-summary/data-access/validation-summary-model';
import { ValidationSummaryService } from '../company/validation-summary/data-access/validation-summary-service';

@Component({
  selector: 'app-email-preview',
  templateUrl: './email-preview.component.html',
  styleUrls: ['./email-preview.component.scss']
})
export class EmailPreviewComponent implements OnInit, AfterViewInit {
  constructor(private companyService: CompanyService,
    private router: Router, public toastService: ToastService,
    private adminService: AdminService, private validationSummaryService: ValidationSummaryService,private AdobelaunchService:AdobelaunchService,private httpService: HttpService,private route: ActivatedRoute) {

  }
  jobid: any
  emailForm: UntypedFormGroup;
  currentYear: number;
  prfNumber: string;
  prfName: string;
  groupCode: string;
  compno: any;
  annualstatementlist: any[] = [];
  priorYearComparisonList: any[] = [];
  rowMailDtls: any[] = [];
  show: boolean = true;
  contentBody: string = "";
  multemailsRegex = /^([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}(?:;[\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})*)$/;
  bottomLine: string = '<div style="padding-top: 1px;padding-bottom: 1px;border-bottom: 1px solid Gray;"><br/></div>';
  previousUrl: string;
  prioryearRoute: boolean = false;
  annualstatementRoute: boolean = false;
  validationsummaryRoute: boolean = false;
  tblid: string;
  comparedPrfNumber: string;
  hasAdminRole = false;
  validationSummaryItem$;
  validationSummaryItem: ValidationSummaryBase;
  validationSummaryItems: ValidationSummaryItem[] = [];
  warningFilteredValidationSummaryItems: any[] = [];
  isCompleteSubmission: boolean = false;
  ngAfterViewInit() {
    this.AdobelaunchService.EventEndDispatch();
    }
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['id']) {
        const id = params['id'];
        this.jobid =id
      }
    });
    this.companyService.getPrfStatus();
    this.removePrevAlerts();
    var Cookiecstid = this.httpService.getIsonetValue("cstid");
    var globalprfcustid=localStorage.getItem('prfcstid')
    if (typeof globalprfcustid!='undefined' && globalprfcustid) {
      if (+globalprfcustid == environment.MasterId || +globalprfcustid == parseInt(environment.ViewerId)) {
        this.hasAdminRole = true;
      }
      else {
        this.hasAdminRole = false;
      }
    }
    else{
      if(Cookiecstid){
        var url=environment.IsoAuthService + "/GetSessionProductGroupMembershipAndCustomerIds/";
        this.httpService.getcustid(url).subscribe((resp) => {
          var splitted = resp.toString().split("|");
          var custid=splitted.find(x => x.includes("PRF") )||"";
          if(custid!=""){
            var cstid =custid.toString().split(",")[0];
            localStorage.setItem("prfcstid", cstid);
            if (+cstid == environment.MasterId || +cstid == parseInt(environment.ViewerId)) {
              this.hasAdminRole = true;
            }
            else {
              this.hasAdminRole = false;
            }
          }
        },
          (error) => {
            console.log("ISOnet product eligibility check error-Email Preview.")
          }
        );

      }
    }

    this.compno = this.companyService.companyId;
    this.currentYear = this.companyService.currentYear;
    this.prfNumber = this.companyService.prfNumber;
    this.prfName = this.companyService.prfName;
    this.companyService.getgroup().subscribe((value) => {
      this.groupCode = value;
    });
    this.previousUrl = localStorage.getItem("routename");
    this.comparedPrfNumber = localStorage.getItem("comparedprfnumber");
    this.emailForm = new UntypedFormGroup({
      bccaddr: new UntypedFormControl('', [Validators.pattern(this.multemailsRegex)]),
      toaddr: new UntypedFormControl('', [Validators.required, Validators.pattern(this.multemailsRegex)]),
      ccaddr: new UntypedFormControl('', [Validators.pattern(this.multemailsRegex)]),
      subject: new UntypedFormControl('', [Validators.required]),
      phone: new UntypedFormControl('', [Validators.minLength(7), Validators.maxLength(14)]),
    });
    this.load();
  }
  load() {
    if (this.previousUrl === 'prioryearcomparison') {
      this.prioryearRoute = true;
      this.loadPriorYearComparison();
      this.tblid = '#tblPriorYear';
    } else if (this.previousUrl === 'annualstatement') {
      this.annualstatementRoute = true;
      this.loadAnnualStatementreport();
      this.tblid = '#tblAnnualSt';
    } else if (this.previousUrl === 'validationsummary') {
      this.validationsummaryRoute = true;
      this.show =true
      this.loadValidationSummaryForEmailPreview();
      this.tblid = '#tblValidationSummary';
    }
  }


  back() {
    this.emailForm.reset();
    localStorage.removeItem('routename');
    if (this.previousUrl === 'prioryearcomparison')
      this.router.navigate(['admin/prioryearcomparison'])
    else if (this.previousUrl === 'annualstatement') {
      if (this.hasAdminRole)
        this.router.navigate(['admin/annualstatementcomparison'])
      else
        this.router.navigate(['/annualstatementcomparison'])
    }
    else if (this.previousUrl === 'validationsummary') {
      this.router.navigate(['admin/validationsummary'])
    }
  }


  loadAnnualStatementreport() {
    this.annualstatementlist = [];
    this.adminService.loadAnnualStatementComparison(this.currentYear, this.groupCode, this.compno).subscribe((resp) => {
      if (resp["message"] == "Success") {
        this.annualstatementlist = resp["ascomparisondata"];
        var index = this.annualstatementlist.indexOf("mailid");
        this.rowMailDtls = this.annualstatementlist.splice(index, 1);
        if ((this.rowMailDtls.length > 0)) {
          this.contentBody = this.rowMailDtls[0]['body'];
          this.emailForm.patchValue({
            toaddr: this.rowMailDtls[0]['mailid'],
            ccaddr: this.rowMailDtls[0]['cc'] || "",
            subject: this.rowMailDtls[0]['subject'],
            phone: this.rowMailDtls[0]['insurerno'],
          })
        }
        this.show = false;

      }
    },
      (error) => {
        this.router.navigate(['/apifailure']);
      }
    )
  }

  loadPriorYearComparison() {
    this.priorYearComparisonList = [];
    this.adminService.loadPriorYearComparisonDetails(this.currentYear, this.groupCode, this.compno, this.comparedPrfNumber).subscribe((resp) => {
      if (resp["message"] == "Success") {
        this.priorYearComparisonList = resp['comparisondata']['prior_comparison_data'];
        this.rowMailDtls = resp['comparisondata']['email_info'][1];
        if ((this.rowMailDtls)) {
          this.contentBody = this.rowMailDtls['body'];
          this.emailForm.patchValue({
            toaddr: this.rowMailDtls['mailid'],
            subject: this.rowMailDtls['subject'],
            ccaddr: this.rowMailDtls['cc'] || "",
            phone: this.rowMailDtls['insurerno'],
          })
        }
        this.show = false;
      }
    },
      (error) => {
        this.router.navigate(['/apifailure']);
      }
    )
  }

  loadValidationSummaryForEmailPreview() {
    this.adminService.getEmailPreviewDetailsForValidationSummary(this.currentYear, this.groupCode, this.compno,this.jobid).subscribe((resp) => {
      if (resp["message"] == "Success") {
        const warningFilteredValidationSummaryItemstest = this.validationSummaryService.mapResponseItemToWarningValidationSummaryItem(resp['data']['validationdata'])
        this.warningFilteredValidationSummaryItems = warningFilteredValidationSummaryItemstest;
        this.rowMailDtls = resp['data']['emailinfo'];
        this.isCompleteSubmission = resp['data']['isCompleteSubmission']
        if ((this.rowMailDtls)) {
          this.contentBody = this.rowMailDtls['body'];
          this.emailForm.patchValue({
            toaddr: this.rowMailDtls['mailid'],
            subject: this.rowMailDtls['subject'],
            ccaddr: this.rowMailDtls['cc'] || "",
            phone: this.rowMailDtls['insurerno'],
          })
        }
        this.show = false;
      }
    },
      (error) => {
        this.router.navigate(['/apifailure']);
      }
    )
  }
  sendMail() {
    this.toastService.remove(this.toastService.toasts[0]);
    let To = this.emailForm.get("toaddr").value;
    let Cc = this.emailForm.get("ccaddr").value;
    let Bcc = this.emailForm.get("bccaddr").value;
    let Subject = this.emailForm.get("subject").value;
    let phone = this.emailForm.get("phone").value;
    let msg = ''
    const table = document.querySelector(this.tblid).outerHTML;
    if (!this.isCompleteSubmission)
      {msg = this.contentBody + '<P align=left>' + phone + '</P>' + this.bottomLine + table;}

    else
    { msg = this.contentBody + '<P align=left>' + phone + '</P>' + table;}


    this.adminService.sendMail(To, Cc, Bcc, Subject, msg).subscribe((resp) => {
      if (resp["message"] == "Success") {
        if(this.previousUrl === 'validationsummary'){
          this.updateValidationEmailDetails(resp["status_msg"]);
        }
        else{
          this.successAlert(resp["status_msg"]);
        }
      }
    },
      (error) => {
        this.router.navigate(['/apifailure']);
      }
    )
  }
  onsubmit() {
    this.sendMail();
  }
  updateValidationEmailDetails(message: string) {
    this.companyService
      .updateValidationEmailCounter(this.jobid, this.compno)
      .subscribe(
        (updateresp) => {
          if (updateresp['message'] == 'Success') {
            console.log('Validation email counter updated successfully');
            this.successAlert(message);
          }
        },
        (error) => {
          this.router.navigate(['/apifailure']);
        }
      );
  }
  removePrevAlerts(){
    for (var i = this.toastService.toasts.length-1; i >= 0; i--) {
      this.toastService.remove(this.toastService.toasts[i]);
    }
  }
  successAlert(msg: string) {
    window.scrollTo(0, 0);
    this.toastService.show(msg, { classname: 'bg-success text-light', delay: 3000 });
  }
}
