import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class UDEF_excl_cells_service {
    constructor() {

    }

    IsExclusiveCell = function (rowId, columnKey, udcolumns, const_fixed_columns, columns) {
        if (columnKey > (const_fixed_columns - 1)) {
            //TO DO : remove this hardcoding
            if (rowId == 21) {
                return false;
            }
            var matchcolumnno = parseInt(udcolumns[columnKey].MatchCol);
            var columnInfo = columns.filter(function (e) {
                return e.Number == matchcolumnno
            });
            if (columnInfo.length > 0) {
                var rows = columnInfo[0]["ExclRows"];
                //TO DO : remove exclusive cells hardcoding
                if (rowId == 17 && matchcolumnno == 35) {
                    rows = rows.replace("17");
                }
                if (rowId == 17 && (matchcolumnno == 25 || matchcolumnno == 32 || matchcolumnno == 34 || matchcolumnno == 62)) {
                    rows = "17," + rows;
                }
                if (rowId == 18 && matchcolumnno == 7) {
                    rows = "18," + rows;
                }
                if (rowId == 27 && (matchcolumnno == 1 || matchcolumnno == 2)) {
                    rows = "27," + rows;
                }
                if (rowId == 27 && columnKey == 3 && udcolumns[columnKey].editable == false) {
                    rows = rows.replace("27");
                }
                if (rowId == 39 && matchcolumnno == 7) {
                    rows = "39," + rows;
                }
                if (rowId == 54 && matchcolumnno == 7) {
                    rows = "54," + rows;
                }
                if (rowId == 58 && (matchcolumnno == 26 || matchcolumnno == 28)) {
                    rows = "58," + rows;
                }
                if (rowId == 59 && matchcolumnno == 25) {
                    rows = "59," + rows;
                }

                if (rows != null) {
                    var rowSplit = rows.split(",");
                    if (rowSplit.indexOf(String(rowId)) >= 0) {
                        return true;
                    }
                }
            }
        }
        return false;
    }
}