<div class="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="pagetitle">Delete</h4>
          <span
            class="table-material-icons close-button"
            (click)="activeModal.dismiss('Cross click')"
            >close</span
          >
        </div>
        <div class="modal-body">
            <p>
                {{label}}
            </p>
            <form [formGroup]="deleteForm" (ngSubmit)="onsubmit()">
                <div class="form-group">
                  <div 
                    class="form-inline margin-top-10"
                    [ngClass]="{'block': isCommentRequired, 'dnone': !isCommentRequired}"
                  >
                    <mat-form-field appearance="fill">
                      <mat-label>Comment</mat-label>
                      <textarea
                        matInput
                        formControlName="comment"
                        placeholder="Enter your comment"
                        rows="2"
                      ></textarea>
                      <mat-error *ngIf="deleteForm.get('comment').hasError('required')">
                        Comment is required.
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="modal-footer">
                  <app-button
                    size="medium"
                    buttonType="primary"
                    class="select-button"
                    [disabled]="deleteForm.invalid"
                    (click)="onsubmit()"
                  >
                    Delete
                  </app-button>
                  <app-button
                    size="medium"
                    (click)="activeModal.dismiss('Cross click')"
                    buttonType="secondary"
                  >
                    Close
                  </app-button>
                </div>
              </form>
              
        </div>

      </div>
    </div>
  </div>