import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService } from '../company/company.service'
import { Router } from '@angular/router';
import { setPrfLock, setValidationLock } from '../company/premiumreport/data-access/premium-report.action';
import { Store } from '@ngrx/store';
@Component({
  selector: 'app-groupmodal',
  templateUrl: './groupmodal.component.html',
  styleUrls: ['./groupmodal.component.scss']
})
export class GroupmodalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,
    private companyService: CompanyService, private router: Router, private store: Store) { }
  groupform: UntypedFormGroup;
  pageerror: boolean = false;
  validationerror: boolean = false;
  error: string;
  showvalidation: boolean = false;
  isdisabled: boolean = false;
  istouched: boolean = false;
  isLoaded:boolean = false;
  ngOnInit(): void {
    setTimeout(()=>{this.isLoaded=true;},500);
    this.groupform = new UntypedFormGroup({
      // groupcode: new FormControl('',[ Validators.required,Validators.maxLength(4),Validators.minLength(4),Validators.pattern("\d{4}")] )
      groupcode: new UntypedFormControl('', [Validators.required, Validators.pattern("^\\d{4}?$")]),
      groupname: new UntypedFormControl(''),
    })
  }
  validateGroup(e) {
    if (e.target.value.length == 4 && /^\d+$/.test(e.target.value)) {
      this.istouched = false;
      this.showvalidation = true;
      this.isdisabled = true;
      this.companyService.validategroup(this.companyService.currentYear, this.groupform.controls['groupcode'].value).subscribe({
        next: (resp) => {
          if (resp["message"] == "Success") {
            if (resp["status"] == '') {
              this.pageerror = true;
              this.validationerror = true;
              this.error = "Group does not exist"
              this.groupform.controls['groupname'].setValue('')
            } else {
              this.pageerror = false;
              this.validationerror = false;
              this.groupform.controls['groupname'].setValue(resp["status"])
            }
            this.showvalidation = false;
            this.isdisabled = false;
          }
        },
        error: (error) => {
          this.router.navigate(['/apifailure']);
        }
      });
    } else {
      this.groupform.controls['groupname'].setValue('')
      this.validationerror = true;
      this.istouched = true;
    }
  }
  onsubmit() {
    this.addcompany();
    this.activeModal.close();

  }

  addcompany() {
    localStorage.setItem("isLocked", 'false');
    this.store.dispatch(setPrfLock({ value: false}));
    this.store.dispatch(setValidationLock({ value: false }));
    localStorage.setItem("companyId", '');
    localStorage.setItem("prfNumber", '');
    localStorage.setItem("prfName", '');
    localStorage.setItem("groupcode", this.groupform.controls['groupcode'].value);

    this.companyService.getLocalStorage();
    localStorage.setItem("isCreateComapny", 'false');
    this.router.navigate(['/companyinformation']);

  }
  validationclick() {
    this.istouched = true;
  }
}
