import { Component, OnInit, ViewEncapsulation, ViewChild, PipeTransform, Pipe, Injectable, AfterViewInit } from '@angular/core';

import { CompanyService } from '../company.service';
import {
  IgxColumnComponent,
  ConnectedPositioningStrategy,
  CloseScrollStrategy,
  HorizontalAlignment,
  VerticalAlignment,
  IgxGridComponent,
  IGridKeydownEventArgs,
  IGridEditEventArgs,
  IgxGridCell,
  IgxExcelExporterService
} from '@infragistics/igniteui-angular';
import { Workbook, CellFill, RowColumnBase, IWorksheetCellFormat, HorizontalCellAlignment, CellBorderLineStyle, WorkbookColorInfo, VerticalCellAlignment, WorkbookStyle, IWorkbookFont, WorkbookFormat } from "igniteui-angular-excel";
import { ExcelUtility } from '../../shared/ExcelUtility';
import { Color, Collection$1, RowSeparatorDescriptionMetadata, FinancialSeriesDescriptionMetadata, truncate } from 'igniteui-angular-core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadPremiumComponent } from '../upload-premium/upload-premium.component';
import { ToastService } from '../../shared/toast.service'
import { PDFService, printParams } from "../../shared/pdf.service";
import { ConfirmDialogService } from '../../confirm-dialog/confirm-dialog.service';
import { UnsavedmsgModalComponent } from '../../unsavedmsg-modal/unsavedmsg-modal.component';
import { AppService } from '../../app.service';
import { UserdefinedcolsmodalComponent } from '../userdefinedcolsmodal/userdefinedcolsmodal.component'
import { Router } from '@angular/router';
import { AdminService } from '../../admin/admin.service';
import { UDEF_excl_cells_service } from '../../shared/UDEF_excl_cells_service';
import { Userdefinedservice, userdefinedParams } from '../../shared/userdefinedservice';
import { AdobelaunchService } from '../../_services/adobelaunch.service';
import { combineLatest } from 'rxjs';
import { isLockForValidationSelector, isPrfLocked } from '../premiumreport/data-access/premium-report.selectors';
import { Store } from '@ngrx/store';
@Injectable({
  providedIn: 'root'
})


@Component({
  selector: 'app-userdefineddata',
  templateUrl: './userdefineddata.component.html',
  styleUrls: ['./userdefineddata.component.scss']
})

export class UserdefineddataComponent implements OnInit, AfterViewInit {
  @ViewChild("grid1", { read: IgxGridComponent, static: true })
  public grid1: IgxGridComponent;
  listenerFn: () => void;
  private editedCellID: { columnID: any; rowID: any; rowIndex: number };
  private editedCell: any;
  public premium_info: any[];
  public direct_premium: any[];
  public supplemental_premium: any[];
  public userdefined_premium: any[];
  public udcolumns: any[];
  public userdefined_premium_supplemental: any[];
  public direct_premium_copy: any[];
  public supplemental_premium_copy: any[];
  public columns: any[];
  public rows: any[];
  public const_fixed_columns = 3;
  public const_slno_columns = 0;
  public const_row_number_column = 0;
  public const_state_column = 1;
  public const_statecode_column = 2;
  public FORMULATED_ROWS = [56,60,63,67];
  public LOCKED_ROWS = [57,64,65];
  public cellName = "";
  public cellFormula = "";
  public userdefined_row_code = "UD";
  public supplemental_row_code = "SP";
  public AssumedFromNonAffiliates_row_code = "AN";
  public total_row_code = "T";
  public premium_type = "D";
  private isDirty = false;
  public showPageError = false;
  public showPageWarning = false;
  private isvalidgrid = true;
  public errorMsg = "";
  public warningMsg = "";
  userdefinedparams: userdefinedParams;
  compno: any;
  groupcode: any;
  currentYear: number;
  prfNumber: string;
  prfName: string;
  isLocked: boolean;
  isReadOnly: boolean;
  isReadOnlyAdmin: boolean;
  user: string;
  isLoading: boolean = true;
  isComplete: boolean = true;
  isUpload: boolean = false;
  isTypingOverGridCell = false;
  prevGridCellValue = "";
  selectText: boolean = true;
  isDownloading: boolean = false;
  hasUserDefinedCols: boolean = false;
  private _positionSettings = {
    horizontalStartPoint: HorizontalAlignment.Left,
    verticalStartPoint: VerticalAlignment.Bottom
  };
  private _overlaySettings = {
    closeOnOutsideClick: true,
    modal: false,
    positionStrategy: new ConnectedPositioningStrategy(this._positionSettings),
    scrollStrategy: new CloseScrollStrategy()
  };
  public options = {
    enabled: true,
    copyHeaders: false,
    copyFormatters: true,
    separator: "\t"
  };

  constructor(
    private adminService: AdminService,
    private excelExportService: IgxExcelExporterService,
    private companyService: CompanyService,
    private modalService: NgbModal,
    public toastService: ToastService,
    private pdfService: PDFService,
    private confirmDialogService: ConfirmDialogService,
    private appService: AppService,
    private router: Router,
    private udef_excl_cells_service: UDEF_excl_cells_service,
    private userdefinedservice: Userdefinedservice,
    private AdobelaunchService: AdobelaunchService,
    private store: Store,
  ) {

  }
  ngAfterViewInit() {
    this.AdobelaunchService.EventEndDispatch();
  }
  ngOnInit() {
    this.removePrevAlerts();
    this.companyService.premiumreportCompleted.subscribe(result => {
      this.isComplete = result;
    });
    this.compno = this.companyService.companyId;
    this.currentYear = this.companyService.currentYear;
    this.prfNumber = this.companyService.prfNumber;
    this.prfName = this.companyService.prfName;
    this.isLocked = this.companyService.isEditLocked;
    this.isReadOnlyAdmin = this.appService.isReadonlyAdmin;
    this.isReadOnly = this.isLocked || this.isReadOnlyAdmin;
    this.companyService.getgroup().subscribe((value) => {
      this.groupcode = value;
    });
    document.getElementById("direct").click();

    combineLatest([
      this.store.select(isLockForValidationSelector),
      this.store.select(isPrfLocked),
    ]).subscribe(([isValidationLocked, prfLocked]) => {
      console.log(
        'updated',
        isValidationLocked,
        localStorage.getItem('isLocked') == 'true'
      );

      this.isLocked = prfLocked || isValidationLocked;
      this.isReadOnly = this.isLocked || this.appService.isReadonlyAdmin;
      console.log(this.isLocked);
    });

    this.LoadData();
    this.companyService.getCustomerPrfProgress(this.currentYear, this.compno, this.groupcode).subscribe((resp) => {

      if (resp["message"] == "Success") {

        if (!this.isComplete)
          this.companyService.premiumreportInProgress.next(true)
      }
    }
      ,
      (error) => {
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    )

  }


  private UpdateFormulaCells() {
    if (this.isTypingOverGridCell == true) {
      return;
    }
    var updatedpremium = this.userdefinedservice.UpdateUDFormulaCells(this.direct_premium, this.supplemental_premium,
      this.userdefined_premium, this.userdefined_premium_supplemental);
    this.direct_premium = updatedpremium[0];
    this.supplemental_premium = updatedpremium[1];
    this.userdefined_premium = updatedpremium[2];
    this.userdefined_premium_supplemental = updatedpremium[3];
    // this.updateSuppPremiumRow();
    // this.updateColumnTotal();
    // this.calculateRowTotal();
    // var updatedpremium = this.premiumreportservice.UpdateToPremiumReport(this.direct_premium, this.supplemental_premium,
    //   this.userdefined_premium, this.userdefined_premium_supplemental);
    // this.direct_premium = updatedpremium[0];
    // this.supplemental_premium = updatedpremium[1];
  }

  private LoadData() {
    this.isLoading = true;
    this.getPrgress();
    this.companyService.getPremiumReportData(this.compno, this.currentYear, this.groupcode).subscribe((resp) => {
      if (resp["message"] == "Success") {
        var result = resp['data'];
        this.direct_premium = resp['data']['data']['direct_premium'];
        this.supplemental_premium = resp['data']['data']['supplemental_premium'];
        this.userdefined_premium = resp['data']['userdefined_premium']['dpremium'];
        this.userdefined_premium_supplemental = resp['data']['userdefined_premium']['spremium'];
        this.columns = result["columns"];

        var statecolumn = resp['data']['userdefined_columns'][this.const_state_column];
        if (statecolumn.columnName) {
          statecolumn.columnName = statecolumn.columnName.replace("\n\n\nState/Territory\n", "");
        }
        this.udcolumns = resp['data']['userdefined_columns'];
        this.updateStatus();
        // To check whether we need to show download template option

        if (this.udcolumns.length > 5 ||
          this.udcolumns[this.const_fixed_columns].editable == true) {
          this.hasUserDefinedCols = true;
        }
        else {
          this.hasUserDefinedCols = false;
        }
        this.rows = result["rows"];
        this.premium_info = this.userdefined_premium;
        this.grid1.data = this.userdefined_premium;
        this.direct_premium_copy = JSON.parse(JSON.stringify(this.userdefined_premium));
        this.supplemental_premium_copy = JSON.parse(JSON.stringify(this.userdefined_premium_supplemental));


        this.userdefinedparams = new userdefinedParams(this.columns, this.rows, this.udcolumns,
          this.const_statecode_column, this.userdefined_row_code, this.supplemental_row_code, this.const_fixed_columns, this.AssumedFromNonAffiliates_row_code);
        this.userdefinedservice.params = this.userdefinedparams;
        this.UpdateFormulaCells();
        this.isLoading = false;
      }
    },
      (error) => {
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    )
  }

  private showError(msg) {
    this.showPageError = true;
    this.showPageWarning = false;
    this.errorMsg = msg;
    setTimeout(() => {
      this.showPageError = false;
      this.errorMsg = "";
    }, 10000);
  }

  private showWarning(msg) {
    this.showPageWarning = true;
    this.warningMsg = msg;
    setTimeout(() => {
      this.showPageWarning = false;
      this.warningMsg = "";
    }, 5000);
  }

  public downloadtemplate() {
    this.isDownloading = true;
    this.adminService.disablesidemenu.next(true);
    this.adminService.disablecommonmenu.next(true);
    this.companyService.disableCompanyList.next(true);
    this.companyService.clearStatus();
    this.companyService.getUDEFTemplateFile("TemplateUDEF.xlsx", 'Add', this.currentYear, this.groupcode, this.compno).subscribe((resp) => {
      if (resp["message"] == "Success") {
        var fileurl = resp["template_url"];
        let pwa = window.open(fileurl, "_blank","noopener noreferrer");
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        }
        this.deleteManageUDEFTemplate("TemplateUDEF.xlsx", 'Delete', this.currentYear, this.groupcode, this.compno);
        this.isDownloading = false;
        this.adminService.disablesidemenu.next(false);
        this.adminService.disablecommonmenu.next(false);
        this.companyService.disableCompanyList.next(false);
        this.getPrgress();
      }
    },
      (error) => {
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    )
  }

  deleteManageUDEFTemplate(filename, mode, year, group, compnyno) {
    this.companyService.getUDEFTemplateFile(filename, mode, year, group, compnyno).subscribe((resp) => {
      if (resp["message"] == "Success") {
      }
    },
      (error) => {

      }
    )
  }

  public UpdatePremiumdetails(showmanagecols = false) {
    this.toastService.reset();
    this.grid1.endEdit();
    this.isDirty = false;
    var griddata = {
      "premreportlobs": this.columns,
      "premreportstates": this.rows,
      "dpremiuminfo": this.direct_premium,
      "spremiuminfo": this.supplemental_premium,
      "udpremiuminfo": this.userdefined_premium,
      "uspremiuminfo": this.userdefined_premium_supplemental,
      "udlobs": this.udcolumns
    };
    this.adminService.disablesidemenu.next(true);
    this.adminService.disablecommonmenu.next(true);
    this.companyService.disableCompanyList.next(true)
    this.companyService.clearStatus();
    this.companyService.updatepremiumdetails(this.currentYear, this.compno, griddata, this.groupcode, true).subscribe((resp) => {
      if (resp["message"] == "Success") {
        this.companyService.loadPremiumData()
        this.isDirty = false;
        this.direct_premium_copy = JSON.parse(JSON.stringify(this.userdefined_premium));
        this.supplemental_premium_copy = JSON.parse(JSON.stringify(this.userdefined_premium_supplemental));
        if (this.isUpload == true) {
          this.toastService.show("File Uploaded Successfully.", { classname: 'bg-success text-light', delay: 5000 });
        }
        else {
          this.toastService.show("Premium saved successfully.", { classname: 'bg-success text-light', delay: 5000 });
        }
        this.adminService.disablesidemenu.next(false);
        this.adminService.disablecommonmenu.next(false);
        this.companyService.disableCompanyList.next(false)
        this.getPrgress()
        this.isUpload = false;
        if (showmanagecols == true) {
          this.showmanagecolswindow();
        }
      }
    },
      (error) => {
        this.isDirty = true;
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    )
  }

  getPrgress() {
    this.companyService.getCustomerPrfProgress(this.currentYear, this.compno, this.groupcode).subscribe((resp) => {
      if (resp["message"] == "Success") {
        if (!this.isComplete)
          this.companyService.groupreportingInProgress.next(true)
        this.companyService.disablePrf.next(false)
      }
    }
      ,
      (error) => {
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    )


  }

  public uploadExcelDataToGrid(uploadedFile) {
    this.grid1.endEdit();
    if (uploadedFile == null) {
      this.showError("Please select a file to Upload!");
      return false;
    }
    var fileExt = uploadedFile.name.split('.').pop();
    if (fileExt == "xlsx" == false) {
      this.showError("Invalid file selected, valid files are of .xlsx types.");
      return false;
    }

    this.showWarning("Please Wait! File upload will take some time.\nYou will get an upload status at end.\nAlso make sure that you are using the correct file for uploading premiums for the company " + this.compno + ".");

    ExcelUtility.load(uploadedFile).then((w) => {
      this.uploadPremium(w);
    });
  }

  private validateLOBs(worksheet) {
    for (let col = 0; col < this.udcolumns.length; col++) {
      var column = this.udcolumns[col];
      var excelLob = worksheet.rows(0).cells(col).value;
      if (excelLob != null && column.columnName.replace(/\r?\n|\r|\s/g, '') != excelLob.replace(/\r?\n|\r|\s/g, '')) {
        this.showError("LOB Header in col [" + (col - 2) + "] is invalid. Please use the template file for upload.");
        return false;
      }
    }
    return true;
  }
  public Clear(flag = false) {
    if (this.isDirty == true || flag == true) {
      this.grid1.endEdit();
      this.isDirty = false;
      this.userdefined_premium = JSON.parse(JSON.stringify(this.direct_premium_copy));
      this.userdefined_premium_supplemental = JSON.parse(JSON.stringify(this.supplemental_premium_copy));
      if (this.premium_type == "D") {
        this.premium_info = this.userdefined_premium;
        this.grid1.data = this.userdefined_premium;
      }
      else {
        this.premium_info = this.userdefined_premium_supplemental;
        this.grid1.data = this.userdefined_premium_supplemental;
      }
    }
  }

  private uploadPremium(workbook) {

    if (workbook.worksheets().count != 2) {
      this.showError("Invalid File.File contains [" + workbook.worksheets().count + "] Sheet(s)! Download the Template.");
      return false;
    }

    if (workbook.worksheets(0).name != "Direct Premiums" || workbook.worksheets(1).name != "Supplemental Premiums") {
      this.showError("Invalid Sheet Names! First sheet name should be [Direct Premiums] and second sheet name should be [Supplemental Premiums].Download the Template.");
      return false;
    }

    var sheetcount = workbook.worksheets().count;
    for (let sheet = 0; sheet < sheetcount; sheet++) {
      var worksheet = workbook.worksheets(sheet);
      console.log("column length",this.udcolumns.length)
      console.log("row length",this.rows.length)
      const expectedColumns = this.udcolumns.length;
      const expectedRows = this.rows.length;
      if (!this.validateTemplateStructure(worksheet, expectedRows, expectedColumns)) {
        this.Clear(true);
        return false;
      }

      if (!this.validateFormulaCells(worksheet, this.udcolumns.length,this.FORMULATED_ROWS)) { 
        this.Clear(true);
        return false; 
      }

      if (!this.validatelockedCells(worksheet,this.LOCKED_ROWS)) { 
        this.Clear(true);
        return false; 
      }

      var slno = 6
      if (this.validateLOBs(worksheet) == true) {
        var i = 1;
        do {
          var stateCode = "";
          var stateDesc = "";
          for (let col = 0; col < this.udcolumns.length; col++) {
            var column = this.udcolumns[col];
            var value = null;
            if (col < this.const_fixed_columns) {
              value = worksheet.rows(i).cells(col).value;
              if(col == this.const_slno_columns){
                if(parseInt(value) == slno){
                  slno = slno+1
                }else{
                  this.showError(
                    `SL No mismatch. Invalid template in sheet ${worksheet.name}. use original template file'.`
                  );
                  this.Clear(true);
                  return false;
                }
              }
            }
            else {
              value =
                    worksheet.rows(i).cells(col).value != null &&
                      worksheet.rows(i).cells(col).value != ''
                      ? worksheet.rows(i).cells(col).value
                      : null;
                    // Check if the value is non-numeric in non-fixed columns
                    if (isNaN(value)) {
                      this.showError('Invalid data! Non-numeric value found in column ' + (col + 1) + ' at row ' + (i+1));
                      this.Clear(true);
                      return false;
                    }
                    // Round numeric values if valid
                    value = value != null ? Math.round(parseFloat(value)) : null;
            }

            if (parseInt(value) > 99999999 || parseInt(value) < -99999999) {
              this.showError("Upload cell value cannot be greater than 99,999,999 or less than -99,999,999.");
              this.isDirty = true;
              this.Clear(true);
              return false;
            }

            if (col == this.const_fixed_columns && this.udcolumns[col].editable == false &&
              value != null && String(value).length > 0 && parseInt(value) != 0) {
              this.showError("Aggregate Write-Ins column is not editable. Not able to upload premium to this column.");
              this.isDirty = true;
              this.Clear(true);
              return false;
            }

            if (col == this.const_state_column) {
              stateDesc = value;
            }
            else if (col == this.const_statecode_column) {
              stateCode = value;
            }

            //state validation
            if (col == this.const_statecode_column && this.isValidState(stateCode, stateDesc, sheet) == false) {
              this.Clear(true);
              return false;
            }

            //Avoid updation of premium for User Defined Row
            if (stateCode == this.userdefined_row_code) {
              break;
            }

            if (sheet == 0 && this.userdefined_premium[i - 1] != null) {
              this.userdefined_premium[i - 1][col] = value;
            }
            else if (sheet == 1 && this.userdefined_premium_supplemental[i - 1] != null) {
              this.userdefined_premium_supplemental[i - 1][col] = value;
            }

          }
          i++;
        }
        while (worksheet.rows(i).cells(0).value != null);
      }
      else {
        // this.showError("Invalid File! Download the Template.");
        this.Clear(true);
        return false;
      }
    };
    if (this.premium_type == "D") {
      this.grid1.data = this.userdefined_premium;
    }
    else {
      this.grid1.data = this.userdefined_premium_supplemental;
    }
    this.grid1.reflow();
    this.UpdateFormulaCells();
    this.isUpload = true;
    this.UpdatePremiumdetails();
    this.showPageError = false;
    this.errorMsg = "";
    // this.toastService.show("Premium uploaded successfully.", { classname: 'bg-success text-light', delay: 5000 });
  }

  private isValidState(stateCode, stateDesc, sheet) {
    if (sheet == 1) {
      if (stateCode == this.supplemental_row_code && (stateDesc === '' || stateDesc == null))
        stateDesc = "Supplemental Premium Volume";
    }
    if (stateCode == this.userdefined_row_code && (stateDesc === '' || stateDesc == null))
      stateDesc = "Total User Defined Columns-Suppl";

    var rowInfo = this.rows.filter(p => {
      return p.Code == stateCode;
    });
    if (rowInfo == null || rowInfo.length === 0) {
      this.showError("Not able to fetch State information. State Code - " + stateCode);
      return false;
    }
    if (rowInfo[0].Name == stateDesc) {
      return true;
    }
    else {
      this.showError("State details in position [" + stateCode + "] is invalid. Please use the template file for upload.");
      return false;
    }
  }

  public exportExcel() {
    this.grid1.endEdit();
    var workbook = new Workbook();
    var sheetname = this.premium_type == "D" ? "Direct Premiums" : "Supplemental Premiums";
    var worksheet = workbook.worksheets().add(sheetname);

    worksheet.displayOptions.panesAreFrozen = true;
    worksheet.displayOptions.frozenPaneSettings.frozenRows = 1;
    worksheet.displayOptions.frozenPaneSettings.frozenColumns = this.const_fixed_columns;

    var font: IWorkbookFont;
    font = workbook.styles().normalStyle.styleFormat.font;
    font.name = "Arial Unicode MS";
    font.height = 10 * 20;

    this.WriteColumnHeadersToExcel(worksheet);

    //worksheet.columns(0)
    var data = this.grid1.data;
    for (let row = 0; row < data.length; row++) {
      for (let col = 0; col < this.udcolumns.length; col++) {
        var columnInfo = this.udcolumns[col];
        var cell = worksheet.rows(row + 1).cells(col);
        if (col == 0) {
          cell.cellFormat.fill = CellFill.createSolidFill("#6EA3CC");
        }
        if (columnInfo.columnNumber == 0 || columnInfo.columnNumber == 2) {
          cell.value = String(data[row][col]);
        }
        else {
          cell.value = data[row][col];
          cell.cellFormat.formatString = "#,##0";
        }

        //Avoid adding user defined and supplemental premiums in last cell of last row
        var isLastCell = false;
        if (columnInfo.columnNumber == this.udcolumns.length - 1 &&
          data[row][this.const_statecode_column] == this.total_row_code) {
          isLastCell = true;
        }

        if (this.IsTotalRowData(parseInt(data[row][this.const_row_number_column])) && col >= this.const_fixed_columns) {
          cell.cellFormat.fill = CellFill.createSolidFill("#CECED2");
          cell = this.setCellBorder(cell, "#000000");
          var rowformula = this.GetTotalRowFormula(parseInt(data[row][this.const_row_number_column]));
          if (rowformula.trim().length > 0) {
            var formulastring = this.getRowFormula(rowformula, row, col);
            cell.applyFormula(formulastring);
          }
        }

        if (columnInfo != null && columnInfo.cellTypeId == 'C') {
          if (columnInfo.cellformulae.length > 0) {
            var formula = this.getFormula(columnInfo, row + 2, true, isLastCell);
            cell.applyFormula(formula);
          }
          cell.cellFormat.fill = CellFill.createSolidFill("#CECED2");
          cell = this.setCellBorder(cell, "#000000");
        }

        //Avoid calculating the total for user defined and supplemental premium for the last column
        if (columnInfo.columnNumber == this.udcolumns.length - 1 &&
          (data[row][this.const_statecode_column] == this.userdefined_row_code ||
            data[row][this.const_statecode_column] == this.supplemental_row_code)) {
          cell.value = "";
        }

        if (data[row][this.const_statecode_column] == this.AssumedFromNonAffiliates_row_code ||
          data[row][this.const_statecode_column] == this.supplemental_row_code) {
          if (columnInfo.columnNumber >= this.const_fixed_columns &&
            (cell.value == null || cell.value == 0)) {
            cell.value = "";
          }
        }

        if (this.IsExclusive(data[row], columnInfo.columnNumber)) {
          cell.cellFormat.fill = CellFill.createSolidFill("#BADDFF");
          cell = this.setCellBorder(cell, "#000000");
        }

      }
    }
    workbook.setCurrentFormat(WorkbookFormat.Excel2007);
    ExcelUtility.save(workbook, "Premium Report");
    this.isDownloading = false;
  }

  private getRowFormula(rowInfo, rowIndex, colIndex) {
    var formula = "";
    if (rowInfo.indexOf(',') > 0) {
      var split = rowInfo.split(',');
      var formulastring = [];
      for (let index = 0; index < split.length; index++) {
        const element = split[index];
        formulastring.push(this.getRowFormulaString(element, rowIndex, colIndex));
      }
      formula = "=" + formulastring.join("+");
    }
    else {
      formula = "=" + this.getRowFormulaString(rowInfo, rowIndex, colIndex);
    }
    return formula;
  }

  private setCellBorder(cell, colString) {
    let color = new Color();
    color.colorString = colString;
    cell.cellFormat.leftBorderColorInfo = new WorkbookColorInfo(color);
    cell.cellFormat.leftBorderStyle = CellBorderLineStyle.Default;
    cell.cellFormat.topBorderColorInfo = new WorkbookColorInfo(color);
    cell.cellFormat.topBorderStyle = CellBorderLineStyle.Default;
    cell.cellFormat.bottomBorderColorInfo = new WorkbookColorInfo(color);
    cell.cellFormat.bottomBorderStyle = CellBorderLineStyle.Default;
    cell.cellFormat.rightBorderColorInfo = new WorkbookColorInfo(color);
    cell.cellFormat.rightBorderStyle = CellBorderLineStyle.Default;
    return cell;
  }

  private WriteColumnHeadersToExcel(worksheet) {
    let white = new Color();
    white.colorString = "#FFFFFF";
    for (let col = 0; col < this.udcolumns.length; col++) {
      var cell = worksheet.rows(0).cells(col);
      if (col == 0) {
        worksheet.columns(col).setWidth(50, 3);
      }
      else {
        worksheet.columns(col).setWidth(150, 3);
      }
      cell.value = this.udcolumns[col].columnName;
      cell.cellFormat.fill = CellFill.createSolidFill("#6EA3CC");
      cell.cellFormat.alignment = HorizontalCellAlignment.Center;
      cell.cellFormat.verticalAlignment = VerticalCellAlignment.Center;
      cell.cellFormat.font.colorInfo = new WorkbookColorInfo(white);
      cell.cellFormat.font.bold = true;
      cell = this.setCellBorder(cell, "#C0C0C0");
    }
  }

  public dataPasted(processedData) {
    this.updateRecords(processedData);
  }

  public editStart(data: IGridEditEventArgs) {
    this.UpdateFormulaCells();
    var flag = this.IsTotalRowData(parseInt(data.rowData[0]));
    data.cancel = flag;
    const currRow = this.grid1.rowList.find((row) => row.key === data.rowID);
    const currCell = currRow.cells.find(
      (cell) => cell.cellID.columnID === data.cellID.columnID
    );

    this.editedCellID = data.cellID;
    this.editedCell = currCell;

    this.editedCell.nativeElement.addEventListener('keydown', this.onKeydown, true);
  }

  private onInputFocus(event){
    event.cell.editMode = true;
    setTimeout(() => {
      let input = document.getElementById("input") as HTMLInputElement;
      if (input !== null && input !== undefined) {
        input.focus();
        input.select();
      }
      }, 0);
  }

  private onKeydown = (event: KeyboardEvent) => {
    const eventKey = event.key.toLowerCase();
    if (
      eventKey === 'arrowup' ||
      eventKey === 'arrowdown' ||
      eventKey === 'arrowright' ||
      eventKey === 'arrowleft' ||
      eventKey === 'enter'
    ) {
      if (eventKey !== 'enter')
        this.grid1.cellSelection= (event.shiftKey)? 'multiple': 'single';
      else
        this.grid1.cellSelection = 'single';

      event.stopPropagation();
      event.preventDefault();
      switch (eventKey) {
        case 'enter':
          this.arrowkeynavigate(this.editedCellID.rowIndex + 1, this.editedCellID.columnID);
          break;
        case 'arrowright':
          this.arrowkeynavigate(this.editedCellID.rowIndex, this.editedCellID.columnID + 1);
          break;
        case 'arrowleft':
          this.arrowkeynavigate(this.editedCellID.rowIndex, this.editedCellID.columnID - 1);
          break;
        case 'arrowup':
          this.arrowkeynavigate(this.editedCellID.rowIndex - 1, this.editedCellID.columnID );
          break;
        case 'arrowdown':
          this.arrowkeynavigate(this.editedCellID.rowIndex + 1, this.editedCellID.columnID );
          break;
      }

      if(event.shiftKey && eventKey !== 'enter'){
          const cellRange = this.selectMultipleCells()
          setTimeout(() => {
            const range = { rowStart: cellRange.rowStart, rowEnd: cellRange.rowEnd, columnStart: cellRange.columnStart, columnEnd: cellRange.columnEnd };
            this.grid1.selectRange(range);
          }, 0);
      }
    }
  };

  public arrowkeynavigate(rowindex, columnid) {
    this.grid1.endEdit();
    this.grid1.cellSelection='multiple';
    this.grid1.navigateTo(
      rowindex,
      columnid,
      obj => {
        obj.target.activate();
        obj.target.setEditMode(true);
        this.grid1.tbody.nativeElement.focus();
        requestAnimationFrame(() => {
          this.grid1.notifyChanges();
        });
      }
    );
    this.UpdateFormulaCells();

  }

  private selectMultipleCells() {
    if (this.grid1.selectedCells.length > 0) {
      const rowIndexes = [];
      const columnIDs = [];

      for (const cell of this.grid1.selectedCells) {
        const rowIndex = cell.id.rowIndex;
        const columnID = cell.id.columnID;

        rowIndexes.push(rowIndex);
        columnIDs.push(columnID);
      }
      // Find minimum and maximum values for rows and columns
      const minRowIndex = Math.min(...rowIndexes);
      const maxRowIndex = Math.max(...rowIndexes);
      const minColumnID = Math.min(...columnIDs);
      const maxColumnID = Math.max(...columnIDs);

      return {
        rowStart: minRowIndex,
        rowEnd: maxRowIndex,
        columnStart: minColumnID,
        columnEnd: maxColumnID,
      };
    }
    return {};
  }

  private IsEditable() {
    if (this.grid1.selectedCells.length == 0) {
      return false;
    }
    if (this.isReadOnly) {
      return false;
    }
    //validating cells
    for (let index = 0; index < this.grid1.selectedCells.length; index++) {
      const element = this.grid1.selectedCells[index];
      if (element.editable == false) {
        // console.log("cell is not editable");
        return false;
      }
      //Fetching cell formula
      var columnIndex = element.column.index;
      var columnInfo = this.udcolumns[columnIndex];
      if (columnInfo.cellTypeId == "C") {
        // console.log("Calculated column will not be editable");
        return false;
      }
      var rowIndex = element.row.index;
      if (this.rows[rowIndex].cellTypeId == "C") {
        // console.log("Calculated row will not be editable");
        return false;
      }
    }
    return true;
  }

  public enterEditMode(evt) {
    if (this.IsEditable() == false) {
      return;
    }

    //Cut operation
    if (evt.ctrlKey == true && evt.keyCode == 88) {
      // console.log("Ctrl+x");

      var invalidCells = this.grid1.selectedCells.filter(p => {
        return p.column.index < this.const_fixed_columns
      });
      if (invalidCells.length == 0) {
        document.execCommand('copy');
        for (let index = 0; index < this.grid1.selectedCells.length; index++) {
          const cell = this.grid1.selectedCells[index];
          this.grid1.data[cell.row.index][cell.column.index] = null;
        }
        this.UpdateFormulaCells();
        this.isDirty = true;
      }
    }

    if (evt.ctrlKey == true && evt.keyCode == 67) {
      if (this.grid1.selectedCells.length > 0) {
        const rowsToCopy = [];
        let minRowIndex = Infinity;
        let minColumnID = Infinity;

        // Find the minimum rowIndex among the selected cells
        for (const cell of this.grid1.selectedCells) {
          const rowIndex = cell.id.rowIndex;
          const columnID = cell.id.columnID;

          if (rowIndex < minRowIndex) {
            minRowIndex = rowIndex;
          }

          if (columnID < minColumnID) {
            minColumnID = columnID;
          }
        }

        for (const cell of this.grid1.selectedCells) {
          const rowIndex = cell.id.rowIndex; // Adjust rowIndex to start from 1st row (0th index)
          const columnID = cell.id.columnID;
          const cellValue = this.grid1.data[rowIndex][columnID];
          const value = cellValue !== null ? cellValue.toString() : '';

          if (!rowsToCopy[rowIndex - minRowIndex]) {
            rowsToCopy[rowIndex - minRowIndex] = [];
          }
          rowsToCopy[rowIndex - minRowIndex][columnID - minColumnID] = value;
        }
        const clipboardData = rowsToCopy
        .map(row => row.join('\t'))
        .join('\n');

        // Copy the data to the clipboard
        navigator.clipboard.writeText(clipboardData)
          .then(() => {
            //you can give copied to clipboard messge here if needed
          })
          .catch((error) => {
            this.showError("Failed to copy data to clipboard: " + error);
          });
      }
    }


    //Allowing to type values by selecting cell
    if (this.grid1.selectedCells.length == 1) {
      if (this.grid1.selectedCells[0] && this.grid1.selectedCells[0].active &&
        isNaN(evt.key) == false && this.IsEditable() &&
        evt.key != "Enter" && evt.key != "ArrowRight" && evt.key != "ArrowLeft"
      ) {
        this.isTypingOverGridCell = true;
        const _cell = this.grid1.selectedCells[0];
        this.prevGridCellValue = this.grid1.data[_cell.row.index][_cell.column.index];
        //this.grid1.selectedCells[0].update(evt.key);
        setTimeout(() => {
          this.grid1.selectedCells[0].editMode=true;
          this.selectText = false;
          this.isTypingOverGridCell = false;
        }, 0);
      } else if (this.grid1.selectedCells[0] && this.grid1.selectedCells[0].active && evt.key == "Escape") {
        this.grid1.selectedCells[0].update(this.prevGridCellValue);
        this.grid1.endEdit();
        this.UpdateFormulaCells();
      }
    }
  }

  public enterGridEditMode(args: IGridKeydownEventArgs) {
    const evt: KeyboardEvent = args.event as KeyboardEvent;

    if (this.IsEditable() == false) {
      if (evt.key == "Enter") {
        this.NavigateCell(args, evt.key);
      }
      else {
        return;
      }
    }

    //Preventing up/down arrow keys
    if ((evt.key == "ArrowUp" || evt.key == "ArrowDown") &&
      this.grid1.selectedCells[0] &&
      this.grid1.selectedCells[0].editMode
    ) {
      evt.preventDefault();
    }

    if (this.grid1.selectedCells.length == 1) {
      if (evt.key == "Enter" && this.grid1.selectedCells[0] && this.grid1.selectedCells[0].editMode == false) {
        this.NavigateCell(args, evt.key);
      }
      if (evt.key == "ArrowRight" && this.grid1.selectedCells[0] && this.grid1.selectedCells[0].editMode) {
        this.grid1.endEdit();
        this.NavigateCell(args, evt.key);
      }
      if (evt.key == "ArrowLeft" && this.grid1.selectedCells[0] && this.grid1.selectedCells[0].editMode) {
        this.grid1.endEdit();
        this.NavigateCell(args, evt.key);
      }
    }
  }

  private NavigateCell(args, key) {
    const target: IgxGridCell = args.target as IgxGridCell;
    var rowIndex = 0;
    var colIndex = 0;
    if (key == "Enter") {
      rowIndex = target.row.index + 1;
      colIndex = target.column.visibleIndex;
    }
    else if (key == "ArrowRight") {
      rowIndex = target.row.index;
      colIndex = target.column.visibleIndex + 1;
    }
    else if (key == "ArrowLeft") {
      rowIndex = target.row.index;
      colIndex = target.column.visibleIndex - 1;
    }
    args.cancel = true;
    this.grid1.navigateTo(
      rowIndex,
      colIndex,
      obj => {
        obj.target.activate();
        obj.target.setEditMode(false);
        this.grid1.tbody.nativeElement.focus();
        requestAnimationFrame(() => {
          this.grid1.notifyChanges();
        });
      }
    );
  }

  public onEditCompleted(data: any) {

    var rowindex = data.cellID.rowIndex;
    var colIindex = data.cellID.columnID;
    if (data.newValue != null && (data.newValue > 0 || data.newValue < 0)) {
      var value = Math.round(parseFloat(data.newValue));
      if (value > 99999999 || value < -99999999) {
        this.isvalidgrid = false;
        this.showError("Value cannot be greater than 99,999,999 or less than -99,999,999.");
        //data.newValue = data.oldValue;
        data.newValue = null;
        this.grid1.data[rowindex][colIindex] = null;
        this.UpdateFormulaCells();
        this.commit();
        return;
      }
      else {
        this.showPageError = false;
        this.grid1.data[rowindex][colIindex] = value;
        data.newValue = value;
      }
    }
    else if (data.newValue == null || data.newValue == 0) {
      data.newValue = null;
      this.grid1.data[rowindex][colIindex] = null;
    }
    if (this.premium_type == "S") {
      // this.UpdateFormulaCells(); //For updating the supplemental premium total
      // var _old = data.oldValue == null ? 0 : data.oldValue;
      // var _new = data.newValue == null ? 0 : data.newValue;
      // var diff = parseInt(_new) - parseInt(_old);
      // this.UpdateSPremiumInfoInDPremium(rowindex, diff, colIindex);
    }
    if (data.newValue != data.oldValue) {
      this.isDirty = true;
    }
    this.editedCell.nativeElement.removeEventListener('keydown', this.onKeydown, true);
    this.UpdateFormulaCells();
    this.commit();
  }

  public onCellFocus(event) {
    if (this.selectText == true) {
      event.target.select();
    }
    this.selectText = true;
  }

  public onCellSelect(data: any) {
    this.onInputFocus(data);
    var rowIndex = data.cell.row.index;
    var columnIndex = data.cell.column.index;

    //Fetching cell name
    this.cellName = this.getColName(columnIndex - 1, rowIndex);

    //Fetching cell formula
    var columnInfo = this.udcolumns.filter(p => {
      return p.columnNumber == columnIndex;
    });

    if (columnInfo != null && Array.isArray(columnInfo) && columnInfo.length > 0) {
      this.cellFormula = this.getFormula(columnInfo[0], rowIndex, false, false);
    }

    //Avoid showing formula for user defined and supplemental premium for the last column
    if (columnInfo && columnInfo.length > 0) {
      if (columnInfo[0].columnNumber == this.udcolumns.length - 1 &&
        (this.grid1.data[data.cell.rowIndex][this.const_statecode_column] == this.userdefined_row_code ||
          this.grid1.data[data.cell.rowIndex][this.const_statecode_column] == this.supplemental_row_code)) {
        this.cellFormula = "";
      }
      this.prevGridCellValue = "";
    }
  }

  public downloadData(isPDF) {
    this.grid1.endEdit();
    if (this.isDirty) {
      this.confirmDialogService.confirmThis_withClose('Premium Report', 'Do you want to save changes?', () => {
        this.UpdateFormulaCells();
        this.UpdatePremiumdetails();
        this.download(isPDF);
      }, () => {
        this.isDirty = false;
        this.Clear(true);
        this.UpdateFormulaCells();
        this.download(isPDF);
      }, () => {
        return;
      });
    }
    else {
      // this.Clear();
      // this.UpdateFormulaCells();
      this.download(isPDF);
    }
  }

  public download(isPDF) {
    if (isPDF) {
      this.generatePDF();
    }
    else {
      this.isDownloading = true;
      setTimeout(() => {
        this.exportExcel();
      }, 100);
    }
  }

  private getFormula(columnInfo, rowIndex, IsExcelExport, IsLastCell) {
    var formula = "";
    if (columnInfo != null && columnInfo.cellformulae != null && columnInfo.cellformulae.length > 0) {
      if (columnInfo.cellformulae.indexOf(',') > 0) {
        var split = columnInfo.cellformulae.split(',');
        var formulastring = [];
        for (let index = 0; index < split.length; index++) {
          const element = split[index];
          formulastring.push(this.getFormulaString(element, rowIndex, IsExcelExport));
        }
        formula = "=" + formulastring.join("+");
      }
      else {
        formula = "=" + this.getFormulaString(columnInfo.cellformulae, rowIndex, IsExcelExport);
      }
    }
    return formula;
  }
  private getFormulaString(formula, rowIndex, IsExcelExport) {
    var formulastring = "";
    var formula = formula.split(":");
    var formulaCellName1 = "";
    var formulaCellName2 = "";
    if (IsExcelExport) {
      formulaCellName1 = this.getColName(formula[0], rowIndex);
      formulaCellName2 = this.getColName(formula[1], rowIndex);
    }
    else {
      formulaCellName1 = this.getColName(formula[0] - 1, rowIndex);
      formulaCellName2 = this.getColName(formula[1] - 1, rowIndex);
    }
    formulastring = "Sum(" + formulaCellName1 + ":" + formulaCellName2 + ")";
    return formulastring;
  }

  private getRowFormulaString(formula, rowIndex, colIndex) {
    var formulastring = "";
    var formula = formula.split(":");
    var formulaCellName1 = this.getColName(colIndex, (formula[0] - 4));
    var formulaCellName2 = this.getColName(colIndex, (formula[1] - 4));
    formulastring = "Sum(" + formulaCellName1 + ":" + formulaCellName2 + ")";
    return formulastring;
  }

  public updateRecords(processedData: any[]) {
    if (this.grid1.selectedCells.length == 0) {
      return;
    }

    // if (this.grid1.crudService.inEditMode) {
    this.grid1.endEdit();
    // }

    if (this.isReadOnly) {
      return;
    }

    this.isDirty = true;
    let columnIsSelected = false;
    let cell = this.grid1.selectedCells[0];
    let columnField;
    if (this.grid1.selectedColumns().length > 0) {
      this.grid1.verticalScrollContainer.scrollPosition = 0;
      this.grid1.cdr.detectChanges();
      columnIsSelected = true;
      columnField = this.grid1.selectedColumns()[0].field;
    }
    //Copied only one cell and pasting to multiple cells
    if (processedData.length == 1 && processedData[0].length == 1 && this.grid1.selectedCells.length > 1) {
      setTimeout(() => {
        const columns = this.grid1.visibleColumns;
        let currentCell = processedData[0].shift();

        var selectedRanges = this.grid1.getSelectedRanges();
        for (let range = 0; range < selectedRanges.length; range++) {
          const selectedrange = selectedRanges[range];
          for (let rowindex = selectedrange.rowStart; rowindex <= selectedrange.rowEnd; rowindex++) {
            for (let colindex = Number(selectedrange.columnStart); colindex <= selectedrange.columnEnd; colindex++) {
              if (columns[colindex].editable == true && this.rows[rowindex].cellTypeId != "C" && currentCell != undefined) {
                var value = Math.round(parseFloat(currentCell)) == 0 ? null : Math.round(parseFloat(currentCell));
                // blocking paste option - cell value more than 8 digit
                if ((value > 99999999 || value < -99999999) == false) {
                  this.grid1.data[rowindex][colindex] = isNaN(value) ? null : value;
                }
                else{
                  this.showError("Value cannot be greater than 99,999,999 or less than -99,999,999.");
                }
              }
            }
          }
        }
        this.grid1.cdr.detectChanges();
        this.grid1.tbody.nativeElement.focus();
        this.commit();
        this.UpdateFormulaCells();
      }, 100);
    }
    else {
      setTimeout(() => {
        if (columnIsSelected) {
          cell = this.grid1.getCellByColumn(0, columnField);
        }
        const pk = this.grid1.primaryKey;
        if (!cell) { return; }
        var rowIndex = cell.row.index;
        // const rowPkValue = cell.row.rowData[pk];
        const cellIndex = cell.column.visibleIndex;
        const columns = this.grid1.visibleColumns;
        let newRowsIndex = 1;
        const updatedRecsPK = [];
        for (const curentDataRow of processedData) {
          const rowData = {};

          const data = this.grid1.filteredSortedData.length ? this.grid1.filteredSortedData : this.grid1.data;
          const dataRec = data[rowIndex];
          const rowPkValue = dataRec ? dataRec[pk] : this.grid1.data.length + newRowsIndex;
          rowData[pk] = rowPkValue;
          for (let j = 0; j < columns.length; j++) {
            let currentCell;
            if (j >= cellIndex) {
              currentCell = curentDataRow.shift();
              if (currentCell != null) {
                currentCell = currentCell.replace(/,/g, '');
              }
            }
            const colKey = columns[j].field;
            if (columns[j].editable == true && this.rows[rowIndex].cellTypeId != "C" && currentCell != undefined) {
              if (parseInt(colKey) >= this.const_fixed_columns) {
                var value = Math.round(parseFloat(currentCell)) == 0 ? null : Math.round(parseFloat(currentCell));
                var valRounded = isNaN(value) ? null : value;
                if (valRounded != null && (valRounded > 0 || valRounded < 0)) {
                  // blocking paste option - cell value more than 8 digit
                  if ((valRounded > 99999999 || valRounded < -99999999) == false) {
                    rowData[colKey] = valRounded;
                    //Selecting the cells
                    const range = { rowStart: rowIndex, rowEnd: rowIndex, columnStart: j, columnEnd: j };
                    this.grid1.selectRange(range);
                  }
                  else{
                    this.showError("Value cannot be greater than 99,999,999 or less than -99,999,999.");
                  }
                }
              }
              else {
                rowData[colKey] = currentCell || (!!dataRec ? dataRec[colKey] : null);
              }
            }
            else {
              rowData[colKey] = dataRec[colKey];
            }
          }
          if (!dataRec) {
            // no rec to update, add instead
            rowData[pk] = rowPkValue;
            this.grid1.addRow(rowData);
            newRowsIndex += 1;
            // continue;
          }
          this.grid1.updateRow(rowData, rowPkValue);
          this.grid1.cdr.detectChanges();
          updatedRecsPK.push(rowPkValue);
          rowIndex++;
        }

        // for (const pkVal of updatedRecsPK) {
        //   const row = this.grid1.getRowByKey(pkVal);
        //   if (row) {
        //     row.nativeElement.style["font-style"] = "italic";
        //     row.nativeElement.style.color = "gray";
        //   }
        // }
        this.grid1.tbody.nativeElement.focus();
        this.commit();
        this.UpdateFormulaCells();
      }, 100);
    }
    this.grid1.selectionService.clearAllSelectedColumns();
  }

  public removeSorting($event) {
    this.grid1.columns.forEach((col) => {
      if (!(col.field === $event.fieldName)) {
        this.grid1.clearSort(col.field);
      }
    });
  }

  public onColumnInit(column: IgxColumnComponent) {

  }

  public getColWidth(colNumber) {
    if (colNumber == this.const_row_number_column) {
      return "50px";
    }
    else if (colNumber == this.const_state_column) {
      return "130px";
    }
    else if (colNumber == this.const_statecode_column) {
      return "85px";
    }
  }

  public updateStatus() {
    this.udcolumns.forEach((res) => this.updateData(res));
  }

  public updateData(res) {
    //updating the first 3 columns as Pinned.
    res.IsPinned = res.columnNumber < this.const_fixed_columns ? true : false;

    //Updating editable status of cell
    res.editable = this.isEditableCell(res);

    //Updating the datatype
    res.dataType = res.columnNumber < this.const_fixed_columns ? "" : "number";
  }

  generatePDF() {
    this.grid1.endEdit();
    this.isLoading = true;
    var temp_rows = this.rows.filter(p => {
      return p.cellTypeId == "C"
    });
    var premium = this.premium_type == "D" ? this.userdefined_premium : this.userdefined_premium_supplemental;
    var premiumtype = this.premium_type == "D" ? "" : " (Supplemental)";
    let params: printParams = new printParams(this.udcolumns, temp_rows, premium, [1, 2], 13, this.currentYear, this.prfNumber, this.prfName, true, this.columns, premiumtype);
    this.pdfService.params = params;
    this.pdfService.generate();
    this.isLoading = false;
  }

  openUploadPremium() {
    const modalRef = this.modalService.open(UploadPremiumComponent);
    modalRef.result.then((result) => {
      if (result) {

        this.uploadExcelDataToGrid(result);
      }
    });
  }

  public isEditableCell(column) {
    if (column.columnNumber < this.const_fixed_columns) {
      return false;
    }
    else if (column.cellTypeId == 'C') {
      return false;
    }
    return true;
  }

  public commit() {
    this.grid1.transactions.commit(this.grid1.data);
  }

  private getColName(colIndex, rowIndex) {
    var s = "";
    while (colIndex >= 0) {
      s = String.fromCharCode(colIndex % 26 + 65) + s;
      colIndex = Math.floor(colIndex / 26) - 1;
    }
    if (s.length > 0) {
      s += rowIndex;
    }
    return s;
  }

  public get undoEnabled(): boolean {
    return this.grid1.transactions.canUndo;
  }

  public get redoEnabled(): boolean {
    return this.grid1.transactions.canRedo;
  }

  public get hasTransactions(): boolean {
    return this.grid1.transactions.getAggregatedChanges(false).length > 0;
  }

  private IsTotalRowData(rowNumber) {
    var rowInfo = this.rows.filter(function (p) {
      return p.rowNumber == parseInt(rowNumber);
    });
    return (rowInfo.length > 0 && rowInfo[0].cellTypeId == 'C') == true ? true : false;
  }

  private GetTotalRowFormula(rowNumber) {
    var rowInfo = this.rows.filter(function (p) {
      return p.rowNumber == parseInt(rowNumber);
    });
    return (rowInfo.length > 0 && rowInfo[0].cellTypeId == 'C') == true ? rowInfo[0].cellformulae : '';
  }

  private isTotalRow = (rowData: any, columnKey: any): boolean => {
    if (columnKey < this.const_fixed_columns) {
      return false;
    }
    var flag = this.IsTotalRowData(parseInt(rowData[0]));
    return flag;
  }

  public verifydata() {
    var isvalid = true;

    for (let d = 0; d < this.userdefined_premium.length; d++) {
      const element = this.userdefined_premium[d];
      var i = 0;
      for (var prop in element) {
        if (parseInt(prop) != i) {
          this.showError("Invalid data in user defined direct premium. Row - " + (d + 1) + ". Missing column - " + (i + 1));
          isvalid = false;
          return false;
        }
        i++;
      }
    }

    for (let d = 0; d < this.userdefined_premium_supplemental.length; d++) {
      const element = this.userdefined_premium_supplemental[d];
      var i = 0;
      for (var prop in element) {
        if (parseInt(prop) != i) {
          this.showError("Invalid data in user defined supp premium. Row - " + (d + 1) + ". Missing column - " + (i + 1));
          isvalid = false;
          return false;
        }
        i++;
      }
    }
    if (isvalid) {
      this.showError("Data is valid");
      return true;
    }
  }

  private IsExclusive = (rowData: any, columnKey: any): boolean => {
    return this.udef_excl_cells_service.IsExclusiveCell(rowData[0], columnKey, this.udcolumns, this.const_fixed_columns, this.columns);
  }

  public IsTotalColumn = (rowData: any, columnKey: any): boolean => {
    var columnInfo = this.udcolumns.filter(function (e) {
      return e.columnNumber == columnKey
    });
    return columnInfo != null && columnInfo[0].cellTypeId == 'C' ? true : false;
  }

  public IsFirstColumn = (rowData: any, columnKey: any): boolean => {
    return columnKey == this.const_row_number_column ? true : false;
  }

  public cellClass = {
    "firstRowColor": this.IsFirstColumn,
    "totalColumn": this.IsTotalColumn,
    "IsTotalRow": this.isTotalRow,
    "IsExclusive": this.IsExclusive
  }

  private switchTab(tabName) {
    var tablinks = document.getElementsByClassName("tablinks");
    for (var i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    if (tabName == "direct") {
      this.premium_type = "D";
      this.grid1.data = this.userdefined_premium;
      tablinks[0].className += " active";
    }
    else {
      this.premium_type = "S";
      this.grid1.data = this.userdefined_premium_supplemental;
      tablinks[1].className += " active";
    }
    // evt.currentTarget.className += " active";
  }

  public showtab(tabName) {
    this.gridClearSelection();
    //validating if the user clicks the same tab
    if ((this.premium_type == "D" && tabName == "direct") ||
      (this.premium_type == "S" && tabName == "supplemental")) {
      return;
    }
    if (this.isvalidgrid == false) {
      this.isvalidgrid = true;
      return;
    }
    if (this.isDirty) {
      this.confirmDialogService.confirmThis_withClose('Premium Report', 'Do you want to save changes?', () => {
        this.switchTab(tabName);
        this.UpdateFormulaCells();
        this.UpdatePremiumdetails();
      }, () => {
        this.isDirty = false;
        this.userdefined_premium = JSON.parse(JSON.stringify(this.direct_premium_copy));
        this.userdefined_premium_supplemental = JSON.parse(JSON.stringify(this.supplemental_premium_copy));
        this.switchTab(tabName);
        this.UpdateFormulaCells();
      }, () => {
        //Close button
        return;
      });
    }
    else {
      this.switchTab(tabName);
      this.UpdateFormulaCells();
    }
  }

  onClickedOutside(e: Event) {
    this.gridClearSelection();
  }

  gridClearSelection() {
    this.grid1.endEdit();
    // for (let index = 0; index < this.grid1.selectedCells.length; index++) {
    //   this.grid1.selectedCells[index].active = false;
    // }
    this.grid1.clearCellSelection();
  }

  hasUnsavedData() {
    this.grid1.endEdit();
    return this.isDirty;
  }

  openPageChangeModal() {
    const modalRef = this.modalService.open(UnsavedmsgModalComponent);
    modalRef.componentInstance.name = 'New';

    modalRef.result.then((result) => {
      if (result) {
        this.UpdatePremiumdetails();
      }
    });
  }

  showmanagecolswindow() {
    const modalRef = this.modalService.open(UserdefinedcolsmodalComponent, { windowClass: "myCustomModalClass" });
    modalRef.result.then((result) => {
      if (result) {
        this.isLoading = true;
        let currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([currentUrl]);
        });
      }
    }, (reason) => {
      this.isLoading = true;
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
    });
  }

  removePrevAlerts(){
    for (var i = this.toastService.toasts.length-1; i >= 0; i--) {
      this.toastService.remove(this.toastService.toasts[i]);
    }
  }

  openuserdefinedcols() {
    if (this.isDirty) {
      this.confirmDialogService.confirmThis_withClose('Premium Report', 'Do you want to save changes?', () => {
        this.UpdatePremiumdetails(true);
      }, () => {
        this.isDirty = false;
        this.showmanagecolswindow();
      }, () => {
        //Close button
        return;
      });
    }
    else {
      this.showmanagecolswindow();
    }
  }

  private validateTemplateStructure(worksheet, expectedRows: number, expectedColumns: number): boolean {
    let actualRowCount = 0
    let actualColumnCount =1
    let rowIndex = 1;
    let colIndex =1;
  
    while (worksheet.rows(rowIndex).cells(0).value != null) {
          actualRowCount++;
          rowIndex++;
        }
  
    while (worksheet.rows(0).cells(colIndex).value != null) {
      actualColumnCount++
      colIndex++
    }
    
    if (actualRowCount !== expectedRows) {
      this.showError(
        `Invalid number of rows. Expected ${expectedRows+1}, but found ${actualRowCount+1}. Please use the correct template.`
      );
      return false;
    }
 
    if (actualColumnCount !== expectedColumns) {
      this.showError(
        `Invalid number of columns. Expected ${expectedColumns}, but found ${actualColumnCount}. Please use the correct template.`
      );
      return false;
    }
    return true;
  }
  private validateFormulaCells(worksheet, lockedColumn, lockedRows): boolean {
    
    for (let row = 1; row <= this.rows.length; row++) { 
        const cell = worksheet.rows(row).cells(lockedColumn-1);
        if (!cell.formula) {  
          this.showError(
            'Invalid data! Manually entered value found in ' + worksheet.name + 
            ' locked column ' + (lockedColumn) + ' at row ' + (row + 1) + 
            '. The value should be formula-calculated.'
          );
          return false;
        }
      // Check specific rows for formula-calculated values
      if (lockedRows.includes(row)) {
        for (let col = 3; col < this.udcolumns.length; col++) { 
          const cell = worksheet.rows(row).cells(col);
          if (!cell.formula) {
            this.showError(
              'Invalid data! Manually entered value found in ' + worksheet.name + 
              ' locked row ' + (row + 1) + ' at column ' + (col + 1) + 
              '. The value should be formula-calculated.'
            );
            return false;
          }
        }
      }
    }
    return true;
  }

  private validatelockedCells(worksheet, lockedRows): boolean {
    
    for (let row=1; row<=this.rows.length; row++){
      if (lockedRows.includes(row)){
        for (let col=3; col< this.udcolumns.length-1; col++){
          const cell = worksheet.rows(row).cells(col);
          if(cell.value !== null && cell.value !== undefined){
            this.showError(
              'Invalid data! Manually entered value found in ' + worksheet.name +
                ' locked row ' + (row + 1) + ' at column ' + (col + 1) +
                '. It is a locked cell. '
            );
            return false;
          }
        }
      }
    }
    return true
  }

  private validateA1Cell(workbook): boolean {
    const expectedValue = "PRF Original";
    const sheetCount = workbook.worksheets().count;
 
    for (let sheet = 0; sheet < sheetCount; sheet++) {
      const worksheet = workbook.worksheets(sheet);
      const a1Value = worksheet.rows(0).cells(0).value;
 
      if (a1Value !== expectedValue) {
        this.showError(
          `Invalid template in sheet ${worksheet.name}. use original template file'.`
        );
        return false;
      }
    }
    return true;
  }
}

@Pipe({ name: "modifyHeader" })
export class ModifyHeader implements PipeTransform {
  transform(value: string): string {
    return value;
  }


}
