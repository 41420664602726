import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { environment } from '../environments/environment';
import { HttpService } from './shared/http.service';
import { NEVER } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private httpService: HttpService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (request.url.startsWith(environment.PRODUCTS_URL)) {
      // If the URL matches the excluded domain, directly pass the request without modifying headers
      return next.handle(request);
    }

    if (request.url.startsWith(environment.apiUrl)) {
      const profileSelected = localStorage.getItem('profile_selected_prf');
      if (profileSelected !== 'yes') {
        console.log('Request aborted: profile_selected_prf is not "yes".');
        return NEVER;
      }
    }


    // Retrieve the ticket from cookies
    const ticket = this.getCookie('ticket');

    // Add the ticket to the request headers if it exists
    if (ticket) {
      request = request.clone({
        setHeaders: {
          authorizationToken: `${ticket}`,
          'source': "prf-external",
        },
      });
    } else {
      if (environment.enviroment_code != 'D') {
        this.httpService.logout();
      }
    }

    return next.handle(request);
  }

  private getCookie(name: string): string {
    const nameEQ = name + '=';
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(nameEQ) === 0) {
        return cookie.substring(nameEQ.length, cookie.length);
      }
    }
    return null;
  }
}
