import { Component, OnInit, Input, HostListener } from "@angular/core";
import { OAuthService } from 'angular-oauth2-oidc';
import { EntitlementService } from '../shared/entitlement.service';
import { AppService } from '../app.service';
import { environment } from 'src/environments/environment';
import { HttpService } from '../shared/http.service';
import { Router } from '@angular/router';
import { CompanyService } from '../company/company.service';
import { CookieService } from 'ngx-cookie-service';


declare function loadCustomerSelectionJS(): any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  objectKeys = Object.keys;

  @Input() productLists: any[];
  @Input() menuLists: any[];
  @Input() productTitle;
  @Input() helpUrl;
  @Input() categoryISOProducts: object;
  selProductList;
  scrollTop;
  showingProductDD: boolean = false;
  showingProfile: boolean = false;
  errorMessage: any;
  hover_megamenu;
  customername:string="";
  userISOProducts = [];
 

  constructor(
    private oauthService: OAuthService,
    private entitlementService: EntitlementService,
    private AppService: AppService,
    private httpService: HttpService,
    private companyService: CompanyService,
    private router: Router,
    private cookieService: CookieService

  ) {

  }


  ngOnInit(): void {
     const userId: string = this.httpService.getIsonetValue('userid');
     const ticket: string = this.httpService.getIsonetValue('ticket');
     if(!this.cookieService.get("prd_ctx")){
      this.cookieService.set("prd_ctx",environment.ProductIdAlias)
    }
     this.AppService.getUserProducts(userId, ticket).subscribe({
      next: _userProducts => {

        // populate user products
        this.userISOProducts = _userProducts;
        this.productLists[0].items = this.userISOProducts;
      },
      error: error => this.errorMessage = <any>error
    });
    if(this.productLists[0]){
      this.hover_megamenu = this.productLists[0].categoryTitle;
    }
    console.log("Calling loadCustomerSelectionJS().");
    this.customername = localStorage.getItem('customername');
  }
  @HostListener("window:scroll")
  onWindowScroll() {
    this.scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
  }

  closeProductDD = () => {
    if (this.showingProductDD) this.showingProductDD = false;
  };

  closeProfileMenu = () => {
    if (this.showingProfile) {
      this.showingProfile = false;
    }
  };

  showProductList(list) {
    this.selProductList = list;
  }

  openInNew(url) {

    this.companyService.getTemplateFile("ScreenHelp.pdf").subscribe({
      next: (resp) => {
        if (resp["message"] == "Success") {
          var fileurl = resp["templateurl"];
          window.open(fileurl, "_blank");
        }
      },
      error: (error) => {
        //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    });
  }

  openURl(url) {
    window.open(url, "_self");
  }

  hoverIn(category: string): void {
    this.hover_megamenu = category;

  }

  logout() {
    console.info("header-logout");
    localStorage.removeItem("prfcstid");
    localStorage.setItem("vendorid", "0");
    localStorage.setItem("vendorname", "");
    localStorage.setItem("isAdmin", "");
    localStorage.setItem("minyear", "");
    localStorage.removeItem('routename');
    localStorage.setItem("searchValue", "");
    localStorage.setItem("searchType", "");
    localStorage.setItem("currentyearadmin","");
    localStorage.setItem("currentyearcust","");
    localStorage.setItem("currentprfyear","");
    localStorage.removeItem('prfStatus');
    //if (this.cookieService.check('customerSelectionDialog')) {
    //  console.info("header-customerSelectionDialog");
    //  //this.cookieService.delete('customerSelectionDialog');
    //}
    //if (this.cookieService.check('customerSelectionDomainName')) {
    //  console.info("header-customerSelectionDomainName");
    //  //this.cookieService.delete('customerSelectionDomainName');
    //}
//
    ////set(name: string, value: string, expires?: number | Date, path?: string, domain?: string, secure?: boolean, sameSite?: 'Lax' | 'None' | 'Strict'): void;
    //var domainnameSelected = this.cookieService.get("customerSelectionDomainName");
    ////alert("domainnameSelected" + domainnameSelected)
    //console.info("domainnameSelected")
    //console.info(domainnameSelected)
    //if (domainnameSelected){
    //  let dateString = '1970-01-01T00:00:00'
    //  let newDate = new Date(dateString);
    //  this.cookieService.set("customerSelectionDialog","",newDate,"/",domainnameSelected,true)
    //  this.cookieService.set("customerSelectionDomainName","",newDate,"/",domainnameSelected,true)
    //}
    this.entitlementService.setLoginStatus(false);
    //this.oauthService.logOut();
    // window.location.href = `${environment.PRODUCTS_URL}`;
  }
  getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let cookieName = name + "=";
    let c: string;

    for (let i: number = 0; i < ca.length; i += 1) {
      if (ca[i].indexOf(name, 0) > -1) {
        c = ca[i].substring(cookieName.length + 1, ca[i].length);
        return c;
      }
    }
    return "";
  }
}

