import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CompanyInformationService } from '../data-access/company-information.service';

@Component({
  selector: 'app-populateaddress',
  templateUrl: './populateaddress.component.html',
  styleUrls: ['./populateaddress.component.scss'],
})
export class PopulateaddressComponent implements OnInit {
  @Input() title;
  @Input() companyNo;
  @Output() addressInfo = new EventEmitter<any>();
  Object = Object;

  constructor(
    public activeModal: NgbActiveModal,
    private companyinfoservice: CompanyInformationService,
    private router: Router
  ) {}
  addressList: any[] = [];
  filteredAddressList: any[] = [];
  status: any;
  selectedAddressForTextArea: string = '';
  selectedAddressvalue: string = '';
  showPageError : boolean = false;
  isLoading: boolean = true;
  selectedAddress: any = {}
  ngOnInit() {
    this.fetchPbsAddressDetails();
  }
  onAddressTypeChange(event: any) {
    const selectedAddressType = event.target.value;
    this.clearAddress();
    if (selectedAddressType === 'pdb') {
      this.fetchPdbAddressDetails();
    } else if (selectedAddressType === 'pbs') {
      this.fetchPbsAddressDetails();
    }
  }
  fetchAddressDetails(addressType: string) {
    const fetchMethod = addressType === 'pdb' ? this.companyinfoservice.getPdbAddressDetails : this.companyinfoservice.getPbsAddressDetails;
    fetchMethod.call(this.companyinfoservice, this.companyNo).subscribe(
      (resp) => {
        if (resp['message'] == 'Success') {
          if (resp['data'].length == 0) {
            this.clearAddress();
            this.showPageError = true;
          } else {
            this.setAddressDetails(resp['data']);
          }
        }
      },
      () => {
        this.routetoApiFailure();
      }
    );
  }

  fetchPdbAddressDetails() {
    this.isLoading = true;
    this.fetchAddressDetails('pdb');
  }

  fetchPbsAddressDetails() {
    this.isLoading = true;
    this.fetchAddressDetails('pbs');
  }
  private setAddressDetails(addressDetails: any[]) {
    this.isLoading = false;
    this.addressList = addressDetails;
    if(addressDetails) {
    this.selectedAddress = addressDetails[0];
    this.selectedAddressForTextArea = `Company Number: ${this.selectedAddress.companyno}\nCompany Name: ${this.selectedAddress.companyname}\nAddress: ${this.selectedAddress.address}\nCity: ${this.selectedAddress.city}\nState: ${this.selectedAddress.state}\nZip Code: ${this.selectedAddress.zipcode}`;
    this.filteredAddressList = addressDetails.map(
      (address: any) =>
        `${address.companyno}, ${address.companyname}, ${address.address}, ${address.city}, ${address.state}, ${address.zipcode}`
    );
    this.selectedAddressvalue = this.filteredAddressList[0];
  }
  else{
    this.clearAddress();
  }
  }
  private routetoApiFailure() {
    this.router.navigate(['/apifailure']);
    this.activeModal.close();
  }

  onAddressSelect(event: any) {
    this.isLoading = false;
    const selectedValue = event.value.split(', ');
    this.selectedAddressvalue = selectedValue.join(', ');
    this.selectedAddress = this.addressList.find(
      (address) => address.companyno === selectedValue[0] && address.companyname === selectedValue[1] 
    );
    if (this.selectedAddress) {
      this.selectedAddressForTextArea =  `Company Number: ${this.selectedAddress.companyno}\nCompany Name: ${this.selectedAddress.companyname}\nAddress: ${this.selectedAddress.address}\nCity: ${this.selectedAddress.city}\nState: ${this.selectedAddress.state}\nZip Code: ${this.selectedAddress.zipcode}`;
    }
  }

  populateAddress() {
    this.addressInfo.emit(this.selectedAddress);
    this.activeModal.close();
  }

  clearAddress() {
    this.showPageError = false;
    this.selectedAddressForTextArea = '';
    this.addressList = [];
    this.selectedAddress = {};
    this.filteredAddressList = [];
    this.selectedAddressvalue = '';
  }
}
