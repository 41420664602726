import { Component, Input} from '@angular/core';
import { NgModel } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-renameconfirm',
  templateUrl: './renameconfirm.component.html',
  styleUrls: ['./renameconfirm.component.scss'],
})
export class RenameconfirmComponent {
  comment: string = '';
  showCommentRequiredError: boolean = false;
  showWhitespaceError: boolean = false;
  @Input() title: string;
  @Input() message: string;
  constructor(public activeModal: NgbActiveModal) {}
  validateComment(commentInput: NgModel) {
    const value = commentInput.value || '';
    this.showWhitespaceError = value.length > 0 && value.trim().length === 0;
    if (commentInput.invalid && !commentInput.touched) {
      commentInput.control.markAsTouched();
    }
  }
  submitComment(commentInput: NgModel) {
    if (commentInput.invalid || this.showWhitespaceError) {
      this.showCommentRequiredError = !this.showWhitespaceError;
      commentInput.control.markAsTouched();
      return;
    }
    // Emit the comment and close the modal
    this.showCommentRequiredError = false;
    this.showWhitespaceError = false;
    this.activeModal.close({
      commentSubmitted: this.comment,
      renameConfirmed: true,
    });
  }

  closeModal() {
    this.showCommentRequiredError = false;
    this.showWhitespaceError = false;
    this.activeModal.dismiss({
      commentSubmitted: '',
      renameConfirmed: false,
    });
  }
}
