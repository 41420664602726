import { AfterViewInit, Component} from '@angular/core';
import { AdobelaunchService } from '../_services/adobelaunch.service';

@Component({
  selector: 'app-overflowgroupmessage',
  templateUrl: './overflowgroupmessage.component.html'
})
export class OverflowgroupmessageComponent implements AfterViewInit {

  constructor(private AdobelaunchService:AdobelaunchService) { }

  ngAfterViewInit() {     
    this.AdobelaunchService.EventEndDispatch();
    }
}
