<div>
  <div class="content-header">
    <div class="content-header-text">Visible States and Territories</div>
    <div class="content-header-sub-text">
      Select the States and Territories you want to be able to update by
      default. You can always edit this in the future, your preference will be
      saved for future sessions.
    </div>
    <div class="slider-section grid-2col-select-all">
      <span class="config-select-all-span">
        Select All
      </span>
      <mat-slide-toggle class="custom-slider-select-all" [checked]="isAllRowsSelected" [hideIcon]="true"
        (change)="toggleSelectAll()"></mat-slide-toggle>
    </div>

  </div>
  <div class="scrollable-content">
    <div class="item-list grid-single-col">
      <div *ngFor="let state of rowStates">
        <div class="slider-section grid-2col-list-items">
          <span class="config-item-span" [style.color]="state.selected ? '#004EAA' : 'black'">{{ state.name }}</span>
          <mat-slide-toggle class="custom-slider" [color]="color" [checked]="state.selected" [hideIcon]="true" (change)="toggleSelectRow(state)">
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>
</div>