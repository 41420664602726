<app-loading-screen *ngIf="show">Loading Group data</app-loading-screen>
<app-loading-screen *ngIf="spinnersave">Saving Group data</app-loading-screen>
<form [formGroup]="groupreportingform" (ngSubmit)="onsubmit()" *ngIf="groupreportloaded | async">

  <div style="float: right;display: flex;" *ngIf="!isReadOnly">
    <app-button size="medium" type="submit" buttonType="primary" style="margin-right: 15px;" class="nocursor"
      [disabled]="!groupreportingform.valid || (groupreporting.length ==0 && !isDelete)">Save Data</app-button>
    <!--  <app-button size="medium" buttonType="secondary" (click)="clear()">CLEAR</app-button> -->
  </div>
  <div class="pagetitle">Group Reporting: {{ currentYear }}</div>
  <div *ngIf="compno!==null">
    <span class="pagetitleSecondLayerBold"> {{ prfNumber.toUpperCase() }} </span>:<span class="pagetitleSecondLayer">
      {{ prfName }}</span>
  </div>
  <!-- <div *ngIf="isLocked" class="information">
    <div class="messagetitle">
      <i class="table-material-icons">error</i>
      This form is aleardy submitted. You cannot make changes.
    </div>
  </div>
  <div *ngIf="isReadOnlyAdmin && !isLocked" class="information">
    <div class="messagetitle">
      <i class="table-material-icons">error</i>
      You are not authorized to make changes.
    </div>
  </div> -->

  <br />
  <div>
    <div class="PageLevelError" *ngIf="showPageError">Either NAIC or AMBEST is required</div>
    <div class="PageLevelError" *ngIf="nvalError">Please enter a valid NAIC</div>
    <div class="PageLevelError" *ngIf="avalError">Please enter a valid AMBEST</div>
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">PRF Number</th>
          <th scope="col">Company</th>
          <th scope="col">Reported PRF</th>
          <th>NAIC</th>
          <th>AMBest</th>
          <th *ngIf="!isReadOnly"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of groupreporting;let row=index">
          <td>{{row+1}}</td>

          <td>{{item.No}} <input type="hidden" [(ngModel)]="item.No" formControlName="No_{{row}}" />
            <input type="hidden" [(ngModel)]="item.VisibleTo" formControlName="VisibleTo_{{row}}" />
            <input type="hidden" [(ngModel)]="item.chktext" formControlName="chktext_{{row}}" />
            <input type="hidden" [(ngModel)]="item.TakenBy" formControlName="TakenBy_{{row}}" />
          </td>

          <td>{{ item.Name }} <input type="hidden" [(ngModel)]="item.Name" formControlName="Name_{{row}}" />
          </td>
          <td class="titleBold">

            <ng-container *ngIf="item.chktext=='Include';else second">

              <div *ngIf="item.chkvalue ==false">
                <label class="customcheck" [ngClass]="{nocursort: isReadOnly}">
                  <input [attr.disabled]="isReadOnly ? 'true' : null" type="checkbox" [(ngModel)]="item.chkvalue"
                    checked='unchecked' (click)="onNativeChange($event,row)" formControlName="chkvalue_{{row}}"/>
                  <div style="color :black;font-weight:normal"> Include</div>
                  <span class="checkmark"></span>
                </label>
              </div>
              <div *ngIf="item.chkvalue ==true">

                <label class="customcheck" [ngClass]="{nocursort: isReadOnly}">
                  <input [attr.disabled]="isReadOnly ? 'true' : null" type="checkbox" checked="checked"
                    [(ngModel)]="item.chkvalue" (click)="onNativeChange($event,row)" formControlName="chkvalue_{{row}}"/>
                  <div style="color :black;font-weight:normal"> Include</div>
                  <span class="checkmark"></span>
                </label>
              </div>
            </ng-container>
            <ng-template #second>

              <div>{{item.chktext}}</div>
            </ng-template>
            <ng-template #third></ng-template>


          </td>
          <td>


            <input [readonly]="isReadOnly" *ngIf="item.chkvalue == true" [(ngModel)]="item.NAIC"
              (keypress)="validateInput($event)" (keyup)="onchange()" style="text-align:right;height: 30px;"
              formControlName="NAIC_{{row}}" class="form-control input-sm" size="5" />
          </td>
          <td>
            <input [readonly]="isReadOnly" *ngIf="item.chkvalue == true" (keyup)="onchange()" [(ngModel)]="item.AMBest"
              (keypress)="validateInput($event)" style="text-align:right;height: 30px;" formControlName="AMBest_{{row}}"
              class="form-control input-sm" size="5" />
          </td>

          <td *ngIf="!isReadOnly">
            <div *ngIf="(item.TakenBy =='' || item.TakenBy ==compno)  && item.No.startsWith('NEW');else default"
              class='delete'>

              <span class="table-material-icons" (click)="deletegroupreport(item)">delete</span>
            </div>

            <ng-template #default>
              <div class='delete_disabled'>
                <span class="table-material-icons">delete</span>
              </div>
            </ng-template>
          </td>
        </tr>

      </tbody>
    </table>

  </div>
  <div *ngIf="!isReadOnly" class='edit' style="float: right;" (click)="add()"> <span
      class="table-material-icons">add</span> Add Row
  </div>
</form>
<app-confirm-dialog></app-confirm-dialog>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>