import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { CompanylistcopyprioryearModel} from 'src/app/company/commpany.model';
import { CompanyService } from 'src/app/company/company.service';
import { ConfirmDialogService } from 'src/app/confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'app-copyfromprioryear',
  templateUrl: './copyfromprioryear.component.html',
  styleUrls: ['./copyfromprioryear.component.scss']
})
export class CopyfromprioryearComponent implements OnInit {
  showsubmittedcompanies: boolean = false;
  showcompaniesaftercopy: boolean = false;
  currentYear: number;
  show: boolean = false;
  companylist=[];
  companybackup=[];
  allchecked:boolean;
  selectedCompanyList:CompanylistcopyprioryearModel[]=[]
  showPageError: boolean = false;
  pageError: string = "";
  isReadOnlyAdmin: boolean;
  groupcode:string ="";
  duplicate :boolean =false;
  duplicatecompanylist=[]
  companyfiltered :CompanylistcopyprioryearModel[]=[]
  searchvalue :string ="";
  usermessage : string ="";

  constructor(private companyService: CompanyService,private router: Router,private appService: AppService, private confirmDialogService: ConfirmDialogService) { }

  ngOnInit(): void {
    this.currentYear=parseInt(localStorage.getItem("currentprfyear"));
    this.isReadOnlyAdmin = this.appService.isReadonlyAdmin;
  }
  checkAllCheckBox(ev: any) {

		this.companylist.forEach(x => x.checked = ev.target.checked)
    this.selectedCompanyList=[];
    if(this.isAllCheckBoxChecked(true)){
    this.companylist.forEach(cm => {
    this.selectedCompanyList.push(cm);
    })
  }
    else{

      this.selectedCompanyList=[]
    }
	}
  isAllCheckBoxChecked(isfromcheckevent: boolean) {
    if(isfromcheckevent){
		this.allchecked=this.companylist.every(p => p.checked);
    }
    else
    {this.allchecked=false;}
    return this.allchecked
	}
  searchonclick() {
    this.search((<HTMLInputElement>document.getElementById('search')).value)
  }
  onenter(e) {
    this.showPageError = false;
    this.searchvalue=""
    if (e.target.value.length == 0) {
     this.show=false
      this.companylist=[]
      this.selectedCompanyList=[]
    }
    if (e.key === 'Enter' || e.keyCode === 13) {
      this.search(e.target.value);

    }
  }
  OncheckBoxChecked($event){
    const companyno=$event.target.value;
    let selectedcompany = this.companylist.find(x => x.compno == companyno);
    if($event.target.checked){
      this.selectedCompanyList.push(selectedcompany);
      this.isAllCheckBoxChecked(true);
    }
    else{
      this.isAllCheckBoxChecked(false);
      const index: number = this.selectedCompanyList.indexOf(selectedcompany);
      if (index !== -1) {
          this.selectedCompanyList.splice(index, 1);
      }
    }

  }
  search(value) {
    this.searchvalue=""
    value = value.toUpperCase();
    let searchtype = '';
    if (value.length == 4 && /^\d+$/.test(value)) {
      searchtype = 'group'
      this.groupcode = value

    } else if (value.length > 4 && /^[a-z0-9]+$/i.test(value) && /^\d+$/.test(value.substring(0, 4))) {
      searchtype = 'companyno'

    } else {
      this.pageError = "Invalid search criteria. Search with either Group Code or PRF Number"
      this.showPageError = true;
    }
    if (searchtype !== '') {
      this.searchvalue=value
      this.showsubmittedcompanies =true;
      this.companyService.searchCompany(this.currentYear-1, searchtype, value).subscribe((resp) => {
        if (resp["message"] == "Success") {
          this.companylist = resp["companylist"];
          this.showsubmittedcompanies =false;
          this.show =true
          if (this.companylist.length == 0) {
            this.showPageError = true;
            this.pageError = "No result found for this search criteria."
          } else {
            this.companylist.forEach(element => {
              element.compname = this.companyService.toTitleCase(element.compname);
              element.modifiedby = this.companyService.toTitleCase(element.modifiedby);


            });
          }
        }
      },
        (error) => {
          // //console.log(error);
          this.router.navigate(['/apifailure']);
        }
      )

    }
  }
  oncopyfromprioryear(){
    if(this.selectedCompanyList.length >0){
     this.Company_duplicatecheck()
    }
    else if(this.searchvalue ==""){
      this.showPageError = true;
      this.pageError="Please search and select at least one company from the list to copy.";
    }
    else if(this.selectedCompanyList.length ==0){
      this.showPageError = true;
      this.pageError="Please select the required companies from list to copy.";
    }

  }
  Company_duplicatecheck(){
    this.showcompaniesaftercopy = true
    this.showPageError = false;
    this.pageError=""
    this.companyService.prioryearcopy(this.currentYear,this.selectedCompanyList,this.groupcode,true,false,this.duplicatecompanylist).subscribe((resp) => {
        if (resp["message"] == "Success") {
          this.duplicatecompanylist=resp["status_msg"]

          if (typeof (resp["status_msg"]) == 'string') {
            this.showPageError = true;
            this.pageError=resp["status_msg"];
            this.showcompaniesaftercopy = false;
            return;
          }

          this.showcompaniesaftercopy = false
          if(this.duplicatecompanylist.length !=0){
            if(this.duplicatecompanylist.length ==1){
             this.usermessage="One of the selected companies already have data in current year, do you want to overwrite?"
            }
            else if(this.duplicatecompanylist.length>1){
            this.usermessage="Few of the selected companies already have data in current year, do you want to overwrite?"
            }
            this.confirmDialogService.confirmThis_withClose('Prior Year Copy', this.usermessage, () => {
          this.showcompaniesaftercopy = true
          this.copy_company(this.currentYear,this.selectedCompanyList,this.groupcode,false,true,this.duplicatecompanylist)
            }, () => {
              this.showcompaniesaftercopy = true
              if(this.duplicatecompanylist.length == this.selectedCompanyList.length){
                this.showcompaniesaftercopy =false;
                this.selectedCompanyList = []
                this.companylist.forEach(x => x.checked = false)
                this.allchecked=false
                this.duplicatecompanylist=[]
              }
              else{
                for (let val of this.duplicatecompanylist){
                  let selectedcompany = this.selectedCompanyList.find(x => x.compno == val);
                  const index: number = this.selectedCompanyList.indexOf(selectedcompany);
                  if (index !== -1) {
                      this.selectedCompanyList.splice(index, 1);
                  }
                }

                this.copy_company(this.currentYear,this.selectedCompanyList,this.groupcode,false,false,this.duplicatecompanylist)
              }

            }, () => {
              //Close button
              return;
            });
          }
          else{
            this.showcompaniesaftercopy = true
            this.copy_company(this.currentYear,this.selectedCompanyList,this.groupcode,false,false,this.duplicatecompanylist)
          }
        }

      },
      (error) => {
        // //console.log(error);
        this.router.navigate(['/apifailure']);
      }
       );
  }
  copy_company(currentYear,selectedCompanyList,groupcode,duplicatechack,overwrite,duplicatecompanylist){
    this.companyService.prioryearcopy(currentYear,selectedCompanyList,groupcode,duplicatechack,overwrite,duplicatecompanylist).subscribe((resp) => {
      if (resp["message"] == "Success") {
        this.showPageError = true;
        this.pageError=resp["status_msg"];
        this.selectedCompanyList = []
        this.duplicatecompanylist=[]
        this.companylist.forEach(x => x.checked = false)
        this.allchecked=false
        this.showcompaniesaftercopy =false;
      }
    },
    (error) => {
      // //console.log(error);
      this.router.navigate(['/apifailure']);
    }
     );
  }

}
