import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Role } from 'src/app/shared/models/user-model';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss']
})
export class WrapperComponent implements OnInit {
  hasAdminRole = false
  currrWidth = window.innerWidth;
  constructor(private app: AppService) { }
  ngOnInit(): void {
    if (this.app.hasRole(Role.Admin)) {
      this.hasAdminRole = true;
    }
  }


}
