import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-deletecompany',
  templateUrl: './deletecompany.component.html',
  styleUrls: ['./deletecompany.component.scss'],
})
export class DeletecompanyComponent implements OnInit {
  @Input() title;
  @Input() label;
  @Input() companyNo;
  @Input() isCommentRequired;
  @Output() deleteConfirmed = new EventEmitter<{ comment: string }>();

  deleteForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.deleteForm = this.fb.group({
      comment: [
        '',
        this.isCommentRequired
          ? [Validators.required, this.noWhitespaceValidator]
          : [this.noWhitespaceValidator],
      ],
    });
  }
  private noWhitespaceValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (control.value && control.value.trim() === '') {
      return { whitespace: true };
    }
    return null;
  }

  onsubmit(): void {
    if (this.deleteForm.invalid) {
      this.deleteForm.markAllAsTouched();
      return;
    }

    const formData = this.deleteForm.value;

    this.deleteConfirmed.emit({
      comment: formData.comment.trim(),
    });

    this.activeModal.close();
  }
}
