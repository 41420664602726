<app-modal-loading-screen *ngIf="show">Copying Company</app-modal-loading-screen>
<app-modal-loading-screen *ngIf="showvalidation">Validating Group</app-modal-loading-screen>
<app-modal-loading-screen *ngIf="!isLoaded">Loading...</app-modal-loading-screen>
<div *ngIf="isLoaded" class="modal" tabindex="-1" role="dialog" style="display:block!important">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="pagetitle">{{title}}</h4>
        <span class="table-material-icons" (mousedown)="activeModal.dismiss('Cross click')"
          style="cursor: pointer;font-size: 24px;">close</span>
      </div>
      <div class="modal-body">
        <div class="pagetitleSecondLayerBold">{{oldCompanyno}} - {{ oldName }}</div>
        <div class="information"> Group company information will not be copied. </div><br />
        <div class="PageLevelError" *ngIf="showPageError">{{error}}</div>
        <form [formGroup]="copyCompanyForm" (ngSubmit)="onsubmit()">

          <label class="lib-text-field" required>
            <input placeholder=" " maxlength="4" (keyup)="validateGroup($event)" formControlName="newGroup" />
            <span class="lib-placeholder">New Group</span>

            <span class="help-block" *ngIf="
            copyCompanyForm.get('newGroup').errors &&
            (copyCompanyForm.get('newGroup').touched ||
            copyCompanyForm.get('newGroup').dirty)
          ">
              <span *ngIf="copyCompanyForm.get('newGroup').errors" class="inputMsg"><i
                  class="table-material-icons">error</i> Group is
                required</span>

            </span>
          </label>
          <label [ngClass]="'lib-text-field'">
            <input readonly class="fullWidth" placeholder=" " formControlName="groupname" />
          </label>
          <div> * Important: The new PRF will be listed under this group.</div>


          <div [ngClass]="{
            'has-error':
            copyCompanyForm.get('newNumber').errors &&
              (copyCompanyForm.get('newNumber').touched ||
              copyCompanyForm.get('newNumber').dirty)
          }">
            <label class="lib-text-field space" required>
              <input class="fullWidth" placeholder=" " formControlName="newNumber" (keyup)="validateBillNumber($event)"
                required />
              <span class="lib-placeholder">New Number</span>
              <span class="help-block" *ngIf="
              copyCompanyForm.get('newNumber').errors &&
            (copyCompanyForm.get('newNumber').touched ||
            copyCompanyForm.get('newNumber').dirty)
          ">

                <span *ngIf="copyCompanyForm.get('newNumber').errors.required" class="inputMsg"><i
                    class="table-material-icons">error</i> ISO Billing Number is
                  required</span>
              </span>
              <span class="error inputMsg" *ngIf="!hasStatrtedWithGroup && !copyCompanyForm.get('newNumber').errors"><i
                  class="table-material-icons">error</i> ISO
                Billing
                Number should start with {{groupcode}}</span>
              <span class="error inputMsg" *ngIf="!isvalidbillno && hasStatrtedWithGroup"><i
                  class="table-material-icons">error</i> Invalid Billing Number</span>
            </label>
          </div>


          <label class="lib-text-field" required>
            <input placeholder=" " formControlName="newName" />
            <span class="lib-placeholder">New Name</span>
            <span class="help-block" *ngIf="
            copyCompanyForm.get('newName').errors &&
            (copyCompanyForm.get('newName').touched ||
            copyCompanyForm.get('newName').dirty)
          ">
              <span *ngIf="copyCompanyForm.get('newName').errors" class="inputMsg"><i
                  class="table-material-icons">error</i> Name is
                required</span>
            </span>
          </label>
          <label class="lib-text-field">
            <input placeholder=" " [(ngModel)]="naic" formControlName="naic" (keyup)="validateNaic($event)"/>
            <span class="lib-placeholder">NAIC Code</span>
          </label>
          <label class="lib-text-field">
            <input placeholder=" " [(ngModel)]="amBest" formControlName="amBest" (keyup)="validateAMBest($event)"/>
            <span class="lib-placeholder">AM Best</span>
          </label>
          <div>

            <app-button buttonType="primary" type="submit" class="nocursor"
              [disabled]="disable || !copyCompanyForm.valid || validationerror || !isvalidbillno || showPageError || !hasStatrtedWithGroup"
              style="width: 100%;padding-right: 10px;">Save Data
            </app-button>

          </div>
        </form>
      </div>
    </div>
  </div>
</div>
