<app-loading-screen *ngIf="show">Loading Annual Statements</app-loading-screen>
<app-loading-screen *ngIf="spinnersave">Saving Annual Statements</app-loading-screen>
<form [formGroup]="anualstatementsForm" (ngSubmit)="onsubmit()" *ngIf="isolinesoaded && anualstatementoaded | async">
  <div style="float: right;display: flex;">
    <app-button *ngIf="!isReadOnly" size="medium" type="submit" buttonType="primary">Save Data</app-button>
  </div>
  <div class="pagetitle">Annual Statement: {{ currentYear }}</div>
  <div>
    <div *ngIf="compno!==null">
      <span class="pagetitleSecondLayerBold"> {{ prfNumber.toUpperCase() }} </span>:<span class="pagetitleSecondLayer">
        {{ prfName }}</span>
    </div>
    <!-- <div *ngIf="isLocked" class="information">
      <div class="messagetitle">
        <i class="table-material-icons">error</i>
        This form is already submitted. You cannot make changes.
      </div>
    </div>
    <div *ngIf="isReadOnlyAdmin && !isLocked" class="information">
      <div class="messagetitle">
        <i class="table-material-icons">error</i>
        You are not authorized to make changes.
      </div>
    </div> -->

    <br />
    <div class="noteB">Note: Dollar amounts represented as thousands of dollars.</div>
    <br />
    <div class="PageLevelError" *ngIf="pageError"><i class="table-material-icons">error</i> Please select To which ISO
      Line does Aggregate Write-In Apply?
    </div>
    <div>


      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Category</th>
            <th scope="col">Direct</th>
            <th scope="col">Reinsurance From Non-Affiliates</th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of annualStatement;let row=index">


            <td style="width: 80px;">{{item.Text1}} <input type="hidden" name="Text1" name="Text1[{{row}}]" /></td>

            <td style="width: 340px;">{{ item.Text }} <input type="hidden" name="Text" name="Text[{{row}}]" />
            </td>
            <td style="width: 280px;">
              <input [readonly]="isReadOnly || item.Text=='Total'" (focusout)="calculateDirect($event)"
                (keypress)="validateDecimalInput($event)" type="text" style="text-align:right;height: 30px;"
                [(ngModel)]="item.Col1" autocomplete="off" formControlName="Col1_{{row}}" name="Col1[{{row}}]"
                class="form-control" size="5" maxlength="21" />
            </td>
            <td style="width: 280px;">
              <input [readonly]="isReadOnly || item.Text=='Total'" (focusout)="calculateNonDirect($event)"
                [(ngModel)]="item.Col2" style="text-align:right;height: 30px;" (keypress)="validateDecimalInput($event)"
                type="text" autocomplete="off" formControlName="Col2_{{row}}" name="Col2[{{row}}]" class="form-control"
                size="5" maxlength="21" />
            </td>

          </tr>

        </tbody>
      </table>
    </div>

  </div>
  <div style="width:80%; float: left;margin-bottom: 210px;margin-top: 10px;">
    <p style="margin-bottom: 15px;font-size: 12px;">To which ISO Line does Aggregate Write-In Apply?</p>
    <div *ngIf="!isReadOnly;else isolineText">
      <div>



        <app-select-dropdown (onSelect)="getIsoLine($event)">
          <option *ngFor="let lob of isolines; let i = index" value="{{lob.Number}}"
            [attr.selected]="lob.Number == selectedisoline ? true : false">
            {{lob.Value}}
          </option>
        </app-select-dropdown>
      </div>
    </div>
    <ng-template #isolineText>
      <label class="lib-text-field" *ngIf="isoline!=='Select'">
        <input [readonly]="isReadOnly" placeholder=" " value="{{isoline}}" />

      </label>
    </ng-template>



  </div>
</form>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>