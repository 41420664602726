<app-loading-screen *ngIf="showspingetprfyears"
  >Loading Company List</app-loading-screen
>

<div *ngIf="headerDetailsLoaded">
  <app-customer-company-list-header
    [isReadOnlyAdmin]="isReadOnlyAdmin"
    [isAdmin]="isAdmin"
    [groupcode]="groupcode"
    [groupname]="groupname"
    [custid]="custid"
    [currentYear]="currentYear"
    (onClickAddNewForm)="addNewForm()"
  ></app-customer-company-list-header>
  <app-company-list-filter
    [yearList]="yearList"
    [filterStatusList]="filterStatusList"
    [selectedStatus]="selectedStatus"
    [currentYear]="currentYear"
    [isAdmin]="isAdmin"
    [searchValue]="searchValue"
    (onYearChange)="onYearChange($event)"
    (onEntersearchClick)="onenter($event)"
    (onSearchButtonClick)="searchonclick($event)"
    (onStatusFilterChange)="getCompanyForStatus($event)"
  >
  </app-company-list-filter>
  <app-customer-company-list
    [companyList]="paginatedCompanyList$ | async"
    [isReadOnlyAdmin]="isReadOnlyAdmin"
    [searchValue]="searchValue"
    [isAdmin]="isAdmin"
    [currentPage]="currentPage"
    [itemsPerPage]="itemsPerPage"
    [showError]="showPageError"
    [pageErrorMessage]="pageError"
    (onOpenCompanyInformation)="openCompanyInformation($event)"
    (onCommentClick)="openComment($event)"
    (onValidationSummaryClick)="navigateToValidationSummary($event)"
    (onDeleteButtonClick)="deleteCompany($event)"
  >
  </app-customer-company-list>
</div>
<app-company-list-pagination
  [showspingetprfyears]="showspingetprfyears"
  [totalItems]="totalItems"
  [itemsPerPage]="itemsPerPage"
  [currentPage]="currentPage"
  (pageChange)="onPageChange($event)"
>
</app-company-list-pagination>

<app-confirm-dialog></app-confirm-dialog>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
