<app-loading-screen *ngIf="spinnersavecontent">Saving email content</app-loading-screen>
<app-loading-screen *ngIf="spinnerloadcontent">Loading email content</app-loading-screen>

<form [formGroup]="emailconfigForm" >

  <div>
    <div class="information" *ngIf="showPageError">
        <div class="messagetitle"><i class="table-material-icons">error</i> {{pageError}}</div>
    </div>
</div>

<div class="row">
    <div class="form-group col-md-6">
      <div><h5>Annual Premium Submission Reminder - {{ currentYear }}</h5></div>
    </div>
    <div></div>
    <div class="inputcontent" >
      <div class="form-check form-check-inline" >

        <input id="stdon" class="form-check-input"type="radio" title="Click for Standard Submission content"
        (change)="handleEmailTypeChangestd($event)" name="selectemail" value="reminderstandard" formControlName="selectemail"
          checked />
        <label for="stdon" class="form-check-label interactive-links" title="Click for Standard Submission content" > Standard </label>

      </div>
      <div class="form-check form-check-inline" style="margin-left:20px">
        <input id="custon" class="form-check-input" type="radio" title="Click for Custom Submission content"
        (change)="handleEmailTypeChangecust($event)" name="selectemail" value="remindercustom" formControlName="selectemail" />
        <label for="custon" title="Click for Custom Submission content" class="form-check-label interactive-links"  > Custom</label>
      </div>
      <app-button  buttonType="primary" style="margin-left:20px;margin-top: 0px;width: auto;" title="Click for saving Submission & Initial reminder content"
      (click)="savemailcontent(emailconfigForm.value.selectemail,emailconfigForm.value.regularremindercontents,emailconfigForm.value.initialnotificationcontents)" [disabled]="isReadOnlyAdmin" [ngClass]="{
        active: !isReadOnlyAdmin,
        disabled: isReadOnlyAdmin
      }" >Save Email Content</app-button>
    </div>

  </div>
    <editor formControlName="regularremindercontents" (onKeyUp)="trackeditorunsaveddata()" [init]="{
      base_url: '/tinymce',
      browser_spellcheck: true,
      height: 300,
      menubar: false,
      branding: false,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help '
      ],
      toolbar: 'undo redo | formatselect | ' +
      'bold italic backcolor | alignleft aligncenter ' +
      'alignright alignjustify | bullist numlist outdent indent | ' +
      'removeformat | help',
      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
    }">
    </editor>
    <br />
    <div class="row">
    <div aria-disabled="true" style="padding-left:20px">
        <h5>Premium Submission Initial Notification - {{ currentYear }}</h5></div>
        <div></div>
        <!--<div style="margin-left:380px;margin-bottom:10px;margin-top:1px"><button  class="btn btn-primary" class="btn-bg" style="margin-left:20px;margin-top: 0px;width: 200px;"
      (click)="savemailcontent(emailconfigForm.value.selectemail,emailconfigForm.value.regularremindercontents,emailconfigForm.value.initialnotificationcontents)" [disabled]="isReadOnlyAdmin" >SAVE INITIAL CONTENT</button>
    </div>-->
  </div>
    <editor formControlName="initialnotificationcontents" (onKeyUp)="trackeditorunsaveddata()" [init]="{
      base_url: '/tinymce',
      browser_spellcheck: true,
      height: 300,
      menubar: false,
      branding: false,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help '
      ],
      toolbar: 'undo redo | formatselect | ' +
      'bold italic backcolor | alignleft aligncenter ' +
      'alignright alignjustify | bullist numlist outdent indent | ' +
      'removeformat | help',
      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;contenteditable:false }'
    }">
    </editor>
    <!--<button (click)="loghtmltoconsole()">Show HTML</button>-->
      <!--<button class="btn btn-primary" class="btn-bg" style="margin-left: 15px;margin-top: 10px;"
      (click)="savemailcontent()" >SAVE EMAIL CONTENT</button>-->

</form>
