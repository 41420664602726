<app-company-list-header
  [showDeleteLoader]="selectDeleteLoader$ | async"
  [isReadOnlyAdmin]="isReadOnlyAdmin$ | async"
  [isAnyCompaniesSelected]="isAnyCompaniesSelected$ | async"
  [isAllCompaniesSelected]="isAllCompaniesSelected$ | async"
  [yearsLoaded]="yearsLoaded$ | async"
  [showPageError]="selectShowPageError$ | async"
  [pageError]="selectPageError$ | async"
  [currentYear]="selectCurrentYear$ | async"
  [yearList]="yearList$ | async"
  [filterListTypes]="filterListTypes$ | async"
  [selectedListType]="selectedListType$ | async"
  [filterStatusList]="filterStatusList$ | async"
  [companyList]="companyList$ | async"
  [showCompanyLoader]="showCompanyLoader$ | async"
  [showUploadLoader]="selectShowUploadLoader$ | async"
  [selectedFilterStatus]="selectFilterStatusSelected$ | async"
  [selectedFilterStatusIndex]="selectedFilterStatusIndex$ | async"
  [uploadingCompaniesCount]="selectUploadingCompaniesCount$ | async"
  [searchValue]="selectSearchValue$ | async"
  [selectedYear]="selectSelectedYear$ | async"
  [filterStatusSelectedLabel]="selectFilterStatusSelectedLabel$ | async"
  [currentPage]="currentPage"
  (pageChange)="onPageChange($event)"
>
</app-company-list-header>

<app-company-list 
  [showCompanyLoader]="showCompanyLoader$ | async"
  [showDeleteLoader]="selectDeleteLoader$ | async"
  [isReadOnlyAdmin]="isReadOnlyAdmin$ | async"
  [isAnyCompaniesSelected]="isAnyCompaniesSelected$ | async"
  [isAllCompaniesSelected]="isAllCompaniesSelected$ | async"
  [yearsLoaded]="yearsLoaded$ | async"
  [showPageError]="selectShowPageError$ | async"
  [pageError]="selectPageError$ | async"
  [currentYear]="selectCurrentYear$ | async"
  [yearList]="yearList$ | async"
  [filterListTypes]="filterListTypes$ | async"
  [selectedListType]="selectedListType$ | async"
  [filterStatusList]="filterStatusList$ | async"
  [companyList]="paginatedCompanyList$ | async"
  [showNoDataMessage]="selectShowNoDataMessage$ | async"
  (sortChanged)="onSortChange($event)"
>
</app-company-list>

<app-company-list-pagination
  [showCompanyLoader]="showCompanyLoader$ | async"
  [totalItems]="totalItems"
  [itemsPerPage]="itemsPerPage"
  [currentPage]="currentPage"
  (pageChange)="onPageChange($event)">
</app-company-list-pagination>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
