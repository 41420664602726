<div class="lib-content">
  <content class="content">
    <div class="lib-container">
      <div>
        <div class="lib-body">
          <div class="div-background">
            <div class="split-container">
              <div class="left-section">
                <h2 class="heading-css">My Dashboard</h2>
                <div class="div-css1">
                  <div class="label1">Product Update</div>
                  <h2 class="label-h3">My Premium Report Form</h2>
                  <p class="para-css">
                    The Premium Report Form year will automatically be<br />defaulted
                    to the year for request submission and will contain<br />
                    the list of
                    <a
                      class="link-css"
                      routerLink="/dboard"
                      routerLinkActive="true"
                      >Premium Report Forms</a
                    >
                    created.
                  </p>
                </div>
                <div class="div-list-css">
                  <ul>
                    <li>Easy monitor Reports and Validations</li>
                    <li class="list-space-css">
                      Access and save PRF content all in one place
                    </li>
                  </ul>
                </div>

                <div class="div-css1">
                  <button class="vui-btn" (click)="onClickApplicationRoute()">
                    Go to My Premium Report
                  </button>
                </div>
              </div>

              <div class="image-css">
                <div (click)="onClickApplicationRoute()" class="prfimg">
                  <img
                    src="../../../assets/img/landingpage_laptop.svg"
                    alt=""
                    width="608px"
                    height="353px"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="div-css1">
            <div class="label2">Explore</div>
            <div class="split-container1">
              <div class="left-section-div2">
                <div (click)="openHelpDoc()" class="helpdoc-img-div"></div>
              </div>
              <div class="div-width-bottom">
                <div class="label3" (click)="openHelpDoc()">
                  PRF Help Document
                </div>
                <div class="label4">What is Premium Report Form?</div>
                <p class="para-css1">
                  <a class="link-css-div2" (click)="onClickApplicationRoute()"
                    >Premium Report Form (PRF)</a
                  >
                  is an external web portal for<br />Verisk customers to submit
                  their annual premiums which are<br />then reviewed internally
                  by the Verisk accounting team.
                </p>
              </div>
              <div class="right-section-div2">
                <div class="div-quickl-faq">
                  <div class="label6">Quick Links</div>
                  <div class="div-as-line"></div>
                </div>
                                <div *ngIf="isCustomer" class="div-quickl-notif-row">
                                    <i class="table-material-icons" *ngIf="validationStatusCount>0">notifications_active</i>
                                    <i class="table-material-icons" *ngIf="validationStatusCount==0">notifications</i>
                                    <a class="link-css-div3" (click)="onClickValidatingNotification()">Notifications</a>
                                    <div class="notification-count-svg-container">

                                        <svg *ngIf="validationStatusCount>0" class="notification-count-icon" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="12" r="10" fill="#c91818"/></svg>
                                        <div class="digit-overlay-notification">{{validationStatusCount}}</div>
                                    </div>
                                </div>

                <div class="div-quickl-row1">
                  <div>
                    <img
                      src="../../../assets/img/helppage_landingpage_questionmark.svg"
                      alt=""
                      width="20px"
                      height="20px"
                    />
                    <a class="link-css-div3" (click)="openHelpDoc()"
                      >Help Document (PDF)</a
                    >
                  </div>
                  <div class="div-quickl-faq-row2">
                    <img
                      src="../../../assets/img/desc_landingpage.png"
                      alt=""
                      width="20px"
                      height="20px"
                    />
                    <a class="link-css-div3" (click)="onClickApplicationRoute()"
                      >Access Premium Report Form</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="split-container1">
              <div class="left-section-div2">
                <div
                (click)="goToDemoVideoLink()"
                  class="image-css-demovideo"
                ></div>
              </div>
              <div class="div-width-bottom">
                <div class="label3" (click)="goToDemoVideoLink()">
                  Training Demo
                  <img
                    src="../../../assets/img/newicon_landingpage.svg"
                    alt=""
                    width="40px"
                    height="20px"
                  />
                </div>
                <div class="label4"></div>
                <p class="para-css1">
                  This application likely has multiple features and
                  functions.<br />
                  Please watch this
                  <a class="link-css-div2" (click)="goToDemoVideoLink()"
                    >Training Demo</a
                  >
                  to guide you through this<br />process.
                </p>
              </div>
              <div class="div-faq" >
                <div class="right-section-div3" >
                  <div class="div-quickl-faq" *ngIf="showFaq">
                    <div class="label6">FAQs</div>
                    <div class="div-as-line"></div>
                  </div>
                  <div class="div-quickl-faq-row2" *ngIf="showFaq">
                    <mat-expansion-panel
                      [expanded]="true"
                      [togglePosition]="'before'"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title class="link-css-div3">
                          <div>
                            How can i get access to Premium<br />
                            Report Form?
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="label-faq">
                        Premium Reports are available to<br />subscribers or
                        premium members.<br />
                        <a
                          class="link-access-faq"
                          (click)="onClickApplicationRoute()"
                          >Access here</a
                        >.
                      </div>
                    </mat-expansion-panel>
                    <mat-accordion *ngFor="let item of expansionPanelItems">
                      <mat-expansion-panel [togglePosition]="'before'">
                        <mat-expansion-panel-header>
                          <mat-panel-title class="link-css-p-0">
                            <div [innerHTML]="item.title"></div>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="label-faq" [innerHTML]="item.content"></div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </content>
</div>
