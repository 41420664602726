<div *ngIf="isLoaded">
    <div *ngIf="message" class="modal" tabindex="-1" role="dialog" style="display:block!important">
        <div class="modal-dialog" style="width:500px;">
            <div class="modal-content">
                <div class=modal-header>
                    <h4 class="page-title">{{message.title}}</h4>
                    <!-- <h4 class="modal-title">Delete</h4> -->


                </div>
                <div *ngIf="message?.type == 'confirm'" class="modal-body">
                    <div class="commentbox">
                        <p>{{message.text}}</p>
                    </div>
                </div>

                <div class="modal-footer">
                    <app-button size="medium" style="width:70px;" (click)="message.yesFn()" buttonType="primary">Yes
                    </app-button>
                    <app-button size="medium" style="width:70px;padding-right: 5px;" (click)="message.noFn()"
                        buttonType="secondary">No
                    </app-button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="message_withclose" class="modal" tabindex="-1" role="dialog" style="display:block!important">
        <div class="modal-dialog" style="width:500px;">
            <div class="modal-content">
                <div class=modal-header>
                    <h4 class="page-title">{{message_withclose.title}}</h4>
                    <!-- <h4 class="modal-title">Delete</h4> -->

                </div>
                <div *ngIf="message_withclose?.type == 'confirm'" class="modal-body">
                    <div class="commentbox">
                        <p>{{message_withclose.text}}</p>
                    </div>
                </div>

                <div class="modal-footer">
                    <app-button size="medium" style="width:70px;" (click)="message_withclose.yesFn()"
                        buttonType="primary">
                        Yes
                    </app-button>
                    <app-button size="medium" style="width:70px;padding-right: 5px;" (click)="message_withclose.noFn()"
                        buttonType="secondary">No
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</div>