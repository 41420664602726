import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AdminCompanyListState} from './admin-company-list-model';

export const selectAdminCompanyListStateFeature =
  createFeatureSelector<AdminCompanyListState>('adminCompanyList');

export const selectAdminCompanyListState = createSelector(
  selectAdminCompanyListStateFeature,
  (adminCompanyList: AdminCompanyListState) =>
    JSON.parse(JSON.stringify(adminCompanyList))
);

export const selectCurrentYear = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) => adminCompanyList.currentYear
);
export const selectSelectedYear = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) => adminCompanyList.selectedYear
);

export const selectPrfYears = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) => adminCompanyList.yearsList
);

export const selectCompanyList = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) => adminCompanyList.companyList
);

export const selectListTypeSelected = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) => adminCompanyList.listTypeSelected
);

export const selectListTypeSelectedValue = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) =>
    adminCompanyList.listTypeSelected.value
);

export const selectSearchType = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) => adminCompanyList.searchType
);

export const selectSearchValue = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) => adminCompanyList.searchValue
);

export const selectShowCompanyLoader = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) =>
    adminCompanyList.showCompanyLoader
);
export const selectShowUploadLoader = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) => adminCompanyList.showUploadLoader
);

export const selectIsReadOnlyAdmin = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) => adminCompanyList.isReadOnlyAdmin
);

export const selectIsYearsLoaded = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) => adminCompanyList.yearsLoaded
);

export const selectFilterStatusSelected = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) =>
    adminCompanyList.filterStatusSelected
);

export const selectFilterStatusSelectedIndex = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) =>
    adminCompanyList.filterStatusSelectedIndex
);

export const selectFilterStatusList = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) => adminCompanyList.filterStatusList
);

export const selectFilterStatusSelectedLabel = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) => adminCompanyList.filterStatusSelectedLabel
);

export const selectFilterListList = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) => adminCompanyList.filterListType
);

export const selectCompanyId = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) => adminCompanyList.companyId
);
export const selectGroupCode = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) => adminCompanyList.groupCode
);
export const selectPrfName = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) => adminCompanyList.prfName
);
export const selectPrfNumber = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) => adminCompanyList.prfNumber
);

export const selectAllChecked = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) => adminCompanyList.allSelected
);

export const selectDeleteLoader = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) => adminCompanyList.deleteLoader
);

export const selectSubmittedAndUploadedCompanies = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) =>
    adminCompanyList.companyList.filter(
      (adminCompanyListItem) =>
        adminCompanyListItem.status === 'Submitted' ||
        adminCompanyListItem.status === 'Uploaded'
    )
);

export const isAllSubmittedAndUploadedCompaniesSelected = createSelector(
  selectSubmittedAndUploadedCompanies,
  (submittedAndUploadedCompanies) =>
    submittedAndUploadedCompanies.length > 0 &&
    submittedAndUploadedCompanies.every(
      (adminCompanyListItem) => adminCompanyListItem.isSelected
    )
);

export const isAnySubmittedAndUploadedCompanySelected = createSelector(
  selectSubmittedAndUploadedCompanies,
  (submittedAndUploadedCompanies) =>
    submittedAndUploadedCompanies.some(
      (adminCompanyListItem) => adminCompanyListItem.isSelected
    )
);

export const selectQuarter = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) => adminCompanyList.selectedQuarter
);

export const selectCorpId = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) => adminCompanyList.corpId
);

export const selectSelectedCompanies = createSelector(
  selectSubmittedAndUploadedCompanies,
  (submittedAndUploadedCompanies) =>
    submittedAndUploadedCompanies
      .filter((adminCompanyListItem) => adminCompanyListItem.isSelected)
      .map(({ compno, groupcode }) => ({
        companyno: compno,
        groupcode,
        corpId: 1,
      }))
);

export const selectPageError = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) => adminCompanyList.pageError
);
export const selectShowPageError = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) => adminCompanyList.showPageError
);

export const selectUploadingCompanies = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) =>
    adminCompanyList.originalCompanyList.filter(
      (adminCompanyListItem) => adminCompanyListItem.status === 'Uploading'
    )
);

export const selectUploadingCompaniesCount = createSelector(
  selectUploadingCompanies,
  (uploadingCompanies) => uploadingCompanies.length
);

export const selectShowNoDataMessage = createSelector(
  selectAdminCompanyListState,
  (adminCompanyList: AdminCompanyListState) => adminCompanyList.showNoDataMessage
);

