<div class="ntm-modal-container">
  <div class="modal-content" [ngClass]="!nowShowing && 'hiding-modal'">
    <div class="modal-head">
      <p style="margin: 0">{{ modalTitle }}</p>
      <a (click)="closeModal()"><i class="material-icons">close</i></a>
    </div>
    <p class="title-note" style="margin: 0">{{ titleNote }}</p>
    <div class="ntm-data-container"><ng-content></ng-content></div>
  </div>
  <div class="modal-background" (click)="closeModal()"></div>
</div>
