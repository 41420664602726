import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router} from '@angular/router';
import { CompanyService } from 'src/app/company/company.service';
import { ToastService } from '../shared/toast.service'
import { AdobelaunchService } from '../_services/adobelaunch.service';
import { environment } from 'src/environments/environment';
import { HttpService } from '../shared/http.service';
import { ValidationSummaryBase, ValidationSummaryItem } from '../company/validation-summary/data-access/validation-summary-model';
import { ValidationSummaryService } from '../company/validation-summary/data-access/validation-summary-service';
import { noWhitespaceValidator } from './whitespace.validator';

@Component({
  selector: 'app-email-preview',
  templateUrl: './email-preview.component.html',
  styleUrls: ['./email-preview.component.scss']
})
export class EmailPreviewComponent implements OnInit, AfterViewInit {
  constructor(private companyService: CompanyService,
    private router: Router, public toastService: ToastService, private validationSummaryService: ValidationSummaryService,private AdobelaunchService:AdobelaunchService,private httpService: HttpService,private route: ActivatedRoute) {

  }
  jobid: any
  emailForm: UntypedFormGroup;
  currentYear: number;
  prfNumber: string;
  prfName: string;
  groupCode: string;
  compno: any;
  annualstatementlist: any[] = [];
  priorYearComparisonList: any[] = [];
  rowMailDtls: any[] = [];
  show: boolean = true;
  contentBody: string = "";
  multemailsRegex = /^([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}(?:;[\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})*)$/;
  bottomLine: string = '<div style="padding-top: 1px;padding-bottom: 1px;border-bottom: 1px solid Gray;"><br/></div>';
  previousUrl: string;
  prioryearRoute: boolean = false;
  annualstatementRoute: boolean = false;
  validationsummaryRoute: boolean = false;
  tblid: string;
  comparedPrfNumber: string;
  hasAdminRole = false;
  validationSummaryItem$;
  validationSummaryItem: ValidationSummaryBase;
  validationSummaryItems: ValidationSummaryItem[] = [];
  warningFilteredValidationSummaryItems: any[] = [];
  isCompleteSubmission: boolean = false;
  tinymceConfig = {
    base_url: '/tinymce',
    branding: false,
    browser_spellcheck: true,
    height: 500,
    menubar: false,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help',
      'image imagetools',
      'link'
    ],
    toolbar:
      'undo redo | formatselect | bold italic backcolor | alignleft aligncenter ' +
      'alignright alignjustify | bullist numlist outdent indent | removeformat | image | help | link',
    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    paste_data_images: true,
    setup: (editor) => {
      // Listen for keydown and input events
      editor.on('keydown input', (e) => {
        // Allow TinyMCE to handle "Ctrl + A + Backspace" normally
        if (e.type === 'keydown' && (e.key === 'Backspace' || e.key === 'Delete')) {
            setTimeout(() => {
                // After the content is deleted, validate the form control
                const content = editor.getContent().trim();
                this.emailForm.get('emailContent')?.setValue(content);
                this.emailForm.get('emailContent')?.markAsDirty();
                this.emailForm.get('emailContent')?.updateValueAndValidity();
            }, 0);
        }
    });
      // Preserve cursor position
      editor.on('BeforeSetContent', (e) => {
        const bookmark = editor.selection.getBookmark(2, true);
        editor.once('SetContent', () => {
          editor.selection.moveToBookmark(bookmark);
        });
      });
    }
  };
  ngAfterViewInit() {
    this.AdobelaunchService.EventEndDispatch();
    }
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['id']) {
        const id = params['id'];
        this.jobid =id
      }
    });
    this.companyService.getPrfStatus();
    this.removePrevAlerts();
    var Cookiecstid = this.httpService.getIsonetValue("cstid");
    var globalprfcustid=localStorage.getItem('prfcstid')
    if (typeof globalprfcustid!='undefined' && globalprfcustid) {
      if (+globalprfcustid == environment.MasterId || +globalprfcustid == parseInt(environment.ViewerId)) {
        this.hasAdminRole = true;
      }
      else {
        this.hasAdminRole = false;
      }
    }
    else{
      if(Cookiecstid){
        let url=environment.IsoAuthService + "/GetSessionProductGroupMembershipAndCustomerIds/";
        this.httpService.getcustid(url).subscribe({
          next: (resp) => {
            let splitted = resp.toString().split("|");
            let custid=splitted.find(x => x.includes("PRF") )||"";
            if(custid!=""){
              let cstid =custid.toString().split(",")[0];
              localStorage.setItem("prfcstid", cstid);
              if (+cstid == environment.MasterId || +cstid == parseInt(environment.ViewerId)) {
                this.hasAdminRole = true;
              }
              else {
                this.hasAdminRole = false;
              }
            }
          },
          error: (error) => {
            console.log("ISOnet product eligibility check error-Email Preview.")
          }
        });

      }
    }

    this.compno = this.companyService.companyId;
    this.currentYear = this.companyService.currentYear;
    this.prfNumber = this.companyService.prfNumber;
    this.prfName = this.companyService.prfName;
    this.companyService.getgroup().subscribe((value) => {
      this.groupCode = value;
    });
    this.previousUrl = localStorage.getItem("routename");
    this.comparedPrfNumber = localStorage.getItem("comparedprfnumber");
    this.emailForm = new UntypedFormGroup({
      bccaddr: new UntypedFormControl('', [Validators.pattern(this.multemailsRegex)]),
      toaddr: new UntypedFormControl('', [Validators.required, Validators.pattern(this.multemailsRegex)]),
      ccaddr: new UntypedFormControl('', [Validators.pattern(this.multemailsRegex)]),
      subject: new UntypedFormControl('', [Validators.required]),
      phone: new UntypedFormControl('', [Validators.minLength(7), Validators.maxLength(14)]),
      emailContent: new UntypedFormControl('', [Validators.required, noWhitespaceValidator()]),
    });
    this.load();
  }
  load() {
    if (this.previousUrl === 'prioryearcomparison') {
      this.prioryearRoute = true;
      this.loadPriorYearComparison();
      this.tblid = '#tblPriorYear';
    } else if (this.previousUrl === 'annualstatement') {
      this.annualstatementRoute = true;
      this.loadAnnualStatementreport();
      this.tblid = '#tblAnnualSt';
    } else if (this.previousUrl === 'validationsummary') {
      this.validationsummaryRoute = true;
      this.show =true
      this.loadValidationSummaryForEmailPreview();
      this.tblid = '#tblValidationSummary';
    }
  }


  back() {
    this.emailForm.reset();
    localStorage.removeItem('routename');
    if (this.previousUrl === 'prioryearcomparison')
      this.router.navigate(['/prioryearcomparison'])
    else if (this.previousUrl === 'annualstatement') {
        this.router.navigate(['/annualstatementcomparison'])
    }
    else if (this.previousUrl === 'validationsummary') {
      this.router.navigate(['/validationsummary'])
    }
  }


  loadAnnualStatementreport() {
    this.annualstatementlist = [];
    this.companyService.loadAnnualStatementComparison(this.currentYear, this.groupCode, this.compno).subscribe({
      next: (resp) => {
        if (resp["message"] == "Success") {
          this.annualstatementlist = resp["ascomparisondata"];
          let index = this.annualstatementlist.indexOf("mailid");
          this.rowMailDtls = this.annualstatementlist.splice(index, 1);
          if ((this.rowMailDtls.length > 0)) {
            this.contentBody = this.rowMailDtls[0]['body'];
            this.emailForm.patchValue({
              toaddr: this.rowMailDtls[0]['mailid'],
              ccaddr: this.rowMailDtls[0]['cc'] || "",
              subject: this.rowMailDtls[0]['subject'],
              phone: this.rowMailDtls[0]['insurerno'],
              emailContent: this.contentBody
            })
          }
          this.show = false;
        }
      },
      error: (error) => {
        this.router.navigate(['/apifailure']);
      }
    })
  }

  loadPriorYearComparison() {
    this.priorYearComparisonList = [];
    this.companyService.loadPriorYearComparisonDetails(this.currentYear, this.groupCode, this.compno, this.comparedPrfNumber).subscribe({
      next: (resp) => {
        if (resp["message"] == "Success") {
          this.priorYearComparisonList = resp['comparisondata']['prior_comparison_data'];
          this.rowMailDtls = resp['comparisondata']['email_info'][1];
          if ((this.rowMailDtls)) {
            this.contentBody = this.rowMailDtls['body'];
            this.emailForm.patchValue({
              toaddr: this.rowMailDtls['mailid'],
              subject: this.rowMailDtls['subject'],
              ccaddr: this.rowMailDtls['cc'] || "",
              phone: this.rowMailDtls['insurerno'],
              emailContent: this.contentBody
            })
          }
          this.show = false;
        }
      },
      error: (error) => {
        this.router.navigate(['/apifailure']);
      }
    })
  }

  loadValidationSummaryForEmailPreview() {
    this.companyService.getEmailPreviewDetailsForValidationSummary(this.currentYear, this.groupCode, this.compno,this.jobid).subscribe({
      next: (resp) => {
        if (resp["message"] == "Success") {
          const warningFilteredValidationSummaryItemstest = this.validationSummaryService.mapResponseItemToWarningValidationSummaryItem(resp['data']['validationdata'])
          this.warningFilteredValidationSummaryItems = warningFilteredValidationSummaryItemstest;
          this.rowMailDtls = resp['data']['emailinfo'];
          this.isCompleteSubmission = resp['data']['isCompleteSubmission']
          if ((this.rowMailDtls)) {
            this.contentBody = this.rowMailDtls['body'];
            this.emailForm.patchValue({
              toaddr: this.rowMailDtls['mailid'],
              subject: this.rowMailDtls['subject'],
              ccaddr: this.rowMailDtls['cc'] || "",
              phone: this.rowMailDtls['insurerno'],
              emailContent: this.rowMailDtls['body']
            })
          }
          this.show = false;
        }
      },
      error: (error) => {
        this.router.navigate(['/apifailure']);
      }
    })
  }
  sendMail() {
    this.toastService.remove(this.toastService.toasts[0]);
    let To = this.emailForm.get("toaddr").value;
    let Cc = this.emailForm.get("ccaddr").value;
    let Bcc = this.emailForm.get("bccaddr").value;
    let Subject = this.emailForm.get("subject").value;
    let phone = this.emailForm.get("phone").value;
    let msg = ''
    
    if(this.validationsummaryRoute){
      this.contentBody = this.emailForm.get("emailContent").value;
    }
    const tableElement = document.querySelector(this.tblid);
    let table = tableElement ? tableElement.outerHTML : '';
    const style = `
    <style>
    @import url('https://fonts.googleapis.com/icon?family=Material+Icons');
    table {
      width: 100%;
      border-collapse: collapse;
    }
    td {
      border-bottom: 1px solid #d3d4d5 !important;
      border-top: 0px !important;
      padding: 6px !important;
      font-family: "Roboto-Regular", sans-serif;
      font-size: 12px;
      text-align: left;
      color: #333333;
      height: 35px;
      vertical-align: middle;
    }
    thead, th {
      border-bottom: 4px solid #ddebf0 !important;
      font-size: 14px !important;
      font-family: "Roboto-Bold", "Roboto", sans-serif !important;
      font-weight: bold;
      color: #333333;
      padding: 6px !important;
    }
    .blue-flag{
      color: #004EAA;font-size: 18px;
    }
    .prioryear-tr{
    background-color: #F8F8F8;
    }
    </style>
  `;
  const validationSummaryStyle = 
  `<style>
  table tbody {
    font-size: smaller;
    }
    </style>`;
    if (this.validationsummaryRoute) {
      table = table + validationSummaryStyle;
    }
    else{
      table = table +style
    }
    if (!this.isCompleteSubmission)
      {msg = this.contentBody + '<P align=left>' + phone + '</P>' + this.bottomLine + table;}

    else
    { msg = this.contentBody + '<P align=left>' + phone + '</P>';}


    this.companyService.sendMail(To, Cc, Bcc, Subject, msg).subscribe({
      next: (resp) => {
        if (resp["message"] == "Success") {
          if(this.previousUrl === 'validationsummary'){
            this.updateValidationEmailDetails(resp["status_msg"]);
          }
          else{
            this.successAlert(resp["status_msg"]);
          }
        }
      },
      error: (error) => {
        this.router.navigate(['/apifailure']);
      }
    });
  }
  onsubmit() {
    this.sendMail();
  }
  updateValidationEmailDetails(message: string) {
    this.companyService
      .updateValidationEmailCounter(this.jobid, this.compno)
      .subscribe({
        next: (updateresp) => {
          if (updateresp['message'] == 'Success') {
            console.log('Validation email counter updated successfully');
            this.successAlert(message);
          }
        },
        error: (error) => {
          this.router.navigate(['/apifailure']);
        }
      });
  }
  removePrevAlerts(){
    for (let i = this.toastService.toasts.length-1; i >= 0; i--) {
      this.toastService.remove(this.toastService.toasts[i]);
    }
  }
  successAlert(msg: string) {
    window.scrollTo(0, 0);
    this.toastService.show(msg, { classname: 'bg-success text-light', delay: 3000 });
  }
}
