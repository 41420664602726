<div class="loading-screen">
    <div class="loading">
        <div class="spinBackground">
            <img src="../../../assets/img/Eclipse.svg" />

            <div class="loading-content">
                <p>
                    <ng-content></ng-content>
                </p>
            </div>
        </div>
    </div>

</div>