import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from '../company/company.service';
import { AppService } from '../app.service';
import { Role } from 'src/app/shared/models/user-model';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss'],
})
export class LandingpageComponent {
  showFaq = false;
  isCustomer = false;
  custid: any;
  yearsLoaded: Promise<boolean>;
  yearList: number[] = [];
  currentYear: number;
  searchYear: number = 0;
  companyList: any[] = [];
  groupcode: string;
  validationStatusCount: number = 0;
  attemptedUrl: string = "";

  constructor(
    private router: Router,
    private companyService: CompanyService,
    private appService: AppService,
    private store: Store,
    private route:ActivatedRoute
  ) { }
  ngOnInit(): void {
    this.handleLoginRedirect()
    if (!this.appService.isReadonlyAdmin) {
      this.navigateToRedirectToValidationSummary();
    }
    sessionStorage.removeItem('redirectToValidationSummaryUrl');
    if (!this.appService.hasRole(Role.Admin)) {
      this.isCustomer = true;
      this.getCurrentYear();
    }
  }
  onClickApplicationRoute() {
    this.router.navigate(['/dboard']);
  }
  goToDemoVideoLink() {
    window.open(
      'https://d36kpfvw2oo91u.cloudfront.net/FUN_013/story.html',
      '_blank',
      'noopener'
    );
  }


  handleLoginRedirect(){
    let attemptedUrl = sessionStorage.getItem(
      'redirect'
    );
    if(attemptedUrl){
      attemptedUrl = attemptedUrl.replace('/redirect/','/')
      const url = new URL(attemptedUrl);
      var  prfyear = url.searchParams.get('year');
      this.companyService.setCurrentYear(parseInt(prfyear));
      const routeurl = url.pathname + url.search;
      console.log(routeurl);
      if (routeurl.endsWith('addcompanymodal')) {
        this.clearLocalStorage()
      }
      this.router.navigateByUrl(routeurl);
      sessionStorage.removeItem('redirect');
    }

  }

  clearLocalStorage() {
    localStorage.setItem('companyId', '');
    localStorage.removeItem('isLocked');
    localStorage.removeItem('lockForValidation');
    localStorage.setItem('groupreportenabled', '');
    localStorage.setItem('prfName', '');
    localStorage.removeItem('prfNumber');
    localStorage.removeItem('prfStatus');
  }


  openHelpDoc() {
    this.companyService.getTemplateFile('ScreenHelp.pdf').subscribe({
      next: (resp) => {
        if (resp['message'] == 'Success') {
          let fileurl = resp['templateurl'];
          window.open(fileurl, '_blank');
        }
      },
      error: (error) => {
        this.router.navigate(['/apifailure']);
      }
    });
  }

  onClickValidatingNotification() {
    localStorage.setItem('notificationStatus', 'Validating');
    this.router.navigate(['/dboard']);
  }

  expansionPanelItems = [
    {
      title: 'Where can I find my IRD Number?',
      content:
        "Check any official tax<br /> documents you've received from<br /> Inland Revenue in the past. Your<br /> IRD number is often included on<br />correspondence related to taxes <br />or financial matters.",
    },
  ];

  getCurrentYear() {
    this.companyService.getcustid().subscribe((value) => {
      if (value !== '' && value !== null) {
        this.custid = value;
        this.companyService.getusergroup(value).subscribe({
          next: (resp) => {
            if (resp['message'] == 'Success' && !resp['usergroupinfo'][0].overflow_status) {
              this.groupcode = resp['usergroupinfo'][0].groupid;
              this.initializeYearData();
            } else if (resp['message'] === 'Success' && resp['usergroupinfo'][0].overflow_status) {
              this.router.navigate(['/overflowcompany']);
            }
          },
          error: (error) => {
            this.companyList = [];
          }
        });
      }
    });
  }

  private  initializeYearData() {
    this.companyService.getPrfYears('').subscribe({
      next: (resp) => {
        if (resp['message'] == 'Success') {
          this.yearList = resp['prfyearlist'];
          this.currentYear = this.yearList[0];
          this.yearsLoaded = Promise.resolve(true);
          this.companyService.setCurrentYear(this.currentYear);
          this.searchYear = parseInt(
            localStorage.getItem('currentyearcust')
          );
          if (this.searchYear) {
            this.currentYear = this.searchYear;
          }
          this.getCompanyForYear(this.currentYear);
        }
      },
      error: (error) => {
        this.companyList = [];
      }
    });
  }

  getCompanyForYear(year: any) {
    if (this.groupcode !== '' && this.groupcode !== null) {
      this.currentYear = parseInt(year);
      this.companyService
        .getCompanyList(this.currentYear, this.groupcode)
        .subscribe({
          next: (resp) => {
            if (resp['message'] == 'Success') {
              this.companyList = resp['companylist'];
              this.getValidationStatusCount();
            }
          },
          error: (error) => {
            this.companyList = [];
          }
        });
    } else {
      console.log('Invalid group code : ' + this.groupcode);
      this.companyList = [];
    }
  }

  getValidationStatusCount() {
    if (this.companyList.length > 0) {
      const validatingCompanies = this.companyList.filter(
        (company) => company.status === 'Validating'
      );
      this.validationStatusCount = validatingCompanies.length;
    } else {
      this.validationStatusCount = 0;
    }
  }
  navigateToRedirectToValidationSummary() {
    this.attemptedUrl = sessionStorage.getItem(
      'redirectToValidationSummaryUrl'
    );
    console.log(this.attemptedUrl);
    const containsredirectToValidationSummaryUrl = this.attemptedUrl?.includes(
      '/redirecttovalidationsummary'
    );
    if (containsredirectToValidationSummaryUrl && this.attemptedUrl) {
      const queryParamsString = this.attemptedUrl.split('?')[1];
      const params = new URLSearchParams(queryParamsString);
      // Check if the required parameters are present
      const hasGroupCode = params.has('groupcode');
      const hasYear = params.has('year');
      const hasCompanyId = params.has('companyid');
      if (hasGroupCode && hasYear && hasCompanyId) {
        const url = new URL(this.attemptedUrl);
        let routeurl = url.pathname + url.search;
        console.log(routeurl);
        this.router.navigateByUrl(routeurl);
      }
    }
  }
}
