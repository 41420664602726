import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { CompanyService } from '../../company/company.service';

import { IgxExcelExporterService } from '@infragistics/igniteui-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { Subject, Subscription, interval, Observable, of } from 'rxjs';
import { delay, switchMap, takeUntil, takeWhile, take } from 'rxjs/operators';
import {
  resetValidationJobId,
  setPrfLock,
  setValidationLock,
} from 'src/app/company/premiumreport/data-access/premium-report.action';
import { AdobelaunchService } from '../../_services/adobelaunch.service';
import { AppService } from '../../app.service';
import { ConfirmDialogService } from '../../confirm-dialog/confirm-dialog.service';
import { ToastService } from '../../shared/toast.service';
import { AdminService } from '../admin.service';
import * as companyActions from './data-access/admin-company-list-actions';
import {
  listTypeListData,
  statusList,
} from './data-access/admin-company-list-model';
import {
  isAllSubmittedAndUploadedCompaniesSelected,
  isAnySubmittedAndUploadedCompanySelected,
  selectAllChecked,
  selectCompanyList,
  selectCurrentYear,
  selectDeleteLoader,
  selectFilterListList,
  selectFilterStatusList,
  selectFilterStatusSelected,
  selectFilterStatusSelectedIndex,
  selectFilterStatusSelectedLabel,
  selectIsReadOnlyAdmin,
  selectIsYearsLoaded,
  selectListTypeSelectedValue,
  selectPageError,
  selectPrfYears,
  selectSearchValue,
  selectSelectedYear,
  selectShowCompanyLoader,
  selectShowNoDataMessage,
  selectShowPageError,
  selectShowUploadLoader,
  selectUploadingCompanies,
  selectUploadingCompaniesCount,
} from './data-access/admin-company-list-selectors';
import { resetValidationSummaryData } from 'src/app/company/validation-summary/data-access/validation-summary-actions';
import { Sort } from '../../admin/table-sort';

@Component({
  selector: 'app-admin-company-list',
  templateUrl: './admin-company-list.component.html',
  styleUrls: ['./admin-company-list.component.scss'],
})
export class AdminCompanyListComponent implements OnInit, OnDestroy {
  groupcode: string;
  groupname: string;

  selectCurrentYear$ = this.store.select(selectCurrentYear);
  yearList$ = this.store.select(selectPrfYears);
  companyList$ = this.store.select(selectCompanyList);
  selectedListType$ = this.store.select(selectListTypeSelectedValue);
  showCompanyLoader$ = this.store.select(selectShowCompanyLoader);
  isReadOnlyAdmin$ = this.store.select(selectIsReadOnlyAdmin);
  yearsLoaded$ = this.store.select(selectIsYearsLoaded);
  filterStatusList$ = this.store.select(selectFilterStatusList);
  selectedFilterStatusIndex$ = this.store.select(
    selectFilterStatusSelectedIndex
  );
  filterListTypes$ = this.store.select(selectFilterListList);
  selectAllChecked$ = this.store.select(selectAllChecked);
  selectDeleteLoader$ = this.store.select(selectDeleteLoader);
  isAllCompaniesSelected$ = this.store.select(
    isAllSubmittedAndUploadedCompaniesSelected
  );
  isAnyCompaniesSelected$ = this.store.select(
    isAnySubmittedAndUploadedCompanySelected
  );
  selectPageError$ = this.store.select(selectPageError);
  selectShowPageError$ = this.store.select(selectShowPageError);
  selectShowUploadLoader$ = this.store.select(selectShowUploadLoader);
  selectUploadingCompanies$ = this.store.select(selectUploadingCompanies);
  selectUploadingCompaniesCount$ = this.store.select(
    selectUploadingCompaniesCount
  );
  selectSearchValue$ = this.store.select(selectSearchValue);
  selectSelectedYear$ = this.store.select(selectSelectedYear);
  selectFilterStatusSelected$ = this.store.select(selectFilterStatusSelected);
  selectFilterStatusSelectedLabel$ = this.store.select(selectFilterStatusSelectedLabel);
  selectShowNoDataMessage$ = this.store.select(selectShowNoDataMessage);
  private destroy$ = new Subject<void>();
  subscription: Subscription;

  statusType: string;
  paginatedCompanyList$: Observable<any[]>;
  fullCompanyList: any[] = [];
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalItems: number;

  constructor(
    private store: Store,
    private appService: AppService,
    private excelExportService: IgxExcelExporterService,
    public toastService: ToastService,
    private companyService: CompanyService,
    private adminservice: AdminService,
    private confirmDialogService: ConfirmDialogService,
    private router: Router,
    private modalService: NgbModal,
    private AdobelaunchService: AdobelaunchService
  ) {}
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.removePrevAlerts();
    this.adminservice.disablesidemenu.next(true);
    this.companyService.getgroup().subscribe((value) => {
      this.groupcode = value;
    });
    this.companyService.getgroupname().subscribe((value) => {
      this.groupname = value;
    });

    this.clearLocalStorage();
    this.store.dispatch(setPrfLock({ value: false }));
    this.store.dispatch(setValidationLock({ value: false }));

    this.store.dispatch(resetValidationJobId());
    this.store.dispatch(resetValidationSummaryData());

    this.companyService.getLocalStorage();

    const isReadonlyAdmin =  this.appService.isReadonlyAdmin;
    if (!isReadonlyAdmin) {
      this.store.dispatch(
        companyActions.setFilterStatusList({ filterStatusList: statusList })
      );
      this.store.dispatch(
        companyActions.setFilterListTypes({ filterListTypes: listTypeListData })
      );
    }

    if (isReadonlyAdmin) {
      this.store.dispatch(companyActions.setIsReadonlyAdmin({value: isReadonlyAdmin}));
    }

    this.store.pipe(select(selectPrfYears), take(1)).subscribe(loaded => {
      if (!loaded.length) {
        this.store.dispatch(companyActions.loadPrfYears({ groupCode: '' }));
      } else {
        this.store.dispatch(companyActions.loadCompanySilently());
      }
    });

    this.router.events.subscribe((event: NavigationStart) => {
      if (
        event.navigationTrigger === 'popstate' &&
        this.router.url == '/dboard'
      ) {
        this.router.navigate(['/']);
      }
    });
    const delayDuration = 1500; //1.5 seconds

  this.subscription = this.selectUploadingCompaniesCount$
      .pipe(
        // Introduce a delay before starting the observable chain
        delay(delayDuration),
        switchMap((count) =>
          interval(1000).pipe(
            // Repeat the inner observable up to 8 times, including the first one
            takeWhile((_, index) => index < 8),
            // Continue until there are no more uploading companies
            takeWhile(() => count > 0),
            // Unsubscribe when the component is destroyed
            takeUntil(this.destroy$)
          )
        )
      ).subscribe(() => this.loadLatestCompanyList());
    
    this.companyList$.subscribe(companyList => {
      this.totalItems = companyList.length;

      if (this.totalItems <= this.itemsPerPage) {
        this.currentPage = 1;
      }

      this.paginatedCompanyList$ = this.paginate(companyList, this.itemsPerPage, this.currentPage);
      this.fullCompanyList = companyList;
    });
    
  }

  paginate(array: any[], itemsPerPage: number, currentPage: number): Observable<any[]> {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedArray = array.slice(startIndex, startIndex + itemsPerPage);
    return of(paginatedArray);
  }

  onSortChange(sortDetails: { property: string, order: string }): void {
    const sort = new Sort();
    this.fullCompanyList.sort(sort.StartSort(sortDetails.property, sortDetails.order));
    this.currentPage = 1;
    this.paginatedCompanyList$ = this.paginate(this.fullCompanyList, this.itemsPerPage, this.currentPage);
  }

  onPageChange(page: number) {
    this.currentPage = page;
    this.paginatedCompanyList$ = this.paginate(this.fullCompanyList, this.itemsPerPage, this.currentPage);

  }

  loadLatestCompanyList() {
    console.log('callInitRefresh..');
    this.store.dispatch(companyActions.loadLatestCompanyList());
  }

  clearLocalStorage() {
    localStorage.setItem('companyId', '');
    localStorage.setItem('year', '');
    localStorage.setItem('prfNumber', '');
    localStorage.setItem('prfName', '');
    localStorage.setItem('groupcode', '');
    localStorage.setItem('isLocked', '');
    localStorage.setItem('lockForValidation', '');
    localStorage.setItem('isCreateComapny', '');
    localStorage.setItem('lockForValidation', '');
  }

  removePrevAlerts() {
    for (let i = this.toastService.toasts.length - 1; i >= 0; i--) {
      this.toastService.remove(this.toastService.toasts[i]);
    }
  }
}
