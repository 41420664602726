import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent implements OnInit {
  @Input() buttomTitle = "Submit";
  @Input() size: string = "";
  @Input() type : string ="button"
  @Input() buttonType: string = "";
  @Input() disabled: boolean;
  @Input() icon: string = null;
  @Input() class: string = null;
  @Input() bold: boolean = false;

  constructor() {}

  btnType = "";
  btnSize = "";
  btnDisable: boolean = false;

  ngOnInit() {
    if (this.buttonType == "primary") {
      this.btnType = "btn-primary";
    } else if (this.buttonType == "secondary") {
      this.btnType = "btn-secondary";
    } else if (this.buttonType == "outline") {
      this.btnType = "btn-outline-primary";
    } else {
      this.btnType = "btn-primary";
    }

    if (this.size == "large") {
      this.btnSize = "btn-large";
    } else if (this.size == "small") {
      this.btnSize = "btn-small";
    } else if (this.btnSize == "medium" || this.btnSize == "") {
      this.btnSize = "btn-medium";
    }

    if (this.disabled == null || this.disabled == false) {
      this.btnDisable = false;
    } else {
      this.btnDisable = true;
    }
  }
}
