<app-modal-loading-screen *ngIf="showvalidation">Validating Group</app-modal-loading-screen>

<div *ngIf="isLoaded" class="modal" tabindex="-1" role="dialog" style="display:block!important">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="pagetitle">Enter Group</h4>
        <span class="table-material-icons" (mousedown)="activeModal.close('Close')"
          style="cursor: pointer;font-size: 24px;">close</span>

      </div>
      <form [formGroup]="groupform" (ngSubmit)="onsubmit()">
        <div class="modal-body">
          <div class="PageLevelError" *ngIf="pageerror">{{error}}</div>
          <div [ngClass]="{
        'has-error':
        groupform.get('groupcode').errors &&
          (groupform.get('groupcode').touched ||
          groupform.get('groupcode').dirty)
      }">
            <label [ngClass]="'lib-text-field'" required>
              <input (keyup)="validateGroup($event)" [readonly]="isdisabled" class="fullWidth" placeholder=" "
                formControlName="groupcode" (keyup)="pageerror=false;" (mousedown)="validationclick()"/>
              <span class="lib-placeholder">Group</span>

              <span class="help-block" *ngIf="
          groupform.get('groupcode').errors &&
            (groupform.get('groupcode').touched ||
            groupform.get('groupcode').dirty)
          ">
                <span *ngIf="groupform.get('groupcode').errors.required && istouched" class="inputMsg"><i
                    class="table-material-icons">error</i> Group is
                  required</span>

                <span *ngIf="groupform.get('groupcode').errors.pattern" class="inputMsg"><i
                    class="table-material-icons">error</i> Please enter a valid
                  Group</span>
              </span>
            </label>
          </div>
          <label [ngClass]="'lib-text-field'">
            <input readonly class="fullWidth" placeholder=" " formControlName="groupname" />
          </label>
        </div>
        <div class="modal-footer">
          <app-button size="medium" type="submit" style="width: 100%;" class="nocursor"
            [disabled]="!groupform.valid || validationerror" buttonType="primary">
            Create Company</app-button>
        </div>
      </form>
    </div>
  </div>
</div>