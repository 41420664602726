import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as GridActions from '../../grid-configuration/data-access/grid-config.actions';
import * as premiumReportActions from './premium-report.action';

import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { of, throwError } from 'rxjs';
import {
  catchError,
  delay,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { Premiumreportservice } from 'src/app/company/premiumreport/data-access/services/premiumreportservice';
import { Userdefinedservice } from 'src/app/shared/userdefinedservice';
import { CompanyService } from '../../company.service';
import * as gridActions from '../../grid-configuration/data-access/grid-config.actions';
import { selectGridConfig } from '../../grid-configuration/data-access/grid-config.selectors';
import { GridConfigService } from '../../grid-configuration/data-access/grid-configuration.service';
import { selectPremiumReportState } from './premium-report.selectors';
import * as ValidationSummaryActions from '../../validation-summary/data-access/validation-summary-actions';
import { selectCommonInputData } from '../../validation-summary/data-access/validation-summary-selectors';

@Injectable()
export class PremiumReportEffects {
  loadPremiumInfoSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(premiumReportActions.loadPremiumInfoSuccess),
      switchMap((x) => {
        let [dColumn, sColumn] = this.premiumReportService.getLockedColumns(
          x.response[7]
        );
        return [
          premiumReportActions.setColums({ grid: 'D', columns: dColumn }),
          premiumReportActions.setColums({ grid: 'S', columns: sColumn }),
        ];
      })
    )
  );

  setGridPremiumDataSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        premiumReportActions.loadPremiumInfoSuccess,
        premiumReportActions.setGridPremiumDataSuccess,
        premiumReportActions.refreshDirectWithCopy,
        premiumReportActions.refreshSupplementalWithCopy,
        gridActions.loadGridConfigurationsSuccess,
        gridActions.saveGridConfigurations
      ),
      withLatestFrom(this.store.select(selectGridConfig)),
      switchMap(([action, gridConfigData]) => {
        return [
          premiumReportActions.backupHiddenRows({ gridConfigData }),
          premiumReportActions.setHiddenPremiumCount({ gridConfigData }),
        ];
      })
    )
  );

  setGridPremiumDataEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(premiumReportActions.setGridPremiumData),
      withLatestFrom(this.store.select(selectPremiumReportState)),
      switchMap(([x, latest]) => {
        let hidden_direct_premium = latest.premiumData.D.hiddenPremiumGridData;
        let hidden_supplemental_premium =
          latest.premiumData.S.hiddenPremiumGridData;
        let direct_premium =
          x.grid == 'D' ? x.premiumData : latest.premiumData.D.premiumGridData;
        let supplemental_premium =
          x.grid == 'S' ? x.premiumData : latest.premiumData.S.premiumGridData;
        let userdefined_premium = latest.userDefinedPremiumData.D;
        let userdefined_premium_supplemental = latest.userDefinedPremiumData.S;

        direct_premium = this.premiumReportService.concatWithHiddenRows(
          direct_premium,
          hidden_direct_premium
        );
        supplemental_premium = this.premiumReportService.concatWithHiddenRows(
          supplemental_premium,
          hidden_supplemental_premium
        );

        const [directPremium, supplementalPremium] =
          this.premiumReportService.UpdateToPremiumReport(
            direct_premium,
            supplemental_premium,
            userdefined_premium,
            userdefined_premium_supplemental
          );
        return [
          premiumReportActions.setGridPremiumDataSuccess({
            D: directPremium,
            S: supplementalPremium,
          }),
        ];
      })
    )
  );

  setErrorEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(premiumReportActions.setError),
      delay(5000),
      tap((x) => {
        console.log(x);
      }),
      switchMap((x) => of(premiumReportActions.clearError()))
    )
  );
  loadPremiumInfoEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(premiumReportActions.loadPremiumInfo),
      tap((x) => {
        console.log(x);
      }),
      switchMap((c) => {
        return this.companyService
          .getPremiumReportData(c.compno, c.currentYear, c.groupcode)
          .pipe(
            map((resp) => {
              if (resp['message'] == 'Success') {
                const result = resp['data'];
                let direct_premium = resp['data']['data']['direct_premium'];
                let direct_premium_copy = JSON.parse(
                  JSON.stringify(direct_premium)
                );
                let supplemental_premium =
                  resp['data']['data']['supplemental_premium'];
                let supplemental_premium_copy = JSON.parse(
                  JSON.stringify(supplemental_premium)
                );
                let userdefined_premium =
                  resp['data']['userdefined_premium']['dpremium'];
                let userdefined_premium_columns =
                  resp['data']['userdefined_columns'];
                let userdefined_premium_supplemental =
                  resp['data']['userdefined_premium']['spremium'];

                let columns = result['columns'];
                let rows = result['rows'];

                let validation_job_id = result['validation_job_id'];

                columns = this.premiumReportService.updatePinnedStatus(columns);
                this.premiumReportService.setParams(
                  columns,
                  rows,
                  userdefined_premium_columns
                );

                this.userDefinedService.setParams(
                  columns,
                  rows,
                  userdefined_premium_columns,
                  this.premiumReportService.CONST_STATECODE_COLUMN,
                  this.premiumReportService.userdefined_row_code,
                  this.premiumReportService.supplemental_row_code,
                  this.premiumReportService.FIXED_COLUMNS,
                  this.premiumReportService.AssumedFromNonAffiliates_row_code
                );
                const [dPremium, sPremium, uPremium, uPremiumSupplemental] =
                  this.userDefinedService.UpdateUDFormulaCells(
                    direct_premium,
                    supplemental_premium,
                    userdefined_premium,
                    userdefined_premium_supplemental
                  );
                const [directPremium, supplementalPremium] =
                  this.premiumReportService.UpdateToPremiumReport(
                    direct_premium,
                    supplemental_premium,
                    userdefined_premium,
                    userdefined_premium_supplemental
                  );
                return [
                  directPremium,
                  supplementalPremium,
                  uPremium,
                  uPremiumSupplemental,
                  direct_premium_copy,
                  supplemental_premium_copy,
                  rows,
                  columns,
                  userdefined_premium_columns,
                  validation_job_id,
                ];
              } else {
                throwError(resp);
              }
            })
          );
      }),
      switchMap((x) => {
        return [
          premiumReportActions.loadPremiumInfoSuccess({
            response: x,
          }),
          premiumReportActions.clearError(),
        ];
      }),
      catchError((x) => {
        this.router.navigate(['/apifailure']);
        return [premiumReportActions.loadPremiumInfoFailed({ response: x })];
      })
    )
  );

  enableConsolidatedViewEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(premiumReportActions.enableConsolidatedView),
      switchMap(() => {
        return this.gridConfigService
          .getConsolidatedViewGridConfigurations()
          .pipe(
            map((data) =>
              GridActions.loadGridConfigurationsSuccess({
                hiddenDirectRowStates: data.direct.states,
                hiddenDirectPremiumColumns: data.direct.columns,
                hiddenSupplementalRowStates: data.supplemental.states,
                hiddenSupplementalPremiumColumns: data.supplemental.columns,
              })
            ),
            catchError((error) =>
              of(GridActions.loadGridConfigurationsFailure({ error }))
            )
          );
      })
    )
  );

  enableDetailedViewEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(premiumReportActions.enableDetailedView),
      switchMap(() => {
        return of(
          GridActions.loadGridConfigurationsSuccess({
            hiddenDirectRowStates: [],
            hiddenDirectPremiumColumns: [],
            hiddenSupplementalRowStates: [],
            hiddenSupplementalPremiumColumns: [],
          })
        );
      })
    )
  );

  updateJobIdEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ValidationSummaryActions.initiateValidationOnAfterSubmitSuccess),
      map(({ jobId }) =>
        premiumReportActions.updateJobId({
          jobId,
        })
      ),
      catchError((error) =>
        of(
          premiumReportActions.updateJobIdFailure({
            error,
          })
        )
      )
    )
  );

  clearJobIdEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ValidationSummaryActions.initiateValidationOnAfterSubmit),
      map(() =>
        premiumReportActions.updateJobId({
          jobId: '',
        })
      ),
      catchError((error) =>
        of(
          premiumReportActions.updateJobIdFailure({
            error,
          })
        )
      )
    )
  );

  updateJobIdSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(premiumReportActions.updateJobId),
      map(() => premiumReportActions.setValidationLock({ value: true })),
      catchError((error) =>
        of(
          premiumReportActions.updateJobIdFailure({
            error,
          })
        )
      )
    )
  );

  unlockToEditInPremiumReportSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ValidationSummaryActions.unlockToEditSuccess,
        ValidationSummaryActions.reSubmitSuccess
      ),
      map(() => premiumReportActions.setValidationLock({ value: false })),
      catchError((error) =>
        of(
          ValidationSummaryActions.unlockToEditFailure({
            error,
          })
        )
      )
    )
  );

  updateLockStatusEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(premiumReportActions.updateLockStatus),
      switchMap(() => {
        const companyNumber = localStorage.getItem('companyId');
        const year = parseInt(localStorage.getItem('year'));
        const groupCode = localStorage.getItem('groupcode');
        return this.companyService
          .getLockStatus(year, companyNumber, groupCode)
          .pipe(
            map((x) =>
              premiumReportActions.updateLockStatusSuccess({
                lockedForValidation: x['result']['lock_for_validation'],
                prfLocked: x['result']['status'] === 'Locked',
              })
            ),
            catchError((error) =>
              of(premiumReportActions.updateLockStatusFailure({ error }))
            )
          );
      })
    )
  );

  loadCompanyInformation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(premiumReportActions.createNewPRF),
        tap((action) => {
          localStorage.setItem('isLocked', 'false');
          this.store.dispatch(premiumReportActions.setPrfLock({ value: false }));
          this.store.dispatch(premiumReportActions.setValidationLock({ value: false }));
          localStorage.setItem('lockForValidation', 'false');
          localStorage.setItem('companyId', action.companyId);
          localStorage.setItem('year', action.currentYear + '');
          localStorage.setItem('prfNumber', action.prfNumber);
          localStorage.setItem(
            'prfName',
            this.companyService.toTitleCase(action.prfName)
          );
          localStorage.setItem('groupcode', action.groupCode);
          this.companyService.getLocalStorage();
        }),
        tap(() => {
          this.router.navigate(['/companyinformation']);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private companyService: CompanyService,
    private premiumReportService: Premiumreportservice,
    private userDefinedService: Userdefinedservice,
    private gridConfigService: GridConfigService,
    private store: Store,
    private router: Router
  ) {}
}
